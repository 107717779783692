 <div class="MobileCategoriesCta">
    <div class="banner_content">
      <div class="banner_information_desktop">
        <div class="banner_information_desktop_title animate__animated animate__fadeInLeft">
          <p class="title_standard desktop">Envía y recibe dinero del extranjero con Mibanco</p>
          <p class="title_standard mobile">Envía y recibe dinero del extranjero con Mibanco</p>
        </div>
        <div class="banner_information_desktop_description animate__animated animate__fadeInLeft">
          <p class="description">Disponible para más de 200 países</p>
        </div>
        <div class="container-button animate__animated animate__fadeInUp">
          <button type="button" class="btn button-desktop" (click)="scrollTo('agences-remittance')">
            <span>Ubícanos aquí</span>
          </button>
        </div>

        <div class="floating-button-mobile animate__animated animate__fadeInUp">
          <div class="floating-button" [class.fixed]="showButton">
            <button type="button" class="btn button-mobile" (click)="scrollTo('agences-remittance')">
              <span>Ubícanos aquí</span>
            </button>
          </div>
        </div>
      </div>
      <div class="image-desktop animate__animated animate__fadeInRight">
        <img class="women-image-desktop" [src]="womenImageRoute" alt="women" loading="lazy" />
      </div>
      <div class="image-mobile animate__animated animate__fadeInRight">
        <div class="image">
          <img class="women-image-mobile" [src]="womenImageRoute" alt="women" loading="lazy" />
        </div>
      </div>
    </div>

    <div class="container">
      <remittances-best-option
        id="landing-card-refers"
        [title]="this.titleRemittance"
        [subtitle]="this.subtitleRemittance"
        [description]="this.descriptionRemittance"
        [titleMobile]="this.titleRemittanceMobile"
        [subtitleMobile]="this.subtitleRemittanceMobile"
        [icons]="this.icons"
      ></remittances-best-option>
    </div>
  </div>

<div id="agences-remittance" class="animate__animated animate__fadeInRight">
<shipping-to-five-agencies
  [selectedFaqButton]="selectedFaqButton"
  [isVisibleButton]="faqItemsVisibleButton"
></shipping-to-five-agencies>
</div>

<div id="section_to_do_today" class="section_to_do_today">
  <milab-want-to-do-today (showSectionTabQuestion)="showSectionTabQuestion($event)"></milab-want-to-do-today>
  <shipping-cost *ngIf="tabOneShow"></shipping-cost>
</div>

<frequent-questions [selectedFaq]="selectedFaq" [isVisible]="faqItemsVisible"></frequent-questions>

<div class="section_info_footer">
  <milab-info-footer></milab-info-footer>
</div>

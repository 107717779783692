<div class="modal-recommend">
    <div class="modal-close" (click)="closeModal()"><img src="assets/icons/communities/close-small.svg" alt="close-small"></div>
    <div class="modal-recommend-content">
        <div class="modal-recommend-content-img">
           <img [src]="this.dataWelcomeRecommend.logo" alt="yevo"/>
        </div>
            <div class="modal-recommend-content-title">{{ this.dataWelcomeRecommend.title}}</div>
            <div class="modal-recommend-content-description">{{ this.dataWelcomeRecommend.description}}</div>
    </div>
    <div class="modal-recommend-card-options">
        <ng-container *ngFor="let item of this.dataWelcomeRecommend.optionsButton">
            <div class="card-button-section">
                <button [className]="item.name == 'Solicitar un Préstamo' ? 'green' : ''"
                (click)="redirectAndClose(item.url,item.action,item.code)">{{item.name}}</button>
            </div>
        </ng-container>
    </div>
</div>
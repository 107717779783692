<div class="container-fluid financing-form-container wrapper-top">
  <nav
    class="general-finance-container-breadcrumb"
    *ngIf="ally !== 'WINGS'"
    [ngClass]="{ 'general-finance-container-breadcrumb-logged': isLogged }"
  >
    <ol class="general-finance-breadcrumb d-flex">
      <yevo-breadcrumb [breadcrumbs]="breadcrumbs"></yevo-breadcrumb>
    </ol>
  </nav>
  <div>
    <div class="form-group col-sm-12 financing__form p-0 d-flex flex-column justify-content-center align-items-center">
      <div class="">
        <h2 class="general-finance-title mt-3 mb-3">Financiamiento General💰</h2>
      </div>
      <form
        [formGroup]="userForm"
        (ngSubmit)="sendForm()"
        click-stop-propagation
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <p class="general-finance-subtitle">Completa tus datos correctamente:</p>
        <div class="form-section">
          <img src="assets/financing/person.svg" alt="person-icon" />
          <p>Información personal</p>
        </div>
        <div class="form-section-personal">
          <div class="row mb-2" *ngIf="!showLoanSimulatorResult">
            <div class="col-sm-12">
              <label>Monto a solicitar*</label>
              <div class="input-group flex-nowrap mt-2">
                <span class="input-group-text span-icon" id="addon-wrapping">S/</span>
                <input
                  type="text"
                  class="form-control h-100 input-border"
                  placeholder=""
                  maxlength="50"
                  formControlName="amount"
                  [attr.disabled]="true"
                  #inputAmount
                />
              </div>
            </div>
          </div>
          <div class="container p-0" *ngIf="showLoanSimulatorResult">
            <yevo-financing-loan-simulator-additional-form
              [amount]="simulatorData.amount"
              [paymentDeadline]="simulatorData.paymentDeadline"
              [maxPaymentRate]="simulatorData.maxPaymentRate"
            ></yevo-financing-loan-simulator-additional-form>
          </div>

          <div class="col-sm-12">
            <div class="row">
              <div class="col-4 col-md-2 document-type pl-0">
                <label>Documento</label>
                <yevo-dropdown
                  [items]="documentTypeList"
                  [itemSelected]="documentTypeSelected"
                  [dropdownPlacement]="['bottom-right']"
                  [canRemoveActiveItem]="false"
                  [disabled]="true"
                  buttonLabel="Documento"
                  (selectItem)="getDocumentTypeEvent($event)"
                ></yevo-dropdown>
              </div>
              <div class="col-8 col-md-4 pl-0 pr-0 pr-md-3">
                <label>Número de documento*</label>
                <input
                  [type]="documentTypeSelected.value === 'E' ? 'text' : 'tel'"
                  class="form-control"
                  placeholder="Número de documento"
                  formControlName="documentNumber"
                  [attr.disabled]="true"
                />
                <label class="form-inline">
                  <div *ngIf="showError('documentNumber')" class="text-error">
                    <div *ngIf="getErrorsFromField('documentNumber')?.required">Número de documento es requerido.</div>
                    <div *ngIf="getErrorsFromField('documentNumber')?.pattern">Número de documento inválido.</div>
                  </div>
                </label>
              </div>
              <div class="col-12 col-md-6 pl-0 pl-md-3">
                <label>Fecha de nacimiento*</label>
                <yevo-dropdown-birthdate
                  (birthdateSelected)="getBirthdateEvent($event)"
                  [invalid]="!!(showError('birthDate') && getErrorsFromField('birthDate'))"
                ></yevo-dropdown-birthdate>
                <label class="form-inline">
                  <div *ngIf="showError('birthDate')" class="text-error">
                    <div *ngIf="getErrorsFromField('birthDate')?.required">Fecha de nacimiento es requerido.</div>
                    <div *ngIf="getErrorsFromField('birthDate')?.invalidDate">Fecha de nacimiento no es válida.</div>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div class="row mb-1" *ngIf="!isLogged">
            <div class="col-12">
              <label>Nombres*</label>
              <input
                type="text"
                class="form-control"
                placeholder="Ingresa tu nombre completo"
                maxlength="100"
                formControlName="name"
                #inputName
              />
              <label class="form-inline">
                <div *ngIf="showError('name')" class="text-error">
                  <div *ngIf="getErrorsFromField('name')?.required">Nombre es requerido.</div>
                  <div *ngIf="getErrorsFromField('name')?.onlyLetter">
                    No se admiten caracteres especiales ni números.
                  </div>
                </div>
              </label>
            </div>
          </div>

          <div class="row mb-1" *ngIf="!isLogged">
            <div class="col-sm-12 col-lg-6">
              <label>Apellido paterno*</label>
              <input
                type="text"
                class="form-control"
                placeholder="Ingresa tu apellido paterno"
                maxlength="100"
                formControlName="fatherLastName"
                #inputName
              />
              <label class="form-inline">
                <div *ngIf="showError('fatherLastName')" class="text-error">
                  <div *ngIf="getErrorsFromField('fatherLastName')?.required">
                    Apellido paterno completo es requerido.
                  </div>
                  <div *ngIf="getErrorsFromField('fatherLastName')?.onlyLetter">
                    No se admiten caracteres especiales ni números.
                  </div>
                </div>
              </label>
            </div>

            <div class="col-sm-12 col-lg-6">
              <label>Apellido materno*</label>
              <input
                type="text"
                class="form-control"
                placeholder="Ingresa tu apellido materno"
                maxlength="100"
                formControlName="motherLastName"
                #inputName
              />
              <label class="form-inline">
                <div *ngIf="showError('motherLastName')" class="text-error">
                  <div *ngIf="getErrorsFromField('motherLastName')?.required">Apellido materno es requerido.</div>
                  <div *ngIf="getErrorsFromField('motherLastName')?.onlyLetter">
                    No se admiten caracteres especiales ni números.
                  </div>
                </div>
              </label>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-sm-12 col-lg-6">
              <label>Número de celular*</label>
              <input
                type="tel"
                class="form-control"
                placeholder="Número de celular"
                maxlength="9"
                formControlName="phone"
                [attr.disabled]="true"
              />
              <label class="form-inline">
                <div *ngIf="showError('phone')" class="text-error">
                  <div *ngIf="getErrorsFromField('phone')?.required">Celular es requerido.</div>
                  <div *ngIf="getErrorsFromField('phone')?.onlyNumber">Solo se admiten números.</div>
                  <div *ngIf="getErrorsFromField('phone')?.phoneNumber">El número ingresado no es válido.</div>
                  <div *ngIf="getErrorsFromField('phone')?.minlength">El número debe tener 9 caracteres</div>
                </div>
              </label>
            </div>

            <div class="col-sm-12 col-lg-6">
              <label>Correo electrónico (opcional)</label>
              <input
                type="mail"
                class="form-control"
                placeholder="Correo electrónico"
                maxlength="100"
                formControlName="email"
                [readonly]="isEmailReadOnly"
                (ngModelChange)="changeEmailReferrer($event)"
              />
              <label class="form-inline">
                <div *ngIf="showError('email')" class="text-error">
                  <div *ngIf="getErrorsFromField('email')?.required">Correo es requerido.</div>
                  <div *ngIf="getErrorsFromField('email')?.pattern">El campo correo no tiene el formato adecuado.</div>
                </div>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-lg-6 marital-status-control">
              <label class="control-label">Estado Civil</label>
              <yevo-dropdown
                [items]="maritalStatusList"
                [itemSelected]="maritalStatusSelected"
                [dropdownPlacement]="['bottom-right']"
                [canRemoveActiveItem]="false"
                buttonLabel="Estado civil"
                containerTitle="Estado civil"
                (selectItem)="getMaritalStatusEvent($event)"
                [showManualError]="showError('maritalStatus')"
              ></yevo-dropdown>
            </div>

            <div class="col-sm-12 col-lg-6">
             <label class="control-label">Tipo de vivienda</label>
              <yevo-dropdown
                [items]="housingTypeList"
                [itemSelected]="housingTypeSelected"
                [dropdownPlacement]="['bottom-right']"
                [canRemoveActiveItem]="false"
                buttonLabel="Selecciona tu opción aquí"
                containerTitle="Selecciona tu opción aquí"
                (selectItem)="getHousingTypeEvent($event)"
                [showManualError]="showError('housingType')"
              ></yevo-dropdown>
            </div>
          </div>

          <div class="row mt-3" *ngIf="showSpouseInputs">
            <div class="col-sm-12">
              <p class="mb-0 mt-2 title-spouse-info">Datos del cónyuge</p>
              <div class="form-group col-12 p-0 d-lg-flex mb-0">
                <div class="col-12 col-lg-3 control-spouse p-0">
                  <label>Documento*</label>
                  <yevo-dropdown
                    [items]="documentTypeList"
                    [itemSelected]="documentTypeSpouseSelected"
                    [dropdownPlacement]="['bottom-right']"
                    [canRemoveActiveItem]="false"
                    [disabled]="false"
                    buttonLabel="Documento"
                    required
                    (selectItem)="getDocumentTypeSpouseEvent($event)"
                    [showManualError]="showError('typeDocumentSpouse')"
                  ></yevo-dropdown>
                  <label class="form-inline">
                    <div *ngIf="showError('typeDocumentSpouse')" class="text-error">
                      <div *ngIf="getErrorsFromField('typeDocumentSpouse')?.required">
                        Tipo de documento es requerido.
                      </div>
                    </div>
                  </label>
                </div>
                <div class="col-12 col-lg-9 control-spouse p-0">
                  <label class="control-label">Número de documento*</label>
                  <input
                    [type]="documentTypeSpouseSelected.value === 'E' ? 'text' : 'tel'"
                    formControlName="documentNumberSpouse"
                    class="form-control"
                    placeholder="Ingresa nro. de documento "
                    [maxlength]="documentMaxLengthSpouse"
                  />
                  <label class="form-inline">
                    <div *ngIf="showError('documentNumberSpouse')" class="text-error">
                      <div *ngIf="getErrorsFromField('documentNumberSpouse')?.required">
                        Número de documento es requerido.
                      </div>
                      <div *ngIf="getErrorsFromField('documentNumberSpouse')?.pattern">
                        Número de documento es inválido.
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="form-group col-12 p-0 d-lg-flex mb-0">
                <div class="col-12 col-lg-6 control-spouse p-0">
                  <label class="control-label">Nombres*</label>
                  <input
                    type="text"
                    formControlName="nameSpouse"
                    class="form-control"
                    placeholder="Nombres"
                    maxlength="50"
                  />
                  <label class="form-inline">
                    <div *ngIf="showError('nameSpouse')" class="text-error">
                      <div *ngIf="getErrorsFromField('nameSpouse')?.required">Nombres del cónyuge es requerido.</div>
                      <div *ngIf="!!getErrorsFromField('nameSpouse')?.onlyLetter">Solo se admiten letras.</div>
                    </div>
                  </label>
                </div>
                <div class="col-12 col-lg-6 control-spouse p-0">
                  <label class="control-label">Apellidos*</label>
                  <input
                    type="text"
                    formControlName="lastNameSpouse"
                    class="form-control"
                    placeholder="Apellidos"
                    maxlength="50"
                  />
                  <label class="form-inline">
                    <div *ngIf="showError('lastNameSpouse')" class="text-error">
                      <div *ngIf="getErrorsFromField('lastNameSpouse')?.required">
                        Apellidos del cónyuge es requerido.
                      </div>
                      <div *ngIf="!!getErrorsFromField('lastNameSpouse')?.onlyLetter">Solo se admiten letras.</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-section mb-4">
          <img src="assets/financing/shopping-cart.svg" alt="bussiness-icon" />
          <p>Mi negocio</p>
        </div>

        <div class="form-section-bussiness">
          <div class="row mb-3">
            <div class="col-12">
              <label class="control-label mb-2">Selecciona tu tipo de actividad*</label>
              <div class="pills-container">
                <button
                  type="button"
                  class="pill"
                  [ngClass]="{ active: item.value == employmentSituationSelected.value }"
                  *ngFor="let item of allActivityType"
                  (click)="getActivityType(item)"
                >
                  {{ item.extraValue }}
                </button>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="control-label">Seleccionar tu giro o actividad comercial de mayor ingreso*</label>
              <yevo-searchable-input
                [items]="commercialActivityList"
                [itemSelected]="commercialActivitySelected"
                (itemSelectedEvent)="handleSelectActivity($event)"
                [showManualError]="showError('businessEconomicActivity')"
              ></yevo-searchable-input>
              <label class="form-inline">
                <div *ngIf="showError('businessEconomicActivity')" class="text-error">
                  <div *ngIf="getErrorsFromField('businessEconomicActivity')?.required">
                    Giro o actividad comercial es requerido.
                  </div>
                </div>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-12">
              <label class="control-label"
                >Indica el monto total de tus <span class="highlight mr-1">Ingresos mensuales*</span>
                <yevo-modal-info
                  [isLabelInfo]="true"
                  [titleModal]="'Ingresos financieros'"
                  descriptionModal="Coloca la <span>suma total</span> de:<br> Dinero que percibe de su negocio de manera mensual + Intereses (ganancias que obtiene de sus cuentas de ahorro)."
                ></yevo-modal-info>
              </label>
              <input
                type="text"
                formControlName="businessSalesAmount"
                class="form-control monthly-income"
                placeholder="0.00"
                maxlength="7"
              />
              <div class="currency-container">
                <label class="currency-symbol">S/ </label>
              </div>
              <label class="form-inline">
                <div *ngIf="showError('businessSalesAmount')" class="text-error">
                  <div *ngIf="getErrorsFromField('businessSalesAmount')?.required">Ingreso mensual es requerido.</div>
                  <div *ngIf="!!getErrorsFromField('businessSalesAmount')?.onlyNumber">Solo se admiten números.</div>
                </div>
              </label>
            </div>
          </div>

          <div class="d-flex justify-content-start align-items-center my-2">
            <yevo-modal-info
              descriptionModal="Indica la ubicación donde realizas la actividad de tu negocio. Si es en tu domicilio, puedes colocar dicha dirección"
              [title]="'Dirección del negocio*'"
            ></yevo-modal-info>
          </div>

          <div class="row mb-1">
            <div class="col-sm-12">
              <yevo-geolocation-grid
                [parentFormGroup]="userForm"
                [showLabels]="true"
                [obligatory]="true"
                [labels]="geolocationLabels"
                [emitValuesSeparately]="true"
                [canRemoveActive]="false"
                (selectSeparatelyItem)="handleMobileGeolocationClick($event!)"
                class="w-100 mt-2 geolocation-general-finance"
                [businessPhoneRequired]="false"
                [hideFourthField]="true"
                [cardThree]="true"
              ></yevo-geolocation-grid>

              <label class="form-inline">
                <div *ngIf="showError('department')" class="text-error">
                  <div *ngIf="getErrorsFromField('department')?.required">Departamento es requerido.</div>
                </div>
                <div *ngIf="showError('province')" class="text-error">
                  <div *ngIf="getErrorsFromField('province')?.required">Provincia es requerido.</div>
                </div>
                <div *ngIf="showError('district')" class="text-error">
                  <div *ngIf="getErrorsFromField('district')?.required">Distrito es requerido.</div>
                </div>
              </label>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-12 mb-3">
              <label>Dirección de tu negocio*</label>
              <input
                #mapSearchField
                class="form-control"
                placeholder="Ingresa tu dirección aquí"
                formControlName="address"
                (focus)="googlePlaceSelected = false"
              />
              <label class="form-inline">
                <div *ngIf="showError('address')" class="text-error">
                  <div *ngIf="getErrorsFromField('address')?.googlePlaceSelected">
                    Agrega una dirección sugerida por google maps para poder continuar
                  </div>
                </div>
              </label>
            </div>
            <div class="col-12" id="geolocationOpenMap">
              <yevo-geolocation-maps
                [currentPosition]="positionSelected"
                (newAddress)="addGeolocation($event)"
                (currentPositionChanged)="currentLocationEvent($event)"
              ></yevo-geolocation-maps>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label>Elige la agencia más cercana a tu negocio*</label>
              <yevo-dropdown-agency
                [items]="listAgency"
                [itemSelected]="agencySelected"
                [dropdownPlacement]="['bottom-right']"
                [canRemoveActiveItem]="false"
                buttonLabel="Elige tu agencia más cercana"
                containerTitle="Elige tu agencia más cercana"
                (selectItem)="getAgencyStatusEvent($event)"
                [showManualError]="showError('agencyName')"
              ></yevo-dropdown-agency>
              <label class="form-inline">
                <div *ngIf="showError('agencyName')" class="text-error">
                  <div *ngIf="getErrorsFromField('agencyName')?.required">Agencia es requerido.</div>
                </div>
              </label>
            </div>
          </div>
        </div>

        <span class="reminder-note mt-3">(*) Campos requeridos</span>

        <div class="submit-button-container mt-5 mb-5">
          <button type="button" class="btn btn-outline-primary general-finance-button-seconday" (click)="goBack()">
            Volver
          </button>
          <button
            type="submit"
            class="d-block border-0 general-finance-button-primary"
            (click)="pushFinancingEventSendtSolicitude()"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<img class="hidden-image images__person" src="/assets/img/seed-cash/person-v2.png" alt="persona" />

<div class="container-fluid d-none d-md-block">
  <div class="row">
    <div class="col-12 pl-0 pr-0 mt-4">
      <img class="general-finance-cloud" src="/assets/img/courses/nubes.png" alt="cloud" />
    </div>
  </div>
</div>
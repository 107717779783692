<div class="conatiner-community-benefits">
    <div class="title"> {{title}} </div>
    <div class="description"> {{description}} </div>
    <div class="icons">
        <div class="item-icon" *ngFor="let icon of icons" (click)='openBenefit(icon)'>
            <div class="icon"> 
                <img [src]='icon.icon' [alt]='icon.name' />
            </div>
            <div class="name"> {{icon.name}} </div>
        </div>
    </div>
</div>
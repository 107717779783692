import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ITabItem, ITrainingBannerItem } from '@shared/interfaces';
import { IDropdownItem } from '@shared/interfaces/IDropdownItem';
import { TopicModel } from '@shared/models/Topic.model';
import { TopicOptionModel } from '@shared/models/TopicOption.model';
import { AuthService } from '@shared/services/auth.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { DirectoryService } from '@shared/services/directory.service';
import { UserInteractionLogService } from '@shared/services/user-interaction-log.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'yevo-training-details-list',
  templateUrl: './training-details-list.component.html',
  styleUrls: ['./training-details-list.component.scss', '../../training.component.scss'],
})
export class TrainingDetailsListComponent implements OnInit {
  showDetail = false;
  interval: number = 2000;
  pauseOnHover: boolean = true;
  pauseOnFocus: boolean = false;

  currentPage: number = 1;
  pageSize: number = 12;
  totalItems!: number;
  pagination = 8;

  topics?: TopicModel[];
  topic = {} as TopicModel;
  topicOption: TopicOptionModel = {} as TopicOptionModel;
  topicOptions: TopicOptionModel[] = [];
  selectedTopic: IDropdownItem | null = null;
  page!: number;
  CODE_ALL_TOPICS = '3007';
  codeSelected: string = '';
  showTopicContent: boolean = false;
  showTabContent: boolean = false;
  selectedTab!: ITabItem;
  tabItems: ITabItem[] = [
    { label: 'Todos', icon: 'house', active: true, code: '' },
    { label: 'Artículos', icon: 'book-open-inactive', active: false, code: 'article,html' },
    { label: 'Cursos', icon: 'play', active: false, code: 'course' },
    { label: 'Videos', icon: 'right-arrow', active: false, code: 'video' },
    { label: 'Progreso', icon: 'progress', active: false, code: 'progress' },
  ];

  carouselSlidesArray: ITrainingBannerItem[] = [
    {
      title: '',
      description: '',
      bannerName: 'Asesórate con Yevo',
      theme: 'theme-1',
    },
  ];

  allPosts: TopicModel[] = [];
  allPostsAux: TopicModel[] = [];

  utm_campaign: string = '';
  utm_content: string = '';
  utm_medium: string = '';
  utm_source: string = '';

  constructor(
    public dataLayerService: DataLayerService,
    private location: Location,
    private directoryService: DirectoryService,
    @Inject(DOCUMENT) private _document: any,
    private userInteractionLogService: UserInteractionLogService,
    public authService: AuthService,
    private router: Router,
    public activateRoute: ActivatedRoute
  ) {
    this.activateRoute.queryParams.subscribe((params) => {
      this.utm_campaign = params.utm_campaign || '';
      this.utm_content = params.utm_content || '';
      this.utm_medium = params.utm_medium || '';
      this.utm_source = params.utm_source || '';
    });
  }

  ngOnInit(): void {
    this.getAllPosts();
  }

  back(): void {
    this.showDetail = false;
  }

  pushBannerEvent(bannerName: string): void {
    this.dataLayerService.pushTrainingBanner(bannerName);
  }

  viewShowDetail(topic: TopicModel, section?: string): void {
    this.logUserAction(topic.title);
    this.dataLayerService.pushTrainingKnowMoreOption(topic.title);
    this.topic = topic;
    this.router.navigate(['/capacitaciones', topic.slug], { queryParamsHandling: 'merge' });

    if (section) this.pushModuleItemEvent(section, topic.title);
  }

  logUserAction(topicTitle: string) {
    this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
      this.userInteractionLogService.logUserAction(user?.documentNumber!, 'click', topicTitle, 'Asesoría');
    });
  }

  isImageDefault(length: any) {
    return length && (length + 1) % 3 === 0;
  }

  pushAdBannerEvent(buttonName: string): void {
    this.dataLayerService.pushTrainingAdBanner(buttonName);
  }

  goBack() {
    this.location.back();
  }

  getAllPosts(): void {
    this.directoryService
      .getAllPosts()
      .pipe(take(1))
      .subscribe((items) => {
        this.allPostsAux = items.filter((item) => {
          return item.type === 'video';
        });
        this.totalItems = items.length - this.pagination;
        this.pageChangeEvent(1);
      });
  }

  pageChangeEvent(page: number): void {
    this.currentPage = page;
    this.allPosts = this.allPostsAux.slice((page - 1) * this.pageSize, page * this.pageSize);
  }

  searchAgainTopics(eventTopic: any) {
    this.directoryService.getTopicById(eventTopic.topicId).subscribe((res) => {
      this.topic = res;
      if ((this.topic?.parameterIds !== undefined, this.topic?.parameterIds !== '')) {
        const paramIds = this.topic?.parameterIds.split(',');
        if (paramIds.length > 1) {
          this.putTopicSelected(paramIds[1]);
        } else if (paramIds.length > 0) {
          this.putTopicSelected(paramIds[0]);
        }
      }
    });
  }

  putTopicSelected(code: any) {
    const topicSelect = this.topicOptions.filter((po) => po.code == code);
    if (topicSelect?.length > 0) {
      this.getTopicsSelected(topicSelect[0].code, topicSelect[0].name);
    }
  }

  backCourse(): void {
    this.topic = {} as TopicModel;
    this.showDetail = false;
  }

  getTopicsSelected(code: string, name: string): void {
    if (!!this.selectedTopic && code === this.selectedTopic.value) {
      return;
    }

    this.selectedTopic = {
      value: code,
      label: name,
    };

    this.dataLayerService.pushTrainingOption(name);
    this.page = 1;

    if (code == this.CODE_ALL_TOPICS) {
      this.codeSelected = '';
      this.showTopicContent = false;
    } else {
      this.showTopicContent = true;
      this.getTopics(code, this.selectedTab?.code);
    }
    this._document.body.classList.remove('overflow-hidden');
    this.selectedTab = this.tabItems[0];
  }

  getTopics(code: string, codeTab: string = ''): void {
    if (code) {
      this.codeSelected = code;
      this.showTabContent = code == this.CODE_ALL_TOPICS;
    } else if (codeTab) {
      this.showTabContent = true;
    }
  }

  pushModuleItemEvent(section: string, moduleName: string): void {
    this.dataLayerService.pushTrainingModule(section, moduleName);
  }
}

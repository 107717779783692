<div class="dropdown-container" [ngClass]="customClass">
  <div ngbDropdown [placement]="dropdownPlacement" click-stop-propagation>
    <button
      ngbDropdownToggle
      class="dropdown-button wrap-text"
      [disabled]="disabled"
      (click)="handleButtonClick($event); $event.preventDefault(); $event.stopPropagation()"
      [ngClass]="{ error: showManualError }"
    >
      <span [ngClass]="{ selected: selectedItem?.name }" class="button-label">
        {{ selectedItem?.name || buttonLabel }}</span
      >
      <span class="agency-address">{{ selectedItem?.address }}</span>
    </button>
    <div
      ngbDropdownMenu
      *ngIf="!showItemsOnMobile && !searchable; else mobileItemList"
      class="dropdown-items-container"
    >
      <div
        *ngFor="let item of items"
        class="dropdown-items-container__item"
        [ngClass]="{ active: item?.name == selectedItem?.name }"
        (click)="handleSelectItem($event, item); closeDropdownMenu()"
      >
        <span class="agency-name">{{ item?.name }}</span>
        <span class="agency-address">{{ item?.address }}</span>
      </div>
    </div>

    <div *ngIf="searchable" ngbDropdownMenu class="w-100">
      <div class="dropdown-items-container search-container">
        <div class="dropdown-items-container__item m-1 mx-3 p-0 d-flex align-items-center">
          <input
            type="text"
            class="form-control search-input w-100 m-0"
            placeholder="Selecciona tu ocupación"
            (input)="onSearchChange($event.target)"
            [(ngModel)]="txtSearch"
          />
          <span class="custom-icon">
            <img class="images__clouds" src="/assets/icons/search-secondary.svg" alt="busquedas" />
          </span>
        </div>
      </div>
      <div *ngIf="!showItemsOnMobile; else mobileItemList" class="dropdown-items-container">
        <div
          *ngFor="let item of items"
          class="dropdown-items-container__item"
          [ngClass]="{ active: item?.name == selectedItem?.name }"
          (click)="handleSelectItem($event, item); closeDropdownMenu()"
        >
          <span class="agency-name">{{ item?.name }}</span>
          <span class="agency-address">{{ item?.address }}</span>
        </div>
      </div>
    </div>

    <!-- Mobile Item List -->
    <ng-template #mobileItemList>
      <div
        *ngIf="showModalOnMobile"
        class="overlay"
        [ngClass]="{ show: isMobileDropdownOpen }"
        (click)="closeDropdownMenu()"
      >
        <div
          class="dropdown-items-container-mobile animate-bottom"
          [ngStyle]="{ height: menuHeightOnMobile }"
          click-stop-propagation
        >
          <div class="dropdown-items-container-mobile__header">
            <div *ngIf="!!containerTitle" class="dropdown-items-container-mobile__title">
              {{ containerTitle }}
            </div>
            <div class="dropdown-items-container-mobile__close" (click)="closeDropdownMenu()">
              <img src="/assets/img/close.png" alt="close_icon" />
            </div>
          </div>
          <div [ngClass]="{ scroll: scrollActivated }" class="dropdown-items-container-mobile__items-list">
            <div
              *ngFor="let item of items"
              class="dropdown-items-container-mobile__item"
              (click)="handleSelectItem($event, item); closeDropdownMenu()"
            >
              <span [ngClass]="{ active: item?.value == itemSelected?.value }">{{ item?.label }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserProfile } from '@shared/models/UserProfileModel';

@Component({
  templateUrl: './financing.component.html',
  styleUrls: ['./financing.component.scss'],
})
export class FinancingComponent implements OnInit {
  user!: UserProfile | null;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.user = this.route.snapshot.data.user;
  }
}

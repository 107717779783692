import { AngularSvgIconModule } from 'angular-svg-icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ClipboardModule } from 'ngx-clipboard';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgbCarouselModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { LayoutModule } from '@angular/cdk/layout';

import { IntranetRoutingModule } from './intranet-routing.module';
import { TrainingCardComponent } from './training/components/training-card/training-card.component';
import { TrainingSectionListComponent } from './training/components/training-section-list/training-section-list.component';
import { TrainingDetailComponent } from './training/training-detail/training-detail.component';
import { TrainingComponent } from './training/training.component';
import { TrainingBadgeComponent } from './training/components/training-badge/training-badge.component';
import { TrainingCourseCardComponent } from './training/components/training-course-card/training-course-card.component';
import { TrainingTabsComponent } from './training/components/training-tabs/training-tabs.component';
import { TrainingDetailsListComponent } from './training/components/training-details-list/training-details-list.component';
import { TrainingSectionBankComponent } from './training/components/training-section-bank/training-section-bank.component';
import { TrainingIframeComponent } from './training/components/training-iframe/training-iframe.component';
import { TrainingBannerComponent } from './training/components/training-banner/training-banner.component';
import { TrainingMoodleComponent } from './training/components/training-moodle/training-moodle.component';
import { TrainingDetailsBannerComponent } from './training/components/training-details-banner/training-details-banner.component';
import { TrainingCardV1Component } from './training/components/training-card-v1/training-card-v1.component';
import { TrainingSectionListV1Component } from './training/components/training-section-list-v1/training-section-list-v1.component';
import { TrainingWebinarsComponent } from './training/components/training-webinars/training-webinars.component';
import { TrainingVirtualCoursesComponent } from './training/components/training-virtual-courses/training-virtual-courses.component';
import { TrainingVirtualCoursesSliderComponent } from './training/components/training-virtual-courses-slider/training-virtual-courses-slider.component';
import { TrainingCourseComponent } from './training/components/training-course/training-course.component';
import { TrainingDownloadsComponent } from './training/components/training-downloads/training-downloads.component';

@NgModule({
  declarations: [
    TrainingCardComponent,
    TrainingComponent,
    TrainingDetailComponent,
    TrainingSectionListComponent,
    TrainingCardComponent,
    TrainingBadgeComponent,
    TrainingCourseCardComponent,
    TrainingTabsComponent,
    TrainingDetailsListComponent,
    TrainingSectionBankComponent,
    TrainingIframeComponent,
    TrainingBannerComponent,
    TrainingMoodleComponent,
    TrainingDetailsBannerComponent,
    TrainingCardV1Component,
    TrainingSectionListV1Component,
    TrainingWebinarsComponent,
    TrainingVirtualCoursesComponent,
    TrainingVirtualCoursesSliderComponent,
    TrainingCourseComponent,
    TrainingDownloadsComponent,
  ],
  imports: [
    AngularSvgIconModule,
    ClipboardModule,
    CommonModule,
    GoogleMapsModule,
    IconSpriteModule,
    ImageCropperModule,
    IntranetRoutingModule,
    LazyLoadImageModule,
    NgbPopoverModule,
    SharedModule,
    SlickCarouselModule,
    NgbCarouselModule,
    LayoutModule,
  ],
  exports: [TrainingCardComponent, TrainingSectionListComponent, TrainingCardV1Component, TrainingTabsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IntranetModule {}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'yevo-live-webinar-banner',
  templateUrl: './live-webinar-banner.component.html',
  styleUrls: ['./live-webinar-banner.component.scss'],
})
export class LiveWebinarBannerComponent implements OnInit {
  @Input() webinar!: {
    slug: string;
    title: string;
    description: string;
  };

  constructor(private router: Router) {}

  ngOnInit(): void {
    //Do nothing
  }

  goToWebinar() {
    this.router.navigate(['/capacitaciones', this.webinar.slug]);
  }

  truncateStringWithEllipsis(text: string, type: string = 'title'): string {
    if (text.trim().length === 0) {
      return '';
    }
  
    const windowWidth = window.innerWidth;
    let maxLength = 0;
  
    const maxLengthMobile = type === 'title' ? 40 : 47;
    const maxLengthDesktop = type === 'title' ? 96 : 70;
    const maxLengthLaptop = type === 'title' ? 40 : 50;
  
    if (windowWidth >= 750 && windowWidth <= 1088) {
      maxLength = maxLengthLaptop;
    } else {
      maxLength = windowWidth < 750 ? maxLengthMobile : maxLengthDesktop;
    }
  
    if (text.length <= maxLength) {
      return text;
    } else {
      const truncatedText = text.slice(0, maxLength);
      return truncatedText + '...';
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-new-banner',
  templateUrl: './new-banner.component.html',
  styleUrls: ['./new-banner.component.scss']
})
export class NewBannerComponent implements OnInit {

  constructor(private dataLayerService: DataLayerService) {
  }

  @Input() origin: string = '';
  @Input() imageDesktop: string = '';
  @Input() imageMobile: string = '';
  @Input() urlWhatsapp: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() sparkLeftMobile: string = '';
  @Input() sparkRightMobile: string = '';
  @Input() sparkLeftDesktop: string = '';
  @Input() sparkRightDesktop: string = '';
  community = '';

  ngOnInit(): void {
    this.community = this.origin === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
  }

  openLink(url: string): void {
    this.dataLayerService.clickCommunity(this.community, 'Únete a la comunidad de WhatsApp', 'Seleccionar', 1);
    window.open(url, '_blank');
  }

}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONST_COMMUNITIES_CODE, TOOLS_VALUES } from '@shared/constants/communities.constants';
import { TOOLS_ICONS } from '@shared/data/communities/tools-icons.json';
import { ToolResponse } from '@shared/interfaces/frontend/communities';
import { CommunityService } from '@shared/services/community.service';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-tool-page',
  templateUrl: './tool-page.component.html',
  styleUrls: ['./tool-page.component.scss'],
})
export class ToolPageComponent implements OnInit {
  digital: boolean = false;
  finance: boolean = true;
  parentRoute = '';
  communityId = 0;
  sectionTools: { [key: string]: ToolResponse[] } = {};
  toolsSelected!: ToolResponse[];
  breadcrumbs = [
    { pageName: 'Home', url: ['..'], active: true },
    { pageName: 'Herramientas', url: null, active: false },
  ];
  actionGetInto = TOOLS_VALUES.actionGetInto;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private communityService: CommunityService,
    private dataLayerService: DataLayerService,
    private http: HttpClient
  ) {}

  async ngOnInit(): Promise<void> {
    this.parentRoute = this.activatedRoute.parent?.routeConfig?.path ?? '';
    this.communityId =
      this.parentRoute === 'emprende-mujer'
        ? CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID
        : CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_ID;
    const arrTools = await this.communityService.getToolsByCommunityId(this.communityId).toPromise();
    arrTools.sort((a, b) => (a.numberOrder ?? 0) - (b.numberOrder ?? 0));
    arrTools.forEach((t) => {
      if (this.sectionTools[t.section]) {
        this.sectionTools[t.section].push({
          ...t,
          iconPath: TOOLS_ICONS.find((e) => e.name.toLowerCase() === t.title.toLowerCase())?.img, 
        });
      } else {
        this.sectionTools[t.section] = [
          {
            ...t,
            iconPath: TOOLS_ICONS.find((e) => e.name.toLowerCase() === t.title.toLowerCase())?.img,
          },
        ];
      }
    });
    this.toolsSelected = this.sectionTools[this.digital ? 'Digitales' : 'Financieras'];
  }

  goHome(): Promise<boolean> {
    return this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  getDigital() {
    this.digital = true;
    this.finance = false;
    this.toolsSelected = this.sectionTools[this.digital ? 'Digitales' : 'Financieras'];
    if (this.parentRoute === 'emprende-mujer') {
      this.dataLayerService.virtualEventEM('Filtrar herramientas: Digitales', 17);
    } else {
      this.dataLayerService.virtualEventBodegueros('Filtrar herramientas: Digitales', 16);
    }
  }

  getFinance() {
    this.digital = false;
    this.finance = true;
    this.toolsSelected = this.sectionTools[this.digital ? 'Digitales' : 'Financieras'];
    if (this.parentRoute === 'emprende-mujer') {
      this.dataLayerService.virtualEventEM('Filtrar herramientas: Financieras', 17);
    } else {
      this.dataLayerService.virtualEventBodegueros('Filtrar herramientas: Financieras', 16);
    }
  }
  
  evaluateAction(url: string, fileName: string, typeAction:string | undefined):void {
    if(typeAction ===TOOLS_VALUES.actionGetInto){
      this.goToToolPage();
      return;
    }
    this.downloadFile(url, fileName);
  }
  
  downloadFile(url: string, fileName: string) {
    if (this.parentRoute === 'emprende-mujer') {
      this.dataLayerService.virtualEventEM(`Descargar: ${fileName}`, 17);
    } else {
      this.dataLayerService.virtualEventBodegueros(`Descargar: ${fileName}`, 16);
    }
    const arrUrl = url.split('.');
    const extension = arrUrl[arrUrl.length - 1];
    if (extension === 'pdf') {
      this.http.get(url, { responseType: 'blob' }).subscribe((data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName + '.' + extension;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      });
    } else {
      window.open(url, '_blank');
    }
  }

  goToToolPage(): void {
    if (this.parentRoute === 'emprende-mujer') {
      this.router.navigate(['nuevo-intranet/comunidades/emprende-mujer', 'utility-calculation']);
    } else {
      this.router.navigate(['nuevo-intranet/comunidades/bodegueros', 'utility-calculation']);
    }
  }
}

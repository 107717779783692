import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'yevo-intranet-tab',
  templateUrl: './intranet-tab.component.html',
  styleUrls: ['./intranet-tab.component.scss'],
})
export class IntranetTabComponent implements OnInit {
  @Input() title: string = '';
  @Input() link: any = [];

  constructor() {
    //DO NOTHING
  }

  ngOnInit(): void {
    //DO NOTHING
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'yevo-home-app-mobil',
  templateUrl: './home-app-mobil.component.html',
  styleUrls: ['./home-app-mobil.component.scss']
})
export class HomeAppMobilComponent implements OnInit {
  sparkLeftMobile = '/assets/img/home/left-mobile.svg';
  sparkRightMobile = '/assets/img/home/right-mobile.svg';
  sparkLeftDesktop = '/assets/img/home/left-mobile.svg';
  sparkRightDesktop = '/assets/img/home/right-mobile.svg';
  @Input() isInternal: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  registerEvent(value:string): void {
    
  }

}

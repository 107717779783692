import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlliedDetailsComponent } from './allied-details/allied-details.component';
import { AlliedComponent } from './allied.component';
import { AlliedResolver } from './allied.resolver';

const routes: Routes = [
  {
    path: '',
    component: AlliedComponent,
    resolve: { alliedResolved: AlliedResolver },
  },
  {
    path: 'detalle/:slug',
    component: AlliedDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AlliedRoutingModule {}

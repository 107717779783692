import { KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'yevo-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  @Input() keyValue!: KeyValue<string, number>;
  @Input() checked?: boolean;
  @Input() isDisabled?: boolean;
  @Input() label?: string;
  @Output() onCheckedChanged = new EventEmitter<void>();

  onCheckedChange(event: any): void {
    this.onCheckedChanged.emit(event);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-card-allied',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() allied!: any;
  @Output() registerClick = new EventEmitter<boolean>(false);

  style = 'card-allied-discount';
  isLogged: boolean = false;

  constructor(private router: Router, private authService: AuthService, private dataLayerService: DataLayerService) {
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLogged = true;
      }
    });
  }

  ngOnInit(): void {
    this.getLogo();
  }

  getLogo() {
    if (this.allied?.textLabel == 'Gratis') {
      this.style = 'card-allied-free';
    }
  }

  link() {
    this.registerClick.emit(true);
    if (this.isLogged) this.dataLayerService.selectedAlliedMainPageOptions(2, this.allied.title);
  }

  registerOnClick() {
    this.registerClick.emit(true);
  }
}

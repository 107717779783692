import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ILoanRequestCardInfo {
  topMessage: string;
  icon: string;
  title: string;
  message: string;
  linkLabel: string;
  linkLabelMobile: string;
  link: string[];
  amountRequested?: number;
  amountSuggested?: number;
  dataLayerTitle?: string;
  typePreApproved: number;
  footer?: string;
}

@Component({
  selector: 'yevo-loan-request-card',
  templateUrl: './loan-request-card.component.html',
  styleUrls: ['./loan-request-card.component.scss'],
})
export class LoanRequestCardComponent implements OnInit {
  @Input() cardInfo!: ILoanRequestCardInfo;
  @Input() type:
    | 'recommended'
    | 'evaluation'
    | 'seed'
    | 'digital-approved'
    | 'green-digital-approved'
    | 'yape'
    | 'second-provision'
    | 'cde'
    | 'first-credit' = 'recommended';
  @Input() aroundBorder: boolean = false;
  @Output() cardClicked = new EventEmitter<ILoanRequestCardInfo>();

  handleCardClicked($event: Event) {
    this.cardClicked.emit(this.cardInfo);
  }

  ngOnInit(): void {
    //DO NOTHING
  }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { imagesallied } from '@shared/data/dashboard/benefit.json';
import { DataLayerService } from '@shared/services/data-layer.service';
import { isSafariBrowser } from '@shared/utils/validators.utils';

@Component({
  selector: 'yevo-banner-allied',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  images: any = imagesallied;
  @Input() loader: boolean = false;

  constructor(config: NgbCarouselConfig, private router: Router, private DataLayerService: DataLayerService) {
    config.showNavigationIndicators = true;
    config.showNavigationArrows = true;
  }

  goToBanner(banner: any) {
    if (banner.external) {
      if (isSafariBrowser()) {
        window.location.href = banner.routerLink;
      } else {
        window.open(banner.routerLink, '_blank');
      }
    } else {
      this.router.navigate([banner.routerLink]);
    }
  }

  selectedLinkCard(title: string) {
    this.DataLayerService.selectedCardBannerDashboard3(title);
  }

  selectButtonBanner(code: string, title: string) {
    this.DataLayerService.selectedButtonAllDashboard3(code, title);
  }
}

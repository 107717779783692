import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CommunityService } from '@shared/services/community.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { RecommendService } from '@shared/services/recommend.service';

import modalWelcomeDataJson from '@shared/data/recommend/modal-welcome-data.json';
import { ModalWelcome, UserCommunity } from '@shared/interfaces/frontend/communities';
import { DataLayerService } from '@shared/services/data-layer.service';
import { VIRTUAL_EVENT_VALUES_RE } from '@shared/constants/recommend.constants';

@Component({
  selector: 'modal-welcome-recommend',
  templateUrl: './modal-welcome-recommend.component.html',
  styleUrls: ['./modal-welcome-recommend.component.scss']
})
export class ModalWelcomeRecommendComponent implements OnInit {

 timesClicked = 0;
 dataWelcomeRecommend!: ModalWelcome;
 @Input() userId!:number; 
 @Input() userRecommend!:number; 
 @Input() modalView!:number; 

 user!: any;
 userCommunity: UserCommunity = {
  name: '',
  userId: 0,
  cellPhone: '',
  email: '',
  communityId: 0,
};
 constructor(public activeModal: NgbActiveModal,private recommendServices: 
  RecommendService, private router: Router,private localStorageService: LocalStorageService,
   private dataLayerService: DataLayerService) { }

  ngOnInit(): void {
    this.dataWelcomeRecommend = modalWelcomeDataJson.modalWelcomeRecommend;
    const userProfile = this.localStorageService.get('userProfile');
    if (userProfile) {
      this.user = JSON.parse(userProfile);
    }
    this.modalView = 1;
    this.recommendServices.updateRecommendProgramByUserModal(this.userId, this.modalView).subscribe();
  }

  redirectAndClose(url: string, action: string, code: number) {
    this.closeModalByButton();
    this.router.navigate([`${url}`]);
    this.dataLayerService.virtualEventEnlaceRE(VIRTUAL_EVENT_VALUES_RE.selectApplyLoanRecommendProgramPage, 
      VIRTUAL_EVENT_VALUES_RE.selectSectionModalWelcome);
  }
  
  closeModal():void {
    this.timesClicked++;
    if (this.timesClicked>1) {
      this.activeModal.close();
    } else {
        return;
    }
  }

  closeModalByButton(){
    this.activeModal.close();
  }
}
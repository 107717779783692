import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IQuestionnaireFinalAnswerList } from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class BusinessDiagnosisService {
  private readonly directoryBase = `${environment.apiBaseUrl}/api-directory`;

  constructor(private http: HttpClient) {}

  public getSurveys() {
    const endpoint = `${this.directoryBase}/survey/all`;
    return this.http.get(endpoint);
  }

  public getQuestionnaire(surveyId: number) {
    const endpoint = `${this.directoryBase}/survey/${surveyId}`;
    return this.http.get(endpoint);
  }

  public sendQuestionnaireAnswers(answerList: IQuestionnaireFinalAnswerList) {
    const endpoint = `${this.directoryBase}/survey`;
    return this.http.post(endpoint, answerList);
  }

  public getLatestResult(parentSurveyId: number) {
    const endpoint = `${this.directoryBase}/survey/user/latest/${parentSurveyId}`;
    return this.http.get(endpoint);
  }

  public getSuggestions(userSurveyId: number) {
    const endpoint = `${this.directoryBase}/survey/user/suggestion/${userSurveyId}`;
    return this.http.get(endpoint);
  }

  public completeSurvey(parentSurveyId: number) {
    const endpoint = `${this.directoryBase}/survey/user/${parentSurveyId}/complete`;
    return this.http.put(endpoint, null);
  }
  
  public updateTopicAsSeen(surveyTopicId: string) {
    const endpoint = `${this.directoryBase}/survey/user/topic/${surveyTopicId}`;
    return this.http.put(endpoint, {});
  }

  public updateCardAllyAsSeen(surveyCardAllyId: string) {
    const endpoint = `${this.directoryBase}/survey/user/ally/${surveyCardAllyId}`;
    return this.http.put(endpoint, {});
  }

  public updateCourseAsSeen(surveyCourseId: string) {
    const endpoint = `${this.directoryBase}/survey/user/course/${surveyCourseId}`;
    return this.http.put(endpoint, {});
  }
}

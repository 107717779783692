<fieldset [formGroup]="frmGeneralInfo" class="mt-4">
  <legend>Información general del negocio</legend>
  <div class="form-row">
    <div class="col-12 title-advice">
      <div class="image">
        <img class="bg-image" src="/assets/icons/informacion-circulo.svg" alt="circulo" />
      </div>
      <span>
        Completa el nombre y la descripción de tu negocio
        <strong>usando la primera letra mayúscula y luego minúscula.</strong> Ejemplo: "La tienda de Yevo"
      </span>
    </div>
    <div class="form-group col-12">
      <label>Nombre de tu negocio*</label>
      <input
        type="text"
        class="form-control"
        placeholder="Ingresa el nombre de tu negocio"
        formControlName="businessName"
        maxlength="100"
        (change)="avoidEmpty($event, 'businessName')"
        [ngClass]="{
          'border-error':
            (errorCode === 'textContentModerator' && errorMsgType === 'business') ||
            (frmGeneralInfo.get('businessName')?.errors?.required && showError('businessName')) ||
            frmGeneralInfo.get('businessName')?.errors?.pattern
        }"
      />
      <label class="form-inline">
        <div *ngIf="showError('businessName')" class="text-error">
          <div *ngIf="frmGeneralInfo.get('businessName')?.errors?.required">Nombre de tu negocio es requerido.</div>
          <div *ngIf="frmGeneralInfo.get('businessName')?.errors?.pattern">Ingrese un nombre de negocio valido.</div>
        </div>
      </label>
    </div>
    <div class="form-group col-12">
      <label>Descripción de tu negocio*</label>
      <textarea
        class="form-control"
        rows="4"
        placeholder="
Aquí puedes poner una breve descripción sobre lo que ofrece tu negocio. 
Ejemplo: Nos dedicamos a la venta de productos de vestir para niños, niñas y jóvenes."
        formControlName="description"
        (change)="avoidEmpty($event, 'description')"
        [ngClass]="{
          'border-error':
            (errorCode === 'textContentModerator' && errorMsgType === 'description') ||
            frmGeneralInfo.get('description')?.errors?.pattern ||
            (frmGeneralInfo.get('description')?.errors?.required && showError('description'))
        }"
        maxlength="350"
        (change)="avoidEmpty($event, 'description')"
      ></textarea>
      <label class="form-inline">
        <div *ngIf="showError('description')" class="text-error">
          <div *ngIf="frmGeneralInfo.get('description')?.errors?.required">
            Breve descripción del negocio es requerida.
          </div>
          <div *ngIf="frmGeneralInfo.get('description')?.errors?.pattern">Ingrese una descripción valida.</div>
        </div>
      </label>
    </div>
    <div class="form-group col-12">
      <label>RUC (Opcional)</label>
      <input type="text" class="form-control" placeholder="Ingresa aquí tu RUC" formControlName="ruc" maxlength="11" />
      <label class="form-inline">
        <div *ngIf="showError('ruc')" class="text-error">
          <div *ngIf="frmGeneralInfo.get('ruc')?.errors?.pattern">RUC tiene formato inválido.</div>
        </div>
      </label>
    </div>
  </div>
</fieldset>

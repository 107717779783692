import { Pipe, PipeTransform } from '@angular/core';
import { PricesMarketsResponse } from '@shared/interfaces/frontend/communities';

@Pipe({
  name: 'searchProduct'
})
export class SearchProductPipe implements PipeTransform {

  transform(products: PricesMarketsResponse[], search: string): any[] {

    if (!products || !search) {
      return products;
    }
    search = search.toLowerCase();
    products= products.filter(m =>
      m.productName.toLowerCase().includes(search)
    );
    if(products.length==0){
      return [{productName: "No hay alternativas.",state:-1 }];
    }
    return products;
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CertifiedResponse,
  Forum,
  SpecializationItem,
  UserLevel,
  VideoViewedResponse,
  WebinarResponse,
  WebinarViewedResponse,
} from '@shared/interfaces/frontend/communities';
import { Subscription } from 'rxjs';
import { CommunityService } from '@shared/services/community.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { ModalQuestionComponent } from '../modal-question/modal-question.component';
import { ModalAnswerComponent } from '../modal-answer/modal-answer.component';
import { CONST_COMMUNITIES_CODE, REFERRED_EVENT_VALUES, REFERRED_PROGRAM_VALUES, TYPE_SCORE_VALUES } from '@shared/constants/communities.constants';
import { UserProfile } from '@shared/models/UserProfileModel';
import { ModalTestLevelComponent } from '../modal-test-level/modal-test-level.component';
import { DataLayerService } from '@shared/services/data-layer.service';
import { MailchimpTransactionalService } from '@shared/services/mailchimp-transactional.service';
import { LoaderService } from '@shared/services/loader.service';
import { ITabItem } from '@shared/interfaces';
import specializationDataJson from '@shared/data/communities/specialization-data.json';

@Component({
  selector: 'yevo-landing-women',
  templateUrl: './landing-women.component.html',
  styleUrls: ['./landing-women.component.scss'],
})
export class LandingWomenComponent implements OnInit, OnDestroy {
  dataForum!: Forum[];
  selectedDataForum!: Forum;
  forumId = 0;
  private subscription = new Subscription();
  COMMUNITY_ID: number = CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID;
  COMMUNITY_NAME = 'Emprende Mujer';
  user!: UserProfile;
  userLevel: UserLevel = {
    idLevel: 0,
    userId: 0,
    flagStatus: 'N',
  };
  levelUser!: number;
  closeResult = '';

  titleBenefits = '¡Creces tú, crecen todas!';
  descriptionBenefits = 'Descubre todos los beneficios que tiene esta comunidad para tu emprendimiento.';
  icons = [
    {
      name: 'Comunidad de Whatsapp',
      icon: '/assets/icons/communities/whatsapp-icon.svg',
      url: '',
      section: 'whatsapp-section',
    },
    {
      name: 'Productos financieros',
      icon: '/assets/icons/communities/producto-financiero-icon.svg',
      url: '',
      section: 'services',
    },
    {
      name: 'Videos prácticos',
      icon: '/assets/icons/communities/videos-icon.svg',
      url: '',
      section: 'educational-content',
    },
    {
      name: 'Capacitaciones virtuales',
      icon: '/assets/icons/communities/capacitaciones-icon.svg',
      url: '',
      section: 'educational-content',
    },
  ];
  source = 'emprende-mujer';
  specializationItems!:SpecializationItem[];
  webinarViewed: null | WebinarViewedResponse[] = null;
  videosViewed: null | VideoViewedResponse[] = null;
  tabItems = [
    {
      label: 'Webinars',
      icon: 'icon',
      active: true,
      code: 'webinars',
    },
    {
      label: 'Videos',
      icon: 'icon',
      active: false,
      code: 'videos',
    },
    {
      label: 'Artículos',
      icon: 'icon',
      active: false,
      code: 'articulos',
    },
  ];
  selectedTab: ITabItem = {
    label: 'Webinars',
    icon: 'icon',
    active: true,
    code: 'webinars',
  };
  webinar!: WebinarResponse;
  certificates: CertifiedResponse[] = [];

  constructor(
    private communityService: CommunityService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private modalService: NgbModal,
    private dataLayerService: DataLayerService,
    private mailchimpTransactionalService: MailchimpTransactionalService,
    private loaderService: LoaderService
  ) {}

  private async loadData(): Promise<void> {
    try {
      const response = await this.communityService.getForum(this.COMMUNITY_ID).toPromise();
      this.dataForum = response;
      this.dataForum.forEach((item) => {
        item.communityId = this.COMMUNITY_ID;
        item.dueDate = format(new Date(item.creationDate), 'd MMM');
        item.answer.sort((a, b) => {
          const dateA = new Date(a.creationDate).getTime();
          const dateB = new Date(b.creationDate).getTime();
          return dateA - dateB;
        });
        item.answer.reverse();
      });
      this.dataForum.sort((a, b) => {
        if (a.answer.length !== b.answer.length) {
          return a.answer.length - b.answer.length;
        } else {
          const dateA = new Date(a.creationDate).getTime();
          const dateB = new Date(b.creationDate).getTime();
          return dateA - dateB;
        }
      });
      this.dataForum.reverse();
      this.selectedDataForum = this.dataForum[0];
    } catch (error) {
      console.error(error);
    }
  }

  async ngOnInit(): Promise<void> {
    this.specializationItems = specializationDataJson.Woman;
    this.loaderService.setIsLoadingLazyPage(true);
    this.webinar = await this.communityService.getLiveIfExists(this.COMMUNITY_ID).toPromise();
    await this.loadCommunities();

    const userString = this.localStorageService.get('userProfile');
    if (userString) {
      this.user = JSON.parse(userString) as UserProfile;
      this.certificates = await this.communityService
        .getCertifiedByUserAndCommunity(this.user.documentNumber, this.COMMUNITY_ID)
        .toPromise();
      const resultLevelUser = await this.communityService
        .getLevelUserByCommunityId(this.user.userId, this.COMMUNITY_ID)
        .toPromise();
      if (resultLevelUser.flagStatus === 'A' && resultLevelUser.idLevel) {
        this.dataLayerService.pushLevel('nivelEmprende', 'Nivel ' + resultLevelUser.idLevel);
      } else {
        this.dataLayerService.pushLevel('nivelEmprende', 'Sin clasificación');
      }
      this.userLevel = resultLevelUser;
      if (this.userLevel.flagStatus == null) {
        const { name, email } = this.user;
        this.mailchimpTransactionalService
          .sendEmailTemplate('emprende-mujer', email, name)
          .catch((err: any) => console.error(err));
        this.checkModal();
      }
      this.validReferred();

      this.communityService.getWebinarsViewedByUserid(this.user.userId).subscribe(
        (response) => (this.webinarViewed = response),
        (error) => {
          console.error('getVideosViewedByUserid ERROR: ', error);
        }
      );

      this.communityService.getVideosViewedByUserid(this.user.userId).subscribe(
        (response) => (this.videosViewed = response),
        (error) => {
          console.error('getVideosViewedByUserid ERROR: ', error);
        }
      );
    }

    await this.updateLevelUser();
    this.loaderService.setIsLoadingLazyPage(false);
  }

  openPopupQuestion(): void {
    const modalRef = this.modalService.open(ModalQuestionComponent);
    modalRef.componentInstance.communityId = this.COMMUNITY_ID;
    modalRef.result
      .then((result) => {
        this.loadData();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  openPopupAnswer(forumId: number, question: string, userName: string): void {
    const modalRef = this.modalService.open(ModalAnswerComponent);
    modalRef.componentInstance.forumId = forumId;
    modalRef.componentInstance.userName = userName;
    modalRef.componentInstance.question = question;
    modalRef.result
      .then((result) => {
        this.loadData();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  goToNextStep(): Promise<boolean> {
    return this.router.navigate(['nuevo-intranet/comunidades/emprende-mujer', 'foro'], {
      state: {
        data: this.dataForum,
        communityId: this.COMMUNITY_ID,
      },
    });
  }

  goToQuestion(forum: Forum): Promise<boolean> {
    const { forumId } = forum;
    return this.router.navigate(['nuevo-intranet/comunidades/emprende-mujer/foro/id', forumId], {
      state: { data: forum, communityId: this.COMMUNITY_ID },
    });
  }

  goToSection(sectionId: string): void {
    if (sectionId === 'training') {
      this.dataLayerService.clickCommunity('Emprende Mujer', 'Quiero subir mi nivel', 'Seleccionar', 9);
    }
    const section = document.getElementById(sectionId);
    let offset;

    const vh = window.innerHeight;
    if (vh < 1200) {
      offset = 75;
    } else {
      offset = 100;
    }

    if (section) {
      const scrollOptions = {
        top: section.getBoundingClientRect().top + window.scrollY - offset,
        behavior: 'smooth' as ScrollBehavior,
      };
      window.scrollTo(scrollOptions);
    }
  }

  openLink(url: string): void {
    window.open(url, '_blank');
  }

  onLink(link: string[], params = {}): void {
    if (Object.keys(params).length) {
      this.router
        .navigate(link, { queryParams: params, queryParamsHandling: 'merge' })
        .then((result) => {
          console.info(result);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.router
        .navigate(link, { queryParamsHandling: 'merge' })
        .then((result) => {
          console.info(result);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  private async loadCommunities(): Promise<void> {
    try {
      this.COMMUNITY_ID = CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID;
      this.COMMUNITY_NAME = 'Emprende Mujer';
      await this.loadData();
    } catch (error) {
      console.error(error);
    }
  }

  private getLevelUserByCommunityId(): void {
    const userString = this.localStorageService.get('userProfile');
    if (userString) {
      this.user = JSON.parse(userString) as UserProfile;
      this.subscription.add(
        this.communityService.getLevelUserByCommunityId(this.user.userId, this.COMMUNITY_ID).subscribe(
          (response) => {
            this.userLevel = response;
            if (this.userLevel.flagStatus == null) {
              this.checkModal();
            }
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.updateLevelUser();
          }
        )
      );
    }
  }

  checkModal(): void {
    if (this.userLevel.userId === 0 || (this.userLevel.userId !== 0 && this.userLevel.flagStatus === 'I')) {
      const modalRef = this.modalService.open(ModalTestLevelComponent);
      modalRef.componentInstance.sourcePage = this.COMMUNITY_NAME;
      modalRef.componentInstance.communityId = this.COMMUNITY_ID;
      modalRef.result
        .then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
            if (result.includes('Closed')) {
              this.getLevelUserByCommunityId();
            } else {
              if (this.userLevel.userId === 0) this.saveUserLevel();
            }
          },
          (reason) => {
            this.closeResult = `${this.getDismissReason(reason)}`;
            if (this.userLevel.userId === 0) this.saveUserLevel();
          }
        )
        .catch((error) => {
          console.error(error);
        });
    }
  }
  initModalDataLayer(): void {
    this.dataLayerService.clickCommunity('Emprende Mujer', 'Iniciar', 'Seleccionar', 9);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private saveUserLevel(): void {
    const userString = this.localStorageService.get('userProfile');
    if (userString) {
      this.user = JSON.parse(userString) as UserProfile;
      this.userLevel.userId = this.user.userId;
      this.userLevel.idLevel = 1;
      this.userLevel.flagStatus = 'I';
      this.userLevel.communityId = this.COMMUNITY_ID;
      this.subscription.add(
        this.communityService.saveLevelUser(this.userLevel).subscribe(
          (response) => {
            this.userLevel = response;
          },
          (error) => {
            console.error(error);
          }
        )
      );
    }
  }

  private async updateLevelUser(): Promise<void> {
    const updateLevelUserResponse = await this.communityService
      .updateLevelUser(this.user.userId, this.COMMUNITY_ID)
      .toPromise();
    const updateLevelUserResponseCheck = await this.communityService
      .updateLevelUser(this.user.userId, this.COMMUNITY_ID)
      .toPromise();
    let thereAreMore = true;
    let prevLastValue: UserLevel = JSON.parse(JSON.stringify(updateLevelUserResponse));
    let lastValue: UserLevel = JSON.parse(JSON.stringify(updateLevelUserResponseCheck));

    while (prevLastValue.idLevel <= lastValue.idLevel && thereAreMore) {
      try {
        const response = await this.communityService.updateLevelUser(this.user.userId, this.COMMUNITY_ID).toPromise();
        prevLastValue = JSON.parse(JSON.stringify(lastValue));
        lastValue = JSON.parse(JSON.stringify(response));
        if (lastValue.idLevel === prevLastValue.idLevel) {
          thereAreMore = false;
        }
      } catch (error) {
        console.error('error: ', error);
      }
    }
    if (lastValue.flagStatus && lastValue.flagStatus !== 'I' && lastValue.userId !== 0) {
      this.userLevel = lastValue;
    }
    this.levelUser = lastValue.idLevel;
  }
  optionSelectedBlockFinancing(option: string): void {
    this.dataLayerService.clickCommunity('Emprende Mujer', option, 'Seleccionar', 3);
  }
  goToCuentasAhorro(): void {
    this.router.navigate(['cuenta-ahorros/landing'], {
      queryParams: {
        utm_source: 'comunidad',
        utm_medium: 'emprendemujer',
        utm_campaign: 'seccioninterna',
      },
    });
  }
  async goToCreditoMujer() {
    const belongsToCreditoMujer = await this.communityService.checkIfCreditoMujer(this.user.documentNumber).toPromise();
    if (belongsToCreditoMujer.length > 0) {
      this.router.navigate(['financiamiento/filtro-financiamiento'], {
        queryParams: {
          aliado: 'comunidademprendemujer',
          utm_source: 'comunidad',
          utm_medium: 'emprendemujer',
          utm_campaign: 'seccioninterna',
        },
      });
    } else {
      this.router.navigate(['financiamiento/filtro-financiamiento']);
    }
  }
  handleSelectedTab(value: ITabItem): void {
    this.selectedTab = value;
  }

  goToWebinar() {
    this.dataLayerService.virtualEventEM(`Seleccionar: ${this.webinar.title}`, 15);
    this.router.navigate(['/nuevo-intranet/comunidades/emprende-mujer/webinar-detalle/id/', this.webinar.id]);
  }

  truncateStringWithEllipsis(text: string, type: string = 'title'): string {
    if (text.trim().length === 0) {
      return '';
    }

    const windowWidth = window.innerWidth;
    let maxLength = 0;

    const maxLengthMobile = type === 'title' ? 40 : 47;
    const maxLengthDesktop = type === 'title' ? 96 : 70;
    const maxLengthLaptop = type === 'title' ? 40 : 50;

    if (windowWidth >= 750 && windowWidth <= 1088) {
      maxLength = maxLengthLaptop;
    } else {
      maxLength = windowWidth < 750 ? maxLengthMobile : maxLengthDesktop;
    }

    if (text.length <= maxLength) {
      return text;
    } else {
      const truncatedText = text.slice(0, maxLength);
      return truncatedText + '...';
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  validReferred(): void {
    this.communityService.getReferredByUser(this.user.userId).subscribe(response => {
      if (response.countDone == REFERRED_PROGRAM_VALUES.stateReferredDefault) {
        this.communityService.updateReferredProgram(response.userReferred, TYPE_SCORE_VALUES.newRegister).subscribe(resp=>{});
        this.communityService.updateStateReferred(this.user.userId,REFERRED_EVENT_VALUES.registerCommunities).subscribe(resp=>{});
      }
    });
  }
}

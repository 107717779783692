import { Injectable } from '@angular/core';
import {  Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunityNotificationService {
  private visibilityChangeSubject = new Subject<boolean>();

  constructor() {
    document.addEventListener('visibilitychange', () => {
      this.visibilityChangeSubject.next(this.isHidden());
    });
  }

  isHidden(): boolean {
    return document.hidden;
  }

  getVisibilityChange() {
    return this.visibilityChangeSubject.asObservable();
  }

  getPushNotificationData(): Observable<any> {
    return new Observable((observer) => {
      caches
        .open('notification-storage')
        .then((cache) => cache.match('messagePush'))
        .then((response) => {
          if (response) {
            return response.json();
          } else {
            throw new Error('El elemento no se encontró en la caché.');
          }
        })
        .then((data) => {
          observer.next(data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  clearPushNotificationData(): Observable<void> {
    return new Observable((observer) => {
      caches
        .open('notification-storage')
        .then((cache) => {
          return cache.delete('messagePush');
        })
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }
}

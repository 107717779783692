<div class="">
    <div class="banner_apps_mobile">
      <div class="banner__text">
        <h2 class="banner__title">Descarga la app</h2>
        <div class="banner_box">
          <img class="img-fluid spark-desktop" [src]="sparkLeftDesktop" alt="spark-left" />
          <img class="img-fluid spark-mobile" [src]="sparkLeftMobile" alt="spark-left" />
          <div class="communities-paragraph"><p class="text-center title-center">MIBANCO MÓVIL</p></div>
          <img class="img-fluid spark-mobile" [src]="sparkRightMobile" alt="spark-right" />
          <img class="img-fluid spark-desktop" [src]="sparkRightDesktop" alt="spark-right" />
        </div>
        <h3 class="banner__subtitle">Es gratis, rápido y seguro.</h3>
      </div>
      <div class="banner__images">
        <div class="img-h01 _mobile"><img src="/assets/img/home/apps_btn.png" alt="apps" /></div>
        <div class=" _desktop " [ngClass]="isInternal? 'img-h002':'img-h02'">
            <img src="/assets/img/home/apps_btn_desktop.png" alt="apps" />
            <div class="title-footer">MIBANCO - BANCO DE LA MICROEMPRESA S.A. RUC 20382036655</div>
        </div>
        <div class="img-h03 _mobile"><img src="/assets/img/home/cell_app.png" alt="celular en mano" /></div>
        <div class="img-h04 _desktop" ><img src="/assets/img/home/cell_app_desktop.png" alt="celular en mano" /></div>
      </div>
    </div>
  </div>
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-specialization-card',
  templateUrl: './specialization-card.component.html',
  styleUrls: ['./specialization-card.component.scss'],
})
export class SpecializationCardComponent implements OnInit {
  @Input() item!: { image: string; image_mobile: string; title: string; description: string; url: string };
  constructor(
    private router: Router,
    private dataLayerService: DataLayerService,
    private activatedRoute: ActivatedRoute
  ) {}
  pathCommunity: string | undefined = '';
  communityNameDataLayer: string = '';

  ngOnInit(): void {
    this.pathCommunity = this.activatedRoute.parent?.routeConfig?.path;
    this.communityNameDataLayer = this.pathCommunity === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
  }

  goToWebinars(url: string) {
    this.pushDataLayer(url);
    this.router
      .navigate([`/nuevo-intranet/comunidades/${this.pathCommunity}/webinar`], {
        queryParams: {
          category: url,
        },
      })
      .catch((err) => console.error('err: ', err));
  }
  pushDataLayer(url: string): void {
    switch (url) {
      case 'especializacion-financiera':
        this.dataLayerService.clickCommunity(
          this.communityNameDataLayer,
          'Especialización Financiera',
          'Seleccionar',
          11
        );
        break;

      case 'especializacion-digital':
        this.dataLayerService.clickCommunity(this.communityNameDataLayer, 'Especialización Digital', 'Seleccionar', 11);
        break;
      case 'independencia-financiera':
        this.dataLayerService.clickCommunity(this.communityNameDataLayer, 'Independencia Financiera', 'Seleccionar', 11);
        break;
      case 'tiendas-virtuales':
        this.dataLayerService.clickCommunity(this.communityNameDataLayer, 'Tiendas Virtuales', 'Seleccionar', 11);
        break;
    }
  }
}

<fieldset>
  <legend>Imágenes para mostrar en el directorio</legend>
  <p class="mb-4 subtitle">
    Sube las mejores imágenes de tus productos y/o negocio (máx. 4). Te recomendamos que sean de
    <strong>alta calidad</strong> y que representen fielmente a tu negocio. <br />
    Mientras <strong>más imágenes</strong> subes, los clientes <strong>podrán apreciar mejor lo que ofreces</strong>.*
  </p>
  <div class="form-group">
    <div class="image-uploader-container">
      <label class="image-uploader">
        <input
          #fileInput
          type="file"
          multiple
          (change)="fileChangeEvent($event)"
          accept="image/png,image/jpeg"
          class="input-image"
        />
        <img src="assets/img/upload.png" alt="upload" />
        <p class="mt-2 img-title">¡Haz clic aquí y sube imágenes seleccionadas de tu negocio!</p>
        <p class="mt-2 img-subtitle">Recuerda que no deben pesar más de 5MB y deben ser rectangulares.</p>
      </label>
    </div>

    <label class="form-inline">
      <div *ngIf="errorMsg" class="text-error">
        <div [innerHTML]="errorMsg"></div>
      </div>
      <div *ngIf="amountErrorMsg" class="text-error">
        <div [innerHTML]="amountErrorMsg"></div>
      </div>
    </label>
  </div>
  <div>
    <div *ngFor="let image of images; index as i" class="row mt-3">
      <div class="col-4 col-md-3 col-lg-2">
        <img [src]="image.value" class="img-fluid img-loaded" alt="loaded" />
      </div>
      <div class="col d-flex flex-column justify-content-center align-items-start">
        <span class="d-block img-filename">{{ image.fileName }}</span>
        <button
          class="btn btn-link px-0"
          (click)="deleteSavedImage(image, i)"
          [ngClass]="{ 'd-none': this.directoryService.getImageProgress(i)?.uploading }"
        >
          <span class="icon">
            <svg-icon-sprite [src]="'close'" [width]="'0.8rem'" [height]="'0.6rem'"></svg-icon-sprite>
          </span>
          Eliminar foto
        </button>
        <div class="d-block w-100">
          <div
            *ngIf="
              i == this.directoryService.getImageProgress(i)?.id && this.directoryService.getImageProgress(i)?.uploading
            "
            class="d-flex align-items-center"
          >
            <div class="d-block progress w-50">
              <div
                class="progress-bar bg-success-bar"
                role="progressbar"
                attr.aria-valuenow="{{ directoryService.getImageProgress(i)?.progress }}"
                aria-valuemin="0"
                aria-valuemax="100"
                [ngStyle]="{ width: directoryService.getImageProgress(i)?.progress + '%' }"
              ></div>
            </div>
            <span class="d-block ml-2 progress-bar-text">{{ directoryService.getImageProgress(i)?.progress }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</fieldset>

import { Component, OnInit, ViewChild, Input, AfterViewInit,HostListener  } from '@angular/core';
import { SwiperOptions } from 'swiper';
import levelsInfo from '@shared/data/communities/levels-info.json';
import { SwiperComponent } from 'swiper/angular';
import { Level, ReferredProgram } from '@shared/interfaces/frontend/communities';
import { MEDAL_INDEX_MAP,REFERRED_PROGRAM_VALUES } from '@shared/constants/communities.constants';

@Component({
  selector: 'yevo-levels-info-section',
  templateUrl: './levels-info-section.component.html',
  styleUrls: ['./levels-info-section.component.scss']
})
export class LevelsInfoSectionComponent implements OnInit, AfterViewInit   {
  @Input() referredPrograma!:ReferredProgram;
  @Input() progressMedal!: string;
  levels: Level[] = [];
  swiperContainerWidth = '290px';
  isMobile!:boolean;
  config: SwiperOptions = {
    direction: 'horizontal',
    freeMode: true,
    slidesPerView: 1,
    spaceBetween: -16,
    initialSlide:0,
    navigation: {
      nextEl: '.swiper-button-level-next',
      prevEl: '.swiper-button-level-prev',
    },
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      334: {
        slidesPerView: 1,
        spaceBetween: -16,
      },
      380: {
        slidesPerView: 1,
        spaceBetween: -16,
      },
      450: {
        slidesPerView: 1,
        spaceBetween: -16,
      },
      550: {
        slidesPerView: 1,
        spaceBetween: -16,
      },
      600: {
        slidesPerView: 1.8,
        spaceBetween: -16,
      },
      690: {
        slidesPerView: 1,
        spaceBetween: -16,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: -16,
      },
      850: {
        slidesPerView: 1,
        spaceBetween: -16,
      },
      950: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      1050: {
        slidesPerView: 3.1,
        spaceBetween: 0,
      },
      1150: {
        slidesPerView: 3.1,
        spaceBetween: 0,
      },
      1250: {
        slidesPerView: 3.1,
        spaceBetween: 0,
      },
      1350: {
        slidesPerView: 3.1,
        spaceBetween: 0,
      },

    },
  };

  @ViewChild('swiperBenefit', { static: false }) swiperBenefit?: SwiperComponent;

  ngOnInit(): void {
    levelsInfo.forEach(objeto => {
      if (objeto.medal === this.progressMedal) {
          objeto.description = REFERRED_PROGRAM_VALUES.currentLevel;
      } else if (MEDAL_INDEX_MAP[objeto.medal] <= MEDAL_INDEX_MAP[this.progressMedal]) {
          objeto.description = REFERRED_PROGRAM_VALUES.unlockedthisLevel;
      }
  });
  this.levels = levelsInfo;
  this.checkIfMobile();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkIfMobile();
  }
  checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  ngAfterViewInit(): void {
    this.changeInitialSlide();
  }
  changeInitialSlide(): void {
    if (this.swiperBenefit && this.progressMedal in MEDAL_INDEX_MAP) {
      const index = MEDAL_INDEX_MAP[this.progressMedal];
      this.swiperBenefit.swiperRef.slideTo(index);
    }
  }
   
}
import { Component, Input, OnInit } from '@angular/core';
import { Banner, Training } from '@shared/interfaces/frontend/communities';
import { Router } from '@angular/router';
import { IMAGE_LIST_BODEGUEROS_WEBINARS } from '@shared/data/communities/banner-webinar-bodegueros.json';
import { IMAGE_LIST_EM_WEBINARS } from '@shared/data/communities/banner-webinar-em.json';
import { DataLayerService } from '@shared/services/data-layer.service';
import Section from '@shared/data/community/community-section.data.json';
import { CommunityService } from '@shared/services/community.service';
@Component({
  selector: 'yc-training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss'],
})
export class TrainingCardComponent implements OnInit {
  @Input()
  training: Training = {
    id: '',
    title: '',
    text: '',
    url: '',
    img: '',
    imgMobile: '',
    imgDesktop: '',
    communityId: 0,
    creationDate: '',
    viewed: false,
  };
  banners: Banner[] = IMAGE_LIST_BODEGUEROS_WEBINARS.concat(IMAGE_LIST_EM_WEBINARS);
  imgDefault = '';
  @Input()
  source = '';
  @Input()
  sourceClick = '';
  @Input()
  nameSectionInternal='';

  constructor(private router: Router, private dataLayerService: DataLayerService,private communityService:CommunityService) {}

  ngOnInit(): void {
    const communityId = this.source === 'emprende-mujer' ? 1 : 2;
    this.banners
      .filter((e) => e.communityId === communityId)
      .forEach((item) => {
        if (item.title === this.training.title) {
          this.training.img = item.img;
        }
      });
    if (this.source === 'emprende-mujer') {
      this.imgDefault = '/assets/img/communities/banner-em-webinar-default.png';
    } else {
      this.imgDefault = '/assets/img/communities/Tenemos_un_nuevo_taller_que_compartir_contigo.png';
    }
  }

  openDetailWebinar(training: Training): Promise<boolean> | void {
    const { id } = training;
    if (id == '22') {
      window.open(training.url, '_blank');
    } else {
      const community = this.source === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
      this.validDataLayerInternal(community, training.title, 'Ver webinar')
      this.communityService.sendVideoCommnunity(training);
      return this.source === 'emprende-mujer'
        ? this.router.navigate(['/nuevo-intranet/comunidades/emprende-mujer/webinar-detalle/id', id], {
            state: { data: training },
          })
        : this.router.navigate(['nuevo-intranet/comunidades/bodegueros/webinar-detalle/id', id], {
            state: { data: training },
          });
    }
  }

  validDataLayerInternal(communityName:string,categoryName:string, label:string){
    if(this.nameSectionInternal==='comunidad'){
      this.dataLayerService.clickCommunityInternal(Section.CommunityWebinar.code,label, categoryName);
      return;
    }
    const codeItem = (communityName === 'Emprende Mujer')?Section.WomenVideoAndWebinar.code:Section.WinemakersVideosAndWebinar.code;
    this.dataLayerService.clickCommunity(communityName, categoryName, label, codeItem);
  }
}

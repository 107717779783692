import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONST_COMMUNITIES_CODE } from '@shared/constants/communities.constants';
import { Article, CommunityBlogCategories } from '@shared/interfaces/frontend/blog';
import { Categories, UserLevel } from '@shared/interfaces/frontend/communities';
import { UserProfile } from '@shared/models/UserProfileModel';
import { BlogService } from '@shared/services/blog.service';
import { CategoryBlogService } from '@shared/services/category-blog.service';
import { CommunityService } from '@shared/services/community.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Subscription } from 'rxjs';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'yc-landing-blogs',
  templateUrl: './landing-blogs.components.html',
  styleUrls: ['./landing-blogs.component.scss'],
})
export class YcLandingBlogsComponent implements OnInit, OnDestroy, OnChanges {
  config: SwiperOptions = {
    direction: 'horizontal',
    freeMode: true,
    slidesPerView: 1.1,
    spaceBetween: 16,
    navigation: {
      nextEl: '.swiper-button-blog-next',
      prevEl: '.swiper-button-blog-prev',
    },
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      334: {
        slidesPerView: 1.1,
        spaceBetween: -16,
      },
      380: {
        slidesPerView: 1.2,
        spaceBetween: -16,
      },
      450: {
        slidesPerView: 1.45,
        spaceBetween: -16,
      },
      550: {
        slidesPerView: 1.65,
        spaceBetween: -16,
      },
      600: {
        slidesPerView: 1.8,
        spaceBetween: -16,
      },
      690: {
        slidesPerView: 1.95,
        spaceBetween: -16,
      },
      768: {
        slidesPerView: 2.05,
        spaceBetween: -16,
      },
      850: {
        slidesPerView: 2.3,
        spaceBetween: -16,
      },
      950: {
        slidesPerView: 2.6,
        spaceBetween: -100,
      },
      1050: {
        slidesPerView: 2.9,
        spaceBetween: -100,
      },
      1150: {
        slidesPerView: 3.1,
        spaceBetween: -100,
      },
      1250: {
        slidesPerView: 3.4,
        spaceBetween: -100,
      },
      1350: {
        slidesPerView: 3.9,
        spaceBetween: -100,
      },
    },
  };

  blogs: Article[] = [];
  blogsShow: Article[] = [];
  subscription: Subscription = new Subscription();
  @Input()
  communityId = 0;
  @Input()
  userLevel: UserLevel = {
    idLevel: 0,
    userId: 0,
    flagStatus: 'N',
  };
  slickConfigBlogs = {
    arrows: false,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    swipe: true,
    mobileFirst: true,
    swipeToSlide: true,
  };
  categories: CommunityBlogCategories[] = [];
  isPressed: boolean[] = [];
  buttonAll: CommunityBlogCategories = {
    name: 'Ver todo',
    quantity: 4,
    communityId: 0,
    categoryId: 0,
  };
  buttonResents: CommunityBlogCategories = {
    name: 'Recientes',
    quantity: 4,
    communityId: 0,
    categoryId: 0,
  };
  @ViewChild('swiperBlog', { static: false }) swiperBlog?: SwiperComponent;
  @ViewChild('slickCarouselBlog', { static: false }) slickCarouselBlog?: SlickCarouselComponent;
  forMe: Categories = {
    id: 0,
    name: 'Para Mí',
    quantity: 4,
    communityId: 0,
    categoryId: 0,
  };
  user!: UserProfile;
  @Input()
  source = '';
  sourceHome = 'home';
  isMaxLevel: boolean = false;

  parentRoute = '';
  @Input() showTitleCategories: boolean = true;

  constructor(
    private community: CommunityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private dataLayerService: DataLayerService,
    private blogService: BlogService,
    private categoryBlogService: CategoryBlogService
  ) {}

  ngOnInit(): void {
    this.parentRoute = this.activatedRoute.parent?.routeConfig?.path ?? '';
    this.isMaxLevel =
      (this.communityId === 1 && CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_MAX_LEVEL === this.userLevel.idLevel) ||
      (this.communityId === 2 && CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_MAX_LEVEL === this.userLevel.idLevel);
    this.getUserLevel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.userLevel.idLevel > 0 && this.userLevel.flagStatus === 'A') {
      if (this.categories[0] && this.categories[0].name !== 'Para Mí') {
        const categoriesAux = JSON.parse(JSON.stringify(this.categories));
        this.categories = [this.forMe].concat(categoriesAux);
        this.isPressed = new Array(this.categories.length).fill(false);
        this.isPressed[0] = true;
      }
    }
    if (changes.communityId) {
      this.userLevel.idLevel = changes.communityId.currentValue;
      this.toggleButton(0);
    }

    if (changes.userLevel) {
      this.isMaxLevel =
        (this.communityId === 1 && CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_MAX_LEVEL === this.userLevel.idLevel) ||
        (this.communityId === 2 && CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_MAX_LEVEL === this.userLevel.idLevel);
      if (this.isMaxLevel) {
        this.getUserLevel();
      }
    }
    setTimeout(() => {
      this.slickCarouselBlog?.unslick();
      this.slickCarouselBlog?.initSlick();
    }, 4000);
  }

  private getWebinars(): void {
    const code =
      this.parentRoute === 'emprende-mujer'
        ? CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID
        : CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_ID;
    this.subscription.add(
      this.blogService.getBlogsByCommunityId(code).subscribe(
        (response) => {
          const idsIncluded: string[] = [];
          this.blogs = response.filter((resp) => {
            if (idsIncluded.includes(resp.articleId.toString())) {
              return false;
            }
            idsIncluded.push(resp.articleId.toString());
            return true;
          });
          this.blogs.sort((a, b) => {
            const dateA = new Date(a.postDate).getTime();
            const dateB = new Date(b.postDate).getTime();
            return dateA - dateB;
          });
          this.blogs.reverse();
          this.blogsShow = this.blogs;
          this.blogsShow = this.blogsShow.slice(0, 10);
          this.swiperBlog?.swiperRef?.slideTo(0);
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  goToSeeMoreArticles(): Promise<boolean> {
    const category = this.categoryBlogService.getCategorySelected();
    if (category.selectedId !== 0) {
      this.categoryBlogService.selectCategory();
    }
    if (this.parentRoute === 'emprende-mujer') {
      this.dataLayerService.virtualEventEM(`Ver artículo: Todos los artículos`, 13);
    } else {
      this.dataLayerService.virtualEventBodegueros(`Ver artículo: Todos los artículos`, 12);
    }
    return this.parentRoute === 'emprende-mujer'
      ? this.router.navigate(['nuevo-intranet/comunidades/emprende-mujer', 'blogs'], {
          state: { communityId: this.communityId },
        })
      : this.router.navigate(['nuevo-intranet/comunidades/bodegueros', 'blogs'], {
          state: { communityId: this.communityId },
        });
  }

  private getCategory(): void {
    const code =
      this.parentRoute === 'emprende-mujer'
        ? CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID
        : CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_ID;
    this.categories = [];
    this.isPressed = [];
    if (this.userLevel.idLevel > 0 && this.userLevel.flagStatus === 'A' && !this.isMaxLevel) {
      this.categories.push(this.forMe);
    }
    this.categories.push(this.buttonResents);
    this.subscription.add(
      this.blogService.getBlogCategoriesByCommunityId(code).subscribe((response) => {
        let qtyAdded = 0;
        response.forEach((category) => {
          if (category.quantity >= 4 && qtyAdded < 5) {
            this.categories.push(category);
            qtyAdded += 1;
          }
        });
        this.buttonAll.communityId = this.communityId;
        this.categories.push(this.buttonAll);
        this.isPressed = new Array(this.categories.length).fill(false);
        this.isPressed[0] = true;
        let categoriesAux: string[] = [];
        this.categories = this.categories.filter((category) => {
          if (categoriesAux.includes(category.name)) {
            return false;
          }
          categoriesAux.push(category.name);
          return true;
        });
      })
    );
  }

  toggleButton(index: number): void {
    for (let i = 0; i < this.isPressed.length; i++) {
      this.isPressed[i] = i === index;
    }
    const communityName = this.parentRoute === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
    this.dataLayerService.clickCommunity(
      communityName,
      this.categories[index].name,
      'Filtrar artículo',
      communityName === 'Emprende Mujer' ? 13 : 12
    );
    this.categoryBlogService.setCategorySelected(this.categories[index].categoryId, this.categories[index].name);
    if (this.categories[index].name === 'Ver todo') {
      this.goToSeeMoreArticles().catch((err) => console.error('err: ', err));
    } else if (this.categories[index].name === 'Recientes') {
      this.getWebinars();
    } else if (this.categories[index].name != 'Para Mí') {
      const code =
        this.parentRoute === 'emprende-mujer'
          ? CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID
          : CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_ID;
      this.subscription.add(
        this.blogService.getBlogsByCommunityAndCategory(code, this.categories[index].categoryId).subscribe(
          (response) => {
            const idsIncluded: string[] = [];
            this.blogsShow = response.filter((resp) => {
              if (idsIncluded.includes(resp.articleId.toString())) {
                return false;
              }
              idsIncluded.push(resp.articleId.toString());
              return true;
            });
            this.swiperBlog?.swiperRef?.slideTo(0);
          },
          (error) => {
            console.error(error);
          }
        )
      );
    }
  }

  private getUserLevel(): void {
    const user = this.localStorageService.get('userProfile');
    if (user) {
      this.user = JSON.parse(user) as UserProfile;
      this.subscription.add(
        this.community.getLevelUserByCommunityId(this.user.userId, this.communityId).subscribe(
          (response) => {
            this.userLevel = response;

            if (this.userLevel.idLevel === 0 && this.userLevel.flagStatus != 'A' && this.isMaxLevel) {
              this.getWebinars();
            }
            this.getCategory();
          },
          (error) => {
            console.error(error);
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

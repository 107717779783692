import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Course } from '@shared/interfaces/frontend';
import { DataLayerService } from '@shared/services/data-layer.service';
import { stripHtml } from '@shared/utils/strip-html.utils';
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';

SwiperCore.use([Navigation]);

@Component({
  selector: 'yevo-training-virtual-courses-slider',
  templateUrl: './training-virtual-courses-slider.component.html',
  styleUrls: ['./training-virtual-courses-slider.component.scss'],
})
export class TrainingVirtualCoursesSliderComponent implements OnInit {
  @Input() title?: string = '';
  @Input() showAllLink?: string[] = null!;
  @Input() code: any;
  @Input() tab?: string = '';
  @Input() courses: Course[] = [];

  configSwiperDesktop: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 200,
    width: 1000,
    navigation: true,
    breakpoints: {
      1440: {
        slidesPerView: 2,
        spaceBetween: 20,
        width: 700,
      },
      1024: {
        slidesPerView: 1,
        spaceBetween: 20,
        width: 350,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 10,
        width: 350,
      },
      425: {
        slidesPerView: 1,
        spaceBetween: 5,
        width: 300,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 5,
        width: 300,
      },
    },
  };

  topic = 'course';
  coursesAux: Course[] = [];
  openModalSeidor: boolean = false;
  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  constructor(public dataLayerService: DataLayerService, private router: Router) {}

  ngOnInit(): void {
    if (this.code == 3004) {
      this.coursesAux.push(this.courses[0]);
      this.coursesAux.push(this.courses[1]);
    } else if (this.code == 3005) {
      this.coursesAux.push(this.courses[2]);
    } else if (this.code == 3007 || this.code == '') {
      this.coursesAux = this.courses;
    }
  }

  verCourse(course: Course) {
    this.dataLayerService.pushTrainingCourseEvent(course.title);
    this.router.navigate([`/cursos/${course.courseId}`], { queryParamsHandling: 'merge' });
  }

  getTextFromHtml(html: string): string {
    return stripHtml(html);
  }
}

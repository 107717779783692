import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IPaymentMethodInfo } from '@shared/interfaces';

@Component({
  selector: 'yevo-checkbox-v2',
  templateUrl: './checkbox-v2.component.html',
  styleUrls: ['./checkbox-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxV2Component {
  @Input() keyValue!: KeyValue<string, number>;
  @Input() checked?: boolean;
  @Input() isDisabled?: boolean;
  @Input() label?: string;
  @Input() aditionalInfo: IPaymentMethodInfo | undefined;
  @Output() onCheckedChanged = new EventEmitter<void>();

  onCheckedChange(event: any): void {
    this.onCheckedChanged.emit(event);
  }
}

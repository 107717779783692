import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CardLaunchesItem } from '@shared/interfaces/IDropdownItem';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-launches-card',
  templateUrl: './launches-card.component.html',
  styleUrls: ['./launches-card.component.scss']
})
export class LaunchesCardComponent {
  @Input() release!: CardLaunchesItem;

  constructor(private router: Router,private dataLayerService: DataLayerService) { }

  navigateRelease(item:any):void {
  if(item.id === 'recomendados'){
    this.dataLayerService.genericMilab('Programa de Referidos - 1','Seleccionar: Programa de Recomendados');
  }
   item.isExternalUrl?window.open(item.url, '_blank'):this.router.navigate([item.url]);
  }
}
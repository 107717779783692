import { Component } from '@angular/core';

@Component({
  selector: 'milab-info-footer',
  templateUrl: './info-footer.component.html',
  styleUrls: ['./info-footer.component.scss']
})
export class InfoFooterComponent {

}

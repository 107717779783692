<div class="container-fluid p-0 m-0 h-100">
  <yevo-financing-request-message
    [isLogged]="isLogged"
    [messageType]="messageType"
    [kushka]="showMessageKushka"
    [unsuccess]="unsuccess"
    [typeForm]="typeForm"
  ></yevo-financing-request-message>
</div>
<!-- pendiente -->

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'yevo-footer-image',
  templateUrl: './footer-image.component.html',
  styleUrls: ['./footer-image.component.scss'],
})
export class FooterImageComponent implements OnInit {
  @Input() showDots: boolean = false;

  constructor() {
    //DO NOTHING
  }

  ngOnInit(): void {
    //DO NOTHING
  }
}

<div class="container mt-4">
  <div class="row">
    <div class="col-md-12">
      <div class="card" [ngClass]="error || financingService.showDocumentError ? 'bgError' : 'bg'">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 text-center">
              <label for="upload">
                <img
                  *ngIf="!load && !error && !partialLoad && !isLoading"
                  src="assets/financing/files/upload-file.svg"
                  alt="files"
                />
                <img
                  *ngIf="load && !error && !partialLoad && !isLoading"
                  src="assets/financing/files/upload-success.svg"
                  alt="success-icon"
                />
                <img *ngIf="error" src="assets/financing/files/error-file.svg" alt="error-icon" />
                <img
                  *ngIf="partialLoad && !error && !isLoading"
                  src="assets/financing/files/warning-file.svg"
                  alt="warning-icon"
                />
                <img
                  *ngIf="isLoading && !error && !load"
                  src="assets/financing/files/uploading.svg"
                  alt="uploading-icon"
                />
                <input
                  type="file"
                  name="file"
                  onclick="this.value=null"
                  (change)="fileChangeEvent($event)"
                  accept=".png, .jpg, .jpeg, .pdf"
                  class="d-none"
                  id="upload"
                  multiple
                  #fileInput
                />
              </label>
            </div>

            <div class="col-md-12" *ngIf="!partialLoad && !load && !error && !isLoading">
              <h2 class="title mt-3">
                Sube 2 boletas o <br class="d-block d-md-none" />
                facturas de tu negocio
              </h2>
              <p class="subTitle mt-2 text-center">Los formatos deben ser JPG, PDF o PNG.</p>
            </div>
            <div class="col-md-12" *ngIf="partialLoad && !error && !isLoading">
              <h2 class="title mt-3 mb-2">
                Te falta subir 1 boleta o factura <br />
                más de tu negocio.
              </h2>
            </div>

            <div class="col-md-12" *ngIf="load && !partialLoad && !error && !isLoading">
              <h2 class="title mt-3 mb-2">
                Boletas/facturas <br />
                subidas con éxito
              </h2>
            </div>

            <yevo-button-animated
              *ngIf="(!load && !error && !isLoading) || (partialLoad && !error && !isLoading)"
              [label]="'Subir boleta'"
              class="w-100 mt-2 px-3"
              [style]="'outline'"
              (buttonClick)="fileInput.click()"
            ></yevo-button-animated>

            <div class="col-md-12 px-2" *ngIf="error">
              <h2 class="title mt-3 mb-2" [innerHTML]="errorMessage"></h2>
              <p class="subTitle mt-2 text-center">Asegúrate de que sean en los formatos aceptados JPG, PDF O PNG.</p>
              <div class="col-md-12 text-center mt-3 mx-0 px-0">
                <yevo-button-animated
                  [label]="'Volver a subir boleta o factura'"
                  class="w-100"
                  [style]="'outline'"
                  [type]="'button'"
                  (buttonClick)="fileInput.click()"
                ></yevo-button-animated>
              </div>
            </div>

            <div class="col-md-12" *ngIf="isLoading">
              <h2 class="title mt-3 mb-2">
                Estamos subiendo <br />
                tus boletas o facturas.
              </h2>
            </div>

            <div *ngIf="!error" class="col-md-12 mt-3">
              <div *ngFor="let contentItem of document; index as i" class="w-100 mb-2">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="w-50">
                    <img
                      class="w-100 uploaded-image"
                      src="assets/financing/files/uploaded-file.svg"
                      alt="uploaded-icon"
                    />
                  </div>
                  <div class="w-50 p-2" *ngIf="financingService?.getImageProgress(i)?.uploading">
                    <p class="document-name m-0 p-0">{{ contentItem.name }}</p>
                    <div class="d-block w-100">
                      <div class="d-flex align-items-center">
                        <div class="d-block progress w-50">
                          <div
                            class="progress-bar bg-success-bar"
                            role="progressbar"
                            attr.aria-valuenow="{{ financingService.getImageProgress(i).progress }}"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            [ngStyle]="{ width: financingService.getImageProgress(i).progress + '%' }"
                          ></div>
                        </div>
                        <span class="d-block ml-2 progress-bar-text"
                          >{{ financingService.getImageProgress(i).progress }}%</span
                        >
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!financingService?.getImageProgress(i)?.uploading" class="w-50 p-2">
                    <p class="document-name m-0 p-0">{{ contentItem.name }}</p>
                    <button
                      type="button"
                      class="btn btn-link p-0 d-flex align-items-center mt-1"
                      (click)="deleteDocument(contentItem)"
                      [ngClass]="{ 'd-none': false }"
                    >
                      <img src="assets/financing/files/delete.svg" alt="delete-icon" />
                      <span class="ml-2">Eliminar documento</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

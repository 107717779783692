<div class="container">
  <div class="row align-items-center">
    <div class="col-12 col-md-12 col-sm-12">
      <div appIframeTracker (iframeClick)="handleIFrameClick(video.videoTitle)">
        <div id="player"></div>
      </div>
    </div>
  </div>
  <div class="row p-0 div__links">
    <div class="col-md-9 p-0 d-flex">
      <a (click)="goHome()" class="links">
        <img style="margin: 0 4px 0 0" src="./assets/icons/communities/arrow.svg" alt="arrow" />Home
      </a>
      <span>/</span>
      <a (click)="goVideos()" class="links">Videos</a>
    </div>
  </div>
  <div class="row p-0 m-0">
    <h4 class="title-video">{{ video.videoTitle }}</h4>
  </div>
  <div class="row p-0 m-0">
    <p class="text-video">{{ video.videoText }}</p>
  </div>
  <div class="row p-0" style="margin: auto auto 16px auto">
    <img src="/assets/icons/communities/calendar.svg" alt="calendar" />
    <p class="date">{{ video.creationDate }}</p>
  </div>
  <div class="row p-0 m-0" style="position: relative">
    <div class="write-comment" [ngClass]="{ active: commentSent }">
      <textarea maxlength="5000" name="comment" [(ngModel)]="comment" placeholder="Añadir comentario"></textarea>
    </div>
    <div class="row div__button" [ngClass]="{ displayButton: commentSent }">
      <button type="button" class="button-comment" (click)="createComment()">Comentar</button>
    </div>
    <div class="comment-posted" [ngClass]="{ active: commentSent }">
      <p>¡Tu comentario ha sido publicado!</p>
      <button type="button" class="button-new-comment" (click)="newComment()">Crear nuevo comentario</button>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 dpf pl_0">
      <img src="assets/icons/communities/count_answer.svg" alt="answer" class="img-fluid img_perfil" />
      <p class="p-count p_com">{{ getListCommentVideo.length }} Comentarios</p>
    </div>
  </div>

  <div class="row dpf mb-3 ml-1" *ngFor="let item of getListCommentVideo">
    <div class="div-comment">
      <p class="p-name">{{ item.userName }}</p>
      <p class="p-date">{{ item.creationDate | date : 'd MMM' }}</p>
      <p class="p-comment">
        {{ item.comment }}
      </p>
    </div>
  </div>
</div>

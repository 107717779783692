export const EVENT_VALUES = {
    codeActionOne:'1',
    codeActionTwo:'2',
    codeActionThree:'3',
    codeActionFour:'4',
    codeActionFive:'5',
    codeActionSix:'6',
    codeActionNine:'9',
    labelSelected:'Seleccionar: ',
    labelSee:' : Ver ',
    labelSeePoints:'Ver mis puntos',
    labelPointsRedeem:'Canjea tu premio',
    labelPointsRedeemTwo:'Canjear premio',
    labelModalGoForm:' Debe ser JBS para llenar el formulario de canje: ',
    labelGoForm:'Ir al formulario',
    labelTerms:'Ver Términos y Condiciones del Programa',
}
<img *ngIf="isLabelInfo" width="20" height="20" [src]="'/assets/icons/seed-cash/info-tip.svg'" alt="tip-tooltip-icon" (mouseover)="open()" (click)="open()" />
<h2 class="title" *ngIf="!isLabelInfo">
  {{ title }}
  <img [src]="'/assets/icons/seed-cash/info-tip.svg'" alt="tip-tooltip-icon" (mouseover)="open()" (click)="open()" />
</h2>
<ng-template #modalInfoContent let-modal>
  <div class="modal-body mb-5 mx-2">
    <button type="button" class="close" (click)="dismiss()">
      <img class="images__clouds" alt="close-small-img" src="/assets/img/close-small.svg" />
    </button>
    <br />
    <div class="d-flex justify-content-center mb-3 w-100">
      <img
        class="img-fluid"
        alt="disclaimer-preapproved-img"
        src="/assets/icons/info-modal.svg"
        width="114"
        height="113"
      />
    </div>
    <h2 class="info-modal-title" [innerHTML]="titleModal"></h2>
    <div class="info-modal-description" [innerHTML]="descriptionModal"></div>
  </div>
</ng-template>

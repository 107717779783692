import { Component, HostListener, Input, OnInit,ViewChild } from '@angular/core';
import { MedalBenefits,ReferredProgram, TermsConditions } from '@shared/interfaces/frontend/communities';
import medalBenefitJson from '@shared/data/communities/medal-benefit.json';
import termsAndConditionsJson from '@shared/data/communities/terms-and-conditions.json';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { ModalTermsPriceSearchComponent } from '../modal-terms-price-search/modal-terms-price-search.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { REFERRED_PROGRAM_VALUES, VIRTUAL_EVENT_VALUES } from '@shared/constants/communities.constants';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-my-benefits-section',
  templateUrl: './my-benefits-section.component.html',
  styleUrls: ['./my-benefits-section.component.scss']
})
export class MyBenefitsSectionComponent implements OnInit {
  @Input() referredPrograma!: ReferredProgram;
  @Input() progressMedal!: string;
  @Input() source!: string;
  medalBenefit: MedalBenefits[] = [];
  termsConditionsReferred!: TermsConditions;
  isMobile!:boolean;
  config: SwiperOptions = {
    direction: 'horizontal',
    freeMode: true,
    slidesPerView: 1.1,
    spaceBetween: -16,
    navigation: {
      nextEl: '.swiper-button-benefit-next',
      prevEl: '.swiper-button-benefit-prev',
    },
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      334: {
        slidesPerView: 1.1,
        spaceBetween: -16,
      },
      380: {
        slidesPerView: 1.2,
        spaceBetween: -16,
      },
      450: {
        slidesPerView: 1.45,
        spaceBetween: -16,
      },
      550: {
        slidesPerView: 1.65,
        spaceBetween: -16,
      },
      600: {
        slidesPerView: 1.8,
        spaceBetween: -16,
      },
      690: {
        slidesPerView: 1.95,
        spaceBetween: -16,
      },
      768: {
        slidesPerView: 2.05,
        spaceBetween: -16,
      },
      850: {
        slidesPerView: 2.3,
        spaceBetween: -16,
      },
      950: {
        slidesPerView: 3,
        spaceBetween: -100,
      },
      1050: {
        slidesPerView: 3.1,
        spaceBetween: -100,
      },
      1150: {
        slidesPerView: 3.3,
        spaceBetween: -100,
      },
      1250: {
        slidesPerView: 3.6,
        spaceBetween: -100,
      },
      1350: {
        slidesPerView: 4,
        spaceBetween: -0,
      },
    },
  };

  @ViewChild('swiperBenefit', { static: false }) swiperBenefit?: SwiperComponent;


  constructor(private modalService: NgbModal,private dataLayerService: DataLayerService) { }

  ngOnInit(): void {
    medalBenefitJson.forEach(element => {
      if (element.medal === this.progressMedal) {
        this.medalBenefit = element.medalBenefits;
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkIfMobile();
  }
  checkIfMobile() {
    this.isMobile = window.innerWidth <= 1024;
  }

  openModal() {
    const modalRef= this.modalService.open(ModalTermsPriceSearchComponent, { windowClass: 'modal-terms' });
    this.termsConditionsReferred =termsAndConditionsJson.referredProgram;
    modalRef.componentInstance.terms = this.termsConditionsReferred;
  }
  evalueateSize():boolean{
    this.checkIfMobile()
    return this.medalBenefit.length>REFERRED_PROGRAM_VALUES.cardDesktopLimit || (this.medalBenefit.length<=REFERRED_PROGRAM_VALUES.cardDesktopLimit && this.isMobile);
  }
  selectedBenefit(selected:string){
    this.dataLayerPush(`${this.progressMedal}: ${selected}`);
  }

  dataLayerPush(labelOption:string): void {
    if (this.source === REFERRED_PROGRAM_VALUES.woman) {
      this.dataLayerService.virtualEventEM(labelOption,VIRTUAL_EVENT_VALUES.selectShareLinkWomanCode);
    } else {
      this.dataLayerService.virtualEventBodegueros(labelOption,VIRTUAL_EVENT_VALUES.selectShareLinkWinemakersCode);
    }
  }
}

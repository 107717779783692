import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Categories, Training, UserLevel, WebinarViewedResponse } from '@shared/interfaces/frontend/communities';
import { CommunityService } from '@shared/services/community.service';
import { CONST_COMMUNITIES_CODE } from '@shared/constants/communities.constants';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UserProfile } from '@shared/models/UserProfileModel';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.scss'],
})
export class TrainingListComponent implements OnInit, OnDestroy {
  @Input()
  communityId = 0;
  subscription: Subscription = new Subscription();
  trainingList: Training[] = [];
  length = 0;
  showButton = false;
  isMobile = false;
  trainingListShow: Training[] = [];
  slickConfigWebinar = {
    arrows: false,
    variableWidth: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    swipeToSlide: true,
    mobileFirst: true,
    swipe: true,
  };
  categories: Categories[] = [];
  isPressed: boolean[] = [];
  all: Categories = {
    name: 'Todos',
    categoryId: 0,
    quantity: 4,
    communityId: 0,
    id: 0,
  };
  forMe: Categories = {
    name: 'Para Mí',
    categoryId: 0,
    quantity: 4,
    communityId: 0,
    id: 0,
  };
  codeId = 0;
  user!: UserProfile;
  userLevel: UserLevel = {
    userId: 0,
    idLevel: 0,
    flagStatus: 'N',
  };
  showRecommendText = true;
  source = '';
  sourceClick = 'list';
  categorySelected: string | null = '';
  webinarViewed: WebinarViewedResponse[] = [];

  constructor(
    private communityService: CommunityService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localStoreService: LocalStorageService,
    private layerService: DataLayerService
  ) {
    this.isMobile = this.getScreenSize();
  }

  ngOnInit(): void {
    const user = this.localStoreService.get('userProfile');
    if (user) this.user = JSON.parse(user) as UserProfile;
    this.communityService.getWebinarsViewedByUserid(this.user.userId).subscribe(
      (response) => {
        this.webinarViewed = response;
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
          if (params.get('category')) {
            this.categorySelected = params.get('category');
          }
        });
        this.communityService.communityIdSearch$.subscribe(
          (response) => {
            if (response) {
              this.communityId = response;
              this.loadCommunityByCode();
            } else {
              this.loadCommunity();
            }
          });
      },
      (error) => {
        console.error('getVideosViewedByUserid ERROR: ', error);
      }
    );
  }

  private loadWebinars(): void {
    this.subscription.add(
      this.communityService.getWebinarsByCommunityId(this.communityId).subscribe(
        (response) => {
          this.trainingList = response;
          this.trainingList.sort((a, b) => {
            const dateA = new Date(a.creationDate).getTime();
            const dateB = new Date(b.creationDate).getTime();
            return dateB - dateA;
          });
          const validation = this.isMobile ? 10 : 15;
          this.trainingList = this.trainingList
            .map((t) => {
              const idsWebinarViewed = this.webinarViewed.map((wv) => wv.webinarId);
              t.viewed = idsWebinarViewed.includes(Number(t.id));
              return t;
            })
            .sort((a, b) => {
              if (a.viewed === b.viewed) return 0;
              if (a.viewed) return 1;
              return -1;
            });
          this.trainingListShow = this.trainingList;
          this.trainingListShow = this.trainingListShow.slice(
            0,
            this.trainingListShow.length > validation ? validation : this.trainingListShow.length
          );
          this.length = this.trainingListShow.length;
          this.showButton = this.trainingList.length > validation;
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  private loadCommunity(): void {
    const parentRoute = this.activatedRoute.parent?.routeConfig?.path;
    this.communityId =
      parentRoute === 'emprende-mujer'
        ? CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID
        : CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_ID;
    this.source = this.communityId === CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID ? 'emprende-mujer' : 'bodegueros';
    this.loadCommunityByCode();
  }

  goHome(): Promise<boolean> {
    return this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  @HostListener('window:resize', ['$event'])
  private getScreenSize(): boolean {
    return window.innerWidth <= 768;
  }

  pagination(): void {
    const community = this.source === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
    this.layerService.clickCommunity(
      community,
      `Ver ${this.trainingList.length - this.length} webinars más`,
      'Cargar',
      7
    );
    let startIndex = 0;
    const validation = this.isMobile ? 10 : 15;
    const endIndex =
      this.trainingListShow.length + (this.trainingList.length > validation ? validation : this.trainingList.length);
    while (startIndex < endIndex) {
      this.trainingListShow = [];
      const batch = this.trainingList.slice(startIndex, endIndex);
      this.trainingListShow.push(...batch);
      this.trainingListShow = this.trainingListShow
        .map((t) => {
          const idsWebinarViewed = this.webinarViewed.map((wv) => wv.webinarId);
          t.viewed = idsWebinarViewed.includes(Number(t.id));
          return t;
        })
        .sort((a, b) => {
          if (a.viewed === b.viewed) return 0;
          if (a.viewed) return 1;
          return -1;
        });
      this.showButton = this.trainingList.length > validation;
      startIndex = endIndex;
    }
    this.length = this.trainingListShow.length;
    this.showButton = this.trainingList.length !== this.trainingListShow.length;
  }
  toggleButtonTodos() {
    this.loadWebinars();
    this.showRecommendText = false;
  }
  toggleButtonParaMi() {
    this.getWebinarLevel();
    this.showRecommendText = true;
  }
  toggleButtonDefault(index: number) {
    this.showRecommendText = false;
    this.subscription.add(
      this.communityService
        .getWebinarByCommunityAndCategory(this.communityId, this.categories[index].categoryId)
        .subscribe(
          (response) => {
            this.trainingList = response;
            this.trainingList = this.trainingList
              .map((t) => {
                const idsWebinarViewed = this.webinarViewed.map((wv) => wv.webinarId);
                t.viewed = idsWebinarViewed.includes(Number(t.id));
                return t;
              })
              .sort((a, b) => {
                if (a.viewed === b.viewed) return 0;
                if (a.viewed) return 1;
                return -1;
              });
            const validation = this.isMobile ? 10 : 15;
            this.trainingListShow = this.trainingList.slice(
              0,
              this.trainingList.length > validation ? validation : this.trainingList.length
            );
            this.length = this.trainingListShow.length;
            this.showButton = this.trainingList.length > validation;
          },
          (error) => {
            console.error(error);
          }
        )
    );
  }
  toggleButton(index: number): void {
    for (let i = 0; i < this.isPressed.length; i++) {
      this.isPressed[i] = i === index;
    }
    const community = this.source === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
    this.layerService.clickCommunity(community, this.categories[index].name, 'Filtrar por', 7);
    switch (this.categories[index].name) {
      case 'Todos':
        this.toggleButtonTodos();
        break;

      case 'Para Mí':
        this.toggleButtonParaMi();
        break;

      default:
        this.toggleButtonDefault(index);
        break;
    }
  }

  private loadCategories(): void {
    if (this.userLevel.idLevel > 0 && this.userLevel.flagStatus === 'A' && this.userLevel.idLevel < 5) {
      this.categories.unshift(this.forMe);
      this.showRecommendText = true;
    }
    this.all.communityId = this.communityId;
    this.categories.push(this.all);
    this.subscription.add(
      this.communityService.getWebinarsCategoryStatistics(this.communityId).subscribe(
        (response) => {
          response.forEach((category) => {
            if (category.quantity >= 1 && category.categoryId !== this.all.categoryId) {
              this.categories.push(category);
            }
          });
          this.isPressed = new Array(this.categories.length).fill(false);
          this.isPressed[0] = true;
          if (this.categorySelected) {
            this.toggleCategorySelected();
          }
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  private loadCommunityByCode(): void {
    this.getLevelUser();
    if (this.communityId === CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID) {
      this.source = 'emprende-mujer';
    } else {
      this.source = 'bodegueros';
    }
  }

  private getLevelUser(): void {
    const user = this.localStoreService.get('userProfile');
    if (user) {
      this.user = JSON.parse(user) as UserProfile;
      this.subscription.add(
        this.communityService.getLevelUserByCommunityId(this.user.userId, this.communityId).subscribe(
          (response) => {
            this.userLevel = response;
            if (this.userLevel.idLevel > 0 && this.userLevel.flagStatus === 'A' && this.userLevel.idLevel < 5) {
              this.getWebinarLevel();
            } else {
              this.loadWebinars();
            }
            this.loadCategories();
          },
          (error) => {
            console.error(error);
          }
        )
      );
    }
  }

  private getWebinarLevel(): void {
    this.subscription.add(
      this.communityService.getWebinarByLevelAndCommunityId(this.userLevel.idLevel, this.communityId).subscribe(
        (response) => {
          response.sort((a, b) => {
            const dateA = new Date(a.creationDate).getTime();
            const dateB = new Date(b.creationDate).getTime();
            return dateB - dateA;
          });
          const validation = this.isMobile ? 10 : 15;
          this.trainingList = response;
          this.trainingList = this.trainingList
            .map((t) => {
              const idsWebinarViewed = this.webinarViewed.map((wv) => wv.webinarId);
              t.viewed = idsWebinarViewed.includes(Number(t.id));
              return t;
            })
            .sort((a, b) => {
              if (a.viewed === b.viewed) return 0;
              if (a.viewed) return 1;
              return -1;
            });
          this.trainingListShow = this.trainingList;

          this.trainingListShow = this.trainingListShow.slice(
            0,
            this.trainingListShow.length > validation ? validation : this.trainingListShow.length
          );
          this.length = this.trainingListShow.length;
          this.showButton = this.trainingList.length > validation;
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }
  toggleCategorySelected(): void {
    let idx = 0;
    this.categories.forEach((category, index) => {
      const slugCategory = category.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)+/g, '')
        .trim();
      if (slugCategory === this.categorySelected) {
        idx = index;
      }
    });
    this.toggleButton(idx);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

<div class="container d-flex justify-content-center px-0">
  <div class="card my-2">
    <div class="d-flex main-container">
      <div class="text-container ml-0">
        <p class="main-title mb-0">¿Deseas conocer tu cuota mensual antes de iniciar tu solicitud?</p>
        <div class="container-fluid main-subtitle">
          <span class="mx-0 mx-lg-2 hand-icon">👉🏼</span>
          <a href="" (click)="sendClickEvent()" [routerLink]="['/financiamiento', 'simulador-prestamo-mibanco']" class="m-0 link"
            >Prueba nuestro <br class="d-block d-lg-none" />
            simulador de préstamos</a
          >
        </div>
      </div>
      <img class="img-asesor" src="/assets/img/financing/loan-simulator/asesor.svg" alt="bodeguero-img" />
    </div>
  </div>
</div>

<div class="modal-share-header">
    <h4 class="modal-share-title">Compartir</h4>
    <div class="modal-share-close">
        <img  class="close" (click)="activeModal.close()" src="/assets/icons/communities/share-close-small.svg" alt="arrow" >
    </div>
</div>
<div class="modal-body">
    <div class="card-share">
        <div class="social-networks">
            <ng-container *ngFor="let item of socialNetworks">
                <div class="item" *ngIf="validResize(item)">
                    <img class="image-social" [src]="item.icon" alt="{{ item.name }}" (click)="shareSocialNetworks(item.name)">
                    <label class="item-name" for="">{{ item.name }}</label>
                </div>
            </ng-container>
        </div>
        <div class="link-content">
            <div class="link">
                <div class="copy-link-mobile" (click)="copyLink()">
                    <img src="/assets/icons/communities/copy-link.svg" alt="copiar enlace">
                </div>
                <div class="copy-link-text">Copiar enlace</div>
            </div>
        </div>
    </div>
</div>
<div class="selected-copy" *ngIf="this.selectedCopyLink">Copiado</div>
<ngb-carousel
  class="bannerRotative_carousel"
  [ngClass]="!loader ? 'bannerRotative_color_carousel' : ''"
  [interval]="5000"
  [showNavigationIndicators]="false"
  [showNavigationArrows]="true"
  (slide)="onSlide($event)"
>
  <ng-template ngbSlide *ngFor="let banner of banners; let i = index">
    <ngx-skeleton-loader
      *ngIf="!loader"
      [appearance]="'line'"
      count="1"
      animation="pulse"
      class="loader_ngx"
      [theme]="{
        'background-color': '#F5F5F5',
        width: '100%',
        height: mediaHeight,
        'margin-bottom': '0',
        'padding-bottom': '0'
      }"
    ></ngx-skeleton-loader>

    <div (click)="banner.routerLink !== '' && goToBanner(banner)" class="rotative_banner__link" [style.background-color]="banner.backgroundColor" *ngIf="loader">
      <img [src]="banner.desktop" alt="banner" class="w-100 rotative_banner d-none desktop" />
      <img [src]="banner.laptop" alt="banner" class="w-100 rotative_banner d-none laptop" />
      <img [src]="banner.tablet" alt="banner" class="w-100 rotative_banner d-none tablet" />
      <img [src]="banner.mobile" alt="banner" class="w-100 rotative_banner d-none mobile" />
    </div>
  </ng-template>
</ngb-carousel>

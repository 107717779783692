<div class="container-header mt-0 w-100 justify-content-center" [ngClass]="{ 'mt-4 mt-lg-0': isLogged }">
  <div class="col-12 breadcrumb mt-2 mt-lg-3 mb-4 mb-lg-0">
    <div class="div_bea">
      <yevo-breadcrumb [goback]="true" [bread]="breadUrl" [breadcrumbs]="breadcrumbs" class="col-12"></yevo-breadcrumb>
    </div>
  </div>
  <div
    class="cont d-flex flex-row justify-content-center overflow-hidden main-container"
    [ngClass]="{ 'disabled-register': (!isLogged && !name) || isFinanceRejectRequest }"
  >
    <div class="row w-100">
      <!-- FIRST COLUMN -->
      <div class="d-flex flex-column justify-content-center col-sm-12  align-items-center">
        <!-- MAIN TITLE -->
        <div class="main-title-section w-100">
          <img class="image" [src]="'/assets/img/messages/' + messageInformation.image + '.svg'" alt="person-adviser" />
          <div class="card p_1_0" [ngClass]="{ 'w-100': !showRegister && !isAffiliate }">
            <h2 id="id-name" class="px-2" [ngClass]="{'name': !isSeedCash,'m-0 seed_title': isSeedCash}" [innerHTML]="messageInformation.name"></h2>
            <h3 id="id-title" class="px-2" [ngClass]="{'title': !isSeedCash,'seed_title': isSeedCash}" [innerHTML]="messageInformation.title"></h3>
            <p
              *ngIf="!isSeedCash"
              class="subtitle"
              [innerHTML]="messageInformation.subtitle"
              [ngClass]="{
                'subtitle-line p-0': isLogged,
                'subtitle-unsuccess': isFinanceRejectRequest,
                'mb-lg-0': messageInformation.buttonTitle && isLogged
              }"
            ></p>

            <div *ngIf="isSeedCash" class="d-flex flex-row">
              <p
                class="subtitle seedcash"
                [ngClass]="{ 'subtitle-line p-0': isLogged, 'subtitle-unsuccess': isFinanceRejectRequest }"
                [innerHTML]="messageInformation.subtitle"
              ></p>
            </div>
          </div>
        </div>

        <div *ngIf="messageInformation.buttonTitle && !isLogged" class="btn-space"></div>

        <yevo-button-animated
          *ngIf="messageInformation.buttonTitle && isLogged"
          [label]="messageInformation.buttonTitle"
          class="w-100 mb-2 mb-lg-4 d-none d-lg-block"
          [style]="'outline'"
          (buttonClick)="findAgencies()"
        ></yevo-button-animated>

        <yevo-button-animated
          *ngIf="messageInformation.buttonTitle && !isLogged"
          [label]="messageInformation.buttonTitle"
          class="w-100 agencies mb_30"
          [style]="'outline'"
          (buttonClick)="findAgencies()"
        ></yevo-button-animated>

        <div *ngIf="messageInformation.buttonTitle && !isLogged" class="btn-space-bottom"></div>
      </div>

      <!-- MOBILE COLUMN -->
      <div
        class="features-section-mobile container-fluid d-block d-md-none d-flex flex-column justify-content-center align-items-center"
      >
        <yevo-button-animated
          [label]="'Crear mi cuenta'"
          [type]="'button'"
          class="w-100 d-block mb-0 mb-md-4 isButtonForRegister"
          (click)="goToRegister()"
          [disabled]="false"
          *ngIf="!isLogged && !name && !isAffiliate"
        ></yevo-button-animated>
      </div>
      <!-- MOBILE COLUMN -->

      <!-- SECOND COLUMN -->
      <div class="col-sm-12  d-flex flex-column justify-content-center align-items-center">
        <!-- FEATURES -->
        <div class="m_a mw-463">
          <div *ngIf="isLogged" class="features-section d-none d-md-block mb-3">
          </div>

          <!-- ACTION -->
          <div class="action-section w-100" *ngIf="showRegister && !isAffiliate">
            <!-- GO TO HOME ACTION -->
            <yevo-button-animated
              *ngIf="isLogged"
              [label]="'Ir al inicio'"
              (buttonClick)="goToHome()"
              class="w-100"
              [icon]="'/assets/icons/home-white.svg'"
            ></yevo-button-animated>

            <div *ngIf="!isLogged && name">
              <yevo-button-animated              
              [label]="'Ir al inicio'"
              (buttonClick)="goToHome()"
              class="w-100 agency-home"
              [icon]="'/assets/icons/home-white.svg'"
            ></yevo-button-animated>
            </div>
          </div>
          <yevo-button-animated
            *ngIf="messageInformation.buttonTitle && isLogged"
            [label]="messageInformation.buttonTitle"
            class="w-100 mb-2 mb-lg-4 d-block d-lg-none"
            [style]="'outline'"
            (buttonClick)="findAgencies()"
          ></yevo-button-animated>
          <yevo-button-animated
            *ngIf="!showRegister && !isAffiliate"
            [label]="'Ir al inicio'"
            (buttonClick)="goToHome()"
            class="w-100 agency-home"
            [icon]="'/assets/icons/home-white.svg'"
          ></yevo-button-animated>
          <yevo-button-animated
            *ngIf="!showRegister && isAffiliate && isLogged"
            [label]="'Ir al inicio'"
            (buttonClick)="goToHome()"
            class="w-100 agency-home"
            [icon]="'/assets/icons/home-white.svg'"
          ></yevo-button-animated>
        </div>
      </div>

      <div class="d-none d-lg-block col-lg">
        <img
          class="points"
          [ngClass]="{ 'point-disabled-register': !isLogged && !name }"
          src="/assets/img/messages/points.svg"
          alt="message"
        />
      </div>
    </div>
  </div>
<div class="modal-overlay" *ngIf="this.typeForm === 'general' && codeRecomended !='' && activeModal && messageType == 'sent' && this.referredCode ==''"></div>
<div class="fixed-bottom"  *ngIf="this.typeForm === 'general' && codeRecomended !='' && messageType == 'sent' && this.referredCode ==''">
    <milab-modal-expandable-refers bgColor="#ECF7F0" [codeRecomended]="codeRecomended" (showEmitter)="showEmitter($event)"></milab-modal-expandable-refers>
</div>
  <yevo-footer-image></yevo-footer-image>
</div>

<div class="banner">
  <div class="row p_0 m_0">
    <div class="col-sm-12 col-md-5 col-lg-5">
      <div class="row dp_f p_75">
        <div class="col-12">
          <p class="d-none d-md-block d-lg-block banner_title">Cursos virtuales</p>
          <p class="d-block d-md-none d-lg-none banner_title txt_center">Cursos virtuales</p>
        </div>
        <div class="col-12 m_0">
          <p class="d-none d-md-block d-lg-block banner_description">
            Complementa los webinars y videos con cursos prácticos y de mucho valor para tu negocio
          </p>
          <p class="d-block d-md-none d-lg-none banner_description txt_center">
            Complementa los webinars y videos con cursos prácticos y de mucho valor para tu negocio
          </p>
        </div>
        <div class="d-none d-md-block d-lg-block col-12">
          <button (click)="showAllCourses()" class="banner_button">Ver todos los cursos</button>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-7 col-lg-7 mt_48 mb-5">
      <yevo-training-virtual-courses-slider [courses]="allCourses"> </yevo-training-virtual-courses-slider>
    </div>
  </div>
</div>

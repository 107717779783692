<div class="specialization_card">
    <div class="image_desktop">
        <img [src]="item.image" [alt]="item.title">
    </div>
    <div class="image_mobile">
        <img [src]="item.image_mobile" [alt]="item.title">
    </div>
    <div class="title">
        {{item.title}}
    </div>
    <div class="description">
        {{item.description}}
    </div>
    <div class="button_container">
        <button (click)="goToWebinars(item.url)">
            Iniciar programa
        </button>
    </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IUploadImageProgress } from '@shared/interfaces/frontend/ui-components/progress-bar';
import { FinancingService } from '@shared/services/financing.service';

enum ErrorMessagesInvoice {
  oneInvoice = 'No se logró subir <br /> tu boleta/factura',
  twoInvoice = 'No se pudieron subir tus 2 <br /> boletas/facturas',
}

@Component({
  selector: 'yevo-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  @Output() documents = new EventEmitter<any>();
  @Output() showDocument = new EventEmitter<any>();
  @Output() uploadDocument = new EventEmitter<any>();
  @Input() uploadDocuments: any;

  @Input() progressObject: IUploadImageProgress = {};
  @Input() progress: number = 0;

  error: boolean = false;

  errorMsg: string = '';
  document: any[] = [];
  load: boolean = false;
  errorMessage: string = ErrorMessagesInvoice.oneInvoice;

  partialLoad: boolean = false;
  isLoading: boolean = false;

  constructor(public financingService: FinancingService) {}

  ngOnInit(): void {
    if (this.uploadDocuments?.length) {
      this.load = true;
      this.error = false;
      this.financingService.showDocumentError = false;
      this.document = this.uploadDocuments;
    }
  }

  fileChangeEvent(event: any): void {
    this.showDocument.emit(true);

    const files: any = event?.target?.files;
    let numberOfFilesToUpload: number = files?.length;
    let filesWithErrors: number = 0;

    if (files && numberOfFilesToUpload && numberOfFilesToUpload <= 2) {
      let isOver5MB: boolean = false;
      for (let i = 0; i < numberOfFilesToUpload; i++) {
        if (files[i].size > 5242880) {
          isOver5MB = true;
          break;
        }
      }

      if (isOver5MB) {
        this.error = true;
        this.errorMessage = 'El tamaño del archivo no puede superar los 5MB';
        return;
      }

      this.error = false;
      this.financingService.showDocumentError = false;

      if (numberOfFilesToUpload == 2) {
        let validatedFiles: any = [];
        for (let i = 0; i < numberOfFilesToUpload; i++) {
          if (!this.isAValidType(files[i].type)) {
            this.error = true;
            this.load = false;
            filesWithErrors++;
          } else {
            validatedFiles.push(files[i]);
          }
        }

        if (validatedFiles.length == 1) {
          this.uploadImage(validatedFiles[0]);
          this.error = false;
        }
        if (validatedFiles.length == 2) {
          this.document.length == 1 ? this.uploadImage(files[0], true) : this.uploadImage(files[0], files[1], true);
        }
      } else {
        if (!this.isAValidType(files[0].type)) {
          this.error = true;
          this.load = false;
          filesWithErrors++;
        } else {
          this.uploadImage(files[0]);
        }
      }

      if (this.error) this.errorMessage = this.getErrorMessage(filesWithErrors);
    } else {
      this.error = true;
    }
  }

  private isAValidType(type: any) {
    return /(jpe?g|png|pdf)$/i.test(type);
  }

  private uploadImage(file: any, file2: any = {}, uploadTwoDocuments: boolean = false) {
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const image = reader.result.split(',')[1];
      let filesUploaded = this.document.length;

      const document = { files: [{ type: `boleta${filesUploaded + 1}`, value: image }] };
      const element = this.getUploadElement(filesUploaded);

      this.financingService.documentsProgress.push(element);
      this.financingService.documentsProgressShadow.push(element);

      this.isLoading = true;

      if (filesUploaded == 0) {
        this.document.push({ name: file.name, image: image });
        this.partialLoad = true;
        this.financingService.postPreapproveFiles(document, this.financingService.solicitudeId).subscribe(
          (resp) => {
            this.document[filesUploaded].id = resp?.body?.files[0]?.solicitudeFileId;
            if (!this.document[filesUploaded].id) return this.rollbackUploadImage();

            this.documents.emit(this.document);
            this.uploadDocument.emit(true);
            this.load = false;
            this.error = false;
            this.isLoading = false;
            this.financingService.removeDocumentUploaded();

            if (uploadTwoDocuments) {
              this.isLoading = true;
              setTimeout(async () => {
                this.uploadImage(file2);
              }, 1000);
            }
          },
          (error) => {
            this.rollbackUploadImage();
          }
        );
      } else if (filesUploaded == 1) {
        this.partialLoad = false;
        this.document.push({ name: file.name, image: image });
        this.financingService.postPreapproveFiles(document, this.financingService.solicitudeId).subscribe(
          (resp) => {
            this.document[filesUploaded].id = resp?.body?.files[0]?.solicitudeFileId;
            if (!this.document[filesUploaded].id) return this.rollbackUploadImage();
            this.documents.emit(this.document);
            this.uploadDocument.emit(true);
            this.load = true;
            this.error = false;
            this.isLoading = false;
            this.financingService.removeDocumentUploaded();
          },
          (error) => {
            this.rollbackUploadImage();
          }
        );
      }
    };
  }

  private rollbackUploadImage(): void {
    this.isLoading = false;
    this.error = true;
    this.partialLoad = false;
    this.document.pop();
    this.financingService.removeDocumentUploaded();
  }

  private getErrorMessage(filesWithErrors: number) {
    return filesWithErrors === 1 ? ErrorMessagesInvoice.oneInvoice : ErrorMessagesInvoice.twoInvoice;
  }

  private getUploadElement(index: number) {
    return {
      id: index,
      progress: 0,
      uploading: true,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.progress.currentValue == 100) {
      this.uploadDocument.emit(true);
      this.load = true;
    }
  }

  private isImageGreaterThanXMB(file: File, sizeLimit: number = 10): boolean {
    const fileSizeInMB = +(file.size / 5120 / 1024).toFixed(4);
    return fileSizeInMB > sizeLimit;
  }

  close() {
    this.error = false;
  }

  deleteDocument(documentItem: any) {
    const indexOfObject = this.document.findIndex((object) => {
      return object.id === documentItem?.id;
    });

    if (indexOfObject !== -1) {
      this.financingService.deleteImage(documentItem?.id).subscribe((resp) => {
        this.financingService.showDocumentError = false;
        this.document.splice(indexOfObject, 1);
        if (this.document.length == 1) {
          this.partialLoad = true;
          this.load = false;
        }
        if (this.document.length == 0) {
          this.partialLoad = false;
          this.load = false;
        }
        this.documents.emit(this.document);
      });
    }
  }
}

<div class="d-flex flex-column vh-100">
  <div id="#yevoHeader">
    <yevo-header class="fixed-header"
      [links]="headerLinks"
      [fluid]="true"
      [showLogoDesktop]="false"
      [mobileButtonRight]="true"
      [shadow]="false"
      [newDashoard]="newDashoard"
      (mobileMenuIsExpanded)="mobileMenuIsExpanded($event)"
    >
    </yevo-header>
    <main *ngIf="!isMenuMobileExpande" class="header-mobile">
      <router-outlet></router-outlet>
    </main>
  </div>
  <footer *ngIf="!isMenuMobileExpande">
    <yevo-footer></yevo-footer>
  </footer>
</div>
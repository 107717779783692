<ngb-carousel
  class="bannerBenefits_allied col-12 pl-md-0 pr-md-0"
  [ngClass]="!loader ? 'bannerBenefits_color_allied' : ''"
  [interval]="5000"
  [showNavigationIndicators]="true"
  [showNavigationArrows]="true"
>
  <ng-template ngbSlide *ngFor="let item of images; let i = index">
    <ngx-skeleton-loader
      *ngIf="!loader"
      [appearance]="'line'"
      count="1"
      animation="pulse"
      [theme]="{
        'background-color': '#F5F5F5',
        'min-width': '137px',
        'max-width': '1140px',
        'min-height': '112px',
        'max-height': '244px',
        width: '100%',
        height: '244px',
        'margin-bottom': 0,
        'padding-bottom': '0'
      }"
    ></ngx-skeleton-loader>

    <div (click)="goToBanner(item); selectedLinkCard(item.title)" class="benefit_allieds__link" *ngIf="loader">
      <img [src]="item.desktop" alt="banner" class="w-100 benefit_allieds__banner d-none d-xl-block" />
      <img [src]="item.laptop" alt="banner" class="w-100 benefit_allieds__banner d-none d-lg-block d-xl-none" />
      <img [src]="item.tablet" alt="banner" class="w-100 benefit_allieds__banner d-none d-md-block d-lg-none" />
      <img [src]="item.mobile" alt="banner" class="w-100 benefit_allieds__banner d-block d-md-none" />
    </div>
  </ng-template>
</ngb-carousel>

import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { getModalOptionsWithCustomClass } from '@shared/constants/ui-modal.constants';
import { IFormTip } from '@shared/interfaces';

@Component({
  selector: 'yevo-modal-dni',
  templateUrl: './modal-dni.component.html',
  styleUrls: ['./modal-dni.component.scss'],
})
export class ModalDniComponent {
  constructor(private modalService: NgbModal) {}

  @Input() tip!: IFormTip;
  @Input() birthdayInput: boolean = false;
  @Input() moveToRight: boolean = false;
  @Input() mobile: boolean = false;
  @Input() designV2: boolean = false;

  modalReference!: NgbModalRef;

  openModal(content: any): void {
    this.modalReference = this.modalService.open(content, getModalOptionsWithCustomClass('custom-modal-seedcash'));
  }

  closeModal(): void {
    this.modalReference?.close();
  }
}

<div class="row">
  <div class="col-12" [ngClass]="cardThree ? 'col-md-4' : 'col-md-6'">
    <label
      *ngIf="showLabels"
      class="dropdown-label"
      [ngClass]="activateClassForAlicoprOrWings ? 'financing-evaluation__label' : ''"
    >
      {{ labels.department }}<span *ngIf="obligatory">*</span>
    </label>
    <yevo-dropdown
      #departmentDropdown
      [items]="departments"
      [itemSelected]="departmentSelected"
      [buttonLabel]="'Departamento'"
      [disabled]="allDisabled"
      [showItemsOnMobile]="showItemsOnMobileVersion"
      [menuHeightOnMobile]="dropdownsConfig[0]?.menuHeightOnMobile"
      [containerTitle]="dropdownsConfig[0]?.containerTitle"
      [scrollActivated]="true"
      [canRemoveActiveItem]="canRemoveActive"
      (clickDropdown)="handleDepartmentDropdownClick()"
      (selectItem)="setDepartment($event, true)"
      [customClass]="showDepartmentError ? 'error' : ''"
    ></yevo-dropdown>
    <label *ngIf="showDepartmentError" class="form-inline">
      <div class="text-error">Departamento es requerido.</div>
    </label>
  </div>
  <div class="col-12" [ngClass]="{ 'col-md-4': cardThree, 'col-md-6': !cardThree, 'pl-lg-0': seedCashStyle }">
    <label
      *ngIf="showLabels"
      class="dropdown-label"
      [ngClass]="activateClassForAlicoprOrWings ? 'financing-evaluation__label' : ''"
    >
      {{ labels.province }}<span *ngIf="obligatory">*</span>
    </label>
    <yevo-dropdown
      #provinceDropdown
      [items]="provinces"
      [itemSelected]="provinceSelected"
      [buttonLabel]="'Provincia'"
      [disabled]="allDisabled || !departmentSelected"
      [showItemsOnMobile]="showItemsOnMobileVersion"
      [menuHeightOnMobile]="dropdownsConfig[1]?.menuHeightOnMobile"
      [containerTitle]="dropdownsConfig[1]?.containerTitle"
      [scrollActivated]="true"
      [canRemoveActiveItem]="canRemoveActive"
      (clickDropdown)="handleProvinceDropdownClick()"
      (selectItem)="setProvince($event, true)"
      [customClass]="showProvinceError ? 'error' : ''"
    ></yevo-dropdown>
    <label *ngIf="showProvinceError" class="form-inline">
      <div class="text-error">Provincia es requerida.</div>
    </label>
  </div>

  <div class="col-12" [ngClass]="cardThree ? 'col-md-4' : 'col-md-6'">
    <label
      *ngIf="showLabels"
      class="dropdown-label"
      [ngClass]="activateClassForAlicoprOrWings ? 'financing-evaluation__label' : ''"
    >
      {{ labels.district }}<span *ngIf="obligatory">*</span>
    </label>
    <yevo-dropdown
      #districtDropdown
      [items]="districts"
      [itemSelected]="districtSelected"
      [buttonLabel]="'Distrito'"
      [disabled]="allDisabled || !provinceSelected"
      [showItemsOnMobile]="showItemsOnMobileVersion"
      [menuHeightOnMobile]="dropdownsConfig[2]?.menuHeightOnMobile"
      [containerTitle]="dropdownsConfig[2]?.containerTitle"
      [scrollActivated]="true"
      [canRemoveActiveItem]="canRemoveActive"
      (clickDropdown)="handleDistrictDropdownClick()"
      (selectItem)="setDistrict($event, true)"
      [customClass]="showDistrictError ? 'error' : ''"
    ></yevo-dropdown>
    <label *ngIf="showDistrictError" class="form-inline">
      <div class="text-error">Distrito es requerido.</div>
    </label>
  </div>

  <div class="col-12 col-md-6" *ngIf="!cardThree" [ngClass]="{ 'pl-lg-0': seedCashStyle, 'd-none': hideFourthField }">
    <div class="form-group">
      <label
        class="control-label"
        class="dropdown-label"
        [ngClass]="activateClassForAlicoprOrWings ? 'financing-evaluation__label' : ''"
        >{{ labelPhone }}</label
      >
      <input
        type="text"
        class="form-control text_14"
        [ngClass]="invalid ? ' ng-dirty ng-touched ng-invalid' : ' ng-dirty ng-touched ng-valid'"
        [placeholder]="placeholderPhone"
        minlength="9"
        maxlength="9"
        [(ngModel)]="businessPhone"
        (input)="onBusinessPhoneChange()"
        (focus)="onBusinessPhoneFocus()"
      />
      <label class="form-inline text_14">
        <div *ngIf="showError" class="text-error">
          <div *ngIf="getErrorsPhoneField.minlength || getErrorsPhoneField.validPhone">Celular inválido.</div>
          <div *ngIf="getErrorsPhoneField.required">El celular es requerido.</div>
          <div *ngIf="!!getErrorsPhoneField.onlyNumber">Solo se admiten números.</div>
        </div>
      </label>
    </div>
  </div>
</div>

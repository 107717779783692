<div
  [ngClass]="{
    'div__videos-container__color': showTitleCategories,
    'div__videos-container__color__educational': !showTitleCategories
  }"
>
  <div class="p-0 videos-container">
    <div class="row" *ngIf="showTitleCategories">
      <h4 class="title__videos">¡Videos prácticos para tu negocio!</h4>
    </div>
    <div class="row" *ngIf="showTitleCategories">
      <p class="paragraph__videos">Aprende nuevas cosas de manera 100% práctica e interactiva en cuestión de minutos</p>
    </div>
    <div class="row" *ngIf="showTitleCategories">
      <p class="text-category">Categorías</p>
    </div>
    <div class="category-mobile">
      <ngx-slick-carousel #slickCarouselVideo [config]="slickConfig" style="margin-bottom: 20px">
        <div ngxSlickItem *ngFor="let item of category; let i = index" class="slide">
          <button
            type="button"
            class="btn btn-category"
            [ngClass]="{ 'btn-category-all': item.name === 'Ver todo', active: isPressed[i] }"
            (click)="toggleButton(i)"
          >
            {{ item.name }}
          </button>
        </div>
      </ngx-slick-carousel>
    </div>
    <div class="row category-desktop">
      <div class="m-md-1 m-lg-1" *ngFor="let item of category; let i = index">
        <button
          type="button"
          class="btn btn-category"
          [ngClass]="{ 'btn-category-all': item.name === 'Ver todo', active: isPressed[i] }"
          (click)="toggleButton(i)"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="swiper-section">
      <swiper #swiperBusiness [config]="config">
        <ng-template swiperSlide *ngFor="let videoBusiness of videosBusinessShow; let i = index">
          <div class="row m-0">
            <div class="col-sm-12 col-md-12 m-0 p-0">
              <yevo-video-communities
                [videoSecctionIntenal]="this.videoSecctionIntenal"
                [video]="videoBusiness"
                class="card__videos__business"
                [source]="source"
                [sourceClick]="sourceHome"
              ></yevo-video-communities>
            </div>
          </div>
        </ng-template>
      </swiper>
      <div class="swiper-button-videos-prev">
        <img src="assets/icons/communities/arrow-left.svg" alt="arrow" />
      </div>
      <div class="swiper-button-videos-next">
        <img src="assets/icons/communities/arrow-right.svg" alt="arrow" />
      </div>
    </div>
    <div class="row p-0 m-auto">
      <a class="btn card__videos__business__button_more" (click)="goToSeeMoreVideos(); seeMore()">Ver más videos</a>
    </div>
  </div>
</div>

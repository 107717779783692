<div>
    <div class="container price_finder_page">
        <div class="row">
            <div class="breadcrumb mt-4 ml-3">
                <a (click)="goHomeSearchPrice()"><img src="/assets/img/savings-account/left-arrow.svg" alt="arrow" />
                    &nbsp;<span class="active">Inicio</span>&nbsp;</a>
                / &nbsp;Comparador de Precios
            </div>
        </div>
    </div>

    <div *ngIf="loading; else errorOrData">
        <div class="resul-page">
            <div class="resul-title">
                <div class="title_  __desktop ">¡{{this.user.name}}, encontramos información de
                    {{this.dataResult.length}} productos!
                </div>
                <img class="resul-title-img" src="/assets/icons/communities/check-circle-outline.svg" alt="arrow">
                <div class="date_">{{formattedDate }}</div>
                <div class="title_  __mobile">¡{{this.user.name}}, encontramos información de {{this.dataResult.length}}
                    productos!
                </div>
            </div>
            <div class="result-content">
                <div class="container-product" *ngFor="let item of this.elementVisible">
                    <div class="container-product-title">
                        <label class="product-title" for="">{{item.productVarietyName}}</label>
                        <label class="product-price" for="">S/ {{item.price}}</label>
                    </div>
                    <div class="label-product" for="">{{item.productName}}</div>
                    <div class="label-mercado" for="">{{item.marketName}}</div>
                </div>
                <div class="btn-ver-mas-button">
                    <div>
                        <label for="">1 - {{countProduct}} de {{this.dataResult.length}} productos</label>
                    </div>
                    <div *ngIf="moreProduct">
                        <button type="button" class="btn btn-link ver-mas-button" (click)="getMoreElement()">
                            Ver 10 productos más
                        </button>
                    </div>

                </div>

                <div class="result-label-container">
                    <div class="result-label-generic">
                        <p class="p-0_"> Precio Promedio (S/. x Kg., S/. x Lt. o S/. x Unid.).</p>
                        <p class="p-0_">Fuente: Mercados Mayoristas de Lima Metropolitana</p>
                        <p class="p-0_">Elaboración: MIDAGRI-DGSEP-DEIA - Área de Comercio</p>
                    </div>
                </div>

                <div class="term-consult__ d-flex">
                    <div class="contenedor_img"><img class="img_consult" src="/assets/icons/communities/info.png"
                            alt="info"></div>
                    <div class="contenedor_text__">
                        <div class="title_text_">A tener en cuenta</div>
                        <div class="descriptions_text_">Los precios mostrados pueden variar dependiendo de los
                            proveedores y del Sistema de Abastecimiento y Precios (SISAP). Cualquier consulta adicional,
                            sírvase comunicarse con el MIDAGRI al <label class="numero_text_">(511) 209 8600</label>
                        </div>
                    </div>

                </div>
            </div>
            <div class="result-conditions"></div>
            <div class="pd-btn">
                <yevo-button-animated class="d-flex " [label]="'Realizar nueva consulta'" [type]="'button'"
                    (buttonClick)="returnConsult()">
                </yevo-button-animated>
            </div>
            <div class="init-button" (click)="goHomeSearchPrice()">ir al Inicio </div>
        </div>
    </div>


    <ng-template #errorOrData>
        <div *ngIf="error">
            <div>
                <div class="container ">
                    <div class="row">
                        <div class="col-12 error-container">
                            <div class="terms_circule"> <img class="terms_img" src="/assets/icons/communities/Error.svg"
                                    alt="arrow"></div>
                            <div class="">
                                <div class="terms_title">Se ha producido un error</div>
                            </div>
                            <div class="terms_descriptions">Estamos trabajando para solucionarlo. Vuelve a internarlo en
                                unos minutos.</div>
                            <div class="btn-conatainer">
                                <button type="button" class="btn btn-link terms_button" (click)="goHomeSearchPrice()">
                                    ir al Inicio
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="notData">
            <div>
                <div class="container ">
                    <div class="row">
                        <div class="col-12 error-container">
                            <div class="terms_circule"> <img class="terms_img"
                                    src="/assets/icons/communities/group-house.svg" alt="arrow"></div>
                            <div class="">
                                <div class="terms_title">No encontramos productos</div>
                            </div>
                            <div class="terms_descriptions">Vuelve a buscar o prueba con un producto diferente.</div>
                            <div class="btn-conatainer">
                                <button type="button" class="btn btn-link terms_button" (click)="returnConsult()">
                                    Realizar nueva consulta
                                </button>
                                <div>
                                    <p class="link_inicio_" (click)="goHomeSearchPrice()">Ir al Inicio</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="cloud-container d-none d-lg-block">
        <img class="img-fluid w-100" src="/assets/img/savings-account/clouds.svg" alt="clouds" />
    </div>
</div>
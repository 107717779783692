export enum REGISTER_SOURCE_CODE {
  DEFAULT = 'basicFlow',
  FINANCING = 'externalFinancingFlow',
  SEEDCASH = 'externalLeadFinancingFlow',
}

export const REGISTER_TITLE = [
  {
    title: 'Crea tu cuenta en Mibanco Lab',
    description:
      '<br class="d-block" />y accede a los servicios que tenemos para ti.<br /><strong>¡Registro 100% gratis para todos, seas o no cliente Mibanco!</strong>',
    descriptionmovil:
      'Tenemos más videos que te <br> ayudarán a potenciar tu negocio <br> <strong>¡Míralos hoy!</strong>',
  },
  {
    title: '¡Regístrate en Mibanco Lab y certifícate hoy!',
    description:
      'Ahora que terminaste el curso <br class="d-block d-md-none" /><strong>¡Obtén GRATIS tu certificado otorgado por Mibanco!</strong>',
    descriptionmovil: '',
  },
  {
    title: '¡Regístrate en Mibanco Lab y <br class="d-block d-md-none"> Sigue aprendiendo!',
    description:
      'Ahora que terminaste el curso <br class="d-block d-md-none"> Tenemos más videos que te ayudarán a potenciar tu <br class="d-block d-md-none" />negocio <strong>¡Míralos hoy!</strong>',
    descriptionmovil:
      'Tenemos más videos que te <br> ayudarán a potenciar tu negocio <br> <strong>¡Míralos hoy!</strong>',
  },
];

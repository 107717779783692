<div class="tab-content">
    <ul class="tabs-nav">
      <li *ngFor="let item of detailToDoToday; let i = index" (click)="setActiveTab(i); $event.preventDefault()"
       [class.tab-active]="i === activeTab">
        <a href="#">{{ item.question }}</a>
      </li>
    </ul>
    <div class="tabs-stage">
      <div *ngFor="let item of detailToDoToday; let i = index" [style.display]="i === activeTab ? 'block' : 'none'">
        <div class="body">
          <div class="frase" [innerHTML]="item.parrafDescription"></div>
          <div class="d-flex content-step" *ngFor="let o of item.list">
            <div class="number_step">
              <img [src]="o.imageStep" alt="">
              <div *ngIf="o.show" class="block"></div>
            </div>
            <p [innerHTML]="o.description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
 
 
 

  

  
<ld-json-script></ld-json-script>
<div class="container main-container">
  <div class="hero-container">
    <a (click)="selectBanner.click()">
      <input type="file" (change)="uploadHeader($event)" class="d-none" #selectBanner accept="image/png,image/jpeg" />
      <div class="hero-banner position-relative" [style.background]="'url(' + imageHeaderUrl + ')'">
        <div class="edit-icon-banner">
          <img src="assets/icons/intranet/edit-user-profile.svg" alt="edit-icon" />
        </div>
      </div>
    </a>
    <div class="hero hero-image container-fluid d-flex flex-column justify-content-center">
      <div class="avatar-container d-flex align-items-center mt-auto">
        <a (click)="selectImage.click()" class="upload-image">
          <div class="circle-border" style="--progress: {{ radianPercentage }}deg;">
            <div class="img-fluid avatar" [style.background]="'url(' + image + ')'"></div>
          </div>
          <input
            type="file"
            (change)="fileChangeEvent($event)"
            class="d-none"
            #selectImage
            accept="image/png,image/jpeg"
          />
          <div class="edit-icon-container">
            <img src="assets/icons/intranet/edit-user-profile.svg" alt="edit-icon" />
          </div>
        </a>
      </div>
    </div>
    <div class="hero container-fluid d-flex flex-column justify-content-center">
      <div class="d-flex align-items-center mt-0 mb-3">
        <div class="title-container d-flex flex-column justify-content-center">
          <div class="tittle-perfil-redes d-flex">
            <p class="user-percent m-0">Perfil completado {{ percentage }}%</p>
            <div class="icons version-desktop">
              <yevo-intranet-social-icons
                [profileSocialMediaLinks]="profileSocialMediaLinks"
              ></yevo-intranet-social-icons>
            </div>
          </div>

          <h1 class="user-name m-0">{{ user?.fullName }}</h1>
          <h2 class="user-bussines font-weight-bold m-0">{{ user?.userInfoResponse?.businessName }}</h2>
          <div class="version-movil m-0 mt-2">
            <yevo-intranet-social-icons
              [profileSocialMediaLinks]="profileSocialMediaLinks"
            ></yevo-intranet-social-icons>
          </div>
        </div>
      </div>
      <div class="tabs-container mt-auto">
        <div class="tabs overflow mb-0">
          <ng-container *ngFor="let item of tabs">
            <yevo-intranet-tab [title]="item.title" [link]="item.link"></yevo-intranet-tab>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="image-uploader-container" [class.cropper-session]="cropperSession">
      <div class="image-uploader auto-size" [class.hidden]="!cropperSession">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="false"
          [autoCrop]="false"
          [aspectRatio]="4 / 3"
          [resizeToWidth]="512"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      </div>
      <ng-template [ngIf]="cropperSession">
        <div class="m-1">
          <button class="btn btn-outline-primary" type="button" (click)="cancelCropSession()">
            <svg-icon-sprite src="close" width="14px" height="14px" class="mr-1"></svg-icon-sprite>
            <span class="button-text">cancelar</span>
          </button>
          <button class="btn btn-primary" type="button" style="margin-left: 5px" (click)="acceptCropSession()">
            <svg-icon-sprite src="check" width="15px" height="14px" class="mr-1" classes="icon-check"></svg-icon-sprite>
            <span class="button-text">aceptar</span>
          </button>
        </div>
      </ng-template>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>

<div class="container mt-4 scroll-x-hidden">
  <div class="row">
    <div class="col-md-12">
      <div class="card" [ngClass]="error || financingService.showSelfieError ? 'bgError' : 'bg'">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 text-center">
              <img
                src="assets/financing/files/camera.svg"
                alt="icon"
                (click)="camera()"
                *ngIf="!show && !load && !error && !isLoading"
              />
              <img
                *ngIf="load && !error && !isLoading"
                src="assets/financing/files/upload-success.svg"
                alt="success-icon"
              />
              <img *ngIf="error" src="assets/financing/files/error-file.svg" alt="error-icon" />
              <img *ngIf="isLoading" src="assets/financing/files/uploading.svg" alt="uploading-icon" />

              <video id="stream" [ngClass]="show ? 'show' : 'hidden'"></video>
              <canvas id="capture" [width]="CANVAS_INIT_WIDTH" [height]="CANVAS_INIT_HEIGHT" class="hidden"></canvas>

              <div class="mt-3">
                <yevo-button-animated
                  *ngIf="show && !error"
                  [label]="'Guardar selfie'"
                  class="w-100"
                  [style]="'outline'"
                  (buttonClick)="takePhoto()"
                  [type]="'button'"
                ></yevo-button-animated>
              </div>
            </div>

            <div class="col-12" *ngIf="!load && !error && !isLoading">
              <h2 class="title mt-3">
                Tómate una foto selfie con tu <br class="d-block d-md-none" />
                negocio o mercadería
              </h2>
              <p class="subTitle mt-2">Asegúrate de que sea de alta calidad y que represente fielmente a tu negocio.</p>
              <yevo-button-animated
                *ngIf="!show"
                [label]="'Tomar foto'"
                class="w-100"
                [style]="'outline'"
                (buttonClick)="camera()"
              ></yevo-button-animated>
            </div>
            <div class="col-12 pr-0" *ngIf="load && !error">
              <h2 class="title mb-3">
                ¡Foto subida con <br />
                éxito!
              </h2>
              <div class="d-flex justify-content-center align-items-center">
                <div class="w-50">
                  <img class="w-100 uploaded-image" id="compressedImage" [src]="compressedImage" alt="selfie-image" />
                </div>
                <div class="w-50 pl-2">
                  <p class="selfie-name m-0 p-0">selfie.jpg</p>
                  <button
                    type="button"
                    class="btn btn-link p-0 d-flex align-items-center"
                    (click)="deleteSelfie()"
                    [ngClass]="{ 'd-none': false }"
                  >
                    <img src="assets/financing/files/delete.svg" alt="delete-icon" />
                    <span class="ml-2">Eliminar foto</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-12" *ngIf="error && !isLoading">
              <h2 class="title mt-3 mb-2">No se pudo subir la imagen</h2>
              <p class="subTitle mt-2 text-center">Sube una foto tuya en tiempo real.</p>
              <yevo-button-animated
                [label]="'Volver a tomar foto'"
                class="w-100 mt-3"
                [style]="'outline'"
                (buttonClick)="camera()"
              ></yevo-button-animated>
            </div>

            <div class="col-md-12" *ngIf="isLoading && !load && !error">
              <h2 class="title mt-3 mb-2">
                Estamos subiendo <br />
                tu fotografía.
              </h2>
              <div class="d-flex justify-content-center align-items-center">
                <div class="w-50">
                  <img class="w-100 uploaded-image" id="compressedImage" [src]="compressedImage" alt="selfie-image" />
                </div>
                <div class="w-50 p-2">
                  <p class="selfie-name m-0 p-0">selfie.jpg</p>
                  <div class="d-block w-100">
                    <div class="d-flex align-items-center">
                      <div class="d-block progress w-50">
                        <div
                          class="progress-bar bg-success-bar"
                          role="progressbar"
                          attr.aria-valuenow="{{ progress }}"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          [ngStyle]="{ width: progress + '%' }"
                        ></div>
                      </div>
                      <span class="d-block ml-2 progress-bar-text">{{ progress }}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

// tslint:disable: no-string-literal
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { FormGroup } from '@angular/forms';
import { GeolocationService } from '@shared/services/geolocation.service';
import { IDropdownConfigParam, IDropdownItem, ISelectItem } from '@shared/interfaces/IDropdownItem';

@Component({
  selector: 'yevo-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.scss'],
})
export class GeolocationComponent implements OnInit, OnChanges {
  @Input() activitedSetPreFormValues = false;
  @Input() activitedPreFormValues = false;
  @Input() dataPreFormValues?: any;
  @Input() parentFormGroup?: FormGroup;
  @Input() geolocation?: string | string[];
  @Input() showLabels = false;
  @Input() labels = {
    department: 'Departamento',
    province: 'Provincia',
    district: 'Distrito',
  };
  @Input() allDisabled = false;
  @Input() obligatory = false;
  @Input() showItemsOnMobileVersion = false;
  @Input() emitValuesSeparately = false;
  @Input() canRemoveActive = true;
  @Input() labelStyle = false;
  @Input() dropdownsConfig: IDropdownConfigParam[] = [
    // Department dropdown configuration
    { containerTitle: 'Departamento', menuHeightOnMobile: '300px' },
    // Province dropdown configuration
    { containerTitle: 'Provincia', menuHeightOnMobile: '250px' },
    // District dropdown configuration
    { containerTitle: 'Distrito', menuHeightOnMobile: '250px' },
  ];
  @Input() locations: any;
  @Output() selectItem = new EventEmitter<string | null>();
  @Output() selectSeparatelyItem = new EventEmitter<IDropdownItem>();
  @Output() selectValueLabelItem = new EventEmitter<any | null>();
  @Output() setPreFormValues = new EventEmitter<any>();

  @ViewChild('departmentDropdown') departmentDropdown!: DropdownComponent;
  @ViewChild('provinceDropdown') provinceDropdown!: DropdownComponent;
  @ViewChild('districtDropdown') districtDropdown!: DropdownComponent;
  @Input() showGeolocationErrors = false;

  departments!: IDropdownItem[];
  provinces!: IDropdownItem[];
  districts!: IDropdownItem[];
  departmentSelected?: IDropdownItem;
  provinceSelected?: IDropdownItem;
  districtSelected?: IDropdownItem;

  geolocationFirstChange = false;

  showDepartmentError = false;
  showProvinceError = false;
  showDistrictError = false;

  constructor(public geolocationService: GeolocationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const geolocation: string = changes?.geolocation?.currentValue;

    if (changes?.locations?.currentValue) {
      this.departmentSelected = changes?.locations?.currentValue.department;
      this.provinceSelected = changes?.locations?.currentValue.province;
      this.districtSelected = changes?.locations?.currentValue.district;
    } else if (geolocation && !this.geolocationFirstChange) {
      if (typeof geolocation === 'string') {
        this.fillUpDropdowns(geolocation.substr(0, 2), geolocation.substr(2, 2), geolocation.substr(4, 2));
      } else if (typeof geolocation === 'object') {
        this.fillUpDropdownsObj(geolocation[0], geolocation[1], geolocation[2]);
      }
      this.geolocationFirstChange = true;
    } else {
      if (!changes?.allDisabled && !changes?.showGeolocationErrors) {
        this.departmentSelected = null!;
        this.provinceSelected = null!;
        this.districtSelected = null!;
      }
    }

    if (changes?.showGeolocationErrors?.currentValue) {
      this.showDepartmentError = !this.parentFormGroup?.controls['department'].value;
      this.showProvinceError = !this.parentFormGroup?.controls['province'].value;
      this.showDistrictError = !this.parentFormGroup?.controls['district'].value;
    } else {
      this.showDepartmentError = false;
      this.showProvinceError = false;
      this.showDistrictError = false;
    }

    if (changes?.activitedPreFormValues?.currentValue) {
      this.departmentSelected = this.departments.find(
        (item) => item.value === this.dataPreFormValues?.department || item.label === this.dataPreFormValues?.department
      );
      if (this.departmentSelected) {
        this.provinces = this.geolocationService.getProvincesAsDropdownItems(this.departmentSelected.value);
        this.provinceSelected = this.provinces.find(
          (item) => item.value === this.dataPreFormValues?.province || item.label === this.dataPreFormValues?.province
        );
        if (this.provinceSelected) {
          this.districts = this.geolocationService.getDistrictsAsDropdownItems(this.provinceSelected.value);
          this.districtSelected = this.districts.find(
            (item) => item.value === this.dataPreFormValues?.district || item.label === this.dataPreFormValues?.district
          );
        }
      }
      this.activitedPreFormValues = false;
    }
  }

  ngOnInit(): void {
    // Initialize Department dropdown options
    this.departments = this.geolocationService.getDepartmentsAsDropdownItems();
    // Autoselect pre-saved options
    if (this.parentFormGroup?.value) {
      const { department, province, district } = this.parentFormGroup.value;

      this.fillUpDropdowns(department, province, district);
    }
  }

  private fillUpDropdowns(department?: string, province?: string, district?: string): void {
    if (department) {
      this.setDepartment(
        {
          event: null,
          item: this.geolocationService.getDepartmentByIdAsDropdownItem(department),
        },
        this.geolocationService.getDepartmentByIdAsDropdownItem(department)
      );
    }
    if (department && province) {
      this.setProvince(
        {
          event: null,
          item: this.geolocationService.getProvinceByIdAsDropdownItem(department + province),
        },
        this.geolocationService.getProvinceByIdAsDropdownItem(department + province)
      );
    }
    if (department && province && district) {
      this.setDistrict(
        {
          event: null,
          item: this.geolocationService.getDistrictByIdAsDropdownItem(department + province + district),
        },
        this.geolocationService.getDistrictByIdAsDropdownItem(department + province + district)
      );
    }
  }

  private fillUpDropdownsObj(department?: string, province?: string, district?: string): void {
    if (department) {
      this.setDepartment({
        event: null,
        item: this.geolocationService.getDepartmentByIdAsDropdownItem(department, true),
      });
    }
    if (department && province) {
      this.setProvince({
        event: null,
        item: this.geolocationService.getProvinceByIdAsDropdownItem(province, true),
      });
    }
    if (department && province && district) {
      this.setDistrict({
        event: null,
        item: this.geolocationService.getDistrictByIdAsDropdownItem(district, true),
      });
    }
  }

  setDepartment($event: ISelectItem, emitValue?: any): void {
    // TODO: Refactor to remove FormGroup dependency
    if (this.parentFormGroup) {
      this.parentFormGroup?.controls['department'].setValue($event?.item?.value);
      this.parentFormGroup?.controls['province'].setValue(null);
      this.parentFormGroup?.controls['district'].setValue(null);
    }
    this.departmentSelected = $event.item;
    this.provinces = this.departmentSelected
      ? this.geolocationService.getProvincesAsDropdownItems(this.departmentSelected.value)
      : null!;
    this.provinceSelected = null!;
    this.districts = null!;
    this.districtSelected = null!;
    this.provinceDropdown?.closeDropdownMenu();
    this.districtDropdown?.closeDropdownMenu();
    if (emitValue) {
      if (this.showGeolocationErrors) this.showDepartmentError = false;
      this.emitCurrentValue(this.departmentSelected);
    }
    if (this.activitedSetPreFormValues) {
      this.setPreFormValues?.emit();
    }
  }

  setProvince($event: ISelectItem, emitValue?: any): void {
    // TODO: Refactor to remove FormGroup dependency
    if (this.parentFormGroup) {
      this.parentFormGroup?.controls['province'].setValue($event?.item?.value);
      this.parentFormGroup?.controls['district'].setValue(null);
    }
    this.provinceSelected = $event.item;
    this.districts = this.provinceSelected
      ? this.geolocationService.getDistrictsAsDropdownItems(this.provinceSelected.value)
      : null!;
    this.districtSelected = null!;
    this.departmentDropdown?.closeDropdownMenu();
    this.districtDropdown?.closeDropdownMenu();
    if (emitValue) {
      if (this.showGeolocationErrors) this.showProvinceError = false;
      this.emitCurrentValue(this.provinceSelected);
    }
    if (this.activitedSetPreFormValues) {
      this.setPreFormValues?.emit();
    }
  }

  setDistrict($event: ISelectItem, emitValue?: any): void {
    // TODO: Refactor to remove FormGroup dependency
    if (this.parentFormGroup) {
      this.parentFormGroup?.controls['district'].setValue($event?.item?.value);
    }
    this.districtSelected = $event.item;
    this.departmentDropdown?.closeDropdownMenu();
    this.provinceDropdown?.closeDropdownMenu();
    if (emitValue) {
      if (this.showGeolocationErrors) this.showDistrictError = false;
      this.emitCurrentValue(this.districtSelected);
    }
    if (this.activitedSetPreFormValues) {
      this.setPreFormValues?.emit();
    }
  }

  handleDepartmentDropdownClick(): void {
    this.provinceDropdown?.closeDropdownMenu();
    this.districtDropdown?.closeDropdownMenu();
  }
  handleProvinceDropdownClick(): void {
    this.departmentDropdown?.closeDropdownMenu();
    this.districtDropdown?.closeDropdownMenu();
  }
  handleDistrictDropdownClick(): void {
    this.departmentDropdown?.closeDropdownMenu();
    this.provinceDropdown?.closeDropdownMenu();
  }

  emitCurrentValue(separatedValue?: IDropdownItem): void {
    this.selectValueLabelItem.emit(separatedValue);
    if (this.emitValuesSeparately) {
      this.selectSeparatelyItem.emit(separatedValue);
      return;
    }

    const geolocation =
      '01' +
      (this.departmentSelected?.value || '') +
      (this.provinceSelected?.value.substr(2, 2) || '') +
      (this.districtSelected?.value.substr(4, 2) || '');
    this.selectItem.emit(geolocation !== '01' ? geolocation : null);
  }
}

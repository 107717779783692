<!-- as dropdown  -->
<ul>
  <li
    *ngIf="asDropdown"
    class="nav-item dropdown"
    [class.active]="isActive"
    ngbDropdown
    [(open)]="servicesNavExpanded"
    (mouseenter)="onHover($event)"
  >
    <a role="button" id="servicesNav" ngbDropdownToggle class="nav-link d-flex link-menu">
      <span>{{ title }}</span>
      <span class="ml-auto ml-lg-2 wrap-icon">
        <svg-icon-sprite
          [src]="servicesNavExpanded ? 'up_arrow' : 'down_arrow'"
          [width]="'12px'"
          [height]="'12px'"
        ></svg-icon-sprite>
      </span>
    </a>
    <div
      ngbDropdownMenu
      class="dropdown-menu services"
      aria-labelledby="servicesNav"
      (mouseleave)="onHoverLeave($event)"
    >
      <div class="cont-menu">
        <div class="opt-menu pdl">
          <p class="link-menu-title mt-3">Te ofrecemos...</p>
          <div class="row">
            <div class="col-md-6">
              <ul>
                <li
                  class="p-0 sub-nav-item position-static"
                  [class.active]="isActiveSubMenu"
                  ngbDropdown
                  [display]="'dynamic'"
                  placement="right-bottom"
                  id="directorio"
                  (mouseover)="changeTitleBanner('Directorio de negocios', '/servicios/directorio')"
                >
                  <a
                    role="button"
                    class="dropdown-item"
                    id="directorio"
                    ngbDropdownToggle
                    routerLink="/servicios/directorio"
                    queryParamsHandling="merge"
                  >
                    <span>Directorio de negocios</span>
                    <span class="ml-auto ml-lg-2 wrap-icon">
                      <svg-icon-sprite [src]="'next'" [width]="'16px'"></svg-icon-sprite>
                    </span>
                  </a>
                </li>

                <li
                  ngbDropdownItem
                  class="p-0 sub-nav-item position-static"
                  [class.active]="isActiveSubMenu"
                  ngbDropdown
                  [display]="'dynamic'"
                  placement="right-bottom"
                  (mouseenter)="onHover($event)"
                  (mouseleave)="onHover($event)"
                  id="capacitaciones"
                  (mouseover)="changeTitleBanner('Capacitaciones', '/servicios/capacitaciones')"
                >
                  <a
                    role="button"
                    class="dropdown-item"
                    id="conoceNav"
                    data-target=".navbar-collapse.show"
                    ngbDropdownToggle
                    routerLink="/servicios/capacitaciones"
                    queryParamsHandling="merge"
                    routerLinkActive="active-link"
                  >
                    <span>Capacitaciones</span>
                    <span class="ml-auto ml-lg-2 wrap-icon">
                      <svg-icon-sprite [src]="'next'" [width]="'16px'"></svg-icon-sprite>
                    </span>
                  </a>
                </li>

                <li
                  ngbDropdownItem
                  class="p-0 sub-nav-item position-static"
                  [class.active]="isActiveSubMenu"
                  ngbDropdown
                  [display]="'dynamic'"
                  placement="right-bottom"
                  (mouseenter)="onHover($event)"
                  (mouseleave)="onHover($event)"
                  (mouseover)="changeTitleBanner('Tienda digital', '/servicios/mi-tienda')"
                >
                  <a
                    role="button"
                    class="dropdown-item"
                    id="conoceNav"
                    data-target=".navbar-collapse.show"
                    ngbDropdownToggle
                    routerLink="/servicios/mi-tienda"
                    queryParamsHandling="merge"
                    routerLinkActive="active-link"
                    id="tiendaDigital"
                  >
                    <span>Tienda digital</span>
                    <span class="ml-auto ml-lg-2 wrap-icon">
                      <svg-icon-sprite [src]="'next'" [width]="'16px'"></svg-icon-sprite>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul>
                <li
                  ngbDropdownItem
                  class="p-0 sub-nav-item position-static"
                  [class.active]="isActiveSubMenu"
                  ngbDropdown
                  [display]="'dynamic'"
                  placement="right-bottom"
                  (mouseenter)="onHover($event)"
                  (mouseleave)="onHover($event)"
                  id="financiamiento"
                  (mouseover)="changeTitleBanner('Financiamiento', '/financiamiento')"
                >
                  <a
                    role="button"
                    class="dropdown-item"
                    id="conoceNav"
                    data-target=".navbar-collapse.show"
                    ngbDropdownToggle
                    routerLink="/financiamiento"
                    queryParamsHandling="merge"
                    routerLinkActive="active-link"
                  >
                    <span>Financiamiento</span>
                    <span class="ml-auto ml-lg-2 wrap-icon">
                      <svg-icon-sprite [src]="'next'" [width]="'16px'"></svg-icon-sprite>
                    </span>
                  </a>
                </li>

                <li
                  ngbDropdownItem
                  class="p-0 sub-nav-item position-static"
                  [class.active]="isActiveSubMenu"
                  ngbDropdown
                  [display]="'dynamic'"
                  placement="right-bottom"
                  (mouseenter)="onHover($event)"
                  (mouseleave)="onHover($event)"
                  id="novedades"
                  (mouseover)="changeTitleBanner('YevoNovedades', '/novedades')"
                >
                  <a
                    role="button"
                    class="dropdown-item"
                    id="conoceNav"
                    data-target=".navbar-collapse.show"
                    ngbDropdownToggle
                    routerLink="/novedades"
                    queryParamsHandling="merge"
                    routerLinkActive="active-link"
                  >
                    <span>YevoNovedades</span>
                    <span class="ml-auto ml-lg-2 wrap-icon">
                      <svg-icon-sprite [src]="'next'" [width]="'16px'"></svg-icon-sprite>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="opt-menu-image bg-menu">
          <h4 class="title-banner mt-5 ml-3">{{ titleBanner }}</h4>
          <p class="saber-mas" *ngIf="external">
            <a class="link-banner" [href]="routerLink" queryParamsHandling="merge" target="_blank"
              >Saber más
              <span class="ml-auto ml-lg-2 wrap-icon">
                <svg-icon-sprite [src]="'next'" [width]="'16px'"></svg-icon-sprite> </span
            ></a>
          </p>
          <p class="saber-mas" *ngIf="!external">
            <a class="link-banner" routerLink="{{ routerLink }}" queryParamsHandling="merge"
              >Saber más
              <span class="ml-auto ml-lg-2 wrap-icon">
                <svg-icon-sprite [src]="'next'" [width]="'16px'"></svg-icon-sprite> </span
            ></a>
          </p>
        </div>
      </div>
    </div>
  </li>

  <!-- as regular list-->
  <li *ngIf="!asDropdown" class="nav-item" [class.active]="isActive" (click)="closeSolution()">
    <a class="nav-link d-flex link-title" id="servicesNav" role="button">
      <span>{{ title }}</span>
      <span class="ml-auto ml-lg-2 wrap-icon">
        <svg-icon-sprite
          [src]="solucionesNavExpanded ? 'up_arrow' : 'down_arrow'"
          [width]="'12px'"
          [height]="'12px'"
          (close)="closeSoluciones()"
        ></svg-icon-sprite>
      </span>
    </a>

    <div class="collapse" [(ngbCollapse)]="solucionesNavClosed" #solucionesCollapse="ngbCollapse">
      <yevo-header-solutions-dropdown
        [solutions]="solutionsNav"
        [asDropdownChild]="false"
        (navigate)="onNavigateHandler($event, { itemLocation: 3 })"
      ></yevo-header-solutions-dropdown>
    </div>
  </li>
</ul>

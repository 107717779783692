<div class="d-flex w-100 overflow icons-container">
  <a *ngFor="let link of profileSocialMediaLinks" [href]="link?.url" target="_blank">
    <div class="icon-container d-flex justify-content-center align-items-center">
      <svg-icon
        [src]="'/assets/icons/intranet/socialM/' + link?.code + '.svg'"
        [applyClass]="true"
        class="custom-icon"
      ></svg-icon>
    </div>
  </a>
</div>

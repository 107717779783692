// tslint:disable: no-string-literal
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { FormGroup } from '@angular/forms';
import { GeolocationService } from '@shared/services/geolocation.service';
import { IDropdownConfigParam, IDropdownItem, ISelectItem } from '@shared/interfaces/IDropdownItem';

@Component({
  selector: 'yevo-geolocation-grid',
  templateUrl: './geolocation-grid.component.html',
  styleUrls: ['./geolocation-grid.component.scss'],
})
export class GeolocationGridComponent implements OnInit, OnChanges {
  @Input() activedPreFilledValues: boolean = false;
  @Input() dataPreForm?: any;
  @Input() showError: boolean = false;
  @Input() cardThree: boolean = false;
  @Input() labelPhone: string = 'Teléfono del negocio o centro laboral';
  @Input() placeholderPhone: string = 'Ingresa aquí el télefono';
  @Input() parentFormGroup?: FormGroup;
  @Input() geolocation?: string | string[];
  @Input() businessPhone?: string | null;
  @Input() businessPhoneRequired: boolean = true;
  @Input() hideFourthField: boolean = false;
  @Input() showLabels = false;
  @Input() showGeolocationErrors = false;
  @Input() labels = {
    department: 'Departamento',
    province: 'Provincia',
    district: 'Distrito',
  };
  getErrorsPhoneField = {
    required: false,
    minlength: false,
    onlyNumber: false,
    validPhone: false,
  };

  @Input() activateClassForAlicoprOrWings: boolean = false;
  @Input() allDisabled = false;
  @Input() obligatory = false;
  @Input() showItemsOnMobileVersion = false;
  @Input() emitValuesSeparately = false;
  @Input() canRemoveActive = true;
  @Input() seedCashStyle = false;
  @Input() dropdownsConfig: IDropdownConfigParam[] = [
    // Department dropdown configuration
    { containerTitle: 'Departamento', menuHeightOnMobile: '300px' },
    // Province dropdown configuration
    { containerTitle: 'Provincia', menuHeightOnMobile: '250px' },
    // District dropdown configuration
    { containerTitle: 'Distrito', menuHeightOnMobile: '250px' },
  ];
  @Output() selectItem = new EventEmitter<string | null>();
  @Output() selectSeparatelyItem = new EventEmitter<IDropdownItem>();
  @Output() phone = new EventEmitter<string | null>();
  @Output() setPreFilledValues = new EventEmitter<boolean>();

  @ViewChild('departmentDropdown') departmentDropdown!: DropdownComponent;
  @ViewChild('provinceDropdown') provinceDropdown!: DropdownComponent;
  @ViewChild('districtDropdown') districtDropdown!: DropdownComponent;

  departments!: IDropdownItem[];
  provinces!: IDropdownItem[];
  districts!: IDropdownItem[];
  departmentSelected?: IDropdownItem;
  provinceSelected?: IDropdownItem;
  districtSelected?: IDropdownItem;

  geolocationFirstChange = false;
  invalid = false;

  showDepartmentError = false;
  showProvinceError = false;
  showDistrictError = false;

  dataLoaded: boolean = false;

  constructor(public geolocationService: GeolocationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const geolocation: string = changes?.geolocation?.currentValue;

    if (geolocation && !this.geolocationFirstChange) {
      if (typeof geolocation === 'string') {
        this.fillUpDropdowns(geolocation.substring(0, 2), geolocation.substring(2, 2), geolocation.substring(4, 2));
      } else if (typeof geolocation === 'object') {
        this.fillUpDropdownsObj(geolocation[0], geolocation[1], geolocation[2]);
      }
      this.geolocationFirstChange = true;
      this.dataLoaded = true;
    } else {
      if (!changes?.allDisabled && !changes?.showGeolocationErrors && !changes?.showError) {
        this.departmentSelected = null!;
        this.provinceSelected = null!;
        this.districtSelected = null!;
      }
    }

    if (changes?.showGeolocationErrors?.currentValue) {
      this.showDepartmentError = !this.parentFormGroup?.controls['department'].value;
      this.showProvinceError = !this.parentFormGroup?.controls['province'].value;
      this.showDistrictError = !this.parentFormGroup?.controls['district'].value;
    } else {
      this.showDepartmentError = false;
      this.showProvinceError = false;
      this.showDistrictError = false;
    }

    if (changes?.showError?.currentValue) {
      this.onBusinessPhoneFocus();
    }

    if (changes?.activedPreFilledValues?.currentValue) {
      this.businessPhone = this.dataPreForm?.businessPhone;
      this.departmentSelected = this.departments.find(
        (item) => item.label == this.dataPreForm?.department || item.value == this.dataPreForm?.department
      );
      if (this.selectSeparatelyItem) {
        this.selectSeparatelyItem.emit(this.departmentSelected);
      }
      if (this.departmentSelected) {
        this.provinces = this.geolocationService.getProvincesAsDropdownItems(this.departmentSelected?.value);
        this.provinceSelected = this.provinces.find(
          (item) => item.label == this.dataPreForm?.province || item.value == this.dataPreForm?.province
        );
        if (this.selectSeparatelyItem) {
          this.selectSeparatelyItem.emit(this.provinceSelected);
        }
        if (this.provinceSelected) {
          this.districts = this.geolocationService.getDistrictsAsDropdownItems(this.provinceSelected?.value);
          this.districtSelected = this.districts.find(
            (item) => item.label == this.dataPreForm?.district || item.value == this.dataPreForm?.district
          );
          if (this.selectSeparatelyItem) {
            this.selectSeparatelyItem.emit(this.districtSelected);
          }
        }
      }
      this.activedPreFilledValues = false;
    }
  }

  ngOnInit(): void {
    // Initialize Department dropdown options
    this.departments = this.geolocationService.getDepartmentsAsDropdownItems();
    // Autoselect pre-saved options
    if (this.parentFormGroup?.value && !this.dataLoaded) {
      const { department, province, district } = this.parentFormGroup.value;
      this.fillUpDropdowns(department, province, district);
    }
    if (this.showError) {
      this.onBusinessPhoneChange();
    }
  }

  private fillUpDropdowns(department?: string, province?: string, district?: string): void {
    if (department) {
      this.setDepartment({
        event: null,
        item: this.geolocationService.getDepartmentByIdAsDropdownItem(department),
      });
    }
    if (department && province) {
      this.setProvince({
        event: null,
        item: this.geolocationService.getProvinceByIdAsDropdownItem(department + province),
      });
    }
    if (department && province && district) {
      this.setDistrict({
        event: null,
        item: this.geolocationService.getDistrictByIdAsDropdownItem(department + province + district),
      });
    }
  }

  private fillUpDropdownsObj(department?: string, province?: string, district?: string): void {
    if (department) {
      this.setDepartment({
        event: null,
        item: this.geolocationService.getDepartmentByIdAsDropdownItem(department, true),
      });
    }
    if (department && province) {
      this.setProvince({
        event: null,
        item: this.geolocationService.getProvinceByIdAsDropdownItem(province, true),
      });
    }
    if (department && province && district) {
      this.setDistrict({
        event: null,
        item: this.geolocationService.getDistrictByIdAsDropdownItem(district, true),
      });
    }
  }

  setDepartment($event: ISelectItem, emitValue?: boolean): void {
    // TODO: Refactor to remove FormGroup dependency
    if (this.parentFormGroup) {
      this.parentFormGroup?.controls['department'].setValue($event?.item?.value);
      this.parentFormGroup?.controls['province'].setValue(null);
      this.parentFormGroup?.controls['district'].setValue(null);
    }
    this.departmentSelected = $event.item;

    this.provinces = this.departmentSelected
      ? this.geolocationService.getProvincesAsDropdownItems(this.departmentSelected.value)
      : null!;

    this.provinceSelected = null!;
    this.provinceDropdown?.closeDropdownMenu();

    setTimeout(() => {
      this.districts = null!;
      this.districtSelected = null!;
      this.districtDropdown?.closeDropdownMenu();
    }, 100);

    if (emitValue) {
      if (this.showGeolocationErrors) this.showDepartmentError = false;
      this.emitCurrentValue(this.departmentSelected);
    }
  }

  setProvince($event: ISelectItem, emitValue?: boolean): void {
    // TODO: Refactor to remove FormGroup dependency
    if (this.parentFormGroup) {
      this.parentFormGroup?.controls['province'].setValue($event?.item?.value);
      this.parentFormGroup?.controls['district'].setValue(null);
    }
    this.provinceSelected = $event.item;
    this.districts = this.provinceSelected
      ? this.geolocationService.getDistrictsAsDropdownItems(this.provinceSelected.value)
      : null!;
    this.districtSelected = null!;
    this.departmentDropdown?.closeDropdownMenu();
    this.districtDropdown?.closeDropdownMenu();
    if (emitValue) {
      if (this.showGeolocationErrors) this.showProvinceError = false;
      this.emitCurrentValue(this.provinceSelected);
    }
  }

  setDistrict($event: ISelectItem, emitValue?: boolean): void {
    // TODO: Refactor to remove FormGroup dependency
    if (this.parentFormGroup) {
      this.parentFormGroup?.controls['district'].setValue($event?.item?.value);
    }
    this.districtSelected = $event.item;
    this.departmentDropdown?.closeDropdownMenu();
    this.provinceDropdown?.closeDropdownMenu();
    if (emitValue) {
      if (this.showGeolocationErrors) this.showDistrictError = false;
      this.emitCurrentValue(this.districtSelected);
    }
  }

  handleDepartmentDropdownClick(): void {
    this.provinceDropdown?.closeDropdownMenu();
    this.districtDropdown?.closeDropdownMenu();
  }
  handleProvinceDropdownClick(): void {
    this.departmentDropdown?.closeDropdownMenu();
    this.districtDropdown?.closeDropdownMenu();
  }
  handleDistrictDropdownClick(): void {
    this.departmentDropdown?.closeDropdownMenu();
    this.provinceDropdown?.closeDropdownMenu();
  }

  emitCurrentValue(separatedValue?: IDropdownItem): void {
    if (this.emitValuesSeparately) {
      this.selectSeparatelyItem.emit(separatedValue);
      return;
    }

    const geolocation =
      '01' +
      (this.departmentSelected?.value || '') +
      (this.provinceSelected?.value.substr(2, 2) || '') +
      (this.districtSelected?.value.substr(4, 2) || '');
    this.selectItem.emit(geolocation !== '01' ? geolocation : null);
  }

  onBusinessPhoneChange(): void {
    if (
      (this.businessPhone == undefined || this.businessPhone == null || this.businessPhone == ''.trim()) &&
      this.businessPhoneRequired
    ) {
      this.showError = true;
      this.getErrorsPhoneField.required = true;
      this.getErrorsPhoneField.minlength = false;
      this.getErrorsPhoneField.onlyNumber = false;
      this.getErrorsPhoneField.validPhone = false;
    } else if (this.businessPhone && this.businessPhone?.length < 9) {
      this.showError = true;
      this.getErrorsPhoneField.minlength = true;
      this.getErrorsPhoneField.required = false;
      this.getErrorsPhoneField.onlyNumber = false;
      this.getErrorsPhoneField.validPhone = false;
    } else if (this.businessPhone && !this.chekIfOnlyNumbers(this.businessPhone)) {
      this.showError = true;
      this.getErrorsPhoneField.required = false;
      this.getErrorsPhoneField.minlength = false;
      this.getErrorsPhoneField.onlyNumber = true;
      this.getErrorsPhoneField.validPhone = false;
    } else if (this.businessPhone && !this.checkIfValidPhone(this.businessPhone)) {
      this.showError = true;
      this.invalid = true;
      this.getErrorsPhoneField.required = false;
      this.getErrorsPhoneField.minlength = false;
      this.getErrorsPhoneField.onlyNumber = false;
      this.getErrorsPhoneField.validPhone = true;
    } else {
      this.invalid = false;
      this.getErrorsPhoneField.required = false;
      this.getErrorsPhoneField.minlength = false;
      this.getErrorsPhoneField.onlyNumber = false;
      this.getErrorsPhoneField.validPhone = false;
    }
    this.phone.emit(this.businessPhone);
  }

  chekIfOnlyNumbers(event: any) {
    const pattern = /[0-9]/;
    return pattern.test(event);
  }

  checkIfValidPhone(event: any) {
    const pattern = /^9/;
    return pattern.test(event);
  }

  onBusinessPhoneFocus() {
    this.invalid = true;
    this.onBusinessPhoneChange();
  }
}

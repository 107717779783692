import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UberService {
  private readonly creditBase = `${environment.apiBaseUrl}/api-credit`;

  documentType: string = '';
  documentNumber: string = '';
  phoneNumber: string = '';
  email: string = '';
  typeCampaign: string = '';
  derivationSelected: string = '';
  initialFormAmount: number = 0;
  promoCode: string = '';
  idPreCredit: number = 0;
  queryParams: Params = [];

  credentials: any;
  profiles: any;

  constructor(private http: HttpClient) {}

  getParams() {
    const endpoint = `${this.creditBase}/param/uber`;
    return this.http.get(endpoint);
  }

  getLoginURL(redirectUrl: string): string {
    const url = this.format(environment.uberLoginUrl, {
      clienId: environment.uberClientId,
      redirectUri: redirectUrl,
    });
    return url;
  }

  getUberToken(code: string, redirectUri: string) {
    const endpoint = `${environment.apiBaseUrl}/ms-uberprotoken/token/exchange`;
    return this.http.post(endpoint, { code, redirectUri });
  }

  getUberProfiles(token: string) {
    const endpoint = `${environment.apiBaseUrl}/ms-uberproprofiles/profiles/partners`;
    return this.http.post(endpoint, { token });
  }

  saveUberRequest(payload: any, isExternal: boolean) {
    const endpoint = isExternal ? `${this.creditBase}/solicitude/uber` : `${this.creditBase}/intranet/solicitude/uber`;
    return this.http.post(endpoint, payload);
  }

  saveData(data: any, params: string) {
    localStorage.setItem('uber', JSON.stringify(data));
    if (params) localStorage.setItem('uber-params', params);
  }

  loadData() {
    const storageData = localStorage.getItem('uber');
    const data = JSON.parse(storageData ? storageData : '{}');

    this.typeCampaign = data?.typeCampaign;
    this.derivationSelected = data?.derivationSelected;
    this.initialFormAmount = data?.initialFormAmount;
    this.idPreCredit = data?.idPreCredit;

    this.documentType = data?.documentType;
    this.documentNumber = data?.documentNumber;
    this.phoneNumber = data?.phoneNumber;
    this.email = data?.email;
    this.promoCode = data?.promoCode;

    this.loadParams();
  }

  returnParams() {
    let params = this.queryParams;
    params.code = null;
    return params;
  }

  getUberQueryParam(params: string): Params {
    let paramsResult: Params = {};
    const arrayParams = params.split('&') || '';
    arrayParams.forEach((element) => {
      const paramLine = element.split('=');
      paramsResult[paramLine[0]] = paramLine[1];
    });
    return paramsResult;
  }

  saveCredentials(credentials: any) {
    localStorage.setItem('uber-creds', JSON.stringify(credentials));
  }

  loadParams() {
    const urlParams = localStorage.getItem('uber-params');
    this.credentials = urlParams;
    this.queryParams = urlParams ? this.getUberQueryParam(urlParams!) : [];
  }

  loadCredentials() {
    const creds = localStorage.getItem('uber-creds');
    const data = JSON.parse(creds ? creds : '{}');
    this.credentials = data;
  }

  clearData() {
    localStorage.removeItem('uber');
    localStorage.removeItem('uber-creds');
    localStorage.removeItem('uber-profile');

    this.documentType = '';
    this.documentNumber = '';
    this.phoneNumber = '';
    this.email = '';
    this.typeCampaign = '';
    this.derivationSelected = '';
    this.initialFormAmount = 0;
    this.idPreCredit = 0;
    this.promoCode = '';
  }

  removeQueryParams() {
    localStorage.removeItem('uber-params');
    this.queryParams = [];
  }

  saveProfiles(profiles: any) {
    localStorage.setItem('uber-profile', JSON.stringify(profiles));
  }

  loadProfiles() {
    const profiles = localStorage.getItem('uber-profile');
    const data = JSON.parse(profiles ? profiles : '{}');
    this.profiles = data;
  }

  private format(value: string, placeholders: any) {
    let formatted = value;
    for (let propertyName in placeholders) {
      let re = new RegExp('{' + propertyName + '}', 'gm');
      formatted = formatted.replace(re, placeholders[propertyName]);
    }
    return formatted;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FinancingFilterComponent } from '@shared/components/financing/financing-filter/financing-filter.component';
import { FinancingPreapprovedRequestComponent } from '@shared/components/financing/financing-preapproved-request/financing-preapproved-request.component';
import { FinancingRequestFormComponent } from '@shared/components/financing/financing-request-form/financing-request-form.component';
import { FinancingThanksFormComponent } from '@shared/components/financing/financing-thanks-form/financing-thanks-form.component';
import { AuthenticatedGuard } from '@shared/guards/authenticated.guard';
import { ExistingDirectoryResolver, ExistingDirectoryResolverKey } from '@shared/resolvers/existing-directory.resolver';
import { AlliedComponent } from '../allied/allied.component';
import { AlliedResolver } from '../allied/allied.resolver';
import { LayoutComponent } from '../layout/layout.component';
import { BodeguerosComponent } from './components/communities/bodegueros/bodegueros.component';
import { CertifiedPageComponent } from './components/communities/certified-page/certified-page.component';
import { CommunitiesComponent } from './components/communities/communities.component';
import { ContentManagerComponent } from './components/communities/content-manager/content-manager.component';
import { DashboardContentManagerComponent } from './components/communities/dashboard-content-manager/dashboard-content-manager.component';
import { LandingBodeguerosComponent } from './components/communities/landing-bodegueros/landing-bodegueros.component';
import { LandingWomenComponent } from './components/communities/landing-women/landing-women.component';
import { PriceFinderPageComponent } from './components/communities/price-finder-page/price-finder-page.component';
import { PriceFinderResultComponent } from './components/communities/price-finder-result/price-finder-result.component';
import { ToolPageComponent } from './components/communities/tool-page/tool-page.component';
import { TrainingDetailComponent } from './components/communities/training-detail/training-detail.component';
import { TrainingListComponent } from './components/communities/training-list/training-list.component';
import { VideosCommunitiesDetailComponent } from './components/communities/videos-communities-detail/videos-communities-detail.component';
import { VideosCommunitiesComponent } from './components/communities/videos-communities/videos-communities.component';
import { WomenCommunityComponent } from './components/communities/women-community/women-community.component';
import { NewIntranetComponent } from './new-intranet.component';
import { UserProfileResolver } from './resolvers/user-profile.resolver';
import { AffiliateConfirmationComponent } from './views/affiliate/affiliate-confirmation.component';
import { AffiliateComponent } from './views/affiliate/affiliate.component';
import { AffiliateResolver, AffiliateResolverKey } from './views/affiliate/affiliate.resolver';
import { Dashboardv3Component } from './views/dashboardv3/dashboardv3.component';
import { FinancingComponent } from './views/financing/financing.component';
import { ProfileMainInfoComponent } from './views/profile-main-info/profile-main-info.component';
import { ProfilePersonalInfoComponent } from './views/profile-personal-info/profile-personal-info.component';
import { ProfileComponent } from './views/profile/profile.component';
import { FinancingCdeFormComponent } from '@shared/components/financing/financing-cde-form/financing-cde-form.component';
import { ReferredProgramPageComponent } from './components/communities/referred-program-page/referred-program-page.component';

const routes: Routes = [
  {
    path: '',
    component: NewIntranetComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: 'dashboard',
        component: Dashboardv3Component,
        canLoad: [AuthenticatedGuard],
        data: {
          title: 'Dashboard - Mibanco Lab',
          pageName: 'Dashboard',
        },
      },
      {
        path: 'perfil',
        component: ProfileComponent,
        canLoad: [AuthenticatedGuard],
        data: {
          title: 'Perfil - Mibanco Lab',
          pageName: 'Perfil',
        },
        resolve: {
          user: UserProfileResolver,
        },
        children: [
          { path: 'mi-perfil', component: ProfileMainInfoComponent },
          { path: 'mi-informacion', component: ProfilePersonalInfoComponent },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'mi-perfil',
          },
        ],
      },
      {
        path: 'financiamiento',
        component: FinancingComponent,
        canLoad: [AuthenticatedGuard],
        data: {
          title: 'Financiamiento - Mibanco Lab',
          pageName: 'Financiamiento',
        },
        resolve: {
          user: UserProfileResolver,
        },
        children: [
          { path: 'filtro-financiamiento', component: FinancingFilterComponent },
          { path: 'formulario-solicitud', component: FinancingRequestFormComponent },
          { path: 'preaprobado-solicitud/:slug', component: FinancingPreapprovedRequestComponent },
          { path: 'compradeuda-solicitud/:slug', component: FinancingCdeFormComponent },
          { path: 'solicitud-enviada', component: FinancingThanksFormComponent },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'filtro-financiamiento',
          },
        ],
      },
      {
        path: 'afiliacion',
        component: AffiliateComponent,
        data: {
          title: 'Afiliación - Mibanco Lab',
          pageName: 'Afiliación',
        },
        resolve: {
          [AffiliateResolverKey]: AffiliateResolver,
          [ExistingDirectoryResolverKey]: ExistingDirectoryResolver,
        },
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'afiliacion/confirmacion',
        data: {
          title: 'Confirmación de afiliación - Mibanco Lab',
          pageName: 'Confirmación de afiliación',
        },
        component: AffiliateConfirmationComponent,
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'comunidades',
        component: CommunitiesComponent,
        data: {
          title: 'Comunidades - Mibanco Lab',
          pageName: 'Comunidades Mibanco Lab',
        },
        canActivate: [AuthenticatedGuard],
        children: [
          {
            path: 'emprende-mujer',
            component: WomenCommunityComponent,
            data: { title: 'Emprende Mujer', pageName: 'Emprede Mujer' },
            children: [
              { path: 'videos', component: VideosCommunitiesComponent },
              { path: 'video-detalle/id/:id', component: VideosCommunitiesDetailComponent },
              { path: 'webinar', component: TrainingListComponent },
              { path: 'webinar-detalle/id/:id', component: TrainingDetailComponent },
              { path: 'blogs', loadChildren: () => import('../blogs/blogs.module').then((m) => m.BlogsModule) },
              { path: 'blog/:id', loadChildren: () => import('../blog/blog.module').then((m) => m.BlogModule) },
              { path: 'certified', component: CertifiedPageComponent },
              { path: 'tools', component: ToolPageComponent },
              { path: '', component: LandingWomenComponent },
              { path: 'programa-referidos', component: ReferredProgramPageComponent},
            ],
          },
          {
            path: 'bodegueros',
            component: BodeguerosComponent,
            data: { title: 'Bodegueros', pageName: 'Bodegueros' },
            children: [
              { path: 'videos', component: VideosCommunitiesComponent },
              { path: 'video-detalle/id/:id', component: VideosCommunitiesDetailComponent },
              { path: 'webinar', component: TrainingListComponent },
              { path: 'webinar-detalle/id/:id', component: TrainingDetailComponent },
              { path: 'blogs', loadChildren: () => import('../blogs/blogs.module').then((m) => m.BlogsModule) },
              { path: 'blog/:id', loadChildren: () => import('../blog/blog.module').then((m) => m.BlogModule) },
              { path: 'certified', component: CertifiedPageComponent },
              { path: 'comparador-precio', component: PriceFinderPageComponent },
              { path: 'tools', component: ToolPageComponent },
              { path: 'resultado-consulta', component: PriceFinderResultComponent },
              { path: '', component: LandingBodeguerosComponent },
              { path: 'programa-referidos', component: ReferredProgramPageComponent},
            ],
          },
          {
            path: 'content-manager',
            component: DashboardContentManagerComponent,
            data: { title: 'content-manager', pageName: 'content-manager' },
            children: [{ path: '', component: ContentManagerComponent }],
          },
        ],
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      }
    ],
  },
  {
    path: '',
    canActivate: [AuthenticatedGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'aliados',
        component: AlliedComponent,
        resolve: { alliedResolved: AlliedResolver },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewIntranetRoutingModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { PricesMarketsResponse } from '@shared/interfaces/frontend/communities';

@Pipe({
  name: 'searchVariety'
})
export class SearchVarietyPipe implements PipeTransform {

  transform(varietys: PricesMarketsResponse[], search: string): any[] {

    if (!varietys || !search) {
      return varietys;
    }
    search = search.toLowerCase();
    varietys= varietys.filter(m =>
      m.productVarietyName.toLowerCase().includes(search)
    );
    if(varietys.length==0){
      return [{productVarietyName: "No hay alternativas.",state:-1 }];
    }
    return varietys;
  }
}

export const NEW_SEED_FLOW = {
    segmentTexiAndStore: 'taxisybodegas',
    independentCode: '10001',
    totalStepsDefault: 3,
    totalStepsTaxi: 4,
    storeCode: '11020',
    taxiCode: '11156',
    segmentLabel: 'segmento',
    taxiName: 'Taxi',
    taxisName: 'Taxis',
    cabbie: 'Taxista',
    taxiDriver: 'Taxistas',
    storeName: 'Bodegas',
    placeholdSelectDefaul:'Selecciona máx 3 opciones',
    withoutApp:'No Trabajo con apps',
    notSupplier:'Ninguna de las anteriores',
    otherSupplier:'Otros',
    limitSuppliersCode:'8',
    taxiNameSelected: 'Transporte particular - taxi',
    typeFormTaxiStore:'SEMILLA_EXPERIMENTO',
    independient: 'Tengo mi propio negocio',
};

export const LIMITS_TICKET: { [key: string]: number } = {
    'defaul': 0,
    'mensual': 1,
    'quincenal': 2,
    'semanal': 3,
    'diaria': 5,
  };

export const ACTIVITY_CODE: { [key: string]: number } = {
    'Tengo mi propio negocio':1,
    'Trabajo como dependiente':2,
  };

  export const ACTIVITY_NAME: { [key: string]: string } = {
    'Tengo mi propio negocio':'Independiente',
    'Trabajo como dependiente':'Dependiente',
  };

export enum ErrorMessagesDocument{
    document = 'Falta completar este campo',
    maxFileSize = 'El tamaño del archivo no puede superar los 5MB',
    fileType = 'El tipo de archivo no es el correcto.',
    serverError ='Ocurrió un problema. Inténtalo de nuevo más tarde.',
  }
export const MULTI_SELECT_VALUES = {
    limitSelect: 3,
    noneCodeAppsTaxi: '5',
}

export const SEED_STEP_VALUES = {
    stepOne: 'Avance Paso 1',
    stepTwo: 'Avance Paso 2',
    stepThree: 'Avance Paso 3',
    stepFour: 'Avance Paso 4',
    seedLabel: 'Mi efectivo semilla',
    seedNewFlow: 'Mi efectivo semilla - Paso 3',
    routePrefixSeedOld:'/efectivo-semilla',
    routePrefixSeed:'/prestamo-efectivo-semilla',
    routeSuffixStep3:'paso-3',
    routeSuffixTaxi:'new-paso-3',
    routeSuffixStore:'store-paso-3',
}
export const fieldDescriptions: { [key: string]: string } = {
    frequencyLoadFuel: 'Falta seleccionar frecuencia de carga combustible',
    fuelLoadQuantity: 'Falta llenar cantidad de combustible',
    appsWork: 'Falta seleccionar apps con la que trabaja',
    wayToPay: 'Falta seleccionar tipo de pago',
    photoFrontVehicle: 'Falta foto de tarjeta de propiedad frontal ',
    photoRearVehicle: 'Falta foto de tarjeta de propiedad posterior ',
    photoTicketFuel: 'Falta foto de boleta de combustibe',
    photoPagoApp: 'Falta foto de pago aplicativo nro1',
    photoPayAppTwo: 'Falta foto de pago aplicativo nro2',
    photoReceiptYape: 'Falta foto de recibo yape o plin nro1',
    photoReceiptPlin: 'Falta foto de recibo yape o plin nro2',
    photoMileage: 'Falta foto de kilometraje',
    photoStoreOne: 'Falta foto de la Bodega nr1',
    photoStoreTwo: 'Falta foto de la Bodega nr2',
    suppliers: 'Falta seleccionar tus proveedores',
    othersSuppliers: 'Falta agregar tu otro proveedor',
    photoSuppliersTicketOne: 'Falta foto de recibo de proveedor nr1',
    photoSuppliersTicketTwo: 'Falta foto de recibo de proveedor nr1',
    photoLightWaterStore: 'Falta foto de tu recibo de luz o agua de tu negocio',
};

export const NEW_SEED_VALUES = {
    stepTotalDependient: 3,
    stepTotalDefault: 4,
    stepTotalComercio: 5,
    stepOneCode:1,
    stepTwoCode:2,
    stepThreeCode:3,
    stepFourCode:4,
    stepFiveCode:5,
    pageOneCode:1,
    pageTwoCode:2,
    pageThreeCode:3,
    pageFourCode:4,
    pageFiveCode:5,
    pageSixCode:6,
    pathPageOne: '/efectivo-semilla/paso-1',
    pathPageTwo: '/efectivo-semilla/nuevo-paso-1',
    pathPageThree: '/efectivo-semilla/nuevo-paso-2',
    pathPageFour: '/efectivo-semilla/nuevo-paso-3',
    pathPageFive: '/efectivo-semilla/nuevo-paso-4',
    pathPageSix: '/efectivo-semilla/paso-final',
    pathPageEight: '/efectivo-semilla/taxi-paso-4',
    pathPageNine: '/efectivo-semilla/taxi-paso-5',
    pathPageElevel: '/efectivo-semilla/comercio-paso-4',
    pathPageTaxiInfo: '/efectivo-semilla/taxi-info',
    pathPageComerceInfo: '/efectivo-semilla/comercio-info',
}

export const DATALAYER_VALUES = {
    stepThree: '3',
    stepFour: '4',
    stepFive: '5',
    businnessTurn: 'Giro del negocio',
}

export const DATALAYER_VALUES_OLD = { 
    numberThree: 3,
    dependiente: 'dependiente',
    taxi: 'taxi',
    commerce: 'comercio',
    otherLineOfBusiness: 'otros giros',
}

export const VALUE_PHOTO = {
    horizontal:'horizontal',
    vertical:'vertical',
    photo:'photo',
    actived:'active',
    comerceOne:'comercio_1',
    comerceTwo:'comercio_2',
}

export const ACTIVITY_NUMBER_DAYS: { [key: string]: number } = {
    'Trabajo como dependiente': 23,
    'Tengo mi propio negocio': 21
  };
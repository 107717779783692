<div class="financing-ad-container w-100 bg-image">
  <div class="title">
    <p class="main-title">¡Consigue nuevos clientes con Yevo!</p>
    <p class="subtitle">
      Completa este formulario en 3 simples pasos y publicaremos tu negocio <br />inmediatamente en el directorio para
      que más personas lo conozcan.
    </p>
  </div>
  <div class="desktop-img d-none d-xl-flex">
    <img src="assets/img/intranet/affiliate/ad-person.png" alt="img-ad" />
  </div>
</div>

import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { IProfileSocialMediaLink } from '@shared/interfaces';
import { UserProfile } from '@shared/models/UserProfileModel';
import { AuthService } from '@shared/services/auth.service';
import { UserService } from '@shared/services/user.service';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { personalInfo, myHome, myBusiness, socialNetwork, workingInformation } from './constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

const SUCCESS_REQUEST_STATUS = 204;

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  user!: UserProfile | null;
  profileSocialMediaLinks: IProfileSocialMediaLink[] = [];
  image: string | SafeUrl = 'assets/icons/intranet/profile-face.svg';
  imageHeaderUrl: string | SafeUrl = 'assets/icons/intranet/profile-header.svg';
  percentage: number = 0;
  radianPercentage: number = 0;
  file: any;

  tabs = [
    {
      title: 'Mi información',
      link: ['/nuevo-intranet', 'perfil', 'mi-informacion'],
    },
  ];

  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;
  @ViewChild('selectImage') fileInput!: ElementRef;
  @ViewChild('selectBanner') selectBanner!: ElementRef;

  imageChangedEvent: any = null;
  cropperSession = false;
  errorMsg?: string | null;
  imageTypeToUpload?: string;
  onUpdatePersonalInfo!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    public authService: AuthService,
    private modalService: NgbModal
  ) {
    this.onUpdatePersonalInfo = this.userService.userInfoUpdated.subscribe(($event) => {
      this.getPercentage();
    });
  }

  ngOnInit(): void {
    this.user = this.route.snapshot.data.user;
    if (this.user?.imageUrl) {
      this.image = this.user?.imageUrl;
    }
    if (this.user?.imageHeaderUrl) {
      this.imageHeaderUrl = this.user?.imageHeaderUrl;
    }
    this.setSocialMediaLinks();
    this.userProfileListenChanges();
    this.getPercentage();
  }

  ngOnDestroy(): void {
    this.modalService?.dismissAll();
  }

  userProfileListenChanges() {
    this.authService.userProfile$.pipe().subscribe((user) => {
      this.user = user;
      this.setSocialMediaLinks();
    });
  }

  setSocialMediaLinks() {
    this.profileSocialMediaLinks = [];
    if (this.user?.userInfoResponse?.whatsAppNumber!) {
      this.profileSocialMediaLinks.push({
        code: 'whatsapp',
        url: 'https://api.whatsapp.com/send?phone=+51' + this.user?.userInfoResponse?.whatsAppNumber!,
      });
    }
    if (this.user?.userInfoResponse?.facebookLink!) {
      this.profileSocialMediaLinks.push({ code: 'facebook', url: this.user?.userInfoResponse?.facebookLink! });
    }
    if (this.user?.userInfoResponse?.twitterLink!) {
      this.profileSocialMediaLinks.push({ code: 'twitter', url: this.user?.userInfoResponse?.twitterLink! });
    }
    if (this.user?.userInfoResponse?.instagramLink!) {
      this.profileSocialMediaLinks.push({ code: 'instagram', url: this.user?.userInfoResponse?.instagramLink! });
    }
    if (this.user?.userInfoResponse?.tiktokLink!) {
      this.profileSocialMediaLinks.push({ code: 'tiktok', url: this.user?.userInfoResponse?.tiktokLink! });
    }
    if (this.user?.userInfoResponse?.webPageLink!) {
      this.profileSocialMediaLinks.push({ code: 'web', url: this.user?.userInfoResponse?.webPageLink! });
    }
  }

  private formatImageToUpload(image: any) {
    const fileSegments = image.split(',');
    const base64Image = fileSegments[1];
    return {
      value: base64Image,
    };
  }

  getBase64(file: File): Promise<string | ArrayBuffer> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (ev) => {
        if (ev.target?.result) {
          resolve(ev.target.result);
        } else {
          reject('error trying convert base 64');
        }
      };
      reader.readAsDataURL(file);
    });
  }

  //upload header without crop
  async uploadHeader(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const file = files.item(0) as File;
    if (file) {
      this.imageTypeToUpload = 'header';
      this.uploadImage(file);
    } else {
      this.fileInput.nativeElement.value = '';
    }
  }

  // CROP COMPONENT EVENT
  fileChangeEvent(event: any): void {
    this.errorMsg = null;
    if (event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.imageTypeToUpload = 'profile';
        this.uploadImage(event.target.files[i]);
      }
    } else {
      this.fileInput.nativeElement.value = '';
    }
  }

  private uploadImage(file: any) {
    const reader = new FileReader();
    let errorMsg = '';

    this.imageChangedEvent = event;
    this.cropperSession = true;
    if (!/(jpe?g|png)$/i.test(file?.type)) {
      errorMsg = 'Solo se admiten imágenes.';
      console.error(errorMsg);
      return;
    }
    reader.readAsDataURL(file);
    reader.onload = () => {};
  }

  imageCropped(event: ImageCroppedEvent): void {
    /* show image cropped */
  }

  imageLoaded(image?: HTMLImageElement): void {
    /* show cropper */
  }

  cropperReady(): void {
    /* cropper ready */
  }

  loadImageFailed(): void {
    /* show message */
  }

  cancelCropSession(): void {
    this.fileInput.nativeElement.value = '';
    this.imageChangedEvent = null;
    this.cropperSession = false;
    this.file = null;
  }

  acceptCropSession(): void {
    const cropEvent = this.imageCropper.crop();
    if (cropEvent?.base64) {
      if (this.imageTypeToUpload == 'header') {
        this.imageHeaderUrl = cropEvent?.base64;
        this.addImageHeader(cropEvent.base64);
      } else if (this.imageTypeToUpload == 'profile') {
        this.image = cropEvent?.base64;
        this.addImage(cropEvent.base64);
      }
    }
    this.cancelCropSession();
  }

  private addImage(value: string | ArrayBuffer | null): void {
    const imageToUpload = this.formatImageToUpload(value);
    this.userService.setUserImageProfile(imageToUpload).subscribe((response) => {
      if (response.status == SUCCESS_REQUEST_STATUS) {
        this.authService.getUserProfile().subscribe();
      }
    });
  }

  addImageHeader(value: string | ArrayBuffer | null): void {
    const imageToUpload = this.formatImageToUpload(value);
    this.userService.setUserImageHeader(imageToUpload).subscribe((response) => {
      if (response.status == SUCCESS_REQUEST_STATUS) {
        this.authService.getUserProfile().subscribe();
      }
    });
  }

  getPercentage(): void {
    const allUserInfo: any = { ...this.user, ...this.user?.userInfoResponse };
    delete allUserInfo.userInfoResponse;

    let cleanUserInfo = Object.keys(allUserInfo)
      .filter((k) => allUserInfo[k] != null || allUserInfo[k] != undefined)
      .reduce((a, k) => ({ ...a, [k]: allUserInfo[k] }), {});

    this.percentage = 0;
    this.percentage += personalInfo.every((key) => cleanUserInfo.hasOwnProperty(key)) ? 25 : 0;
    this.percentage += myHome.every((key) => cleanUserInfo.hasOwnProperty(key)) ? 25 : 0;
    this.percentage += socialNetwork.every((key) => cleanUserInfo.hasOwnProperty(key)) ? 25 : 0;

    let percentageWorkingBusiness = 0;
    percentageWorkingBusiness = workingInformation.every((key) => cleanUserInfo.hasOwnProperty(key)) ? 25 : 0;
    if (percentageWorkingBusiness == 0) {
      percentageWorkingBusiness = myBusiness.every((key) => cleanUserInfo.hasOwnProperty(key)) ? 25 : 0;
    }
    this.percentage += percentageWorkingBusiness;
    this.radianPercentage = (this.percentage * 360) / 100;
  }
}

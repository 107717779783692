import { DirectoryService } from 'src/app/shared/services/directory.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TopicModel } from '@shared/models/Topic.model';

export const ExistingCatalogTrainingResolverKey = 'ExistingCatalogTrainingResolved';

@Injectable({
  providedIn: 'root',
})
export class ExistingCatalogTrainingResolver implements Resolve<TopicModel | null> {
  constructor(private directoryService: DirectoryService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): TopicModel | null | Observable<TopicModel | null> | Promise<TopicModel | null> {
    const slug = route.paramMap.get('trainingSlug') || '';
    const token = route.queryParamMap.get('token') || undefined;

    return slug ? this.directoryService.getTopicBySlug(slug, token) : of(null);
  }
}

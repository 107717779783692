import { AuthService } from './../services/auth.service';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { isPlatformServer } from '@angular/common';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LogglyService } from '@shared/services/loggly.service';
import { LocalStorageService } from '@shared/services/local-storage.service';

/** Inject With Credentials into the request */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private logglyService: LogglyService,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let redirectionErrorPage = 'ingresar';

    const url = window.location.href.split('?')[1] || '';
    const pathname = window.location?.pathname || '';

    return next.handle(request).pipe(
      tap(
        (event) => {},
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            this.logglyService.log('HTTP ERROR', {
              url: request.url,
              method: request.method,
              error: error,
            });
          }

          if (error.status === 401) {
            if (!this.localStorageService.get('refresh_token')) {
              if (isPlatformServer(this.platformId)) {
                redirectionErrorPage = 'blank-page';
              }
              this.authService.logout(redirectionErrorPage);
            }
            if (!pathname.includes('/ingresar')) this.setRedirectRoute(pathname, url);
          }
        }
      )
    );
  }

  setRedirectRoute(pathname: any, url: any) {
    let navigation = url ? `${pathname}?${url}` : pathname;
    this.localStorageService.set('requestedRoute', navigation);
  }
}

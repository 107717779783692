<div class="accordion" [ngStyle]="{backgroundColor: bgColor}">
    <div class="accordion__header" >
        <button class="accordion__toggle-button" [ngStyle]="{backgroundColor: bgColor}" (click)="checkexpandable()">
            <img src="/assets/img/arrow-up.svg" [ngClass]="{'icon-rotated': showContent}"  alt="Expand/Collapse">
        </button>
        <div class="accordion__title">¿Te recomendaron solicitar este préstamo?</div>
    </div>
    <div class="accordion__body" *ngIf="showContent">
        <p class="accordion__body-text">Compártele este enlace a tu amigo o familiar para premiarlo con un
           <b> vale de regalo de S/ 30.</b></p>
        <milab-box-link [referredCode]="codeRecomended"></milab-box-link>
        <milab-card-social [socialNetworks]="socialNetworks" [referredCode]="codeRecomended"></milab-card-social>
        <p class="accordion__body-info">* Debe ser un cliente de Mibanco.</p>
    </div>
</div>
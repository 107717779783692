<div class="container tools_page">
  <div class="row">
    <div class="breadcrumb mt-4 ml-3">
      <yevo-breadcrumb [breadcrumbs]="breadcrumbs"></yevo-breadcrumb>
    </div>
  </div>
  <div class="tools_page_title">Herramientas</div>
  <div class="tools_page_description">
    Tenemos unas plantillas gratuitas que te serán útiles para potenciar tu negocio.
  </div>

  <div class="row d-none d-md-flex justify-content-center mb-4">
    <div class="allied-cards allied-cards-benefits" [ngClass]="{ active: finance }" (click)="getFinance()">
      <img src="assets/icons/communities/tool-money.svg" alt="money card" class="mt-3" />
      <p>Financieras</p>
    </div>

    <div class="mr-2 mr-md-3 allied-cards" [ngClass]="{ active: digital }" (click)="getDigital()">
      <img src="assets/icons/communities/tool-cart.svg" alt="credit card" class="mt-3" />
      <p>Digitales</p>
    </div>
  </div>
  <div class="row d-flex d-block d-md-none justify-content-center mb-4">
    <div class="col-12 d-flex justify-content-center d-md-none">
      <div class="allied-card-container" [ngClass]="{ active: finance }" (click)="getFinance()">
        <img src="assets/icons/communities/tool-money.svg" alt="money card" />
        <p>Financieras</p>
      </div>

      <div class="mr-2 allied-card-container" [ngClass]="{ active: digital }" (click)="getDigital()">
        <img src="assets/icons/communities/tool-cart.svg" alt="credit card" />
        <p>Digitales</p>
      </div>
    </div>
  </div>

  <div class="tools_page_cards">
    <div class="tools_page_card" *ngFor="let tool of toolsSelected">
      <div class="tools_card_icon">
        <img [src]="tool.iconPath" alt="tool-icon" />
      </div>
      <div class="tools_card_info">
        <div class="tools_card_info_title">{{ tool.title }}</div>
        <div class="tools_card_info_description">{{ tool.description }}</div>
        <div class="tools_card_info_download" (click)="evaluateAction(tool.url, tool.title,tool.typeAction)">
          {{tool.typeAction}}
          <img *ngIf="tool.typeAction !=this.actionGetInto" src="assets/icons/communities/download-tool.svg" alt="download-tool-icon" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="last-container">
  <img src="assets/icons/communities/new-landing-end.svg" alt="clouds" class="last-img" />
</div>

<div *ngIf="loading; else content">
    <div class="dsc-banner-loader">
        <div class="dsc-banner-loader_content"></div>
        <div class="dsc-banner-loader_content_text">
            <div class="dsc-banner-loader_text" *ngFor="let item of this.totalBars;"></div>
            <div class="dsc-banner-loader_content_two"></div>
            <div class="dsc-banner-loader_text" *ngFor="let item of this.totalBars;"></div>
        </div>
        
     </div>
    
</div>
  <ng-template #content>
    <div class="banner_content">
        <div class="banner_information_desktop">
            <div class="banner_information_desktop_title">
                <p class='title_standard desktop'>Programa de <span class="title_big">Referidos</span></p>
                <p class='title_standard mobile'>Programa</p>
                <p class="title_big mobile">de Referidos</p>
            </div>
            <div class="banner_information_desktop_description">
                <p class='description'>¡Participa recomendando Yevo a tus amigos y obtén grandes beneficios!</p>
            </div>
            <div class="container-button">
                <button type='button' class='btn button-desktop' (click)="openModal()">
                    <span>Compartir enlace</span>
                </button>
            </div>
        </div>
        <div class="image-desktop">
            <img class='women-image-desktop' [src]='womenImageRoute' alt='women' loading="lazy" />
        </div>
        <div class="image-mobile">
            <div class="image">
                <img class='women-image-mobile' [src]='womenImageRoute' alt='women' loading="lazy" />
            </div>
        </div>
    </div>
    <div class="floating-button-mobile">
        <div class="floating-button" [class.fixed]="showButton">
            <button type='button' class='btn button-mobile' (click)="openModal()">
                <span>Compartir enlace</span>
            </button>
        </div>
    </div>
    <yevo-question-accordion [listQuestions]="listQuestions" [isVisible]="itemsVisible"
        (questionClick)="questionClicked($event)">
    </yevo-question-accordion>
    
    <div class="content-tabs">
        <yevo-benefits-tabs [referredPrograma]="referredPrograma" [progressMedal]="progressMedal" [items]="tabItems" [selectedItem]="selectedTab" (selectedItemEmit)="handleSelectedTab($event)">
        </yevo-benefits-tabs>
        <yevo-progress-level  [referredPrograma]="referredPrograma" [progressMedal]="progressMedal"  *ngIf="selectedTab.code === 'miProgreso'"></yevo-progress-level>
        <yevo-levels-info-section  [referredPrograma]="referredPrograma" [progressMedal]="progressMedal"  *ngIf="selectedTab.code === 'miProgreso'"></yevo-levels-info-section>
        <yevo-my-benefits-section [referredPrograma]="referredPrograma" [progressMedal]="progressMedal"*ngIf="selectedTab.code === 'misBeneficios'" [source]="this.parentRoute"></yevo-my-benefits-section>
    </div>
  </ng-template>
<div class="section">
  <div class="content-title">
    <div class="title">
      <h4>Compara los niveles</h4>
      <p>Conoce los beneficios que obtendrías subiendo de nivel en el Programa de Referidos.</p>
    </div>
  </div>
  <div class="benefit-section-container">
    <div class="swiper-section">
      <swiper #swiperBenefit [config]="config" *ngIf="this.isMobile; else desktopView"  [style.width]="swiperContainerWidth">
        <ng-container *ngFor="let item of this.levels; let i = index">
          <ng-template swiperSlide>
            <yevo-levels-card [levels]="item" class="benefit-section-card-swiper"></yevo-levels-card>
          </ng-template>
        </ng-container>
      </swiper>

      <ng-template #desktopView>
        <swiper #swiperBenefit [config]="config" style="margin: 0 10px">
          <ng-container *ngFor="let item of this.levels; let i = index">
            <ng-template swiperSlide>
              <yevo-levels-card [levels]="item" class="benefit-section-card-swiper"></yevo-levels-card>
            </ng-template>
          </ng-container>
        </swiper>
      </ng-template>

    </div>
  </div>
</div>

  <div class="container-landing-remittances">
    <div class="title" [innerHTML]="title"></div>
    <div class="subtitle">{{ subtitle }}</div>
    <div class="description">{{ description }}</div>

    <div class="text-first">
      <span class="text-mobile">{{ titleMobile }}</span> {{ subtitleMobile }}
    </div>

    <div class="icons">
      <div
        class="item-icon"
        *ngFor="let icon of icons"
        (click)="openRefers(icon)"
        [ngClass]="{ 'bg-color-disabled': !icon.isActived }"
      >
        <div class="icon size">
          <img [src]="icon.icon" [alt]="icon.name" />
        </div>
        <div class="name" [ngClass]="{ 'color-disabled': !icon.isActived }">{{ icon.name }}</div>
        <div class="decription" [innerHTML]="icon.description"></div>
      </div>
    </div>
    <p class="text-center">
      Términos y Condiciones de WU para el envío y recepción de dinero <a href="https://www.westernunionperu.pe/assets/pdf/terminos-y-condiciones-2017.pdf" class="link-here" target="_blank"> <strong> aquí </strong></a>
    </p>
  </div>
  <div></div>


import { FinancingService } from '@shared/services/financing.service';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProgressBarImage } from '@shared/models/DirectoryModel';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DirectoryService } from '../services/directory.service';
import { IUploadImageProgress } from '@shared/interfaces/frontend/ui-components/progress-bar';

@Injectable()
export class HttpProgressInterceptor implements HttpInterceptor {
  constructor(private directoryService: DirectoryService, private financingService: FinancingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let imageReceived = false;
    let imageAdded: IProgressBarImage;

    if (req.reportProgress) {
      if (req.url.includes('images')) {
        return next.handle(req).pipe(
          tap(
            (event: HttpEvent<any>) => {
              if (!imageReceived) {
                imageAdded = this.directoryService.getLastImageAdded();
                imageReceived = true;
              }
              if (event.type === HttpEventType.UploadProgress) {
                let progress = (imageAdded.progress = Math.round((event.loaded / event.total!) * 100));
                if (progress === 100) {
                  imageAdded.progress = 99;
                }
              } else if (event.type === HttpEventType.Response) {
                imageAdded.progress = 100;
              }
            },
            (error) => {
              console.error('error', error);
            }
          )
        );
      }

      if (req.url.includes('upload')) {
        let reqType = req?.body?.files[0]?.type;
        let image: IUploadImageProgress = {};

        return next.handle(req).pipe(
          tap(
            (event: HttpEvent<any>) => {
              if (!imageReceived) {
                imageReceived = true;
                image.uploading = true;
                image =
                  reqType == 'selfie'
                    ? this.financingService.selfieProgress
                    : this.financingService.getLastDocumentAdded();
              }
              if (event.type === HttpEventType.UploadProgress) {
                image.uploading = true;
                let progress = Math.round((event.loaded / event.total!) * 100);
                image.progress = progress === 100 ? 99 : progress;
              } else if (event.type === HttpEventType.Response) {
                image.progress = 100;
                image.uploading = false;
              }
            },
            (error) => {
              console.error('error', error);
            }
          )
        );
      }
    }
    return next.handle(req);
  }
}

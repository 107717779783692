import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AlliedService } from '@shared/services/allied.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlliedResolver implements Resolve<Observable<any>> {
  constructor(private alliedService: AlliedService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const logged = localStorage.getItem('userProfile')
    if (logged) {
      return this.alliedService.getAllied().pipe(
        map(allied => {
          return { allied };
        })
      );
    }
    return this.alliedService.getPublicAllied().pipe(
      map(allied => {
        return { allied };
      })
    );
  }
}

<div *ngIf="!kushka && !unsuccess" class="h-100">
  <yevo-general-message
    [isLogged]="isLogged"
    [unsuccess]="unsuccess"
    [messageInformation]="messageInformation"
    [showRegister]="showRegister"
    [isAffiliate]="isAffiliate"
    [pageType]="pageType"
    [messageType]="messageType"
    [ip]="ip"
    [username]="username"
    [documentNumber]="documentNumber"
    [email]="email"
    [typeForm]="typeForm"
    [isFinanceRejectRequest]="isFinanceRejectRequest"
  ></yevo-general-message>
</div>

<div class="container mt-0 my-md-5" *ngIf="kushka && !formGeneral">
  <div class="kushka-message-container">
    <div class="row justify-content-lg-center kushka-form__content kushka-form-activation">
      <div class="col-12 col-lg-6">
        <div class="kushka-form-activation__container">
          <div>
            <p class="kushka-form-activation__title">Tu solicitud ha sido enviada</p>
            <p class="kushka-form-activation__subtitle mb-0">
              Para atender tu solicitud más rápido y sin necesidad de ir al banco, envíanos unos documentos adicionales.
            </p>
            <p class="kushka-form-activation__description mt-2">Sigue estos pasos para hacerlo:</p>
          </div>

          <div class="kushka-form-activation__card">
            <span>1. &nbsp;</span>
            <p class="mb-0">Revisa en tu bandeja de entrada o correos no deseados el mensaje de Yevo de Mibanco.</p>
            <img src="/assets/img/register/gmail_outlook.png" alt="Gmail - Outlook" />
          </div>

          <div class="kushka-form-activation__card">
            <span>2. &nbsp;</span>
            <p class="mb-0">
              Envíanos los documentos solicitados: boleta, recibo de luz del negocio y de tu vivienda más dos fotos con
              tu activo de trabajo (local, inventario, etc).
            </p>
            <img src="/assets/img/extern-financing/yevo-kushka.png" alt="Documentos solicitados" />
          </div>

          <div class="kushka-form-activation__button text-center animated name-text-container py-2">
            <button class="form-message__button border-0 mt-1 mb-3" (click)="buttonClickPushEvent()">
              {{ currentLabel }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="unsuccess">
  <yevo-general-message
    [isLogged]="isLogged"
    [unsuccess]="unsuccess"
    [messageInformation]="messageInformation"
    [showRegister]="showRegister"
    [isAffiliate]="isAffiliate"
    [pageType]="pageType"
    [messageType]="messageType"
    [ip]="ip"
    [username]="username"
    [documentNumber]="documentNumber"
    [email]="email"
    [typeForm]="typeForm"
    [isFinanceRejectRequest]="isFinanceRejectRequest"
  ></yevo-general-message>
</div>

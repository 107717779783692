<div class="specialization_section_container">
    <div class="specialization_section container-card">
        <div class="title">
            {{title}}
        </div>
        <div class="subtitle">
            {{subtitle}}
        </div>
        <div class="cards_container">
            <swiper [config]='configSwiper' class='swiper swiper_custom_class' >
                <ng-template swiperSlide *ngFor='let item of items, let i = index;'>
                    <yevo-specialization-card [item]="item"></yevo-specialization-card>
                </ng-template>
            </swiper>
        </div>
    </div>
</div>
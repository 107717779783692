  <div class="container price_markets_header">
    <div class="row">
      <div class="breadcrumb mt-4 ml-3">
        <a (click)="goInicio()"><img src="/assets/icons/breadcrumb-arrow.svg" alt="arrow" /> &nbsp;<span
            class="active">Inicio</span>&nbsp;</a>
        / &nbsp;Comparador de Precios
      </div>
    </div>
  </div>

  <div *ngIf="loading; else errorOrmidagriData" >
  <section class="container price_markets__section">
    <div class="row ">
      <div class="col-6 d-none d-md-block">
        <div class="price_markets__container_desktop ">
          <div class="  price_markets__title_desktop">
            <div class="price_markets__word"> Comparador</div>
            <div class="price_markets__line"></div>
            <div class="price_markets__word2"> de precios </div>
            <div class="price_markets__description"> Enterate en qué mercado tienen tus productos al mejor precio </div>
          </div>
          <div><img class="price_markets__img" src="/assets/img/communities/bodeguero/finder-page-desktop.png" alt="banner" /></div>
        </div>
      </div>
  
      <div class="col-12 col-md-6">
        <div class="price_markets___second">
          <div class="price_markets___title_container">
            <div class="price_markets___title  __desktop">¡Encuentra el mejor precio!</div>
            <div class="price_markets___title  __mobile">¡Consulta y encuentra el mejor precio!</div>
            <div class="price_markets___description __desktop">
              Consulta precios de productos y sus presentaciones en el Módulo de Mercados Mayoristas
            </div>
            <div class="price_markets___description  __mobile">
              Consulta precios de productos en el Módulo de Mercados Mayoristas
            </div>
          </div>
  
          <div>
            <div class="price_markets____container_form   ">
              <div class="price-page__form-group">
                <label class="price-page__title_imput" for="">Mercado</label>
                <div class="price-page__multi-select">
                  <div class="price-page__select-field " (click)="open(content,1)">
                    <input class="price-page__imput-select " type="text" [placeholder]="placeholderMarket" readonly>
                    <span class="price-page__down-arrow" >
                      <img class="price-page__img-page" src="/assets/icons/arrow-down-black.svg" alt="arrow">
                    </span>
                  </div>
                  <div   class="price-page___list" [hidden]="!listSelectOnMarket" [ngClass]="{'price-page___show-opctions': listSelectOnMarket}">
                    <label for="task1" class="price-page___list_label" *ngFor="let item of this.marketsList" (click)="checkMarket(item,'desktop')">
                      <span class="price-page__list_label_text">{{item.marketName}}</span>
                    </label>
                  </div>
  
                </div>
              </div>
              <div class="price-page__form-group">
                <label class="price-page__title_imput" for="">Producto</label>
                <div class="price-page__multi-select">
                  <div class="price-page__select-field " (click)="open(searchModalProduct,2)">
                    <input class="price-page__imput-select " type="text" [placeholder]="placeholderProduct" readonly>
                    <span class="price-page__down-arrow" >
                      <img class="price-page__img-page" src="/assets/icons/arrow-down-black.svg" alt="arrow"></span>
                  </div>
                </div>
                <div  class="price-page___list" [hidden]="!listSelectOnProduct" [ngClass]="{'price-page___show-opctions': listSelectOnProduct}">
                  <label class="check_" *ngFor="let item of this.productList ; let i = index ">{{item.productName }}
                    <input (click)="checkProduct(item,$event,'check_s')"   class="input_  check_s"  type="checkbox"  >
                    <span  class="span_"></span>
                  </label>
                </div>
              </div>
              <div class="price-page__form-group">
                <label class="price-page__title_imput" for="">Presentación del producto</label>
                <div class="price-page__multi-select">
                  <div class="price-page__select-field " (click)="open(searchModalVariety,3)">
                    <input class="price-page__imput-select " type="text" [placeholder]="placeholderVariety" readonly>
                    <span class="price-page__down-arrow" >
                      <img class="price-page__img-page" src="/assets/icons/arrow-down-black.svg" alt="arrow"></span>
                  </div>
                </div>
                <div  class="price-page___list" [hidden]="!listSelectOnVariety" [ngClass]="{'price-page___show-opctions': listSelectOnVariety}">
                  <label class="check_" *ngFor="let item of  this.varietyList ; let i = index ">{{item.productVarietyName }}
                    <input (click)="checkVariety(item,$event,'check_vd')"   class="input_  check_vd"  type="checkbox"  >
                    <span class="span_"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="pd-btn"> 
          <button type="button"class="btn_page" (click)="consult()" [disabled]="!showbuttonConsult"  [ngClass]="{'btn_active': showbuttonConsult, 'btn_disable': !showbuttonConsult}" > Consultar</button>
      </div>
  
      <div class="d-flex align-items-start align-items-center">
        <label class="label-conditions">
          Al consultar, usted acepta los
          <span>
            <label (click)="openModalTerms(termsConditions)"  class="conditions">términos y condiciones</label>
          </span>
          del servicio de Comparador de Precios. Yevo no se responsabiliza por la calidad de los
          productos. Este es un servicio ofrecido por el Ministerio de Agricultura y Riego, a través de su
          plataforma Sistema de Abastecimiento y Precios (SISAP).
        </label>
      </div>
      </div>
    </div>
  </section>
</div>
  
<ng-template #errorOrmidagriData>
  <div >
      <div >
          <div class="container ">
              <div class="row">
                  <div class="col-12 error-container">
                      <div class="terms_circule"> <img class="terms_img"
                              src="/assets/icons/communities/Error.svg" alt="arrow"></div>
                      <div class="">
                          <div class="terms_title">Se ha producido un error</div>
                      </div>
                      <div class="terms_descriptions">Estamos trabajando para solucionarlo. Vuelve a internarlo en unos minutos.</div>
                      <div class="btn-conatainer">
                          <button type="button" class="btn btn-link terms_button" (click)="goInicio()">
                              Ir al Inicio 
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</ng-template>
  
  <div class="cloud-container d-none d-lg-block">
    <img class="img-fluid w-100" src="/assets/img/savings-account/clouds.svg" alt="clouds" />
  </div>

<ng-template #content let-modal>
  <div class="modal-body hidden">
    <div class="container-fluid">
      <div class="price-page_mobile__content-title">
        <div (click)="closeModal(1)"><img class="price-page_mobile__img-page_" src="/assets/icons/arrow-left.svg" alt="arrow"></div>
        <div class="price-page_mobile__title-search">{{titleMarketModal}}</div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="price-page_mobile__form-group">
            <div class="price-page_mobile__multi-select">
              <div class="price-page_mobile__select-field " >
                <input class="price-page_mobile__imput-select " [(ngModel)]="pipeMarket" type="text" [placeholder]="placeholderMarketSearchMobile">
                <span class="price-page_mobile__down-arrow" >
                  <img class="price-page_mobile__img-page" src="/assets/icons/search-secondary.svg" alt="search">
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="table-responsive table-h">
            <div class="">
              <label class="check_"  *ngFor="let item of this.marketsList | searchMarket:this.pipeMarket " (click)="checkMarket(item,'mobile')">{{item.marketName }}
              </label>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #searchModalProduct let-modal>
  <div class="modal-body hidden">
    <div class="container-fluid">
      <div class="price-page_mobile__content-title">
        <div (click)="closeModal(2)" ><img class="price-page_mobile__img-page_" src="/assets/icons/arrow-left.svg" alt="arrow"></div>
        <div class="price-page_mobile__title-search">{{titleProductModal}}</div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="price-page_mobile__form-group">
            <div class="price-page_mobile__multi-select">
              <div class="price-page_mobile__select-field ">
                <input class="price-page_mobile__imput-select " type="text" [(ngModel)]="pipeProduct" [placeholder]="placeholderProductSearchMobile">
                <span class="price-page_mobile__down-arrow" >
                  <img class="price-page_mobile__img-page" src="/assets/icons/search-secondary.svg" alt="search">
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="table-responsive table-h">
          <div class="">
            <label class="check_" *ngFor="let item of this.productList | searchProduct:pipeProduct; let i = index " >{{item.productName }}
              <input (click)="checkProduct(item,$event,'check_m')"  *ngIf="(item.id !=-1)" class="input_  check_m"  type="checkbox"  >
              <span *ngIf="(item.state !=-1)" class="span_ "></span>
            </label>
          </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #searchModalVariety let-modal>
  <div class="modal-body hidden">
    <div class="container-fluid">
      <div class="price-page_mobile__content-title">
        <div (click)="closeModal(3)" ><img class="price-page_mobile__img-page_" src="/assets/icons/arrow-left.svg" alt="arrow"></div>
        <div class="price-page_mobile__title-search">{{titleVarietyModal}}</div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="price-page_mobile__form-group">
            <div class="price-page_mobile__multi-select">
              <div class="price-page_mobile__select-field " >
                <input class="price-page_mobile__imput-select " type="text" [(ngModel)]="pipeVariety" [placeholder]="placeholderVarietySearchMobile">
                <span class="price-page_mobile__down-arrow" >
                  <img class="price-page_mobile__img-page" src="/assets/icons/search-secondary.svg" alt="search">
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="table-responsive table-h">
              <div class="">
                <label class="check_" *ngFor="let item of this.varietyList | searchVariety:pipeVariety; let i = index ">{{item.productVarietyName }}
                  <input (click)="checkVariety(item,$event,'check_vm')"  *ngIf="(item.state !=-1)"  class="input_  check_vm"  type="checkbox">
                  <span *ngIf="(item.state !=-1)" class="span_ "></span>
                </label>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #termsConditions let-modal>
  <div class="modal-body hidden  modal_pm__terms">
      <div class="container-fluid">
          <div class="row">
              <div class="col-md-12 text-center">
                  <div class="modal_pm__terms_circule">
                      <img class="terms_img" src="/assets/icons/communities/file-check-outline.png" alt="arrow" >
                  </div>
                 
              </div>
              <div class="col-md-12 text-center">
                  <div class="modal_pm__terms_title">{{titleTerms}}</div>
              </div>
          </div>
      </div>
      <div class="container modal_pm__cuerpo">
          <div class="modal_pm__terms_descriptions">{{descriptionTerms}}</div>
          <div class="modal_pm__terms_descriptions">{{descriptionTerms2}}</div>
          <button type="button" class="btn btn-link modal_pm__terms_button" (click)="closeModalTerms()">
             Entendido
          </button>
      </div>
  </div>
</ng-template>
import { Component, Input } from '@angular/core';

import { Router } from '@angular/router';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Course } from '@shared/interfaces';
import { DataLayerService } from '@shared/services/data-layer.service';
import { stripHtml } from '@shared/utils/strip-html.utils';

@Component({
  selector: 'yevo-training-course',
  templateUrl: './training-course.component.html',
  styleUrls: ['./training-course.component.scss', '../training-section-bank/training-section-bank.component.scss'],
})
export class TrainingCourseComponent {
  @Input() courses!: Course;

  topic = 'course';

  coursesAux: Course[] = [];

  openModalSeidor: boolean = false;

  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  constructor(private dataLayerService: DataLayerService, private router: Router) {}

  verCourse() {
    this.dataLayerService.pushTrainingCourseEvent(this.courses.title);
    this.router.navigate([`/cursos/${this.courses.courseId}`], { queryParamsHandling: 'merge' });
  }

  getTextFromHtml(html: string): string {
    return stripHtml(html);
  }
}

<div class="container-fluid financing-form-container" [ngClass]="{ intranet: isLogged }">
  <div>
    <div class="form-group col-sm-12 financing__form p-0 mb-0 d-flex flex-column justify-content-center">
      <div class="financing-wrapper">
        <div class="financing-content">
          <br class="d-none d-lg-block" />
          <div class="col-12 p-0 breadcrumb">
            <div class="div_bea">
              <yevo-breadcrumb [breadcrumbs]="breadcrumbs" class="col-12"></yevo-breadcrumb>
            </div>
          </div>
          <h2 class="main-title" *ngIf="activeStepNumber == 1">Compra de deuda express 💰</h2>
        </div>
      </div>
      <yevo-form-steps-v2
        [steps]="steps"
        [numberOfActiveStep]="activeStepNumber"
        [ngClass]="{ 'steps-design': activeStepNumber > 1 }"
        [totalSteps]="4"
        class="mt-1"
      ></yevo-form-steps-v2>

      <button
        class="btn-accordion-step-two collapsed collapse show justify-content-between align-items-center mb-3"
        type="button"
        *ngIf="activeStepNumber == 3"
        (click)="navigationSteps()"
      >
        <span>
          <svg-icon
            [src]="'/assets/icons/financing/shop-cart.svg'"
            [applyClass]="true"
            class="btn-accordion-icon"
          ></svg-icon>
          Mi negocio
        </span>
      </button>

      <form [formGroup]="userForm" (ngSubmit)="completeSolicitude()" role="form">
        <!-- FORM INITIAL 1 -->
        <div *ngIf="showInitialForm">
          <div class="row mb-1" *ngIf="showAmount && !showLoanSimulatorResult">
            <div class="col-sm-12">
              <label>Monto a solicitar*</label>
              <div class="input-group flex-nowrap mb-3 mt-2">
                <input
                  type="tel"
                  class="form-control h-100 input-border"
                  placeholder=""
                  maxlength="50"
                  formControlName="amount"
                  [attr.disabled]="true"
                />
              </div>
            </div>
          </div>

          <div class="container p-0" *ngIf="showLoanSimulatorResult">
            <yevo-financing-loan-simulator-additional-form
              [amount]="simulatorData.amount"
              [paymentDeadline]="simulatorData.paymentDeadline"
              [maxPaymentRate]="simulatorData.maxPaymentRate"
            ></yevo-financing-loan-simulator-additional-form>
          </div>

          <div id="accordion">
            <!-- ACCORDION 1 - PERSONAL INFO -->
            <div class="accordion-item mb-2" *ngIf="!isIndependent">
              <div class="mb-2">
                <button
                  class="btn-accordion justify-content-between align-items-center"
                  accordion-heading
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapsePersonalInfo"
                  aria-expanded="false"
                  (click)="changeToStep1()"
                >
                  <span>
                    <svg-icon
                      [src]="'/assets/icons/financing/person.svg'"
                      [applyClass]="true"
                      class="btn-accordion-icon"
                    ></svg-icon>
                    Información personal
                  </span>
                  <span>
                    <svg-icon
                      [src]="'/assets/icons/arrow-up-without-circle.svg'"
                      [applyClass]="true"
                      class="btn-accordion-icon arrow-up-icon"
                    ></svg-icon>
                  </span>
                </button>
              </div>
              <div id="collapsePersonalInfo" class="collapse show box-accordion" data-parent="#accordion">
                <div class="form-container-accordion">
                  <div class="row mb-1">
                    <div class="col-12">
                      <label class="label-control">Nombres*</label>

                      <input
                        type="text"
                        class="form-control"
                        placeholder="Ingresa tu nombre completo"
                        maxlength="100"
                        formControlName="name"
                      />
                      <yevo-modal-dni [tip]="tipList[1]"></yevo-modal-dni>
                      <label class="form-inline">
                        <div *ngIf="showError('name')" class="text-error">
                          <div *ngIf="getErrorsFromField('name')?.required">Nombre es requerido.</div>
                          <div *ngIf="getErrorsFromField('name')?.onlyLetter">
                            No se admiten caracteres especiales ni números.
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="col-12" *ngIf="showNameMessage">
                      <p class="aditional-info text-center px-4 px-md-2">
                        Nombres son requeridos. No se admiten caracteres especiales ni números
                      </p>
                    </div>
                    <div class="col-12 lastname-control">
                      <label>Apellido paterno*</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Apellido paterno"
                        maxlength="100"
                        formControlName="fatherLastName"
                      />
                      <yevo-modal-dni [tip]="tipList[2]"></yevo-modal-dni>
                      <label class="form-inline">
                        <div *ngIf="showError('fatherLastName')" class="text-error">
                          <div *ngIf="getErrorsFromField('fatherLastName')?.required">
                            Apellido paterno es requerido.
                          </div>
                          <div *ngIf="getErrorsFromField('fatherLastName')?.onlyLetter">
                            No se admiten caracteres especiales ni números.
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="col-12" *ngIf="showLastNameMessage">
                      <p class="aditional-info text-center px-4 px-md-2">
                        Apellido paterno es requerido.. No se admiten caracteres especiales ni números
                      </p>
                    </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col-12">
                      <label>Apellido materno*</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Apellido materno"
                        maxlength="100"
                        formControlName="motherLastName"
                      />
                      <yevo-modal-dni [tip]="tipList[3]"></yevo-modal-dni>
                      <label class="form-inline">
                        <div *ngIf="showError('motherLastName')" class="text-error">
                          <div *ngIf="getErrorsFromField('motherLastName')?.required">
                            Apellido materno es requerido.
                          </div>
                          <div *ngIf="getErrorsFromField('motherLastName')?.onlyLetter">
                            No se admiten caracteres especiales ni números.
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="form-group col-4 mb-0 document-type">
                          <label>Documento*</label>
                          <yevo-dropdown
                            [items]="documentTypeList"
                            [itemSelected]="documentTypeSelected"
                            [dropdownPlacement]="['bottom-right']"
                            [canRemoveActiveItem]="false"
                            [disabled]="true"
                            buttonLabel="Documento"
                            (selectItem)="getDocumentTypeEvent($event)"
                          ></yevo-dropdown>
                        </div>
                        <div class="form-group col-8 pl-0 mb-0">
                          <label>N° de DNI o carnet de extranjería*</label>
                          <input
                            type="tel"
                            class="form-control"
                            placeholder="Número de identidad"
                            formControlName="documentNumber"
                            [attr.disabled]="true"
                          />
                          <label class="form-inline">
                            <div *ngIf="showError('documentNumber')" class="text-error">
                              <div *ngIf="getErrorsFromField('documentNumber')?.required">
                                Número de identidad es requerido.
                              </div>
                              <div *ngIf="getErrorsFromField('documentNumber')?.pattern">
                                Número de identidad inválido.
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-lg-6">
                      <label>Correo electrónico*</label>
                      <input
                        type="mail"
                        class="form-control"
                        placeholder="Correo electrónico"
                        maxlength="100"
                        formControlName="email"
                        [readonly]="isEmailReadOnly"
                      />
                      <label class="form-inline">
                        <div *ngIf="showError('email')" class="text-error">
                          <div *ngIf="getErrorsFromField('email')?.required">Correo es requerido.</div>
                          <div *ngIf="getErrorsFromField('email')?.pattern">
                            El campo correo no tiene el formato adecuado.
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <label>Celular*</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Celular"
                        maxlength="9"
                        formControlName="phone"
                        [attr.disabled]="true"
                      />

                      <label class="form-inline">
                        <div *ngIf="showError('phone')" class="text-error">
                          <div *ngIf="getErrorsFromField('phone')?.required">Celular es requerido.</div>
                          <div *ngIf="getErrorsFromField('phone')?.onlyNumber">Solo se admiten números.</div>
                          <div *ngIf="getErrorsFromField('phone')?.phoneNumber">El número ingresado no es válido.</div>
                          <div *ngIf="getErrorsFromField('phone')?.minlength">El número debe tener 9 caracteres</div>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <label>Confirma el número de celular*</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Ingresa nuevamente el número de celular"
                        maxlength="9"
                        formControlName="phoneConfirm"
                      />

                      <label class="form-inline">
                        <div *ngIf="showError('phoneConfirm')" class="text-error">
                          <div *ngIf="getErrorsFromField('phoneConfirm')?.required">Celular es requerido.</div>
                          <div *ngIf="getErrorsFromField('phoneConfirm')?.onlyNumber">Solo se admiten números.</div>
                          <div *ngIf="getErrorsFromField('phoneConfirm')?.mismatchedPhones">
                            Has colocado dos números diferentes.
                          </div>
                          <div *ngIf="getErrorsFromField('phoneConfirm')?.phoneNumber">
                            El número ingresado no es válido.
                          </div>
                          <div *ngIf="getErrorsFromField('phoneConfirm')?.minlength">
                            El número debe tener 9 caracteres
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-lg-6 document-type marital-status-control">
                      <yevo-modal-selected
                        title="Indícanos en qué horario te podemos contactar"
                        titleModal="Horario de contacto"
                        [businessFrequencyList]="timeContactList"
                        (itemSelectedEvent)="getTimeContactEvent($event)"
                        [invalid]="showError('timeContact')"
                        [itemSelected]="timeContactSelected"
                      ></yevo-modal-selected>

                      <label class="form-inline">
                        <div *ngIf="showError('timeContact')" class="text-error">
                          <div *ngIf="getErrorsFromField('timeContact')?.required">Horario de contacto requerido.</div>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-12 col-md-12">
                      <label class="financing-evaluation__label">Situación laboral*</label>
                      <div class="d-flex" *ngIf="selectedIndependentActivity.length">
                        <yevo-filter-pills
                          [items]="employmentSituationList"
                          [itemSelected]="employmentSituationSelected"
                          [itemIdSelected]="'1'"
                          [dropdownPlacement]="['bottom-right']"
                          dropdownTitle=""
                          [numberPillsShowed]="numberCategoriesShowed"
                          (selectItem)="getEmploymentSituationEvent($event)"
                          [showSeeMore]="false"
                          [showJustifyContentBetween]="false"
                          [withHandleOption]="false"
                          [showSecondTheme]="true"
                        ></yevo-filter-pills>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-lg-6 document-type marital-status-control">
                      <label class="financing-evaluation__label"
                        >Selecciona tu estado Civil <span>(recuerda que debe ser igual a tu DNI)*</span></label
                      >
                      <yevo-modal-selected
                        titleModal="Selecciona tu estado civil"
                        [businessFrequencyList]="maritalStatusList"
                        (itemSelectedEvent)="getMaritalStatusEvent($event)"
                        [invalid]="showError('maritalStatus')"
                        [itemSelected]="maritalStatusSelected"
                      ></yevo-modal-selected>

                      <label class="form-inline">
                        <div *ngIf="showError('maritalStatus')" class="text-error">
                          <div *ngIf="getErrorsFromField('maritalStatus')?.required">Estado civil es requerido.</div>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-lg-6 document-type marital-status-control">
                      <yevo-modal-selected
                        title="Tipo de vivienda*"
                        titleModal="Tipo de vivienda"
                        [businessFrequencyList]="houseTypes"
                        (itemSelectedEvent)="getHouseTypeEvent($event)"
                        [invalid]="showError('housingType')"
                        [itemSelected]="houseTypeSelected"
                      ></yevo-modal-selected>

                      <label class="form-inline">
                        <div *ngIf="showError('housingType')" class="text-error">
                          <div *ngIf="getErrorsFromField('housingType')?.required">Tipo de vivienda es requerido.</div>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="row mt-3" *ngIf="showSpouseInputs">
                    <div class="col-sm-12">
                      <p class="mb-0 mt-1 title-spouse-info">Datos del cónyuge</p>
                      <div class="row">
                        <div class="control-spouse col-4 pr-0">
                          <label>Documento*</label>
                          <yevo-dropdown
                            [items]="documentTypeList"
                            [itemSelected]="documentTypeSpouseSelected"
                            [dropdownPlacement]="['bottom-right']"
                            [canRemoveActiveItem]="false"
                            [disabled]="false"
                            buttonLabel="DNI"
                            required
                            (selectItem)="getDocumentTypeSpouseEvent($event)"
                            [showManualError]="showError('typeDocumentSpouse')"
                          ></yevo-dropdown>
                          <label class="form-inline">
                            <div *ngIf="showError('typeDocumentSpouse')" class="text-error">
                              <div *ngIf="getErrorsFromField('typeDocumentSpouse')?.required">
                                Documento es requerido.
                              </div>
                            </div>
                          </label>
                        </div>
                        <div class="control-spouse col-8">
                          <label class="control-label">Nro. de documento*</label>
                          <input
                            [type]="documentTypeSpouseSelected.value === 'E' ? 'text' : 'tel'"
                            formControlName="documentNumberSpouse"
                            class="form-control"
                            placeholder="Número identidad"
                            [maxlength]="documentMaxLengthSpouse"
                          />
                          <label class="form-inline">
                            <div *ngIf="showError('documentNumberSpouse')" class="text-error">
                              <div *ngIf="getErrorsFromField('documentNumberSpouse')?.required">
                                Número identidad es requerido.
                              </div>
                              <div *ngIf="getErrorsFromField('documentNumberSpouse')?.pattern">
                                Número identidad es inválido.
                              </div>
                            </div>
                          </label>
                        </div>
                        <div class="control-spouse col-12">
                          <label class="control-label">Nombres*</label>
                          <input
                            type="text"
                            formControlName="nameSpouse"
                            class="form-control"
                            placeholder="Nombres"
                            maxlength="50"
                          />
                          <label class="form-inline">
                            <div *ngIf="showError('nameSpouse')" class="text-error">
                              <div *ngIf="getErrorsFromField('nameSpouse')?.required">
                                Nombres del cónyuge es requerido.
                              </div>
                              <div *ngIf="!!getErrorsFromField('nameSpouse')?.onlyLetter">Solo se admiten letras.</div>
                            </div>
                          </label>
                        </div>
                        <div class="col-12">
                          <label class="control-label">Apellidos*</label>
                          <input
                            type="text"
                            formControlName="lastNameSpouse"
                            class="form-control"
                            placeholder="Apellidos"
                            maxlength="50"
                          />
                          <label class="form-inline">
                            <div *ngIf="showError('lastNameSpouse')" class="text-error">
                              <div *ngIf="getErrorsFromField('lastNameSpouse')?.required">
                                Apellidos del cónyuge es requerido.
                              </div>
                              <div *ngIf="!!getErrorsFromField('lastNameSpouse')?.onlyLetter">
                                Solo se admiten letras.
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ACCORDION 2 - BUSINESS INFO -->
            <div *ngIf="isIndependent" class="accordion-item">
              <div class="mb-2">
                <button
                  class="btn-accordion collapsed collapse show justify-content-between align-items-center"
                  accordion-heading
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseBusinessInfo"
                  aria-expanded="false"
                  (click)="changeToStep2()"
                >
                  <span>
                    <svg-icon
                      [src]="'/assets/icons/financing/shop-cart.svg'"
                      [applyClass]="true"
                      class="btn-accordion-icon"
                    ></svg-icon>
                    Mi negocio
                  </span>
                  <span>
                    <svg-icon
                      [src]="'/assets/icons/arrow-up-without-circle.svg'"
                      [applyClass]="true"
                      class="btn-accordion-icon arrow-up-icon"
                    ></svg-icon>
                  </span>
                </button>
                <div id="collapseBusinessInfo" class="collapse box-accordion" data-parent="#accordion">
                  <div class="form-container-accordion">
                    <div class="row">
                      <div class="col-12">
                        <yevo-modal-info
                          title="Dirección del negocio"
                          descriptionModal="Indica la ubicación donde realizas la actividad de tu negocio. Si es en tu domicilio, puedes colocar dicha dirección"
                        ></yevo-modal-info>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <label>Dirección del negocio* </label>
                        <input
                          #mapSearchField
                          class="form-control"
                          placeholder="Ingresa tu dirección aquí"
                          formControlName="address"
                          id="address"
                          (focus)="googlePlaceSelected = false"
                        />
                        <label class="form-inline">
                          <div *ngIf="showError('address')" class="text-error">
                            <div *ngIf="getErrorsFromField('address')?.googlePlaceSelected">
                              Agrega una dirección sugerida por google maps para poder continuar
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="open-map d-block d-lg-none col-12" id="geolocationOpenMap">
                      <yevo-geolocation-maps
                        (newAddress)="addGeolocation($event)"
                        (buttonAccepted)="buttonAcceptedLocation($event)"
                        (buttonClose)="buttonCloseLocation($event)"
                      ></yevo-geolocation-maps>
                    </div>

                    <div class="row mb-1 geolocation-container">
                      <div class="col-sm-12">
                        <div class="geolocation-dropdown-container">
                          <yevo-geolocation
                            [parentFormGroup]="userForm"
                            [showLabels]="true"
                            [obligatory]="true"
                            [labels]="geolocationLabels"
                            [locations]="locations"
                            (selectItem)="handleGeolocationClick($event!)"
                            (selectValueLabelItem)="handleGeolocationSeparatelyClick($event!)"
                            [showGeolocationErrors]="showGeolocationErrors"
                          ></yevo-geolocation>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="seed-cash-dropdown col-sm-12 col-lg-6 marital-status-control">
                        <label class="control-label">Actividad económica*</label>
                        <yevo-searchable-input
                          [items]="economicActivityList"
                          [itemSelected]="economicActivitySelected"
                          (itemSelectedEvent)="getEconomicActivityEvent($event)"
                          [showManualError]="showError('economicActivity')"
                        ></yevo-searchable-input>
                        <label class="form-inline">
                          <div *ngIf="showError('economicActivity')" class="text-error">
                            <div *ngIf="getErrorsFromField('economicActivity')?.required">
                              Actividad económica es requerido.
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 mt-2">
                        <label>Celular del negocio (opcional)</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Celular del negocio"
                          maxlength="9"
                          formControlName="businessPhone"
                        />
                        <label class="form-inline">
                          <div *ngIf="showError('businessPhone')" class="text-error">
                            <div *ngIf="!!getErrorsFromField('businessPhone')?.onlyNumber">
                              Solo se admiten números.
                            </div>
                            <div *ngIf="getErrorsFromField('businessPhone')?.phoneNumber">
                              El número ingresado no es válido.
                            </div>
                            <div *ngIf="getErrorsFromField('businessPhone')?.minlength">
                              El número debe tener 9 caracteres
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12 col-lg-6 document-type marital-status-control">
                        <yevo-modal-selected
                          title="¿Recibiste ayuda para completar este formulario?"
                          titleModal="¿Recibiste ayuda para completar este formulario?"
                          [businessFrequencyList]="helpWithForm"
                          (itemSelectedEvent)="getHelpWithForm($event)"
                          [invalid]="showError('helpWithFormSelected')"
                          [itemSelected]="helpWithFormSelected"
                        ></yevo-modal-selected>

                        <label class="form-inline">
                          <div *ngIf="showError('helpWithFormSelected')" class="text-error">
                            <div *ngIf="getErrorsFromField('helpWithFormSelected')?.required">
                              ¿Recibiste ayuda para completar este formulario? es requerido.
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ACCORDION 3 - WORK INFO -->
            <div *ngIf="isDependent" class="accordion-item">
              <div class="mb-2">
                <button
                  class="btn-accordion collapsed justify-content-between align-items-center"
                  accordion-heading
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseWorkInfo"
                  aria-expanded="false"
                  (click)="changeStep(2); changeStepTitle('secondStepTitleWork'); changeUrl('paso3')"
                >
                  <span>
                    <svg-icon
                      [src]="'/assets/icons/financing/briefcase.svg'"
                      [applyClass]="true"
                      class="btn-accordion-icon"
                    ></svg-icon>
                    Información laboral
                  </span>
                  <span>
                    <svg-icon
                      [src]="'/assets/icons/arrow-up-without-circle.svg'"
                      [applyClass]="true"
                      class="btn-accordion-icon arrow-up-icon"
                    ></svg-icon>
                  </span>
                </button>
                <div id="collapseWorkInfo" class="collapse box-accordion" data-parent="#accordion">
                  <div class="form-container-accordion">
                    <div class="row mb-1">
                      <div class="col-12">
                        <label class="label-control">Ocupación o profesión</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="¿A qué te dedicas?"
                          maxlength="100"
                          formControlName="occupation"
                        />
                        <label class="form-inline">
                          <div *ngIf="showError('occupation')" class="text-error">
                            <div *ngIf="getErrorsFromField('occupation')?.required">
                              Nombre del negocio es requerido.
                            </div>
                            <div *ngIf="getErrorsFromField('occupation')?.onlyLetter">
                              No se admiten caracteres especiales ni números.
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-12">
                        <label class="control-label">Inicio de actividad laboral </label>
                        <yevo-datepicker
                          [label]="'Inicio de actividad laboral'"
                          [showLabel]="false"
                          [placeholder]="'dd/mm/aaaa'"
                          [obligatory]="true"
                          [validateFullAge]="true"
                          [dateSelected]="userForm.get('workStartDate')?.value"
                          (selectDate)="selectWorkStartDate($event)"
                          [showManualError]="showError('workStartDate')"
                        ></yevo-datepicker>
                        <label class="form-inline">
                          <div *ngIf="showError('workStartDate')" class="text-error">
                            <div *ngIf="getErrorsFromField('workStartDate')?.required">
                              Fecha de inicio de actividad laboral es requerido.
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-12">
                        <label class="label-control">Sueldo bruto mensual</label>
                        <div class="currency-container">
                          <label class="currency-label d-flex"
                            >S/
                            <div *ngIf="!userForm.get('grossSalary')?.value">0.00</div>
                          </label>
                        </div>
                        <input
                          type="tel"
                          class="form-control currency-input"
                          maxlength="7"
                          formControlName="grossSalary"
                        />
                        <label class="form-inline">
                          <div *ngIf="showError('grossSalary')" class="text-error">
                            <div *ngIf="getErrorsFromField('grossSalary')?.required">Sueldo bruto es requerido.</div>
                            <div *ngIf="!!getErrorsFromField('grossSalary')?.onlyNumber">Solo se admiten números.</div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-12">
                        <label class="label-control">Gastos mensuales</label>
                        <div class="currency-container">
                          <label class="currency-label d-flex"
                            >S/
                            <div *ngIf="!userForm.get('workExpense')?.value"></div>
                            0.00</label
                          >
                        </div>
                        <input
                          type="tel"
                          class="form-control currency-input"
                          maxlength="7"
                          formControlName="workExpense"
                        />
                        <a class="info-message-button" href="" [routerLink]="" (click)="showHideWorkExpenseMessage()">
                          <svg-icon src="/assets/icons/info-green-2x.svg"></svg-icon>
                        </a>
                        <label class="form-inline">
                          <div *ngIf="showError('workExpense')" class="text-error">
                            <div *ngIf="getErrorsFromField('workExpense')?.required">
                              Gastos mensuales es requerido.
                            </div>
                            <div *ngIf="!!getErrorsFromField('workExpense')?.onlyNumber">Solo se admiten números.</div>
                          </div>
                        </label>
                      </div>
                      <div class="col-12" *ngIf="showWorkExpenseMessage">
                        <p class="aditional-info">
                          <span class="high-green">Coloca la suma total de:</span> Gastos de alimentación + Alquiler de
                          vivienda + Luz + Agua + Teléfono fijo + Línea móvil + Otros (mensualidad escolar, movilidad
                          escolar, apoyo económico a familiar, etc.) + Pagos mensuales de préstamos + Tarjetas de
                          crédito + prestamistas.
                        </p>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-12">
                        <label class="label-control">Empresa en la que labora</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Nombre de la empresa"
                          maxlength="100"
                          formControlName="employerName"
                        />
                        <label class="form-inline">
                          <div *ngIf="showError('employerName')" class="text-error">
                            <div *ngIf="getErrorsFromField('employerName')?.required">
                              Nombre de la empresa es requerido.
                            </div>
                            <div *ngIf="getErrorsFromField('employerName')?.onlyLetter">
                              No se admiten caracteres especiales ni números.
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-12">
                        <label class="label-control">RUC de empresa en la que labora</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="RUC"
                          maxlength="11"
                          formControlName="employerRuc"
                        />
                        <label class="form-inline">
                          <div *ngIf="showError('employerRuc')" class="text-error">
                            <div *ngIf="getErrorsFromField('employerRuc')?.required">
                              RUC de empresa en que labora es requerido.
                            </div>
                            <div *ngIf="getErrorsFromField('employerRuc')?.minlength">RUC inválido.</div>
                            <div *ngIf="!!getErrorsFromField('employerRuc')?.onlyNumber">Solo se admiten números.</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="submit-button-container mt-3 mb-5" *ngIf="!isDependent">
            <button
              type="button"
              class="d-block border-0 btn-submit"
              (click)="showAmount ? showNextFormGroupStepTwo() : showNextFormGroup(); googleMapsAutocomplete()"
            >
              Continuar
            </button>
          </div>

          <div *ngIf="isDependent" class="submit-button-container mt-3 mb-5">
            <button type="submit" [disabled]="!isInitialFormValid" class="d-block border-0 btn-submit">
              Enviar solicitud
            </button>
          </div>
        </div>
        <!-- END FORM INITIAL 1 -->

        <!-- FORM FINAL -->
        <div *ngIf="!showInitialForm">
          <div class="row mb-1">
            <div class="card" [ngClass]="mobileBreakShow ? 'col-1' : 'd-none'"></div>
            <div class="col-10" [ngClass]="mobileBreakShow ? 'col-10' : 'col-12'">
              <div *ngIf="showFirstStepOfFinalForm">
                <div class="card p-2 m-1">
                  <div class="row mb-0">
                    <div class="col-12">
                      <h2 class="card-title">Ventas de mi negocio</h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-6 document-type marital-status-control">
                      <yevo-modal-selected
                        title="¿Cuál es la frecuencia de tus ventas?"
                        titleModal="Frecuencia de ventas"
                        [businessFrequencyList]="businessFrequencyList"
                        (itemSelectedEvent)="getBusinessFrequencyEvent($event)"
                        [invalid]="showError('businessSalesFrequency')"
                        [itemSelected]="businessFrequencySelected"
                      ></yevo-modal-selected>

                      <label class="form-inline">
                        <div *ngIf="showError('businessSalesFrequency')" class="text-error">
                          <div *ngIf="getErrorsFromField('businessSalesFrequency')?.required">
                            La frecuencia de tus ventas es requerido.
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12">
                      <label class="label-control"
                        >Ingresa tus ventas
                        <span *ngIf="businessFrequencySelected" class="label-highligthed">{{
                          businessFrequencySelected.label | lowercase
                        }}</span>
                      </label>
                      <div class="currency-container">
                        <label class="currency-label d-flex" for="sales"
                          >S/
                          <div *ngIf="!userForm.get('businessSalesAmount')?.value">0.00</div>
                        </label>
                      </div>
                      <input
                        id="sales"
                        name="sales"
                        type="tel"
                        class="form-control currency-input"
                        maxlength="7"
                        formControlName="businessSalesAmount"
                      />
                      <label class="form-inline">
                        <div *ngIf="showError('businessSalesAmount')" class="text-error">
                          <div *ngIf="getErrorsFromField('businessSalesAmount')?.required">Ventas es requerida.</div>
                          <div *ngIf="!!getErrorsFromField('businessSalesAmount')?.onlyNumber">
                            Solo se admiten números.
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="row mb-0 mt-3">
                    <div class="col-12">
                      <h2 class="card-title">Compras de mi negocio</h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-6 document-type marital-status-control">
                      <label class="label-control"
                        >¿Cuál es la <span>frecuencia de tus compras</span> para tu negocio?</label
                      >
                      <yevo-modal-selected
                        titleModal="Frecuencia de compras"
                        [businessFrequencyList]="businessPurchasesFrequencyList"
                        (itemSelectedEvent)="getBusinessPurchasesFrequencyEvent($event)"
                        [invalid]="showError('businessPurchasesFrequency')"
                        [itemSelected]="businessPurchasesFrequencySelected"
                      ></yevo-modal-selected>

                      <label class="form-inline">
                        <div *ngIf="showError('businessPurchasesFrequency')" class="text-error">
                          <div *ngIf="getErrorsFromField('businessPurchasesFrequency')?.required">
                            La frecuencia de compras es requerido.
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 lastname-control">
                      <label
                        >Indica el monto de tus compras
                        <span *ngIf="businessPurchasesFrequencySelected" class="label-highligthed">{{
                          businessPurchasesFrequencySelected.label | lowercase
                        }}</span>
                      </label>
                      <div class="currency-container">
                        <label class="currency-label d-flex" for="purchases"
                          >S/
                          <div *ngIf="!userForm.get('businessPurchasesAmount')?.value">0.00</div></label
                        >
                      </div>
                      <input
                        id="purchases"
                        name="purchases"
                        type="tel"
                        class="form-control currency-input"
                        maxlength="7"
                        formControlName="businessPurchasesAmount"
                      />
                      <label class="form-inline">
                        <div *ngIf="showError('businessPurchasesAmount')" class="text-error">
                          <div *ngIf="getErrorsFromField('businessPurchasesAmount')?.required">
                            Monto de compras es requerida.
                          </div>
                          <div *ngIf="!!getErrorsFromField('businessPurchasesAmount')?.onlyNumber">
                            Solo se admiten números.
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="row mb-0 mt-3">
                    <div class="col-12">
                      <yevo-modal-info
                        title="Gastos de mi negocio"
                        titleModal="Gastos de mi negocio"
                        descriptionModal="Coloca la <b>suma total</b> de:
                      Alquiler de local + Transporte de mercadería + Luz + Agua + Celular del negocio + Arbitrios + Otros (publicidad, personal de servicio, etc.)"
                      ></yevo-modal-info>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 lastname-control">
                      <label>¿Cuál es el monto mensual de tus <span>gastos adicionales</span>?</label>
                      <div class="currency-container">
                        <label class="currency-label d-flex" for="additionalExpense"
                          >S/
                          <div *ngIf="!userForm.get('businessAdditionalExpense')?.value">0.00</div></label
                        >
                      </div>
                      <input
                        id="additionalExpense"
                        name="additionalExpense"
                        type="tel"
                        class="form-control currency-input"
                        maxlength="7"
                        formControlName="businessAdditionalExpense"
                      />

                      <label class="form-inline">
                        <div *ngIf="showError('businessAdditionalExpense')" class="text-error">
                          <div *ngIf="getErrorsFromField('businessAdditionalExpense')?.required">
                            Gastos adicionales de tu negocio es requerido.
                          </div>
                          <div *ngIf="!!getErrorsFromField('businessAdditionalExpense')?.onlyNumber">
                            Solo se admiten números.
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="col-12" *ngIf="showBusinessAdditionalExpenseMessage">
                      <p class="aditional-info">
                        <span class="high-green">Coloca la suma total de:</span> Alquiler de local + Transporte de
                        mercadería + Luz + Agua + Celular del negocio + Arbitrios + Otros (publicidad, personal de
                        servicio, etc.)
                      </p>
                    </div>
                  </div>
                  <div class="row mb-0 mt-3">
                    <div class="col-12">
                      <yevo-modal-info
                        title="Gastos familiares"
                        titleModal="Gastos familiares"
                        descriptionModal="Coloca la <b>suma total</b> de:
                        Gastos de alimentación + Alquiler de vivienda + Luz + Agua + Teléfono fijo + Línea móvil + Otros (mensualidad escolar, movilidad escolar, apoyo económico a familiar, etc.)"
                      ></yevo-modal-info>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12">
                      <label class="label-control">Gastos familiares mensuales</label>
                      <div class="currency-container">
                        <label class="currency-label d-flex" for="familyExpenses"
                          >S/
                          <div *ngIf="!userForm.get('familyExpense')?.value">0.00</div></label
                        >
                      </div>
                      <input
                        id="familyExpenses"
                        name="familyExpenses"
                        type="tel"
                        class="form-control currency-input"
                        maxlength="7"
                        formControlName="familyExpense"
                      />

                      <label class="form-inline">
                        <div *ngIf="showError('familyExpense')" class="text-error">
                          <div *ngIf="getErrorsFromField('familyExpense')?.required">
                            Gastos familiares es requerido.
                          </div>
                          <div *ngIf="!!getErrorsFromField('familyExpense')?.onlyNumber">Solo se admiten números.</div>
                        </div>
                      </label>
                    </div>
                    <div class="col-12" *ngIf="showFamilyExpenseMessage">
                      <p class="aditional-info text-center px-4 px-md-2">
                        <span class="high-green">Coloca la suma total de:</span><br />
                        Gastos de alimentación + Alquiler de vivienda + Luz + Agua + Teléfono fijo + Línea móvil + Otros
                        (mensualidad escolar, movilidad escolar, apoyo económico a familiar, etc.)
                      </p>
                    </div>
                  </div>
                  <div class="row mb-0 mt-3">
                    <div class="col-12">
                      <yevo-modal-info
                        title="Gastos financieros"
                        titleModal="Gastos financieros"
                        descriptionModal="Si tienes créditos adicionales, <b>considera la suma de:</b>
                        Créditos con prestamistas +  Cooperativas + ONGs + Préstamos de familiares que usted asume."
                      ></yevo-modal-info>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 lastname-control">
                      <label>Monto total de pagos mensuales, préstamos, tarjetas (sin incluir Mibanco)</label>
                      <div class="currency-container">
                        <label class="currency-label d-flex" for="additionalPayments"
                          >S/
                          <div *ngIf="!userForm.get('additionalPayment')?.value">0.00</div></label
                        >
                      </div>
                      <input
                        id="additionalPayments"
                        name="additionalPayments"
                        type="tel"
                        class="form-control currency-input"
                        maxlength="7"
                        formControlName="additionalPayment"
                      />
                      <label class="form-inline">
                        <div *ngIf="showError('additionalPayment')" class="text-error">
                          <div *ngIf="getErrorsFromField('additionalPayment')?.required">
                            Pagos totales es requerido.
                          </div>
                          <div *ngIf="!!getErrorsFromField('additionalPayment')?.onlyNumber">
                            Solo se admiten números.
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-12 lastname-control">
                      <label>Préstamos mensuales adicionales con prestamistas, ONG.</label>
                      <div class="currency-container">
                        <label class="currency-label d-flex" for="additionalLoans"
                          >S/
                          <div *ngIf="!userForm.get('additionalLoansAmount')?.value">0.00</div></label
                        >
                      </div>
                      <input
                        id="additionalLoans"
                        name="additionalLoans"
                        type="tel"
                        class="form-control currency-input"
                        maxlength="7"
                        formControlName="additionalLoansAmount"
                      />

                      <label class="form-inline">
                        <div *ngIf="showError('additionalLoansAmount')" class="text-error">
                          <div *ngIf="getErrorsFromField('additionalLoansAmount')?.required">
                            Préstamos adicionales es requerido.
                          </div>
                          <div *ngIf="!!getErrorsFromField('additionalLoansAmount')?.onlyNumber">
                            Solo se admiten números.
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="col-12" *ngIf="showAditionalLoansMessage">
                      <p class="aditional-info w-90">
                        <span class="high-green"
                          >Si tienes créditos adicionales,<br />
                          considera la suma de:</span
                        ><br />
                        Créditos con prestamistas + Cooperativas + ONGs + Préstamos de familiares que usted asume.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="showSecondStepOfFinalForm">
                <div class="card p-2 m-1">
                  <div class="row mb-1">
                    <div class="col-12">
                      <p class="title-upload-required mt-2" *ngIf="showUploadErrorMessage">
                        {{ uploadErrorMessage }}
                      </p>
                      <yevo-selfie
                        (selfie)="addSelfie($event)"
                        (showDocument)="buttonAcceptedSelfie($event)"
                        (uploadDocument)="buttonUploadSelfie($event)"
                        [uploadSelfie]="selfie"
                        [progressObject]="financingService.selfieProgress"
                        [progress]="financingService.selfieProgress.progress"
                      ></yevo-selfie>
                      <yevo-documents
                        (documents)="addDocuments($event)"
                        (showDocument)="buttonAcceptedDocument($event)"
                        (uploadDocument)="buttonUploadDocument($event)"
                        [uploadDocuments]="documents"
                      ></yevo-documents>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              [ngClass]="mobileBreakShow ? 'col-1 d-flex align-items-center justify-content-center' : 'd-none'"
              class="card"
            >
              <a
                href=""
                [routerLink]=""
                (click)="nextStepOfFinalForm()"
                *ngIf="isFinalFormValid && !showThirdStepOfFinalForm"
              >
                <svg-icon src="/assets/icons/arrow-right-without-circle-2x.svg"></svg-icon>
              </a>
            </div>
          </div>
          <oigo-loading></oigo-loading>
          <div *ngIf="!showThirdStepOfFinalForm" class="submit-button-container mt-3 mb-5">
            <button type="button" class="d-block border-0 btn-submit" (click)="nextStepOfFinalForm()">Continuar</button>
          </div>
          <!-- MAIN BUTTON -->
          <div *ngIf="showThirdStepOfFinalForm" class="submit-button-container mt-3 mb-5">
            <button type="submit" class="d-block border-0 btn-submit">Enviar Solicitud</button>
          </div>
        </div>
        <!-- END FORM FINAL -->
      </form>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'yevo-financing-loan-simulator-additional-form',
  templateUrl: './financing-loan-simulator-additional-form.component.html',
  styleUrls: ['./financing-loan-simulator-additional-form.component.scss'],
})
export class FinancingLoanSimulatorAdditionalFormComponent implements OnInit {
  @Input() hideLayout: boolean = true;
  @Input() amount: number = 0;
  @Input() paymentDeadline: number = 0;
  @Input() maxPaymentRate: number = 0;

  public loanSimulatorAdditionalForm!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.loanSimulatorAdditionalForm = this.fb.group({
      amount: [this.formatNumber(this.amount)],
      paymentDeadline: [this.paymentDeadline + ' meses'],
      maxPaymentRate: [this.formatNumber(this.maxPaymentRate)],
    });
  }

  formatNumber(number: number): string {
    return number.toFixed(2);
  }
}

<div class='container'>
  <div class='row m-0 p-0'>
    <a (click)='goHome()' class='div__a'>
      <img style='margin-right: 4px;' src='./assets/icons/communities/arrow.svg' alt=''>Home
    </a>
  </div>
  <div class='row m-0 p-0'>
    <p class='title-communities'>Videos de la Comunidad</p>
  </div>
  <div class='row m-0 p-0'>
    <h4 class='h4-title '>¡Aprende en menos de 2 minutos!</h4>
  </div>
  <div class='row m-0'>
    <p class='text-category'>Categorías</p>
  </div>
  <div class='category-mobile'>
    <ngx-slick-carousel [config]='slickConfig' style='margin-bottom: 20px'>
      <div ngxSlickItem *ngFor='let item of category, let i = index' class='slide'>
        <button type='button' class='btn btn-category'
                [ngClass]="{'active': isPressed[i] }" (click)='toggleButton(i)'>{{ item.name }}</button>
      </div>
    </ngx-slick-carousel>
  </div>
  <div class='row category-desktop'>
    <div class='m-md-1 m-lg-1' *ngFor='let item of category, let i = index'>
      <button type='button' class='btn btn-category'
              [ngClass]="{'active': isPressed[i]}" (click)='toggleButton(i)'>{{ item.name }}</button>
    </div>
  </div>
  <div class="row m-0 p-0 te-recomendamos-text" *ngIf='showRecommendText && this.isPressed[0] && this.category[0].name === "Para Mí"'>
    Te recomendamos
  </div>
  <div class='row m-0 p-0' style='margin: 25px auto 25px;'>
    <div class='col-sm-1 col-md-4 d-flex p-0' *ngFor='let video of videosShows; let i = index'>
      <yevo-video-communities [video]='video' [source]='source' [sourceClick]='sourceClick' class='card-videos'></yevo-video-communities>
    </div>
  </div>
  <div class='row'>
    <p class='text-pagination'>1 - {{ length }} de {{ videos.length }} videos</p>
  </div>
  <div class='row' *ngIf='showButton'>
    <button type='button' class='btn button-pagination' (click)='pagination()'>Ver {{videos.length - length }} videos más</button>
  </div>
</div>

<div class='div__allied-container__color'>
  <div class='container'>
    <div class='row justify-content-center'>
      <h4>Negocios aliados</h4>
    </div>
    <div class='row justify-content-center mx-0'>
      <p class='allied__subtitle'>Disfruta de los beneficios que negocios dentro de Yevo tienen para ti</p>
    </div>
    <div class='row m-0'>
      <swiper [config]='configSwiper' class='swiper'>
        <ng-template swiperSlide *ngFor='let item of dataAllied, let i = index;'>
          <div class="card-allied">
            <img class="allied-img-cover" [src]="item.img" alt="imagen-aliado">
            <span [ngClass]="{ 'free': item.discount === 'Gratis'}">{{ item.discount }}</span>
            <div class="d-flex flex-align-start justify-content-start p-2">
              <img class="allied-logo" [src]="item.logo" alt="logo">
              <div>
                <p class="card-allied__name">{{ item.name }}</p>
                <p class="card-allied__description">{{ item.description }}</p>
                <button (click)="openModal(item)" class="card-allied__button">{{ item.button }}</button>
              </div>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
    <div class='row swiper-pagination'></div>
    <div class='row p-0 m-auto'>
      <a (click)='optionSelectedBlockFinancing("Todos los aliados")' routerLink='/aliados' class='btn-allied-section'>Ver todos los aliados</a>
    </div>
  </div>
</div>


<!-- ********************* MODAL *********************** -->
<ng-template #content let-c="close" let-d="dismiss" class="modal-contents">
  <div class="modal-body text-center py-4 hidden">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <img src="/assets/icons/close-green.svg" alt="cerrar" />
    </button>
    <img [src]="modalData.image" alt="image" class="modal-allied-image mb-3 mt-3" />
    <div class="container">
      <div class="row">
        <div class="col-md-12 modal-allied-line d-flex pl-0 pr-0">
          <div *ngIf="modalData.tags.length" class="d-flex">
            <span class="modal-allied-tags mt-3 mb-3" *ngFor="let item of modalData.tags; let i = index">
              <img [src]="tagsIcon[i]" alt="image" class="modal-allied-icon" /> {{ item.label }}</span
            >
            <span class="modal-allied-tags mt-3 mb-3">
              <img src="/assets/img/allies/store.svg" alt="image" class="modal-allied-icon" />Negocio</span
            >
          </div>
        </div>

        <div class="col-12 pl-0 pr-0">
          <p class="modal-allied-title">{{ modalData.title }}</p>
        </div>

        <div class="col-md-12 modal-allied-line pl-0 pr-0" *ngIf="modalData.description">
          <p class="modal-allied-description mt-3" [innerHTML]="modalData.description"></p>
        </div>

        <div class="col-12 mb-3 text-center pl-0 pr-0 mt-4" *ngIf="modalData.link">
          <button class="btn btn-primary modal-allied-button" (click)="openLink(modalData.link); optionSelectedBlockFinancing(modalData.title)">
            {{ modalData.button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<div class="image-gradent-background">
  <div class="container five-agencies" id="five-agencies">
    <h1 class="title">¡Ubica tu sede más cercana!</h1>
    <h2 class="description">Disponible en estas 5 agencias Mibanco</h2>
    <div class="content-desktop">
      <div class="tabs">
        <input class="input" name="tabs" type="radio" id="tab-1" checked="checked"/>
        <label class="label" for="tab-1" (click)="showOptions('tab1','Surco')" (keyup)="handleKeyUp($event)">
          <img *ngIf="!activedTagOne" class="ml-2" src="/assets/img/landing/remittances/btn-down.png" alt="open">
          <img *ngIf="activedTagOne" class="ml-2" src="/assets/img/landing/remittances/btn-up.png" alt="close">
          Surco 
          <span>Av. Los Próceres 155</span>
          <ng-container *ngIf="activedTagOne" > 
            <span class="mt-2">L-V: 9am a 6pm</span>
            <span>Sábado: 9am a 1pm</span>
          </ng-container>
        </label>
        <div class="panel">
          <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.46220882147!2d-76.98901372517307!3d-12.148903688095977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105b86ac663a4ab%3A0x668cb64fd07d850e!2sMibanco%20Surco!5e0!3m2!1ses-419!2spe!4v1715111730706!5m2!1ses-419!2spe"
          width="100%"
          height="340"
          title="Surco"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        </div>
        <input class="input" name="tabs" type="radio" id="tab-2"/>
        <label class="label" for="tab-2" (click)="showOptions('tab2',' Mar. Cáceres')" (keyup)="handleKeyUp($event)">
          <img *ngIf="!activedTagTwo" class="ml-2" src="/assets/img/landing/remittances/btn-down.png" alt="open">
          <img *ngIf="activedTagTwo" class="ml-2" src="/assets/img/landing/remittances/btn-up.png" alt="close">
          Mar. Cáceres
          <span>Av. Próceres de la Independencia 5118</span>
          <ng-container *ngIf="activedTagTwo" > 
            <span class="mt-2">L-V: 9am a 6pm</span>
            <span>Sábado: 9am a 1pm</span>
          </ng-container>
         
        </label>
        <div class="panel">
          <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3903.3701835912443!2d-76.99033002218235!3d-11.948852058062029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105dacacc187153%3A0x316c5cad820ba3a0!2sAv.%20Pr%C3%B3ceres%20de%20la%20Independencia%205118%2C%20San%20Juan%20de%20Lurigancho%2015442!5e0!3m2!1ses-419!2spe!4v1715111835809!5m2!1ses-419!2spe"
          width="100%"
          title="Independencia"
          height="340"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        </div>
        <input class="input" name="tabs" type="radio" id="tab-3"/>
        <label class="label" for="tab-3" (click)="showOptions('tab3','Gamarra')" (keyup)="handleKeyUp($event)">
          <img *ngIf="!activedTagThree" class="ml-2" src="/assets/img/landing/remittances/btn-down.png" alt="open">
          <img *ngIf="activedTagThree" class="ml-2" src="/assets/img/landing/remittances/btn-up.png" alt="close">
          Gamarra
            <span>Jr. Humboldt 1609</span>
            <ng-container *ngIf="activedTagThree" > 
              <span class="mt-2">L-V: 9am a 6pm </span>
              <span>Sábado: 9am a 1pm</span>
            </ng-container>
           
        </label>
        <div class="panel">
          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3901.705056291901!2d-77.013459!3d-12.063803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDAzJzQ5LjciUyA3N8KwMDAnNDguNSJX!5e0!3m2!1ses-419!2spe!4v1726174184585!5m2!1ses-419!2spe" width="100%" height="340" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Gamarra"></iframe>
        </div>
        <input class="input" name="tabs" type="radio" id="tab-4"/>
        <label class="label" for="tab-4" (click)="showOptions('tab4','Pte. Piedra')" (keyup)="handleKeyUp($event)">
          <img *ngIf="!activedTagFour" class="ml-2" src="/assets/img/landing/remittances/btn-down.png" alt="open">
          <img *ngIf="activedTagFour" class="ml-2" src="/assets/img/landing/remittances/btn-up.png" alt="close">
          Pte. Piedra
          <span>Panamercana Nte. 1057</span>
          <ng-container *ngIf="activedTagFour" > 
            <span class="mt-2">L-V: 9am a 6pm </span>
          <span>Sábado: 9am a 1pm</span>
          </ng-container>
          
        </label>
        <div class="panel">
          <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d976.1404585441859!2d-77.07548513130965!3d-11.86591368064001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105d1349989b69d%3A0xe91e7e4cda4a5832!2sAu.%20Panamericana%20Nte.%201057%2C%20Puente%20Piedra%2015118!5e0!3m2!1ses-419!2spe!4v1715111957356!5m2!1ses-419!2spe"
          width="100%"
          height="340"
          style="border: 0"
          title="Puente piedra"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        </div>
        <input class="input" name="tabs" type="radio" id="tab-5"/>
        <label class="label" for="tab-5" (click)="showOptions('tab5','Villa Maria')" (keyup)="handleKeyUp($event)">
          <img *ngIf="!activedTagFive" class="ml-2" src="/assets/img/landing/remittances/btn-down.png" alt="open">
          <img *ngIf="activedTagFive" class="ml-2" src="/assets/img/landing/remittances/btn-up.png" alt="close">
          Villa Maria
            <span>Av. Salvador Allende 1304</span>
            <ng-container *ngIf="activedTagFive" > 
              <span class="mt-2">L-V: 9am a 6pm</span>
              <span>Sábado: 9am a 1pm</span>
            </ng-container>
         
        </label>
        <div class="panel">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.303882348083!2d-76.95893792586111!3d-12.159702544011946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105b8f0854cc82b%3A0x470c4216984aa165!2sMibanco%20S.A.!5e0!3m2!1ses-419!2spe!4v1726153317219!5m2!1ses-419!2spe" width="100%" height="340" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="villa maria"></iframe>
        </div>
      </div>
    </div>
    <div class="container-button-mobile content-mobile">
        <div class="cards-container-button mt-4">
          <div>
            <div *ngFor="let detail of faqDetailsButton; let index = index"
            [ngClass]="isVisibleButton[index]?'card-accordeon-button clicked': 'card-accordeon-button'">
              <div class="card-accordeon_header-button">
                <h5 class="mb-0">
                  <button class="btn btn-link clicked" (click)="questionOnClick(index, detail.title)" [attr.aria-expanded]="isVisibleButton[index]">
                    <div>{{ detail.title }} 
                      <img *ngIf="!isVisibleButton[index]" class="ml-2" src="/assets/img/landing/remittances/btn-down.png" alt="open">
                      <img *ngIf="isVisibleButton[index]" class="ml-2" src="/assets/img/landing/remittances/btn-up.png" alt="close">
                    </div>
                    <span class="mt-2">{{ detail.adress }}</span>
                   
                  </button>
                </h5>
              </div>
              <div class="collapse" [ngbCollapse]="!isVisibleButton[index]">
                <div class="card-accordeon_body-button">
                  <span>{{ detail.hour }}</span>
                  <span>{{ detail.date }}</span>
                  <iframe
                  [src]="changeIframeMap(detail.url)"
                  width="100%"
                  height="320"
                  title=""
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  class="mt-2"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

  </div>
</div>

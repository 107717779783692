import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserProfile } from '@shared/models/UserProfileModel';
import { AuthService } from '@shared/services/auth.service';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class UserProfileResolver {
  constructor(private authService: AuthService, @Inject(PLATFORM_ID) private platformId: any) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (isPlatformBrowser(this.platformId)) {
      return new Promise((resolve) => {
        this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
          user ? resolve(user as UserProfile) : this.authService.getUserProfile();
        });
      });
    }
    return of().toPromise();
  }
}

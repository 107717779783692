<div class="educational_content_section_container">
  <div class="educational_content_section_container_title">¡Capacítate para mejorar tu negocio!</div>
  <div class="educational_content_section_container_description">
    Aprende nuevas cosas de manera 100% práctica e interactiva en cuestión de minutos
  </div>
  <div class="tabs-container overflow container">
    <ul class="nav nav-pills tabs-container_mb-4" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation" *ngFor="let tab of items">
        <a
          [ngClass]="{ active: tab.active }"
          class="nav-link d-flex active"
          data-toggle="pill"
          (click)="selectTab(tab)"
        >
          {{ tab.label }}
        </a>
      </li>
    </ul>
  </div>
  <div class="educational_content_section_container_tabs"></div>
  <div class="educational_content_section_container_content"></div>
</div>

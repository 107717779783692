import { Component, Input } from '@angular/core';
import { Level } from '@shared/interfaces/frontend/communities';

@Component({
  selector: 'yevo-levels-card',
  templateUrl: './levels-card.component.html',
  styleUrls: ['./levels-card.component.scss']
})
export class LevelsCardComponent {

@Input() levels!: Level;

}
<div class="container certified_page">
  <div class="row">
    <div class="breadcrumb mt-4 ml-3">
      <yevo-breadcrumb [breadcrumbs]="breadcrumbs"></yevo-breadcrumb>
    </div>
  </div>
  <div class="certified_page_title">Certificados</div>
  <div class="certified_page_description">
    Obtendrás un certificado cada vez que completes un webinar o Programa Especializado.
  </div>
  <div class="certificates_container">
    <div class="certificates_header">
      <div class="certificates_header_module">Módulo</div>
      <div class="certificates_header_date">Fecha</div>
      <div class="certificates_header_certificate">Certificado</div>
    </div>
    <div class="certificates_body" *ngIf="certificates.length > 0">
      <div class="certificates_body_row" *ngFor="let cert of certificates">
        <div class="certificates_body_row_module">
          <div class="certificates_body_row_module_title">{{ cert.certificateCentralName }}</div>
          <div class="certificates_body_row_module_type">{{ cert.type }}</div>
        </div>
        <div class="certificates_body_row_date">
          <div class="certificates_body_row_date_isnew">
            <div *ngIf="cert.new" class="_dot"></div>
            <span>{{ cert.new ? 'Nuevo' : '' }}</span>
          </div>
          <div class="certificates_body_row_date_date">{{ cert.dateCertificate | shortDate }}</div>
        </div>
        <div class="certificates_body_row_certificate" (click)="openModal(modalContent, cert)">
          <img src="assets/icons/communities/certified_icon.svg" alt="certified_icon" />
          <div class="certificates_body_row_certificate_label">Ver certificado</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="last-container">
  <img src="/assets/icons/communities/new-landing-end.svg" alt="clouds" class="last-img" />
</div>
<ng-template #modalContent class="modal_certificate">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Cerrar" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="type">{{ typeModal }}</div>
    <div class="title">{{ titleModal }}</div>
    <div class="iframe_pdf_certificate">
      <div id="src_pdf_test" class="src_pdf" #srcpdftest>
        <div class="image_pdf">
          <img [src]="srcimgpdf" alt="side_pdf_image" />
        </div>
        <div class="body_pdf_container">
          <div class="body_pdf">
            <div class="logo">
              <img src="assets/img/communities/certificate/certificado_logo.svg" alt="side_pdf_image" />
            </div>
            <div class="title contenido">
              {{ this.certificateSelected.certificateCentralName }}
            </div>
            <div class="p_1 contenido">Otorgado a</div>
            <div class="belongs_to contenido">
              {{ this.certificateSelected.completeName }}
            </div>
            <div class="p_2 contenido">Por culminar satisfactoriamente el webinar</div>
            <div class="certificate_title contenido">
              {{ this.certificateSelected.certificateName }}
            </div>
            <div class="p_2 contenido">
              durante {{ this.certificateSelected.hours }} horas elaborado por la Comunidad
              {{ this.sourcePagePdf }}
              de Yevo de Mibanco.
            </div>
            <div class="p_2 contenido">{{ this.transformDate(this.certificateSelected.dateCertificate) }}</div>
            <div class="firma contenido">
              <div class="firmaimg contenido">
                <img src="assets/img/communities/certificate/certificado_firma.svg" alt="side_pdf_image" />
              </div>
              <div class="firma_label">
                <div class="firma_label_name">Laura Villanueva</div>
                <div class="firma_label_pos">Gerente de Marca y conocimiento del cliente</div>
              </div>
            </div>
          </div>
          <div class="footer_pdf">
            <img src="assets/img/communities/certificate/certificado_bottom_image.svg" alt="side_pdf_image" />
          </div>
        </div>
      </div>
    </div>
    <div class="button_container">
      <div class="download_button" (click)="downloadpdf()">
        <img src="/assets/icons/communities/download_certificate_icon.svg" alt="download_icon" />
        Descargar
      </div>
    </div>
  </div>
</ng-template>

import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  HostListener,
  SimpleChanges,
  Inject,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { getModalOptionsWithCustomClass } from '@shared/constants/ui-modal.constants';
import { IDropdownItem, ISelectItem } from '@shared/interfaces/IDropdownItem';
import { eliminarTildes } from '@shared/utils/string.util';

@Component({
  selector: 'yevo-searchable-input',
  templateUrl: './searchable-input.component.html',
  styleUrls: ['./searchable-input.component.scss'],
})
export class SearchableInputComponent implements OnInit {
  @Input() items: IDropdownItem[] = [];
  @Input() itemSelected!: IDropdownItem | null;
  @Input() showManualError: boolean = false;
  @Output() itemSelectedEvent = new EventEmitter<ISelectItem>();

  modalReference!: NgbModalRef;
  isMobile: boolean = true;
  showFullScreenModal: boolean = false;

  allItems!: IDropdownItem[];
  mostPopularItems!: IDropdownItem[];

  txtSearch: string = '';
  deleteSearch: boolean = false;

  constructor(private modalService: NgbModal, @Inject(DOCUMENT) private _document: any) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.generateValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.itemSelected?.currentValue) {
      this.itemSelected = changes?.itemSelected?.currentValue;
    }
    if (changes?.items?.currentValue) {
      this.generateValues();
    }
  }

  generateValues() {
    this.allItems = this.items;
    this.mostPopularItems = this.allItems.filter((item: IDropdownItem) => item.order! > 0);

    this.mostPopularItems.sort((a: IDropdownItem, b: IDropdownItem) => a.order! - b.order!);
  }

  openModal(content: TemplateRef<any>) {
    const modalClass = this.getModalClass();
    this.modalReference = this.modalService.open(content, getModalOptionsWithCustomClass(modalClass, 'lg', true));

    this.modalReference.result.then(
      (result) => {},
      (reason) => (this.showFullScreenModal = false)
    );
  }

  selectItem(event: any, item: IDropdownItem): void {
    this.itemSelected = item;
    this.itemSelectedEvent.emit({ event, item });
    this.txtSearch = item.label;
    this.onSearchChange({ value: item.label });
    if (NgbModalRef) this.closeModal();
  }

  closeModal(): void {
    this.modalReference?.close();
    this.showFullScreenModal = false;
  }

  getModalClass(): string {
    return this.isMobile ? 'custom-modal-mobile-dropdown' : 'custom-modal-dropdown';
  }

  toggleFullScreenModal(): void {
    this.showFullScreenModal = true;
  }

  onSearchChange(searchValue: any): void {
    const searchQuery = searchValue?.value ? eliminarTildes(searchValue.value) : '';
    this.deleteSearch = searchQuery != '';

    this.allItems = this.items.filter((item: any) =>
      eliminarTildes(item.label).toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    this.isMobile = window.innerWidth <= 768;
  }

  deleteSearchText() {
    this.txtSearch = '';
    this.deleteSearch = false;
    this.allItems = this.items;
  }

  allActivities() {
    this._document.getElementById('searched').focus();
    this.deleteSearchText();
    this.toggleFullScreenModal();
  }
}

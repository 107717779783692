<div class="modal-body">
  <div class="container">
    <div class="row mt-4">
      <div class="d-flex w-100 justify-content-center mb-4">
        <img src="/assets/icons/intranet/achievement.png" alt="achievement-icon" />
      </div>
      <div class="step-content col-sm-12">
        <div>
          <h2 class="step-content__main-title text-center mb-4">
            ¡Felicidades! Subiste un<br />
            nuevo nivel.
          </h2>
          <p class="step-content__subtitle text-center px-4 mb-4">
            Sigue usando Yevo para obtener puntos y ganar los mejores beneficios para ti. Mira tu progreso en el nuevo
            perfil de Yevo.
          </p>
          <div class="d-flex w-100 justify-content-center">
            <button type="button" class="btn btn-primary w-100 mb-4 mx-4" (click)="closeModal()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ld-json-script></ld-json-script>
<div id="affiliate-ad" class="container-fluid mt-header">
  <yevo-affiliate-ad></yevo-affiliate-ad>
</div>
<div id="affiliate-box" class="container-main">
  <div id="#steps-page" class="container afil-body">
    <div class="row">
      <div class="col-md-12 mb-3">
        <a class="go-back" (click)="goBack()">
          <img src="assets/icons/arrow-left.svg" alt="flecha para atrás" class="mr-2 go-back-image" />
          <span class="ml-1">Regresar</span>
        </a>
      </div>
      <div class="col-12 col-md">
        <ul class="nav justify-content-start">
          <li>
            <a class="nav-link px-0">
              <img class="bg-image" src="/assets/icons/negocio-contacto.svg" alt="negocio" />
              <img *ngIf="!isProductStep()" class="img-empty" alt="empty" />
              <img *ngIf="isProductStep()" class="bg-image" src="/assets/icons/negocio-line.svg" alt="negocios" />
            </a>
          </li>
          <li>
            <a class="nav-link px-0">
              <img
                *ngIf="!isProductStep()"
                class="bg-image"
                src="/assets/icons/negocio-productos-disabled.svg"
                alt="negocio"
              />
              <img *ngIf="isProductStep()" class="bg-image" src="/assets/icons/negocio-productos.svg" alt="negocio" />
              <img *ngIf="!isLocationStep()" class="img-empty" alt="empty" />
              <img *ngIf="isLocationStep()" class="bg-image" src="/assets/icons/negocio-line.svg" alt="negocio" />
            </a>
          </li>
          <li>
            <a class="nav-link px-0">
              <img
                *ngIf="!isLocationStep()"
                class="bg-image"
                src="/assets/icons/negocio-localizacion-disabled.svg"
                alt="negocio"
              />
              <img
                *ngIf="isLocationStep()"
                class="bg-image"
                src="/assets/icons/negocio-localizacion.svg"
                alt="negocio"
              />
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <div [hidden]="stepStatus !== 'tab-general'">
            <yevo-general-info
              [generalInfo]="directoryData"
              [errorCode]="errorCode"
              [errorMsgType]="errorMsgType"
              (stateAfterFieldBlur)="saveFormStateInLocalStorage($event, sectionNames.general)"
            >
            </yevo-general-info>

            <yevo-contact-info
              [contactTypes]="contactTypes"
              [contactInformation]="directoryData?.contactInformation"
              (stateAfterFieldBlur)="saveFormStateInLocalStorage($event, sectionNames.contact)"
            >
            </yevo-contact-info>
          </div>

          <div [hidden]="stepStatus !== 'tab-product'">
            <p class="mt-3 title-page">Cuéntanos sobre los productos que ofreces</p>

            <yevo-product-info
              [paymentMethods]="paymentMethods"
              [categories]="categories"
              [generalInfo]="directoryData"
              [errorCode]="errorCode"
              [errorMsgType]="errorMsgType"
              (stateAfterFieldBlur)="saveFormStateInLocalStorage($event, sectionNames.product)"
            ></yevo-product-info>

            <yevo-image-uploader
              [loadedImages]="images"
              [directoryId]="directoryId"
              [changeView]="eventsSubject.asObservable()"
            ></yevo-image-uploader>
          </div>

          <div [hidden]="stepStatus !== 'tab-location'">
            <p class="mt-3 title-page">¡Llegamos al último paso!</p>
            <p class="description-page">
              Completa la ubicación de tu negocio para que más clientes sepan dónde encontrarlo
            </p>

            <yevo-locations
              [locations]="directoryData?.locationInformation"
              (stateAfterFieldBlur)="saveFormStateInLocalStorage($event, sectionNames.locations)"
            ></yevo-locations>
            <div *ngIf="generalError" class="mt-2">
              <label>
                <div class="text-error">
                  {{ generalError }}
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container afil-body">
  <ul class="nav justify-content-between btn-spaces m-0 mt-3 p-0">
    <li *ngIf="stepStatus == 'tab-general'" class="d-lg-flex mb-2 button-home">
      <a
        class="btn btn-outline-primary btn-w d-flex align-items-center justify-content-center button-home"
        routerLink="/nuevo-intranet/dashboard"
        queryParamsHandling="merge"
        ><img src="assets/icons/home/home-green.svg" alt="arrow-left" class="home-icon mb-1" />Ir al Inicio</a
      >
    </li>
    <li *ngIf="stepStatus == 'tab-general'" class="btn-continue">
      <button
        type="button"
        class="btn btn-primary btn-w d-flex align-items-center justify-content-center"
        (click)="onContinue(true, $event)"
        [disabled]="!isFormValidFirst && !btnEnabled"
      >
        Continuar
      </button>
    </li>

    <li *ngIf="stepStatus == 'tab-product'" class="d-none d-lg-flex">
      <a
        class="btn btn-outline-primary btn-w d-flex align-items-center justify-content-center"
        (click)="onContinue(false, $event)"
        data-target="#tab-general"
        data-toggle="tab"
        >Volver</a
      >
    </li>
    <li *ngIf="stepStatus == 'tab-product'" class="btn-continue">
      <button
        type="button"
        class="btn btn-primary btn-w d-flex align-items-center justify-content-center"
        (click)="onContinue(true, $event)"
        [disabled]="!isFormValidSecond"
      >
        Continuar
      </button>
    </li>
    <li *ngIf="stepStatus == 'tab-location'" class="d-none d-lg-flex">
      <a
        class="btn btn-outline-primary btn-w d-flex align-items-center justify-content-center"
        (click)="onContinue(false, $event)"
        ><img src="assets/icons/home/home-green.svg" alt="arrow-left" class="home-icon mb-1" />Ir al Inicio</a
      >
    </li>
    <li *ngIf="stepStatus == 'tab-location'" class="btn-continue">
      <button type="button" class="btn btn-primary btn-w" [disabled]="!isFormValid" (click)="affiliateAttempt($event)">
        Publicar negocio
      </button>
    </li>
  </ul>
</div>

<div class='modal modal-welcome' [ngClass]='{ disabled: isWelcome, active: isShow }' tabindex='-1' *ngIf='!isLoading'>
  <div class='modal-dialog'>
    <div class='modal-content border-0'>
      <div class='modal-header border-0'>
        <img class='img-fluid img-intro' [src]='welcomeTestImage' alt='' />
        <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'
                (click)='activeModal.close("Dismissed modal")'><img class='img-close'
                                                                    src='/assets/icons/communities/button-close.svg'
                                                                    alt=''>
        </button>
      </div>
      <div class='modal-body border-0'>
        <p class='text-welcome'>{{this.sourcePage === 'Bodegueros' ? '¡Bienvenido a la Comunidad' : '¡Bienvenida a Emprende Mujer'}}!</p>
        <p class='text-message'>Queremos conocer tu negocio para saber cómo podemos ayudarte.</p>
      </div>
      <div class='modal-footer border-0'>
        <button type='button' class='btn button-cancel' (click)="activeModal.close('Dismissed modal'); testLater();">En otro momento
        </button>
        <button type='button' class='btn button-start' data-bs-dismiss='modal'
                (click)='startTest()'>Iniciar
        </button>
      </div>
    </div>
  </div>
</div>
<div class='modal modal-question' [ngClass]='{active: isStart1 }' tabindex='-1' *ngIf='!isLoading'>
  <div class='modal-dialog'>
    <div class='modal-content border-0'>
      <div class='row justify-content-between button-header'>
        <button type='button' class='btn-back' aria-label='Back' (click)='back()'><</button>
        <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'
                (click)='activeModal.close("Dismissed modal")'><img style='margin: -20px;'
                                                                    src='/assets/icons/communities/button-close.svg'
                                                                    alt=''>
        </button>
      </div>
      <div class='row justify-content-between progress-and-text'>
        <div class='progress m-auto' role='progressbar' aria-label='Basic example' aria-valuenow='20' aria-valuemin='0'
              aria-valuemax='100'>
          <div class='progress-bar' style='width: 20%'></div>
        </div>
        <p class='text-stage m-auto'>1/5</p>
      </div>
      <div class='modal-header border-0 flex-column'>
        <img class='img-fluid m-auto' src='/assets/icons/communities/house-circle.svg' alt=''>
      </div>
      <div class='modal-body border-0'>
        <p class='text-question'>{{questionsCommunity[0].questionText}}</p>
        <div class='row buttons-question'>
          <div class='col-12 col-sm-12 col-md-6' *ngFor='let option of questionsCommunity[0].options; let i = index'>
            <button type='button' class='btn btn-question' (click)='startQuestion(i)'>
              {{ option.optionText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class='modal modal-question' [ngClass]='{active: isStart2}' tabindex='-1' *ngIf='!isLoading'>
  <div class='modal-dialog'>
    <div class='modal-content border-0'>
      <div class='row justify-content-between button-header'>
        <button type='button' class='btn-back' aria-label='Back' (click)='back()'><</button>
        <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'
                (click)='activeModal.close("Dismissed modal")'><img style='margin: -20px;'
                                                                    src='/assets/icons/communities/button-close.svg'
                                                                    alt=''>
        </button>
      </div>
      <div class='row justify-content-between progress-and-text'>
        <div class='progress m-auto' role='progressbar' aria-label='Basic example' aria-valuenow='40' aria-valuemin='0'
              aria-valuemax='100'>
          <div class='progress-bar' style='width: 40%'></div>
        </div>
        <p class='text-stage m-auto'>2/5</p>
      </div>
      <div class='modal-header border-0 flex-column'>
        <img class='img-fluid m-auto' src='/assets/icons/communities/people.svg' alt=''>
      </div>
      <div class='modal-body border-0'>
        <p class='text-question'>{{questionsCommunity[1].questionText}}</p>
        <div class='row buttons-question'>
          <div class='col-12 col-sm-12 col-md-6' *ngFor=' let option of questionsCommunity[1].options; let i = index'>
            <button type='button' class='btn btn-question' (click)='startQuestion(i)'>
              {{ option.optionText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class='modal modal-question' [ngClass]='{active: isStart3}' tabindex='-1' *ngIf='!isLoading'>
  <div class='modal-dialog'>
    <div class='modal-content border-0'>
      <div class='row justify-content-between button-header'>
        <button type='button' class='btn-back' aria-label='Back' (click)='back()'><</button>
        <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'
                (click)='activeModal.close("Dismissed modal")'><img style='margin: -20px;'
                                                                    src='/assets/icons/communities/button-close.svg'
                                                                    alt=''>
        </button>
      </div>
      <div class='row justify-content-between progress-and-text'>
        <div class='progress m-auto' role='progressbar' aria-label='Basic example' aria-valuenow='60' aria-valuemin='0'
              aria-valuemax='100'>
          <div class='progress-bar' style='width: 60%'></div>
        </div>
        <p class='text-stage m-auto'>3/5</p>
      </div>
      <div class='modal-body border-0' style='margin-bottom: 26px'>
        <p class='text-question'>{{questionsCommunity[2].questionText}}</p>
        <p class='text-subtitle' style='margin-bottom: 32px'>Puedes elegir más de una opción.</p>
        <div class='channel'>
          <div class='custom-control custom-checkbox' *ngFor='let option of questionsCommunity[2].options; let i = index'>
            <input type='checkbox' class='custom-control-input' id='{{i}}' (click)='checkInput(i)'>
            <label class='custom-control-label' for='{{i}}'>{{ option.optionText }}</label>
          </div>
        </div>
      </div>
      <div class='modal-footer modal-footer-step-3 border-0'>
        <button type='button' class='btn button-continue' [ngClass]='{ active: activeButton }'
                (click)='startQuestion(0)'>Continuar
        </button>
      </div>
    </div>
  </div>
</div>
<div class='modal modal-question' [ngClass]='{active: isStart4}' tabindex='-1' *ngIf='!isLoading'>
  <div class='modal-dialog'>
    <div class='modal-content border-0'>
      <div class='row justify-content-between button-header'>
        <button type='button' class='btn-back' aria-label='Back' (click)='back()'><</button>
        <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'
                (click)='activeModal.close("Dismissed modal")'><img style='margin: -20px;'
                                                                    src='/assets/icons/communities/button-close.svg'
                                                                    alt=''>
        </button>
      </div>
      <div class='row justify-content-between progress-and-text'>
        <div class='progress m-auto' role='progressbar' aria-label='Basic example' aria-valuenow='80' aria-valuemin='0'
              aria-valuemax='100'>
          <div class='progress-bar' style='width: 80%'></div>
        </div>
        <p class='text-stage m-auto'>4/5</p>
      </div>
      <div class='modal-header border-0 flex-column'>
        <img class='img-fluid m-auto' src='/assets/icons/communities/buildings.svg' alt=''>
      </div>
      <div class='modal-body border-0'>
        <p class='text-question'>{{questionsCommunity[3].questionText}}</p>
        <div class='row buttons-question'>
          <div class='col-12 col-sm-12 col-md-6' *ngFor=' let option of questionsCommunity[3].options; let i = index'>
            <button type='button' class='btn btn-question' (click)='startQuestion(i)'>
              {{ option.optionText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class='modal modal-question' [ngClass]='{active: isStart5}' tabindex='-1' *ngIf='!isLoading'>
  <div class='modal-dialog'>
    <div class='modal-content border-0'>
      <div class='row justify-content-between button-header'>
        <button type='button' class='btn-back' aria-label='Back' (click)='back()'><</button>
        <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'
                (click)='activeModal.close("Dismissed modal")'><img style='margin: -20px;'
                                                                    src='/assets/icons/communities/button-close.svg'
                                                                    alt=''>
        </button>
      </div>
      <div class='row justify-content-between progress-and-text-finish'>
        <div class='progress m-auto' role='progressbar' aria-label='Basic example' aria-valuenow='100' aria-valuemin='0'
              aria-valuemax='100'>
          <div class='progress-bar' style='width: 100%'></div>
        </div>
        <p class='text-stage m-auto'>5/5</p>
      </div>
      <div class='modal-body border-0'>
        <p class='text-question text-question-finish'>{{questionsCommunity[4].questionText}}</p>
        <p class='text-subtitle'>Puedes elegir más de una opción.</p>
        <div class='row m-0 justify-content-center'>
          <div class='m-1 m-sm-1 m-md-1 m-lg-1' *ngFor='let option of questionsCommunity[4].options; let i = index'>
            <button type='button' class='btn btn-chip' (click)='checkButton(i)' [ngClass]='{ active : isPressed[i]}'>
              {{ option.optionText }}
            </button>
          </div>
        </div>
      </div>
      <div class='modal-footer modal-footer-step-5 border-0'>
        <button type='button' class='btn button-continue' [ngClass]='{ active: activeButtonFinish }'
                (click)='startQuestion(0)'>{{ titleButtonFinish }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class='modal modal-question' [ngClass]='{active: isFinish}' tabindex='-1' *ngIf='!isLoading'>
  <div class='modal-dialog'>
    <div class='modal-content border-0'>
      <button type='button' class='btn-close' style='margin: 10px 16px auto auto;' data-bs-dismiss='modal'
              aria-label='Close'
              (click)='activeModal.close("Closed modal")'><img style='margin: -20px;'
                                                                src='/assets/icons/communities/button-close.svg' alt=''>
      </button>
      <div class='modal-header border-0'>
        <img class='img-fluid m-auto' src='/assets/icons/communities/check-circle-outline.svg' alt='' />
      </div>
      <div class='modal-body border-0' style="display: flex; justify-content: center; align-items: center; flex-wrap: wrap;">
        <p class='text-question justify-content-center'>¡Gracias por dejarnos conocerte!</p>
        <p class='text-title-finish' style='color: #009530; font-weight: 700; margin: auto 50px 4px;'>{{ messageLevel }}</p>
        <p class='text-title-finish-support' style='color: #151D18; font-weight: 400'>{{ messageSupport }}</p>
      </div>
      <div class='modal-footer border-0'  style="margin-bottom: 71px;">
        <button type='button' class='btn button-finish'
                (click)='activeModal.close("Closed modal")'>Entendido
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid p-0 d-flex flex-column">
  <div class="card col-12">
    <div class="card-body">
      <div class="row my-history-header-edit">
        <h2 class="title d-flex">
          <svg-icon src="assets/icons/intranet/flag.svg" [applyClass]="true" class="custom-icon"></svg-icon>
          <span>Mi historia</span>
        </h2>
        <div class="my-history-edit">
          <a (click)="editProgressStory()" href="" [routerLink]="">
            Editar
            <img src="assets/icons/intranet/edit.png" alt="edit-icon" />
          </a>
        </div>
      </div>
      <div class="row history-box">
        <div class="m-3">
          <div class="history-title mb-4">Cuéntanos cómo y por qué iniciaste.</div>
          <textarea
            #progressInput
            [disabled]="progressInputIsDisabled"
            (blur)="progressInputLeave()"
            rows="4"
            cols="180"
            class="form-control progress-story-input"
            placeholder="Historia breve de emprendimiento (Máx. 200 caracteres)"
            [(ngModel)]="progressStoryText"
            maxlength="200"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="card col-12">
    <div class="card-body">
      <div class="row">
        <h2 class="title">
          <svg-icon src="assets/icons/intranet/flash.svg" [applyClass]="true" class="custom-icon"></svg-icon>
          <span>Mis logros</span>
        </h2>
        <p class="title-description d-none d-lg-block">
          Cada vez que cumplas una meta y logres completar un servicios apareceran unas insignias que te premiaran con
          puntos por seguir usando Yevo y beneficiarte de todos nuestros servicios y promociones. Los puntos te servirán
          para canjear premios en beneficio de tu negocio y salud financiera.
        </p>
      </div>
      <!-- ROW TIMELINE -->
      <div class="row mt-5">
        <div *ngFor="let achievement of achivements" class="col-lg-2 ml-3 col-12 d-none d-lg-block">
          <div class="row custom {{ achievement?.reached ? achievement?.style : 'default' }}">
            <div class="col-12 d-flex justify-content-center">
              <div class="medal">
                <img
                  [ngClass]="{ 'image-default': !achievement?.reached }"
                  src="./assets/icons/intranet/{{ achievement?.icon }}.png"
                  alt="achievement-icon"
                />
              </div>
            </div>
            <div class="col-12 text-center mt-3">
              <label for="level-title" class="level">{{ achievement?.title }}</label>
              <label for="description">{{ achievement?.description }}</label>
            </div>
          </div>
        </div>
        <div class="col-12 d-lg-none d-block">
          <section class="custom-timeline">
            <div class="row no-gutters" *ngFor="let achievement of achivements; let i = index">
              <div class="col" [class.d-none]="i % 2 === 0"></div>
              <div
                class="col custom {{ achievement?.reached ? achievement?.style : 'default' }} {{
                  i % 2 === 0
                    ? achievement?.reached
                      ? achievement?.style + '-line-right'
                      : 'default-line-right'
                    : achievement?.reached
                    ? achievement?.style + '-line-left'
                    : 'default-line-left'
                }}"
              >
                <span class="conect-line"></span>
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <div class="medal">
                      <img
                        [ngClass]="{ 'image-default': !achievement?.reached }"
                        src="./assets/icons/intranet/{{ achievement?.icon }}.png"
                        alt="medal"
                      />
                    </div>
                  </div>
                  <div class="col-12 text-center mt-3">
                    <label for="level-title" class="level">{{ achievement?.title }}</label>
                    <br />
                    <label for="description">{{ achievement?.description }}</label>
                  </div>
                </div>
              </div>
              <div class="col" [class.d-none]="i % 2 === 1"></div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>

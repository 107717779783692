<div class="training-card" (click)="verCourse()">
  <div class="col-12 training-card__content-img mt-3">
    <img [src]="courses.thumbnail" alt="badge" class="training-card__img" />
  </div>
  <div class="pt-3 pl-0 pr-0">
    <p class="training-card__title">
      {{ courses.title }}
    </p>
    <button class="training-card__button">Ver curso</button>
  </div>
</div>

import { CurrencyMaskConfig } from 'ng2-currency-mask';

export const SEIDOR_COURSE_BASE_URL: string = 'https://educacion.yevo.pe/course/view.php?id=';

export enum SEIDOR_COURSES_URL {
  ABC_DIGITAL_SALES_1_COURSE = '12',
  ABC_DIGITAL_SALES_2_COURSE = '13',
  BUILD_YOUR_BUDGET = '9',
  CREDIT_HISTORY_COURSE = '4',
  DIGITAL_PAYMENT_METHODS_COURSE = '14',
  FINANCE_SYSTEM_COURSE = '15',
  SAVINGS_COURSE = '8',
  COMMUNITY_COURSE = '5',
  COMMUNITY_BODEGUERO = '17',
}

export enum SEIDOR_COURSES_TITLE {
  ABC_DIGITAL_SALES_1_COURSE = 'ABC Digitalización Parte 1',
  ABC_DIGITAL_SALES_2_COURSE = 'ABC Digitalización Parte 2',
  BUILD_YOUR_BUDGET = 'Construye tu presupuesto',
  CREDIT_HISTORY_COURSE = 'Historia crediticia',
  DIGITAL_PAYMENT_METHODS_COURSE = 'Medios de pagos digitales',
  FINANCE_SYSTEM_COURSE = 'Sistema Financiero',
  SAVINGS_COURSE = 'Ahorro',
}

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'center',
  allowNegative: false,
  decimal: ',',
  precision: 0,
  prefix: 'S/ ',
  suffix: '',
  thousands: ',',
};

export const NOTIFICATION = {
  maxTitleLength : 46,
  maxDescriptionLength : 93
};
export const BANNER_VALUES = {
  urlCalculateSeed :'/efectivo-semilla/paso-1',
}

export const URL_RECOMMENDED = {
  url :'/recomienda-y-gana',
}

export const URL_VALUE = {
  urlHomeInternal :'/nuevo-intranet/dashboard',
  urlHomeExternal :'/'
}

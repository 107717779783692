import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ModalContainerComponent } from '@shared/components/modal-container/modal-container.component';
import { AuthenticatedGuard } from '@shared/guards/authenticated.guard';
import { LoggedInGuard } from '@shared/guards/loggedIn.guard';
import { BlankPageComponent } from '@shared/pages/blank-page/blank-page.component';
import { LayoutComponent } from './layout/layout.component';
import { RemittancesLandingComponent } from './remittances/remittances-landing/remittances-landing.component';
import { RecommendedLandingComponent } from './recommended/recommended-landing/recommended-landing.component';
import { PagePointsProgramComponent } from './points-program/pages/page-points-program/page-points-program.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'financiamiento',
        loadChildren: () => import('./financing/financing.module').then((m) => m.FinancingModule),
      },
      {
        path: 'efectivo-semilla/:subroute',
        redirectTo: 'financiamiento',
        pathMatch: 'full',
      },
      {
        path: 'prestamo-efectivo-semilla',
        loadChildren: () => import('./seed-cash/seed-cash.module').then((m) => m.SeedCashModule),
      },
      {
        path: 'cuenta-ahorros',
        loadChildren: () => import('./savings-account/savings-account.module').then((m) => m.SavingsAccountModule),
        canLoad: [AuthenticatedGuard],
      },
      {
        path: 'faq',
        loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'contacto',
        loadChildren: () => import('./contact-us/contact-us.module').then((m) => m.ContactUsModule),
      },
      {
        path: 'terminos-y-condiciones',
        loadChildren: () => import('./terms-conditions/terms-conditions.module').then((m) => m.TermsConditionsModule),
      },
      {
        path: 'cookie',
        loadChildren: () => import('./cookie/cookie.module').then((m) => m.CookieModule),
      },
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
        data: {
          title: 'Mibanco Lab - Haz crecer tu negocio',
          description:
            'Hemos creado un espacio con servicios que te ayudarán a impulsar el crecimiento de tu negocio. Además, podrás encontrar miles de productos y servicios cerca de ti.',
          pageName: 'Haz crecer tu negocio',
        },
      },
      {
        path: 'remesas',
        data: {
          title: 'Remesas - Mibanco Lab',
          pageName: 'Remesas',
        },
        component: RemittancesLandingComponent,
        loadChildren: () => import('./remittances/remittances-landing.module').then((m) => m.RemittancesLandingModule),
      },
    ],
  },
  {
    path: 'blank-page',
    component: BlankPageComponent,
  },
  {
    path: 'nuevo-intranet',
    canLoad: [AuthenticatedGuard],
    loadChildren: () => import('./new-intranet/new-intranet.module').then((m) => m.NewIntranetModule),
  },
  {
    path: 'registro',
    loadChildren: () => import('./register/register.module').then((m) => m.RegisterModule),
    canLoad: [LoggedInGuard],
  },
  {
    path: 'ingresar',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'recuperar-password',
    loadChildren: () => import('./recover-password/recover-password.module').then((m) => m.RecoverPasswordModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'crear-password',
    loadChildren: () => import('./create-password/create-password.module').then((m) => m.CreatePasswordModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'verificacion-de-activacion',
    loadChildren: () => import('./account-activation/account-activation.module').then((m) => m.AccountActivationModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'recomienda-y-gana',
    data: {
      title: 'Referidos - Mibanco Lab',
      pageName: 'Referidos',
    },
    component: RecommendedLandingComponent,
    loadChildren: () => import('./recommended/recommended.module').then((m) => m.RecommendedModule),
  },
  {
    path: 'programa-de-puntos',
    data: {
      title: 'Programa de puntos',
      pageName: 'Canjea tus puntos',
    },
    component: PagePointsProgramComponent,
    loadChildren: () => import('./points-program/points-program.module').then((m) => m.PointsProgramModule),
  },
  {
    path: 'tratamiento-datos-personales',
    outlet: 'modal',
    component: ModalContainerComponent,
    data: {
      showBackButton: true,
      backButtonText: 'Entendido',
      type: 'personal-info',
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./personal-info-modal/personal-info-modal.module').then((m) => m.PersonalInfoModalModule),
      },
    ],
  },
  {
    path: 'compartir-datos-personales',
    outlet: 'modal',
    component: ModalContainerComponent,
    data: {
      showBackButton: true,
      backButtonText: 'Entendido',
      type: 'share-personal-info',
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./share-personal-info-modal/share-personal-info-modal.module').then(
            (m) => m.SharePersonalInfoModalModule
          ),
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/',
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      onSameUrlNavigation: 'ignore',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<!-- TOOLTIP - DESKTOP -->
<div
  class="d-none d-lg-block disclaimer-icon"
  [ngbTooltip]="tipContent"
  [ngClass]="{ 'birthday-icon': birthdayInput, 'to-right': moveToRight }"
>
  <img [src]="'/assets/icons/seed-cash/info-tip.svg'" alt="tip-tooltip-icon" />
</div>

<!-- MODAL BUTTON - MOBILE -->
<div
  class="d-block d-lg-none disclaimer-icon"
  [ngClass]="{ 'birthday-icon': birthdayInput, 'disclaimer-icon-mobile': mobile }"
>
  <a href="" [routerLink]="" (click)="openModal(modalMobile)">
    <img [src]="'/assets/icons/seed-cash/info-tip.svg'" alt="tip-modal-icon" />
  </a>
</div>

<!-- TEMPLATE DESKTOP AND MODAL MOBILE-->
<ng-template #tipContent>
  <div class="tip-container d-flex">
    <br />
    <div class="tip-message mt-1 mx-2">
      <h2 class="tip-title">{{ tip.title }}</h2>
      <p class="tip-description">{{ tip.description }}</p>
    </div>
    <div class="tip-image mt-0">
      <img [src]="'/assets/img/seed-cash/tips/' + tip.image + '.png'" width="212" height="135" alt="tip-image" />
    </div>
  </div>
</ng-template>

<ng-template #modalMobile let-modal class="modal-content">
  <div class="modal-header pt-4 pb-3">
    <h2 class="mb-0">{{ tip.title }}</h2>
    <button type="button" class="close" (click)="closeModal()">
      <img class="images__clouds" src="/assets/img/close-small.svg" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body pt-0 pb-5">
    <div class="modal-description mb-3">{{ tip.description }}</div>
    <div class="d-flex justify-content-center">
      <img class="img-fluid img-tooltip" [src]="'/assets/img/seed-cash/tips/' + tip.image + '.png'" alt="tip-image" />
    </div>
  </div>
</ng-template>

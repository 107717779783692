import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONST_COMMUNITIES_CODE } from '@shared/constants/communities.constants';
import { ITabItem } from '@shared/interfaces';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-educational-content-section',
  templateUrl: './educational-content-section.component.html',
  styleUrls: ['./educational-content-section.component.scss'],
})
export class EducationalContentSectionComponent implements OnInit {
  @Input() items: ITabItem[] = [];
  @Input() selectedItem!: ITabItem;
  @Output() selectedItemEmit = new EventEmitter<any>();
  private communityId!: number;

  constructor(
    public dataLayerService: DataLayerService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const parentRoute = this.activatedRoute.parent?.routeConfig?.path;
    if (parentRoute) {
      this.communityId =
        parentRoute === 'emprende-mujer'
          ? CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID
          : CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_ID;
    }
  }

  selectTab(tab: ITabItem): void {
    if (this.communityId === CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID) {
      this.dataLayerService.virtualEventEM(`Seleccionar: ${tab.label}`, 13);
    } else {
      this.dataLayerService.virtualEventBodegueros(`Seleccionar: ${tab.label}`, 12);
    }
    this.selectedItem = tab;
    this.setTabActive(this.selectedItem);
    this.selectedItemEmit.emit(this.selectedItem);
  }

  resetTabs() {
    this.selectedItem = this.items[0];
    this.setTabActive(this.selectedItem);
  }

  setTabActive(activeTab: ITabItem): void {
    this.items.forEach((x) => {
      x.active = x.code == activeTab.code;
    });
  }
}

<div class="container-fluid form-container py-2" [ngClass]="{ 'without-layaout': hideLayout }">
  <form [formGroup]="loanSimulatorAdditionalForm" click-stop-propagation>
    <div class="col-12">
      <div class="row mb-1">
        <div class="col-sm-12 p-0">
          <label class="control-label">Monto a solicitar* S/</label>
          <div class="input-group flex-nowrap mb-1">
            <span class="input-group-text span-icon pr-1" id="addon-wrapping">S/</span>
            <input
              type="number"
              class="form-control h-100 pl-0 input-border"
              placeholder=""
              maxlength="50"
              formControlName="amount"
              [attr.disabled]="true"
              #inputAmount
            />
          </div>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-sm-12 p-0">
          <label class="control-label">Plazo de pago de préstamo (en meses)*</label>
          <div class="input-group flex-nowrap mb-1">
            <input
              type="text"
              class="form-control h-100"
              formControlName="paymentDeadline"
              [attr.disabled]="true"
              #inputAmount
            />
          </div>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-sm-12 p-0">
          <label class="control-label">Cuota máxima aproximada</label>
          <div class="input-group flex-nowrap mb-1">
            <span class="input-group-text span-icon pr-1" id="addon-wrapping">S/</span>
            <input
              type="number"
              class="form-control h-100 input-border pl-0"
              formControlName="maxPaymentRate"
              [attr.disabled]="true"
              #inputAmount
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CATEGORIESLIST } from '@shared/data/foro/categories-data.json';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserProfile } from '@shared/models/UserProfileModel';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { Category, ForumRequest } from '@shared/interfaces/frontend/communities';
import { Subscription } from 'rxjs';
import { CommunityService } from '@shared/services/community.service';
import { ModalQuestionMessageComponent } from '../modal-question-message/modal-question-message.component';

@Component({
  selector: 'yevo-modal-question',
  templateUrl: './modal-question.component.html',
  styleUrls: ['./modal-question.component.scss'],
})
export class ModalQuestionComponent implements OnInit, OnDestroy {
  modalBoxActive = false;
  modalTransitionActive = false;
  category!: Category[];
  closeResult = '';
  user!: UserProfile;
  forumRequest: ForumRequest = {
    userId: 0,
    question: '',
    userName: '',
    questionDetail: '',
    communityId: 0,
  };
  question = '';
  questionDetail = '';
  private subscription = new Subscription();
  @Input()
  communityId = 0;

  constructor(
    private activeModal: NgbActiveModal,
    private localStorageService: LocalStorageService,
    private communityService: CommunityService,
    private modalService: NgbModal
  ) {
    this.category = CATEGORIESLIST;
  }

  ngOnInit(): void {
    this.deactivateScroll();
    this.modalBoxActive = true;
    this.modalTransitionActive = true;
  }

  closePopup(): void {
    this.modalTransitionActive = false;
    this.modalBoxActive = false;
    this.question = '';
    this.questionDetail = '';
    const t = setTimeout(() => {
      clearTimeout(t);
      this.activateScroll();
      this.question = '';
    }, 400);
    this.activeModal.close('Modal Question Closed');
  }

  activateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'auto';
  }

  deactivateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'hidden';
  }

  createQuestion(): void {
    const userString = this.localStorageService.get('userProfile');
    if (userString && this.question !== '') {
      this.user = JSON.parse(userString) as UserProfile;
      this.forumRequest.userName = `${this.user.name} ${this.user.fatherLastName} ${this.user.motherLastName}`;
      this.forumRequest.question = this.question;
      this.forumRequest.userId = this.user.userId;
      this.forumRequest.questionDetail = this.questionDetail;
      this.forumRequest.communityId = this.communityId;
      this.subscription.add(
        this.communityService.createQuestion(this.forumRequest).subscribe(
          (response) => {
            this.question = '';
            this.questionDetail = '';
            this.closePopup();
            const modalRef = this.modalService.open(ModalQuestionMessageComponent);
            modalRef.componentInstance.responseMessage = response;
            modalRef.result
              .then((result) => {
                console.info(result);
              })
              .catch((error) => {
                console.error(error);
              });
          },
          (error) => {
            console.error(error);
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

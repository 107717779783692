<div
  class="container mt-0 w-100 justify-content-center mb-5 message-digital-aproved-main"
  [ngClass]="{ 'mt-2': isLogged }"
>
  <div class="row">
    <div class="col-12 breadcrumb mt-3 mb-4 mb-lg-0">
      <yevo-breadcrumb [breadcrumbs]="breadcrumbs"></yevo-breadcrumb>
    </div>
  </div>

  <div class="col-12 text-center message-digital-aproved-container">
    <img src="assets/img/financing/person-aproved.png" alt="person" class="message-digital-aproved-img mt-3 mb-2" />
    <h2 class="message-digital-aproved-title">
      {{ username }}, Tu solicitud de <br class="message-digital-aproved-space" />
      préstamo ha sido enviada
    </h2>
    <p class="message-digital-aproved-subtitle mb-5">Tu asesor le dará seguimiento a tu <br />solicitud</p>
    <yevo-button-animated
      [label]="'Ir al inicio'"
      (buttonClick)="goToHome()"
      class="w-100"
      [icon]="'/assets/icons/home-white.svg'"
    ></yevo-button-animated>
  </div>
</div>

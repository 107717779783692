<div class='modal' [ngClass]='{ activeBox: modalBoxActive, activeTransitions: modalTransitionActive }'>
  <div class='modal-layer' (click)='closePopup()'></div>
  <div class='modal-content'>
    <div class='row-cont'>
      <div class='col-md-3 text-col'>
        <div class='row'>
          <div class='col'>
            <div class='title'>ESCRIBE TU RESPUESTA A:</div>
            <div class='question'>{{ question }}</div>
            <div class='user'>{{ userName }}</div>
          </div>
          <div class='col-2 hide-X1'>
            <a [routerLink]='' (click)='closePopup()'> <img src='/assets/icons/communities/close.svg' alt='' /></a>
          </div>
        </div>
      </div>
      <div class='col-md'>

        <textarea maxlength='5000' name='answerPopup' [(ngModel)]='answerPopup' class='textarea'
                  placeholder='Escribe aquí tu respuesta'></textarea>
        <div class='row mt-2'>
          <div class='col-sm-12 col-md-12 d-flex m-auto'>
            <button type='button' (click)='closePopup()' class='button-1-modal'>Cancelar</button>
            <button type='button' (click)='createAnswer()' class='button-2-modal'>Responder</button>
          </div>
        </div>

      </div>
      <div class='col-md-1 hide-X2'>
        <a [routerLink]='' (click)='closePopup()'> <img src='/assets/icons/communities/close.svg' alt='' /></a>
      </div>
    </div>
  </div>
</div>

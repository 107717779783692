import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { CommunityService } from '@shared/services/community.service';
import { Categories, Training, UserLevel, WebinarViewedResponse } from '@shared/interfaces/frontend/communities';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperComponent } from 'swiper/angular';
import { UserProfile } from '@shared/models/UserProfileModel';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { DataLayerService } from '@shared/services/data-layer.service';
import { CONST_COMMUNITIES_CODE } from '@shared/constants/communities.constants';
import Section from '@shared/data/community/community-section.data.json';

@Component({
  selector: 'yc-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.scss'],
})
export class YcTrainingsComponent implements OnInit, OnDestroy, OnChanges {
  config: SwiperOptions = {
    direction: 'horizontal',
    freeMode: true,
    slidesPerView: 1.1,
    spaceBetween: 16,
    navigation: {
      nextEl: '.swiper-button-training-next',
      prevEl: '.swiper-button-training-prev',
    },
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      334: {
        slidesPerView: 1.1,
        spaceBetween: -16,
      },
      380: {
        slidesPerView: 1.2,
        spaceBetween: -16,
      },
      450: {
        slidesPerView: 1.45,
        spaceBetween: -16,
      },
      550: {
        slidesPerView: 1.65,
        spaceBetween: -16,
      },
      600: {
        slidesPerView: 1.8,
        spaceBetween: -16,
      },
      690: {
        slidesPerView: 1.95,
        spaceBetween: -16,
      },
      768: {
        slidesPerView: 2.05,
        spaceBetween: -16,
      },
      850: {
        slidesPerView: 2.3,
        spaceBetween: -16,
      },
      950: {
        slidesPerView: 2.6,
        spaceBetween: -100,
      },
      1050: {
        slidesPerView: 2.9,
        spaceBetween: -100,
      },
      1150: {
        slidesPerView: 3.1,
        spaceBetween: -100,
      },
      1250: {
        slidesPerView: 3.4,
        spaceBetween: -100,
      },
      1350: {
        slidesPerView: 3.9,
        spaceBetween: -100,
      },
    },
  };

  trainings: Training[] = [];
  trainingsShow: Training[] = [];
  subscription: Subscription = new Subscription();
  @Input()
  communityId = 0;
  @Input()
  userLevel: UserLevel = {
    idLevel: 0,
    userId: 0,
    flagStatus: 'N',
  };
  slickConfigTraining = {
    arrows: false,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    swipe: true,
    mobileFirst: true,
    swipeToSlide: true,
  };
  categories: Categories[] = [];
  isPressed: boolean[] = [];
  buttonAll: Categories = {
    id: 0,
    name: 'Ver todo',
    quantity: 4,
    communityId: 0,
    categoryId: 0,
  };
  buttonResents: Categories = {
    id: 0,
    name: 'Recientes',
    quantity: 4,
    communityId: 0,
    categoryId: 0,
  };
  @ViewChild('swiperTraining', { static: false }) swiperTraining?: SwiperComponent;
  @ViewChild('slickCarouselTraining', { static: false }) slickCarouselTraining?: SlickCarouselComponent;
  forMe: Categories = {
    id: 0,
    name: 'Para Mí',
    quantity: 4,
    communityId: 0,
    categoryId: 0,
  };
  user!: UserProfile;
  @Input()
  source = '';
  sourceHome = 'home';
  isMaxLevel: boolean = false;
  @Input() webinarViewed: WebinarViewedResponse[] = [];
  @Input() showForMe: boolean = true;
  @Input() showTitleCategories: boolean = true;
  @Input() nameSection:string='';
  nameSectionInternal='';
  initSectionCommunity!:boolean;
  constructor(
    private community: CommunityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.nameSectionInternal=this.nameSection;
    this.isMaxLevel =
      (this.communityId === 1 && CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_MAX_LEVEL === this.userLevel.idLevel) ||
      (this.communityId === 2 && CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_MAX_LEVEL === this.userLevel.idLevel);
    this.getUserLevel();
    this.initSectionCommunity=false;
  }
  changesCommunityId(changes: SimpleChanges) {
    this.userLevel.idLevel = changes.communityId.currentValue;
    this.toggleButton(0);
  }
  changesUserLevel(changes: SimpleChanges) {
    if (
      changes.userLevel.previousValue.idLevel !== changes.userLevel.currentValue.idLevel &&
      changes.userLevel.currentValue.flagStatus === 'A'
    ) {
      this.getWebinarLevel();
    }
    this.isMaxLevel =
      (this.communityId === 1 && CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_MAX_LEVEL === this.userLevel.idLevel) ||
      (this.communityId === 2 && CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_MAX_LEVEL === this.userLevel.idLevel);
    if (this.isMaxLevel) {
      this.getUserLevel();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.userLevel.idLevel > 0 && this.userLevel.flagStatus === 'A') {
      if (this.categories[0] && this.categories[0].name !== 'Para Mí') {
        const categoriesAux = JSON.parse(JSON.stringify(this.categories));
        if (this.showForMe) {
          this.categories = [this.forMe].concat(categoriesAux);
        } else {
          this.categories = [].concat(categoriesAux);
          this.toggleButton(0);
        }
        this.isPressed = new Array(this.categories.length).fill(false);
        this.isPressed[0] = true;
        this.getWebinarLevel();
      }
    }
    if (changes.communityId) {
      this.changesCommunityId(changes);
    }
    if (changes.userLevel) {
      this.changesUserLevel(changes);
    }
    setTimeout(() => {
      this.slickCarouselTraining?.unslick();
      this.slickCarouselTraining?.initSlick();
    }, 4000);
  }

  private getWebinars(): void {
    this.subscription.add(
      this.community.getWebinarsByCommunityId(this.communityId).subscribe(
        (response) => {
          const idsIncluded: string[] = [];
          this.trainings = response.filter((resp) => {
            if (idsIncluded.includes(resp.id)) {
              return false;
            }
            idsIncluded.push(resp.id);
            return true;
          });
          this.trainings.sort((a, b) => {
            const dateA = new Date(a.creationDate).getTime();
            const dateB = new Date(b.creationDate).getTime();
            return dateA - dateB;
          });
          this.trainings.reverse();
          this.trainingsShow = this.trainings;
          this.trainingsShow = this.trainingsShow
            .map((t) => {
              const idsWebinarViewed = this.webinarViewed.map((wv) => wv.webinarId);
              t.viewed = idsWebinarViewed.includes(Number(t.id));
              return t;
            })
            .sort((a, b) => {
              if (a.viewed === b.viewed) return 0;
              if (a.viewed) return 1;
              return -1;
            });
          this.trainingsShow = this.trainingsShow.slice(0, 10);
          this.swiperTraining?.swiperRef?.slideTo(0);
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  goToSeeMoreWebinars() {
    this.community.sendCommnunityId(this.communityId);
    return this.source === 'emprende-mujer'
      ? this.router.navigate(['nuevo-intranet/comunidades/emprende-mujer', 'webinar'], {
          state: { communityId: this.communityId },
        })
      : this.router.navigate(['nuevo-intranet/comunidades/bodegueros', 'webinar'], {
          state: { communityId: this.communityId },
        });
  }

  private getCategory(): void {
    this.categories = [];
    this.isPressed = [];
    if (this.userLevel.idLevel > 0 && this.userLevel.flagStatus === 'A' && !this.isMaxLevel) {
      if (this.showForMe) {
        this.categories.push(this.forMe);
      }
    }
    this.categories.push(this.buttonResents);
    this.subscription.add(
      this.community.getWebinarsCategoryStatistics(this.communityId).subscribe((response) => {
        let qtyAdded = 0;
        response.forEach((category) => {
          if (category.quantity >= 4 && qtyAdded < 5) {
            this.categories.push(category);
            qtyAdded += 1;
          }
        });
        this.buttonAll.communityId = this.communityId;
        this.categories.push(this.buttonAll);
        this.isPressed = new Array(this.categories.length).fill(false);
        this.isPressed[0] = true;
        let categoriesAux: string[] = [];
        this.categories = this.categories.filter((category) => {
          if (categoriesAux.includes(category.name)) {
            return false;
          }
          categoriesAux.push(category.name);
          return true;
        });
        this.toggleButton(0);
      })
    );
  }
  toggleTodos() {
    this.goToSeeMoreWebinars().catch((err) => console.error('err: ', err));
  }
  toggleRecientes() {
    this.getWebinars();
  }
  toggleParaMi() {
    this.getWebinarLevel();
  }
  toogleDefault(index: number) {
    this.subscription.add(
      this.community.getWebinarByCommunityAndCategory(this.communityId, this.categories[index].categoryId).subscribe(
        (response) => {
          const idsIncluded: string[] = [];
          this.trainingsShow = response.filter((resp) => {
            if (idsIncluded.includes(resp.id)) {
              return false;
            }
            idsIncluded.push(resp.id);
            return true;
          });
          this.trainingsShow = this.trainingsShow
            .map((t) => {
              const idsWebinarViewed = this.webinarViewed.map((wv) => wv.webinarId);
              t.viewed = idsWebinarViewed.includes(Number(t.id));
              return t;
            })
            .sort((a, b) => {
              if (a.viewed === b.viewed) return 0;
              if (a.viewed) return 1;
              return -1;
            });
          this.swiperTraining?.swiperRef?.slideTo(0);
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }
  toggleButton(index: number): void {
    for (let i = 0; i < this.isPressed.length; i++) {
      this.isPressed[i] = i === index;
    }
    const communityName = this.source === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
    if(this.initSectionCommunity)this.validDataLayerInternal(communityName, this.categories[index].name, 'Filtrar por');
    this.initSectionCommunity=true;
    switch (this.categories[index].name) {
      case 'Ver todo':
        this.toggleTodos();
        break;
      case 'Recientes':
        this.toggleRecientes();
        break;
      case 'Para Mí':
        this.toggleParaMi();
        break;

      default:
        this.toogleDefault(index);
        break;
    }
  }

  private getUserLevel(): void {
    const user = this.localStorageService.get('userProfile');
    if (user) {
      this.user = JSON.parse(user) as UserProfile;
      this.subscription.add(
        this.community.getLevelUserByCommunityId(this.user.userId, this.communityId).subscribe(
          (response) => {
            this.userLevel = response;
            if (this.userLevel.idLevel > 0 && this.userLevel.flagStatus === 'A' && !this.isMaxLevel) {
              this.getWebinarLevel();
            } else {
              this.getWebinars();
            }
            this.getCategory();
          },
          (error) => {
            console.error(error);
          }
        )
      );
    }
  }
  private getWebinarLevel(): void {
    this.subscription.add(
      this.community.getWebinarByLevelAndCommunityId(this.userLevel.idLevel, this.communityId).subscribe(
        (response) => {
          response.sort((a, b) => {
            const dateA = new Date(a.creationDate).getTime();
            const dateB = new Date(b.creationDate).getTime();
            return dateB - dateA;
          });
          this.trainings = response;
          this.trainingsShow = this.trainings;
          this.trainingsShow = this.trainingsShow
            .map((t) => {
              const idsWebinarViewed = this.webinarViewed.map((wv) => wv.webinarId);
              t.viewed = idsWebinarViewed.includes(Number(t.id));
              return t;
            })
            .sort((a, b) => {
              if (a.viewed === b.viewed) return 0;
              if (a.viewed) return 1;
              return -1;
            });

          this.trainingsShow = this.trainingsShow.slice(0, 10);
          this.swiperTraining?.swiperRef?.slideTo(0);
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  seeMore(): void {
    const community = this.source === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
    this.validDataLayerInternal(community,'Todos los webinars', 'Ver webinar')
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
validDataLayerInternal(communityName:string,categoryName:string, label:string){
  if(this.nameSection==='comunidad'){
    this.dataLayerService.clickCommunityInternal(Section.CommunityWebinar.code,label, categoryName);
    return;
  }
  const codeItem = (communityName === 'Emprende Mujer')?Section.WomenVideoAndWebinar.code:Section.WinemakersVideosAndWebinar.code;
  this.dataLayerService.clickCommunity(communityName, categoryName, label, codeItem);
}
}

import { DirectoryGeneralInfo } from '@shared/models/DirectoryModel';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'yevo-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
})
export class GeneralInfoComponent implements OnInit {
  @Input() generalInfo?: DirectoryGeneralInfo;
  @Input() errorCode?: string;
  @Input() errorMsgType?: string;
  @Output() stateAfterFieldBlur = new EventEmitter();
  frmGeneralInfo!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
    this.subscribeFormChanges();
    this.loadData();
  }

  showError(field: string): boolean | undefined {
    const control = this.frmGeneralInfo.get(field);
    return control?.touched && !control.valid;
  }

  private subscribeFormChanges() {
    this.frmGeneralInfo.valueChanges.subscribe((formValue) => {
      this.stateAfterFieldBlur.emit(formValue);
    });
  }

  private buildForm(): void {
    this.frmGeneralInfo = this.fb.group(
      {
        businessName: [
          null,
          [
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern(/^((?!\s{2,}).)*$/),
          ],
        ],
        ruc: [null, [Validators.pattern('[0-9]{11}')]],
        description: [null, [Validators.required, Validators.maxLength(350)]],
      },
      { updateOn: 'blur' }
    );
  }

  private loadData(): void {
    if (this.generalInfo) {
      this.frmGeneralInfo.patchValue(this.generalInfo);
    }
  }

  avoidEmpty(event: any, key: string) {
    if (this.frmGeneralInfo.controls[key].value === ' ') {
      this.frmGeneralInfo.controls[key].patchValue(null);
      this.frmGeneralInfo.controls[key].markAsTouched();
    }
  }
}

import { Image } from './ImageModel';

export class DirectoryGeneralInfo {
  directoryId?: number;
  businessName!: string;
  ruc?: string;
  description?: string;
  address?: string;
  productDescription?: string;
  homeDelivery!: boolean;
  pickUpSalePoint!: boolean;
  categories?: Category[];
  paymentMethods?: PaymentMethod[];
  slug?: string;
  published?: boolean;
}

export class Category {
  id?: number;
  value!: number;
  name?: string;
}

export class PaymentMethod {
  id?: number;
  value!: number;
  name?: string;
  otherDescription?: string;
}

export class LocationInformation {
  businessName!: string;
  businessHours!: string;
  ubigeo!: string;
}

export class ContactInformation {
  link!: string;
  principal?: boolean;
  type!: number;
  virtual?: boolean;
}

export class Directory extends DirectoryGeneralInfo {
  locationInformation?: LocationInformation[];
  contactInformation?: ContactInformation[];
  creationDate?: Date;
}

export class ExistingDirectoryResolved {
  directoryData!: Directory;
  images!: Image[];
}

export class GetDirectory {
  offset: number = 0;
  limit: number = 8;
  address: string = '';
  businessName: string = '';
  description: string = '';
  orderBy: string = '';
  ubigeo: string = '';
  virtualStore: boolean = false;
  categories: string = '';
}

export class DataDirectory {
  directoryId?: number;
  businessName?: string;
  slug?: string;
  description?: string;
  productDescription?: string;
  pickUpSalePoint?: boolean;
  homeDelivery?: boolean;
  paymentValue?: string;
  address?: null;
  url?: string;
  link?: string;
  virtual?: boolean;
  totalRows?: number;
}

export class IProgressBarImage {
  id?: number;
  progress?: number;
  uploading?: boolean;
}

export class TopicViewUser {
  topicId!: number;
  source!: string;
  type!: string;
  utmMedium: string = '';
  utmCampaign: string = '';
  utmSource: string = '';
}

import { Component, Input, OnInit } from '@angular/core';
import { Banner, RewardRequest, VideoBusiness } from '@shared/interfaces/frontend/communities';
import { Router } from '@angular/router';
import { IMAGE_LIST_EM_VIDEOS } from '@shared/data/communities/banner-video-em.json';
import { IMAGE_LIST_BODEGUEROS_VIDEOS } from '@shared/data/communities/banner-video-bodegueros.json';
import { DataLayerService } from '@shared/services/data-layer.service';
import { CommunityService } from '@shared/services/community.service';
import { PERSONALIZER_VALUES } from '@shared/constants/communities.constants';

@Component({
  selector: 'yevo-video-communities',
  templateUrl: './video-communities.component.html',
  styleUrls: ['./video-communities.component.scss'],
})
export class VideoCommunitiesComponent implements OnInit {
  @Input()
  video: VideoBusiness = {
    videoTitle: '',
    videoText: '',
    id: 0,
    videoUrl: '',
    creationDate: '',
    comment: [],
    img: '',
    communityId: 0,
    viewed: false,
    source:'',
  };
  banners: Banner[] = IMAGE_LIST_BODEGUEROS_VIDEOS.concat(IMAGE_LIST_EM_VIDEOS);
  imgDefault = '';
  @Input()
  source = '';
  @Input()
  sourceClick = '';
  @Input()
  videoSecctionIntenal='';
  rewardRequest!: RewardRequest;
  constructor(private router: Router, private dataLayerService: DataLayerService,private communityService:CommunityService) {}

  ngOnInit(): void {
    const communityId = this.source === 'emprende-mujer' ? 1 : 2;
    this.banners
      .filter((e) => e.communityId === communityId)
      .forEach((item) => {
        if (item.title === this.video.videoTitle) {
          this.video.img = item.img;
        }
      });
    if (this.source === 'emprende-mujer') {
      this.imgDefault = '/assets/img/communities/Revisa_aqui_un_nuevo_video_de_mucho_valor_para_tu_negocio.png';
    } else {
      this.imgDefault = '/assets/icons/communities/training-desktop-img.png';
    }
  }

  openDetailVideo(video: VideoBusiness): Promise<boolean> {
    const { id } = video;
    const community = this.source === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
    this.validDataLayerInternal(community,video.videoTitle, 'Ver video');
    this.communityService.sendVideoCommnunity(video);
    this.sendNegativeOrPositiveReward(video);
    return this.source === 'emprende-mujer'
      ? this.router.navigate(['/nuevo-intranet/comunidades/emprende-mujer/video-detalle/id', id], {
          state: { data: video },
        })
      : this.router.navigate(['nuevo-intranet/comunidades/bodegueros/video-detalle/id', id], {
          state: { data: video },
        });
  }
  validDataLayerInternal(communityName:string,categoriaName:string, label:string){
    if(this.videoSecctionIntenal==='comunidad'){
      this.dataLayerService.clickCommunityInternal(7,label, categoriaName);
      return;
    }
    const codeItem = (communityName === 'Emprende Mujer')?13:12;
    this.dataLayerService.clickCommunity(communityName, categoriaName, label, codeItem);
  }

  sendNegativeOrPositiveReward(video: VideoBusiness): void {
    this.rewardRequest = { value: this.checkIfSourcePersonalizer(video) ? PERSONALIZER_VALUES.rewardPositiveCode : PERSONALIZER_VALUES.rewardNegativeCode };
    this.communityService.sendRewardToPersonalizer(this.rewardRequest).subscribe();
  }

  checkIfSourcePersonalizer(video: VideoBusiness): boolean {
    return video.source === PERSONALIZER_VALUES.recommendedByAzure;
  }
}

import { Component} from '@angular/core';
import { CommunityService } from '@shared/services/community.service';
import { Router } from '@angular/router';
import { NotificationMessage } from '@shared/interfaces/frontend/communities';
import { USER_PROFILE } from '@shared/constants/communities.constants';

@Component({
  selector: 'yevo-content-manager',
  templateUrl: './content-manager.component.html',
  styleUrls: ['./content-manager.component.scss']
})
export class ContentManagerComponent {

  userProfile!: any;
  isLoggedIn: boolean = false;
  formData: NotificationMessage = {
    registration_ids: [], notification: { requireInteraction: true, title: '', body: '', icon: '', image: '', tag: '', click_action: '' },
    data: {
      url: '',
      icon: ''
    }
  };


  constructor(private router: Router, private communityService: CommunityService) {
    const userLocalStorage = localStorage.getItem('userProfile');
    if (userLocalStorage) {
      this.validSession(JSON.parse(userLocalStorage));
    }
  }


  validSession(user: any) {
    if (user.roleId === USER_PROFILE.ADMIN_CONTENT_COMMUNITY) {
      this.isLoggedIn = true;
      return;
    }
    this.goStart();
  }
  goStart(): void {
    this.router.navigate(['']);
  }

  submitForm() {
    this.formData.data.url=this.formData.notification.click_action;
    this.communityService.sendNotification(this.formData).subscribe();
    this.cleanForm();
  }
  cleanForm() {
    this.formData = {
      registration_ids: [], notification: { requireInteraction: true, title: '', body: '', icon: '', image: '', tag: '', click_action: '' },
      data: {
        url: '',
        icon: ''
      }
    };
  }
}

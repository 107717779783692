<div class="container-fluid d-none d-md-block" (mouseenter)="mouseEnter()">
  <div class="row position-relative">
    <div class="w-100">
      <ul class="card dropdown-menu nav-align expand-user-menu menu-user-container" (mouseleave)="onHoverLeave()">
        <li>
          <a class="dropdown-item">
            <span class="text px-0 menu-user-name">{{ userName }}</span>
          </a>
        </li>
        <!-- IS LOGGED -->
        <li *ngIf="isLOgged" class="item-menu-user-list">
          <a
            class="dropdown-item item-menu-user"
            routerLink="/nuevo-intranet/perfil/mi-informacion"
            (click)="activeMarking('Ver mi perfil')"
            queryParamsHandling="merge"
          >
            <img src="/assets/icons/header/perfil.svg" class="menu-user-icon mr-2" alt="perfil" />
            <span class="text px-0 menu-user-title">Ver mi perfil</span>
          </a>
        </li>
        <li *ngIf="isLOgged" class="item-menu-user-list">
          <a
            class="dropdown-item item-menu-user"
            routerLink="/"
            queryParamsHandling="merge"
            routerLinkActive="active"
            (click)="closeSession()"
          >
            <img src="/assets/icons/header/out.svg" class="menu-user-icon mr-2" alt="cerrar sesión" />
            <span class="text menu-user-title pl-0">Cerrar sesión</span>
          </a>
        </li>
        <!-- IS LOGGED -->
      </ul>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12 close" (click)="closeModal()">
      <img src="assets/img/register/close.png" alt="close">
    </div>
  </div>
  <div class="row">
    <div class="col-12 img">
      <img src="assets/img/register/register.png" alt="register">
    </div>
  </div>
  <div class="row">
    <div class="title col-12">
      <h1>
        ¡Descubre esta<br> funcionalidad!
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12 description">
      <p>
        Para usar esta y muchas herramientas más, <span>regístrate</span> en Mibanco Lab y accede a los servicios que tenemos para ti.
        <br>
      </p>
      <span>¡Registro 100% gratis, seas o no cliente Mibanco!</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 btn_register">
      <button (click)="registerEvent()">
        Quiero registrarme
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 login">
      <p (click)="login()">
        Iniciar sesión
      </p>
    </div>
  </div>
</div>
import { IDropdownItem } from '@shared/interfaces/IDropdownItem';
import { Lookup, LookupOrder } from '@shared/models';

export function getDropdownItems(list: any) {
  let finalList: IDropdownItem[] = [];
  list.map((item: any) => finalList.push({ label: item.name, value: item.code }));
  return finalList;
}

export function findInAllItems(items: IDropdownItem[], searchParam: string) {
  return items.find((d) => d.label === searchParam)!;
}

export function generateDropDownItems(data: Lookup[]) {
  return data.map(
    (data) =>
      ({
        label: data.name,
        value: data.code.toString(),
      } as IDropdownItem)
  );
}

export function generateDropDownItemsOrder(data: LookupOrder[]) {
  return data.map(
    (data) =>
      ({
        label: data.name,
        value: data.code.toString(),
        order: data.order,
      } as IDropdownItem)
  );
}

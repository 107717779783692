import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TAGICON } from '@shared/data/allied/allied.data.json';
import { AuthService } from '@shared/services/auth.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { isSafariBrowser } from '@shared/utils/validators.utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yevo-allied-modal',
  templateUrl: './allied-modal.component.html',
  styleUrls: ['./allied-modal.component.scss'],
})
export class AlliedModalComponent implements OnInit {
  @Input() details: any = {};
  @Input() isBusinessDiagnosisFlow: boolean = false;

  modalBoxActive = false;
  modalTransitionActive = false;
  isLogged: boolean = false;
  tagsIcon = TAGICON;

  private subscription = new Subscription();

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private authService: AuthService,
    private router: Router,
    public dataLayerService: DataLayerService
  ) {
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLogged = true;
      }
    });
  }

  ngOnInit(): void {
    this.deactivateScroll();
    this.modalBoxActive = true;
    this.modalTransitionActive = true;
  }

  closePopup(): void {
    this.modalTransitionActive = false;
    this.modalBoxActive = false;
    const t = setTimeout(() => {
      clearTimeout(t);
      this.activateScroll();
    }, 400);
    this.activeModal.close('Modal Answer Closed');
  }

  activateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'auto';
  }

  deactivateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'hidden';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  closeModal() {
    this.activateScroll();
    this.modalService.dismissAll();
  }

  goToLink() {
    if (this.isLogged) {
      !this.isBusinessDiagnosisFlow &&
        this.dataLayerService.selectedAlliedButtons(this.details.allied, this.details.button);
      if (isSafariBrowser()) {
        window.location.href = this.details.link;
      } else {
        window.open(this.details.link, '_blank');
      }
    } else {
      this.registerEvent();
    }
  }

  download() {
    if (this.isLogged) {
      if (isSafariBrowser()) {
        window.location.href = this.details.download;
      } else {
        window.open(this.details.download, '_blank');
      }
    } else {
      this.registerEvent();
    }
  }

  registerEvent() {
    this.closeModal();
    this.router.navigate(['/registro'], { queryParamsHandling: 'merge' });
  }

  addButtonEvent() {
    if (this.isLogged && !this.isBusinessDiagnosisFlow) {
      this.dataLayerService.selectedAlliedButtons(this.details.allied, this.details.catalogue);
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownItem } from '@shared/interfaces/IDropdownItem';

@Component({
  selector: 'yevo-modal-selected',
  templateUrl: './modal-selected.component.html',
  styleUrls: ['./modal-selected.component.scss'],
})
export class ModalSelectedComponent implements OnInit {
  @Input() openInfoModal: boolean = false;
  @Input() title: string = '';
  @Input() titleModal: string = '';
  @Input() descriptionModal: string = '';
  @Input() businessFrequencyList: IDropdownItem[] = [];
  @Input() classList: string = 'col-sm-12 col-lg-6';
  @Input() placeholder: string = 'Selecciona tu opción aquí';

  @Input() invalid: boolean = false;
  @Input() items: IDropdownItem[] = [];
  @Input() itemSelected!: any | null;
  @Input() options!: IDropdownItem | null;
  @Input() showExtraValue: boolean = false;
  @Input() numberTopicOptionsShowed: number = 12;
  @Input() showInactiveTheme: boolean = false;
  @Output() itemSelectedEvent: EventEmitter<IDropdownItem> = new EventEmitter();

  @ViewChild('modalSelectedContent', { static: true }) modalSelectedContent!: TemplateRef<any>;

  modalReference!: NgbModalRef;

  selectedTopic: IDropdownItem | null = null;
  userForm!: FormGroup;

  isOpen: boolean = false;

  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  constructor(private modalService: NgbModal, private fb: FormBuilder) {}

  ngOnInit(): void {
    //DO NOTHING
  }

  open() {
    if (!this.isOpen) {
      this.modalService.open(this.modalSelectedContent, this._modalOptionsNormal);
      this.isOpen = true;
  
      setTimeout(() => {
        this.isOpen = false;
      }, 1000);
    }
  }

  public dismiss(): void {
    this.modalService.dismissAll();
  }

  public close(): void {
    this.modalService.dismissAll();
  }

  getTopicsSelected(code: string, name: string, extraValue: string): void {
    this.itemSelected = { label: name, value: code };
    let data: any = { item: { label: name, value: code } };
    if (extraValue) {
      data.item.extraValue = extraValue;
      this.itemSelected.extraValue = extraValue;
    }
    this.itemSelectedEvent.emit(data);
    this.dismiss();
  }

  public getItemLabel() {
    if (this.itemSelected) {
      if (this.itemSelected?.extraValue) return this.itemSelected?.label + this.itemSelected?.extraValue;
      return this.itemSelected?.label;
    }
    return null;
  }
}

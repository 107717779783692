<div class="modal-body mx-2" *ngIf="!withoutConnection && !reconnect">
  <div class="mb_3r">
    <button type="button" class="close" (click)="dismiss()">
      <img class="images__clouds" alt="close-small-img" src="/assets/img/close-small.svg" />
    </button>
  </div>
  <div class="center">
    <yevo-mibanco-loader></yevo-mibanco-loader>
  </div>
  <div>
    <h1 class="title">Conectando...</h1>
    <p class="description">
      Por favor no cierres la página.<br />
      <br />
      <span class="fw_600">Esto puede tardar un momento</span>
    </p>
  </div>
</div>

<div class="modal-body mx-2" *ngIf="!withoutConnection && reconnect">
  <div class="mb_3r">
    <button type="button" class="close" (click)="dismiss()">
      <img class="images__clouds" alt="close-small-img" src="/assets/img/close-small.svg" />
    </button>
  </div>
  <div class="center">
    <yevo-mibanco-loader></yevo-mibanco-loader>
  </div>
  <div>
    <h1 class="title">
      Tratando de <br />
      reconectar...
    </h1>
    <p class="description">
      Por favor no cierres la página.<br />
      <br />
      <span class="fw_600">Esto puede tardar un momento</span>
    </p>
  </div>
</div>

<div class="modal-body mx-2" *ngIf="withoutConnection">
  <div class="mb_3r">
    <button type="button" class="close" (click)="dismiss()">
      <img class="images__clouds" alt="close-small-img" src="/assets/img/close-small.svg" />
    </button>
  </div>
  <div class="center">
    <img width="144" height="120" src="/assets/img/conection.png" alt="logo" />
  </div>
  <div>
    <h1 class="title">¡Uy! Algo paso...</h1>
    <br />
    <p class="description">
      <span class="fw_600">Mibanco Lab</span>
      ha tenido un pequeño<br />
      problema. Por favor escríbenos a:<br />
      <br />
      <span class="fw_600 c_v">soporte.lab@mibanco.com.pe</span><br /><br />
      Horario de atención 9:00 am a 6:00 pm
      <br />
    </p>

    <div class="row jc_center">
      <button (click)="dismiss()" class="btn_reg" (click)="dismiss()">Volver a intentar</button>
    </div>
    <br />
  </div>
</div>

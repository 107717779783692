import { Injectable } from '@angular/core';
import { ClientModel } from '@shared/models/client.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  private personalInfoTreatment = new Subject<boolean>();
  private personalInfoShare = new Subject<boolean>();

  public userId!: number;

  defaultClientData: ClientModel = {
    name: '',
    fatherLastName: '',
    motherLastName: '',
    email: '',
    password: '',
    typeDocument: '',
    documentNumber: '',
    phone: '',
    chkPersonalData: false,
    chkTerms: false,
    shareThirdParties: false,
    source: '',
  };

  clientData: ClientModel = this.defaultClientData;

  registerStep$: BehaviorSubject<number> = new BehaviorSubject(0);

  setPersonalInfoTreatment(event: boolean): void {
    this.personalInfoTreatment.next(event);
  }

  setPersonalInfoShare(event: boolean): void {
    this.personalInfoShare.next(event);
  }

  getPersonalInfoTreatment(): Observable<boolean> {
    return this.personalInfoTreatment.asObservable();
  }

  getPersonalInfoShare(): Observable<boolean> {
    return this.personalInfoShare.asObservable();
  }
}

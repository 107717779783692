import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'yevo-register-error-modal',
  templateUrl: './register-error-modal.component.html',
  styleUrls: ['./register-error-modal.component.scss'],
})
export class RegisterErrorModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}

import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Meta, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownItem } from '@shared/interfaces/IDropdownItem';
import { CommentModel } from '@shared/models/Video.model';
import { AuthService } from '@shared/services/auth.service';
import { BusinessDiagnosisService } from '@shared/services/business-diagnosis.service';
import { SEOService } from '@shared/services/seo.service';
import { VideoProgressService } from '@shared/services/video-progress.service';
import { VideoService } from '@shared/services/video.service';
import { take } from 'rxjs/operators';
import { FeedbackModel } from 'src/app/shared/models/FeedbackModel';
import { TopicModel } from 'src/app/shared/models/Topic.model';
import { TopicOptionModel } from 'src/app/shared/models/TopicOption.model';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { DirectoryService } from 'src/app/shared/services/directory.service';
import { ImageVideoService } from 'src/app/shared/services/image-video.service';
import { ExistingCatalogTrainingResolverKey } from './catalog-training.resolver';

declare let window: any;

@Component({
  selector: 'yevo-training-detail',
  templateUrl: './training-detail.component.html',
  styleUrls: ['./training-detail.component.scss'],
})
export class TrainingDetailComponent implements OnInit {
  @Output() closeView = new EventEmitter<number>();
  @Output() searchTopics = new EventEmitter<any>();
  @Input() topic = {} as TopicModel;
  @Input() topics?: TopicModel[];
  @Input() topicOption = {} as TopicOptionModel;
  @Input() topicOptions = [] as TopicOptionModel[];

  frmRegister!: FormGroup;

  descriptionCol = '';
  descriptionRow = '';
  resourceUrl!: SafeResourceUrl;
  feel = '';
  frmFeedback!: FormGroup;
  hiddenComment = false;
  isLoaded: boolean = false;
  paramCourseUrl: string = '';

  utm_campaign: string = '';
  utm_content: string = '';
  utm_medium: string = '';
  utm_source: string = '';
  played: boolean = true;
  location: string = window.location.href;
  direction: string = 'https://api.whatsapp.com/send?text=' + this.location;
  copied: boolean = false;

  getListCommentVideo: any[] = [];
  userName$!: any;
  isList: boolean = false;
  send: boolean = false;

  topicOptionsDropdown: any[] = [];
  selectedTopic: IDropdownItem | null = null;
  numberTopicOptionsShowed: number = 5;
  listTopic: any = [];

  player: any;
  videoProgressInterval: any;
  videoAlreadyPlayed: boolean = false;
  event75PercentHandled: boolean = false;
  isBusinessDiagnosis: boolean = false;
  userSurveyId!: number;
  isSurveyCompleted: boolean = false;
  topicAdditionalData: any;
  childFenomenon: boolean = false;
  breadcrumbs: any;

  public get isFormValid(): boolean {
    return this.frmFeedback?.valid;
  }

  constructor(
    private directoryService: DirectoryService,
    public imageVideoService: ImageVideoService,
    public dataLayerService: DataLayerService,
    private seoService: SEOService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private meta: Meta,
    private title: Title,
    private video: VideoService,
    private videoProgressService: VideoProgressService,
    private businessDiagnosisService: BusinessDiagnosisService,
    private locationBack: Location
  ) {
    this.paramCourseUrl = this.route.snapshot.paramMap.get('trainingSlug') || '';
    this.route.queryParams.subscribe((params) => {
      this.utm_campaign = params.utm_campaign || '';
      this.utm_content = params.utm_content || '';
      this.utm_medium = params.utm_medium || '';
      this.utm_source = params.utm_source || '';
    });

    const state = window.history.state;
    if (state?.isBusinessDiagnosis) {
      sessionStorage.setItem('capacitacionesBreadcrumbs', JSON.stringify(state));
      this.getData(state);
    } else {
      const state = JSON.parse(sessionStorage.getItem('capacitacionesBreadcrumbs')!);
      this.getData(state);
    }
  }

  getData(state: any) {
    this.isBusinessDiagnosis = state?.isBusinessDiagnosis;
    this.userSurveyId = state?.userSurveyId;
    this.isSurveyCompleted = state?.isSurveyCompleted;
    this.topicAdditionalData = state?.topicAdditionalData;
    if (sessionStorage.getItem('videoChildFenomenon') !== null) {
      this.childFenomenon = true;
      setTimeout(() => {
        sessionStorage.removeItem('videoChildFenomenon');
      }, 1000);
    }
  }

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnDestroy(): void {
    this.videoProgressService.stopVideoProgressInterval();
  }

  public initComponent(): void {
    this.initForm();
    this.loadData();
    this.metaTags();

    this.resourceUrl = this.imageVideoService.getUrl(this.topic.image);

    setTimeout(() => {
      this.initVideoPlayer();
    });
    this.getlistComment();
    this.getTopicOptions();
    this.setNumberItemsShowed(window.innerWidth);

    this.getFeedback(this.topic);

    this.showFeedbackComments(true);

    document.getElementById('training-detail')?.scrollIntoView({ behavior: 'smooth' });

    this.seoService.updateTrainingCanonicalUrl(this.topic.slug!);

    this.updateTitle(this.topic.title);
  }

  public getlistComment() {
    this.isList = true;

    this.video
      .getAllVideoComment(this.topic.topicId)
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.getListCommentVideo = data;
        }
      });
    this.authService.userProfile$.subscribe((user) => {
      this.userName$ = user;
    });
  }

  private loadData(): void {
    this.route.data.subscribe((data) => {
      const existingTrainingyResolved: TopicModel = data[ExistingCatalogTrainingResolverKey];
      this.topic = existingTrainingyResolved;
      this.breadcrumbsPage(this.topic);
      if (this.topic.slug) {
        const data = {
          topicId: Number(this.topic.topicId),
          source: 'videoPreviewFlow',
          type: this.topic.type,
          utmMedium: this.utm_medium,
          utmCampaign: this.utm_campaign,
          utmSource: this.utm_source,
        };
        this.isLoaded = true;
        this.directoryService.addTopicViewedV2(data).subscribe();
      } else {
        this.router.navigate(['/nuevo-intranet', 'dashboard'], { queryParamsHandling: 'merge' });
      }
    });
  }

  public breadcrumbsPage(topic: any) {
    let stateBreadcrumb = 'Videos';
    if (topic?.type === 'article' || topic.type === 'html') {
      stateBreadcrumb = 'Artículos';
    } else if (topic?.type === 'webinar') {
      stateBreadcrumb = 'Webinars';
    }

    if (this.childFenomenon) {
      this.breadcrumbs = [
        { pageName: 'Fenómeno El Niño', url: ['/fenomeno-el-nino'], active: true },
        {
          pageName: stateBreadcrumb,
          url: null,
          active: false,
        },
      ];
    } else if (this.isBusinessDiagnosis) {
      this.breadcrumbs = [
        { pageName: 'Resultados', url: ['/diagnostico-negocio', 'resultado'], active: true },
        {
          pageName: stateBreadcrumb,
          url: null,
          active: false,
        },
      ];
    } else {
      this.breadcrumbs = [
        { pageName: 'Capacitaciones', url: ['/capacitaciones'], active: true },
        {
          pageName: stateBreadcrumb,
          url: null,
          active: false,
        },
      ];
    }
  }

  private updateTitle(topicTitle: string) {
    const titleGenerated = `${topicTitle} - Capacitaciones`;
    this.seoService.updateTitle(titleGenerated);
  }

  descriptionsFormat(): void {
    const descriptions = this.topic.description.split('<bottom-row>');
    if (descriptions.length > 0) {
      this.descriptionCol = descriptions[0];
    }
    if (descriptions.length > 1) {
      this.descriptionRow = descriptions[1];
    }
  }

  getFeedback(topic: TopicModel) {
    this.directoryService.getFeedbackByTopicId(topic.topicId).subscribe((feedback) => {
      this.feel = feedback.feel ?? '';
    });
  }

  saveFeedback(feel: string) {
    if (feel == 'like' && this.feel == 'like') return;
    if (feel == 'dislike' && this.feel == 'dislike') return;
    const feedbackReq = {
      topicId: Number(this.topic.topicId),
      feel: feel,
    } as FeedbackModel;
    this.directoryService.saveFeedback(feedbackReq).subscribe((feedback) => {
      this.dataLayerService.pushTrainingFeedbackEvent(this.topic.title, feel);
      this.getFeedback(this.topic);
      this.showFeedbackComments(false);
      this.frmFeedback.get('comments')?.patchValue('');
    });
  }

  sendComment() {
    const feedbackReq = {
      topicId: Number(this.topic.topicId),
      comments: this.frmFeedback.value.comments,
    } as FeedbackModel;
    this.directoryService.updateFeedback(feedbackReq).subscribe((feedback) => {
      this.dataLayerService.pushTrainingFeedbackEvent(this.topic.title, 'Comentario enviado');
      this.hiddenComment = true;
    });
  }

  discard() {
    this.dataLayerService.pushTrainingFeedbackEvent(this.topic.title, 'Comentario omitido');
    this.hiddenComment = true;
  }

  showFeedbackComments(status: boolean) {
    this.hiddenComment = status;
    const element = document.querySelector('#feedback-comment') as any;
    element.hidden = status;
  }

  back(): void {
    this.router.navigate(['/capacitaciones'], { queryParamsHandling: 'merge' });
  }

  getDay() {
    let date;
    if (this.topic.modificationDate === null) {
      date = this.topic.creationDate.toString();
    } else {
      date = this.topic.modificationDate.toString();
    }
    return `${date.substring(8, 10)} de ${date.substring(5, 7)} del ${date.substring(0, 4)}`;
  }

  handleIFrameClick(videoTitle: string) {
    this.dataLayerService.pushTrainingVideoClick(videoTitle);
  }

  initVideoPlayer() {
    const initialized: boolean = this.videoProgressService.initScriptPlayer(window, document);
    if (initialized && this.isBusinessDiagnosis) {
      this.player = new window['YT'].Player('youtubePlayer', {
        events: {
          onStateChange: this.onPlayerStateChange.bind(this),
        },
      });
    }
  }
  startVideoProgressInterval() {
    this.videoProgressService.startVideoProgressInterval(this.player, this.handleVideo75Percent.bind(this));
  }
  handleVideo75Percent() {
    if (!this.event75PercentHandled) {
      this.event75PercentHandled = true;
      if (!this.isSurveyCompleted) this.businessDiagnosisService.completeSurvey(this.userSurveyId).subscribe();
      if (!this.topicAdditionalData.seen) {
        this.businessDiagnosisService.updateTopicAsSeen(this.topicAdditionalData.userSurveyTopicId).subscribe();
      }
    }
  }
  onPlayerStateChange(event: any) {
    if (this.videoAlreadyPlayed) return;
    this.startVideoProgressInterval();
    if (event.data === window['YT'].PlayerState.PLAYING) {
      this.videoAlreadyPlayed = true;
    }
  }

  private initForm() {
    this.frmRegister = this.formBuilder.group({
      comment: ['', [Validators.required, Validators.maxLength(500), Validators.nullValidator]],
    });
  }

  viewShowSuggestion(topic: TopicModel): void {
    this.searchTopics.emit(topic);
    this.resourceUrl = this.imageVideoService.getUrl(topic.image);

    document.getElementById('#training-title')?.scrollIntoView({ behavior: 'smooth' });
    this.router.navigate(['/capacitaciones', topic.slug], { queryParamsHandling: 'merge' });
    setTimeout(() => {
      this.seoService.updateTrainingCanonicalUrl(topic.slug!);
      this.updateTitle(this.topic?.title);
    }, 1500);
  }

  isHtml(): boolean {
    return this.topic.type === 'html';
  }

  metaTags() {
    this.title.setTitle(`${this.topic.title}`);
    this.meta.updateTag({ name: 'title', content: `${this.topic.title}` });
    this.meta.updateTag({ name: 'description', content: `${this.topic.description}` });

    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:url', content: `https://lab.mibanco.com.pe/capacitaciones/${this.paramCourseUrl}/` });
    this.meta.updateTag({ property: 'og:title', content: `${this.topic.title}` });
    this.meta.updateTag({ property: 'og:description', content: `${this.topic.description}` });
    this.meta.updateTag({ property: 'og:image', content: 'https://lab.mibanco.com.pe/assets/seo/logo.jpeg' });

    this.meta.updateTag({ property: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({
      property: 'twitter:url',
      content: `https://lab.mibanco.com.pe/capacitaciones/${this.paramCourseUrl}/`,
    });
    this.meta.updateTag({ property: 'twitter:title', content: `${this.topic.title}` });
    this.meta.updateTag({ property: 'twitter:description', content: `${this.topic.description}` });
    this.meta.updateTag({ property: 'twitter:image', content: 'https://lab.mibanco.com.pe/assets/seo/logo.jpeg' });
    this.meta.updateTag({ property: 'twitter:site', content: '@Yevo' });
  }
  copy() {
    if (this.topic.type == 'video') {
      navigator.clipboard
        .writeText(this.location.replace('capacitaciones/', 'educacion/video/'))
        .then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 3000);
        })
        .catch((err) => {});
    } else {
      navigator.clipboard
        .writeText(this.location)
        .then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 3000);
        })
        .catch((err) => {});
    }
  }

  onSubmit() {
    const body: CommentModel = {
      topicId: this.topic.topicId,
      comment: this.frmRegister.get('comment')?.value,
      userId: this.userName$.userId,
    };

    this.video
      .setVideoComment(body)
      .pipe(take(1))
      .subscribe(
        (data: any) => {
          this.send = true;
          this.getlistComment();
        },
        (error) => {
          console.error('error', error);
        }
      );
  }

  newComment() {
    this.send = false;
    this.frmRegister.get('comment')?.setValue(' ');
  }

  getTopicOptions(): void {
    const topicsSelected: any = this.topic.parameterIds.split(',') || [];

    this.directoryService.getTopics().subscribe((items: any) => {
      this.getListTopic(items, topicsSelected);
    });
  }

  getListTopic(topics: any[], topicsSelected: any[]) {
    if (topicsSelected.includes(' 3018')) {
      this.topicOptionsDropdown = [
        {
          label: 'Fenómeno El Niño',
          value: 3018,
        },
      ];
      return;
    }
    this.topicOptionsDropdown = topics
      .map((value: any) => {
        for (const iterator of topicsSelected) {
          if (iterator == value.code && value.code != '3007') {
            return {
              label: value.name,
              value: value.code,
            };
          }
        }

        return null;
      })
      .filter(Boolean);
  }

  setNumberItemsShowed(windowWidth: number): void {
    if (windowWidth <= 1200) {
      this.numberTopicOptionsShowed = 5;
    } else if (windowWidth >= 1200) {
      this.numberTopicOptionsShowed = 8;
    }
  }

  goToTraining() {
    if (this.childFenomenon) {
      this.router.navigate(['/fenomeno-el-nino']);
    } else {
      this.router.navigate(['/capacitaciones']);
    }
  }

  goBack() {
    this.locationBack.back();
  }

  isArticleHtml() {
    let htmlString = this.topic.contentOfType;

    // Parse the HTML string into a Document object
    let parser = new DOMParser();
    let doc = parser.parseFromString(htmlString, 'text/html');

    // Select the style tag
    let styleTag: any = doc.querySelector('style');

    // Remove the style tag
    styleTag.parentNode.removeChild(styleTag);

    // Serialize the Document object back into an HTML string
    let serializer = new XMLSerializer();
    htmlString = serializer.serializeToString(doc);

    return htmlString;
  }
}

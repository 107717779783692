import { Component, HostListener, OnInit } from '@angular/core';
import { REMITTANCES_PROGRAM_VALUES } from '@shared/constants/remittances.contanst';
import { ButtonRemittancesModel, RemittancesModel } from '@shared/models/remittances.model';
import termsAndConditionsJson from '@shared/data/remittances/terms-conditions.json';
import remittancesJson from '@shared/data/remittances/map-google.json';
import { DataLayerService } from '@shared/services/data-layer.service';
import { VIRTUAL_EVENT_VALUES_REMITTANCES } from '@shared/constants/remittances.constant';

@Component({
  selector: 'remittances-landing',
  templateUrl: './remittances-landing.component.html',
  styleUrls: ['./remittances-landing.component.scss'],
})
export class RemittancesLandingComponent implements OnInit {
  showButton: boolean = false;
  tabOneShow: boolean = true;
  numberTab?: number;
  titleRemittance = 'Somos tu mejor opción para';
  subtitleRemittance = 'el traslado de dinero internacional';
  descriptionRemittance = 'Conoce nuestros beneficios';
  titleRemittanceMobile = 'Somos tu mejor opción para';
  subtitleRemittanceMobile = 'el traslado de dinero internacional';
  icons = [
    {
      name: 'Tipo de cambio',
      description: 'competitivo',
      icon: '/assets/img/landing/remittances/money-u.svg',
      url: '',
      section: '',
      isActived: true,
    },
    {
      name: 'Seguridad garantizada',
      description: 'en agencias',
      icon: '/assets/img/landing/remittances/escudo-u.svg',
      url: '',
      section: '',
      isActived: true,
    },
    {
      name: ' Entrega de tu ',
      description: 'dinero a tiempo',
      icon: '/assets/img/landing/remittances/map-u.svg',
      url: '',
      section: '',
      isActived: true,
    },
    {
      name: 'Atención',
      description: 'rápida y eficaz',
      icon: '/assets/img/landing/remittances/home-u.svg',
      url: '',
      section: '',
      isActived: true,
    },
  ];

  womenImageRoute: string = REMITTANCES_PROGRAM_VALUES.womanImageRoute;
  womenImageRouteImageBack: string = REMITTANCES_PROGRAM_VALUES.womenImageRouteImageBack;

  faqItemsVisible: boolean[] = [];
  selectedFaqId?: number;

  faqItemsVisibleButton: boolean[] = [];
  selectedFaqIdButton?: number;

  public get cardsFaq(): RemittancesModel[] {
    return this.faqData?.slice().sort((prev, curr) => prev.order - curr.order);
  }

  public get selectedFaq(): RemittancesModel | undefined {
    return this.faqData?.find((faq) => faq.contentManagerId === this.selectedFaqId);
  }

  public get cardsFaqButton(): ButtonRemittancesModel[] {
    return this.faqDataButton?.slice().sort((prev, curr) => prev.order - curr.order);
  }

  public get selectedFaqButton(): ButtonRemittancesModel | undefined {
    return this.faqDataButton?.find((faq) => faq.contentManagerId === 1);
  }

  constructor(private dataLayerService: DataLayerService) {}

  ngOnInit(): void {
    this.faqData = termsAndConditionsJson.termsAndConditonsByTab;
    this.selectedFaqId = this.cardsFaq[0].contentManagerId;
    this.faqDataButton = remittancesJson.faqGoogleMap;
    this.selectedFaqIdButton = this.cardsFaq[0].contentManagerId;
  }

  SendMoney() {}

  private faqData!: RemittancesModel[];

  private faqDataButton!: ButtonRemittancesModel[];

  scrollTo(elementId: string) {
    document.querySelector(`#${elementId}`)?.scrollIntoView({ behavior: 'smooth' });
    this.dataLayerPush(VIRTUAL_EVENT_VALUES_REMITTANCES.selectBannerRemittancesProgramPage);
  }

  dataLayerPush(labelOption:string): void {
    this.dataLayerService.virtualEventEnlaceREM(labelOption,VIRTUAL_EVENT_VALUES_REMITTANCES.selectSectionBanner);
  }

  @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: any) {
      const element = document.getElementById('five-agencies');
      if (element && window.scrollY >= 380) {
        element.classList.add('animate__animated', 'animate__fadeInRight');
      }

      const elementSendMoney = document.getElementById('send-money');
      if (elementSendMoney && window.scrollY >= 1000) {
        elementSendMoney.classList.add('animate__animated', 'animate__fadeInLeft');
      }
    }

    showSectionTabQuestion(numberTab: number){
      this.numberTab = numberTab;
      console.log(" this.numberTab",  this.numberTab);
      const pushQueryAttempt = this.selectedFaqId != this.numberTab;
      this.faqItemsVisible = [];
      this.selectedFaqId = this.numberTab;
      if (pushQueryAttempt) {
        this.dataLayerService.pushQueryAttempt(this.selectedFaq?.title!);
      }
      document.getElementById('section_to_do_today')?.scrollIntoView({ behavior: 'smooth' });

      if(numberTab > 0 ) {
        this.tabOneShow = false;
      } else {
        this.tabOneShow = true;
      }
    }
}


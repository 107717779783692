<div class="conatiner-home-benefits">
  <div class="title">{{ title }}</div>
  <div class="icons">
    <div class="main-item-icon" *ngFor="let icon of icons">
        <span *ngIf="icon.client != false " class="btn-new">Nuevo</span>
      <div
        class="item-icon"
        (click)="openBenefit(icon)"
        [ngClass]="{ 'bg-color-disabled': !icon.isActived }"
      >
        <div class="icon">
          <img [src]="icon.isActived?icon.icon:icon.iconDisable" [alt]="icon.name" />
        </div>
        <div class="name" [ngClass]="{ 'color-disabled': !icon.isActived }">{{ icon.name }}</div>
      </div>
    </div>
  </div>
</div>

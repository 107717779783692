<div class="custom-control custom-checkbox custom-control-inline">
  <input
    type="checkbox"
    class="custom-control-input"
    [id]="keyValue['key']"
    [value]="keyValue['value']"
    [checked]="checked"
    [disabled]="isDisabled"
    (change)="onCheckedChange($event)"
  />
  <label class="custom-control-label" [for]="keyValue['key']">
    {{ label || keyValue["key"] }}
  </label>
</div>

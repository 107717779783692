import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'yevo-blank-page',
  templateUrl: './blank-page.component.html',
  styleUrls: ['./blank-page.component.scss'],
})
export class BlankPageComponent implements OnInit {
  constructor() {
    //DO NOTHING
  }

  ngOnInit(): void {
    //DO NOTHING
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CommunityService } from '@shared/services/community.service';

@Component({
  selector: 'yevo-modal-answer-message',
  templateUrl: './modal-answer-message.component.html',
  styleUrls: ['./modal-answer-message.component.scss'],
})
export class ModalAnswerMessageComponent implements OnInit, OnDestroy {
  modalBoxActive = false;
  modalTransitionActive = false;
  @Input()
  forumId = 0;
  subscription = new Subscription();
  codeWomen = 1;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private communityService: CommunityService
  ) {}

  ngOnInit(): void {
    this.deactivateScroll();
    this.modalBoxActive = true;
    this.modalTransitionActive = true;
  }

  closePopup(): void {
    this.modalTransitionActive = false;
    const t = setTimeout(() => {
      clearTimeout(t);
      this.activateScroll();
      this.modalBoxActive = false;
    }, 400);
    this.activeModal.close('Modal Answer Message Closed');
  }

  deactivateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'hidden';
  }

  activateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'auto';
  }

  openQuestion(): void {
    this.subscription.add(
      this.communityService.getForumId(this.forumId).subscribe(
        (response) => {
          const route =
            this.codeWomen === response.communityId
              ? 'nuevo-intranet/comunidades/emprende-mujer/foro/id'
              : 'nuevo-intranet/comunidades/bodegueros/foro/id';
          this.router
            .navigate([route, this.forumId], { state: { data: response } })
            .then((result) => {
              console.info(result);
            })
            .catch((error) => {
              console.error(error);
            });
          this.closePopup();
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DirectoryService } from '@shared/services/directory.service';
import { AuthService } from '@shared/services/auth.service';
import { DOCUMENT } from '@angular/common';
import { take } from 'rxjs/operators';
import { UserService } from '@shared/services/user.service';

@Component({
  templateUrl: './affiliate-confirmation.component.html',
  styleUrls: ['./affiliate-confirmation.component.scss'],
})
export class AffiliateConfirmationComponent implements OnInit {
  directoryId!: number;
  directoryUrl!: string;
  slug!: string;

  isLogged!: boolean;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private directoryService: DirectoryService,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.getDirectoryCreated(this.route);
  }

  ngOnInit(): void {
    this.loadLoggedUser();
  }

  getDirectoryCreated(route: ActivatedRoute) {
    route.queryParams.subscribe(async (params) => {
      try {
        if (params['directoryId']) {
          this.directoryId = params['directoryId'];
          this.getDirectorySlug(this.directoryId);
        } else {
          this.router.navigate(['/nuevo-intranet', 'afiliacion'], { queryParamsHandling: 'merge' });
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  private getDirectorySlug(directoryId: number) {
    this.directoryService.getUserDirectory(directoryId).subscribe((resp) => {
      if (resp) {
        this.slug = resp?.slug!;
        this.directoryUrl = `${this.document.location.origin}/directorio/${this.slug}`;
      }
    });
  }

  private loadLoggedUser() {
    this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
      this.isLogged = !!user;
      this.authService.getUserProfile().subscribe();
    });
  }
}

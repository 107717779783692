import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MedalBenefits } from '@shared/interfaces/frontend/communities';
import { PARAMETERS_URL_VALUES } from '@shared/constants/communities.constants';

@Component({
  selector: 'yevo-my-benefit-card',
  templateUrl: './my-benefit-card.component.html',
  styleUrls: ['./my-benefit-card.component.scss']
})
export class MyBenefitCardComponent implements OnInit {
  @Input() medalBenefit!:MedalBenefits;
  @Input() progressMedal!: string;
  @Output() selectedItemEmit = new EventEmitter<any>();

  utmSource!: string;
  utmMedium!: string;
  utmCampaign!: string;
  urlPage!:string;
  urlWhatsApp!:string;
  parentRoute: string | undefined;

  constructor(private router: Router, private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.communityValues();
  }

  goToRedirection(urlPage: boolean, url: string, title:string): void {
    this.parentRoute = this.route.parent?.routeConfig?.path;
    this.selectedItemEmit.emit(title);
    this.communityValues();
    if (urlPage && this.parentRoute) {
      const urlTmp = url.replace("source", this.parentRoute);
      this.router.navigate([`${urlTmp}`], {
        queryParams: {
          utm_source: this.utmSource,
          utm_medium: this.utmMedium,
          utm_campaign: this.utmCampaign,
        },
      });
    } else {
      window.open(this.urlWhatsApp, '_blank');
    }
  }

  communityValues() {
    if (this.parentRoute === 'emprende-mujer') {
      this.urlWhatsApp = PARAMETERS_URL_VALUES.urlWhatsAppWoman;
      this.utmSource = PARAMETERS_URL_VALUES.utmSourceWoman;
      this.utmMedium = PARAMETERS_URL_VALUES.utmMediumBanner;
      this.utmCampaign = PARAMETERS_URL_VALUES.utmCampaignReferred;
    } else {
      this.utmSource = PARAMETERS_URL_VALUES.utmSourceWinemakers;
      this.utmMedium = PARAMETERS_URL_VALUES.utmMediumBanner;
      this.utmCampaign = PARAMETERS_URL_VALUES.utmCampaignReferred;
      this.urlWhatsApp = PARAMETERS_URL_VALUES.urlWhatsAppWinemakers;
    }
  }
}

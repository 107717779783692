import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'yevo-modal-register',
  templateUrl: './modal-register.component.html',
  styleUrls: ['./modal-register.component.scss']
})
export class ModalRegisterComponent {

  constructor( private modalService: NgbModal, private router: Router,) { }

  closeModal() {
    this.modalService.dismissAll();
  }

  registerEvent() {
    this.router.navigate(['/registro'], { queryParamsHandling: 'merge' });
    this.modalService.dismissAll();
  }

  login() {
    this.router.navigate(['/ingresar'], { queryParamsHandling: 'merge' });
    this.modalService.dismissAll();
  }

}

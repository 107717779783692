import { Component, Input } from '@angular/core';
import { DataLayerService } from '@shared/services/data-layer.service';

export type Icon = {
  name: string;
  icon: string;
  url: string;
  section?: string;
};

@Component({
  selector: 'yevo-community-benefits',
  templateUrl: './community-benefits.component.html',
  styleUrls: ['./community-benefits.component.scss'],
})
export class CommunityBenefitsComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() icons: Icon[] = [];

  constructor(private dataLayerService: DataLayerService) {}

  openBenefit(icon: Icon): void {
    const community = this.title.includes('crecen') ? 'Emprende Mujer' : 'de Bodegueros';
    this.dataLayerService.clickCommunity(community, icon.name, 'Seleccionar', 2);
    if (icon.section) {
      this.goToSection(icon.section);
    } else {
      window.open(icon.url, '_blank');
    }
  }

  goToSection(sectionId: string): void {
    const section = document.getElementById(sectionId);
    let offset;

    const vh = window.innerHeight;
    if (vh < 1200) {
      offset = 75;
    } else {
      offset = 100;
    }

    if (section) {
      const scrollOptions = {
        top: section.getBoundingClientRect().top + window.scrollY - offset,
        behavior: 'smooth' as ScrollBehavior,
      };
      window.scrollTo(scrollOptions);
    }
  }
}

import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ILatLng } from '@shared/interfaces';
import { DataLayerService } from '@shared/services/data-layer.service';
import { GeolocationService } from '@shared/services/geolocation.service';

@Component({
  selector: 'yevo-geolocation-maps',
  templateUrl: './geolocation-maps.component.html',
  styleUrls: ['./geolocation-maps.component.scss'],
})
export class GeolocationMapsComponent implements OnInit {
  @ViewChild('mapSearchField') formSearchField!: ElementRef;
  @Output() newAddress = new EventEmitter<any>();
  @Output() mapOpened = new EventEmitter<any>();
  @Output() buttonAccepted = new EventEmitter<any>();
  @Output() buttonClose = new EventEmitter<any>();
  @Input() currentPosition!: google.maps.Marker;
  @Output() currentPositionChanged = new EventEmitter<any>();

  mapModal!: NgbModalRef;
  LIMA_LTG_LNG = { lat: -12.0552493, lng: -77.0627323 };

  autocompleteOptions: google.maps.places.AutocompleteOptions = {
    componentRestrictions: { country: 'PE' },
    // !: Be careful to keep a few fields because Google charges us $$$ for each field that we request
    fields: ['address_components', 'geometry', 'formatted_address'],
  };

  mapConfigurations: google.maps.MapOptions = {
    center: this.LIMA_LTG_LNG,
    disableDefaultUI: true,
    fullscreenControl: false,
    zoom: 16,
    zoomControl: true,
  };

  map!: google.maps.Map;
  modalSearchField!: HTMLInputElement;
  geocoder = new google.maps.Geocoder();
  googlePlaceSelected = false;
  tempValidAddress = '';

  constructor(
    public dataLayerService: DataLayerService,
    public geolocationService: GeolocationService,
    private modalService: NgbModal,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.LIMA_LTG_LNG.lat = position.coords.latitude;
          this.LIMA_LTG_LNG.lng = position.coords.longitude;
        },
        (error) => {
          this.hideOpenMapLink();
          alert(`${error.message}`);
        }
      );
    }
  }

  initModalMapAndSearchBar() {
    const mapRefElement = document.getElementById('map') as HTMLElement;
    this.map = new google.maps.Map(mapRefElement, {
      ...this.mapConfigurations,
    });
    this.modalSearchField = document.getElementById('modalSearchField') as HTMLInputElement;
    const modalAutocomplete = new google.maps.places.Autocomplete(this.modalSearchField, this.autocompleteOptions);
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.modalSearchField);

    if (!this.currentPosition) {
      if (!this.geolocationService.getCurrentPosition()) {
        this.requestAndSetCurrentCoordinates();
      } else {
        this.currentPosition = this.geolocationService.getCurrentPosition();
        let latLng = {
          lat: this.currentPosition.getPosition()!.lat(),
          lng: this.currentPosition.getPosition()!.lng(),
        };

        this.setMarker({ latLng, setCenter: true });
      }
    } else {
      let latLng = {
        lat: this.currentPosition.getPosition()!.lat(),
        lng: this.currentPosition.getPosition()!.lng(),
      };

      this.setMarker({ latLng, setCenter: true });
    }

    // Events subscriptions
    modalAutocomplete.addListener('place_changed', () => {
      const place = modalAutocomplete.getPlace();
      if (!place) return;
      const bounds = new google.maps.LatLngBounds();

      if (!place.geometry || !place.geometry.location) return;
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }

      this.setMarker({
        latLng: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      });
      this.map.fitBounds(bounds);
    });
    this.map.addListener('bounds_changed', () => {
      modalAutocomplete.setBounds(this.map?.getBounds() as google.maps.LatLngBounds);
    });
    this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
      const latLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.setMarker({ latLng });
    });
  }

  ngAfterViewInit() {
    const formAutocomplete = new google.maps.places.Autocomplete(
      this.formSearchField.nativeElement,
      this.autocompleteOptions
    );
    formAutocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const location = formAutocomplete.getPlace().geometry?.location;

        if (!location) return;
        const latLng: ILatLng = {
          lat: location.lat(),
          lng: location.lng(),
        };
        this.setMarker({ latLng, fillAutocompleteField: true });
      });
    });
  }

  openModalMap(modalContent: any) {
    this.mapModal = this.modalService.open(modalContent, { size: 'xl' });
    this.mapModal.shown.subscribe(() => {
      this.initModalMapAndSearchBar();
    });

    this.mapModal.result.then(
      (result) => {
        this.map = null!;
      },
      (reason) => {
        this.googlePlaceSelected = false;
        this.map = null!;
      }
    );
    this.mapOpened.emit();
  }

  requestAndSetCurrentCoordinates() {
    let latLng = this.LIMA_LTG_LNG;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          latLng.lat = position.coords.latitude;
          latLng.lng = position.coords.longitude;
          this.setMarker({ latLng, setCenter: true });
        },
        (e) => console.error(e),
        { enableHighAccuracy: true, timeout: 5000 }
      );
    }
  }

  close() {
    this.buttonClose.emit(true);
    this.modalService.dismissAll();
  }

  Accept() {
    this.buttonAccepted.emit(true);
    this.modalService.dismissAll();
  }

  private setMarker({
    latLng,
    fillAutocompleteField = true,
    setCenter = false,
  }: {
    latLng: ILatLng;
    fillAutocompleteField?: boolean;
    setCenter?: boolean;
  }) {
    if (!this.currentPosition || !this.currentPosition.setPosition) {
      this.currentPosition = new google.maps.Marker({
        position: latLng,
        map: this.map,
        icon: 'https://yevononprodstorage.blob.core.windows.net/yevononprodcontainer/pin.png',
      });
    } else {
      this.currentPosition.setMap(this.map);
      this.currentPosition.setPosition(latLng);
    }

    if (setCenter) {
      this.map.setCenter(latLng);
    }

    if (fillAutocompleteField) {
      this.setAddressFromGeocode(latLng);
    }

    this.googlePlaceSelected = true;
    this.currentPositionChanged.emit(this.currentPosition);
    this.geolocationService.setCurrentPosition(this.currentPosition);
  }

  private setAddressFromGeocode(latLng: ILatLng) {
    this.geocoder.geocode(
      {
        location: latLng,
      },
      (results, status) => {
        const result = results[0];
        if (status === google.maps.GeocoderStatus.OK && result) {
          if (this.modalSearchField) {
            this.modalSearchField.value = result.formatted_address;
          }

          const data = {
            address: result.formatted_address,
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
          };
          this.newAddress.emit(data);
        }
      }
    );
  }

  private hideOpenMapLink() {
    const openMapLink = document.getElementById('geolocationOpenMap') as HTMLElement;
    openMapLink.style.setProperty('display', 'none', 'important');
  }
}

import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'yevo-modal-general-message',
  templateUrl: './modal-general-message.component.html',
  styleUrls: ['./modal-general-message.component.scss'],
})
export class ModalGeneralMessageComponent {
  constructor(private modalService: NgbModal) {}

  public closeModal() {
    this.modalService.dismissAll();
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { messageTypes } from '@shared/constants/financing.constants';
import { AuthService } from '@shared/services/auth.service';
import { LoaderService } from '@shared/services/loader.service';
import { UserService } from '@shared/services/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'yevo-financing-thanks-form',
  templateUrl: './financing-thanks-form.component.html',
  styleUrls: ['./financing-thanks-form.component.scss'],
})
export class FinancingThanksFormComponent {
  showMessage: boolean = false;
  showMessageKushka: boolean = false;
  isLogged!: boolean;
  messageType!: messageTypes;
  unsuccess: boolean = false;
  typeForm: string = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private userService: UserService,
    private loaderService: LoaderService
  ) {
    const message = this.router.getCurrentNavigation()?.extras.state;

    if (message) {
      this.showMessage = message?.showMessage;
      this.showMessageKushka = message?.showMessageKushka;
    }
    this.hasStatusParam();
    this.loadLoggedUser();
  }

  private loadLoggedUser() {
    this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
      this.isLogged = !!user;
      if (!user?.levels?.loanAppliedSuccessfully) {
        user!.levels.loanAppliedSuccessfully = true;
        this.userService.setUserLevelUp();
        this.authService.getUserProfile().subscribe();
      }
    });
  }

  private hasStatusParam() {
    const params = this.route.snapshot.queryParams;
    this.typeForm = params['aliado']?.toUpperCase();
    if (params['estado']) {
      if (params['estado'] === 'solicitudexitosa') {
        this.messageType = 'sent';
      } else {
        if (sessionStorage.getItem('currentFinancing') === 'lead') {
          this.messageType = 'not-sent';
        } else if (params['aliado'] == 'yape') {
          this.isLogged = false;
          this.messageType = params['message'];
        } else {
          this.unsuccess = true;
        }
      }
    } else {
      this.messageType = 'sent';
    }
  }
}

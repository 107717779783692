<div *ngIf="this.isLoggedIn">
      <div class="container margin">
        <div class="row">
          <form (ngSubmit)="submitForm()"> 
            <p class="margin">Nueva Notificación</p>
            <div class="col-12 col-md-12">
              <div class="form-group">
                <label for="title">Titulo:</label>
                <input type="text" id="title" name="title" [(ngModel)]="formData.notification.title" class="form-control">
              </div>
              <div class="form-group">
                <label for="body">Descripción:</label>
                <input type="text" id="body" name="body" [(ngModel)]="formData.notification.body" class="form-control">
              </div>
              <div class="form-group">
                <label for="icon">Icono (<span class="info-section">https/..</span>):</label>
                <input type="text" id="icon" name="icon" [(ngModel)]="formData.notification.icon" class="form-control">
              </div>
              <div class="form-group">
                <label for="image">Imagen (<span class="info-section">https/..</span>):</label>
                <input type="text" id="image" name="image" [(ngModel)]="formData.notification.image" class="form-control">
              </div>
              <div class="form-group">
                <label for="clickAction">Sección de la Página (<span class="info-section">nuevo-intranet/comunidades/bodegueros/comparador-precio</span>): </label>
                <input type="text" id="clickAction" name="clickAction" [(ngModel)]="formData.notification.click_action"
                  class="form-control">
              </div>
            </div>
            <button type="submit" class="btn btn-primary">Enviar Notificación</button>
          </form>
          </div>
      </div>
</div>
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import dataJSON from '@shared/data/community/midagri/markets.json';
import productsDataJSON from '@shared/data/community/midagri/products.json';
import { IMarket, IProduct, PricesMarketsResponse } from '@shared/interfaces/frontend/communities';
import { CommunityService } from '@shared/services/community.service';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-price-finder-page',
  templateUrl: './price-finder-page.component.html',
  styleUrls: ['./price-finder-page.component.scss'],
})
export class PriceFinderPageComponent implements OnInit, OnDestroy {
  idSection: string = 'price-finder-section';
  isMobile: boolean = false;
  titleTerms: string = 'Términos y condiciones';
  descriptionTerms: string =
    'Mibanco proporciona esta información a través de este servicio con el fin de ofrecer datos relevantes y actualizados para los afiliados de Yevo.';
  descriptionTerms2: string =
    'Mibanco obtiene la información de fuentes externas y terceros, por lo que, el banco no puede garantizar su integridad ni actualidad. Por lo tanto, los usuarios deben entender que Mibanco no se hace responsable ante cualquier inexactitud, omisión o error en la información proporcionada, ni las decisiones tomadas con base en dicha información';
  ALL_MARKETS: string = 'Todos los mercados';
  ALL_PRODUCT: string = 'Todos los productos';
  ALL_VARIETY: string = 'Todas las presentaciones';
  pipeMarket = '';
  pipeProduct = '';
  pipeVariety = '';
  titleMarketModal: string = 'Seleccione un mercado';
  titleProductModal: string = 'Seleccione un producto';
  titleVarietyModal: string = 'Selecciona una presentación';
  placeholderMarket: string = 'Seleccione un mercado';
  placeholderProduct: string = 'Seleccione un producto';
  placeholderVariety: string = 'Selecciona una presentación';
  placeholderMarketSearchMobile: string = 'Busca un mercado';
  placeholderProductSearchMobile: string = 'Busca un producto';
  placeholderVarietySearchMobile: string = 'Busca presentaciones de producto';
  marketSelect!: PricesMarketsResponse;
  marketsMidagriJSON: IMarket[] = dataJSON;
  productJSON: IProduct[] = productsDataJSON;
  midagriData: PricesMarketsResponse[] = [];
  marketsList: PricesMarketsResponse[] = [];
  productList: PricesMarketsResponse[] = [];
  varietyList: PricesMarketsResponse[] = [];
  midagriDataTmp: PricesMarketsResponse[] = [];
  midagriDataTmp2: PricesMarketsResponse[] = [];
  showModal: boolean = false;
  productSelect: string = '';
  varietyProductSelect: string = '';
  thereIsData: boolean = false;
  showbuttonConsult: boolean = false;
  listSelectOnMarket: boolean = false;
  listSelectOnProduct: boolean = false;
  listSelectOnVariety: boolean = false;
  conditionsMarkt: boolean = false;
  listProductSelections: IProduct[] = [];
  productChecked: PricesMarketsResponse[] = [];
  varietyChecked: PricesMarketsResponse[] = [];
  error!: string;
  loading = true;
  validData = true;
  allMarketSelect: boolean = false;
  allProductSelect: boolean = false;
  allVarietySelect: boolean = false;
  codeMarketselect!: number;
  checkedProduct: boolean = false;
  checkedVariety: boolean = false;
  nameMarketselect!: string;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkIfMobile();
  }

  constructor(
    public modal: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private dataLayerService: DataLayerService,
    private communityServer: CommunityService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getDataInit();
    this.checkIfMobile();
    window.addEventListener('popstate', this.closeModalNavegations.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('popstate', this.closeModalNavegations.bind(this));
  }

  closeModalNavegations() {
    this.modal.dismissAll();
  }

  checkIfMobile(): void {
    this.isMobile = window.innerWidth <= 768;
  }

  getDataInit(): void {
    this.communityServer.getAllPriceMarkets().subscribe(
      (response) => {
        this.midagriData = response;
        if (this.midagriData === null || this.midagriData.length === 0) {
          this.loading = false;
        } else {
          this.loading = true;
          this.getMarket();
        }
      },
      (error) => {
        this.loading = false;
        this.error = error;
      }
    );
    this.marketsMidagriJSON = dataJSON;
  }

  getMarket(): void {
    this.marketsMidagriJSON.forEach((market) => {
      const filteredData = this.midagriData.filter((data) => data.marketId === market.id);

      if (filteredData.length > 0) {
        this.marketsList.push(filteredData[0]);
      }
    });
    this.marketsList.unshift(this.dataResponse());
  }

  open(content: any, codSelect: number) {
    if (this.isMobile) {
      this.activeAndDesactiveEventMobile();
      this.modal.open(content, { windowClass: 'modal_price-markets' }).result.then(() => {
        this.showModal = false;
      });
      this.showModal = true;

      return;
    }
    this.activeAndDesactiveEventDesktop(codSelect);
  }

  activeAndDesactiveEventDesktop(codeSelect: number) {
    if (codeSelect === 1) {
      this.showbuttonConsult = false;
      this.listSelectOnMarket = !this.listSelectOnMarket;
      this.listSelectOnProduct = false;
      this.listSelectOnVariety = false;
      return;
    }
    if (codeSelect === 2) {
      this.showbuttonConsult = false;
      this.listSelectOnMarket = false;
      this.listSelectOnProduct = !this.listSelectOnProduct;
      this.listSelectOnVariety = false;
      return;
    }
    this.listSelectOnMarket = false;
    this.listSelectOnProduct = false;
    this.listSelectOnVariety = !this.listSelectOnVariety;
  }

  activeAndDesactiveEventMobile() {
    this.listSelectOnMarket = false;
    this.listSelectOnProduct = false;
    this.listSelectOnVariety = false;
  }

  checkMarket(market: PricesMarketsResponse, typeDispositive: string): void {
    this.varietyList.splice(0, this.varietyList.length);
    this.midagriDataTmp.splice(0, this.midagriDataTmp.length);
    this.productList.splice(0, this.productList.length);
    this.productChecked.splice(0, this.productChecked.length);
    market.marketName.toUpperCase() == this.ALL_MARKETS.toUpperCase()
      ? this.getProduct(market, true)
      : this.getProduct(market, false);
    this.placeholderMarket = market.marketName;
    if (this.isMobile) {
      this.activeAndDesactiveEventMobile();
    } else {
      this.listSelectOnMarket = !this.listSelectOnMarket;
    }
    this.codeMarketselect = market.marketId;
    this.nameMarketselect = market.marketName;
    this.cleanEvent(true, false, false);
  }

  cleanEvent(isMercado: boolean, isproduct: boolean, isvariedad: boolean) {
    if (isMercado) {
      this.varietyList.splice(0, this.varietyList.length);
      this.listProductSelections.splice(0, this.listProductSelections.length);
      this.placeholderProductSearchMobile = 'Busca un producto';
      this.placeholderVarietySearchMobile = 'Busca presentaciones de producto';
      this.placeholderProduct = 'Seleccione un producto';
      this.placeholderVariety = 'Selecciona una presentación';
      return;
    }
    if (isproduct) {
      this.varietyList.splice(0, this.varietyList.length);
      this.placeholderVarietySearchMobile = 'Busca presentaciones de producto';
      this.placeholderVariety = 'Selecciona una presentación';
    }
  }

  getProduct(market: PricesMarketsResponse, isAllmarkets: boolean): void {
    this.varietyList.splice(0, this.varietyList.length);
    if (isAllmarkets) {
      this.productJSON.forEach((product) => {
        const matchingData = this.midagriData.find((data) => data.productId === product.id);
        if (matchingData) {
          this.productList.push(matchingData);
        }
      });
      this.allMarketSelect = true;
      this.productList.unshift(this.dataResponse());
      return;
    }
    this.allMarketSelect = false;
    this.midagriDataTmp = this.midagriData.filter((item) => {
      return item.marketId == market.marketId;
    });
    const productSet = new Set();
    for (const product of this.midagriDataTmp) {
      const id = product.productId;
      if (!productSet.has(id)) {
        productSet.add(id);
        this.productList.push({
          marketName: market.marketName,
          productName: product.productName,
          price: 0,
          productVarietyName: '',
          currentDate: new Date(),
          marketId: market.marketId,
          productId: product.productId,
          isEmpty: true,
          state: 0,
        });
      }
    }
    this.productList.unshift(this.dataResponse());
  }

  dataResponse(): PricesMarketsResponse {
    return {
      marketName: this.ALL_MARKETS,
      productName: this.ALL_PRODUCT,
      price: 0,
      productVarietyName: this.ALL_VARIETY,
      currentDate: new Date(),
      marketId: 0,
      productId: 0,
      isEmpty: false,
      state: 0,
    };
  }

  checkProduct(product: PricesMarketsResponse, ischecked: Event, style: string): void {
    const target = ischecked.target as HTMLInputElement;
    this.placeholderProduct = product.productName;
    this.varietyList.splice(0, this.varietyList.length);
    this.varietyChecked.splice(0, this.varietyChecked.length);
    this.placeholderVarietySearchMobile = 'Busca presentaciones de producto';
    this.placeholderVariety = 'Selecciona una presentación';

    if (product.productName === this.ALL_PRODUCT) {
      if (target.checked) {
        this.checkboxesUtil(true, style);
        this.checkedProduct = true;
        this.allProductSelect = true;
        this.productChecked = this.productList.filter((item) => item.marketId !== 0);
      } else {
        this.checkboxesUtil(false, style);
        this.checkedProduct = false;
        this.allProductSelect = false;
        this.productChecked.splice(0, this.productChecked.length);
        this.placeholderProduct = 'Seleccione un producto';
      }
      this.getVariety(this.productChecked);
      return;
    }

    if (this.checkedProduct && !target.checked) {
      this.checkedProduct = false;
      this.checkboxesUtil(false, style);
      this.productChecked.splice(0, this.productChecked.length);
      this.allProductSelect = false;
      this.placeholderProduct = 'Seleccione un producto';
      this.getVariety(this.productChecked);
      return;
    }
    if (target.checked) {
      this.productChecked.push(product);
    } else {
      let i: number = 0;
      let found: number = 0;
      this.productChecked.forEach((item) => {
        if (item.productId === product.productId) {
          found = i;
        }
        i++;
      });
      this.productChecked.splice(found, 1);
    }
    this.allProductSelect = false;
    this.getVariety(this.productChecked);
  }

  getVariety(listProduct: PricesMarketsResponse[]): void {
    if (this.allMarketSelect) {
      this.varietyList.splice(0, this.varietyList.length);
      listProduct.forEach((product) => {
        const filteredData = this.midagriData.filter((data) => data.productName === product.productName);
        this.varietyList.push(...filteredData);
      });
    } else {
      this.varietyList.splice(0, this.varietyList.length);
      listProduct.forEach((product) => {
        const filteredData = this.midagriData.filter(
          (data) => data.productName === product.productName && data.marketId === this.codeMarketselect
        );
        this.varietyList.push(...filteredData);
      });
    }
    this.varietyList.unshift(this.dataResponse());
  }

  checkVariety(variety: PricesMarketsResponse, ischecked: Event, style: string): void {
    this.varietyProductSelect = variety.productVarietyName;
    this.placeholderVariety = variety.productVarietyName;
    const target = ischecked.target as HTMLInputElement;

    if (variety.productVarietyName === this.ALL_VARIETY) {
      if (target.checked) {
        this.checkboxesUtil(true, style);
        this.checkedVariety = true;
        this.allVarietySelect = true;
        this.varietyChecked = this.varietyList.filter((item) => item.marketId !== 0);
        this.showbuttonConsult = true;
      } else {
        this.showbuttonConsult = false;
        this.checkboxesUtil(false, style);
        this.checkedVariety = false;
        this.allVarietySelect = false;
        this.placeholderVariety = 'Selecciona una presentación';
        this.varietyChecked.splice(0, this.varietyChecked.length);
      }
      return;
    }

    if (this.checkedVariety && !target.checked) {
      this.checkedVariety = false;
      this.checkboxesUtil(false, style);
      this.varietyChecked.splice(0, this.varietyChecked.length);
      this.allVarietySelect = false;
      this.showbuttonConsult = false;
      this.placeholderVariety = 'Selecciona una presentación';
      return;
    }

    if (target.checked) {
      this.varietyChecked.push(variety);
    } else {
      let i: number = 0;
      let found: number = 0;
      this.varietyChecked.forEach((item) => {
        if (item.productId === variety.productId) {
          found = i;
        }
        i++;
      });
      this.varietyChecked.splice(found, 1);
    }
    this.allVarietySelect = false;
    if (this.varietyChecked.length === 0) {
      this.showbuttonConsult = false;
    } else {
      this.showbuttonConsult = true;
    }
  }
  checkboxesUtil(ischecked: boolean, style: string): void {
    let checkboxes = document.querySelectorAll(`.${style}`);
    checkboxes.forEach((checkbox) => {
      if (checkbox instanceof HTMLInputElement) {
        ischecked ? (checkbox.checked = true) : (checkbox.checked = false);
      }
    });
  }
  goInicio(): void {
    this.router.navigate(['..'], { relativeTo: this.route }).then(() => {
      const element = document.getElementById(this.idSection);
      if (element) {
        element.scrollIntoView();
      }
    });
  }

  openModalTerms(termsConditions: any) {
    this.modal.open(termsConditions, { windowClass: 'modal_termsAndConditions' }).result.then();
  }

  closeModalTerms() {
    this.modal.dismissAll();
  }

  closeModal(code: number) {
    this.showModal = false;
    this.modal.dismissAll();
  }

  consult(): void {
    const parentRoute = this.route.parent?.routeConfig?.path;
    if (parentRoute) {
      this.communityServer.sendFormPriceMarkets(this.varietyChecked);
      this.router.navigate([`/nuevo-intranet/comunidades/${parentRoute}/resultado-consulta`]);
      this.goToTaggerGoogle(this.varietyChecked);
    }
  }

  goToTaggerGoogle(data: PricesMarketsResponse[]): void {
    if (this.allProductSelect && this.allVarietySelect) {
      const marketName = this.allMarketSelect ? this.ALL_MARKETS : this.nameMarketselect;
      this.dataLayerService.virtualEventPriceComparator(
        'Consulta exitosa',
        18,
        marketName,
        this.ALL_PRODUCT,
        this.ALL_VARIETY
      );
      return;
    }
    data.forEach((element) => {
      const marketName = this.allMarketSelect ? this.ALL_MARKETS : element.marketName;
      const productName = this.allProductSelect ? this.ALL_PRODUCT : element.productName;
      const productVarietyName = this.allVarietySelect ? this.ALL_VARIETY : element.productVarietyName;
      this.dataLayerService.virtualEventPriceComparator(
        'Consulta exitosa',
        18,
        marketName,
        productName,
        productVarietyName
      );
    });
  }
}

<div class="price-finder__mobile">
    <div class="container-price-finder">
        <div class="title"> {{title}} </div>
        <div class="description"> {{description}} </div>
        <div class="contenedor-img-button">
            <img class="img-price-finder" [src]="image_mobile" [alt]="alt_image_mobile" loading="lazy" />
            <button type="button" class="btn btn-link finder-button" (click)="goToPriceFinder()">
                Buscar el mejor precio
            </button>
            <div class=" p-1 mb-2 meesage-mobile">
                Revisa los
                <a (click)="open()">términos y condiciones</a>
            </div>
        </div>
    </div>
</div>

<div class="price-finder__desktop">
    <div class="conteiner-desktop">
        <div class="row">
            <div class="col-6">
                <div class="informative-desktop">
                    <div class="title"> {{title}} </div>
                    <div class="description"> {{description}} </div>
                    <div class="">
                        <button type="button" class="btn btn-link finder-button" (click)="goToPriceFinder()">
                            Buscar el mejor precio
                        </button>
                        <div class=" p-1 mb-2 meesage">
                            Revisa los
                            <a (click)="open()">términos y condiciones</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="img-desktop">
                    <img class='' [src]='imageDesktop' alt='bodeguero' loading="lazy" />
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" [ngClass]="{ activeBox: modalBoxActive, activeTransitions: modalTransitionActive }">
  <div class="modal-layer" (click)="closePopup()"></div>

  <div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <img src="/assets/icons/close-green.svg" alt="cerrar" />
    </button>
    <div class="container">
      <div class="row">
        <div class="col-12 pl-md-0 pr-md-0 mt-3">
          <img [src]="details?.image" alt="image" class="modal-allied-image mb-3 mt-3" />
        </div>

        <div class="col-md-12 modal-allied-line d-flex pl-md-0 pr-md-0">
          <div *ngIf="details?.tags.length" class="d-flex">
            <span class="modal-allied-tags mt-3 mb-3" *ngFor="let item of details?.tags; let i = index">
              <img [src]="tagsIcon[i]" alt="image" class="modal-allied-icon" /> {{ item?.label }}</span
            >
            <span class="modal-allied-tags mt-3 mb-3">
              <img src="/assets/img/allies/store.svg" alt="image" class="modal-allied-icon" />Negocio</span
            >
          </div>
        </div>

        <div class="col-12 pl-md-0 pr-md-0 mt-3">
          <p class="modal-allied-title">
            {{ details?.title }}
          </p>
        </div>

        <div class="col-md-12 modal-allied-line pl-md-0 pr-md-0" *ngIf="details?.description">
          <p class="modal-allied-description mt-4 mb-4" [innerHTML]="details.description"></p>
        </div>

        <div class="col-12 mb-3 pl-md-0 pr-md-0 modal-allied-line container-button" *ngIf="isLogged">
          <a
            *ngIf="details?.catalogue"
            [href]="details.download"
            class="btn btn-primary modal-allied-button-catalogue mt-4"
            target="_blank"
            [download]="details.title"
            (click)="addButtonEvent()"
          >
            <span>{{ details?.catalogue }}</span>
          </a>

          <button class="btn btn-primary modal-allied-button ml-lg-4 mt-4" *ngIf="details?.button" (click)="goToLink()">
            <span>{{ details?.button }}</span>
          </button>
        </div>

        <div class="col-12 mb-3 pl-md-0 pr-md-0 modal-allied-line container-button" *ngIf="!isLogged">
          <button class="btn btn-primary modal-allied-button ml-lg-4 mt-4" *ngIf="details?.button" (click)="goToLink()">
            <span>Registrarme</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

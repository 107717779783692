<div class="bc-footer">
    <footer class="">
      <div class="footer-seed">
        <div class="__desktop">
          <a routerLink="/" class="navbar-logo">
            <img class="logo-home" src="/assets/img/logo_negativo.png" alt="logo_mibancolab">
          </a>
        </div>
        <div class="footer-seed-content">
          <div class="footer-seed-link">
            <a *ngIf="activedTerms" routerLink="/terminos-y-condiciones">Términos y Condiciones</a>
            <a *ngIf="activedCookie" routerLink="/cookie">Política de Cookies</a>
            <a href="https://www.mibanco.com.pe/libro-reclamaciones" target="_blank" rel="noopener noreferrer">
                <svg-icon-sprite class="mr-2" [src]="'reclamaciones-secondary'" [width]="'19px'"></svg-icon-sprite> Libro de reclamaciones
            </a>
          </div>
          <div class="__mobile logo">
            <a routerLink="/" class="navbar-logo">
              <img class="logo-home" src="/assets/img/logo_negativo.png" alt="logo_mibancolab">
            </a>
          </div>
          <div class="footer-seed-year">© 2024 MibancoLab - Todos los derechos reservados</div>
        </div>
      </div>
    </footer>
  </div>
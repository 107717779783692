import { Component, OnInit, ViewChild } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { DATA_ALLIED } from '@shared/data/communities/data-allied.json';
import { Allied } from '@shared/interfaces/frontend/communities';
import SwiperCore, { Pagination } from 'swiper/core';
import './allied-business.component.scss';
import { DataLayerService } from '@shared/services/data-layer.service';
import { AlliedModalComponent } from 'src/app/allied/components/allied-modal/allied-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AlliedService } from '@shared/services/allied.service';
import { TAGICON } from '@shared/data/allied/allied.data.json';

SwiperCore.use([Pagination]);

@Component({
  selector: 'yevo-allied-business',
  templateUrl: './allied-business.component.html',
  styleUrls: ['./allied-business.component.scss'],
})
export class AlliedBusinessComponent implements OnInit {
  @ViewChild('content') content: any;
  closeResult = '';

  configSwiper: SwiperOptions = {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 16,
    breakpoints: {
      992: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
    },
    pagination: {
      clickable: true,
    },
    mousewheel: true,
    keyboard: true,
  };

  dataAllied: Allied[] = DATA_ALLIED;
  topicOptionsDropdown: any = [];
  modalData!: any;
  mobileBreakShow: boolean = false;
  modalBoxActive = false;
  modalTransitionActive = false;
  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents',
    backdrop: 'static',
    keyboard: false,
  };
  tagsIcon = TAGICON;

  constructor(
    private layerService: DataLayerService,
    private modalService: NgbModal,
    public breakpointObserver: BreakpointObserver,
    private AlliedService: AlliedService
  ) {}

  ngOnInit(): void {
    this.viewPort();
    this.getPills();
  }

  getPills() {
    this.AlliedService.getCategory().subscribe((items: any) => {
      this.topicOptionsDropdown = items.map((value: any) => {
        return {
          label: value.name,
          value: value.code,
        };
      });
    });
  }

  openModal(value: any) {
    let tags = value.parameterIds.split(',').filter((item: any) => item != '39001');
    let nameTags = this.topicOptionsDropdown.filter((item: any) => {
      const code = item.value.toString();
      if (tags.includes(code)) return item.label;
    });
    this.layerService.clickCommunityInternal(5, 'Ver aliado',value.title);

    this.modalData = {
      title: value.modalFirstDescription,
      description: value.modalSecondDescription,
      catalogue: value.modalSecondButtonLabel || '',
      button: value.modalFirstButtonLabel || '',
      image: value.modalIconSrc,
      tags: nameTags,
      link: value.modalFirstButtonLink,
      download: value.modalSecondButtonLink,
      allied: value.name,
    };

    if (this.mobileBreakShow) {
      const modalRef = this.modalService.open(AlliedModalComponent);
      modalRef.componentInstance.details = this.modalData;
      modalRef.result
        .then((result) => {})
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.modalBoxActive = true;
      this.modalTransitionActive = true;
      this.deactivateScroll();
      this.modalService.open(this.content, this._modalOptionsNormal);
    }
  }

  openLink(url: string): void {
    window.open(url, '_blank');
  }

  optionSelectedBlockFinancing(option: string): void {
    this.layerService.clickCommunityInternal(5, 'Ver aliado',option);
  }

  closeModal() {
    this.modalTransitionActive = false;
    this.modalBoxActive = false;
    const t = setTimeout(() => {
      clearTimeout(t);
      this.activateScroll();
    }, 400);
    this.modalService.dismissAll();
  }

  activateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'auto';
  }

  deactivateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'hidden';
  }

  viewPort() {
    this.breakpointObserver.observe(['(min-width: 768px)']).subscribe((state: BreakpointState) => {
      if (state.breakpoints['(min-width: 768px)']) {
        this.mobileBreakShow = true;
      }
    });

    this.breakpointObserver
      .observe(['(min-width: 300px)', '(max-width: 750px)'])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints['(min-width: 300px)'] && state.breakpoints['(max-width: 750px)']) {
          this.mobileBreakShow = false;
        }
      });
  }
}

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CategoryTemp,
  LevelRequest,
  LevelResponse,
  LevelResult,
  Question,
  Questions,
  UserLevel,
} from '@shared/interfaces/frontend/communities';
import { CommunityService } from '@shared/services/community.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { UserProfile } from '@shared/models/UserProfileModel';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-modal-test-level',
  templateUrl: './modal-test-level.component.html',
  styleUrls: ['./modal-test-level.component.scss'],
})
export class ModalTestLevelComponent implements OnInit, OnChanges, OnDestroy {
  isWelcome = false;
  isShow = true;
  isStart1 = false;
  isStart2 = false;
  isStart3 = false;
  isStart4 = false;
  isStart5 = false;
  isLoading = false;
  isFinish = false;
  isPressed: boolean[] = [];
  questions: Question[] = [];
  peoples: Question[] = [];
  canals: Question[] = [];
  check: boolean[] = [];
  competitions: Question[] = [];
  categories: CategoryTemp[] = [];
  subscription: Subscription = new Subscription();
  activeButton = false;
  activeButtonFinish = false;
  titleButtonFinish = 'Continuar';
  levelRequest: LevelRequest = {
    idQuestion: 0,
    idAnswerQuestion1: 0,
    idAnswerQuestion2: 0,
  };
  optionsSelected = {
    optionQuestion1: 0,
    optionQuestion2: 0,
  };
  levelResult: LevelResult = {
    combinationId: 0,
    combinationName: '',
    result: 0,
  };
  levelResponse: LevelResponse = {
    idQuestion: 0,
    idAnswerQuestion1: 0,
    idAnswerQuestion2: 0,
    idLevel: 0,
  };
  messageLevel = '';
  messageSupport = '';
  userLevel: UserLevel = {
    userId: 0,
    idLevel: 0,
    flagStatus: 'A',
  };
  user!: UserProfile;
  loaderWidth = 80;
  @Input() sourcePage = '';
  @Input() communityId = 0;
  welcomeTestImage = '';
  community = '';
  questionsCommunity: Questions[] = [];
  constructor(
    public activeModal: NgbActiveModal,
    private communityService: CommunityService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.selectImages();
    this.selectServices();
    this.community = this.sourcePage === 'Emprende Mujer' ? 'Emprende Mujer' : 'de Bodegueros';
    this.getQuestionsByCommunityId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.sourcePage) {
      this.selectServices();
    }
    if (this.communityId) {
      this.getQuestionsByCommunityId();
    }
  }

  selectImages(): void {
    this.welcomeTestImage =
      this.sourcePage.toLowerCase() === 'bodegueros'
        ? '/assets/img/communities/test-welcome-bodegueros.png'
        : '/assets/img/communities/test-welcome.png';
  }

  selectServices(): void {
    if (this.sourcePage.toLowerCase() === 'bodegueros') {
      this.questions = this.communityService.getQuestionBodegueros();
      this.peoples = this.communityService.getQuestionPeopleBodegueros();
      this.canals = this.communityService.getQuestionCanalBodegueros();
      this.check = new Array(this.canals.length).fill(false);
      this.competitions = this.communityService.getQuestionCompetitionBodegueros();
      this.categories = this.communityService.getCategoryTempBodeguero();
      this.isPressed = new Array(this.categories.length).fill(false);
    } else {
      this.questions = this.communityService.getQuestion();
      this.peoples = this.communityService.getQuestionPeople();
      this.canals = this.communityService.getQuestionCanal();
      this.check = new Array(this.canals.length).fill(false);
      this.competitions = this.communityService.getQuestionCompetition();
      this.categories = this.communityService.getCategoryTemp();
      this.isPressed = new Array(this.categories.length).fill(false);
    }
  }
  getQuestionsByCommunityId(): void {
    this.isLoading = true;
    this.subscription.add(
      this.communityService.getQuestionsByCommunityId(this.communityId).subscribe(
        (response) => {
          this.questionsCommunity = response.map((question) => {
            let questionAux = question;
            questionAux.options = questionAux.options.sort((a, b) => a.optionId - b.optionId);
            return questionAux;
          });
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      )
    );
  }

  back(): void {
    if (this.isStart1) {
      this.isStart1 = false;
      this.isWelcome = false;
      this.isShow = true;
    } else if (this.isStart2) {
      this.isStart2 = false;
      this.isStart1 = true;
    } else if (this.isStart3) {
      this.isStart3 = false;
      this.isStart2 = true;
    } else if (this.isStart4) {
      this.isStart4 = false;
      this.isStart3 = true;
    } else if (this.isStart5) {
      this.isStart5 = false;
      this.isStart4 = true;
    }
  }

  startTest(): void {
    this.dataLayerService.clickCommunity(this.community, 'Iniciar', 'Seleccionar', 10);
    this.dataLayerService.clickCommunity(this.community, 'Iniciar', 'Paso 0', 10);
    this.isStart1 = true;
    this.isWelcome = true;
    this.isShow = false;
  }
  testLater(): void {
    this.dataLayerService.clickCommunity(this.community, 'En otro momento', 'Seleccionar', 10);
    this.dataLayerService.clickCommunity(this.community, 'En otro momento', 'Paso 0', 10);
  }

  startQuestion(index: number): void {
    if (this.isStart1) {
      this.isStart2 = true;
      this.isShow = false;
      this.isStart1 = false;
      this.levelRequest.idAnswerQuestion2 = this.questions[index].id;
      this.optionsSelected.optionQuestion1 = this.questionsCommunity[0].options[index].optionId;
      this.dataLayerService.clickCommunity(
        this.community,
        this.questionsCommunity[0].options[index].optionText,
        'Paso 1',
        10
      );
    } else if (this.isStart2) {
      this.isStart3 = true;
      this.isStart2 = false;
      this.levelRequest.idAnswerQuestion1 = this.peoples[index].id;
      this.levelRequest.idQuestion = 2;
      this.optionsSelected.optionQuestion2 = this.questionsCommunity[1].options[index].optionId;
      this.dataLayerService.clickCommunity(
        this.community,
        this.questionsCommunity[1].options[index].optionText,
        'Paso 2',
        10
      );
    } else if (this.isStart3 && this.activeButton) {
      this.isStart3 = false;
      this.isStart4 = true;
      let checkNames = '';
      for (let i = 0; i < this.canals.length; i++) {
        if (this.check[i]) {
          checkNames += this.questionsCommunity[2].options[i].optionText + ', ';
        }
      }
      this.dataLayerService.clickCommunity(
        this.community,
        checkNames.substring(0, checkNames.length - 2),
        'Paso 3',
        10
      );
    } else if (this.isStart4) {
      this.isStart5 = true;
      this.isStart4 = false;
      this.dataLayerService.clickCommunity(
        this.community,
        this.questionsCommunity[3].options[index].optionText,
        'Paso 4',
        10
      );
    } else if (this.isStart5 && this.activeButtonFinish) {
      this.stepFinish();
    }
  }

  private stepFinish(): void {
    this.isFinish = true;
    this.isStart5 = false;
    this.isLoading = true;
    let nameCategory = '';
    for (let i = 0; i < this.categories.length; i++) {
      if (this.isPressed[i]) {
        nameCategory += this.questionsCommunity[4].options[i].optionText + ', ';
      }
    }
    this.dataLayerService.clickCommunity(
      this.community,
      nameCategory.substring(0, nameCategory.length - 2),
      'Paso 5',
      10
    );
    this.getLevelQuestion();
  }

  checkInput(id: number): void {
    this.check[id] = !this.check[id];
    this.activeButton = this.check.some((item) => item);
  }

  checkButton(id: number): void {
    this.isPressed[id] = !this.isPressed[id];
    this.activeButtonFinish = this.isPressed.some((item) => item);
    if (this.activeButtonFinish) {
      this.titleButtonFinish = 'Finalizar';
    } else {
      this.titleButtonFinish = 'Continuar';
    }
  }

  private getLevelQuestion(): void {
    this.subscription.add(
      this.communityService
        .getLevelQuestionByCommunityId(this.optionsSelected.optionQuestion1, this.optionsSelected.optionQuestion2)
        .subscribe(
          (response) => {
            this.levelResult = response;
            if (this.levelResult.result) {
              this.dataLayerService.pushLevel(
                this.sourcePage === 'Emprende Mujer' ? 'nivelEmprende' : 'nivelBodegueros',
                'Nivel ' + this.levelResult.result
              );
              if (this.levelResult.result === 1) {
                this.messageLevel = 'Estás en el nivel 1: Arrancando el negocio.';
                this.messageSupport = 'Te brindaremos el apoyo necesario para concretar tu negocio.';
              } else if (this.levelResult.result === 2) {
                this.messageLevel = 'Estás en el nivel 2: Empezando la aventura';
                this.messageSupport = 'Nos enfocaremos en ayudarte para que tu negocio despegue.';
              } else if (this.levelResult.result === 3) {
                this.messageLevel = 'Estás en el nivel 3: Creciendo tu negocio';
                this.messageSupport = 'Te ayudaremos a potenciar tu marca y hacer crecer tu negocio.';
              } else if (this.levelResult.result === 4) {
                this.messageLevel = 'Estás en el nivel 4: Tu negocio al siguiente nivel';
                this.messageSupport = 'Te ayudaremos a consolidar tu marca y encontrar nuevas oportunidades.';
              }
            }
            this.saveUserLevel();
            this.isLoading = false;
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
          }
        )
    );
  }

  private saveUserLevel(): void {
    const userString = this.localStorageService.get('userProfile');
    if (userString) {
      this.user = JSON.parse(userString) as UserProfile;
      this.userLevel.userId = this.user.userId;
      this.userLevel.idLevel = this.levelResult.result;
      this.userLevel.communityId = this.communityId;
      this.subscription.add(
        this.communityService.saveLevelUser(this.userLevel).subscribe(
          (response) => {
            this.userLevel = response;
          },
          (error) => {
            console.error(error);
          }
        )
      );
    }
  }

  public dismiss(): void {
    this.dataLayerService.clickCommunity(this.community, 'En otro momento', 'Paso 0', 10);
    this.modalService.dismissAll();
  }

  open(content: any): void {
    this.isLoading = true;
    this.modalService.open(content, { centered: true });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'yevo-financing-preapproved-request',
  templateUrl: './financing-preapproved-request.component.html',
  styleUrls: ['./financing-preapproved-request.component.scss'],
})
export class FinancingPreapprovedRequestComponent {
  constructor() {}
}

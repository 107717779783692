<div class="top-banner _mobile" (click)="goToLink()">
    <div class="top-banner-left">
        <div class="container_">
            <div class="image-container">
                <img src="/assets/img/home/internal/dinner.svg" alt="dinner">
            </div>
            <div class="text-container">
                <h2 class="title">Calcula y pide tu préstamo,</h2>
                <p class="description">respaldado por Mibanco.</p>
            </div>
        </div>
    </div>
    <div class="top-banner-right">
        <button class="top-banner-right_button" >Ingresa aquí </button>
    </div>
</div>

<div class="_desktop">
    <div class="banner_desktop " (click)="goToLink()">
        <div class="banner_desktop_component">
            <div class="banner_desktop_component_image">
                <img class="banner_desktop_component_image_dinner" src="/assets/img/home/internal/dinner.svg" alt="dinner">
            </div>
            <div class="banner_desktop_component_title">Calcula y pide tu préstamo</div>
            <div class="banner_desktop_component_description">respaldado por Mibanco</div>
            <div class="banner_desktop_component_button">
                <button>Ingresa aquí </button>
            </div>
        </div>
    </div>
</div>
import { Component, EventEmitter, Input, Output} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'milab-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss']
})
export class HeaderV2Component {
  @Input() activedIcon: boolean = false;
  @Input() titleButon!:string;
  @Input() urlAction:string = '/';
  @Input() isExternal: boolean = false;
  @Output() actionEmitter = new EventEmitter<void>();
  constructor(private router: Router) { }

  goHome(){
    this.actionEmitter.emit();
    if(this.isExternal){
      window.open(this.urlAction, '_blank');
    }else{
      this.router.navigate([this.urlAction]);
    }
  }
}
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Course } from '@shared/interfaces/frontend';
import { DataLayerService } from '@shared/services/data-layer.service';
import { stripHtml } from '@shared/utils/strip-html.utils';

@Component({
  selector: 'yevo-training-section-bank',
  templateUrl: './training-section-bank.component.html',
  styleUrls: ['./training-section-bank.component.scss'],
})
export class TrainingSectionBankComponent implements OnInit {
  @Input() title: string = '';
  @Input() showAllLink: string[] = null!;
  @Input() code: any;
  @Input() tab: string = '';
  @Input() courses: Course[] = [];

  topic = 'course';

  coursesAux: Course[] = [];

  openModalSeidor: boolean = false;

  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  constructor(private router: Router, public dataLayerService: DataLayerService) {}

  ngOnInit(): void {
    if (this.code == 3004) {
      this.coursesAux.push(this.courses[0]);
      this.coursesAux.push(this.courses[1]);
    } else if (this.code == 3005) {
      this.coursesAux.push(this.courses[2]);
    } else if (this.code == 3007 || this.code == '') {
      this.coursesAux = this.courses;
    }

    this.formatTags();
  }

  formatTags() {
    this.courses.forEach((c: Course)=> {
      c.tags = c.thumbnailTags.split(',');
    })

    this.courses.reverse();
  }

  verCourse(course: Course) {
    this.dataLayerService.pushTrainingCourseEvent(course.title);
    this.router.navigate([`/cursos/${course.courseId}`], { queryParamsHandling: 'merge' });
  }

  getTextFromHtml(html: string): string {
    return stripHtml(html);
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'yevo-women-community',
  templateUrl: './women-community.component.html',
  styleUrls: ['./women-community.component.scss']
})
export class WomenCommunityComponent {
  constructor(private activatedRoute: ActivatedRoute) {
  }
}

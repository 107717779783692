<div class='modal' [ngClass]='{ activeBox: modalBoxActive, activeTransitions: modalTransitionActive }'>
  <div class='modal-layer' (click)='closePopup()'></div>
  <div class='modal-content2'>
    <div class='question-asked activeResponseSentModal'>
      <div class='close-button-box' style='text-align: right'>
        <a [routerLink]='' (click)='closePopup()'>
          <img src='/assets/icons/communities/close.svg' alt=''
          /></a>
      </div>
      <div class='success-answer'>
        ¡Tu pregunta ha sido<br class='line-break' />
        publicada!
      </div>
      <div class='m-auto d-flex'>
        <button type='button' class='button-2-modal' style='margin: 16px auto;' (click)='openQuestion()'>Ver pregunta
        </button>
      </div>
    </div>
  </div>
</div>

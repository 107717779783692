<oigo-loading></oigo-loading>

<div class="bg-top-banner" id="top-banner" (click)="goToLink()">
  <yevo-top-banner class="fixed-top" (loadAlert)="loadCalendar($event)" ></yevo-top-banner>
</div>

<div *ngIf="showWebinarBanner" class="mt-3">
  <yevo-live-webinar-banner [webinar]="webinarBannerData"></yevo-live-webinar-banner>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-12 pl-md-0 mb-md-3" *ngIf="!isLoadCalendar">
      <div class="mt-4 mt-sm-5 mt-md-4 mt-lg-4">
        <ngx-skeleton-loader
          *ngIf="!isLoadCalendar"
          [appearance]="'line'"
          count="1"
          animation="pulse"
          class="dashboard__title_skeleton"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          *ngIf="!isLoadCalendar"
          [appearance]="'line'"
          count="1"
          animation="pulse"
          class="dashboard__subTitle_skeleton"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="col-md-12 pl-md-0 mb-md-3 animate__animated animate__fadeInRight " *ngIf="isLoadCalendar">
      <h1 class="title-dashboard mt-4 mt-sm-5 mt-md-4 mt-lg-4  mb-lg-4 d-none d-md-block">
        <span>¡Hola, {{ (authService.userProfile$ | async)?.name || '' }}!</span> <br class="d-block d-md-none" />
        Mira todo lo que puedes hacer hoy
      </h1>
      <h1 class="title-dashboard mt-2 d-block d-md-none">
        <span>¡Hola, {{ (authService.userProfile$ | async)?.name || '' }}!</span>
      </h1>
      <h1 class="title-dashboard d-block d-md-none">Mira todo lo que puedes hacer hoy</h1>
    </div>
  </div>
</div>

<div id="launches">
  <yevo-home-launches [isExternal]="false" [releases]="recomendedJson"></yevo-home-launches>
</div>

<div class=" col-12 d-md-none scroll-center" (click)="scrollTo('home-card-benefits')" >
  <img class="image mg-01" src="/assets/img/home/btn_scroll.svg" alt="">
</div>

<div class="container animate__animated animate__fadeInRight">
  <yevo-home-card-benefits
  id="home-card-benefits"
  [title]="this.titleBenefits"
  [description]="this.descriptionBenefits"
  [icons]="this.icons"
  [client]="client">
 </yevo-home-card-benefits>
</div>

<div  id="swiper-benefit">
  <yevo-swiper-benefit [banners]="banners" [client]="client"></yevo-swiper-benefit>
</div>

<div class=" col-12 col-md-12 scroll-center mt-5" (click)="scrollTo('top-banner')">
  <img class="image mg-01 t-rotate" src="/assets/img/home/btn_scroll.svg" alt="">
  <div class="line"></div>
</div>
<div class="container">
  <yevo-home-app-mobil [isInternal]="true"></yevo-home-app-mobil>
</div>

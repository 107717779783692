import { Component, Input } from '@angular/core';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-financing-loan-simulator-banner',
  templateUrl: './financing-loan-simulator-banner.component.html',
  styleUrls: ['./financing-loan-simulator-banner.component.scss'],
})
export class FinancingLoanSimulatorBannerComponent {
  @Input() from: string = '';

  constructor(private dataLayerService: DataLayerService) {}

  sendClickEvent() {
    this.dataLayerService.clickSimulatorBanner(this.from);
  }
}

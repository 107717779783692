import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DataLayerService } from '@shared/services/data-layer.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import headerData from 'src/app/shared/data/header.data.json';
import { SolutionsNavModel } from 'src/app/shared/models';

const SolutionsNav: SolutionsNavModel[] = headerData.faqNav.sort((prev, curr) => prev.order - curr.order);

@Component({
  selector: 'yevo-header-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  styles: [
    `
      .dropdown-toggle::after {
        display: none !important;
      }
    `,
  ],
})
export class FaqComponent implements OnInit {
  @Input() title = '';
  @Input() asDropdown = true;
  @Output() navigate: EventEmitter<any> = new EventEmitter<any>();

  links = [{ routerLink: [''], title: '' }];
  isActive = false;
  isActiveSubMenu = false;
  @Input() servicesNavExpanded = false;
  servicesSubNavExpanded = false;
  solucionesNavExpanded = true;
  solutionsNav = SolutionsNav;
  subscription$ = new Subscription();
  titleBanner: string = '¿Tienes alguna duda?';
  routerLink: string = '/faq';

  constructor(private el: ElementRef, private router: Router, private dataLayerService: DataLayerService) {}

  get solucionesNavClosed(): boolean {
    return !this.solucionesNavExpanded;
  }
  set solucionesNavClosed(val) {
    this.solucionesNavExpanded = !val;
  }
  get servicesNavClosed(): boolean {
    return !this.servicesNavExpanded;
  }
  set servicesNavClosed(val) {
    this.servicesNavExpanded = !val;
  }

  closeSoluciones() {
    if (this.solucionesNavExpanded) this.solucionesNavExpanded = true;
  }

  ngOnInit() {
    this.subscription$.add(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        this.close();
        this.isActive = this.isRouterActive() || this.isSubRouterActive();
        this.isActiveSubMenu = this.isSubRouterActive();
      })
    );

    this.isActive = this.isRouterActive();
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  onNavigateHandler(event: any, options?: any) {
    this.navigate.emit(event);
  }

  trackByIndex(index: number, item: SolutionsNavModel): any {
    return index;
  }

  private isRouterActive(): any {
    return (
      this.links.length &&
      this.links.reduce(
        (res, link: { routerLink: string[] }) =>
          res || this.router.isActive(this.router.createUrlTree(link.routerLink), true),
        false
      )
    );
  }

  private isSubRouterActive(): any {
    return SolutionsNav.reduce(
      (res, link) => res || this.router.isActive(this.router.createUrlTree(link.routerLink), true),
      false
    );
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.close();
    }
  }
  @HostListener('window:resize', ['$event'])
  close(): void {
    this.closeSoluciones();
    if (this.servicesNavExpanded) this.servicesNavExpanded = false;
  }

  onHover(event: MouseEvent): void {
    this.servicesNavExpanded = true;
  }
  onHoverLeave(event: any): void {
    this.servicesNavExpanded = false;
  }

  navigateBank() {
    this.router.navigate(['/efectivo-semilla'], { queryParamsHandling: 'merge' });
  }
  closeSolution() {
    this.solucionesNavExpanded = !this.solucionesNavExpanded;
  }
  changeTitleBanner(title: string, router: string) {
    this.titleBanner = title;
    this.routerLink = router;
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'milab-footer-v2',
  templateUrl: './footer-v2.component.html',
  styleUrls: ['./footer-v2.component.scss']
})
export class FooterV2Component implements OnInit {
 
  @Input() activedTerms: boolean = false;
  @Input() activedCookie: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
    //Do nothing
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { REGULAR_PHRASE, WHATSAPP_COMMUNITY, creditOption, savingsAccountOption, trainYouOption } from '@shared/constants/communities.constants';
import { WhatsappOption } from '@shared/interfaces/frontend/communities';
import { Router } from '@angular/router';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-whatsapp-icon-floating',
  templateUrl: './whatsapp-icon-floating.component.html',
  styleUrls: ['./whatsapp-icon-floating.component.scss']
})
export class WhatsappIconFloatingComponent implements OnInit {
  showFloating = false;
  @Input() urlWhatsApp = '';
  @Input() source = '';

  commentsVersions: any[] = [
    WHATSAPP_COMMUNITY.firstVersionName,
    WHATSAPP_COMMUNITY.secondVersionName
  ];
  selectedVersions = '';
  randomIndex!: number;
  whatsappFloatingOptions: WhatsappOption[] = [];
  otherOptions = WHATSAPP_COMMUNITY.otherOptionsTitle;
  whyJoin = WHATSAPP_COMMUNITY.whyJoinTitle;
  firstVersionName = WHATSAPP_COMMUNITY.firstVersionName;
  community = '';
  communityCode!: number;
  titleButton = WHATSAPP_COMMUNITY.titleButton;
  utmSourceCommunity = '';
  whatsappContentTitle ='';

  constructor(private location: Location, private router: Router, private dataLayerService: DataLayerService) { }

  ngOnInit(): void {
    this.generateRandomIndex();
    this.cleanDescriptionInUrl();
    this.generateDescriptionInUrl();
    this.whatsappFloatingOptions = [creditOption, savingsAccountOption, trainYouOption];
    this.communityValues();
  }
  ngOnDestroy() {
    this.cleanDescriptionInUrl();
  }
  communityValues() {
    if (this.source === 'emprende-mujer') {
      this.community = 'Emprende Mujer';
      this.communityCode = WHATSAPP_COMMUNITY.womanCode;
      this.utmSourceCommunity = WHATSAPP_COMMUNITY.utmSourceWoman;
      this.whatsappContentTitle = WHATSAPP_COMMUNITY.whatsappWomanTitle;
    } else {
      this.community = 'de Bodegueros';
      this.communityCode = WHATSAPP_COMMUNITY.winemakersCode;
      this.utmSourceCommunity = WHATSAPP_COMMUNITY.utmSourceWinemakers;
      this.whatsappContentTitle = WHATSAPP_COMMUNITY.whatsappWinemakersTitle;
    }
  }

  showFloatingComponent() {
    if (!this.showFloating) {
      this.sendDataLayer('Seleccionar');
    }
    this.showFloating = !this.showFloating;
  }
  closeFloatingComponent() {
    this.showFloating = false;
  }
  generateDescriptionInUrl() {
    const url = this.location.path();
    const parameters = this.location.normalize(url).split('?')[1];
    if (parameters?.includes('descripcion=')) {
      const newURL = url.replace(REGULAR_PHRASE.description, REGULAR_PHRASE.firstValue);
      this.location.replaceState(newURL);
    } else {
      const separator = url.includes('?') ? '&' : '?';
      const newURL = `${url}${separator}descripcion=${this.selectedVersions}`;
      this.location.replaceState(newURL);
    }
  }
  cleanDescriptionInUrl() {
    const url = this.location.path();
    const newURL = url.replace(REGULAR_PHRASE.description, REGULAR_PHRASE.firstValue);
    this.location.replaceState(newURL);
  }
  generateRandomIndex() {
    const crypto = window.crypto;
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);
    this.randomIndex = array[0] % this.commentsVersions.length;
    this.selectedVersions = this.commentsVersions[0];
  }
  goToWhatsappCommunity(): void {
    this.sendDataLayer(this.titleButton)
    window.open(this.urlWhatsApp, '_blank');
  }

  goToRedirection(url: string, label: string): void {
    this.router.navigate([`${url}`], {
      queryParams: {
        utm_source: this.utmSourceCommunity,
        utm_medium: WHATSAPP_COMMUNITY.utmMedium,
        utm_campaign: WHATSAPP_COMMUNITY.utmCampaign,
      },
    });
    this.sendDataLayer(label);
  }

  sendDataLayer(label: string) {
    this.dataLayerService.clickCommunity(this.community, this.selectedVersions, label, this.communityCode);
  }
}
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import releaseJson  from '@shared/data/home/release.json';
import { CardLaunchesItem } from '@shared/interfaces/IDropdownItem';

// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Pagination, Navigation, Autoplay]);

@Component({
  selector: 'yevo-swiper-benefit',
  templateUrl: './swiper-benefit.component.html',
  styleUrls: ['./swiper-benefit.component.scss']
})
export class SwiperBenefitComponent implements OnInit {

  releases: CardLaunchesItem[] = releaseJson;
  @Input() banners: any = [];
  @Input() client!:boolean;

  page: number =1;
  loader :boolean = true;
  @ViewChild('swiperDashboard', { static: false }) swiperDashboard?: SwiperComponent;
  @ViewChild('swiperDashboardCourseDesktop', { static: false }) swiperDashboardCourseDesktop?: SwiperComponent;

  configSwiper: any = {
    pagination: {
      type: 'custom',
    },
    spaceBetween: 16,
    initialSlide: 0,
    centeredSlides: true,
    roundLengths: true,
    loop: false,
    loopAdditionalSlides: 30,
    speed: 400,
    autoplay: false,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 5,
      },
      425: {
        slidesPerView: 1,
        spaceBetween: 5,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 5,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1044: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
    },
    observer: true,
  };

  configSwiperDesktop: any = {
    loop: true,
    pagination: {
      clickable: true,
      type: 'custom',
    },
    navigation: false,
    spaceBetween: 16,
    slidesPerGroup: 1,
    initialSlide: 0,
    speed: 800,
    autoplay: {
      enabled: true,
      delay: 30000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
      425: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
      1044: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
    },
    observer: true,
  };

  constructor(private cdr: ChangeDetectorRef){}

  ngOnInit(): void {
    this.banners = this.client ? this.banners : this.banners.filter((image:any) => !image.client);
  }

  onSlideChange() {
    const currentIndex = this.swiperDashboard?.swiperRef.realIndex;
    this.page = (currentIndex ?? 0) + 1;
    this.cdr.detectChanges();
  }

  listNextMobile() {
    this.swiperDashboard?.swiperRef.slideNext(400);
    const currentIndex = this.swiperDashboard?.swiperRef.activeIndex; 
    this.page = (currentIndex ?? 0) + 1;
  }

  listPrevMobile() {
    this.swiperDashboard?.swiperRef.slidePrev(400);
    const currentIndex = this.swiperDashboard?.swiperRef.activeIndex; 
    this.page = (currentIndex ?? 0) + 1;
  }

  listNextDesktop() {
    this.swiperDashboardCourseDesktop?.swiperRef.slideNext(400);
  }

  listPrevDesktop() {
    this.swiperDashboardCourseDesktop?.swiperRef.slidePrev(400);
  }
}

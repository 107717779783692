import { Component, HostListener, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { URL_RECOMMENDED } from '@shared/constants/dashboard.constants';
import { VIRTUAL_EVENT_VALUES_RE } from '@shared/constants/recommend.constants';
import { DataLayerService } from '@shared/services/data-layer.service';
import { isSafariBrowser } from '@shared/utils/validators.utils';


@Component({
  selector: 'yevo-card-swiper-benefit',
  templateUrl: './card-swiper-benefit.component.html',
  styleUrls: ['./card-swiper-benefit.component.scss']
})
export class CardSwiperBenefitComponent implements OnInit {
@Input() banner: any;
mediaHeight: string = '325px';
screenWidth!: number;

constructor(private router: Router, private dataLayerService: DataLayerService) { }

  ngOnInit(): void {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenWidth = window.innerWidth;
  }

  getImageForDevice(item:any): string {
    this.getScreenSize();
    
    if (this.screenWidth < 760) {
      return item.mobile;
    } else if (this.screenWidth >= 760 && this.screenWidth < 1004) {
      return item.tablet;
    } else if (this.screenWidth >= 1004 && this.screenWidth < 1228) {
      return item.laptop;
    } else {
      return item.desktop;
    }
  }


  goToBanner(banner: any) {
    this.dataLayerBanner(banner);
    if (banner.external) {
      if (isSafariBrowser()) {
        window.location.href = banner.routerLink;
      } else {
        window.open(banner.routerLink, '_blank');
      }
    } else {
      this.router.navigate([banner.routerLink]);
    }
  }

  setMediaHeight() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 1439 && screenWidth > 575) {
      this.mediaHeight = '244px';
    } else if (screenWidth <= 575) {
      this.mediaHeight = '325px';
    }
  }

  dataLayerBanner(banner:any){
    if(banner.routerLink == URL_RECOMMENDED.url ){
      this.dataLayerService.virtualEventEnlaceRE(VIRTUAL_EVENT_VALUES_RE.selectCloseCardAndBannerRecommendProgramPage,
         VIRTUAL_EVENT_VALUES_RE.selectSectionBanner);
    }
  }
}


<div class="social-networks">
    <ng-container *ngFor="let item of socialNetworks">
        <div class="item" *ngIf="validResize(item)">
            <img class="image-social" 
                 [src]="item.icon"  
                 (click)="shareSocialNetworks(item.name)" 
                 (keydown)="handleKeyPress($event)" 
                 alt="{{ item.name }}">
            <label class="item-name" for="">{{ item.name }}</label>
        </div>
    </ng-container>
</div>
<ld-json-script></ld-json-script>

<div *ngIf="!showDetail" id="main" class="trainingMainBanner carousel-fade p-0 d-none d-lg-block">
  <ngb-carousel
    #carousel
    [interval]="interval"
    [pauseOnHover]="pauseOnHover"
    [pauseOnFocus]="pauseOnFocus"
    [showNavigationArrows]="false"
    [showNavigationIndicators]="false"
  >
    <ng-template ngbSlide *ngFor="let slide of carouselSlidesArray">
      <div class="carousel-item active theme-1">
        <div class="trainingListItems carousel-information mt-3">
          <br />
          <br />
          <h2 class="mt-4 mb-3">
            ¡Hola emprendedor, bienvenido a <br />
            nuestra sección de capacitaciones!
          </h2>
          <p>
            Aqui encontrarás información útil para tu negocio, podrás aprender a usar herramientas <br />
            digitales, promocionar tu negocio en internet, manejar mejor tus finanzas y mucho más
          </p>
          <div class="d-flex mt-4 justify-content-start"></div>
        </div>
        <div class="footer d-flex justify-content-end">
          <img
            class="images__person"
            src="/assets/img/intranet/training/training-banner-person.png"
            alt="slide theme"
          />
          <img
            class="images__icon1"
            [src]="'/assets/img/intranet/training/banner-' + theme + '/icon-1.png'"
            alt="slide theme"
          />
          <img
            class="images__icon2"
            [src]="'/assets/img/intranet/training/banner-' + theme + '/icon-2.png'"
            alt="slide theme"
          />
          <img
            class="images__icon3"
            [src]="'/assets/img/intranet/training/banner-' + theme + '/icon-3.png'"
            alt="slide theme"
          />
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>

<div class="trainingListContainer d-none d-lg-none">
  <div class="row">
    <div class="col-md-12 mt-4">
      <yevo-back-button [routerLink]="['/nuevo-intranet', 'dashboard']" queryParamsHandling="merge"></yevo-back-button>
    </div>
  </div>
</div>
<div *ngIf="!showDetail" class="trainingListContainer">
  <div class="trainingListItems div-menu-inside mt-md-4">
    <div class="overflow d-lg-block m-2 mb-5">
      <div class="breadcrumb">
        <yevo-breadcrumb [breadcrumbs]="breadcrumbs"></yevo-breadcrumb>
      </div>
    </div>
    <div class="text-title d-block d-lg-none mb-3">¿Qué tema quieres aprender hoy?</div>
    <div class="text-title d-none d-lg-block mt-4 mb-4">¿Qué tema quieres aprender hoy?</div>

    <div class="typeahead-container mb-3 w-100">
      <yevo-typeahead
        [placeholder]="'Escribe aquí el tema que quieres aprender'"
        [training]="true"
        [allData]="allDataSearch"
        [startSearchAt]="3"
        [webinarsMostVisited]="webinarsMostVisited"
        (selectItem)="trainingSelected($event)"
        (dataLayerEvent)="pushSearchResultEvent($event)"
        (searchValue)="searchValue($event)"
        (searchEvent)="pushSearchResultEvent($event)"
        (searchClear)="searchClear($event)"
        class="w-100"
      ></yevo-typeahead>
    </div>
    <div class="typeahead-container notFound w-100 mb-4" *ngIf="!resultSearch && isSearch">
      <p class="mb-0">
        Lo lamentamos. No encontramos un resultado que coincida con tu búsqueda. Vuelve a buscar o prueba con una
        palabra clave diferente.
      </p>
    </div>
    <div class="mb-0 mb-3 overflow d-none d-lg-block">
      <yevo-filter-pills
        [items]="topicOptionsDropdown"
        [showSliderPills]="true"
        [itemSelected]="selectedTopic"
        [showSeeMore]="false"
        [dropdownPlacement]="['bottom-right']"
        [scrollActivated]="true"
        dropdownTitle="Ver temas"
        [numberPillsShowed]="numberTopicOptionsShowed"
        [showJustifyContentBetween]="false"
        [withHandleOption]="false"
        (selectItem)="getTopicsSelected($event.item?.value || '', $event.item?.label || '')"
        class="pill-training"
      ></yevo-filter-pills>
    </div>

    <div class="overflow d-lg-none mb-3">
      <yevo-filter-pills
        [items]="topicOptionsDropdown"
        [showSliderPills]="true"
        [itemSelected]="selectedTopic"
        [showSeeMore]="false"
        [dropdownPlacement]="['bottom-right']"
        [scrollActivated]="true"
        dropdownTitle="Ver temas"
        [numberPillsShowed]="numberTopicOptionsShowed"
        [showJustifyContentBetween]="false"
        [withHandleOption]="false"
        (selectItem)="getTopicsSelected($event.item?.value || '', $event.item?.label || '')"
        class="pill-training"
      ></yevo-filter-pills>
    </div>
  </div>

  <div *ngIf="!showDetail" class="trainingListItems">
    <yevo-training-tabs
      [items]="tabItems"
      [selectedItem]="selectedTab"
      (selectedItemEmit)="handleSelectedTab($event)"
      class="taps-scroll"
    ></yevo-training-tabs>
  </div>

  <div class="trainingListItems mb_30_movil" *ngIf="!dataSearch.length && !isSearch">
    <div *ngIf="!showTopicContent && !showTabContent">
      <div class="mt-2 mt-lg-3">
        <yevo-training-section-list-v1
          title="Nuevos videos para tu negocio"
          [list]="topicsMostRecent"
          [topicNameSelected]="selectedTopic?.label"
          (clickTopic)="viewShowDetail($event, 'Lo más nuevo')"
        >
        </yevo-training-section-list-v1>
      </div>
    </div>
  </div>

  <div
    class="trainingListItems mb_30_movil"
    *ngIf="!dataSearch.length && !isSearch && !showDetail && !showTabContent && !showTopicContent"
    id="sectionDownload"
  >
    <div class="mt-2 mt-lg-3 mb-lg-5">
      <yevo-training-downloads></yevo-training-downloads>
    </div>
  </div>
</div>

<div *ngIf="!isSearch">
  <div
    *ngIf="
      (!dataSearch.length && !showDetail && !showTabContent) ||
      (!dataSearch.length && !showDetail && showTabContent && selectedTab.label == 'Cursos')
    "
    class="spacer mt-4"
  >
    <div class="trainingListItems" *ngIf="selectedTab?.label == 'Cursos'">
      <div class="mt-lg-3">
        <yevo-training-section-bank
          title="Cursos"
          [code]="codeSelected"
          [tab]="selectedTab.label"
          [courses]="allCourses"
        ></yevo-training-section-bank>
      </div>
    </div>

    <div *ngIf="!showDetail && !showTabContent && !showTopicContent">
      <yevo-training-webinars
        [topics]="firsWebinars"
        (clickTopic)="viewShowDetail($event, 'Webinar')"
        (clickAllTopics)="handleSelectedTab($event)"
      ></yevo-training-webinars>
    </div>
  </div>

  <div class="trainingListContainer" *ngIf="!dataSearch.length && !showDetail && !showTabContent && !showTopicContent">
    <div class="trainingListItems mb_32">
      <div>
        <div class="mb_32 mt-3 mt-md-5">
          <yevo-training-section-list-v1
            title="Los videos más vistos"
            [list]="topicsMostViewed"
            (clickTopic)="viewShowDetail($event, 'Lo más visto')"
            [showAllLink]="['/capacitaciones', 'list']"
          >
          </yevo-training-section-list-v1>
        </div>
      </div>
    </div>
  </div>

  <div class="mt_24" *ngIf="!dataSearch.length && !showDetail && !showTabContent && !showTopicContent">
    <yevo-training-virtual-courses
      [seletecTab]="selectedTab"
      [allCourses]="allCourses"
      (goAllCourses)="handleSelectedTab($event)"
    >
    </yevo-training-virtual-courses>
  </div>
  <!-- Aquí los cursos-->
  <div
    *ngIf="dataSearch.length === 0 && !showDetail && showTopicContent && !showTabContent"
    class="trainingListContainer"
  >
    <div class="trainingListItems">
      <div *ngIf="topics && topics.length > 0 && (selectedTab == undefined || selectedTab.label != 'Cursos')">
        <h2 class="text-title title-topic mt-0">{{ selectedTopic?.label }}</h2>
        <div class="row card-webinars">
          <yevo-training-card-v1
            [topic]="topic"
            [cardTitle]="topic.title"
            (clickTopic)="viewShowDetail($event)"
            *ngFor="let topic of topics"
            class="mb-3"
          ></yevo-training-card-v1>
        </div>
      </div>
      <div
        class="w-full centered"
        *ngIf="topics && topics.length == 0 && (selectedTab == undefined || selectedTab.label != 'Cursos')"
      >
        <br /><br />
        <img
          src="/assets/img/intranet/training/no-filter-option.png"
          alt="Por el momento no contamos con contenido disponible"
        />
        <br /><br />
        <p class="titlewarning">¡Oh no! Por el momento no contamos con contenido disponible para esta sección.</p>
        <br />
        <p class="subtitlewarning">Nos encontramos trabajando para asesorarte con nuevos contenidos.</p>
      </div>
      <div class="w-full box-page trainingPagination d-flex justify-content-center mb-5">
        <yevo-pagination-v2
          [page]="page"
          [dataSize]="total"
          [pageSize]="pageSize"
          (pageChange)="pageChangeEvent($event)"
        ></yevo-pagination-v2>
      </div>
    </div>
  </div>

  <!-- Aquí los videos-->
  <div *ngIf="!dataSearch.length && !showDetail && showTabContent" class="trainingListContainer mt-0 mt-md-4">
    <div class="trainingListItems">
      <h2 class="text-title title-topic mt-0 mb-3 mb-md-5" *ngIf="topics && topics.length">
        {{ selectedTab.label }}
      </h2>
      <div class="row card-webinars">
        <div *ngFor="let topic of topics" class="mb-3">
          <yevo-training-card
            [topic]="topic"
            (clickTopic)="viewShowDetail($event)"
            *ngIf="topic.type == 'html' || topic.type == 'article'"
          ></yevo-training-card>
          <yevo-training-card-v1
            *ngIf="topic.type == 'video'"
            [topic]="topic"
            [cardTitle]="topic.title"
            (clickTopic)="viewShowDetail($event)"
          ></yevo-training-card-v1>
          <yevo-training-card-v1
            *ngIf="topic.type == 'webinar'"
            [topic]="topic"
            [cardTitle]="topic.title"
            (clickTopic)="viewShowDetail($event)"
          ></yevo-training-card-v1>
        </div>
      </div>

      <div class="w-full centered" *ngIf="topics && topics.length == 0 && selectedTab.label != 'Cursos'">
        <br /><br />
        <img
          src="/assets/img/intranet/training/no-filter-option.png"
          alt="Por el momento no contamos con contenido disponible"
        />
        <br /><br />
        <p class="titlewarning">¡Oh no! Por el momento no contamos con contenido disponible para esta sección.</p>
        <br />
        <p class="subtitlewarning">Nos encontramos trabajando para asesorarte con nuevos contenidos.</p>
      </div>

      <div class="w-full box-page trainingPagination d-flex justify-content-center mb-5">
        <yevo-pagination-v2
          [page]="page"
          [dataSize]="total"
          [pageSize]="pageSize"
          (pageChange)="pageChangeEvent($event)"
        ></yevo-pagination-v2>
      </div>
    </div>
  </div>
</div>

<div *ngIf="dataSearch.length && !isSearch" class="trainingListContainer mt-0 mt-md-4">
  <div class="trainingListItems">
    <div class="row card-search mt-4 mt-md-0">
      <div *ngFor="let topic of dataSearch" class="mb-3">
        <yevo-training-card
          [topic]="topic"
          (clickTopic)="viewShowDetail($event)"
          *ngIf="topic.type == 'html' || topic.type == 'article'"
        ></yevo-training-card>

        <yevo-training-card-v1
          *ngIf="topic.type == 'video' || topic.type == 'dir' || topic.type == 'webinar'"
          [topic]="topic"
          [cardTitle]="topic.title"
          (clickTopic)="viewShowDetail($event)"
        ></yevo-training-card-v1>

        <yevo-training-course *ngIf="topic.type == 'course'" [courses]="topic"> </yevo-training-course>
      </div>
    </div>

    <div class="w-full box-page trainingPagination d-flex justify-content-center mb-5">
      <yevo-pagination-v2
        class="trainingPagination-pagination"
        [page]="pageSearch"
        [dataSize]="auxDataSearch.length"
        [pageSize]="pageSize"
        (pageChange)="pageChangeEventSearch($event)"
      ></yevo-pagination-v2>
    </div>
  </div>
</div>

<div class="trainingListContainer" *ngIf="!resultSearch && isSearch">
  <div class="trainingListItems mb_32">
    <div>
      <div class="mb_32 mt-3 mt-md-5">
        <yevo-training-section-list-v1
          title="Los videos más vistos"
          [list]="topicsMostViewed"
          (clickTopic)="viewShowDetail($event, 'Lo más visto')"
          [showAllLink]="['/capacitaciones', 'list']"
        >
        </yevo-training-section-list-v1>
      </div>
    </div>
  </div>
</div>

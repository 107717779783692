import { Component, OnInit, OnChanges, Output, EventEmitter, Input, Injectable, SimpleChanges } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomDatepickerService, I18n } from '@shared/services/datepicker.service';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? (date.day < 10 ? '0' + date.day : date.day) +
          this.DELIMITER +
          (date.month < 10 ? '0' + date.month : date.month) +
          this.DELIMITER +
          date.year
      : '';
  }
}

@Component({
  selector: 'yevo-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerService },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class DatepickerComponent implements OnInit, OnChanges {
  @Input() label: string = '';
  @Input() icon: string = 'calendar';
  @Input() showLabel: boolean = true;
  @Input() showManualError: boolean = false;
  @Input() placeholder!: string;
  @Input() obligatory: boolean = false;
  @Input() validateFullAge: boolean = false;
  @Input() startDate: NgbDate = new NgbDate(1990, 1, 1);
  @Input() minDate: NgbDate = this.setDeafultMinYear();
  @Input() maxDate: NgbDate = this.setDeafultMaxYear();
  @Input() dateSelected!: NgbDate;
  @Input() isDisabled: boolean = false;
  @Output() selectDate = new EventEmitter<string | null>();

  formaDate!: FormGroup;

  constructor() {
    this.initForm();
  }

  private setDeafultMaxYear() {
    return new NgbDate(new Date().getUTCFullYear(), new Date().getMonth() + 1, new Date().getDate());
  }

  private setDeafultMinYear() {
    // MINIMUN YEAR CAN'T BE LESS THAN 100 YEARS FROM TODAY
    return new NgbDate(new Date().getUTCFullYear() - 100, 12, 31);
  }

  private initForm() {
    this.formaDate = new FormGroup({
      date: new FormControl(null, this.obligatory ? [Validators.required] : [Validators.nullValidator]),
    });
  }

  ngOnInit(): void {
    if (this.validateFullAge) this.fullAgeValidation();

    this.formaDate.get('date')?.valueChanges.subscribe((resp) => {
      this.startDate = new NgbDate(resp.year, resp.month, resp.day);
      this.selectDate.emit(
        `${resp.year}-${resp.month.toString().length < 2 ? '0' + resp.month : resp.month}-${
          resp.day.toString().length < 2 ? '0' + resp.day : resp.day
        }`
      );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dateSelected?.currentValue) {
      this.formaDate.get('date')?.setValue(this.dateSelected);
      this.startDate = this.dateSelected;
    }
  }

  fullAgeValidation() {
    let currentDate = new Date();
    this.maxDate = new NgbDate(currentDate.getFullYear() - 18, currentDate.getMonth() + 1, currentDate.getDate());
  }

  public getErrorsFromField(field: string): any {
    return this.formaDate.get(field)?.errors;
  }

  public showError(field: string, dirty: boolean = false): boolean | undefined {
    const control = this.formaDate.get(field)!;
    return dirty ? control.invalid && (control.dirty || control.touched) : control.invalid && control.touched;
  }
}

import { DirectoryGeneralInfo } from '@shared/models/DirectoryModel';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { originalOrder } from 'src/app/shared/utils/pipe.utils';
import { KeyValue } from '@angular/common';
import { IPaymentMethodInfo } from '@shared/interfaces';

@Component({
  selector: 'yevo-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
})
export class ProductInfoComponent implements OnInit {
  @Input() paymentMethods!: Map<string, number>;
  @Input() categories!: Map<string, number>;
  @Input() generalInfo?: DirectoryGeneralInfo;
  @Input() errorCode?: string;
  @Input() errorMsgType?: string;
  @Output() stateAfterFieldBlur = new EventEmitter();
  frmProductInfo!: FormGroup;
  hasOtherPayment = false;
  codeOther = 1005;
  descriptionOther = '';
  originalOrder = originalOrder;

  paymentMethodAditionalInfo: IPaymentMethodInfo[] = [
    { value: 1001, key: 'Efectivo', icon: 'cash-icon', iconWidth: '20px', isSvg: true },
    { value: 1002, key: 'Tarjetas', icon: 'card-icon', iconWidth: '20px', isSvg: true },
    { value: 1003, key: 'Yape', icon: 'yape.png', iconWidth: '3rem', isSvg: false },
    { value: 1004, key: 'Plin', icon: 'plin.png', iconWidth: '3rem', isSvg: false },
    { value: 1005, key: 'Otros', icon: 'dollar', iconWidth: '23px', isSvg: true },
  ];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
    this.subscribeFormChanges();
    this.loadData();
    setTimeout(() => {
      const value = this.descriptionOther !== '' ? this.descriptionOther : '-';
      this.frmProductInfo.get('otherPaymentMethod')?.patchValue(value);
    });
  }

  private subscribeFormChanges() {
    this.frmProductInfo.valueChanges.subscribe((formValue) => {
      this.stateAfterFieldBlur.emit(formValue);
    });
  }

  onPaymentMethodCheckChanged(event: any): void {
    if (event.target.defaultValue == this.codeOther) {
      const valuePm = event.target.checked ? this.descriptionOther : '-';
      this.frmProductInfo.get('otherPaymentMethod')?.patchValue(valuePm);
    }
    const { checked, value } = event.target;
    this.updateFormArray(this.paymentMethodsFormArray, value, checked);
  }

  onCategoryCheckChanged(event: any): void {
    const { checked, value } = event.target;
    this.updateFormArray(this.categoriesFormArray, value, checked);
  }

  isChecked(value: string | number, field: 'categories' | 'paymentMethods'): boolean {
    const checkedValues = this.frmProductInfo.get(field) as FormArray;
    return checkedValues.value.find((checkedValue: any) => checkedValue.value == value);
  }

  maxSelected(value: string | number) {
    return this.categoriesFormArray.length > 2 && !this.isChecked(value, 'categories');
  }

  showError(field: string): boolean | undefined {
    const control = this.frmProductInfo.get(field);
    return control?.touched && !control.valid;
  }

  isDeliveryValid(): boolean {
    const homeControl = this.frmProductInfo.get('homeDelivery');
    const pickupControl = this.frmProductInfo.get('pickUpSalePoint');
    return homeControl?.value || pickupControl?.value;
  }

  showTextOption() {
    const checkedValues = this.frmProductInfo.get('paymentMethods') as FormArray;
    this.hasOtherPayment = checkedValues.value.find((checkedValue: any) => checkedValue.value == this.codeOther);
    return this.hasOtherPayment;
  }

  private get paymentMethodsFormArray(): FormArray {
    return this.frmProductInfo.get('paymentMethods') as FormArray;
  }

  private get categoriesFormArray(): FormArray {
    return this.frmProductInfo.get('categories') as FormArray;
  }

  private buildForm(): void {
    this.frmProductInfo = this.fb.group(
      {
        productDescription: [null, [Validators.required, Validators.maxLength(350)]],
        pickUpSalePoint: [false, Validators.required],
        homeDelivery: [false, Validators.required],
        paymentMethods: this.fb.array([], Validators.required),
        categories: this.fb.array([], Validators.required),
        otherPaymentMethod: [null, Validators.required],
      },
      { updateOn: 'blur' }
    );
  }

  private loadData(): void {
    if (this.generalInfo) {
      this.frmProductInfo.patchValue(this.generalInfo);
      this.generalInfo.paymentMethods?.forEach((paymentMethod) => {
        if (paymentMethod.value === this.codeOther) {
          this.descriptionOther = paymentMethod.otherDescription ?? '';
        }
        this.updateFormArray(this.paymentMethodsFormArray, paymentMethod.value, true);
      });
      this.generalInfo.categories?.forEach((category) => {
        this.updateFormArray(this.categoriesFormArray, category.value, true);
      });
    }
  }

  private updateFormArray(formArray: FormArray, value: string | number, add: boolean) {
    if (add) {
      formArray.push(this.fb.group({ value }));
    } else {
      let i: number = 0;

      formArray.controls.forEach((ctrl: AbstractControl) => {
        if (ctrl.value.value == value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  avoidEmpty(event: any, key: string) {
    if (this.frmProductInfo.controls[key].value === ' ') {
      this.frmProductInfo.controls[key].patchValue(null);
      this.frmProductInfo.controls[key].markAsTouched();
    }
  }

  getAditionalInfo(paymentMethod: KeyValue<string, number>): IPaymentMethodInfo | undefined {
    return this.paymentMethodAditionalInfo.find((value) => value.value == paymentMethod.value);
  }
}

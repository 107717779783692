import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IUploadImageProgress } from '@shared/interfaces/frontend/ui-components/progress-bar';
import { FinancingService } from '@shared/services/financing.service';

@Component({
  selector: 'yevo-selfie',
  templateUrl: './selfie.component.html',
  styleUrls: ['./selfie.component.scss'],
})
export class SelfieComponent implements OnInit {
  @Output() selfie = new EventEmitter<any>();
  @Output() showDocument = new EventEmitter<any>();
  @Output() uploadDocument = new EventEmitter<any>();
  @Input() uploadSelfie: any;
  @Input() progressObject: IUploadImageProgress = {};
  @Input() progress: number = 0;
  @Input() selfieId: number = 0;

  error: boolean = false;

  video: any;
  canvas: any;
  image: any;
  compressedImage: any;
  show: boolean = false;
  load: boolean = false;
  isLoading: boolean = false;

  CANVAS_INIT_WIDTH: number = 320;
  CANVAS_INIT_HEIGHT: number = 320;
  RESIZING_FACTOR: number = 1;
  QUALITY_FACTOR: number = 1;

  constructor(@Inject(DOCUMENT) public document: Document, public financingService: FinancingService) {}

  ngOnInit(): void {
    this.video = document.querySelector('video');
    this.video.setAttribute('playsinline', '');
    this.video.setAttribute('autoplay', '');
    this.video.setAttribute('muted', '');
    this.canvas = document.querySelector('canvas');
    this.compressedImage = document.querySelector('#compressedImage');

    if (this.financingService.uploadedSelfieId && this.financingService.uploadedSelfieId != 0) {
      this.selfieId = this.financingService.uploadedSelfieId;
      if (this.financingService.uploadedSelfie) this.compressedImage = this.financingService.uploadedSelfie;
    } else {
      this.uploadSelfie = false;
      this.image = '';
      this.load = false;
      this.show = false;
      this.error = false;
    }

    if (this.uploadSelfie) {
      this.load = true;
      this.show = false;
      this.error = false;
    }
  }

  camera() {
    this.error = false;
    this.load = false;
    this.show = true;
    this.showDocument.emit(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((mediaStream: any) => {
        this.error = false;
        this.financingService.showSelfieError = false;
        if ('srcObject' in this.video) {
          this.video.srcObject = mediaStream;
        } else {
          this.video.src = window.URL.createObjectURL(mediaStream);
        }
        this.video.onloadedmetadata = (e: any) => {
          this.video.play();
        };
      })
      .catch((err) => {
        if (err.message === 'Permission denied') {
          alert('Unable to access camera: ');
          this.error = true;
          this.show = false;
        }
      });
  }

  takePhoto() {
    const context: any = this.canvas.getContext('2d');
    this.canvas.width = this.CANVAS_INIT_WIDTH * this.RESIZING_FACTOR;
    this.canvas.height = this.CANVAS_INIT_HEIGHT * this.RESIZING_FACTOR;

    context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    this.image = this.canvas.toDataURL('image/png', this.QUALITY_FACTOR);
    this.compressedImage = this.image;

    this.financingService.uploadedSelfie = this.compressedImage;

    const selfie = { files: [{ value: this.image.split(',')[1], type: 'selfie' }] };
    this.isLoading = true;
    this.load = false;
    this.error = false;
    this.show = false;
    this.financingService.postPreapproveFiles(selfie, this.financingService.solicitudeId).subscribe((resp) => {
      this.selfieId = resp?.body?.files[0]?.solicitudeFileId;
      this.selfie.emit(this.image.split(',')[1]);

      if (this.selfieId) {
        this.financingService.uploadedSelfieId = this.selfieId;
        this.isLoading = false;
        this.load = true;
      } else {
        this.isLoading = false;
        this.load = false;
        this.error = true;
        this.selfie.emit(null);
        this.financingService.uploadedSelfie = '';
        this.financingService.uploadedSelfieId = 0;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.progress.currentValue == 100) {
      this.uploadDocument.emit(true);
      this.isLoading = false;
      this.load = true;
    }
  }

  stopVideo() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
        this.video.srcObject = null;
      });
    }
  }

  close() {
    this.error = false;
  }

  deleteSelfie() {
    this.financingService.deleteImage(this.selfieId).subscribe((resp) => {
      this.load = false;
      this.error = false;
      this.selfie.emit(null);
      this.financingService.uploadedSelfie = '';
      this.financingService.uploadedSelfieId = 0;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { FinancingService } from '@shared/services/financing.service';
import { TYPES_CARD_REQUEST } from '@shared/constants/financing.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'yevo-financing-request-form',
  templateUrl: './financing-request-form.component.html',
  styleUrls: ['./financing-request-form.component.scss'],
})
export class FinancingRequestFormComponent implements OnInit {
  public cardEvaluation!: boolean;
  public aliado: boolean = false;

  constructor(public financingService: FinancingService, private route: ActivatedRoute, public router: Router) {
    if (this.route.snapshot.queryParamMap.get('aliado') === 'alicorp') {
      this.aliado = true;
    }
    const state = window.history?.state;
    if (state?.alliedWasAlicorp) {
      this.aliado = state?.alliedWasAlicorp;
    }
  }

  ngOnInit(): void {
    this.cardEvaluation = this.financingService.typeCardSelected === TYPES_CARD_REQUEST.EVALUATION ? true : false;
  }
}

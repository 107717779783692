import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { FinancingService } from '@shared/services/financing.service';
import { LocalStorageService } from '@shared/services/local-storage.service';

@Component({
  selector: 'yevo-general-message-digital-aproved',
  templateUrl: './general-message-digital-aproved.component.html',
  styleUrls: ['./general-message-digital-aproved.component.scss'],
})
export class GeneralMessageDigitalAprovedComponent implements OnInit {
  isLogged: boolean = false;

  breadcrumbs: any;
  breadcrumbRoute: string[] = [];

  name: string = '';
  user!: any;
  username: string = '';

  constructor(
    private router: Router,
    private financingService: FinancingService,
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {
    this.initializeUser();
    this.setBreadcrumbRoute();
    this.setBreadcrumbs();
  }

  ngOnInit(): void {
    this.getUsername();
  }

  private initializeUser(): void {
    const userProfile = this.localStorageService.get('userProfile');
    if (userProfile) {
      this.user = JSON.parse(userProfile);
    }
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLogged = true;
      }
    });
  }

  private setBreadcrumbRoute(): void {
    this.breadcrumbRoute = this.isLogged
      ? ['/financiamiento', 'filtro-financiamiento']
      : ['/financiamiento', 'solicitud'];
  }

  private setBreadcrumbs(): void {
    const state = window.history.state;
    if (state.isFinancingAprobado) {
      this.breadcrumbs = [
        { pageName: 'Financiamiento', url: this.breadcrumbRoute, active: true },
        { pageName: 'Aprobado digital', url: null, active: false },
      ];
    } else if (state.isFinancingFirstCredit) {
      this.breadcrumbs = [
        { pageName: 'Financiamiento', url: this.breadcrumbRoute, active: true },
        { pageName: 'Crédito aprobado', url: null, active: false },
      ];
    } else {
      this.router.navigate(['/']);
    }
  }

  private getUsername(): void {
    this.name = this.financingService.sucessFinancingSolicitudeRegister?.name;
    if (this.isLogged) {
      this.username = this.name || this.user.name || 'Hola';
    } else if (!this.username) {
      this.username = this.financingService.sucessFinancingSolicitudeRegister?.name || 'Hola';
    }
  }

  public goToHome(): void {
    const route = this.isLogged ? ['/nuevo-intranet', 'dashboard'] : ['/'];
    this.router.navigate(route, { queryParamsHandling: 'merge' });
  }
}

<div class="levels-card-content">
    <div class="levels-card-content-header">
        <div class="content-image">
            <img [src]="this.levels.icon" alt="Imagen" class="medal-image">
        </div>
        <div class="content-title">
        <p class="levels-card-content-header-title">{{this.levels.level}}</p>
        <p class="levels-card-content-header-description">{{this.levels.description}}</p>
        </div>
    </div>
    <div *ngFor="let item of this.levels.benefits; let i = index" class="levels-card-content-info">
       <div  [ngClass]="{ 'c-broze': this.levels.color ==='broze',
       'c-silver': this.levels.color ==='silver',
       'c-gold': this.levels.color ==='gold'  }" class="levels-card-content-info-title">{{item.title}}</div>
       <div class="levels-card-content-info-description">{{item.description}}</div>
    </div>
</div>
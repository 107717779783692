<div class='modal' [ngClass]='{ activeBox: modalBoxActive, activeTransitions: modalTransitionActive }'>
  <div class='modal-layer' (click)='closePopup()'></div>
  <div class='modal-content'>
    <div class='response-sent activeResponseSentModal' style='padding: 0'>
      <div class='close-button-box' style='text-align: right'>
        <a [routerLink]='' (click)='closePopup()'>
          <img src='/assets/icons/communities/close.svg' alt='' style="padding-right:21px"
          /></a>
      </div>
      <div class='success-answer'>
        ¡Tu respuesta ha sido<br class='line-break' />
        publicada!
      </div>
      <div class='m-auto d-flex'>
        <button type='button' class='button-2-modal' style='margin: 16px auto 70px;' (click)='openQuestion()'>Ver respuesta
        </button>
      </div>
    </div>
  </div>
</div>

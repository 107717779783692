<nav
  id="#yevoHeader"
  class="navbar navbar-expand-lg menu-header-green navbar-light d-flex align-items-end over-header"
  [ngClass]="{
    'shadow-none': !shadow,
    'height-mobile-expanded': showLogoMobile && !responsiveMenuClosed,
    'isDashboard-menu': isDashboard,
    'height-mobile-expanded-login': showLogoMobile && !responsiveMenuClosed && isLOgged
  }"
  (click)="closeResponsiveMenu()"
>
  <div
    *ngIf="isBrowser"
    [ngClass]="{
      container: !fluid,
      'container-fluid menu-mobile': fluid
    }"
    (mouseleave)="closedDesktopMenuTop($event)"
  >
    <yevo-header-brand-hamburger
      [open]="!responsiveMenuClosed"
      (clickMenu)="collapse.toggle()"
      [brandLink]="navigate"
      [brandImage]="responsiveMenuClosed ? '/assets/img/logo_negativo.png' : '/assets/img/new-logo-mobile.svg'"
      [showLogoDesktop]="showLogoDesktop"
      [showLogoMobile]="showLogoMobile"
      [mobileButtonRight]="mobileButtonRight"
      [ngClass]="{
        'spacing-mobile': showLogoMobile,
        'spacing-desktop': showLogoDesktop,
        'img-negative': responsiveMenuClosed
      }"
      class="mb-2 mt-2"
      (mouseenter)="leaveMenuClose(); isActiveTrainingPageMenu()"
      (mouseleave)="isActiveTrainingPageMenu()"
    >
    </yevo-header-brand-hamburger>

    <a
      (click)="goToUrlInternal('/')"
      class="side-nav__logo-link d-none d-lg-block"
      *ngIf="isLOgged && dashboard"
      (mouseenter)="leaveMenuClose()"
    >
      <img src="/assets/img/logo_negativo.png" alt="Yevo" class="img-logo-yevo" />
    </a>

    <div
      class="collapse navbar-collapse"
      [ngClass]="isDashboard ? '' : 'navbarNav-desktop'"
      id="navbarNav"
      [(ngbCollapse)]="responsiveMenuClosed"
      #collapse="ngbCollapse"
    >
      <!-- DESIGN DESKTOP MENU -->
      <div class="d-none d-lg-block">
        <ul class="navbar-nav" *ngIf="links?.desktop?.length" [ngClass]="isDashboard ? 'navbar-nav-dashboard' : ''">
          <!-- DESIGN DESKTOP MENU NEW -->
          <ng-container *ngFor="let link of links.desktop; let i = index">
            <li
              [class]="menuActiveStyles(i)"
              routerLinkActive="active"
              [id]="'linkMenu' + i"
              (mouseenter)="onHover(link); onHoverActive(link, i)"
              (mouseleave)="!link.user && onLeaveHoverActive(link, i)"
              class="d-flex"
            >
              <ng-template [ngIf]="!link.services && !link.faq">
                <!-- desktop -->
                <ng-template [ngIf]="link.expanded && !link.expandedDeactivate">
                  <a
                    class="nav-link d-none d-lg-block link-menu-desktop"
                    queryParamsHandling="merge"
                    data-target=".navbar-collapse.show"
                    (mouseover)="over(link)"
                  >
                    {{ link.title }}
                  </a>
                </ng-template>

                <ng-template [ngIf]="link.expanded && link.expandedDeactivate">
                  <a
                    class="nav-link d-none d-lg-block link-menu-desktop"
                    queryParamsHandling="merge"
                    data-target=".navbar-collapse.show"
                    [routerLink]="link.routerLink"
                  >
                    {{ link.title }}
                  </a>
                </ng-template>
              </ng-template>

              <ng-template [ngIf]="!link.expanded && link.user">
                <a
                  class="nav-link d-none d-lg-block link-menu-desktop"
                  queryParamsHandling="merge"
                  data-target=".navbar-collapse.show"
                  (click)="handleLinkClick($event)"
                  (mouseenter)="overUser()"
                >
                  {{ link.title }}
                </a>
              </ng-template>

              <!-- MENU CONTACT AND FQA DESKTOP-->
              <ng-template [ngIf]="!link.expanded && !link.user">
                <a
                  class="nav-link d-none d-lg-block link-menu-desktop"
                  queryParamsHandling="merge"
                  data-target=".navbar-collapse.show"
                  [routerLink]="link.routerLink"
                  (click)="handleLinkClick($event)"
                >
                  {{ link.title }}
                </a>
              </ng-template>

              <ng-template [ngIf]="link.services">
                <!-- desktop -->
                <yevo-header-services
                  [title]="link.title"
                  [asDropdown]="true"
                  [servicesNavExpanded]="showServices"
                  class="d-none d-lg-block link-menu"
                >
                </yevo-header-services>
                <!-- mobile -->
                <yevo-header-services
                  [title]="link.title"
                  [asDropdown]="false"
                  class="d-lg-none"
                ></yevo-header-services>
              </ng-template>

              <ng-template [ngIf]="link.faq">
                <!-- desktop -->
                <yevo-header-faq
                  [title]="link.title"
                  [asDropdown]="true"
                  [servicesNavExpanded]="showFaqs"
                  class="d-none d-lg-block link-menu"
                >
                </yevo-header-faq>
                <!-- mobile -->
                <yevo-header-faq [title]="link.title" [asDropdown]="false" class="d-lg-none"></yevo-header-faq>
              </ng-template>
              <img *ngIf="!link.expandedDeactivate" src="assets/icons/white-down-row.svg" alt="row" />

                <div
                *ngIf="!isLOgged && !hoverUser"
                [ngClass]="'isNotLogged header-square-course' + i" 
                [id]="'header-square-course' + i"
              ></div>
              <div *ngIf="isLOgged" [ngClass]="menuStyles(i)" [id]="'header-square-course' + i" style="background-color: white"></div>      
            </li>
          </ng-container>
          <div class="content-btn-external">
            <button
            class="header-v2-button-login ml-4"
            (click)="goToLogin()"
            *ngIf="!isLOgged"
            (mouseenter)="leaveMenuClose(); leaveMenuUserLogged(); isActiveTrainingPageMenu()"
            (mouseleave)="isActiveTrainingPageMenu()"
          >
            Iniciar sesión
          </button>
          <button
            class="header-v2-button-register ml-dinamic"
            (click)="goToRegister()"
            *ngIf="!isLOgged"
            (mouseenter)="leaveMenuClose(); leaveMenuUserLogged(); isActiveTrainingPageMenu()"
            (mouseleave)="isActiveTrainingPageMenu()"
          >
            Regístrate gratis
          </button>
          </div>
          <button
            class="header-v2-button-login header-v2-button-login-hidden ml-4"
            (click)="goToHome()"
            *ngIf="isLOgged"
            (mouseenter)="leaveMenuClose(); leaveMenuUserLogged(); isActiveTrainingPageMenu()"
            (mouseleave)="isActiveTrainingPageMenu()"
          >
            <img src="assets/icons/header/home-white.svg" alt="home" /> Ir a Inicio
          </button>
        </ul>
      </div>
      <!-- END DESIGN DESKTOP MENU -->

      <!-- MOBILE DESING MENU -->
      <div *ngIf="links?.mobile?.length; else notMobile">
        <ul class="navbar-nav menu-header-top d-block d-lg-none mobile-menu-scroll">
          <!-- DESIGN MOBILE MENU NEW -->
          <ng-container *ngFor="let link of links.mobile; let i = index">
            <li
              class="nav-item ml-16"
              (mouseenter)="onHover(link)"
              [ngClass]="{
                'new-dashboard-menu-title': link.main,
                'new-dashboard-menu-subtitle': !link.main,
                'active-mobile': mainMenu === link?.id,
                'mt-3': link?.isHome
              }"
              *ngIf="link?.subtitle && link?.communities"
            >
              <div class="dpf">
                <a
                  *ngIf="!link.main && !link?.course"
                  class="nav-link d-lg-none link-menu new-dashboard-menu-subtitle"
                  [ngClass]="mainMenu === link?.id ? 'activeMobile' : ''"
                  [routerLink]="link.routerLink"
                  queryParamsHandling="merge"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  (click)="
                    isActiveMenuMobile(link.id);
                    link?.logout ? closeSession() : handleLinkClick($event);
                    gotoCalendar(link);
                    _close();
                    validateUserCommunity(link.title, link.code)
                  "
                >
                  <img [src]="mainMenu === link?.id ? link.imgActive : link.img" alt="img" class="mb-1 ml-2" />{{
                    link.title
                  }}
                </a>
              </div>
            </li>
            <li
              class="nav-item"
              routerLinkActive="active"
              (mouseenter)="onHover(link)"
              [ngClass]="{
                'new-dashboard-menu-title': link.main,
                'new-dashboard-menu-subtitle': !link.main,
                'active-mobile': mainMenu === link?.id,
                'mt-3': link?.isHome
              }"
              *ngIf="!link?.subtitle"
            >
              <!-- MOBILE NEW MENU -->
              <ng-template [ngIf]="!link.services && !link.faq">
                <!-- mobile -->
                <div class="dpf">
                  <a
                    *ngIf="!link.main && !link?.course && link.routerLink == 'modal-register'"
                    class="nav-link d-lg-none link-menu new-dashboard-menu-subtitle"
                    [ngClass]="mainMenu === link?.id ? 'activeMobile' : ''"
                    (click)="
                      isActiveMenuMobile(link.id);
                      isLOgged && link?.category && dataLayerService.categoryNewHome(link.category, link.title);
                      openmodalRegister()
                    "
                  >
                    <img
                      [src]="mainMenu === link?.id ? link.imgActive : link.img"
                      alt="img"
                      class="mb-1 mr-2 icon-mobile"
                    />{{ link.title }}
                  </a>
                  <a
                    *ngIf="link?.child && !link.main && !link?.course && link.routerLink != 'modal-register'"
                    class="nav-link d-lg-none link-menu new-dashboard-menu-subtitle"
                    [ngClass]="mainMenu === link?.id ? 'activeMobile' : ''"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    (click)="
                      isActiveMenuMobile(link.id);
                      _close();
                      link?.category && dataLayerService.categoryNewHome(link.category, link.title);
                      onLink(4, ['/fenomeno-el-nino'])
                    "
                  >
                    <img
                      [src]="mainMenu === link?.id ? link.imgActive : link.img"
                      alt="img"
                      class="mb-1 mr-2 icon-mobile"
                    />{{ link.title }}
                  </a>
                   <!-- subTitle Options mobile-->
                  <a
                    *ngIf="!link?.child && !link.main && !link?.course && link.isActived && link.routerLink != 'modal-register'"
                    class="nav-link d-lg-none link-menu new-dashboard-menu-subtitle"
                    [ngClass]="mainMenu === link?.id ? 'activeMobile' : ''"
                    queryParamsHandling="merge"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    (click)="
                      isActiveMenuMobile(link.id);
                      link?.logout ? closeSession() : handleLinkClick($event);
                      gotoCalendar(link);
                      _close();
                      goToSimulator(link);
                      isLOgged && activeMarking(link);
                      goToUrl(link);
                      link?.category && dataLayerService.categoryNewHome(link.category, link.title)
                    "
                  >
                    <div class="content-mobile">
                      <img
                      [src]="mainMenu === link?.id ? link.imgActive : link.img"
                      alt="img"
                      class="mb-1 mr-2 icon-mobile"
                    />{{ link.title }} <div *ngIf="link.activedTab" class="event-actived">¡PARTICIPA!</div>
                    </div>
                  </a>
                  <span *ngIf="link?.new" class="new" [ngClass]="link?.child ? 'm-l-new' : ''">Nuevo</span>

                  <a
                    *ngIf="!link.main && link?.course"
                    class="nav-link d-lg-none link-menu new-dashboard-menu-subtitle"
                    [ngClass]="mainMenu === link?.id ? 'activeMobile' : ''"
                    queryParamsHandling="merge"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    (click)="
                      isActiveMenuMobile(link.id); onLinkCourses('COMMUNITY_COURSE'); _close(); gotoCalendar(link)
                    "
                  >
                    <img
                      [src]="mainMenu === link?.id ? link.imgActive : link.img"
                      alt="img"
                      class="mb-1 mr-2 icon-mobile"
                    />{{ link.title }}
                  </a>
                  <!-- Title Options mobile-->
                  <a
                    *ngIf="link.main && !link.linkActive"
                    class="nav-link d-lg-none link-menu new-dashboard-menu-title"
                    [routerLink]="link.routerLink"
                    (click)="
                      isActiveMenuMobile(link.id);
                      link?.category && dataLayerService.categoryNewHome(link.category, link.title.toLowerCase())
                    "
                  >
                    {{ link.title }}
                  </a>
                  <a
                    *ngIf="link.main && link.linkActive"
                    class="nav-link d-lg-none link-menu new-dashboard-menu-title"
                    [routerLink]="link.routerLink"
                    data-toggle="collapse"
                    (click)="isActiveMenuMobile(link.id); handleLinkClick($event); _close()"
                  >
                    <img
                      [src]="mainMenu === link?.id ? link.imgActive : link.img"
                      alt="img"
                      class="mb-1 mr-2 icon-mobile"
                    />{{ link.title }}
                  </a>
                </div>
                <!-- desktop -->
                <a
                  class="nav-link d-none d-lg-block link-menu"
                  [routerLink]="link.routerLink"
                  queryParamsHandling="merge"
                  data-target=".navbar-collapse.show"
                  (click)="isActiveMenuMobile(link.id); handleLinkClick($event); _close()"
                >
                  <img [src]="mainMenu === link?.id ? link.imgActive : link.img" alt="img mr-2 icon-mobile" />{{
                    link.title
                  }}
                </a>
              </ng-template>
              <!-- END MOBILE NEW MENU -->

              <ng-template [ngIf]="link.services">
                <!-- desktop -->
                <yevo-header-services
                  [title]="link.title"
                  [asDropdown]="true"
                  [servicesNavExpanded]="showServices"
                  class="d-none d-lg-block link-menu"
                >
                </yevo-header-services>

                <!-- mobile -->
                <yevo-header-services
                  [title]="link.title"
                  [asDropdown]="false"
                  class="d-lg-none"
                ></yevo-header-services>
              </ng-template>

              <ng-template [ngIf]="link.faq">
                <!-- desktop -->
                <yevo-header-faq
                  [title]="link.title"
                  [asDropdown]="true"
                  [servicesNavExpanded]="showFaqs"
                  class="d-none d-lg-block link-menu"
                >
                </yevo-header-faq>
                <!-- mobile -->
                <yevo-header-faq [title]="link.title" [asDropdown]="false" class="d-lg-none"></yevo-header-faq>
              </ng-template>
            </li>
          </ng-container>
        </ul>
        <div class="text-center d-block d-md-none">
          <hr />
          <p class="text-center ruc-yevo">
            MIBANCO - BANCO DE LA MICROEMPRESA S.A. <br />
            RUC 20382036655
          </p>
        </div>
      </div>

      <ng-template #notMobile>
        <ul class="navbar-nav menu-header-top d-block d-lg-none">
          <ng-container *ngFor="let link of links">
            <li
              class="nav-item"
              routerLinkActive="active"
              (mouseenter)="onHover(link)"
              [ngClass]="link.main ? 'new-dashboard-menu-title' : 'new-dashboard-menu-subtitle'"
            >
              <ng-template [ngIf]="!link.services && !link.faq">
                <!-- mobile -->
                <a
                  *ngIf="!link.main"
                  class="nav-link d-lg-none link-menu new-dashboard-menu-subtitle"
                  [routerLink]="link.routerLink"
                  queryParamsHandling="merge"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  (click)="handleLinkClick($event)"
                >
                  {{ link.title }}
                </a>
                <a
                  *ngIf="link.main"
                  class="nav-link d-lg-none link-menu new-dashboard-menu-title"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  (click)="handleLinkClick($event)"
                  onclick="return false;"
                >
                  {{ link.title }}
                </a>
                <!-- desktop -->
                <a
                  class="nav-link d-none d-lg-block link-menu"
                  [routerLink]="link.routerLink"
                  queryParamsHandling="merge"
                  data-target=".navbar-collapse.show"
                  (click)="handleLinkClick($event)"
                >
                  {{ link.title }}
                </a>
              </ng-template>

              <ng-template [ngIf]="link.services">
                <!-- desktop -->
                <yevo-header-services
                  [title]="link.title"
                  [asDropdown]="true"
                  [servicesNavExpanded]="showServices"
                  class="d-none d-lg-block link-menu"
                >
                </yevo-header-services>
                <!-- mobile -->
                <yevo-header-services
                  [title]="link.title"
                  [asDropdown]="false"
                  class="d-lg-none"
                ></yevo-header-services>
              </ng-template>

              <ng-template [ngIf]="link.faq">
                <!-- desktop -->
                <yevo-header-faq
                  [title]="link.title"
                  [asDropdown]="true"
                  [servicesNavExpanded]="showFaqs"
                  class="d-none d-lg-block link-menu"
                >
                </yevo-header-faq>
                <!-- mobile -->
                <yevo-header-faq [title]="link.title" [asDropdown]="false" class="d-lg-none"></yevo-header-faq>
              </ng-template>
            </li>
          </ng-container>
        </ul>
        <!-- END MOBILE DESING MENU -->
      </ng-template>
    </div>
  </div>
</nav>

<!-- Usuario no logged -->

<!-- Usuario logged -->
<ng-template #navLogged>
  <ul class="navbar-nav d-none d-lg-block">
    <!-- desktop -->
    <li
      class="nav-item-user dropdown d-none d-lg-block"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a class="nav-link d-flex align-items-center" id="settingNav" role="button" (mouseenter)="overUser()">
        <div class="login-name wordWrap">Mi Yevo</div>
      </a>
      <ul
        class="dropdown-menu dropdown-menu dropdown-menu-right dropdown-menu-lg-left nav-align expand-user-menu-logged"
        aria-labelledby="settingNav"
      >
        <li>
          <a class="dropdown-item">
            <span class="text px-0 header-title-user">{{ userName$ | async }}</span>
          </a>
        </li>
        <li *ngIf="!isLOgged">
          <a class="dropdown-item" routerLink="/ingresar" queryParamsHandling="merge">
            <img src="/assets/icons/person-user.svg" class="icon" alt="persona" />
            <span class="text px-0 header-subtitle-user">Iniciar sesión</span>
          </a>
        </li>
        <li *ngIf="isLOgged">
          <a class="dropdown-item" routerLink="/nuevo-intranet/perfil/mi-perfil" queryParamsHandling="merge">
            <img src="/assets/icons/person-user.svg" class="icon" alt="perfil" />
            <span class="text px-0 header-subtitle-user">Ver mi perfil</span>
          </a>
        </li>
        <li>
          <a
            class="dropdown-item"
            routerLink="/ingresar"
            queryParamsHandling="merge"
            routerLinkActive="active"
            (click)="closeSession()"
          >
            <img src="/assets/icons/person-delete.svg" class="icon" alt="eliminar persona" />
            <span class="text header-subtitle-user">Cerrar sesión</span>
          </a>
        </li>
      </ul>
    </li>
    <!-- mobile -->
    <li class="nav-item dropdown d-lg-none" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link d-flex align-items-center" id="settingNav" role="button" (click)="collapseUserMenu.toggle()">
        <div class="login-name wordWrap">{{ userName$ | async }}</div>
        <div class="login-default">
          <img src="/assets/icons/login-default.svg" alt="login" />
        </div>
        <span class="ml-auto ml-lg-2 wrap-icon">
          <svg-icon-sprite [src]="settingNavExpanded ? 'up_arrow' : 'down_arrow'" [width]="'12px'" [height]="'12px'">
          </svg-icon-sprite>
        </span>
      </a>
      <ul class="user-menu nav-align collapse" [(ngbCollapse)]="userMenuClosed" #collapseUserMenu="ngbCollapse">
        <li class="user-menu-item">
          <div>
            <a routerLink="/nuevo-intranet/dashboard" queryParamsHandling="merge" routerLinkActive="active"
              ><span class="text px-0">Inicio</span>
            </a>
          </div>
        </li>
        <li class="user-menu-item">
          <a routerLink="/ingresar" queryParamsHandling="merge" (click)="closeSession()">
            <img src="/assets/icons/close_session.svg" class="icon" alt="cerrar sesión" />
            <span class="text">Cerrar sesión</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
  <ul class="navbar-nav menu-header-footer d-block d-lg-none">
    <!-- mobile -->

    <li class="nav-item d-lg-none">
      <a class="nav-link d-flex align-items-center" id="settingNav" role="button" routerLink="/quienes_somos">
        <img src="/assets/icons/chat-question-outline.png" class="menu-icon" alt="¿Quiénes somos?" />
        <span class="text">¿Quiénes somos?</span>
      </a>
    </li>
    <li class="nav-item d-lg-none">
      <a class="nav-link d-flex align-items-center" id="settingNav" role="button" routerLink="/faq">
        <img src="/assets/icons/message-square.png" class="menu-icon" alt="¿Tienes dudas?" />
        <span class="text">¿Tienes dudas?</span>
      </a>
    </li>
    <li class="nav-item d-lg-none">
      <a class="nav-link d-flex align-items-center" id="settingNav" role="button" routerLink="/fenomeno-el-nino">
        <img src="/assets/icons/fenomeno_ni.png" class="menu-icon" alt="Unidos contra el Fenómeno el Niño" />
        <span class="text">Unidos contra el Fenómeno El Niño</span>
      </a>
    </li>
    <li class="nav-item d-lg-none">
      <a class="nav-link d-flex align-items-center" id="settingNav" role="button" routerLink="/contacto" alt="wifi">
        <img src="/assets/icons/wifi.png" class="menu-icon" alt="wifi" />
        <span class="text">Contáctanos</span>
      </a>
    </li>
    <li class="nav-item d-lg-none">
      <a class="nav-link d-flex align-items-center" id="settingNav" role="button" (click)="closeSession()">
        <img src="/assets/icons/person-delete.svg" class="menu-icon" alt="cerrar sesión" />
        <span class="text">Cerrar mi sesión</span>
      </a>
    </li>
    <p class="text-center mt-4 ruc">
      MIBANCO - BANCO DE LA MICROEMPRESA S.A.<br />
      RUC 20382036655
    </p>
  </ul>
</ng-template>

<yevo-desktop-menu
  *ngIf="hover"
  [links]="selectedItem"
  [isLOgged]="isLOgged"
  (closedDesktopMenu)="closedDesktopMenu()"
  (desktopMenuId)="isActiveDesktopMenu($event)"
></yevo-desktop-menu>

<yevo-desktop-menu-user *ngIf="hoverUser" (closedDesktopMenuUser)="closedDesktopMenuUser()"></yevo-desktop-menu-user>

<div
  class="alert-push animate__backInRight"
  [ngClass]="{ animate__backOutRight: activerBackOutRight }"
  *ngIf="this.showNotification"
  (click)="notificationForeground()"
>
  <div class="notification">
    <div class="notification-icon">
      <img [src]="notificationData.notification.icon" alt="Icono de notificación" />
    </div>
    <div class="notification-content">
      <h4 class="notification-title">{{ truncateText(notificationData.notification.title, maxTitleLength) }}</h4>
      <p class="notification-body">{{ truncateText(notificationData.notification.body, maxDescriptionLength) }}</p>
    </div>
  </div>
</div>

<div class="container message-container">
  <div class="financing__form text-center animated">
    <img class="form-message__img" src="assets/gifs/high-five.gif" alt="high five" />
    <h4 class="form-message__title d-flex justify-content-center">¡Tu negocio está publicado!</h4>
    <p class="form-message__subtitle d-flex justify-content-center">
      Copia el siguiente enlace para compartirlo con más personas:
    </p>
    <p class="form-message__url d-flex justify-content-center">{{ directoryUrl }}</p>
    <button
      class="form-message__button border-0 mt-3 mb-3"
      [routerLink]="['/directorio/' + slug]"
      queryParamsHandling="merge"
    >
      Visitar mi negocio
    </button>
  </div>
  <div class="desktop-img d-none d-xl-flex">
    <img src="assets/img/intranet/affiliate/ad-person.png" alt="img-ad" />
  </div>
</div>

<div class="whatsapp-button">
  <div class="version_one icon-style " (click)="showFloatingComponent()">
    <img class="icon_" src="/assets/icons/communities/whatsapp.svg" [alt]="selectedVersions" />
  </div>
</div>

<div class="floating-banner__desktop">
  <div class="triangle" *ngIf="showFloating">
    <div id="triangulo-invertido" class="shape"></div>
    <div id="triangulo-invertido" class="shape_two"></div>
  </div>
</div>

<div class="comment" *ngIf="showFloating">
  <div>
    <div class="whatsapp-section">
      <div class="close-icon" (click)="closeFloatingComponent()">
        <img src="/assets/icons/communities/close-small.svg" alt="close">
      </div>
      <div class="title-container">
        <span class="title">
         {{ this.whatsappContentTitle }}
        </span>
      </div>
      <div class="button-container">
        <div class="button-whatsapp" (click)="goToWhatsappCommunity()">
          <img src='/assets/icons/communities/whatsapp.svg' alt='wa-icon' style="height: 19px; width: 19px;" />
          <span class="button-text">{{ this.titleButton }}</span>
        </div>
      </div>
      <div class="option-container">
        <strong class="option-title">{{ selectedVersions===firstVersionName ? otherOptions : whyJoin }}</strong>
      </div>
      <div class="icons-container">
        <div class="icon-card" *ngFor=" let opction of this.whatsappFloatingOptions">
          <div class="icon-image">
            <img [src]="selectedVersions===firstVersionName?opction.iconWithRedirection:opction.iconInformative"
              alt='wa-asesoria' />
          </div>
          <div class="icon-content">
            <div class="icon-title">
              {{ selectedVersions===firstVersionName ? opction.titleWithRedirection : opction.titleInformative }}
            </div>
            <div class="icon-descriptions">
              {{ selectedVersions===firstVersionName ? opction.descriptionWithRedirection :
              opction.descriptionInformative }}
            </div>
          </div>
          <div class="icon" *ngIf="selectedVersions===firstVersionName"
            (click)="goToRedirection(opction.urlWithRedirection,(selectedVersions===firstVersionName)?opction.titleWithRedirection:opction.titleInformative)">
            <img src="/assets/icons/communities/arrow-right-icon-gray.svg" alt="close"></div>
        </div>
      </div>
    </div>
  </div>
import { Component, OnInit } from '@angular/core';
import { VIRTUAL_EVENT_VALUES_REMITTANCES } from '@shared/constants/remittances.constant';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'shipping-cost',
  templateUrl: './shipping-cost-component.html',
  styleUrls: ['./shipping-cost-component.scss']
})
export class LandingRemittancesShippingCostComponent implements OnInit {

  archivoURL: string = 'https://www.westernunionperu.pe/assets/pdf/TarifarioWU.pdf';

  constructor( private dataLayerService: DataLayerService ) {}

  ngOnInit() {}

  openDocument(){
    this.dataLayerPush();
    window.open(this.archivoURL, '_blank');
  }

  dataLayerPush(): void {
    this.dataLayerService.virtualEventEnlaceREM(VIRTUAL_EVENT_VALUES_REMITTANCES.selectTariffRemittancesProgramPage, VIRTUAL_EVENT_VALUES_REMITTANCES.selectSectionAgenceBtnTariff);
  }

}
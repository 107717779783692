<div
  class="card-container"
  [ngClass]="{
    'recommended-card': type === 'recommended',
    'evaluation-card': type === 'evaluation',
    'seed-card': type === 'seed',
    'digital-approved-card': type === 'digital-approved',
    'green-digital-approved-card': type === 'green-digital-approved',
    'second-provision-card': type === 'second-provision',
    'yape-card': type === 'yape',
    'cde-card': type === 'cde',
    'first-credit-card': type === 'first-credit'
  }"
  (click)="handleCardClicked($event)"
  click-stop-propagation
>
  <div class="top-message">
    <p>
      {{ cardInfo?.topMessage }}
      <svg-icon class="vt_tt" [src]="cardInfo?.icon"></svg-icon>
    </p>
  </div>
  <div class="title">
    <img class="imgevaluation" *ngIf="type === 'second-provision'" src="/assets/icons/cono.svg" alt="icons" />
    <p>{{ cardInfo?.title }}</p>
  </div>

  <div class="body" *ngIf="type != 'yape'">
    <p [innerHTML]="cardInfo?.message"></p>
  </div>

  <div class="footer justify-content-end desktop-button">
    <Button
      type="button"
      [ngClass]="type === 'yape' ? 'yape-button' : aroundBorder ? 'button-solicitude__rounded' : 'button-solicitude'"
      >{{ cardInfo?.linkLabel }}</Button
    >
  </div>

  <div class="footer justify-content-end mobile-button">
    <Button
      type="button"
      [ngClass]="type === 'yape' ? 'yape-button' : aroundBorder ? 'button-solicitude__rounded' : 'button-solicitude'"
      >{{ cardInfo?.linkLabelMobile }}</Button
    >
  </div>

  <div
    *ngIf="cardInfo?.footer && type != 'yape'"
    class="footer footer-text d-flex justify-content-start align-items-center"
  >
    <p [innerHTML]="cardInfo?.footer"></p>
  </div>

  <div *ngIf="type === 'seed'" class="floating-message position-absolute">
    <p class="text-right">Hasta S/ 1000</p>
  </div>
</div>

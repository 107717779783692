<ngb-accordion [closeOthers]="true" (shown)="activeClass($event)" (hidden)="hiddenClass($event)"  [activeIds]="activePanels">
  <ngb-panel id="personal-information">
    <ng-template ngbPanelTitle>
      <div class="header-accordion">
        <div class="row">
          <div class="col-10">
            <h2>
              <svg-icon
                src="assets/icons/intranet/personal-info.svg"
                [applyClass]="true"
                class="icon-header"
              ></svg-icon>
              <span>Información personal</span>
            </h2>
          </div>
          <div class="col-2">
            <span class="arrow"></span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent class="mt-2">
      <form (ngSubmit)="updatePersonalInformationProfile()">
        <div [formGroup]="frmUserInfoGroup" class="container-fluid p-0 d-flex flex-column">
          <div class="col-12">
            <div class="d-flex justify-content-end">
              <a class="my-history-edit" (click)="enableFormPersonalInfo()">
                Editar
                <img src="assets/icons/intranet/edit.png" alt="edit-icon" />
              </a>
            </div>
            <div class="row">
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Nombres*</label>
                <input
                  type="text"
                  formControlName="name"
                  class="form-control"
                  placeholder="Ingresa tus nombres completos"
                  maxlength="50"
                />
                <label class="form-inline">
                  <div
                    *ngIf="frmUserInfoGroup.controls['name'].invalid && frmUserInfoGroup.controls['name'].touched"
                    class="text-error"
                  >
                    <div *ngIf="frmUserInfoGroup.controls['name'].invalid">Nombre es requerido.</div>
                    <div *ngIf="frmUserInfoGroup.controls['name'].hasError('pattern')">Sólo se admiten letras.</div>
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Apellido paterno*</label>
                <input
                  type="text"
                  formControlName="fatherLastName"
                  class="form-control"
                  placeholder="Ingresa tu apellido paterno"
                  maxlength="50"
                />
                <label class="form-inline">
                  <div
                    *ngIf="
                      frmUserInfoGroup.controls['fatherLastName'].invalid &&
                      frmUserInfoGroup.controls['fatherLastName'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="frmUserInfoGroup.controls['fatherLastName'].invalid">
                      Apellido paterno es requerido.
                    </div>
                    <div *ngIf="frmUserInfoGroup.controls['fatherLastName'].hasError('pattern')">
                      Sólo se admiten letras.
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Apellido materno*</label>
                <input
                  type="text"
                  formControlName="motherLastName"
                  class="form-control"
                  placeholder="Ingresa tu apellido materno"
                  maxlength="50"
                />
                <label class="form-inline">
                  <div
                    *ngIf="
                      frmUserInfoGroup.controls['motherLastName'].invalid &&
                      frmUserInfoGroup.controls['motherLastName'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="frmUserInfoGroup.controls['motherLastName'].invalid">
                      Apellido materno es requerido.
                    </div>
                    <div *ngIf="frmUserInfoGroup.controls['motherLastName'].hasError('pattern')">
                      Sólo se admiten letras.
                    </div>
                  </div>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="profile-dropdown typedocument-dropdown form-group col-12 col-lg-4">
                <label class="control-label">Tipo de documento*</label>
                <yevo-dropdown
                  [items]="documentTypeList"
                  [itemSelected]="documentTypeSelected"
                  [dropdownPlacement]="['bottom-right']"
                  [canRemoveActiveItem]="false"
                  buttonLabel="Documento"
                  (selectItem)="handleSelectedCombo($event, 'typeDocument', 'typeDocumentSelected')"
                  [disabled]="true"
                ></yevo-dropdown>
              </div>
              <div class="form-group col-12 col-lg-4">
                <label class="control-label dni-label">Número de DNI*</label>
                <input
                  [type]="documentTypeSelected.value === 'E' ? 'text' : 'tel'"
                  formControlName="documentNumber"
                  class="form-control"
                  maxlength="8"
                  placeholder="Ingresa tu número de DNI"
                  #dni
                  [readonly]="true"
                />
              </div>
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Fecha de nacimiento*</label>
                <yevo-datepicker
                  [label]="'Fecha de nacimiento'"
                  [showLabel]="false"
                  [placeholder]="'dd/mm/aaaa'"
                  [obligatory]="true"
                  [dateSelected]="selectedBornDate"
                  [validateFullAge]="true"
                  [isDisabled]="formDisabledPersonalInfo"
                  (selectDate)="selectBornDate($event)"
                >
                </yevo-datepicker>
                <label class="form-inline">
                  <div
                    *ngIf="
                      frmUserInfoGroup.controls['bornDate'].invalid && frmUserInfoGroup.controls['bornDate'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="frmUserInfoGroup.controls['bornDate'].invalid">Fecha de nacimiento es requerido.</div>
                  </div>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="profile-dropdown form-group col-12 col-lg-4">
                <label class="control-label">Género*</label>
                <yevo-dropdown
                  [items]="dropdownOptions.genderOptions"
                  [itemSelected]="selectedOptions.genderSelected"
                  [scrollActivated]="true"
                  [showItemsOnMobile]="false"
                  [showModalOnMobile]="false"
                  [canRemoveActiveItem]="false"
                  menuHeightOnMobile="50%"
                  containerTitle="Seleciona tu opción aquí"
                  buttonLabel="Seleciona tu opción aquí"
                  [disabled]="formDisabledPersonalInfo"
                  (selectItem)="handleSelectedGender($event, 'gender', 'genderSelected')"
                ></yevo-dropdown>
                <label class="form-inline">
                  <div
                    *ngIf="
                      frmUserInfoGroup.get('gender')?.hasError('required') && frmUserInfoGroup.get('gender')?.touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="!selectedOptions?.genderSelected">Género es requerido.</div>
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Correo electrónico*</label>
                <input
                  class="form-control"
                  formControlName="email"
                  maxlength="100"
                  placeholder="Ingresa tu correo electrónico"
                  #email
                  [readonly]="true"
                />
              </div>

              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Número celular*</label>
                <input
                  type="tel"
                  formControlName="phone"
                  class="form-control"
                  placeholder="Ingresa tu número celular"
                  minlength="9"
                  maxlength="9"
                />
                <label class="form-inline">
                  <div *ngIf="frmUserInfoGroup.controls['phone'].invalid" class="text-error">
                    <div *ngIf="frmUserInfoGroup.controls['phone'].invalid">Número de celular es requerido.</div>
                    <div *ngIf="frmUserInfoGroup.controls['phone'].invalid">Número de celular es inválido.</div>
                  </div>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="profile-dropdown form-group col-12 col-lg-4">
                <label class="control-label">Situación laboral*</label>
                <yevo-dropdown
                  [items]="dropdownOptions.employmentOptions"
                  [itemSelected]="selectedOptions.employmentSelected"
                  [scrollActivated]="true"
                  [showItemsOnMobile]="false"
                  [showModalOnMobile]="false"
                  [canRemoveActiveItem]="false"
                  menuHeightOnMobile="50%"
                  containerTitle="Seleciona tu opción aquí"
                  buttonLabel="Seleciona tu opción aquí"
                  [disabled]="formDisabledPersonalInfo"
                  (selectItem)="handleSelectedCombo($event, 'employment', 'employmentSelected')"
                ></yevo-dropdown>
                <label class="form-inline">
                  <div
                    *ngIf="!selectedOptions?.employmentSelected && formTouchedPersonalInfo && !formDisabledPersonalInfo"
                    class="text-error"
                  >
                    <div *ngIf="!selectedOptions?.employmentSelected">Situación laboral es requerido.</div>
                  </div>
                </label>
              </div>
              <div class="profile-dropdown form-group col-12 col-lg-4">
                <label class="control-label">Estado civil*</label>
                <yevo-dropdown
                  [items]="dropdownOptions.maritalStatusOptions"
                  [itemSelected]="selectedOptions.maritalStatusSelected"
                  [scrollActivated]="true"
                  [showItemsOnMobile]="false"
                  [showModalOnMobile]="false"
                  [canRemoveActiveItem]="false"
                  menuHeightOnMobile="50%"
                  containerTitle="Seleciona tu opción aquí"
                  buttonLabel="Seleciona tu opción aquí"
                  [disabled]="formDisabledPersonalInfo"
                  (selectItem)="handleSelectedCombo($event, 'maritalStatus', 'maritalStatusSelected')"
                ></yevo-dropdown>
                <label class="form-inline">
                  <div
                    *ngIf="
                      !selectedOptions?.maritalStatusSelected && formTouchedPersonalInfo && !formDisabledPersonalInfo
                    "
                    class="text-error"
                  >
                    <div *ngIf="!selectedOptions?.maritalStatusSelected">Estado civil es requerido.</div>
                  </div>
                </label>
              </div>
              <div class="profile-dropdown form-group col-12 col-lg-4">
                <label class="control-label">Número de hijos*</label>
                <yevo-dropdown
                  [items]="dropdownOptions.howManyChildrenOptions"
                  [itemSelected]="selectedOptions.howManyChildrenSelected"
                  [scrollActivated]="true"
                  [showItemsOnMobile]="false"
                  [showModalOnMobile]="false"
                  [canRemoveActiveItem]="false"
                  menuHeightOnMobile="50%"
                  containerTitle="Seleciona tu opción aquí"
                  buttonLabel="Seleciona tu opción aquí"
                  [disabled]="formDisabledPersonalInfo"
                  (selectItem)="handleSelectedCombo($event, 'numberChildren', 'howManyChildrenSelected')"
                ></yevo-dropdown>
                <label class="form-inline">
                  <div
                    *ngIf="
                      !selectedOptions?.howManyChildrenSelected && formTouchedPersonalInfo && !formDisabledPersonalInfo
                    "
                    class="text-error"
                  >
                    <div *ngIf="!selectedOptions?.howManyChildrenSelected">Número de hijos.</div>
                  </div>
                </label>
              </div>
            </div>

            <div *ngIf="this.selectedOptions.maritalStatusSelected?.value === MARITAL_STATUS_INDEX">
              <div class="row">
                <p class="ml-3"><strong>Datos del cónyuge</strong></p>
              </div>
              <div class="row">
                <div class="profile-dropdown typedocument-dropdown form-group col-4 col-lg-4">
                  <label class="control-label">Tipo de Documento*</label>
                  <yevo-dropdown
                    [items]="documentTypeList"
                    [itemSelected]="selectedOptions.spouseDocumentTypeSelected"
                    [dropdownPlacement]="['bottom-right']"
                    [canRemoveActiveItem]="false"
                    buttonLabel="Documento"
                    [disabled]="formDisabledPersonalInfo"
                    (selectItem)="handleSelectedCombo($event, 'typeDocument', 'spouseDocumentTypeSelected')"
                  ></yevo-dropdown>
                  <label class="form-inline">
                    <div
                      *ngIf="
                        !selectedOptions?.spouseDocumentTypeSelected &&
                        formTouchedPersonalInfo &&
                        !formDisabledPersonalInfo
                      "
                      class="text-error"
                    >
                      <div *ngIf="!selectedOptions?.spouseDocumentTypeSelected">Tipo de Documento es requerido.</div>
                    </div>
                  </label>
                </div>
                <div class="form-group col-8 col-lg-8">
                  <label class="control-label">Nro. de documento del cónyuge*</label>
                  <input
                    [type]="selectedOptions.spouseDocumentTypeSelected.value === 'E' ? 'text' : 'tel'"
                    formControlName="spouseDocumentNumber"
                    class="form-control"
                    [maxlength]="documentMaxLengthSpouse"
                    placeholder="Documento del cónyuge"
                    [disabled]="formDisabledPersonalInfo"
                    #dni
                  />
                  <label class="form-inline">
                    <div
                      *ngIf="
                        frmUserInfoGroup.controls['spouseDocumentNumber'].invalid &&
                        frmUserInfoGroup.controls['spouseDocumentNumber'].touched
                      "
                      class="text-error"
                    >
                      <div *ngIf="frmUserInfoGroup.controls['spouseDocumentNumber'].invalid">
                        Número de documento es requerido.
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12 col-lg-6">
                  <label class="control-label">Nombres*</label>
                  <input
                    type="text"
                    formControlName="spouseName"
                    class="form-control"
                    placeholder="Nombres completos"
                    [disabled]="formDisabledPersonalInfo"
                    maxlength="50"
                    (blur)="trimField('spouseName')"
                  />
                  <label class="form-inline">
                    <div
                      *ngIf="
                        frmUserInfoGroup.controls['spouseName'].invalid &&
                        frmUserInfoGroup.controls['spouseName'].touched
                      "
                      class="text-error"
                    >
                      <div *ngIf="frmUserInfoGroup.controls['spouseName'].invalid">Nombres es requerido.</div>
                    </div>
                  </label>
                </div>
                <div class="form-group col-12 col-lg-6">
                  <label class="control-label">Apellidos*</label>
                  <input
                    type="text"
                    formControlName="spouseLastName"
                    class="form-control"
                    placeholder="Apellidos"
                    [disabled]="formDisabledPersonalInfo"
                    maxlength="50"
                  />
                  <label class="form-inline">
                    <div
                      *ngIf="
                        frmUserInfoGroup.controls['spouseLastName'].invalid &&
                        frmUserInfoGroup.controls['spouseLastName'].touched
                      "
                      class="text-error"
                    >
                      <div *ngIf="frmUserInfoGroup.controls['spouseLastName'].invalid">Apellidos es requerido.</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid p-0" *ngIf="!formDisabledPersonalInfo">
          <div class="col-12 m-0 p-0">
            <div class="register__submit text-center">
              <button
                (click)="onCancelSubmitPersonalInfo()"
                type="button"
                [disabled]="formDisabledPersonalInfoButton"
                class="button-reset"
              >
                Cancelar
              </button>
              <button type="submit" [disabled]="formDisabledPersonalInfoButton" class="button-save">Guardar</button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </ngb-panel>

  <ngb-panel id="my-home">
    <ng-template ngbPanelTitle>
      <div class="header-accordion">
        <div class="row">
          <div class="col-10">
            <h2>
              <svg-icon src="assets/icons/intranet/home.svg" [applyClass]="true" class="icon-header"></svg-icon>
              <span>Mi domicilio</span>
            </h2>
          </div>
          <div class="col-2">
            <span class="arrow"></span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <form (ngSubmit)="updateMyHomeProfile()">
        <div [formGroup]="formMyHomeGroup" class="container-fluid p-0 d-flex flex-column">
          <div class="col-12">
            <div class="d-flex justify-content-end">
              <a class="my-history-edit" (click)="enabledFormHomeInfo()">
                Editar
                <img src="assets/icons/intranet/edit.png" alt="edit-icon" />
              </a>
            </div>
            <div class="row geolocation-profile-container mb-3">
              <div class="col-sm-12">
                <div class="geolocation-dropdown-container">
                  <yevo-geolocation
                    [geolocation]="geolocationSelected"
                    [showLabels]="true"
                    [obligatory]="true"
                    [emitValuesSeparately]="true"
                    [canRemoveActive]="false"
                    [allDisabled]="formDisabledHomeInfo"
                    (selectSeparatelyItem)="handleGeolocationClick('geolocation', $event!)"
                  ></yevo-geolocation>

                  <div class="container">
                    <div class="row">
                      <div class="col-md text-error">
                        <div
                          *ngIf="
                            formMyHomeGroup.controls['department'].invalid &&
                            formMyHomeGroup.controls['department']?.touched &&
                            !formDisabledHomeInfo
                          "
                        >
                          Departamento es requerido.
                        </div>
                      </div>
                      <div class="col-md text-error">
                        <div
                          *ngIf="
                            formMyHomeGroup.controls['province'].invalid &&
                            formMyHomeGroup.controls['province']?.touched &&
                            !formDisabledHomeInfo
                          "
                        >
                          Provincia es requerido.
                        </div>
                      </div>
                      <div class="col-md text-error">
                        <div
                          *ngIf="
                            formMyHomeGroup.controls['district'].invalid &&
                            formMyHomeGroup.controls['district']?.touched &&
                            !formDisabledHomeInfo
                          "
                        >
                          Distrito es requerido.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Dirección*</label>
                <input
                  #mapSearchField
                  class="form-control"
                  placeholder="Ingresa tu dirección aquí"
                  formControlName="address"
                  (focus)="googlePlaceSelected = false"
                  [readonly]="true"
                />
                <label class="form-inline">
                  <div
                    *ngIf="formMyHomeGroup.controls['address'].invalid && formMyHomeGroup.controls['address'].touched"
                    class="text-error"
                  >
                    <div *ngIf="formMyHomeGroup.controls['address'].invalid">
                      Agrega una dirección sugerida por google maps para poder continuar
                    </div>
                  </div>
                </label>

                <div class="open-map d-block d-lg-none mt-3">
                  <ng-container>
                    <a
                      class="btn btn-link"
                      (click)="openModalMap(modalcontent, 'address')"
                      [ngClass]="{ 'link-disabled': formDisabledHomeInfo }"
                    >
                      Quiero usar mi ubicación actual
                      <img class="bg-image mr-3" [src]="'/assets/icons/financing/pin.svg'" alt="usar-mi-ubicacion" />
                    </a>
                  </ng-container>
                </div>
              </div>

              <div class="profile-dropdown form-group col-12 col-lg-4">
                <label class="control-label">Tipo de vivienda*</label>
                <yevo-dropdown
                  [items]="dropdownOptions.housingTypesOptions"
                  [itemSelected]="selectedOptions.housingTypesSelected"
                  [scrollActivated]="true"
                  [showItemsOnMobile]="false"
                  [showModalOnMobile]="false"
                  [canRemoveActiveItem]="false"
                  menuHeightOnMobile="50%"
                  containerTitle="Seleciona tu opción aquí"
                  buttonLabel="Seleciona tu opción aquí"
                  [disabled]="formDisabledHomeInfo"
                  (selectItem)="handleSelectedCombo($event, 'housingType', 'housingTypesSelected')"
                ></yevo-dropdown>
                <label class="form-inline">
                  <div
                    *ngIf="!selectedOptions?.housingTypesSelected && formTouchedHomeInfo && !formDisabledHomeInfo"
                    class="text-error"
                  >
                    <div *ngIf="!selectedOptions?.housingTypesSelected">Tipo de vivienda es requerido.</div>
                  </div>
                </label>
              </div>
            </div>

            <div class="open-map d-none d-lg-block">
              <ng-container>
                <a
                  class="btn btn-link"
                  (click)="openModalMap(modalcontent, 'address')"
                  [ngClass]="{ 'link-disabled': formDisabledHomeInfo }"
                >
                  Quiero usar mi ubicación actual
                  <img class="bg-image mr-3" [src]="'/assets/icons/financing/pin.svg'" alt="usar-mi-ubicacion" />
                </a>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="container-fluid p-0" *ngIf="!formDisabledHomeInfo">
          <div class="col-12 m-0 p-0">
            <div class="register__submit text-center">
              <button
                (click)="onCancelSubmitHomeInfo()"
                type="button"
                [disabled]="formDisabledHomeInfoButton"
                class="button-reset"
              >
                Cancelar
              </button>
              <button type="submit" [disabled]="formDisabledHomeInfoButton" class="button-save">Guardar</button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </ngb-panel>

  <ngb-panel id="my-business" *ngIf="isIndependent">
    <ng-template ngbPanelTitle>
      <div class="header-accordion">
        <div class="row">
          <div class="col-10">
            <h2>
              <svg-icon src="assets/icons/financing/shop-cart.svg" [applyClass]="true" class="icon-header"></svg-icon>
              <span>Mi negocio</span>
            </h2>
          </div>
          <div class="col-2">
            <span class="arrow"></span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <form (ngSubmit)="updateMyBusinessInformationProfile()">
        <div [formGroup]="formBusinessGroup" class="container-fluid p-0 d-flex flex-column">
          <div class="col-12">
            <div class="d-flex justify-content-end">
              <a class="my-history-edit" (click)="enabledFormBusinessInfo()">
                Editar
                <img src="assets/icons/intranet/edit.png" alt="edit-icon" />
              </a>
            </div>
            <div class="row">
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Nombre del negocio*</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="businessName"
                  placeholder="Ingresa el nombre de tu negocio aquí"
                />
                <label class="form-inline">
                  <div
                    *ngIf="
                      formBusinessGroup.controls['businessName'].invalid &&
                      formBusinessGroup.controls['businessName'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="formBusinessGroup.controls['businessName'].invalid">
                      Nombre del negocio es requeridos.
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">RUC*</label>
                <input
                  type="tel"
                  class="form-control"
                  maxlength="11"
                  minlength="11"
                  formControlName="businessRuc"
                  placeholder="RUC"
                />
                <label class="form-inline">
                  <div
                    *ngIf="
                      formBusinessGroup.controls['businessRuc'].invalid &&
                      formBusinessGroup.controls['businessRuc'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="formBusinessGroup.controls['businessRuc'].invalid">RUC requerido.</div>
                  </div>
                </label>
              </div>
            </div>

            <div class="row geolocation-profile-container mb-3">
              <div class="col-sm-12">
                <div class="geolocation-dropdown-container">
                  <yevo-geolocation
                    [geolocation]="geolocationBusinessSelected"
                    [showLabels]="true"
                    [obligatory]="true"
                    [emitValuesSeparately]="true"
                    [canRemoveActive]="false"
                    [allDisabled]="formDisabledBusinessInfo"
                    (selectSeparatelyItem)="handleGeolocationClick('geolocationBusiness', $event!)"
                  ></yevo-geolocation>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-md text-error">
                    <div
                      *ngIf="
                        formBusinessGroup.controls['businessDepartment'].invalid &&
                        formBusinessGroup.controls['businessDepartment'].touched &&
                        !formDisabledBusinessInfo
                      "
                    >
                      Departamento es requerido.
                    </div>
                  </div>
                  <div class="col-md text-error">
                    <div
                      *ngIf="
                        formBusinessGroup.controls['businessProvince'].invalid &&
                        formBusinessGroup.controls['businessProvince'].touched &&
                        !formDisabledBusinessInfo
                      "
                    >
                      Provincia es requerido.
                    </div>
                  </div>
                  <div class="col-md text-error">
                    <div
                      *ngIf="
                        formBusinessGroup.controls['businessDistrict'].invalid &&
                        formBusinessGroup.controls['businessDistrict'].touched &&
                        !formDisabledBusinessInfo
                      "
                    >
                      Distrito es requerido.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Dirección de tu negocio*</label>
                <input
                  #mapSearchFieldBusiness
                  class="form-control"
                  placeholder="Ingresa tu dirección aquí"
                  formControlName="ba"
                  (focus)="googlePlaceSelected = false"
                  [readonly]="true"
                />
                <label class="form-inline">
                  <div
                    *ngIf="formBusinessGroup.controls['ba'].invalid && formBusinessGroup.controls['ba'].touched"
                    class="text-error"
                  >
                    <div *ngIf="formBusinessGroup.controls['ba'].invalid">
                      Agrega una dirección sugerida por google maps para poder continuar
                    </div>
                  </div>
                </label>
              </div>

              <div class="open-map d-block d-lg-none w-100 d-flex justify-content-center">
                <ng-container>
                  <a
                    class="btn btn-link"
                    (click)="openModalMap(modalcontent, 'businessAddress')"
                    [ngClass]="{ 'link-disabled': formDisabledBusinessInfo }"
                  >
                    Quiero usar mi ubicación actual
                    <img class="bg-image mr-3" [src]="'/assets/icons/financing/pin.svg'" alt="usar-mi-ubicacion"
                  /></a>
                </ng-container>
              </div>

              <div class="profile-dropdown form-group col-12 col-lg-4">
                <label class="control-label">Rubro*</label>
                <yevo-dropdown
                  [items]="dropdownOptions?.businessSectorsOptions"
                  [itemSelected]="selectedOptions?.businessSectorsSelected"
                  [scrollActivated]="true"
                  [showItemsOnMobile]="false"
                  [showModalOnMobile]="false"
                  [canRemoveActiveItem]="false"
                  menuHeightOnMobile="50%"
                  containerTitle="Seleciona tu opción aquí"
                  buttonLabel="Seleciona tu opción aquí"
                  [disabled]="formDisabledBusinessInfo"
                  (selectItem)="handleSelectedCombo($event, 'businessSector', 'businessSectorsSelected')"
                ></yevo-dropdown>
                <label class="form-inline">
                  <div
                    *ngIf="
                      !selectedOptions?.businessSectorsSelected && formTouchedBusinessInfo && !formDisabledBusinessInfo
                    "
                    class="text-error"
                  >
                    <div *ngIf="!selectedOptions?.businessSectorsSelected">Rubro es requerido.</div>
                  </div>
                </label>
              </div>
              <div class="profile-dropdown form-group col-12 col-lg-4">
                <label class="control-label">Actividad económica*</label>
                <yevo-dropdown
                  [items]="dropdownOptions.businessTypesOptions"
                  [itemSelected]="selectedOptions.businessTypesSelected"
                  [scrollActivated]="true"
                  [showItemsOnMobile]="false"
                  [showModalOnMobile]="false"
                  [canRemoveActiveItem]="false"
                  menuHeightOnMobile="50%"
                  containerTitle="Seleciona tu opción aquí"
                  buttonLabel="Seleciona tu opción aquí"
                  [disabled]="formDisabledBusinessInfo"
                  (selectItem)="handleSelectedCombo($event, 'businessType', 'businessTypesSelected')"
                ></yevo-dropdown>
                <label class="form-inline">
                  <div
                    *ngIf="
                      !selectedOptions?.businessTypesSelected && formTouchedBusinessInfo && !formDisabledBusinessInfo
                    "
                    class="text-error"
                  >
                    <div *ngIf="!selectedOptions?.businessTypesSelected">Actividad económica es requerido.</div>
                  </div>
                </label>
              </div>
            </div>

            <div class="open-map d-none d-lg-block">
              <ng-container>
                <a
                  class="btn btn-link"
                  (click)="openModalMap(modalcontent, 'businessAddress')"
                  [ngClass]="{ 'link-disabled': formDisabledBusinessInfo }"
                >
                  Quiero usar mi ubicación actual
                  <img class="bg-image mr-3" [src]="'/assets/icons/financing/pin.svg'" alt="usar-mi-ubicacion"
                /></a>
              </ng-container>
            </div>

            <div class="row">
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Teléfono*</label>
                <input
                  type="tel"
                  formControlName="businessPhoneCel"
                  class="form-control"
                  placeholder="Teléfono"
                  minlength="9"
                  maxlength="9"
                />
                <label class="form-inline">
                  <div
                    *ngIf="
                      formBusinessGroup.controls['businessPhoneCel'].invalid &&
                      formBusinessGroup.controls['businessPhoneCel'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="formBusinessGroup.controls['businessPhoneCel'].invalid">Teléfono es requerido.</div>
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Fecha de inicio de negocio*</label>
                <yevo-datepicker
                  [label]="'Fecha de inicio de negocio'"
                  [showLabel]="false"
                  [placeholder]="'dd/mm/aaaa'"
                  [obligatory]="true"
                  [dateSelected]="selectedBusinessStartDate"
                  [startDate]="currentDate"
                  [isDisabled]="formDisabledBusinessInfo"
                  (selectDate)="selectBusinessStartDate($event)"
                >
                </yevo-datepicker>
                <label class="form-inline">
                  <div
                    *ngIf="
                      formBusinessGroup.controls['businessStartDate'].invalid &&
                      formBusinessGroup.controls['businessStartDate'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="formBusinessGroup.controls['businessStartDate'].invalid">
                      Fecha de inicio de negocio es requerido.
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Tu cuota máxima para pagar un préstamo*</label>
                <div class="currency-container">
                  <label class="currency-label">S/</label>
                </div>
                <input
                  type="tel"
                  [value]="formBusinessGroup.get('businessMaxMonthlyLoanPayment')?.value"
                  class="form-control currency-input"
                  formControlName="businessMaxMonthlyLoanPayment"
                  placeholder="0"
                />
                <label class="form-inline">
                  <div
                    *ngIf="
                      formBusinessGroup.controls['businessMaxMonthlyLoanPayment'].invalid &&
                      formBusinessGroup.controls['businessMaxMonthlyLoanPayment'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="!!getErrorsFromFieldBusiness('businessMaxMonthlyLoanPayment')?.required">
                      Tu cuota máxima para pagar un préstamo es requerido.
                    </div>
                    <div *ngIf="!!getErrorsFromFieldBusiness('businessMaxMonthlyLoanPayment')?.onlyNumber">
                      Sólo se admiten números enteros.
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid p-0" *ngIf="!formDisabledBusinessInfo">
          <div class="col-12 m-0 p-0">
            <div class="register__submit text-center">
              <button
                (click)="onCancelSubmitBusinessInfo()"
                type="button"
                [disabled]="formDisabledBusinessInfoButton"
                class="button-reset"
              >
                Cancelar
              </button>
              <button type="submit" [disabled]="formDisabledBusinessInfoButton" class="button-save">Guardar</button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </ngb-panel>

  <ngb-panel id="social-network">
    <ng-template ngbPanelTitle>
      <div class="header-accordion">
        <div class="row">
          <div class="col-10">
            <h2>
              <svg-icon src="assets/icons/intranet/social/web.svg" [applyClass]="true" class="icon-header"></svg-icon>
              <span>Redes Sociales</span>
            </h2>
          </div>
          <div class="col-2">
            <span class="arrow"></span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <form (ngSubmit)="updateSocialMediaProfile()">
        <div [formGroup]="formSocialMediaGroup" class="container-fluid p-0 d-flex flex-column">
          <div class="col-12">
            <div class="d-flex justify-content-end">
              <a class="my-history-edit" (click)="enabledFormSocialInfo()">
                Editar
                <img src="assets/icons/intranet/edit.png" alt="edit-icon" />
              </a>
            </div>
            <div class="row">
              <div class="form-group col-12 col-lg-4">
                <div class="url-title d-flex align-items-center justify-content-start mb-3">
                  <svg-icon src="assets/iconos_2/whatsapp.svg" [applyClass]="true" class="social-media-icon"></svg-icon>
                  <label class="contact-label">Escribe el número de contacto de <br />tu negocio (Whatsapp)*</label>
                </div>
                <input
                  type="text"
                  formControlName="whatsapp"
                  class="form-control"
                  placeholder="Ejemplo: 922123454"
                  minlength="9"
                  maxlength="9"
                />

                <label class="form-inline">
                  <div
                    *ngIf="
                      formSocialMediaGroup.controls['whatsapp'].invalid &&
                      formSocialMediaGroup.controls['whatsapp'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="!!getErrorsFromSocialField('whatsapp')?.required">Whatsapp es requerido.</div>
                    <div *ngIf="!getErrorsFromSocialField('whatsapp')?.required">Ingrese un número válido.</div>
                    <div *ngIf="!!getErrorsFromSocialField('whatsapp')?.onlyNumber">Solo se admiten números.</div>
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <div class="url-title d-flex align-items-center justify-content-start mb-3">
                  <svg-icon src="assets/iconos_2/fb.svg" [applyClass]="true" class="social-media-icon"></svg-icon>
                  <label class="contact-label">Pega aquí el enlace de tu página <br />de Facebook</label>
                </div>
                <input
                  type="text"
                  formControlName="facebook"
                  class="form-control"
                  placeholder="Ejemplo: www.facebook.com/miperfil"
                  maxlength="250"
                  (blur)="trimField('facebook')"
                />
                <label class="form-inline">
                  <div *ngIf="formSocialMediaGroup.controls['facebook'].invalid" class="text-error">
                    El campo Facebook no tiene el formato adecuado.
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <div class="url-title d-flex align-items-center justify-content-start mb-3">
                  <svg-icon src="assets/iconos_2/insta.svg" [applyClass]="true" class="social-media-icon"></svg-icon>
                  <label class="contact-label">Pega aquí el enlace de tu página <br />de Instagram</label>
                </div>
                <input
                  type="text"
                  formControlName="instagram"
                  class="form-control"
                  placeholder="Ejemplo: www.instagram.com/miperfil"
                  maxlength="250"
                  (blur)="trimField('instagram')"
                />
                <label class="form-inline">
                  <div *ngIf="formSocialMediaGroup.controls['instagram'].invalid" class="text-error">
                    El campo Instagram no tiene el formato adecuado.
                  </div>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12 col-lg-4">
                <div class="url-title d-flex align-items-center justify-content-start mb-3">
                  <svg-icon
                    src="assets/iconos_2/tiktok.svg"
                    [applyClass]="true"
                    class="social-media-icon no-fill"
                  ></svg-icon>
                  <label class="contact-label">Pega aquí el enlace de tu página de <br />Tiktok</label>
                </div>
                <input
                  type="text"
                  formControlName="tiktok"
                  class="form-control"
                  placeholder="Ejemplo: www.tiktok.com/@Mibanco"
                  maxlength="250"
                  (blur)="trimField('tiktok')"
                />
                <label class="form-inline">
                  <div *ngIf="formSocialMediaGroup.controls['tiktok'].invalid" class="text-error">
                    El campo Tiktok no tiene el formato adecuado.
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <div class="url-title d-flex align-items-center justify-content-start mb-3">
                  <svg-icon src="assets/iconos_2/twitter.svg" [applyClass]="true" class="social-media-icon"></svg-icon>
                  <label class="contact-label">Pega aquí el enlace de perfil <br />de Twitter</label>
                </div>
                <input
                  type="text"
                  formControlName="twitter"
                  class="form-control"
                  placeholder="Ejemplo: twitter.com/Mibanco"
                  maxlength="250"
                  (blur)="trimField('twitter')"
                />
                <label class="form-inline">
                  <div *ngIf="formSocialMediaGroup.controls['twitter'].invalid" class="text-error">
                    El campo Twitter no tiene el formato adecuado.
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <div class="url-title d-flex align-items-center justify-content-start mb-3">
                  <svg-icon src="assets/iconos_2/web.svg" [applyClass]="true" class="social-media-icon"></svg-icon>
                  <label class="contact-label">Pega aquí el enlace de perfil <br />Web</label>
                </div>
                <input
                  type="text"
                  formControlName="webpage"
                  class="form-control"
                  placeholder="Ejemplo: https://lab.mibanco.com.pe/"
                  maxlength="250"
                  (blur)="trimField('webpage')"
                />
                <label class="form-inline">
                  <div *ngIf="formSocialMediaGroup.controls['webpage'].invalid" class="text-error">
                    El campo web page no tiene el formato adecuado.
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid p-0" *ngIf="!formDisabledSocialInfo">
          <div class="col-12 m-0 p-0">
            <div class="register__submit text-center">
              <button
                (click)="onCancelSubmitSocialInfo()"
                type="button"
                [disabled]="formDisabledSocialInfoButton"
                class="button-reset"
              >
                Cancelar
              </button>
              <button type="submit" [disabled]="formDisabledSocialInfoButton" class="button-save">Guardar</button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </ngb-panel>

  <ngb-panel id="working-information" *ngIf="isDependent">
    <ng-template ngbPanelTitle>
      <div class="header-accordion">
        <div class="row">
          <div class="col-10">
            <h2>
              <svg-icon src="assets/icons/intranet/briefcase.svg" [applyClass]="true" class="icon-header"></svg-icon>
              <span>Información laboral</span>
            </h2>
          </div>
          <div class="col-2">
            <span class="arrow"></span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <form (ngSubmit)="updateWorkInformationProfile()">
        <div [formGroup]="formWorkInfoGroup" class="container-fluid p-0 d-flex flex-column">
          <div class="col-12">
            <div class="d-flex justify-content-end">
              <a class="my-history-edit" (click)="enabledFormWorkInfo()">
                Editar
                <img src="assets/icons/intranet/edit.png" alt="edit-icon" />
              </a>
            </div>
            <div class="row">
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Ocupación o profesión*</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="employmentOccupation"
                  placeholder="¿A qué te dedicas?"
                />
                <label class="form-inline">
                  <div
                    *ngIf="
                      formWorkInfoGroup.controls['employmentOccupation'].invalid &&
                      formWorkInfoGroup.controls['employmentOccupation'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="formWorkInfoGroup.controls['employmentOccupation'].invalid">
                      Ocupación o profesión es requerido.
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Inicio de actividad laboral*</label>
                <yevo-datepicker
                  [label]="'Inicio de actividad laboral'"
                  [showLabel]="false"
                  [placeholder]="'dd/mm/aaaa'"
                  [obligatory]="true"
                  [dateSelected]="selectedWorkStartDate"
                  [startDate]="currentDate"
                  [isDisabled]="formDisabledWorkInfo"
                  (selectDate)="selectWorkStartDate($event)"
                >
                </yevo-datepicker>
                <label class="form-inline">
                  <div
                    *ngIf="
                      formWorkInfoGroup.controls['employmentStartDate'].invalid &&
                      formWorkInfoGroup.controls['employmentStartDate'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="formWorkInfoGroup.controls['employmentStartDate'].invalid">
                      Inicio de actividad laboral es requerido.
                    </div>
                  </div>
                </label>
              </div>
              <div class="profile-dropdown form-group col-12 col-lg-4">
                <label class="control-label">Ingresos mensuales (Sueldo bruto mensual)*</label>
                <yevo-dropdown
                  [items]="dropdownOptions.monthlyIncomesOptions"
                  [itemSelected]="selectedOptions.monthlyIncomesSelected"
                  [scrollActivated]="true"
                  [showItemsOnMobile]="false"
                  [showModalOnMobile]="false"
                  [canRemoveActiveItem]="false"
                  menuHeightOnMobile="50%"
                  containerTitle="Seleciona tu opción aquí"
                  buttonLabel="Seleciona tu opción aquí"
                  [disabled]="formDisabledWorkInfo"
                  (selectItem)="handleSelectedCombo($event, 'monthlyIncome', 'monthlyIncomesSelected')"
                ></yevo-dropdown>
                <label class="form-inline">
                  <div
                    *ngIf="!selectedOptions?.monthlyIncomesSelected && formTouchedWorkInfo && !formDisabledWorkInfo"
                    class="text-error"
                  >
                    <div *ngIf="!selectedOptions?.monthlyIncomesSelected">Ingresos mensuales es requerido.</div>
                  </div>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Gastos mensuales*</label>
                <div class="currency-container">
                  <label class="currency-label">S/</label>
                </div>
                <input
                  type="tel"
                  [value]="formWorkInfoGroup.get('monthlyExpenses')?.value"
                  class="form-control currency-input"
                  formControlName="monthlyExpenses"
                  placeholder="0"
                />
                <label class="form-inline">
                  <div
                    *ngIf="
                      formWorkInfoGroup.controls['monthlyExpenses'].invalid &&
                      formWorkInfoGroup.controls['monthlyExpenses'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="!!getErrorsFromFieldWork('monthlyExpenses')?.required">
                      Gastos mensuales es requerido.
                    </div>
                    <div *ngIf="!!getErrorsFromFieldWork('monthlyExpenses')?.onlyNumber">
                      Sólo se admiten números enteros.
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">Empresa en la que labora*</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="employerBusinessName"
                  placeholder="Nombre de la empresa"
                />
                <label class="form-inline">
                  <div
                    *ngIf="
                      formWorkInfoGroup.controls['employerBusinessName'].invalid &&
                      formWorkInfoGroup.controls['employerBusinessName'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="formWorkInfoGroup.controls['employerBusinessName'].invalid">
                      Empresa en la que labora es requerido.
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-group col-12 col-lg-4">
                <label class="control-label">RUC de la empresa en la que labora*</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="employerBusinessRuc"
                  maxlength="11"
                  minlength="11"
                  placeholder="RUC"
                  type="tel"
                />
                <label class="form-inline">
                  <div
                    *ngIf="
                      formWorkInfoGroup.controls['employerBusinessRuc'].invalid &&
                      formWorkInfoGroup.controls['employerBusinessRuc'].touched
                    "
                    class="text-error"
                  >
                    <div *ngIf="formWorkInfoGroup.controls['employerBusinessRuc'].invalid">
                      RUC de la empresa en la que labora es requerido.
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid p-0" *ngIf="!formDisabledWorkInfo">
          <div class="col-12 m-0 p-0">
            <div class="register__submit text-center">
              <button
                (click)="onCancelSubmitWorkInfo()"
                type="button"
                [disabled]="formDisabledWorkInfoButton"
                class="button-reset"
              >
                Cancelar
              </button>
              <button type="submit" [disabled]="formDisabledWorkInfoButton" class="button-save">Guardar</button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </ngb-panel>
</ngb-accordion>

<!-- Map Modal -->
<ng-template #modalcontent let-modal>
  <div class="modal-body">
    <input id="modalSearchField" type="text" placeholder="Ingresa una dirección aquí" />
    <div id="map"></div>
  </div>
  <div class="modal-footer justify-content-center">
    <div class="d-flex">
      <div class="dismiss-button mr-1 mr-sm-3">
        <yevo-button [className]="'light'" (click)="modal.dismiss()">Cancelar</yevo-button>
      </div>
      <div class="accept-button">
        <yevo-button [className]="'primary'" (click)="modal.close()">Aceptar</yevo-button>
      </div>
    </div>
  </div>
</ng-template>
<!-- END: Map Modal -->

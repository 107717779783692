<div class='modal' [ngClass]='{ activeBox: modalBoxActive, activeTransitions: modalTransitionActive }'>
  <div class='modal-layer' (click)='closePopup()'></div>
  <div class='modal-content2'>
    <div class='row'>
      <div class='col-md-3 text-col'>
        <div class='row'>
          <div class='col'>
            <div class='question'>¡Apóyate en tu comunidad para resolver tus dudas!</div>
          </div>
          <div class='col-2 hide-X1'>
            <div class='close-button'>
              <a [routerLink]='' (click)='closePopup()'> <img src='/assets/icons/communities/close.svg'
                                                              alt='' /></a>
            </div>
          </div>
        </div>
      </div>
      <div class='col-md' style='padding: 0'>
        <div class='col'>
          <div class='row select-display'>
            <div class='col-lg-6'>
              <div class='label'>Pregunta</div>
              <input
                type='text'
                name='question'
                [(ngModel)]='question'
                class='input'
                placeholder='Ingresa aquí tu pregunta'
                maxlength='500'
              />
            </div>
            <div class='col-lg-6'>
              <div class='label'>Categoría</div>
              <yevo-dropdown
                [items]='category'
                buttonLabel='Selecciona la categoría'
                [dropdownPlacement]="['bottom-right']"
                [canRemoveActiveItem]='false'
                [disabled]='false'
                [customClass]="'dropdown'"
              ></yevo-dropdown>
            </div>
          </div>
          <div>
            <textarea class='textarea' name='questionDetail'
                      [(ngModel)]='questionDetail' maxlength='5000'
                      placeholder='Detalla más sobre tu pregunta'></textarea>
          </div>
          <div class='row mt-2'>
            <div class='col-sm-12 d-flex'>
              <button type='button' (click)='closePopup()' class='button-1-modal'>Cancelar</button>
              <button type='button' (click)='createQuestion()' class='button-2-modal'>Crear Pregunta</button>
            </div>
          </div>
        </div>
      </div>
      <div class='col-md-1 hide-X2'>
        <a [routerLink]='' (click)='closePopup()'> <img src='/assets/icons/communities/close.svg' alt='' /></a>
      </div>
    </div>
  </div>
</div>

<ld-json-script></ld-json-script>
<div class="container">
  <div class="container mt-4 mb-5" *ngIf="isLoaded && !isHtml() && topic?.type != 'article'">
    <div class="row">
      <div class="col-12 mb-2 pl-0">
        <yevo-breadcrumb [breadcrumbs]="breadcrumbs"></yevo-breadcrumb>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 box-img mt-2 w-100">
        <img
          *ngIf="imageVideoService.validType(topic.image) == ''"
          src="{{ 'assets/img/banner-fullpage.png' }}"
          class="topic-img"
          alt="cursos"
        />

        <img
          *ngIf="imageVideoService.validType(topic.image) == 'image'"
          src="{{ resourceUrl }}"
          class="topic-image"
          alt="cursos"
        />
      </div>
      <div class="col-12 pl-0 pr-0">
        <div appIframeTracker (iframeClick)="handleIFrameClick(topic.title)">
          <iframe
            *ngIf="imageVideoService.validType(topic.image) == 'video'"
            class="topic-img"
            [src]="resourceUrl"
            id="youtubePlayer"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="courses"
          >
          </iframe>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 pr-0 pl-0">
        <h4 class="details-video-title">{{ topic.title }}</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-12 pr-0 pl-0">
        <p class="text-video details-video-description mb-0" [innerHTML]="topic.description"></p>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-md-2 col-lg-1 mt-4 d-none d-md-block d-lg-block">
          <div class="row subt mb-1 shared-color">Compartir</div>
          <div class="row dpf">
            <a href="{{ direction }}" target="_blank" rel="noopener noreferrer"
              ><img class="icon" src="/assets/icons/whatsappicon.png" alt="whatsapp icon"
            /></a>
            <img class="icon" src="/assets/icons/linkicon.png" (click)="copy()" alt="link icono" />
            <div class="copy" *ngIf="copied">
              <h5>Copiado</h5>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3 mt-4">
          <div class="row mb-2">
            <span class="subt">Aquí aprenderás sobre</span>
          </div>
          <div class="row">
            <div class="col-12 dpf pl_0">
              <yevo-filter-pills-v2
                [items]="topicOptionsDropdown"
                [showSeeMore]="false"
                [dropdownPlacement]="['bottom-right']"
                [scrollActivated]="true"
                dropdownTitle="Ver temas"
                [numberPillsShowed]="numberTopicOptionsShowed"
                [showJustifyContentBetween]="false"
                [withHandleOption]="false"
                class="details-video-fill"
              ></yevo-filter-pills-v2>
            </div>
          </div>
        </div>

        <div class="d-none d-md-block d-lg-block col-md-6 col-lg-8 als_c">
          <div class="row dpf">
            <div class="col-12 dpf mt-3">
              <div class="mr_42 dpf als_i">
                <img class="w_20" src="/assets/icons/see.png" alt="eyes" />
                <span class="ml-1">{{ topic.totalVisitedNumber }}</span>
              </div>
              <div class="dpf mr_42 als_i">
                <img class="w_16" src="/assets/icons/calendar.png" alt="calendar" />
                <span class="ml-1">{{ topic.creationDate | date : 'd MMM' }} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 d-block d-md-none d-lg-none mt_15 shared-button pl-0">
          <div class="subt">Compartir</div>
          <div class="dpf d-flex justify-content-between">
            <div>
              <a href="{{ direction }}" target="_blank" rel="noopener noreferrer" class="mr-2"
                ><img class="icon" src="/assets/icons/whatsappicon.png" alt="whatsapp icon"
              /></a>
              <img class="icon" src="/assets/icons/linkicon.png" (click)="copy()" alt="link icon" />
              <div class="copy" *ngIf="copied">
                <h5>Copiado</h5>
              </div>
            </div>
            <div class="mr_24 dpf als_i">
              <img class="w_20" src="/assets/icons/see.png" alt="eyes icon" />
              <span class="ml-1">{{ topic.totalVisitedNumber }}</span>
            </div>
            <div class="dpf als_i">
              <img class="w_16" src="/assets/icons/calendar.png" alt="calendar" />
              <span class="ml-1">{{ topic.creationDate | date : 'd MMM' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="frmRegister" (ngSubmit)="onSubmit()" *ngIf="!send">
      <div class="row">
        <div class="col-12 p_0">
          <div class="write-comment">
            <textarea
              maxlength="5000"
              name="comment"
              placeholder="Añadir comentario"
              id="comment"
              formControlName="comment"
              ng-reflect-maxlength="5000"
              ng-reflect-name="comment"
              ng-reflect-model=""
              class="ng-untouched ng-pristine ng-valid comm"
            ></textarea>
          </div>
          <div
            class="farm-validation ml-1"
            *ngIf="frmRegister.get('comment')?.hasError('required') && frmRegister.get('comment')?.touched"
          >
            Por favor ingresa tu comentario
          </div>
          <div class="div__button d-none d-md-block d-lg-block txta_e">
            <button type="submit" [disabled]="!frmRegister.valid" class="btn btn-primary button-register button-comm">
              Comentar
            </button>
          </div>
          <div class="div__button d-block d-md-none d-lg-none txta_c">
            <button type="submit" [disabled]="!frmRegister.valid" class="btn btn-primary button-commovil">
              Comentar
            </button>
          </div>
        </div>
      </div>
    </form>

    <div class="row" *ngIf="send">
      <div class="col-12 text-center mt-3">
        <p class="comment-send mb-5">¡Tu comentario ha sido enviado!</p>
        <button type="button" class="button-comment mb-5" (click)="newComment()">Crear nuevo comentario</button>
      </div>
    </div>

    <div class="row mb_24">
      <div class="col-12 dpf pl_0">
        <img src="assets/icons/communities/count_answer.svg" alt="answer" class="img-fluid img_perfil" />
        <p class="p-count p_com">{{ getListCommentVideo.length }} Comentarios</p>
      </div>
    </div>

    <div class="row dpf mb-3" *ngFor="let item of getListCommentVideo">
      <div class="div-comment">
        <p class="p-name">{{ item.username }}</p>
        <p class="p-date">{{ item.creationDate | date : 'd MMM' }}</p>
        <p class="p-comment">
          {{ item.comment }}
        </p>
      </div>
    </div>
  </div>

  <div class="container mt-4" *ngIf="(isLoaded && topic?.type == 'article') || isHtml()">
    <div id="training-detail">
      <div class="row px-4">
        <div class="col-12">
          <div class="row pb-4 pb-md-5">
            <yevo-breadcrumb [breadcrumbs]="breadcrumbs"></yevo-breadcrumb>
          </div>
        </div>
      </div>

      <div id="#training-title" class="row px-1 justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="row align-items-center">
            <div class="col-12 title pb-3">
              {{ topic.title }}
            </div>
            <div class="col-12 d-none d-md-block">
              <div class="row">
                <div class="main-box">
                  <div class="main-option">{{ topicOption.name }}</div>
                </div>
              </div>
            </div>

            <div
              *ngIf="isHtml()"
              class="col-12 html-content"
              [innerHTML]="sanitizer.bypassSecurityTrustHtml(isArticleHtml())"
            ></div>

            <div *ngIf="!isHtml()" class="mt-2 w-100">
              <div class="col-12">
                <div class="row">
                  <div
                    class="col-12 text-base detail pb-3"
                    [innerHTML]="sanitizer.bypassSecurityTrustHtml(topic.description)"
                  ></div>
                </div>
              </div>
              <div class="col-12 box-img">
                <img
                  *ngIf="imageVideoService.validType(topic.image) == ''"
                  src="{{ 'assets/img/banner-fullpage.png' }}"
                  class="topic-img"
                  alt="cursos"
                />

                <img
                  *ngIf="imageVideoService.validType(topic.image) == 'image'"
                  src="{{ resourceUrl }}"
                  class="topic-image"
                  alt="cursos"
                />
              </div>
              <div class="col-12">
                <div appIframeTracker (iframeClick)="handleIFrameClick(topic.title)">
                  <iframe
                    *ngIf="imageVideoService.validType(topic.image) == 'video'"
                    class="topic-img"
                    [attr.data-cookieblock-src]="resourceUrl"
                    data-cookieconsent="statistics"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="courses"
                  >
                  </iframe>
                </div>
              </div>
            </div>
          </div>
          <div class="row main-like">
            <div class="inside-like">
              <div class="col-12 box-like position-top">
                <p class="text-like">¿Te parecío útil esta información?</p>
              </div>
              <div class="col-12 img-like position-bottom">
                <a
                  (click)="saveFeedback('like')"
                  class="like"
                  [ngClass]="feel == '' || feel == 'dislike' ? 'like--inactive' : feel == 'like' ? 'like--active' : ''"
                >
                </a>
                <a
                  (click)="saveFeedback('dislike')"
                  class="dislike"
                  [ngClass]="
                    feel == '' || feel == 'like' ? 'dislike--inactive' : feel == 'dislike' ? 'dislike--active' : ''
                  "
                >
                </a>
              </div>
            </div>
          </div>

          <div class="suggestion-main d-none">
            <div class="mb-4 mt-4 video-relation">
              <span>Videos relacionados 👀</span>
            </div>

            <div class="box-main-suggestion">
              <div *ngFor="let topic of topic.suggestionGroupTopic" class="pb-4">
                <img
                  *ngIf="imageVideoService.validType(topic.image) == ''"
                  src="{{ 'assets/img/banner-fullpage.png' }}"
                  class="topic-img-suggestion"
                  alt="cursos"
                />

                <a class="footer-link select-option" (click)="viewShowSuggestion(topic)">
                  <img class="img-fluid topic-img-suggestion" [src]="topic.thumbnail" alt="cursos" />

                  <div class="topic-text">
                    <p class="topic-title text-line mb-2 mt-3">
                      {{ topic.title }}
                    </p>
                    <p class="topic-details text-line">
                      {{ topic.description }}
                    </p>
                  </div>
                  <div class="pull-right">
                    <span class="viewMore">Conocer más</span>
                    <span class="icon align-self-center viewMoreIcon">
                      <img src="{{ 'assets/img/next-bg.svg' }}" alt="cursos" />
                    </span>
                  </div>
                </a>
              </div>

              <div *ngIf="topic.suggestionGroupTopic.length === 2" class="show-box"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="feedback-comment"
      class="col-12 box-comment position-top position-bottom"
      [ngClass]="!hiddenComment ? 'comment--in' : 'comment--out'"
    >
      <div [formGroup]="frmFeedback" class="row ml-0">
        <div class="col-12 col-md-6 box-col">
          <p class="title-comment">¡Gracias por calificar este video!</p>
          <p class="description-comment">Queremos conocer tu opinión</p>
        </div>
        <div class="col-12 col-md-6 box-col row">
          <div class="col-12">
            <textarea
              class="form-control ta-comment"
              type="text"
              formControlName="comments"
              placeholder="Escribe aquí los comentarios o sugerencias que tengas sobre este video."
              rows="3"
              maxlength="500"
            ></textarea>
          </div>
          <div class="col-12 btn-options">
            <a class="btn-md mt-2 mr-4 btn-discard" (click)="discard()">Ahora no</a>
            <button
              type="button"
              class="btn btn-primary btn-md mt-2 ml-2 btn-send"
              (click)="sendComment()"
              [disabled]="!isFormValid"
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

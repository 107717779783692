import { Component, Input, SimpleChanges } from '@angular/core';
import { StepFormItem } from '@shared/interfaces';

@Component({
  selector: 'yevo-form-steps-v2',
  templateUrl: './form-steps-v2.component.html',
  styleUrls: ['./form-steps-v2.component.scss'],
})
export class FormStepsV2Component {
  @Input() steps!: StepFormItem[];
  @Input() numberOfActiveStep: number = 1;
  @Input() totalSteps: number = 3;
  @Input() showSteps: boolean = true;
  @Input() hasSameStyle: boolean = false;
  @Input() currentTitle: string = '';
  @Input() isSeedCash: boolean = false;

  ICON_DEFAULT_PATH: string = '/assets/icons/financing/';
  ICON_DEFAULT_EXTENSION: string = '.svg';

  ngOnChanges(changes: SimpleChanges): void {
    let currentStepValue = changes?.numberOfActiveStep.currentValue;
    if (currentStepValue) {
      this.steps.forEach((step) => {
        step.active = step.step == currentStepValue;
      });
    }
  }

  public getIconPath(iconName: string): string {
    return `${this.ICON_DEFAULT_PATH}${iconName}${this.ICON_DEFAULT_EXTENSION}`;
  }
}

<div class="section">
  <div class="content-title">
    <div class="title">
      <h4>Beneficios de este nivel</h4>
      <p>Disfruta de estos beneficios exclusivos por ser parte del nivel Bronce.</p>
    </div>
  </div>
  <div class="benefit-section-container">
    <div class="swiper-section" *ngIf="evalueateSize(); else desktopView">
      <swiper #swiperBenefit [config]="config" style="margin: 0 10px">
        <ng-container *ngFor="let item of this.medalBenefit; let i = index">
          <ng-template swiperSlide>
            <yevo-my-benefit-card
              [medalBenefit]="item"
              [progressMedal]="this.progressMedal"
              (selectedItemEmit)="selectedBenefit($event)"
              class="benefit-section-card-swiper"
            ></yevo-my-benefit-card>
          </ng-template>
        </ng-container>
      </swiper>
      <div class="swiper-button-benefit-prev">
        <img src="assets/icons/communities/arrow-ios-left.svg" alt="left" />
      </div>
      <div class="swiper-button-benefit-next">
        <img src="assets/icons/communities/arrow-ios-right.svg" alt="right" />
      </div>
    </div>
    <ng-template #desktopView>
      <div class="card-desktop">
        <ng-container *ngFor="let item of this.medalBenefit; let i = index">
          <yevo-my-benefit-card
            [medalBenefit]="item"
            [progressMedal]="this.progressMedal"
            (selectedItemEmit)="selectedBenefit($event)"
            class="benefit-section-card-swiper"
          ></yevo-my-benefit-card>
        </ng-container>
      </div>
    </ng-template>
  </div>
  <div class="footer-terms">
    <p class="terms">Al participar del Programa de Referidos, usted acepta los</p>
    <span class="terms-event" (click)="openModal()">términos y condiciones.</span>
  </div>
</div>

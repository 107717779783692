import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
@Component({
  selector: 'yevo-header-brand-hamburger',
  templateUrl: './brand-hambuger.component.html',
  styleUrls: ['./brand-hambuger.component.scss'],
})
export class BrandHamburgerComponent implements OnInit {
  @Input() brandLink!: string;
  @Input() open!: boolean;
  @Input() brandImage!: string;

  @Input() showLogoMobile: boolean = true;
  @Input() showLogoDesktop: boolean = true;
  @Input() mobileButtonRight: boolean = true;

  @Output() clickMenu: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('style.flex-direction')
  flexDirection = 'row';
  isLOgged = false;

  constructor(
    private router: Router,
    public authService: AuthService,
    private LocalStorageService: LocalStorageService,
    private dataLayerService: DataLayerService
  ) {
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLOgged = true;
      }
    });
  }

  onClickMenuHandler() {
    this.clickMenu.emit();
  }

  ngOnInit(): void {
    this.flexDirection = this.mobileButtonRight ? 'row' : 'row-reverse';
  }

  login() {
    this.dataLayerService.categoryNewHome('Botones principales', 'Iniciar sesión');
    this.removeMenu();
    setTimeout(() => {
      this.router.navigate(['/ingresar']);
    }, 300);
  }

  removeMenu() {
    sessionStorage.setItem('activePageMobile', `-1`);
    this.LocalStorageService.set('activePage', '-1');
    this.LocalStorageService.set('activePageUrlSubmenu', '-1');
  }
}

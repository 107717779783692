<div class="training-section-list container pl-md-0 pr-md-0" *ngIf="coursesAux.length > 0">
  <div class="mb-md-0 d-flex align-items-end justify-content-center justify-content-md-start">
    <h2 class="m-0 pl-0 pl-md-5 pl-lg-0 mr-md-2 mb-3 mb-md-5">{{ title }}</h2>
    <a *ngIf="!!showAllLink" class="d-flex back-button" [routerLink]="showAllLink" queryParamsHandling="merge">
      <span class="ml-2">Ver todos</span>
    </a>
  </div>

  <div class="row pt-0 card-courses">
    <div class="training-card-course" *ngFor="let course of coursesAux; let index = index" (click)="verCourse(course)">
      <div>
        <div
          class="col-12 training-card-course__content-img mt-2"
        >
          <div class="training-card-course__tags" *ngIf="course.thumbnailTags">
            <label class="tag" [class.new]="tag === 'Nuevo'" *ngFor="let tag of course.tags">{{ tag }}</label>
          </div>
          <img [src]="course.thumbnail" alt="badge" class="training-card-course__img" />
        </div>
        <div class="pt-05 pl-0 pr-0">
          <p class="training-card-course__title">{{ course.title }}</p>
          <p class="training-card-course__description">{{ course.description }}</p>
        </div>
      </div>
      <div>
        <button class="training-card-course__button">Ver curso</button>
      </div>
    </div>
  </div>
</div>

<!--This section show message if don't find any result in filters-->
<!--DO NOT DELETE THIS, WE WILL NEED IN THE FUTURE-->
<div class="w-full centered" *ngIf="coursesAux.length == 0">
  <br /><br />
  <img
    src="/assets/img/intranet/training/no-filter-option.png"
    alt="Por el momento no contamos con contenido disponible"
  />
  <br /><br />
  <p class="titlewarning">¡Oh no! Por el momento no contamos con contenido disponible para esta sección.</p>
  <br />
  <p class="subtitlewarning">Nos encontramos trabajando para asesorarte con nuevos contenidos.</p>
</div>
<yevo-modal-seidor [openModal]="openModalSeidor"></yevo-modal-seidor>

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CommunityService } from '@shared/services/community.service';
import { Subscription } from 'rxjs';
import { Categories, PersonalizerRecomedationsRequest, UserLevel, VideoBusiness, VideoViewedResponse } from '@shared/interfaces/frontend/communities';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { CONST_COMMUNITIES_CODE } from '@shared/constants/communities.constants';
import { UserProfile } from '@shared/models/UserProfileModel';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-videos-communities',
  templateUrl: './videos-communities.component.html',
  styleUrls: ['./videos-communities.component.scss'],
})
export class VideosCommunitiesComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  videos: VideoBusiness[] = [];
  videosShows: VideoBusiness[] = [];
  myVideos: VideoBusiness[] = [];
  isUpdateVideos: boolean = false;
  personalizerRecomedationsRequest: PersonalizerRecomedationsRequest = {
    userId: 0,
    rewardActionId: 0,
    probability: 0,
  }
  COMMUNITY_ID = 0;
  slickConfig = {
    arrows: false,
    variableWidth: true,
    slidesToShow: 2,
    infinite: false,
    mobileFirst: true,
    swipe: true,
    swipeToSlide: true,
    slidesToScroll: 1,
  };
  category: Categories[] = [];
  isPressed: boolean[] = [];
  all: Categories = {
    name: 'Todos',
    categoryId: 0,
    quantity: 4,
    communityId: 0,
    id: 0,
  };
  length = 0;
  showButton = false;
  isMobile = false;
  forMe: Categories = {
    name: 'Para Mí',
    categoryId: 0,
    quantity: 4,
    communityId: 0,
    id: 0,
  };
  user!: UserProfile;
  userLevel: UserLevel = {
    idLevel: 0,
    userId: 0,
    flagStatus: 'N',
  };
  showRecommendText = true;
  source = '';
  sourceClick = 'list';
  isForMe = false;
  selectTagAll = false;
  videosViewed: VideoViewedResponse[] = [];

  constructor(
    private communityService: CommunityService,
    private router: Router,
    private localStoreService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private layerService: DataLayerService
  ) {
    this.isMobile = this.getScreenSize();
  }

  ngOnInit(): void {
    this.isUpdateVideos= false;
    const user = this.localStoreService.get('userProfile');
    const parentRoute = this.activatedRoute.parent?.routeConfig?.path;
    const code =
      parentRoute === 'emprende-mujer'
        ? CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID
        : CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_ID;
    this.source = code === CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID ? 'emprende-mujer' : 'bodegueros';
    if (user) this.user = JSON.parse(user) as UserProfile;
    this.communityService.getVideosViewedByUserid(this.user.userId).subscribe(
      (response) => {
        this.videosViewed = response;
        this.activatedRoute.queryParamMap.subscribe(
          (params: ParamMap) => (this.selectTagAll = params.get('all') === 'true')
        );
        this.communityService.communityIdSearch$.subscribe(
          (response) => {
            if (response) {
              this.COMMUNITY_ID = response;
              this.getLevelUser();
            } else {
              this.loadCommunity();
            }
          }
        );
      },
      (error) => {
        console.error('getVideosViewedByUserid ERROR: ', error);
      }
    );
  }

  private loadVideos(): void {
    this.subscription.add(
      this.communityService.getVideosByCommunityId(this.COMMUNITY_ID).subscribe(
        (response) => {
          this.videos = response;
          this.videos.sort((a, b) => {
            const dateA = new Date(a.creationDate).getTime();
            const dateB = new Date(b.creationDate).getTime();
            return dateA - dateB;
          });
          const validation = this.isMobile ? 10 : 15;
          this.videos.reverse();
          this.videos = this.videos
            .map((t) => {
              const idsWebinarViewed = this.videosViewed.map((wv) => wv.videoId);
              t.viewed = idsWebinarViewed.includes(Number(t.id));
              return t;
            })
            .sort((a, b) => {
              if (a.viewed === b.viewed) return 0;
              if (a.viewed) return 1;
              return -1;
            });
          this.videosShows = this.videos;
          this.videosShows = this.videosShows.slice(
            0,
            this.videosShows.length > validation ? validation : this.videosShows.length
          );
          this.length = this.videosShows.length;
          this.showButton = this.videos.length > validation;
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (!this.isForMe) {
            if (this.category[0] && this.category[0].name === 'Para Mí') {
              this.category.shift();
              this.isPressed = this.isPressed.map((e) => false);
              this.isPressed[0] = true;
            }
          }
        }
      )
    );
  }

  goHome(): Promise<boolean> {
    return this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  private loadCommunity(): void {
    const parentRoute = this.activatedRoute.parent?.routeConfig?.path;
    this.COMMUNITY_ID =
      parentRoute === 'emprende-mujer'
        ? CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID
        : CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_ID;
    this.source = this.COMMUNITY_ID === CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID ? 'emprende-mujer' : 'bodegueros';
    this.getLevelUser();
  }

  toggleButton(index: number): void {
    for (let i = 0; i < this.isPressed.length; i++) {
      this.isPressed[i] = i === index;
    }
    const community = this.source === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
    this.layerService.clickCommunity(community, this.category[index].name, 'Filtar por', 8);
    switch (this.category[index].name) {
      case 'Todos':
        this.loadVideos();
        this.showRecommendText = false;
        break;

      case 'Para Mí':
        this.getVideoByLevel();
        this.showRecommendText = true;
        break;

      default:
        this.showRecommendText = false;
        this.subscription.add(
          this.communityService
            .getVideoByCommunityAndCategory(this.COMMUNITY_ID, this.category[index].categoryId)
            .subscribe(
              (response) => {
                this.videos = response;
                this.videos = this.videos
                  .map((t) => {
                    const idsWebinarViewed = this.videosViewed.map((wv) => wv.videoId);
                    t.viewed = idsWebinarViewed.includes(Number(t.id));
                    return t;
                  })
                  .sort((a, b) => {
                    if (a.viewed === b.viewed) return 0;
                    if (a.viewed) return 1;
                    return -1;
                  });
                this.videosShows = this.videos;
                this.length = this.videosShows.length;
                this.showButton = this.videos.length > (this.isMobile ? 10 : 15);
              },
              (error) => {
                console.error(error);
              }
            )
        );
        break;
    }
  }

  private loadCategory(): void {
    let showAllIndex = 0;
    if (
      (this.isForMe && this.userLevel.idLevel && this.userLevel.idLevel < 5 && !this.selectTagAll) ||
      (this.userLevel.idLevel > 0 &&
        this.userLevel.flagStatus === 'A' &&
        this.userLevel.idLevel < 5 &&
        !this.selectTagAll)
    ) {
      this.category.unshift(this.forMe);
    }
    this.all.communityId = this.COMMUNITY_ID;
    this.category.push(this.all);
    showAllIndex = this.category.length - 1;
    this.subscription.add(
      this.communityService.getVideosCategoryStatistics(this.COMMUNITY_ID).subscribe(
        (response) => {
          response.forEach((item) => {
            if (item.quantity >= 1) {
              this.category.push(item);
            }
          });
          this.isPressed = new Array(this.category.length).fill(false);
          if (!this.selectTagAll) {
            this.isPressed[0] = true;
          } else {
            this.isPressed[showAllIndex] = true;
          }
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  pagination(): void {
    let startIndex = 0;
    const community = this.source === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
    this.layerService.clickCommunity(community, `Ver ${this.videos.length - this.length} videos más`, 'Cargar', 8);
    const validation = this.isMobile ? 10 : 15;
    const endIndex = this.videosShows.length + (this.videos.length > validation ? validation : this.videos.length);
    while (startIndex < endIndex) {
      this.videosShows = [];
      const batch = this.videos.slice(startIndex, endIndex);
      this.videosShows.push(...batch);
      this.videosShows = this.videosShows
        .map((t) => {
          const idsWebinarViewed = this.videosViewed.map((wv) => wv.videoId);
          t.viewed = idsWebinarViewed.includes(Number(t.id));
          return t;
        })
        .sort((a, b) => {
          if (a.viewed === b.viewed) return 0;
          if (a.viewed) return 1;
          return -1;
        });
      this.showButton = this.videos.length > validation;
      startIndex = endIndex;
    }
    this.length = this.videosShows.length;
    this.showButton = this.videos.length !== this.videosShows.length;
  }

  @HostListener('window:resize', ['$event'])
  private getScreenSize(): boolean {
    return window.innerWidth <= 768;
  }

  private getLevelUser(): void {
    const userString = this.localStoreService.get('userProfile');
    if (userString) {
      this.user = JSON.parse(userString) as UserProfile;
      this.subscription.add(
        this.communityService.getLevelUserByCommunityId(this.user.userId, this.COMMUNITY_ID).subscribe(
          (response) => {
            this.userLevel = response;
            if (this.userLevel.userId > 0 && this.userLevel.flagStatus === 'A' && this.userLevel.idLevel < 5) {
              if (!this.selectTagAll) {
                this.getVideoByLevel();
              } else {
                this.loadVideos();
              }
            } else {
              this.loadVideos();
            }
            this.loadCategory();
          },
          (error) => {
            console.error(error);
          }
        )
      );
    }
  }

  private getVideoByLevel(): void {
    if (this.isUpdateVideos) { return; }
    this.isUpdateVideos = true;
    this.subscription.add(
      this.communityService.getVideoByLevelAndCommunityId(this.userLevel.idLevel, this.COMMUNITY_ID).subscribe(
        (response) => {
          this.loadPersonalizerVideos(response);
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadPersonalizerVideos(response: any): void {
    this.communityService.getPersonalizerVideoByCommunityIdAndUserId(this.COMMUNITY_ID, this.user.userId).subscribe(
      (personalizerResponse) => {
        this.savePersonalizerRecomedations(personalizerResponse);
        this.myVideos = this.addPersonalizerVideo(response, personalizerResponse.videos);
        if (this.myVideos.length === 0 && this.selectTagAll) {
          this.isForMe = false;
          this.loadVideos();
          return;
        }
        this.isForMe = true;
        this.myVideos.sort((a, b) => {
          const dateA = new Date(a.creationDate).getTime();
          const dateB = new Date(b.creationDate).getTime();
          return dateB - dateA;
        });
        this.videos = this.myVideos;
        this.videos = this.videos
          .map((t) => {
            const idsWebinarViewed = this.videosViewed.map((wv) => wv.videoId);
            t.viewed = idsWebinarViewed.includes(Number(t.id));
            return t;
          })
          .sort((a, b) => {
            if (a.viewed === b.viewed) return 0;
            if (a.viewed) return 1;
            return -1;
          });
        this.videosShows = this.videos;
        const validation = this.isMobile ? 10 : 15;
        this.videosShows = this.videosShows.slice(0,this.videosShows.length > validation ? validation : this.videosShows.length);
        this.length = this.videosShows.length;
        this.showButton = this.videos.length > validation;
      });
  }

  savePersonalizerRecomedations(response: any): void {
    this.personalizerRecomedationsRequest.userId = response.userId;
    this.personalizerRecomedationsRequest.rewardActionId = response.rewardActionId;
    this.personalizerRecomedationsRequest.probability = response.probability;
    this.communityService.savePersonalizerRecomedations(this.personalizerRecomedationsRequest).subscribe();
  }

  addPersonalizerVideo(videoBusiness :any,videosPersonalizer:any):any{
    const uniqueIds = new Set<number>();
    return [...videoBusiness, ...videosPersonalizer].filter((video) => {
      if (!uniqueIds.has(video.id)) {
        uniqueIds.add(video.id);
        return true;
      }
      return false;
    });
  }
}

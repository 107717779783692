import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import toDoTodayJson from '@shared/data/remittances/to-do-today.data.json';
import { DetailToDoToday } from '@shared/interfaces/frontend/remittances/to-do-today';

@Component({
  selector: 'milab-want-to-do-today',
  templateUrl: './want-to-do-today.component.html',
  styleUrls: ['./want-to-do-today.component.scss']
})
export class WantToDoTodayComponent implements OnInit {

  detailToDoToday: DetailToDoToday[] = [];
  titleTabFisrt: string = 'Quiero enviar';
  titleTabSecond: string = 'Quiero recibir';
  numberIndexTab?: number;
  
  @Output() showSectionTabQuestion: EventEmitter<number> = new EventEmitter<number>();


  constructor() { }

  ngOnInit(): void {
    this.detailToDoToday = toDoTodayJson.toDoToday;
  }

  showSectionTab(selectedNumberIndexTab: number) {
    this.numberIndexTab = selectedNumberIndexTab;
    this.showSectionTabQuestion.emit(this.numberIndexTab);
  }

}

import { Component, EventEmitter, Input, Output,OnInit } from '@angular/core';
import { ReferredProgram, TabManager } from '@shared/interfaces/frontend/communities';
@Component({
  selector: 'yevo-benefits-tabs',
  templateUrl: './benefits-tabs.component.html',
  styleUrls: ['./benefits-tabs.component.scss']
})
export class BenefitsTabsComponent implements OnInit {
  @Input() items: TabManager[] = [];
  @Input() selectedItem!: TabManager;
  @Input() referredPrograma!:ReferredProgram;
  @Input() progressMedal!:string;
  @Output() selectedItemEmit = new EventEmitter<any>();

  selectTab(tab: TabManager): void {
    this.selectedItem = tab;
    this.setTabActive(this.selectedItem);
    this.selectedItemEmit.emit(this.selectedItem);
  }

  ngOnInit(): void {
    this.resetTabs();
  }
  resetTabs() {
    this.selectedItem = this.items[0];
    this.setTabActive(this.selectedItem);
  }

  setTabActive(activeTab: TabManager): void {
    this.items.forEach((x) => {
      x.active = x.code == activeTab.code;
    });
  }
}


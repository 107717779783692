import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ALLIED_BENEFITS_DATA,
  ALLIED_BUSINESS,
  ALLIED_BUSINESS_DATA,
  TAGICON,
} from '@shared/data/allied/allied.data.json';
import { IDropdownItem, ISelectItem } from '@shared/interfaces/IDropdownItem';
import { AlliedService } from '@shared/services/allied.service';
import { AuthService } from '@shared/services/auth.service';
import { DashboardService } from '@shared/services/dashboard.service';
import { isSafariBrowser } from '@shared/utils/validators.utils';
import { DataLayerService } from './../shared/services/data-layer.service';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { imagesallied as IMAGES_ALLIED } from '@shared/data/dashboard/benefit.json';
import { AlliedModalComponent } from './components/allied-modal/allied-modal.component';

@Component({
  selector: 'yevo-allied',
  templateUrl: './allied.component.html',
  styleUrls: ['./allied.component.scss'],
})
export class AlliedComponent implements OnInit {
  @ViewChild('content') content: any;
  closeResult = '';
  breadcrumbs = [
    { pageName: 'Inicio', url: ['/'], active: true },
    { pageName: 'Beneficios exclusivos', url: null, active: false },
  ];

  modalBoxActive = false;
  modalTransitionActive = false;

  business: boolean = true;
  benefits: boolean = false;

  businessData: any = ALLIED_BUSINESS_DATA;
  benefitsData: any = ALLIED_BENEFITS_DATA;
  banners: any = IMAGES_ALLIED;

  alliedData: any = ALLIED_BUSINESS;
  allieds: any = [];
  tagsIcon = TAGICON;

  isLogged = false;
  isScrolled: boolean = false;

  allied: any;
  service: any;

  topicOptionsDropdown: any = [];
  topicSelected!: IDropdownItem;

  currentPage = 1;
  pageSize = 6;

  modalData!: any;

  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents',
    backdrop: 'static',
    keyboard: false,
  };

  mobileBreakShow: boolean = false;
  category: any = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    public dataLayerService: DataLayerService,
    private dashboardService: DashboardService,
    private modalService: NgbModal,
    public breakpointObserver: BreakpointObserver,
    public AlliedService: AlliedService
  ) {
    this.modalService.dismissAll();
    this.loadLoggedUser();
  }

  private loadLoggedUser() {
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLogged = true;
        this.breadcrumbs = [
          { pageName: 'Inicio', url: ['/nuevo-intranet', 'dashboard'], active: true },
          { pageName: 'Beneficios exclusivos', url: null, active: false },
        ];
      }
    });
  }

  ngOnInit(): void {
    this.getLoadData();
    this.getPills();
    this.viewPort();
  }

  pageChangeEvent(page: number): void {
    this.currentPage = page;

    const start = (page - 1) * 6;
    const end = start + 6;

    let dataAllied = this.allieds;
    this.alliedData = dataAllied.slice(start, end);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  selectTopic(event: ISelectItem) {
    this.topicSelected = event.item;
    if (this.isLogged) this.dataLayerService.selectedAlliedFilters(event.item.label);

    this.currentPage = 1;

    const code = event.item.value.toString();
    const allData = this.allieds || [];

    let category = allData.filter((data: any) => {
      const paramIds = data.parameterIds.split(',');
      if (paramIds.includes(code)) return data;
    });

    this.pageSize = category.length > 6 ? 6 : this.allieds.length;
    this.alliedData = category.slice(0, 6);
  }

  getPills() {
    this.AlliedService.getCategory().subscribe((items: any) => {
      this.topicOptionsDropdown = items.map((value: any) => {
        return {
          label: value.name,
          value: value.code,
        };
      });
      this.topicSelected = this.topicOptionsDropdown[0];
    });
  }

  getLoadData() {
    this.route.data.subscribe((data) => {
      const alliedData = data['alliedResolved'];
      this.allieds = alliedData.allied.filter((ally:any) => ally.title !== "Wally");
      this.allieds.forEach((element: any) => {
        switch (element.title) {
          case 'Crosland':
            element.cardAllyId = 7;
            break;
          case 'Insuma':
            element.cardAllyId = 6;
            break;
          case 'Wally':
            element.cardAllyId = 5;
            break;
          case 'Yape':
            element.cardAllyId = 4;
            break;
          case 'Culqi':
            element.cardAllyId = 3;
            break;
          case 'SOAT':
            element.cardAllyId = 2;
            break;
          case 'Notariza':
            element.cardAllyId = 1;
            break;
        }
      });
      this.allieds.sort((a: any, b: any) => {
        return b.cardAllyId - a.cardAllyId;
      });
      this.alliedData = alliedData.allied?.filter((ally:any) => ally.title !== "Wally");
      this.alliedData = this.alliedData.slice(0, 6);
    });

    this.dashboardService.getCardList().subscribe((data) => {
      this.service = data.filter((item: any) => item.type === 'servicio' && item.iconName != 'SOAT');
    });
  }

  getbusiness(title: string) {
    this.currentPage = 1;
    this.pageSize = 6;
    this.dataLayerService.selectedNewAlliedOptions('1', title, this.isLogged);
    this.business = true;
    this.benefits = false;
  }

  getbenefits(title: string) {
    this.currentPage = 1;
    this.pageSize = this.allieds.length;
    this.dataLayerService.selectedNewAlliedOptions('1', title, this.isLogged);
    this.business = false;
    this.benefits = true;
  }

  goToDetailsBenefit(title: string, redirect: string) {
    this.dataLayerService.selectedNewAlliedOptions('2', title, this.isLogged);
    this.goToLink(redirect);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const numb = window.scrollY;
    this.isScrolled = numb >= 0;
  }

  registerEvent() {
    this.modalService.dismissAll();
    this.dataLayerService.registerWithAllied();
    this.router.navigate(['/registro'], { queryParamsHandling: 'merge' });
  }

  goToLink(link: string) {
    if (this.isLogged) {
      if (isSafariBrowser()) {
        window.location.href = link;
      } else {
        window.open(link, '_blank');
      }
    } else {
      this.registerEvent();
    }
  }

  download(url: string) {
    if (this.isLogged) {
      if (isSafariBrowser()) {
        window.location.href = url;
      } else {
        window.open(url, '_blank');
      }
    } else {
      this.registerEvent();
    }
  }

  openModal(value: any) {
    let tags = value.parameterIds.split(',').filter((item: any) => item != '39001');

    let nameTags = this.topicOptionsDropdown.filter((item: any) => {
      const code = item.value.toString();
      if (tags.includes(code)) return item.label;
    });

    this.modalData = {
      title: value.modalFirstDescription,
      description: value.modalSecondDescription,
      catalogue: value.modalSecondButtonLabel || '',
      button: value.modalFirstButtonLabel || '',
      image: value.modalIconSrc,
      tags: nameTags,
      link: value.modalFirstButtonLink,
      download: value.modalSecondButtonLink,
      allied: value.title,
    };

    if (this.mobileBreakShow) {
      const modalRef = this.modalService.open(AlliedModalComponent);
      modalRef.componentInstance.details = this.modalData;
      modalRef.result
        .then((result) => {})
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.modalBoxActive = true;
      this.modalTransitionActive = true;
      this.deactivateScroll();
      this.modalService.open(this.content, this._modalOptionsNormal);
    }
  }

  closeModal() {
    this.modalTransitionActive = false;
    this.modalBoxActive = false;
    const t = setTimeout(() => {
      clearTimeout(t);
      this.activateScroll();
    }, 400);
    this.modalService.dismissAll();
  }

  activateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'auto';
  }

  deactivateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'hidden';
  }

  viewPort() {
    this.breakpointObserver.observe(['(min-width: 768px)']).subscribe((state: BreakpointState) => {
      if (state.breakpoints['(min-width: 768px)']) {
        this.mobileBreakShow = true;
      }
    });

    this.breakpointObserver
      .observe(['(min-width: 300px)', '(max-width: 750px)'])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints['(min-width: 300px)'] && state.breakpoints['(max-width: 750px)']) {
          this.mobileBreakShow = false;
        }
      });
  }

  addInternalEvents(sectionCode: number, alliedDetail: string) {
    if (this.isLogged) this.dataLayerService.selectedAlliedMainPageOptions(sectionCode, alliedDetail);
  }

  selectedBanner(item: any) {
    this.dataLayerService.selectedCardBannerDashboard3(item.title);
  }
}

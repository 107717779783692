import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IAchivementCard } from '@shared/interfaces/frontend';
import { UserProfile } from '@shared/models/UserProfileModel';
import { AuthService } from '@shared/services/auth.service';
import { DirectoryService } from '@shared/services/directory.service';
import { dataProfile } from '@shared/data/dashboard/profile.json';

@Component({
  selector: 'yevo-profile-main-info',
  templateUrl: './profile-main-info.component.html',
  styleUrls: ['./profile-main-info.component.scss'],
})
export class ProfileMainInfoComponent implements OnInit {
  user!: UserProfile;
  cardStyle: string = '';
  showDirectoryLink: boolean = false;
  progressInputIsDisabled: boolean = true;
  progressStoryText!: string;
  @ViewChild('progressInput') progressInput!: ElementRef;

  achivements: IAchivementCard[] = dataProfile;

  constructor(public authService: AuthService, private directoryService: DirectoryService) {}

  ngOnInit(): void {
    this.userProfileListenChanges();

    if (this.user?.levels) {
      this.getAchivementsReached(this.user?.levels);
      this.getDirectoryVisits();
    }
  }

  private userProfileListenChanges() {
    this.authService.userProfile$.pipe().subscribe((user) => {
      this.user = user!;
      this.getAchivementsReached(this.user?.levels);
      this.getDirectoryVisits();
      this.getProgressStory(this.user?.progress);
    });
  }

  private getAchivementsReached(levels: any) {
    this.achivements.forEach((medal, i) => {
      this.achivements[i].reached = levels[medal.tag];
    });
  }

  private getDirectoryVisits() {
    if (this.user.visited <= 0 && !this.user?.directoryId) {
      this.cardStyle = 'no-views-style';
      this.showDirectoryLink = true;
    }
  }

  private getProgressStory(progress: string) {
    if (progress) {
      this.progressStoryText = progress;
    }
  }

  public editProgressStory() {
    this.progressInputIsDisabled = false;
    setTimeout(() => {
      this.progressInput.nativeElement.focus();
    }, 100);
  }

  public progressInputLeave() {
    this.progressInputIsDisabled = true;
    this.directoryService.updateProfileStory(this.progressStoryText).subscribe((resp) => {
      this.user.progress = this.progressStoryText;
      this.authService.getUserProfile().subscribe();
    });
  }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

export type Icon = {
  name: string;
  icon: string;
  url: string;
  description: string;
  section?: string;
  isActived: boolean;
  client?: boolean;
};

@Component({
  selector: 'remittances-best-option',
  templateUrl: './landing-remittances-best-option.component.html',
  styleUrls: ['./landing-remittances-best-option.component.scss']
})
export class LandingRemittancesBestOptionComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() description = '';
  @Input() icons: Icon[] = [];
  @Input() titleMobile = '';
  @Input() subtitleMobile = '';
  

  constructor(private router: Router) { }

  openRefers(icon: Icon): void {
    if(icon.url !== ''){
      this.router.navigate([icon.url]);
    }
  }
}
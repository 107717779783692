import { DataLayerService } from '@shared/services/data-layer.service';
import { EMAIL_REGEX, NAME_REGEX, ONLY_NUMBER_REGEX, PHONE_NUMBER_REGEX } from '@shared/utils/regex';
import { AMOUNT_VALIDATORS, DATE_VALIDATOR, renameValidatorError } from '@shared/utils/validators.utils';

import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FORM_TYPES, MARRIED_IDENTIFIER, googleAutocompleteOptions } from '@shared/constants/financing.constants';
import { HttpResponseCodes } from '@shared/constants/services.constants';
import { DOCUMENTTYPELIST } from '@shared/data/login/login.json';
import { IExternalUserFinancingForm, ILatLng, IUrlQueryParamsFinancing } from '@shared/interfaces';
import { IDropdownItem, ISelectedItem, ISelectItem } from '@shared/interfaces/IDropdownItem';
import { IUserFinancingForm, UserProfile } from '@shared/models/UserProfileModel';
import { AuthService } from '@shared/services/auth.service';
import { FinancingService } from '@shared/services/financing.service';
import { GeolocationService } from '@shared/services/geolocation.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { generateDropDownItemsOrder } from '@shared/utils/dropdown.utils';
import { formMarkAsTouched, isFormInvalid } from '@shared/utils/form.utils';
import {
  DNI_LENGTH,
  DNI_LENGTH_REGEX,
  INMIGRATION_CARD_LENGTH,
  INMIGRATION_CARD_LENGTH_REGEX,
} from '@shared/utils/login';
import { take } from 'rxjs/operators';
import * as seedCashNewData from '@shared/data/financing/seed-cash-new-data.json';
import { RecommendService } from '@shared/services/recommend.service';

@Component({
  selector: 'yevo-financing-form',
  templateUrl: './financing-form.component.html',
  styleUrls: ['./financing-form.component.scss'],
})
export class FinancingFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('inputName') inputName!: ElementRef;
  @ViewChild('mapSearchField', { static: false }) formSearchField!: ElementRef;

  private userFinancingForm!: IUserFinancingForm;
  formDisabled = true;
  userForm!: FormGroup;
  mapModal!: NgbModalRef;
  userTemp!: UserProfile | null;

  documentTypeList: IDropdownItem[] = DOCUMENTTYPELIST;
  DOCTYPE_DNI_INDEX = 0;
  DOCTYPE_CE_INDEX = 1;

  documentTypeSelected: IDropdownItem = this.documentTypeList[0];
  documentTypeSpouseSelected: IDropdownItem = this.documentTypeList[0];
  documentMaxLength: number = DNI_LENGTH;
  documentLengthRegex: string = DNI_LENGTH_REGEX;
  documentMaxLengthSpouse: number = DNI_LENGTH;
  documentLengthRegexSpouse: string = DNI_LENGTH_REGEX;

  isLogged!: boolean;
  isExternalFinancing: boolean = false;
  externalUserFinancingForm!: IExternalUserFinancingForm;
  urlQueryParams: IUrlQueryParamsFinancing = {};

  maritalStatusList: IDropdownItem[] = [];
  maritalStatusSelected!: IDropdownItem;
  showSpouseInputs: boolean = false;

  geolocationLabels = {
    department: 'Departamento donde se encuentra tu negocio',
    province: 'Provincia donde se encuentra tu negocio',
    district: 'Distrito donde se encuentra tu negocio',
  };

  // Google Maps integration variables
  LIMA_LTG_LNG = { lat: -12.0552493, lng: -77.0627323 };
  autocompleteOptions: google.maps.places.AutocompleteOptions = googleAutocompleteOptions;
  googlePlaceSelected = false;
  geocoder = new google.maps.Geocoder();
  currentGeolocation: any = {};
  newGeolocation: any = {};

  positionSelected!: google.maps.Marker;
  currentPosition!: google.maps.Marker;

  latitude: string = '';
  longitude: string = '';

  showLoanSimulatorResult: boolean = false;
  simulatorData: any = {};

  activedPreFilledValuesGeolocalitation: boolean = false;
  dataPreFilledGeolocalitation: any = {};

  preForm: any;

  listAgency: any = [];

  agencySelected: any = {};
  ally: string = '';
  departmentId: any = 0;
  provinceId: any = 0;
  districtId: any = 0;

  selectedUbigeo: string = '';
  isEmailReadOnly: boolean = true;

  allActivityType: IDropdownItem[] = [];
  employmentSituationSelected!: IDropdownItem;

  commercialActivityList: IDropdownItem[] = [];
  commercialActivitySelected!: IDropdownItem;

  timeContactList: IDropdownItem[] = [];
  timeContactSelected!: IDropdownItem;
  ally_was_alicorp: boolean = false;
  birthDateSelected: { date: string } = { date: '' };
  loadingSubmited: boolean = false;

  breadcrumbs = [
    { pageName: 'Financiamiento', url: ['/financiamiento/solicitud'], active: true },
    { pageName: 'Financiamiento General', url: null, active: false },
  ];

  isSendingSolicitude: boolean = false;
  isAllowed: boolean = false;
  housingTypeStatusJson!: ISelectedItem[];

  housingTypeList: IDropdownItem[] = [];
  housingTypeSelected!: IDropdownItem;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private financingService: FinancingService,
    public dataLayerService: DataLayerService,
    public geolocationService: GeolocationService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private location: Location,
    private localStorageService: LocalStorageService,
    private recommendService: RecommendService,
  ) {
    const state = window.history.state;
    if (state?.wasAlicorp) {
      this.ally_was_alicorp = state?.wasAlicorp;
    }
    if (state.source === 'simulator') {
      this.showLoanSimulatorResult = true;
      this.isEmailReadOnly = false;
      this.simulatorData = { ...state?.data };
    }
    if (this.financingService.assessEligibilityExternalRequest.email === '') {
      this.isEmailReadOnly = false;
    }

    this.isExternalFinancing = this.financingService.isExternalFinancing;
    this.loadLoggedUser();
    this.getRouteUrlParameters();
  }

  ngOnInit() {
    this.initRecomendedSolicitor();
    this.authService.isLogged$.subscribe((logged) => { 
      if (logged) {
        this.breadcrumbs = [
          { pageName: 'Financiamiento', url: ['/financiamiento/filtro-financiamiento'], active: true },
          { pageName: 'Financiamiento General', url: null, active: false },
        ];
      }
    });
    this.validateInitialValues();
    this.buildForm();
    this.loadData();
    this.initDropdownParams();
    this.validateAgency();
    this.housingTypeStatusJson = seedCashNewData.housingType;
  }

  initRecomendedSolicitor(){
    this.financingService.initTrackingRecomended();
    this.financingService.hasSentTrackingRecommended = this.financingService.defaultRefersData?.referredCode.length > 1;
    this.financingService.solicitorCode = '';
  }

  ngAfterViewInit() {
    const formAutocomplete = new google.maps.places.Autocomplete(
      this.formSearchField.nativeElement,
      this.autocompleteOptions
    );
    formAutocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const location = formAutocomplete.getPlace().geometry?.location;
        if (!location) return;
        const latLng: ILatLng = {
          lat: location!.lat(),
          lng: location!.lng(),
        };
        this.currentPosition = new google.maps.Marker({
          position: latLng,
          icon: 'https://yevononprodstorage.blob.core.windows.net/yevononprodcontainer/pin.png',
        });
        this.newGeolocation = latLng;
        this.setLocation();
      });
    });
  }
  public currentLocationEvent(event?: any) {
    this.googlePlaceSelected = true;
    this.currentPosition = event;
  }

  ngOnDestroy() {
    if (this.mapModal) {
      this.mapModal.close();
    }
  }

  private getRouteUrlParameters() {
    this.urlQueryParams.aliado = this.route.snapshot.queryParamMap.get('aliado') || this.financingService.aliado;
    this.urlQueryParams.utmSource = this.route.snapshot.queryParamMap.get('utm_source')! || '';
    this.urlQueryParams.utmMedium = this.route.snapshot.queryParamMap.get('utm_medium')! || '';
    this.urlQueryParams.utmCampaign = this.route.snapshot.queryParamMap.get('utm_campaign')! || '';
    this.urlQueryParams.utmContent = this.route.snapshot.queryParamMap.get('utm_content')! || '';
    this.urlQueryParams.utmId = this.route.snapshot.queryParamMap.get('utm_id')! || '';
  }

  private initDropdownParams() {
    this.financingService.getGeneralFormParams().subscribe((res) => {
      const maritalStatusParams = res.data.maritalStatus;
      const employmentStatusParams = res.data.employmentStatus;
      const timeContactParams = res.data.contactTimes;
      const housingTypeParams = this.housingTypeStatusJson;
      const businessEconomicActivityParams = res.data.commercialBusiness;

      this.commercialActivityList = generateDropDownItemsOrder(businessEconomicActivityParams);
      maritalStatusParams.map((i: any) =>
        this.maritalStatusList.push({ label: i.name, value: i.code, order: i.order })
      );
      employmentStatusParams.map((i: any) =>
        this.allActivityType.push({ label: i.name, value: i.code, order: i.order, extraValue: i.extraValue })
      );
      housingTypeParams.map((i: any) => this.housingTypeList.push({ label: i.label, value: i.label, order: i.value }));
      this.employmentSituationSelected = this.allActivityType[0];
      this.userForm.get('employmentSituation')?.setValue(this.employmentSituationSelected.extraValue);
    });
  }

  private getSpouseInfo(): void {
    if (this.userForm.get('maritalStatus')?.value == MARRIED_IDENTIFIER) {
      this.showSpouseInputs = true;
      this.showSpouseFields();
    } else {
      this.showSpouseInputs = false;
      this.hideSpouseFields();
    }
  }

  private loadLoggedUser() {
    this.authService.userProfile$.pipe(take(1)).subscribe((user) => { 
      this.userTemp = user;   
      this.isLogged = !!user;
      if (!this.isLogged && !this.isExternalFinancing) {
        this.router.navigate(['financiamiento', 'solicitud'], { queryParamsHandling: 'merge' });
      } else {
        this.isExternalFinancing ? this.loadFinancingUserExternal() : this.loadFinancingUser();
      }
    });
  }

  loadFinancingUser() {
    this.authService.userProfile$.subscribe(
      (userProfile) => (this.userFinancingForm = userProfile?.getUserFinancingForm()!)
    );
  }

  loadFinancingUserExternal() {
    this.userFinancingForm = {
      name: '',
      fatherLastName: '',
      motherLastName: '',
      ...this.financingService.assessEligibilityExternalRequest,
    };
  }

  validateInitialValues() {
    if (!this.financingService.initialFormAmount) {
      this.router.navigate(['nuevo-intranet', 'dashboard'], { queryParamsHandling: 'merge' });
    }
  }

  getPayload() {
    const payload = this.userForm.value;
    payload.latitude = this.latitude;
    payload.longitude = this.longitude;
    if (this.localStorageService.get('ally_was_alicorp') === 'true') {
      payload.aliado = 'alicorp';
      this.localStorageService.remove('ally_was_alicorp');
    }
    if (this.localStorageService.get('ally_was_uber') === 'true') {
      payload.aliado = 'uber';
      this.localStorageService.remove('ally_was_uber');
    }
    return payload;
  }

  public getMaritalStatusEvent(event: any): void {
    this.maritalStatusSelected = event.item!;
    this.userForm.get('maritalStatus')?.setValue(this.maritalStatusSelected?.label || '');
    this.getSpouseInfo();
  }

  public getContactTimeEvent(event: any): void {
    this.timeContactSelected = event.item!;
    this.userForm.get('timeContact')?.setValue(this.timeContactSelected?.label || '');
  }

  public getHousingTypeEvent(event: any): void {
    this.housingTypeSelected = event.item!;
    this.userForm.get('housingType')?.setValue(this.housingTypeSelected?.label || '');
  }

  public getAgencyStatusEvent(event: any): void {
    this.agencySelected = event.item!;
    this.userForm.get('agencyName')?.setValue(event.item?.name || '');
    this.userForm.get('agencyAddress')?.setValue(event.item?.address || '');
    this.userForm.get('agencyCode')?.setValue(event.item?.code || '');
  }

  public handleSelectActivity(event?: any): void {
    this.commercialActivitySelected = event?.item;
    this.userForm.get('businessEconomicActivity')?.setValue(this.commercialActivitySelected?.label || '');
  }

  public getActivityType(item: IDropdownItem): void {
    this.employmentSituationSelected = item;
    this.userForm.get('employmentSituation')?.setValue(item.extraValue);
  }

  public getBirthdateEvent(event: any) {
    this.userForm.get('birthDate')?.setValue(event.date);
    this.userForm.get('birthDate')?.markAsDirty();
    this.userForm.get('birthDate')?.markAsTouched();
    this.birthDateSelected = event;
  }

  sendForm() {
    if(this.loadingSubmited)return;
    this.loadingSubmited = true;
    const formKeys = Object.keys(this.userForm.controls);
    if (isFormInvalid(this.userForm, formKeys)) {
      formMarkAsTouched(this.userForm, true, formKeys);
      this.loadingSubmited = false;
      return;
    }

    this.setSucessInformationForNextView();
    this.patchFormValues();
    this.isExternalFinancing ? this.sendSolicitudeExternal() : this.sendSolicitude();
  }

  sendSolicitude() {
    const payload = this.getPayload();
    payload.typeDocument = this.documentTypeSelected.value;

    if (this.financingService.typeCampaign) {
      payload.typeCampaign = this.financingService.typeCampaign;
    }
    if (this.urlQueryParams.aliado) {
      payload.aliado = this.urlQueryParams.aliado;
    }
    if (this.urlQueryParams.utmSource) {
      payload.utmSource = this.urlQueryParams.utmSource;
    }
    if (this.urlQueryParams.utmMedium) {
      payload.utmMedium = this.urlQueryParams.utmMedium;
    }
    if (this.urlQueryParams.utmCampaign) {
      payload.utmCampaign = this.urlQueryParams.utmCampaign;
    }
    if (this.urlQueryParams.utmContent) {
      payload.utmContent = this.urlQueryParams.utmContent;
    }
    if (this.urlQueryParams.utmId) {
      payload.utmId = this.urlQueryParams.utmId;
    }

    if (this.showLoanSimulatorResult) {
      payload.simulatorId = this.simulatorData.simulatorId;
      payload.simulatorTermAmount = this.simulatorData.maxPaymentRate;
      payload.simulatorTerms = this.simulatorData.paymentDeadline;
    }

    payload.idPreCredit = this.financingService.idPreCredit;
    payload.referredCode = this.financingService.defaultRefersData?.referredCode,
    payload.formType = this.getFormType();
    this.deleteSpouseFields();
    if (!this.isSendingSolicitude) {
      this.isSendingSolicitude = true;
      this.financingService.createFinanceGeneralInternal(payload).subscribe(
        () => {
          this.getDataFinance();
          this.showMessageSuccess();
          this.sendMailchimpRecomended(false,0);
          this.financingService.setTrackingRecommended(0,this.userTemp?.documentNumber || '');
          this.dataLayerService.pushFinancingSolicitudeSuccess(
            this.isExternalFinancing,
            this.financingService.derivationSelected
          );
        },
        (error) => {
          if (error.status == HttpResponseCodes.HTTP_FORBIDEN_STATUS || error.error.code === 'notAllowed') {
            this.isAllowed = true;
            this.getDataFinance();
            this.showMessageSuccess();
          }
        }
      );
    }
    this.loadingSubmited = false;
  }

  private setSucessInformationForNextView() {
    this.financingService.setSuccessInformation(
      this.userForm.get('name')?.value,
      this.userForm.get('fatherLastName')?.value,
      this.userForm.get('motherLastName')?.value
    );
  }

  sendSolicitudeExternal() {
    const payload = this.getPayload();

    const idPreCredit =
      this.financingService.assessEligibilityExternalResponse?.idPreCredit || this.financingService.idPreCredit;
    this.financingService.assessEligibilityExternalRequest.email = this.userForm.get('email')?.value;

    this.externalUserFinancingForm = {
      typeDocument: this.financingService.assessEligibilityExternalRequest?.typeDocument,
      idPreCredit: idPreCredit,
      referredCode: this.financingService.defaultRefersData?.referredCode,
      formType: this.getFormType(),
      ...payload,
    };

    if (this.showLoanSimulatorResult) {
      this.externalUserFinancingForm.simulatorId = this.simulatorData.simulatorId;
      this.externalUserFinancingForm.simulatorTermAmount = this.simulatorData.maxPaymentRate;
      this.externalUserFinancingForm.simulatorTerms = this.simulatorData.paymentDeadline;
    }

    if (this.financingService.typeCampaign) {
      this.externalUserFinancingForm.typeCampaign = this.financingService.typeCampaign;
    }
    if (this.urlQueryParams.aliado) {
      this.externalUserFinancingForm.aliado = this.urlQueryParams.aliado;
    }
    if (this.urlQueryParams.utmSource) {
      this.externalUserFinancingForm.utmSource = this.urlQueryParams.utmSource;
    }
    if (this.urlQueryParams.utmMedium) {
      this.externalUserFinancingForm.utmMedium = this.urlQueryParams.utmMedium;
    }
    if (this.urlQueryParams.utmCampaign) {
      this.externalUserFinancingForm.utmCampaign = this.urlQueryParams.utmCampaign;
    }
    if (this.urlQueryParams.utmId) {
      this.externalUserFinancingForm.utmId = this.urlQueryParams.utmId;
    }
    if (this.urlQueryParams.utmContent) {
      this.externalUserFinancingForm.utmContent = this.urlQueryParams.utmContent;
    }
    if (this.localStorageService.get('ally_was_alicorp') === 'true') {
      this.externalUserFinancingForm.aliado = 'alicorp';
      this.localStorageService.remove('ally_was_alicorp');
    }
    if (this.localStorageService.get('ally_was_uber') === 'true') {
      this.externalUserFinancingForm.aliado = 'uber';
      this.localStorageService.remove('ally_was_uber');
    }
    this.deleteSpouseFields();

    if (!this.isSendingSolicitude) {
      this.isSendingSolicitude = true;
      this.financingService
        .createFinanceGeneralExternal(this.externalUserFinancingForm)
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.getDataFinance();
            this.sendMailchimpRecomended(true,0);
            this.financingService.setTrackingRecommended(response.id,this.externalUserFinancingForm.documentNumber)
            this.dataLayerService.pushFinancingSolicitudeSuccess(
              this.isExternalFinancing,
              this.financingService.derivationSelected
            );

            this.checkDocument();
          },
          (error) => {
            if (error.status == HttpResponseCodes.HTTP_FORBIDEN_STATUS || error.error.code === 'notAllowed') {
              this.isAllowed = true;
              this.getDataFinance();
              this.checkDocument();
            }
          }
        );
    }
    this.loadingSubmited = false;
  }

  getDataFinance() {
    this.financingService.sucessFinancingSolicitudeRegister.name = this.userForm.get('name')?.value;
    this.financingService.sucessFinancingSolicitudeRegister.fatherLastName = this.userForm.get('fatherLastName')?.value;
    this.financingService.sucessFinancingSolicitudeRegister.motherLastName = this.userForm.get('motherLastName')?.value;
    this.financingService.sucessFinancingSolicitudeRegister.typeDocument = this.documentTypeSelected.value;
  }

  private deleteSpouseFields() {
    if (!this.showSpouseInputs) {
      delete this.externalUserFinancingForm?.typeDocumentSpouse;
      delete this.externalUserFinancingForm?.documentNumberSpouse;
      delete this.externalUserFinancingForm?.nameSpouse;
      delete this.externalUserFinancingForm?.lastNameSpouse;
    }
  }

  checkDocument() {
    // DNI VALIDATION
    this.authService
      .checkDocument(this.externalUserFinancingForm.documentNumber)
      .pipe(take(1))
      .subscribe(
        (response) => {
          // HTTP 200: DNI exists in DB
          this.showMessageSuccess();
          this.financingService.isExternalFinancing = false;
        },
        (error) => {
          // 404 ERROR: DNI Doesn't exist
          this.showMessageSuccess();
        }
      );
  }

  private showSpouseFields(): void {
    if (this.preForm?.documentNumberSpouse) {
      let preFormObject = this.preForm;
      this.userForm.get('typeDocumentSpouse')?.setValue(preFormObject.typeDocumentSpouse);
      let documentTypeSpouse = this.documentTypeList.find((item) => item.value === preFormObject.typeDocumentSpouse);
      if (documentTypeSpouse) {
        this.documentTypeSpouseSelected = documentTypeSpouse;
      }

      this.userForm.get('typeDocumentSpouse')?.updateValueAndValidity();
      this.userForm.get('documentNumberSpouse')?.setValue(preFormObject.documentNumberSpouse);

      this.userForm.get('documentNumberSpouse')?.updateValueAndValidity();
      this.userForm.get('nameSpouse')?.setValue(preFormObject.nameSpouse);

      this.userForm.get('nameSpouse')?.updateValueAndValidity();
      this.userForm.get('lastNameSpouse')?.setValue(preFormObject.lastNameSpouse);

      this.userForm.get('lastNameSpouse')?.updateValueAndValidity();
    } else {
      this.userForm.get('typeDocumentSpouse')?.setValidators([Validators.required]);
      this.userForm.get('typeDocumentSpouse')?.setValue(this.documentTypeSpouseSelected.value);
      this.userForm
        .get('documentNumberSpouse')
        ?.setValidators([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(8),
          Validators.pattern('[0-9]{8}'),
        ]);
      this.userForm
        .get('nameSpouse')
        ?.setValidators([
          Validators.required,
          Validators.maxLength(50),
          renameValidatorError(Validators.pattern(NAME_REGEX), 'onlyLetter'),
        ]);
      this.userForm
        .get('lastNameSpouse')
        ?.setValidators([
          Validators.required,
          Validators.maxLength(50),
          renameValidatorError(Validators.pattern(NAME_REGEX), 'onlyLetter'),
        ]);
    }
  }

  private hideSpouseFields(): void {
    this.userForm.get('typeDocumentSpouse')?.setValidators(Validators.nullValidator);
    this.userForm.get('documentNumberSpouse')?.setValidators(Validators.nullValidator);
    this.userForm.get('nameSpouse')?.setValidators(Validators.nullValidator);
    this.userForm.get('lastNameSpouse')?.setValidators(Validators.nullValidator);
    this.userForm.get('typeDocumentSpouse')?.setValue(null);
    this.userForm.get('documentNumberSpouse')?.setValue(null);
    this.userForm.get('nameSpouse')?.setValue(null);
    this.userForm.get('lastNameSpouse')?.setValue(null);
  }

  showMessageSuccess() {
    this.isAllowed
      ? this.isUnsuccessMessage()
      : this.redirectSolicitudeShowMessage(!(this.financingService.typeCampaign === 2));
  }

  redirectSolicitudeShowMessage(showOriginalMessage: boolean = false) {
    this.router.navigate(['financiamiento', 'solicitud-enviada'], {
      state: { showMessage: showOriginalMessage, showMessageKushka: !showOriginalMessage, isFinancingGeneral: true },
      queryParamsHandling: 'merge',
    });
  }

  isUnsuccessMessage(showOriginalMessage: boolean = false) {
    this.router.navigate(['financiamiento', 'solicitud-enviada'], {
      queryParams: {
        estado: 'NotOffer',
      },
      state: { showMessage: showOriginalMessage, showMessageKushka: !showOriginalMessage, isFinancingGeneral: true },
      queryParamsHandling: 'merge',
    });
  }

  formatPayloadGeolocation(payload: any) {
    payload.department = this.geolocationService.getDepartmentByIdAsDropdownItem(payload.department).label;
    payload.province = this.geolocationService.getProvinceByIdAsDropdownItem(payload.province).label;
    payload.district = this.geolocationService.getDistrictByIdAsDropdownItem(payload.district).label;
  }

  showError(field: string, dirty: boolean = false): boolean | undefined {
    const control = this.userForm.get(field)!;
    if (dirty) {
      return control.invalid && (control.dirty || control.touched);
    } else {
      return control.invalid && control.touched;
    }
  }

  getErrorsFromField(field: string): any {
    return this.userForm.get(field)?.errors;
  }

  private buildForm() {
    this.userForm = this.fb.group({
      amount: [null, [Validators.required]],
      name: [
        null,
        [
          Validators.required,
          Validators.maxLength(100),
          renameValidatorError(Validators.pattern(NAME_REGEX), 'onlyLetter'),
        ],
      ],
      fatherLastName: [
        null,
        [
          Validators.required,
          Validators.maxLength(100),
          renameValidatorError(Validators.pattern(NAME_REGEX), 'onlyLetter'),
        ],
      ],
      motherLastName: [
        null,
        [
          Validators.required,
          Validators.maxLength(100),
          renameValidatorError(Validators.pattern(NAME_REGEX), 'onlyLetter'),
        ],
      ],
      documentNumber: [null, [Validators.required, Validators.pattern('[0-9]{8}')]],
      phone: [
        null,
        [
          Validators.required,
          Validators.maxLength(9),
          Validators.minLength(9),
          renameValidatorError(Validators.pattern(PHONE_NUMBER_REGEX), 'phoneNumber'),
          renameValidatorError(Validators.pattern(ONLY_NUMBER_REGEX), 'onlyNumber'),
        ],
      ],
      maritalStatus: [null],
      email: [null],
      department: [null, Validators.required],
      province: [null, Validators.required],
      district: [null, Validators.required],
      ubigeo: [null],
      address: [null, this.validatePlaceOptionSelected()],
      typeDocumentSpouse: [null],
      documentNumberSpouse: [null, [Validators.maxLength(8), Validators.minLength(8), Validators.pattern('[0-9]{8}')]],
      agencyName: [null, Validators.required],
      agencyAddress: [null],
      agencyCode: [null],
      nameSpouse: [
        null,
        [Validators.maxLength(50), renameValidatorError(Validators.pattern(NAME_REGEX), 'onlyLetter')],
      ],
      lastNameSpouse: [
        null,
        [Validators.maxLength(50), renameValidatorError(Validators.pattern(NAME_REGEX), 'onlyLetter')],
      ],
      timeContact: [''],
      housingType: [''],
      employmentSituation: ['Tengo un negocio', Validators.required],
      businessEconomicActivity: ['', Validators.required],
      businessSalesAmount: [null, AMOUNT_VALIDATORS(true)],
      birthDate: ['', [Validators.required, DATE_VALIDATOR()]],
    });
  }

  private loadData() {
    if (this.userFinancingForm) {
      const index =
        this.userFinancingForm?.documentNumber.length == DNI_LENGTH ? this.DOCTYPE_DNI_INDEX : this.DOCTYPE_CE_INDEX;
      this.documentTypeSelected = this.documentTypeList[index];
      this.changeValidatorsDocumentNumber();

      this.userForm.reset({
        amount: this.formatAmountNumber(this.financingService.initialFormAmount),
        name: this.userFinancingForm.name,
        fatherLastName: this.userFinancingForm.fatherLastName,
        motherLastName: this.userFinancingForm.motherLastName,
        documentNumber: this.userFinancingForm.documentNumber,
        phone: this.userFinancingForm.phone,
        email: this.userFinancingForm.email,
        department: null,
        province: null,
        district: null,
        address: '',
      });
    }
  }

  //* GEOLOCATION */
  private validatePlaceOptionSelected(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.googlePlaceSelected ? null : { googlePlaceSelected: { value: control.value } };
    };
  }
  private setAddressFromGeocode(latLng: any) {
    this.geocoder.geocode(
      {
        location: latLng,
      },
      (results, status) => {
        const result = results[0];
        if (status === google.maps.GeocoderStatus.OK && result) {
          this.userForm.get('address')?.setValue(result.formatted_address);
          this.currentGeolocation = { ...this.newGeolocation };
          this.latitude = this.currentGeolocation?.lat;
          this.longitude = this.currentGeolocation?.lng;
        }
      }
    );
  }
  private setLocation() {
    this.positionSelected = this.currentPosition;
    this.setAddressFromGeocode(this.positionSelected.getPosition());
    this.googlePlaceSelected = true;
  }

  public getDocumentTypeEvent(event: ISelectItem): void {
    this.documentTypeSelected = event.item!;
    this.userForm.get('documentNumber')?.setValue('');
    this.changeValidatorsDocumentNumber();
  }
  public getDocumentTypeSpouseEvent(event: ISelectItem): void {
    this.documentTypeSpouseSelected = event.item!;
    this.userForm.get('typeDocumentSpouse')?.setValue(this.documentTypeSpouseSelected?.value || '');
    this.userForm.get('documentNumberSpouse')?.setValue('');
    this.changeValidatorsSpouseDocumentNumber();
  }

  private changeValidatorsDocumentNumber() {
    if (this.documentTypeSelected.value === 'E') {
      this.documentMaxLength = INMIGRATION_CARD_LENGTH;
      this.documentLengthRegex = INMIGRATION_CARD_LENGTH_REGEX;
    } else {
      this.documentMaxLength = DNI_LENGTH;
      this.documentLengthRegex = DNI_LENGTH_REGEX;
    }

    this.userForm
      .get('documentNumber')
      ?.setValidators([Validators.required, Validators.pattern(`[0-9]{${this.documentLengthRegex}}`)]);
    this.userForm.get('documentNumber')?.updateValueAndValidity();
  }
  private changeValidatorsSpouseDocumentNumber() {
    if (this.documentTypeSpouseSelected.value === 'E') {
      this.documentMaxLengthSpouse = INMIGRATION_CARD_LENGTH;
      this.documentLengthRegexSpouse = INMIGRATION_CARD_LENGTH_REGEX;
    } else {
      this.documentMaxLengthSpouse = DNI_LENGTH;
      this.documentLengthRegexSpouse = DNI_LENGTH_REGEX;
    }

    this.userForm
      .get('documentNumberSpouse')
      ?.setValidators([Validators.required, Validators.pattern(`[0-9]{${this.documentLengthRegexSpouse}}`)]);
    this.userForm.get('documentNumberSpouse')?.updateValueAndValidity();
  }

  public pushFinancingEventSendtSolicitude() {
    this.dataLayerService.pushFinancingSolicitudeIntention(
      this.isExternalFinancing,
      this.financingService.derivationSelected
    );
  }

  addGeolocation(geolocation: any) {
    this.latitude = geolocation.lat;
    this.longitude = geolocation.lng;
    this.userForm.get('latitude')?.setValue(this.latitude);
    this.userForm.get('longitude')?.setValue(this.longitude);
    this.userForm.get('address')?.setValue(geolocation.address.trim());
    this.googlePlaceSelected = true;
  }

  private preFillForm(preFormObject: any) {
    this.userForm.get('name')?.setValue(preFormObject.name);

    this.userForm.get('name')?.updateValueAndValidity();
    this.userForm.get('fatherLastName')?.setValue(preFormObject.fatherLastName);

    this.userForm.get('fatherLastName')?.updateValueAndValidity();
    this.userForm.get('motherLastName')?.setValue(preFormObject.motherLastName);

    this.userForm.get('motherLastName')?.updateValueAndValidity();
    this.userForm.get('latitude')?.setValue(preFormObject.latitude);

    this.userForm.get('latitude')?.updateValueAndValidity();
    this.latitude = preFormObject.latitude;
    this.userForm.get('longitude')?.setValue(preFormObject.longitude);

    this.userForm.get('longitude')?.updateValueAndValidity();
    this.longitude = preFormObject.longitude;
    this.currentGeolocation = { lat: preFormObject.latitude, lng: preFormObject.longitude };
    this.userForm.get('address')?.setValue(preFormObject.address);

    this.userForm.get('address')?.updateValueAndValidity();
    this.googlePlaceSelected = true;
    this.userForm
      .get('department')
      ?.setValue(this.geolocationService.getDepartmentByIdAsDropdownItem(preFormObject.department, true).value);
    this.userForm
      .get('province')
      ?.setValue(this.geolocationService.getProvinceByIdAsDropdownItem(preFormObject.province, true).value);
    this.userForm
      .get('district')
      ?.setValue(this.geolocationService.getDistrictByIdAsDropdownItem(preFormObject.district, true).value);
    this.userForm.setControl('ubigeo', this.fb.control(preFormObject.ubigeo));

    this.userForm.get('ubigeo')?.updateValueAndValidity();
    this.dataPreFilledGeolocalitation = {
      department: preFormObject.department,
      province: preFormObject.province,
      district: preFormObject.district,
    };
    this.activedPreFilledValuesGeolocalitation = true;
    this.userForm.get('maritalStatus')?.setValue(preFormObject.maritalStatus);
    let maritalStatus = this.maritalStatusList.find((item) => item.label === preFormObject.maritalStatus);
    if (maritalStatus) {
      this.maritalStatusSelected = maritalStatus;
      this.getSpouseInfo();
      if (preFormObject.maritalStatus?.value == MARRIED_IDENTIFIER) {
        this.userForm.get('typeDocumentSpouse')?.setValue(preFormObject.typeDocumentSpouse);
        let documentTypeSpouse = this.documentTypeList.find((item) => item.value === preFormObject.typeDocumentSpouse);
        if (documentTypeSpouse) {
          this.documentTypeSpouseSelected = documentTypeSpouse;
        }

        this.userForm.get('typeDocumentSpouse')?.updateValueAndValidity();
        this.userForm.get('documentNumberSpouse')?.setValue(preFormObject.documentNumberSpouse);

        this.userForm.get('documentNumberSpouse')?.updateValueAndValidity();
        this.userForm.get('nameSpouse')?.setValue(preFormObject.nameSpouse);

        this.userForm.get('nameSpouse')?.updateValueAndValidity();
        this.userForm.get('lastNameSpouse')?.setValue(preFormObject.lastNameSpouse);

        this.userForm.get('lastNameSpouse')?.updateValueAndValidity();
      }
    }
  }

  private getFormType(): string {
    return FORM_TYPES.GENERAL;
  }

  private validateAgency() {
    this.userForm.get('agencyName')?.setValidators(Validators.required);
    this.userForm.get('agencyName')?.updateValueAndValidity();
  }

  public handleMobileGeolocationClick(geolocation?: any): void {
    this.listAgency = [];
    this.agencySelected = {};
    if (geolocation.value.length === 2) {
      this.departmentId = parseInt(geolocation.value);
      this.provinceId = null;
      this.districtId = null;
      this.userForm.get('department')?.setValue(geolocation?.label);
    } else if (geolocation.value.length === 4) {
      this.provinceId = parseInt(geolocation.value);
      this.districtId = null;
      this.userForm.get('province')?.setValue(geolocation?.label);
    } else if (geolocation.value.length === 6) {
      this.districtId = parseInt(geolocation.value);
      this.userForm.get('district')?.setValue(geolocation?.label);
      this.selectedUbigeo = '01' + geolocation?.value;
      this.userForm.get('ubigeo')?.setValue(this.selectedUbigeo);
      this.getListAgency();
    }
  }

  getListAgency() {
    this.userForm.get('agencyName')?.setValue(null);
    this.financingService
      .getAgencyGeneral(this.departmentId, this.provinceId, this.districtId)
      .subscribe((res: any) => {
        this.listAgency = res;
      });
  }

  patchFormValues() {
    this.userForm.get('businessSalesAmount')?.setValue(Number(this.userForm.get('businessSalesAmount')!.value));
    this.userForm.get('amount')?.setValue(this.parseFormattedNumber(this.userForm.get('amount')!.value));
  }

  goBack() {
    this.location.back();
  }

  formatAmountNumber(number: number): string {
    return number.toLocaleString('es-PE');
  }

  parseFormattedNumber(formattedNumber: string): number {
    return parseInt(formattedNumber.replace(/,/g, ''));
  }

changeEmailReferrer(value: string) {
    this.checkAndToggleValidators(value, 'email');
}

checkAndToggleValidators(value: string, controlName: string) {
  const control = this.userForm.get(controlName);
  const controlValue = control?.value?.trim();

  if (value) {
      this.addValidators(controlName, [Validators.required, Validators.maxLength(100), Validators.pattern(EMAIL_REGEX)]);
  } else if (!controlValue) {
      this.clearValidators(controlName);
  }
}

addValidators(controlName: string, validators: any[]) {
  const control = this.userForm.get(controlName);
  control?.setValidators(validators);
  control?.updateValueAndValidity();
}

clearValidators(controlName: string) {
  const control = this.userForm.get(controlName);
  control?.clearValidators();
  control?.updateValueAndValidity();
}

sendMailchimpRecomended(external: boolean,id:number){
  let referredCode  = this.financingService.defaultRefersData.referredCode;
  if (!referredCode) {
    return;
  }
  this.recommendService.sendFormExistsMailchimp({referred:referredCode,external:external,userId:id}).subscribe();
}

}
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlliedRoutingModule } from './allied-routing.module';
import { AlliedComponent } from './allied.component';
import { AlliedDetailsComponent } from './allied-details/allied-details.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IntranetModule } from '../intranet/intranet.module';
import { SwiperModule } from 'swiper/angular';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { AlliedLogoComponent } from './components/allied-logo/allied-logo.component';
import { BannerComponent } from './components/banner/banner.component';
import { CardComponent } from './components/card/card.component';
import { AlliedModalComponent } from './components/allied-modal/allied-modal.component';

@NgModule({
  declarations: [
    AlliedComponent,
    AlliedDetailsComponent,
    AlliedLogoComponent,
    BannerComponent,
    CardComponent,
    AlliedModalComponent,
  ],
  imports: [
    CommonModule,
    AlliedRoutingModule,
    NgxSkeletonLoaderModule,
    IntranetModule,
    SwiperModule,
    NgbCarouselModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AlliedModule {}

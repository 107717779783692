import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CONST_COMMUNITIES_CODE } from '@shared/constants/communities.constants';
import { CertifiedResponse } from '@shared/interfaces/frontend/communities';
import { UserProfile } from '@shared/models/UserProfileModel';
import { CommunityService } from '@shared/services/community.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { jsPDF } from 'jspdf';
import { breviaBold64, breviaRegular64 } from './base64Ttf';

@Component({
  selector: 'yevo-certified-page',
  templateUrl: './certified-page.component.html',
  styleUrls: ['./certified-page.component.scss'],
})
export class CertifiedPageComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private service: CommunityService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private localStorageService: LocalStorageService,
    public dataLayerService: DataLayerService
  ) { }
  communityCode = 0;
  certificates: CertifiedResponse[] = [];
  certificateSelected!: CertifiedResponse;
  modalRef: NgbModalRef | undefined;
  typeModal: string = '';
  titleModal: string = '';
  documentCertificate!: any;
  pdfBlob!: Blob;
  pdfBase64: any;
  pdfBlobUrl!: string;
  pdfUrl!: SafeResourceUrl;
  doc: any;
  user!: UserProfile;
  srcimgpdf!: string;
  sourcePagePdf!: string;

  breadcrumbs = [
    { pageName: 'Home', url: ['..'], active: true },
    { pageName: 'Certificados', url: null, active: false },
  ];

  @ViewChild('iframeElement', { static: false }) iframeElement!: ElementRef;
  @ViewChild('srcpdftest', { static: false }) srcpdf!: ElementRef;

  async ngOnInit(): Promise<void> {
    const userString = this.localStorageService.get('userProfile');
    if (userString) {
      this.user = JSON.parse(userString) as UserProfile;
      const parentRoute = this.activatedRoute.parent?.routeConfig?.path;
      if (parentRoute) {
        this.communityCode =
          parentRoute === 'emprende-mujer'
            ? CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID
            : CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_ID;
        this.sourcePagePdf =
          this.communityCode === CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID ? 'Emprende Mujer' : 'de Bodegueros';
        this.srcimgpdf =
          this.communityCode === CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID
            ? 'assets/img/communities/certificate/certificado_side_image_em.png'
            : 'assets/img/communities/certificate/certificado_side_image_bodegueros.png';
      }
      this.certificates = await this.service
        .getCertifiedByUserAndCommunity(this.user.documentNumber, this.communityCode)
        .toPromise();
      this.certificateSelected = this.certificates[0];
    }
  }

  goHome(): Promise<boolean> {
    return this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  openModal(content: any, certificate: CertifiedResponse) {
    this.certificateSelected = certificate;

    let typeDataLayer = certificate.type.toLowerCase() === 'webinar' ? 'W' : 'PE';
    if (this.communityCode === CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID) {
      this.dataLayerService.virtualEventEM(`Vista previa: ${typeDataLayer}: ${certificate.certificateCentralName}`, 17);
    } else {
      this.dataLayerService.virtualEventBodegueros(
        `Vista previa: ${typeDataLayer}: ${certificate.certificateCentralName}`,
        16
      );
    }

    const doc = new jsPDF({
      orientation: 'landscape',
    });

    doc.addImage(
      `${this.communityCode === CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID
        ? 'assets/img/communities/certificate/certificado_side_image_em.png'
        : 'assets/img/communities/certificate/certificado_side_image_bodegueros.png'
      }`,
      'PNG',
      0,
      0,
      80,
      210
    );
    doc.addFileToVFS('brevia-bold.ttf', breviaBold64);
    doc.addFileToVFS('brevia-regular.ttf', breviaRegular64);
    doc.addFont('brevia-bold.ttf', 'breviaBold64', 'bold');
    doc.addFont('brevia-regular.ttf', 'breviaRegular64', 'normal');

    doc.setFontSize(25);
    doc.setFont('breviaBold64', 'bold');
    doc.setTextColor('#009530');
    doc.text(certificate.certificateCentralName, 190, 50, { align: 'center' });

    doc.setTextColor('#000000');
    doc.addImage('assets/img/communities/certificate/certificado_logo.png', 'PNG', 161, 5, 55, 35);
    doc.setFontSize(20);
    doc.setFont('breviaRegular64', 'normal');
    doc.text('Otorgado a', 190, 65, { align: 'center' });

    doc.setFontSize(25);
    doc.setFont('breviaBold64', 'bold');
    doc.text(certificate.completeName, 190, 80, { align: 'center' });

    doc.setFontSize(15);
    doc.setFont('breviaRegular64', 'normal');
    doc.text(`Por culminar satisfactoriamente ${certificate.toFinish}`, 190, 90, { align: 'center' });

    doc.setFontSize(22);
    doc.setFont('breviaBold64', 'bold');
    doc.text(certificate.certificateName, 190, 100, { align: 'center' });

    doc.setFontSize(15);
    doc.setFont('breviaRegular64', 'normal');
    doc.text(
      `durante ${certificate.hours} horas elaborado por la Comunidad ${this.communityCode === CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID ? 'Emprende Mujer' : 'de Bodegueros'
      } de Yevo de Mibanco`,
      190,
      110,
      {
        maxWidth: 140,
        align: 'center',
      }
    );

    doc.setFontSize(15);
    doc.text(this.transformDate(certificate.dateCertificate), 190, 130, { align: 'center' });
    doc.addImage(`assets/img/communities/certificate/certificado_firma.png`, 'PNG', 150, 140, 88, 30);
    doc.setFontSize(14);
    doc.setTextColor('#231F20');
    doc.text('Laura Villanueva', 190, 180, { align: 'center' });
    doc.setFontSize(11);
    doc.text('Gerente de Marca y conocimiento del cliente', 190, 185, { align: 'center' });

    doc.addImage('assets/img/communities/certificate/certificado_bottom_image.png', 'PNG', 80, 200, 220, 10);
    this.doc = doc;
    this.documentCertificate = doc.output('arraybuffer');

    this.pdfBlob = new Blob([this.documentCertificate], { type: 'application/pdf' });
    this.pdfBlobUrl = URL.createObjectURL(this.pdfBlob);
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfBlobUrl + '#toolbar=0');
    this.showModal(content, certificate);
  }
  showModal(content: any, certificate: CertifiedResponse) {
    this.typeModal = `Certificado del ${certificate.type.toLowerCase()}`;
    this.titleModal = certificate.certificateName;
    this.modalRef = this.modalService.open(content, { centered: true, windowClass: 'modal_certificate' });
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  downloadpdf() {
    if (this.communityCode === CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_ID) {
      this.dataLayerService.virtualEventEM(`${this.titleModal}: Descargar`, 17);
    } else {
      this.dataLayerService.virtualEventBodegueros(`${this.titleModal}: Descargar`, 16);
    }
    this.doc.save('certificate.pdf');
  }
  transformDate(inputDate: string) {
    const dateParts = inputDate.split('/');
    if (dateParts.length !== 3) {
      return 'Formato de fecha incorrecto. Debe ser dd/MM/yyyy';
    }

    const day = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1;
    const year = parseInt(dateParts[2]);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return 'Formato de fecha incorrecto. Los valores deben ser numéricos.';
    }

    const months = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ];

    const dateObj = new Date(year, month, day);

    const dayNumber = dateObj.getDate();
    const monthName = months[dateObj.getMonth()];
    const yearValue = dateObj.getFullYear();

    return `${dayNumber} de ${monthName} del ${yearValue}`;
  }
}

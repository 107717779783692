<div class="webinars">
  <div class="row m_0">
    <div class="col-12 mt-3">
      <h1 class="webinars_title">¡No te pierdas nuestros webinars!</h1>
    </div>
    <div class="col-12">
      <p class="webinars_description">Despeja tus dudas con nuestros expertos</p>
    </div>
    <div class="col-12 dp_f jc_c_d scroll pb_10 pl_none_320">
      <div class="webinars_card" *ngFor="let topic of topics">
        <div class="row webinars_poster">
          <img class="webinars_poster_img" [src]="topic.thumbnail" alt="topic" />
        </div>
        <div class="row webinars_card_title">
          <div class="col-12">
            <p class="webinars_card_title_title">{{ topic.description }}</p>
          </div>
          <div class="col-12 ta_c mt-3 mb-3">
            <button class="webinars_card_title_button" (click)="showTopic(topic)">Ver webinar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 ta_c mt_26 mb-3">
      <button (click)="showAllTopics()" class="webinars_button">Ver más webinars</button>
    </div>
  </div>
</div>

<div class="modal-body">
  <div class="logo d-flex justify-content-center">
    <img src="/assets/img/logo.png" alt="Logo de MiBanco" />
  </div>
  <p class="message">
    ¡Oh no! Tenemos un problema y no se puede continuar. Por favor, vuelve a intentar registrarte más tarde.
  </p>
  <div class="button-container d-flex justify-content-center">
    <div class="accept-button">
      <yevo-button (buttonClick)="activeModal.close()">Aceptar</yevo-button>
    </div>
  </div>
</div>

import { ElementRef } from '@angular/core';
import { FormGroup, ValidationErrors, Validators } from '@angular/forms';

export function getErrorsFromField(field: string, form: FormGroup): ValidationErrors | null | undefined {
  return form.get(field)?.errors;
}

export function showError(field: string, dirty: boolean = false, form: FormGroup): boolean | undefined {
  const control = form.get(field)!;
  return dirty ? control.invalid && (control.dirty || control.touched) : control.invalid && control.touched;
}

export function trimField(field: string, form: FormGroup) {
  return form.get(field)?.setValue(form.get(field)?.value.trim());
}

export function clearFieldValidation(form: FormGroup, formcontrolName: string): void {
  form.get(formcontrolName)?.setValidators(Validators.nullValidator);
  form.get(formcontrolName)?.setValue(null);
  form.get(formcontrolName)?.markAsUntouched();
  form.get(formcontrolName)?.markAsPristine();
  form.get(formcontrolName)?.reset();
}

export function isInvalidControl(form: FormGroup, key: any) {
  return !form.get(key)?.valid;
}

export function formMarkAsTouched(
  form: FormGroup,
  isTouched: boolean = true,
  controls: any,
  evaluateKeys: boolean = false
) {
  const keys = evaluateKeys ? (<any>Object).keys(controls) : (<any>Object).values(controls);
  keys.forEach((control: any) => {
    isTouched ? form.get(control)?.markAsTouched() : form.get(control)?.markAsUntouched();
  });
}

export function isFormInvalid(form: FormGroup, keys: any, evaluateKeys: boolean = false): boolean {
  let isInvalid = false;
  for (let value of Object.keys(keys)) {
    const toEvaluate = evaluateKeys ? value : keys[value];
    if (isInvalidControl(form, toEvaluate)) {
      isInvalid = true;
      break;
    }
  }
  return isInvalid;
}

export function focusFormControl(form: FormGroup, elementReference: ElementRef): void {
  for (const key of Object.keys(form.controls)) {
    if (form.controls[key].invalid) {
      const invalidControl = elementReference.nativeElement.querySelector('[formcontrolname="' + key + '"]');
      /** Exclude for the customized radiobutton (ex: seedcash insurance card) */
      if (invalidControl && invalidControl?.type !== 'radio') {
        invalidControl?.focus();
        break;
      } else {
        /** For reusable components we rely on css classes called the same as the formControl */
        let hElement: HTMLElement = elementReference.nativeElement;
        let divElement = hElement.getElementsByClassName(key)[0];
        if (divElement) {
          const headerOffset = 100;
          const elementPosition = divElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
          break;
        }
      }
    }
  }
}

export function focusFormDiv(key: string, elementReference: ElementRef): void {
  let hElement: HTMLElement = elementReference.nativeElement;
  let divElement = hElement.getElementsByClassName(key)[0];
  if (divElement) {
    const headerOffset = 100;
    const elementPosition = divElement.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RouterLinkModel, SocialNetworkModel } from '@shared/models';
import { DashboardService } from '@shared/services/dashboard.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { MailchimpTransactionalService } from '@shared/services/mailchimp-transactional.service';
import {
  footerFirstColumnRoutes,
  footerFirstColumnRoutesLogin,
  footerFiveColumnRoutesLogin,
  footerFourColumnRoutesLogin,
  footerSecondColumnRoutes,
  footerSecondColumnRoutesLogin,
  footerThirdColumnRoutes,
  footerThirdColumnRoutesLogin,
  footerThirdColumnRoutesLoginMobile,
  socialNetworks,
} from '@shared/utils/get-social-footer';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { footerFiveColumnRoutes, footerFourColumnRoutes } from '../../shared/utils/get-social-footer';
import { ModalRegisterComponent } from '@shared/components/modal-register/modal-register.component';
import { CommunityService } from '@shared/services/community.service';
import { CourseService } from '@shared/services/course.service';
import { UserProfile } from '@shared/models/UserProfileModel';

@Component({
  selector: 'yevo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  INDEX_FIRST_LIST: number = 0;
  INDEX_SECOND_LIST: number = 1;

  IS_VISIBLE_FIRST_LIST: boolean = true;
  IS_VISIBLE_SECOND_LIST: boolean = true;
  IS_VISIBLE_THIRD_LIST: boolean = true;
  IS_VISIBLE_FOURT_LIST: boolean = true;
  IS_VISIBLE_FIVE_LIST: boolean = true;
  IS_VISIBLE_SIX_LIST: boolean = true;

  isVisible: boolean[] = [
    this.IS_VISIBLE_FIRST_LIST,
    this.IS_VISIBLE_SECOND_LIST,
    this.IS_VISIBLE_THIRD_LIST,
    this.IS_VISIBLE_FOURT_LIST,
    this.IS_VISIBLE_FIVE_LIST,
    this.IS_VISIBLE_SIX_LIST,
  ];

  footerFirstColumnRoutes = footerFirstColumnRoutes;
  footerSecondColumnRoutes = footerSecondColumnRoutes;
  footerThirdColumnRoutes = footerThirdColumnRoutes;
  footerFourColumnRoutes = footerFourColumnRoutes;
  footerFiveColumnRoutes = footerFiveColumnRoutes;
  footerThirdColumnRoutesMobile = footerThirdColumnRoutesLoginMobile;
  socialNetworks = socialNetworks;
  hideFooter$: Observable<boolean>;
  isLOgged = false;
  navigate: string = '/';
  YEAR!: number;
  openModalSeidor: boolean = false;

  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  userclient!: UserProfile;
  userId!: number;
  menuItemRecommendActive: boolean = false;

  constructor(
    public authService: AuthService,
    public dataLayerService: DataLayerService,
    private router: Router,
    private dashboardService: DashboardService,
    private LocalStorageService: LocalStorageService,
    private mailchimpTransactionalService: MailchimpTransactionalService,
    private modalService: NgbModal,
    private communityService: CommunityService,
    private courseService: CourseService
  ) {
    this.hideFooter$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(this.isInFinancingRoute)
    );
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLOgged = true;
        this.getHeaderLinks(logged);
      }
    });
  }

  getHeaderLinks(isLogged: boolean) {
    if (isLogged) {
      this.footerFirstColumnRoutes = footerFirstColumnRoutesLogin;
      this.footerSecondColumnRoutes = footerSecondColumnRoutesLogin;
      this.footerThirdColumnRoutes = footerThirdColumnRoutesLogin;
      this.footerFourColumnRoutes = footerFourColumnRoutesLogin;
      this.footerFiveColumnRoutes = footerFiveColumnRoutesLogin;
    }
  }

  ngOnInit() {
    this.navigateLogin();
    this.YEAR = new Date().getFullYear();
    const userProfile = this.LocalStorageService.get('userProfile');
    if (userProfile) {
      this.userclient = JSON.parse(userProfile) as UserProfile;
      this.userId = this.userclient.userId;
    }
  }

  footerLinkOnClick(event: any) {
    this.dataLayerService.pushFooterMenu(event.target.innerText);
  }

  goHeaderSection() {
    document.getElementById('#yevoHeader')?.scrollIntoView({ behavior: 'smooth' });
  }

  isHidden(hiddenIn?: string) {
    return this.authService.isLogged$.pipe(map((logged) => !(logged && hiddenIn === 'dashboard')));
  }

  trackByIndex(index: number, item: RouterLinkModel | SocialNetworkModel) {
    return index;
  }

  isInFinancingRoute(data: any) {
    return data.url === '/nuevo-intranet/financiamiento';
  }

  questionOnClick(index: number) {
    this.isVisible[index] = !this.isVisible[index];
  }

  navigateLogin() {
    if (this.isLOgged) {
      this.navigate = '/nuevo-intranet/dashboard';
    } else {
      this.navigate = '/';
    }
  }

  onLinkCourses(courseId: string) {
    if (this.isLOgged) {
      this.router.navigate(['/capacitaciones', { queryParamsHandling: 'merge' }]);
    } else {
      this.router.navigate(['/registro', 'paso-1']);
    }
  }

  gotoCalendar() {
    this.dashboardService.changeCalendar(true);
    this.LocalStorageService.set('activeCalendar', 'true');
    if (this.isLOgged) {
      this.router.navigate(['/nuevo-intranet', 'dashboard']);
    } else {
      this.router.navigate(['/ingresar']);
    }
  }

  isRemoveCalendar() {
    this.dashboardService.changeCalendar(false);
    this.LocalStorageService.set('activeCalendar', 'false');
  }

  goToSimulator(footerRoute: any) {
    if (this.isLOgged && footerRoute?.isSimulator) {
      this.mailchimpTransactionalService.postSimulatorEmail().subscribe();
    }
  }

  onLink(link: any) {
    if (link?.goTo === 'calendar') {
      this.LocalStorageService.set('activeCalendar', 'true');
      this.dashboardService.changeCalendar(true);
    }

    if (link?.goTo === 'community') {
      this.goToCommunity();
      return;
    }

    if (!this.isLOgged && link?.goTo === 'modalRegister') {
      this.openModalRegister();
      return;
    }

    if (this.isLOgged && link?.goTo === 'trainingDownload') {
      this.goTotrainingDownload();
      return;
    }
    
    if (link.linkExternal) {
      this.goToExternalPage(link?.routerLink);
      return;
    }

    this.router.navigate(link?.routerLink, { queryParamsHandling: 'merge' });
  }

  openModalRegister() {
    this.modalService.open(ModalRegisterComponent, this._modalOptionsNormal);
  }

  goToCommunity() {
    sessionStorage.setItem('communityWoman', 'true');
    this.communityService.changeCommunitySection(true);
    setTimeout(() => {
      this.router.navigate(['/comunidades']);
    }, 300);
  }

  goTotrainingDownload() {
    sessionStorage.setItem('trainingDownload', 'true');
    this.courseService.changeDownloadSection(true);
    setTimeout(() => {
      this.router.navigate(['/capacitaciones']);
    }, 300);
  }

  goToExternalPage(url: string) {
    window.open(url, '_blank');
  }

  validMenuRecommended(client: boolean, worker: boolean) {
    this.footerFirstColumnRoutes[1].isRecommend = client && !worker;
  }
}

<div class="dp_f">
  <div class="swiper-button-prev d-none">
    <img src="assets/icons/carousel-indicator-prev-icon-black.svg" alt="arrow-left" />
  </div>
  <swiper
    #swiperDashboardCourseDesktop
    class="swiperDashboardCourseDesktop col-12 pl-md-0 pr-md-0 training-dashboard-banner d-block d-md-block"
    [config]="configSwiperDesktop"
    modules="{[Pagination,Autoplay]}"
    [navigation]="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }"
    [pagination]="false"
    [direction]="'horizontal'"
  >
    <ng-template swiperSlide [ngIf]="courses.length" *ngFor="let course of courses">
      <div class="banner_card" (click)="verCourse(course)">
        <div class="col-12 p_0 m_0">
          <p class="banner_card_title">{{ course.title }}</p>
        </div>
        <div class="col-12 m_0 p_0">
          <p class="banner_card_description">
            {{ course.description }}
          </p>
        </div>
        <div class="col-12 container-button">
          <button class="banner_card_button">Empezar curso</button>
        </div>
      </div>
    </ng-template>
  </swiper>
  <div class="swiper-button-next ps-5">
    <img src="assets/icons/arrowright.png" alt="arrow-right" class="cursor-pointer" />
  </div>
</div>

import { take } from 'rxjs/operators';
import { TopicModel } from 'src/app/shared/models/Topic.model';
import { TopicOptionModel } from 'src/app/shared/models/TopicOption.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { DirectoryService } from 'src/app/shared/services/directory.service';
import { ImageVideoService } from 'src/app/shared/services/image-video.service';
import { SwiperComponent } from 'swiper/angular';

import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarousel, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { VIDEOS_CODES } from '@shared/constants/course.constants';
import { allyBenefitsData, carouselSlidesData, tabItemData } from '@shared/data/training/course.json';
import { Course, ITabItem, ITrainingBannerItem } from '@shared/interfaces';
import { IDropdownItem } from '@shared/interfaces/IDropdownItem';
import { IintranetCardModel, IntranetCardModel } from '@shared/models/IntranetCardModel';
import { CourseService } from '@shared/services/course.service';
import { DashboardService } from '@shared/services/dashboard.service';
import { UserInteractionLogService } from '@shared/services/user-interaction-log.service';
import { getRandomItemFromArray } from '@shared/utils/get-random-item-from-array';
import { stripHtml } from '@shared/utils/strip-html.utils';

@Component({
  selector: 'yevo-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss'],
})
export class TrainingComponent implements OnInit {
  breadcrumbs: any = [
    { pageName: 'Inicio', url: ['/nuevo-intranet', 'dashboard'], active: true },
    { pageName: 'Capacitaciones', url: null, active: false },
  ];
  topics?: TopicModel[];
  topic = {} as TopicModel;
  topicOption: TopicOptionModel = {} as TopicOptionModel;
  showDetail = false;
  code = '';

  page = 1;
  pageSize = 8;
  total = 0;
  codeSelected = '';

  numberTopicOptionsShowed: number = 5;
  topicOptions: TopicOptionModel[] = [];
  topicOptionsDropdown: IDropdownItem[] = [];
  selectedTopic: IDropdownItem | null = null;
  topicsAll: TopicModel[] = [];
  topicsMostRecent: TopicModel[] = [];
  topicsMostViewed: TopicModel[] = [];
  topicsRecommended: TopicModel[] = [];
  showTopicContent: boolean = false;
  showTabContent: boolean = false;
  userSegmentCode!: string;

  swiperBenefitsBreakpoints = {
    679: {
      slidesPerView: 2,
    },
    1400: {
      slidesPerView: 3,
    },
  };
  benefitsCurrentPage = 1;

  tabItems: ITabItem[] = tabItemData;
  selectedTab!: ITabItem;

  allPosts: TopicModel[] = [];
  auxAllPosts: TopicModel[] = [];

  allyBenefits: IintranetCardModel[] = allyBenefitsData;

  courses: Course[] = [];
  auxCourses: Course[] = [];

  paused: boolean = false;
  unpauseOnArrow: boolean = false;
  pauseOnIndicator: boolean = false;
  pauseOnHover: boolean = true;
  pauseOnFocus: boolean = false;
  interval: number = 2000;
  carouselSlidesArray: ITrainingBannerItem[] = carouselSlidesData;

  @ViewChild('swiperRef', { static: false }) swipeComponent?: SwiperComponent;
  @ViewChild('carousel', { static: true }) carousel?: NgbCarousel;

  utm_campaign: string = '';
  utm_content: string = '';
  utm_medium: string = '';
  utm_source: string = '';

  subscription$: any;
  subscription$Video: any;

  theme = 'theme-1';
  refreshIntervalId: any;
  counter = 0;

  allWebinars: TopicModel[] = [];
  firsWebinars: TopicModel[] = [];

  allDataSearch: any[] = [];
  dataSearch: any[] = [];
  auxDataSearch: any[] = [];
  valueSearch: string = '';
  resultSearch: boolean = true;
  pageSearch = 1;
  isSearch: boolean = false;
  parentRoute = '';
  webinarsMostVisited: any = [];
  webinarsVideoAux: any[] = [];
  reloadTab: boolean = true;
  tabsAndFiltersRestore: boolean = false;
  allCourses: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public imageVideoService: ImageVideoService,
    public dataLayerService: DataLayerService,
    public authService: AuthService,
    public sanitizer: DomSanitizer,
    private directoryService: DirectoryService,
    private userInteractionLogService: UserInteractionLogService,
    private router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    public activateRoute: ActivatedRoute,
    @Inject(DOCUMENT) private _document: any,
    config: NgbTypeaheadConfig,
    private dashboardService: DashboardService,
    private courseService: CourseService
  ) {
    this.getAllCourses();
    this.activateRoute.queryParams.subscribe((params) => {
      this.utm_campaign = params.utm_campaign || '';
      this.utm_content = params.utm_content || '';
      this.utm_medium = params.utm_medium || '';
      this.utm_source = params.utm_source || '';
    });
    config.showHint = true;
    sessionStorage.removeItem('capacitacionesBreadcrumbs');
    sessionStorage.removeItem('isBusinessDiagnosisBreadcrumbs');
  }

  ngOnInit(): void {
    this.parentRoute = this.router.url;

    this.selectedTab = this.tabItems[0];
    this.directoryService
      .getTopicsAll()
      .pipe(take(1))
      .subscribe((items) => {
        const itemsWithoutQuechua = items.filter((item) => item?.parameterId != VIDEOS_CODES.CODE_QUECHUA_TOPICS);
        this.topicsAll = itemsWithoutQuechua;
        this.getTopicsMostRecent(this.topicsAll);
        this.getTopicsMostViewed(this.topicsAll);
      });

    this.getAllPosts();
    this.getTopicOptions();
    this.setNumberItemsShowed(window.innerWidth);
    this.initFills();
    this.isActiveVideo();
    this.courseService.activeDownload.subscribe((data) => {
      if (data) {
        this.goToCommunities(data);
      }
    });
  }

  getAllCourses() {
    this.courseService.getAllCourses().subscribe((resp: any) => {
      if (resp) {
        this.allCourses = resp;
        this.courses = resp;
        this.auxCourses = resp;

        this.isActiveCourse();
      }
    });
  }

  initFills() {
    let initTabs: any = this.tabItems.map((value: any) => {
      if (value.label === 'Todos') {
        return { ...value, active: true };
      } else {
        return { ...value, active: false };
      }
    });

    this.tabItems = initTabs;
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.subscription$Video.unsubscribe();
  }

  handleClickPreviousItem(currentPage: number): void {
    this.benefitsCurrentPage = currentPage;
    this.swipeComponent?.swiperRef?.slidePrev();
  }

  handleClickNextItem(currentPage: number): void {
    this.benefitsCurrentPage = currentPage;
    this.swipeComponent?.swiperRef?.slideNext();
  }

  onSlideChange($event: any): void {
    // NOTE: We're summing up because swipe componente return a 0 based index
    const activeIndex = $event.activeIndex + 1;
    this.benefitsCurrentPage = activeIndex;
    // Using changeDectetorRef since tiny-pagination component is missing
    // the detection of swipe component events
    this.changeDetectorRef.detectChanges();
  }

  handleClickAllyBenefits(cardInfo: IntranetCardModel) {
    this.dataLayerService.pushClickIntranetCard('AllyBenefits', cardInfo.title);
  }

  getAllPosts(): void {
    this.directoryService
      .getAllPosts()
      .pipe(take(1))
      .subscribe((items) => {
        this.allPosts = items;
        this.auxAllPosts = items;
        this.allWebinars = items.filter((post) => post.type == 'webinar');

        this.webinarsMostVisited = items
          .filter((post) => post.type == 'webinar')
          .sort((a: any, b: any) => b.totalVisitedNumber - a.totalVisitedNumber)
          .slice(0, 3)
          .map((item) => ({ ...item, mostVisited: true }));

        this.webinarsVideoAux = this.webinarsMostVisited;

        this.firsWebinars = this.allWebinars.slice(0, 4);
        this.getRecommendedForYou();
        this.allDataSearch = items.map((item) => ({ title: item.title }));
        this.allDataSearch = [
          ...this.allDataSearch,
          ...this.auxCourses.map((item) => ({ title: item.title, type: 'course' })),
        ];
      });
  }

  getTopicsMostRecent(topicsAll: TopicModel[]): void {
    let filteredTopics = topicsAll.filter((topic) => topic.type === 'video');
    this.sortTopicsByCreationDate(filteredTopics);
  }

  sortTopicsByCreationDate(filteredTopics: TopicModel[]) {
    let sortedTopics = filteredTopics.sort(
      (a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
    );
    let top12Topics = sortedTopics.slice(0, 12);
    this.topicsMostRecent = top12Topics;
  }

  getTopicsMostViewed(topicsAll: TopicModel[]): void {
    let filteredTopics = topicsAll.filter((topic) => topic.type === 'video');
    this.sortTopicsByViews(filteredTopics);
  }

  sortTopicsByViews(filteredTopics: TopicModel[]) {
    let sortedTopics = filteredTopics.sort((a, b) => {
      if (a.visitedNumberLastMonth && b.visitedNumberLastMonth) {
        return b.visitedNumberLastMonth - a.visitedNumberLastMonth;
      }
      return 0;
    });
    let top12Topics = sortedTopics.slice(0, 12);
    this.topicsMostViewed = top12Topics;
  }

  getRecommendedForYou(): void {
    this.authService.userProfile$.subscribe((user) => {
      // If user doesn't have segmentCode, use `26001` by default.
      this.userSegmentCode = user?.segmentCode || VIDEOS_CODES.CODE_MICRO_ENTREPRENEUR;
      this.topicsRecommended = this.allPosts.filter((post) =>
        post.segmentCodes?.split(',').includes(this.userSegmentCode.toString())
      );
      // Get random items when topics are greater than 4
      if (this.topicsRecommended.length > 4) {
        this.topicsRecommended = getRandomItemFromArray(this.topicsRecommended, 4);
      }

      return this.topicsRecommended;
    });
  }

  getTopicOptions(): void {
    this.directoryService.getTopics().subscribe((items) => {
      const sortedItems = items.slice().sort((a, b) => (a.counter! > b.counter! ? -1 : 1));
      this.topicOptions = sortedItems;

      this.topicOptionsDropdown = sortedItems.map((item) => ({
        label: item.name,
        value: item.code,
        counter: item.counter,
      }));
      this.selectedTopic = this.topicOptionsDropdown[0];
      this.selectedTab = this.tabItems[0];
    });
  }

  getTopicsSelected(code: string, name: string): void {
    if (!this.tabsAndFiltersRestore) {
      this.clearExternalSearch();
    }
    this.dataSearch = [];
    this.isSearch = false;
    this.resultSearch = false;

    if (!!this.selectedTopic && code === this.selectedTopic.value) {
      return;
    }

    this.selectedTopic = {
      value: code,
      label: name,
    };

    this.dataLayerService.pushTrainingOption(name);
    this.page = 1;
    // SHOW ALL SECTIONS PILL
    if (code == VIDEOS_CODES.CODE_ALL_TOPICS && this.selectedTab?.label == 'Todos') {
      this.codeSelected = '';
      this.showTopicContent = false;
    } else {
      this.showTopicContent = true;
      this.getTopics(code, this.selectedTab?.code);
    }
    this._document.body.classList.remove('overflow-hidden');
  }

  getTopics(code: string, codeTab: string = ''): void {
    let allPostsByTopic: TopicModel[] = [];
    if (code == VIDEOS_CODES.CODE_ALL_TOPICS && codeTab == '') {
      this.codeSelected = '';
      this.showTopicContent = false;
      return;
    }

    if (code) {
      this.codeSelected = code;
      if (codeTab && codeTab.trim() != '') {
        allPostsByTopic = this.allPosts.filter(
          (x) => x.parameterIds?.includes(this.codeSelected) && codeTab.includes(x.type)
        );
      } else {
        allPostsByTopic = this.allPosts.filter((x) => x.parameterIds?.includes(this.codeSelected));
      }
      this.showTabContent = code == VIDEOS_CODES.CODE_ALL_TOPICS;
    } else if (codeTab) {
        allPostsByTopic = this.allPosts.filter(
          (x) =>
            x.parameterIds?.includes(this.codeSelected) &&
            !x.parameterIds?.includes(VIDEOS_CODES.CODE_QUECHUA_TOPICS) &&
            codeTab.includes(x.type)
        );
        this.showTabContent = true;
    }

    this.total = allPostsByTopic.length;
    this.showRows(allPostsByTopic);
  }

  showRows(topics: TopicModel[]): void {
    this.total = topics.length;
    const init = (this.page - 1) * this.pageSize;
    const last = this.page * this.pageSize;
    const end = this.total < last ? this.total : last;
    this.topics = [];
    for (let i = init; i < end; i++) {
      this.topics.push(topics[i]);
    }
  }

  setNumberItemsShowed(windowWidth: number): void {
    if (windowWidth <= 900) {
      // Tablet portview
      this.pageSize = 6;
    } else if (windowWidth <= 1200) {
      // Desktop portview
      this.pageSize = 8;
    } else {
      // Desktop Widescreen portview
      this.pageSize = 8;
      this.numberTopicOptionsShowed = 8;
    }
  }

  @HostListener('window:resize', ['$event'])
  actionsOnResize(event: any): void {
    this.setNumberItemsShowed(window.innerWidth);
  }

  putTopicSelected(code: any) {
    const topicSelect = this.topicOptions.filter((po) => po.code == code);
    if (topicSelect?.length > 0) {
      this.getTopicsSelected(topicSelect[0].code, topicSelect[0].name);
    }
  }

  back(): void {
    this.topic = {} as TopicModel;
    this.showDetail = false;
  }

  viewShowDetail(topic: TopicModel, section?: string): void {
    this.logUserAction(topic.title);
    this.dataLayerService.pushTrainingKnowMoreOption(topic.title);
    this.topic = topic;
    this.router.navigate(['/capacitaciones', topic.slug], { queryParamsHandling: 'merge' });

    if (section) this.pushModuleItemEvent(section, topic.title);
  }

  logUserAction(topicTitle: string) {
    this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
      this.userInteractionLogService.logUserAction(user?.documentNumber!, 'click', topicTitle, 'Asesoría');
    });
  }

  pageChangeEvent(page: number): void {
    this.page = page;
    this.getTopics(this.codeSelected, this.selectedTab.code);
  }

  stop(event: any) {
    event.stopPropagation();
  }

  handleSelectedTab(value: ITabItem): void {
    if (!this.tabsAndFiltersRestore) {
      this.clearExternalSearch();
    }
    this.dataSearch = [];
    this.isSearch = false;
    this.resultSearch = false;
    this.selectedTab = value;
    this.getTopics(this.codeSelected, this.selectedTab.code);
    this.showTabContent = !!this.selectedTab.code;

    this.pushTabEvent(this.selectedTab.label);

    this.tabItems = tabItemData.map((item) => {
      if (item.label === value.label) {
        return { ...item, active: true };
      } else {
        return { ...item, active: false };
      }
    });
  }

  pushBannerEvent(bannerName: string): void {
    this.dataLayerService.pushTrainingBanner(bannerName);
  }

  pushTabEvent(tabName: string): void {
    this.dataLayerService.pushTrainingTab(tabName);
  }

  pushModuleItemEvent(section: string, moduleName: string): void {
    this.dataLayerService.pushTrainingModule(section, moduleName);
  }

  pushAdBannerEvent(buttonName: string): void {
    this.dataLayerService.pushTrainingAdBanner(buttonName);
  }

  isImageDefault(length: any) {
    return length && (length + 1) % 3 === 0;
  }

  getTextFromHtml(html: string): string {
    return stripHtml(html);
  }

  isActiveCourse() {
    this.subscription$ = this.dashboardService.activeCourse.subscribe((data) => {
      if (data && this.allCourses.length > 0) {
        this.tabItems = tabItemData.map((item, index) => {
          if (index === 2) {
            return { ...item, active: true };
          } else {
            return { ...item, active: false };
          }
        });

        this.getSelectTabCourse(data);
        setTimeout(() => {
          this.dashboardService.changeCourse(false);
        }, 3000);
      }
    });
  }

  getSelectTabCourse(data: boolean) {
    if (data) {
      this.selectedTab = { label: 'Cursos', icon: 'play', active: true, code: 'course' };

      this.getTopics(this.codeSelected, this.selectedTab.code);

      this.showTabContent = true;
      this.pushTabEvent(this.selectedTab.label);
    }
  }

  isActiveVideo() {
    this.subscription$Video = this.dashboardService.activeVideo.subscribe((data) => {
      if (data) {
        this.tabItems = tabItemData.map((item, index) => {
          if (index === 3) {
            return { ...item, active: true };
          } else {
            return { ...item, active: false };
          }
        });
        this.directoryService
          .getAllPosts()
          .pipe(take(1))
          .subscribe((items) => {
            this.allPosts = items;
            this.getSelectTabVideo(data);
          });
      }
    });
  }

  getSelectTabVideo(data: boolean) {
    if (data) {
      this.selectedTab = { label: 'Videos', icon: 'right-arrow', active: false, code: 'video' };
      let allPostsByTopic: TopicModel[] = [];

      allPostsByTopic = this.allPosts.filter((x) => x.type == 'video');

      this.total = allPostsByTopic.length;
      this.showRows(allPostsByTopic);

      this.showTabContent = true;
      this.showDetail = false;

      this.pushTabEvent(this.selectedTab.label);

      setTimeout(() => {
        this.dashboardService.changeVideo(false);
      }, 5000);
    }
  }

  trainingSelected(item: any) {
    this.pageSearch = 1;
    this.hideKeyboard();
    this.dataSearch = [];
    this.restoreTabsAndFilters();

    if (item.title) {
      item.name = item.title;
    }
    if (item.name != '' && item.name != undefined && item.name != null) {
      let topics = this.auxAllPosts.filter(
        (x) =>
          x.title
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(
              item.name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
            ) ||
          x.description
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(
              item.name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
            ) ||
          x.type
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(
              item.name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
            )
      );

      let courses = this.auxCourses.filter(
        (x) =>
          x.title
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(
              item.name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
            ) ||
          x.description
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(
              item.name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
            )
      );
      if (courses.length > 0) {
        courses = courses.map((item) => ({ ...item, type: 'course' }));
      }
      const total = [...topics, ...courses];

      this.auxDataSearch = total;
      this.dataSearch = total.slice(0, 8);
      this.resultSearch = Boolean(total.length);
      this.isSearch = Boolean(!total.length);
      let reviewSearch: any = [];
      this.initFills();

      // historySearchCapacitaciones
      const history = this.getSearchHistory();
      if (history) {
        if (history.length == 4) {
          history.shift();
          history.push(item);
          localStorage.setItem('historySearchCapacitaciones', JSON.stringify(history));
          reviewSearch = history.reverse();
        } else {
          history.push(item);
          localStorage.setItem('historySearchCapacitaciones', JSON.stringify(history));
          reviewSearch = history.reverse();
        }
      } else {
        localStorage.setItem('historySearchCapacitaciones', JSON.stringify(total));
        reviewSearch = total;
      }

      this.webinarsMostVisited = [...reviewSearch.slice(0, 4), ...this.webinarsVideoAux];
    } else if (item.name === '' && item.type == 'search') {
      let reviewSearch: any = [];
      const history = this.getSearchHistory();
      if (history) {
        reviewSearch = history.reverse();
        this.webinarsMostVisited = [...reviewSearch.slice(0, 4), ...this.webinarsVideoAux];
      } else {
        this.webinarsMostVisited = [...this.webinarsVideoAux];
      }
    }
  }

  getSearchHistory() {
    let historySearchCapacitaciones = localStorage.getItem('historySearchCapacitaciones');
    if (historySearchCapacitaciones) {
      return JSON.parse(historySearchCapacitaciones);
    }
    return null;
  }

  pushSearchResultEvent(searchTerm: any): void {
    this.resultSearch = Boolean(searchTerm.length);
    this.isSearch = Boolean(!searchTerm.length);
  }

  searchValue(value: any) {
    this.valueSearch = value;
  }

  pageChangeEventSearch(page: number): void {
    this.pageSearch = page;
    this.showRowsSearch();
  }

  showRowsSearch(): void {
    const total = this.auxDataSearch.length;
    const init = (this.pageSearch - 1) * this.pageSize;
    const last = this.pageSearch * this.pageSize;
    const end = total < last ? total : last;
    this.dataSearch = [];
    for (let i = init; i < end; i++) {
      this.dataSearch.push(this.auxDataSearch[i]);
    }
  }

  searchClear($event: any) {
    if ($event) {
      this.codeSelected = '';
      this.showTopicContent = false;
      this.pageSearch = 1;
      this.dataSearch = [];
      this.isSearch = false;
      this.resultSearch = false;
    }
  }
  clearExternalSearch() {
    this.pageSearch = 1;
    let clear = document.getElementById('clearTypeHead');
    if (clear) {
      clear.click();
    }
  }

  hideKeyboard() {
    const input = document.getElementById('typeahead');
    input?.blur();
  }

  restoreTabsAndFilters() {
    this.tabsAndFiltersRestore = true;
    const firstChild = document.querySelector('.category-pills-container')?.firstElementChild;
    firstChild?.dispatchEvent(new Event('click'));
    const firstChildTab = document.querySelector('#pills-tab')?.firstElementChild;
    firstChildTab?.firstElementChild?.dispatchEvent(new Event('click'));
    setTimeout(() => {
      this.tabsAndFiltersRestore = false;
    }, 200);
  }

  goToCommunities(data: boolean) {
    if (sessionStorage.getItem('trainingDownload') == 'true' && data) {
      if (typeof window !== 'undefined') {
        setTimeout(function () {
          const linkCalendar: any = document.getElementById('sectionDownload')?.offsetTop ?? '';
          window.scrollTo({ left: 0, top: linkCalendar, behavior: 'smooth' });
        }, 1000);
        setTimeout(() => {
          this.courseService.changeDownloadSection(false);
          sessionStorage.setItem('trainingDownload', 'false');
        }, 1200);
      }
    }
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuestionsData } from '@shared/interfaces/frontend/communities';

@Component({
  selector: 'yevo-question-accordion',
  templateUrl: './question-accordion.component.html',
  styleUrls: ['./question-accordion.component.scss']
})
export class QuestionAccordionComponent {  
  @Input() listQuestions!: QuestionsData[];
  @Input() isVisible: boolean[] = [];
  @Output() questionClick = new EventEmitter<string>();

  questionOnClick(index: number) {
    this.isVisible[index] = !this.isVisible[index];
    if (this.isVisible[index]) {
      this.questionClick.emit(this.listQuestions[index].question);
    }
  }
}
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DownloadResponse } from '@shared/interfaces/frontend/education';
import { CourseService } from '@shared/services/course.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Pagination, Navigation, Autoplay]);

@Component({
  selector: 'yevo-training-downloads',
  templateUrl: './training-downloads.component.html',
  styleUrls: ['./training-downloads.component.scss'],
})
export class TrainingDownloadsComponent implements OnInit {
  sectionTools: { [key: string]: DownloadResponse[] } = {};
  downloads: DownloadResponse[] = [];
  digital: boolean = false;
  finance: boolean = true;
  downloadsSelected!: DownloadResponse[];
  downloadsSelectedDesktop!: DownloadResponse[][];

  page: number = 1;
  endPage: number = 0;

  courseDesktop: any[] = [];

  pages: any = { type: 'fraction' };

  pageMobile: number = 1;
  endPageMobile: number = 0;

  @ViewChild('swiperDashboard', { static: false }) swiperDashboard?: SwiperComponent;
  @ViewChild('swiperDashboardCourseDesktop', { static: false }) swiperDashboardCourseDesktop?: SwiperComponent;

  configSwiper: any = {
    loop: true,
    loopedSlides: 2,
    direction: 'horizontal',
    centeredSlides: true,
    watchSlidesProgress: true,
    slidesPerView: 4,
    spaceBetween: 10,
    wrapperClass: 'swiper-wrapper',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      clickable: true,
      type: 'fraction',
    },
    autoplay: {
      enabled: true,
      delay: 20000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      425: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 0,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
      1044: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
    },
    observer: true,
  };

  configSwiperDesktop: any = {
    pagination: {
      type: 'fraction',
    },
    autoplay: {
      enabled: true,
      delay: 20000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    slidesPerView: 1,
    loop: true
  };

  constructor(public downloadService: CourseService, private dataLayerService: DataLayerService) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.setDownloadsSelectedDesktop();
  }

  async ngOnInit(): Promise<void> {
    this.downloads = await this.downloadService.getToolsTraining().toPromise();
    this.initial();
    setTimeout(() => {
      this.inicialized();
    }, 2000);
  }

  initial() {
    this.courseDesktop = [];
    this.downloadsSelected = [];
    this.downloadsSelectedDesktop = [];
    this.page = 1;
    this.pageMobile = 1;
    this.endPage = 0;
    this.endPageMobile = 0;
    this.sectionTools = {};
    const arrTools = this.downloads;
    arrTools.forEach((t) => {
      let type = t.url.split('.');
      if (type[type.length - 1] === 'xlsx') {
        type[type.length - 1] = 'Excel';
      } else {
        type[type.length - 1] = type[type.length - 1].toString();
        type[type.length - 1] = type[type.length - 1].toUpperCase();
      }
      if (!this.sectionTools[t.section]) {
        this.sectionTools[t.section] = [
          {
            ...t,
            type: type[type.length - 1],
          },
        ];
      } else {
        this.sectionTools[t.section].push({
          ...t,
          type: type[type.length - 1],
        });
      }
    });
    this.downloadsSelected = this.sectionTools[this.digital ? 'Digitales' : 'Financieras'];
    this.setDownloadsSelectedDesktop();
    this.formatCarouselDesktop(this.downloadsSelected);
    this.swiperDashboardCourseDesktop?.swiperRef?.slideTo(1);
  }

  inicialized() {
    this.digital = true;
    this.finance = false;
    this.initial();
  }

  getDigital() {
    this.dataLayerService.selectedOptionDownloads('Digitales');
    this.digital = true;
    this.finance = false;
    this.initial();
  }

  getFinance() {
    this.dataLayerService.selectedOptionDownloads('Financieras');
    this.digital = false;
    this.finance = true;
    this.initial();
  }

  ListPrev() {
    this.swiperDashboardCourseDesktop?.swiperRef.slidePrev(400);
  }

  ListNext() {
    this.swiperDashboardCourseDesktop?.swiperRef.slideNext(400);
  }

  formatCarouselDesktop(download: DownloadResponse[]) {
    this.endPage = Math.ceil(download.length / 4);
    this.endPageMobile = download.length;
    let j = -1;

    for (let i = 0; i < download.length; i++) {
      if (i % 4 == 0) {
        j++;
        this.courseDesktop[j] = [];
        this.courseDesktop[j].push(download[i]);
      } else {
        this.courseDesktop[j].push(download[i]);
      }
    }
  }

  listNextMobile() {
    this.swiperDashboard?.swiperRef.slideNext(400);
  }

  listPrevMobile() {
    this.swiperDashboard?.swiperRef.slidePrev(400);
  }

  private setDownloadsSelectedDesktop(): void {
    const screenWidth = window.innerWidth;
    let slidesPerView = 1;

    if (screenWidth >= 1320) {
      slidesPerView = 4;
    } else if (screenWidth >= 1200 && screenWidth < 1320) {
      slidesPerView = 3;
    } else if (screenWidth >= 768 && screenWidth < 1200) {
      slidesPerView = 2;
    }

    this.downloadsSelectedDesktop = this.splitArray(this.downloadsSelected, slidesPerView);
  }

  splitArray(inputArray: DownloadResponse[], elementsPerSubarray: number): DownloadResponse[][] {
    const outputArray: DownloadResponse[][] = [];

    for (let i = 0; i < inputArray.length; i += elementsPerSubarray) {
        const subArray = inputArray.slice(i, i + elementsPerSubarray);
        outputArray.push(subArray);
    }
    return outputArray;
  }
}

<div class="row">
  <div [class]="classList">
    <label class="title-label" *ngIf="title">{{ title }}</label>
    <div (click)="open()" class="mobile-div">
      <input
        type="text"
        class="form-control custom-selector"
        [ngClass]="invalid ? 'invalid' : ''"
        maxlength="12"
        [placeholder]="placeholder"
        disabled
        [value]="showExtraValue ? getItemLabel() : itemSelected?.label"
      />
      <span class="custom-icon">
        <img class="images__clouds" src="/assets/icons/dropdown-down-arrow.svg" alt="dropdown-down-arrow" />
      </span>
      <div class="invisible-div"></div>
    </div>
  </div>
</div>

<ng-template #modalSelectedContent let-modal>
  <div class="modal-body mb-5 mx-2">
    <button type="button" class="close mt-3" (click)="dismiss()">
      <img class="images__clouds" alt="close-small-img" src="/assets/img/close-small.svg" />
    </button>

    <h2 class="info-modal-title mb-4 mt-3">{{ titleModal }}</h2>
    <yevo-filter-pills
      [items]="businessFrequencyList"
      [itemSelected]="itemSelected"
      [showSeeMore]="false"
      [dropdownPlacement]="['bottom-right']"
      dropdownTitle="Ver temas"
      [numberPillsShowed]="numberTopicOptionsShowed"
      [showJustifyContentBetween]="false"
      [withHandleOption]="false"
      [showSecondTheme]="true"
      [showJustifyWrap]="true"
      [showInactiveTheme]="showInactiveTheme"
      (selectItem)="
        getTopicsSelected($event.item?.value || '', $event.item?.label || '', $event.item?.extraValue || '')
      "
    ></yevo-filter-pills>
  </div>
</ng-template>

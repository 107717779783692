import { Component, OnInit,HostListener } from '@angular/core';
import { REFERRED_PROGRAM_VALUES,MEDALS_VALUES,VIRTUAL_EVENT_VALUES, MEDAL_MAP } from '@shared/constants/communities.constants';
import { QuestionsData, TabManager, ReferredProgram } from '@shared/interfaces/frontend/communities';
import benefitsTabs from '@shared/data/communities/benefits-tabs.json';
import progressLevel from '@shared/data/communities/progress-level.json';
import questionReferredProgram from '@shared/data/communities/question-referred-program.json';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalShareLinkComponent } from '../modal-share-link/modal-share-link.component';
import { CommunityService } from '@shared/services/community.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DataLayerService } from '@shared/services/data-layer.service';


@Component({
  selector: 'yevo-referred-program-page',
  templateUrl: './referred-program-page.component.html',
  styleUrls: ['./referred-program-page.component.scss']
})

export class ReferredProgramPageComponent implements OnInit {
  womenImageRoute: string = REFERRED_PROGRAM_VALUES.womanImageRoute;
  listQuestions: QuestionsData[] = [];
  itemsVisible: boolean[] = [];
  questionSelected = '';
  progressMedal!:string;
  tabItems:any= [] ;
  selectedTab!: TabManager;
  user: any = '';
  referredPrograma!: ReferredProgram;
  loading: boolean = true;
  totalBars: number[] = []
  bars = REFERRED_PROGRAM_VALUES.sizeBarLoadingDefault;
  parentRoute = '';
  fixedPointInit: number = REFERRED_PROGRAM_VALUES.pointScrollYInit; 
  fixedPointEnd: number = REFERRED_PROGRAM_VALUES.pointScrollYEnd; 
  showButton: boolean = false;

  constructor(private modalService: NgbModal, 
              private communityService: CommunityService,
              private localStorageService: LocalStorageService,
              private activatedRoute: ActivatedRoute,
              private dataLayerService: DataLayerService) {}

  ngOnInit(): void {
    this.totalBars = Array(this.bars).fill(0).map((_, index) => index);
    this.fillQuestionsReferredProgram();
    const userProfile = this.localStorageService.get('userProfile');
    this.parentRoute = this.activatedRoute.parent?.routeConfig?.path ?? '';
    if (userProfile) {
      this.user = JSON.parse(userProfile);
      this.selectedTab = progressLevel;
      this.tabItems = benefitsTabs;
      this.loading = true;
      this.myProgress();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.showButton = (window.scrollY > this.fixedPointInit);
  }

  myProgress(){
        this.communityService.getReferredProgramByUserId(this.user.userId).pipe(
          switchMap((response) => {
            if (response.referredCode == null) {
              return this.communityService.registerReferredProgram(this.user.userId).pipe(
                switchMap(() => this.communityService.getReferredProgramByUserId(this.user.userId)));
            } 
            return of(response);
          })).subscribe(
          (response) => {
            this.referredPrograma = response;
            this.dataLayerService.pushProgramReferredUser(response.referredCode,MEDAL_MAP[response.medalId]);
            this.progressLevelPoints(this.referredPrograma.totalPoints);
            this.timeoutLoading();
          },
          (error) => {}
        );
      }
  
	    progressLevelPoints(points: number): void {
        if (points >= REFERRED_PROGRAM_VALUES.pointsStartBronze && points < REFERRED_PROGRAM_VALUES.pointsStartSilver) {
          this.progressMedal = MEDALS_VALUES.bronze;
        } else if (points >= REFERRED_PROGRAM_VALUES.pointsStartSilver && points < REFERRED_PROGRAM_VALUES.pointsStartGold) {
          this.progressMedal = MEDALS_VALUES.silver;
        } else if (points >= REFERRED_PROGRAM_VALUES.pointsStartGold) {
          this.progressMedal = MEDALS_VALUES.gold;
        }
      }
      
  handleSelectedTab(value: TabManager): void {
    this.selectedTab = value;
    this.dataLayerPush(VIRTUAL_EVENT_VALUES.tabReferred+value.label);
  }

  questionClicked(question: string) {
    this.questionSelected =question;
    this.dataLayerPush(VIRTUAL_EVENT_VALUES.askReferredProgram+question);
  }

  fillQuestionsReferredProgram(){
    this.listQuestions = questionReferredProgram;
  }
  openModal() {
    const modalRef = this.modalService.open(ModalShareLinkComponent, { windowClass: 'modal-share-link' });
    modalRef.componentInstance.referredCode = this.referredPrograma.referredCode;
    modalRef.componentInstance.source =this.parentRoute;
    this.dataLayerPush(VIRTUAL_EVENT_VALUES.selectShareLinkReferredProgramPage);
  }

  timeoutLoading(): void {
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  dataLayerPush(labelOption:string): void {
    if (this.parentRoute === REFERRED_PROGRAM_VALUES.woman) {
      this.dataLayerService.virtualEventEM(labelOption,VIRTUAL_EVENT_VALUES.selectShareLinkWomanCode);
    } else {
      this.dataLayerService.virtualEventBodegueros(labelOption,VIRTUAL_EVENT_VALUES.selectShareLinkWinemakersCode);
    }
  }

}
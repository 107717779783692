<div class="card border-0">
  <div [ngClass]="{ 'dark-overlay': this.video.viewed }">
    <img [src]="video.img | image : imgDefault" class="card-img-top img-fluid" alt="..." />
    <div class="viewed_indicator" *ngIf="this.video.viewed">
      <div class="check_icon_container">
        <img src="assets/icons/communities/multimedia_viewed.svg" alt="check_icon" />
      </div>
      <div class="green_loader"></div>
    </div>
  </div>

  <div class="card-body p-0">
    <p class="card-title video-title">{{ video.videoTitle }}</p>
    <a class="btn-video" (click)="openDetailVideo(video)">Ver video</a>
  </div>
</div>

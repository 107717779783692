import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import affiliateData from '@shared/data/affiliate/affiliate.data.json';
import { Lookup } from 'src/app/shared/models';
import { ContactInformation } from 'src/app/shared/models/DirectoryModel';

function atLeastOneWhatsappValidator(): ValidatorFn {
  const whatsappCode = 2004;
  return (control: AbstractControl): { [key: string]: any } | null => {
    let valid: boolean = false;
    const formArray = control as FormArray;
    for (const x of formArray.controls) {
      if (x.value.type === whatsappCode && x.value.link) {
        valid = true;
        break;
      }
    }
    return valid ? null : { error: 'Debes registrar un número de contacto con whatsapp.' };
  };
}

enum frmGroupOrder {
  WHATSAPP_INDEX = 0,
  FACEBOOK_INDEX = 1,
  INSTAGRAM_INDEX = 2,
  MITIENDA_INDEX = 3,
}

type ContactInfoDescriptionType = 'Whatsapp' | 'WebSite' | 'Instagram' | 'Facebook';

@Component({
  selector: 'yevo-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent implements OnInit {
  @Input() contactTypes!: Lookup[];
  @Input() contactInformation?: ContactInformation[];
  @Output() stateAfterFieldBlur = new EventEmitter();

  frmContactInfo!: FormArray;
  showFieldIcon: any = [];

  public get frmContactInfoFormGroups(): FormGroup[] {
    return this.frmContactInfo?.controls as FormGroup[];
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.frmContactInfo = this.fb.array(this.initContactInfoFields(), [atLeastOneWhatsappValidator()]);
    this.orderFormGroup();
    this.subscribeFormChanges();
    this.loadData();
  }

  private subscribeFormChanges() {
    this.frmContactInfo.valueChanges.subscribe((formValue) => {
      this.stateAfterFieldBlur.emit(formValue);
    });
  }

  getInfoFromType(type: number, attribute: string) {
    const contactTypeName = this.contactTypes.find((contactType) => contactType.code === type)?.name;

    for (const key in this.contactInfoDescriptions) {
      if (key === contactTypeName) {
        if (attribute === 'label') {
          return this.contactInfoDescriptions[key as ContactInfoDescriptionType].label;
        }
        if (attribute === 'icon') {
          return this.contactInfoDescriptions[key as ContactInfoDescriptionType].icon;
        }
        if (attribute === 'placeholder') {
          return this.contactInfoDescriptions[key as ContactInfoDescriptionType].placeholder;
        }
      }
    }
    return '';
  }

  getMaxLengthFromType(type: number) {
    if (type === 2004) return 9;
    return 100;
  }

  onCheckedChange(event: any): void {
    const { value, checked } = event.target;

    this.frmContactInfoFormGroups.forEach((contactInfoFormGroup) => {
      const contactInfoType = contactInfoFormGroup.get('type')?.value;
      if (contactInfoType == value) {
        contactInfoFormGroup.get('principal')?.setValue(checked);
      } else {
        contactInfoFormGroup.get('principal')?.setValue(false);
      }
    });
  }

  showError(contactInfo: FormGroup): boolean | undefined {
    const control = contactInfo?.get('link');
    return control?.touched && control?.invalid;
  }

  getPatternErrorFromType(type: number): string {
    if (type === 2004) return 'Por favor ingrese un número válido';
    return 'Por favor ingresa una url válida Ejm: https://url.com';
  }

  isContactRequired(contactInfo: FormGroup): string {
    const type = contactInfo?.get('type')?.value;
    if (type === 2004) {
      return '*Es importante que completes este campo para continuar con el proceso ';
    }
    return '';
  }

  getMaxMinLengthErrorFromType(type: number): string {
    if (type === 2004) return 'Por favor ingrese un número de 9 dígitos';
    return '';
  }

  private orderFormGroup(): void {
    let initFormGroup = { ...this.frmContactInfo?.controls };
    this.frmContactInfo.controls[0] = initFormGroup[frmGroupOrder.MITIENDA_INDEX];
    this.frmContactInfo.controls[1] = initFormGroup[frmGroupOrder.WHATSAPP_INDEX];
    this.frmContactInfo.controls[2] = initFormGroup[frmGroupOrder.INSTAGRAM_INDEX];
    this.frmContactInfo.controls[3] = initFormGroup[frmGroupOrder.FACEBOOK_INDEX];
  }

  private loadData(): void {
    if (this.contactInformation) {
      this.frmContactInfoFormGroups.forEach((contactInfoFormGroup) => {
        const contactInfoType = contactInfoFormGroup.get('type')?.value;
        const contactInfoData =
          this.contactInformation?.find((contactInformationData) => contactInformationData.type == contactInfoType) ??
          {};

        contactInfoFormGroup.patchValue(contactInfoData);
      });
    }

    const element = this.frmContactInfoFormGroups;
    let indice = 1;
    const elementos = [...element.slice(0, indice), ...element.slice(2, element.length)];

    this.showFieldIcon = elementos;
  }

  private initContactInfoFields(): FormGroup[] {
    if (!this.contactTypes) return [];
    return this.contactTypes.map((contactInfoType) => {
      return this.initContactInfo(contactInfoType.code);
    });
  }

  private initContactInfo(type: number): FormGroup {
    const validators =
      type === 2004
        ? [Validators.pattern(/^\d+$/), Validators.maxLength(9), Validators.minLength(9)]
        : [Validators.pattern(/^(http|https):\/\/\w+/), Validators.maxLength(100)];

    return this.fb.group(
      {
        link: [null, Validators.compose(validators)],
        type,
        principal: [false],
      },
      { updateOn: 'blur' }
    );
  }

  private contactInfoDescriptions = affiliateData.contactInfoDescriptions;
}

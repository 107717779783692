<div class="container">
  <div class="row m-0">
    <a class="button-home" (click)="goHome()">
      <img style="margin-right: 4px" src="./assets/icons/communities/arrow.svg" alt="arrow" />Home
    </a>
  </div>
  <div class="row m-0">
    <p class="title-webinars">Webinars de la Comunidad</p>
  </div>
  <div class="row m-0">
    <h2>¡Capacítate con nosotros!</h2>
  </div>
  <div class="row m-0">
    <p class="title-category">Categorías</p>
  </div>
  <div class="mobile">
    <ngx-slick-carousel [config]="slickConfigWebinar" class="carousel">
      <div ngxSlickItem *ngFor="let category of categories; let i = index" class="slide">
        <button
          type="button"
          class="btn button-category"
          [ngClass]="{ active: isPressed[i] }"
          (click)="toggleButton(i)"
        >
          {{ category.name }}
        </button>
      </div>
    </ngx-slick-carousel>
  </div>
  <div class="row desktop">
    <div class="m-md-1 m-lg-1" *ngFor="let category of categories; let i = index">
      <button type="button" class="btn button-category" [ngClass]="{ active: isPressed[i] }" (click)="toggleButton(i)">
        {{ category.name }}
      </button>
    </div>
  </div>
  <div
    class="row m-0 p-0 te-recomendamos-text"
    *ngIf="showRecommendText && this.isPressed[0] && this.categories[0].name === 'Para Mí'"
  >
    Te recomendamos
  </div>
  <div class="row p-0 card-grid">
    <div class="col-sm-1 col-md-4 d-flex p-0" *ngFor="let training of trainingListShow; let i = index">
      <yc-training-card
        [training]="training"
        [source]="source"
        [sourceClick]="sourceClick"
        class="card-webinars"
      ></yc-training-card>
    </div>
  </div>
  <div class="row">
    <p class="text-pagination">1 - {{ length }} de {{ trainingList.length }} webinars</p>
  </div>
  <div class="row" *ngIf="showButton">
    <button type="button" class="btn button-pagination" (click)="pagination()">
      Ver {{ trainingList.length - length }}
      webinars más
    </button>
  </div>
</div>

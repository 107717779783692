<div class="container-fluid dropdown_container-menu card d-none d-lg-block" (mouseleave)="onHoverLeave()">
  <div class="row">
    <div class="col-md-3 col-xl-3">
      <div class="dropdown_home-container" (click)="home()" (mouseenter)="iconHome()" (mouseleave)="iconHomeLeave()">
        <img src="/assets/icons/header/home.svg" alt="home" class="mr-2 dropdown-start-icon" *ngIf="!iconHomeGreen" />
        <img
          src="/assets/icons/header/home_green.svg"
          alt="home"
          class="mr-2 dropdown-start-icon"
          *ngIf="iconHomeGreen"
        />
        <span class="dropdown_home-container-start">Ir al Inicio</span>
      </div>
    </div>

   <!-- IS NOT LOGGED RELEASE-->
   <div class="col-md-5 col-xl-5 d-none mb-4" [ngClass]="links.subMenu.release ? 'd-block' : 'd-none'">
    <ul class="dropdown_list-container">
      <li

      class="dropdown_list-title-service"
      [ngClass]="{ active: hover == 'hover6', 'dropdown_link-service-active': linkActive == 6 }"
      (mouseenter)="hoverDesktop(6)"
      (mouseleave)="leaveDesktop(6)"
      (click)="
        isActiveMenuMobile(19);
        dataLayerService.categoryNewHome('Nuevos Lanzamientos', 'Recomienda y gana');
        onLinkInternal('/recomienda-y-gana');
      "
    >
      <div class="div-option" [ngClass]="{ active: (linkActive == 6 && mainMenu == 0) || hover == 'hover6' }">
        
        <img
          src="assets/icons/header/finance/refers-icon-menu-green.svg"
          alt="remittances"
          *ngIf="(linkActive == 6 && mainMenu == 0) || hover == 'hover6'"
        />
        <img
          src="assets/icons/header/finance/refers-icon-menu.svg"
          alt="remittances"
          *ngIf="hover !== 'hover6'"
          [ngClass]="{ 'd-none': linkActive == 6 && mainMenu == 0 }"
        />
        Recomendados Mibanco <div class="event-actived">¡PARTICIPA!</div>
      </div>
    </li>
      <li
      class="dropdown_list-title-service"
      [ngClass]="{ active: hover == 'hover2', 'dropdown_link-service-active active': linkActive == 1 }"
      (mouseenter)="hoverDesktop(2)"
      (mouseleave)="leaveDesktop(2)"
      (click)="
        isActiveMenuMobile(3);
        dataLayerService.categoryNewHome('Nuevos Lanzamientos', 'Solicita tu préstamo Mi Efectivo Semilla');
        onLink(1, ['/remesas'])
      "
    >
      <div class="div-option" [ngClass]="{ active: (linkActive == 2 && mainMenu == 0) || hover == 'hover2' }">
        <img
          src="assets/icons/header/finance/remittance-icon-green.svg"
          alt="money"
          class="dropdown-start-icon"
          *ngIf="(linkActive == 2 && mainMenu == 0) || hover == 'hover2'"
        />
        <img
          src="assets/icons/header/finance/remittance-icon.svg"
          alt="money"
          class="dropdown-start-icon"
          *ngIf="hover !== 'hover2'"
          [ngClass]="{ 'd-none': linkActive == 2 && mainMenu == 0 }"
        />
        Remesas
      </div>
    </li>
      <li
        class="dropdown_list-title-service"
        [ngClass]="{ active: hover == 'hover3', 'dropdown_link-service-active': linkActive == 3 }"
        (mouseenter)="hoverDesktop(3)"
        (mouseleave)="leaveDesktop(3)"
        (click)="
          isActiveMenuMobile(3);
          dataLayerService.categoryNewHome('Nuevos Lanzamientos', 'Juntas');
          onLinkExternal('https://www.junta.pe/');
        "
      >
        <div class="div-option" [ngClass]="{ active: (linkActive == 3 && mainMenu == 0) || hover == 'hover3' }">
          <img
            src="assets/icons/header/finance/ayuda-green.svg"
            alt="card"
            *ngIf="(linkActive == 3 && mainMenu == 0) || hover == 'hover3'"
          />
          <img
            src="assets/icons/header/finance/ayuda.svg"
            alt="card"
            *ngIf="hover !== 'hover3'"
            [ngClass]="{ 'd-none': linkActive == 3 && mainMenu == 0 }"
          />
          Juntas
        </div>
      </li>
      <li
        class="dropdown_list-title-service"
        [ngClass]="{ active: hover == 'hover4', 'dropdown_link-service-active': linkActive == 4 }"
        (mouseenter)="hoverDesktop(4)"
        (mouseleave)="leaveDesktop(4)"
        (click)="isActiveMenuMobile(4);
          dataLayerService.categoryNewHome('Nuevos Lanzamientos', 'Academia del progreso');
          onLinkExternal('https://academiadelprogreso.mibanco.com.pe/');
        "
      >
        <div class="div-option" [ngClass]="{ active: (linkActive == 4 && mainMenu == 0) || hover == 'hover4' }">
          <img
            src="assets/icons/header/finance/academia-green.svg"
            alt="briefcase"
            *ngIf="(linkActive == 4 && mainMenu == 0) || hover == 'hover4'"
          />
          <img
            src="assets/icons/header/finance/academia.svg"
            alt="briefcase"
            *ngIf="hover !== 'hover4'"
            [ngClass]="{ 'd-none': linkActive == 4 && mainMenu == 0 }"
          />
          Academia del progreso
        </div>
      </li>
    </ul>
  </div>

  <div class="col-md-4 col-xl-4 d-none " [ngClass]="links.subMenu.release? 'd-flex align-items-center' : 'd-none'">
    <div class="dropdown_menu-line-1"></div>
    <div>
      <h1 class="dropdown_title-service">
        ¡Impulsa tu negocio <br />
        solicitando tu préstamo <br />
        fácilmente!
      </h1>

      <button
        class="btn btn-primary dropdown_button-service"
        (click)="
          isActiveMenuMobile(4);
          dataLayerService.categoryBannerNewHome('Nuevos Lanzamientos', 'Solicita tu préstamo');
          goToFinance()
        "
      >
        Solicita tu préstamo
      </button>
    </div>
  </div>
  <!-- END IS NOT LOGGED RELEASE-->

    <!-- IS NOT LOGGED FINANCE-->
    <div class="col-md-5 col-xl-5 d-none mb-6" [ngClass]="links.subMenu.finance ? 'd-block' : 'd-none'">
      <ul class="dropdown_list-container">
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover1', 'dropdown_link-service-active active': linkActive == 1 }"
          (mouseenter)="hoverDesktop(1)"
          (mouseleave)="leaveDesktop(1)"
          (click)="
            isActiveMenuMobile(3);
            dataLayerService.categoryNewHome('Servicios Financieros', 'Calcula tu cuota mensual');
            onLink(1, ['/financiamiento', 'simulador-prestamo-mibanco'])
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 1 && mainMenu == 0) || hover == 'hover1' }">
            <img
              src="assets/icons/header/finance/money_green.svg"
              alt="money"
              class="dropdown-start-icon"
              *ngIf="(linkActive == 1 && mainMenu == 0) || hover == 'hover1'"
            />
            <img
              src="assets/icons/header/finance/money-black.svg"
              alt="money"
              class="dropdown-start-icon"
              *ngIf="hover !== 'hover1'"
              [ngClass]="{ 'd-none': linkActive == 1 && mainMenu == 0 }"
            />
            Calcula tu cuota mensual
          </div>
        </li>
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover2', 'dropdown_link-service-active': linkActive == 2 }"
          (mouseenter)="hoverDesktop(2)"
          (mouseleave)="leaveDesktop(2)"
          (click)="
            isActiveMenuMobile(4);
            dataLayerService.categoryNewHome('Servicios Financieros', 'Solicita tu préstamo');
            isLOgged ? onLink(2, ['/financiamiento', 'filtro-financiamiento']) : onLink(2, ['/financiamiento', 'solicitud'])
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 2 && mainMenu == 0) || hover == 'hover2' }">
            <img
              src="assets/icons/header/finance/credit-card-check-outline_green.svg"
              alt="card"
              *ngIf="(linkActive == 2 && mainMenu == 0) || hover == 'hover2'"
            />
            <img
              src="assets/icons/header/finance/credit-card-check-outline.svg"
              alt="card"
              *ngIf="hover !== 'hover2'"
              [ngClass]="{ 'd-none': linkActive == 2 && mainMenu == 0 }"
            />
            Solicita tu préstamo
          </div>
        </li>
      </ul>
    </div>

    <div class="col-md-4 col-xl-4 d-none" [ngClass]="links.subMenu.finance ? 'd-flex align-items-center' : 'd-none'">
      <div class="dropdown_menu-line-1"></div>
      <div>
        <h1 class="dropdown_title-service">
          ¡Impulsa tu negocio <br />
          solicitando tu préstamo <br />
          fácilmente!
        </h1>

        <button
          class="btn btn-primary dropdown_button-service"
          (click)="
            isActiveMenuMobile(4);
            dataLayerService.categoryBannerNewHome('Servicios Financieros', 'Solicita tu préstamo');
            goToFinance()
          "
        >
          Solicita tu préstamo
        </button>
      </div>
    </div>
    <!-- END IS NOT LOGGED FINANCE-->

    <!-- IS NOT LOGGED COMMUNITY-->
    <div class="col-md-3 col-xl-3 d-none" [ngClass]="links.subMenu.community ? 'd-block' : 'd-none'">
      <ul class="dropdown_list-container">
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover1', 'dropdown_link-service-active active': linkActive == 1 }"
          (mouseenter)="hoverDesktop(1)"
          (mouseleave)="leaveDesktop(1)"
          (click)="
            isActiveMenuMobile(8);
            dataLayerService.categoryNewHome('Comunidades', 'Mis comunidades');
            onLink(1, ['/comunidades'])
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 1 && mainMenu == 1) || hover == 'hover1' }">
            <img
              src="assets/icons/header/community/servicio_green.svg"
              alt="money"
              *ngIf="(linkActive == 1 && mainMenu == 1) || hover == 'hover1'"
            />
            <img
              src="assets/icons/header/community/servicio.svg"
              alt="money"
              *ngIf="hover !== 'hover1'"
              [ngClass]="{ 'd-none': linkActive == 1 && mainMenu == 1 }"
            />
            Mis comunidades
          </div>
        </li>
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover2', 'dropdown_link-service-active': linkActive == 2 }"
          (mouseenter)="hoverDesktop(2)"
          (mouseleave)="leaveDesktop(2)"
          (click)="
            isActiveMenuMobile(9);
            dataLayerService.categoryNewHome('Comunidades', 'Emprende Mujer');
            isLOgged ? onLink(2, ['/nuevo-intranet', 'comunidades', 'emprende-mujer']) : goToCommunity(2)
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 2 && mainMenu == 1) || hover == 'hover2' }">
            <img
              src="assets/icons/header/community/woman_green.svg"
              alt="card"
              *ngIf="(linkActive == 2 && mainMenu == 1) || hover == 'hover2'"
            />
            <img
              src="assets/icons/header/community/woman.svg"
              alt="card"
              *ngIf="hover !== 'hover2'"
              [ngClass]="{ 'd-none': linkActive == 2 && mainMenu == 1 }"
            />
            Emprende Mujer
          </div>
        </li>
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover2', 'dropdown_link-service-active': linkActive == 3 }"
          (mouseenter)="hoverDesktop(3)"
          (mouseleave)="leaveDesktop(3)"
          (click)="
            isActiveMenuMobile(10);
            dataLayerService.categoryNewHome('Comunidades', 'Bodegueros');
            isLOgged ? onLink(3, ['/nuevo-intranet', 'comunidades', 'bodegueros']) : goToCommunity(3)
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 3 && mainMenu == 1) || hover == 'hover3' }">
            <img
              src="assets/icons/header/community/storefront-outline_green.svg"
              alt="briefcase"
              *ngIf="(linkActive == 3 && mainMenu == 1) || hover == 'hover3'"
            />
            <img
              src="assets/icons/header/community/storefront-outline.svg"
              alt="briefcase"
              *ngIf="hover !== 'hover3'"
              [ngClass]="{ 'd-none': linkActive == 3 && mainMenu == 1 }"
            />
            Bodegueros
          </div>
        </li>
      </ul>
    </div>

    <div
      class="col-md-6 col-xl-6 position-relative header-allied-container"
      [ngClass]="links.subMenu.community ? 'd-flex align-items-center' : 'd-none'"
    >
      <div class="dropdown_menu-line-1"></div>
      <div>
        <h1 class="dropdown_title-service">
          ¡El mejor aliado para hacer <br />
          crecer tu negocio! Esencial,<br />
          fácil y gratis!
        </h1>

        <img
          src="assets/icons/header/community/img_web_comunidades.png"
          alt="image"
          class="position-absolute header-community-image"
        />

        <img
          src="assets/icons/header/community/chica_comunidad.png"
          alt="image"
          class="position-absolute header-community-circle"
        />

        <button
          class="btn btn-primary dropdown_button-service"
          (click)="
            isActiveMenuMobile(8);
            dataLayerService.categoryBannerNewHome('Comunidades', 'Únete a una comunidad');
            onLink(1, ['/comunidades'])
          "
        >
          {{ isLOgged ? 'Conoce más' : 'Únete a una comunidad' }}
        </button>
      </div>
    </div>
    <!-- END IS NOT LOGGED COMMUNITY-->

    <!-- IS NOT LOGGED TRAINING-->
    <div class="col-md-5 col-xl-5 d-none" [ngClass]="links.subMenu.training ? 'd-block' : 'd-none'">
      <ul class="dropdown_list-container">
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover1', 'dropdown_link-service-active active': linkActive == 1 }"
          (mouseenter)="hoverDesktop(1)"
          (mouseleave)="leaveDesktop(1)"
          (click)="
            isActiveMenuMobile(12);
            dataLayerService.categoryNewHome('Capacitaciones', 'Capacítate con respaldo Mibanco');
            isLOgged ? onLink(1, ['/capacitaciones']) : onLink(1, ['/servicios', 'capacitaciones'])
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 1 && mainMenu == 2) || hover == 'hover1' }">
            <img
              src="assets/icons/header/training/Icon_green.svg"
              alt="money"
              *ngIf="(linkActive == 1 && mainMenu == 2) || hover == 'hover1'"
            />
            <img
              src="assets/icons/header/training/Icon.svg"
              alt="money"
              *ngIf="hover !== 'hover1'"
              [ngClass]="{ 'd-none': linkActive == 1 && mainMenu == 2 }"
            />
            Capacítate con respaldo Mibanco
          </div>
        </li>
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover2', 'dropdown_link-service-active': linkActive == 2 }"
          (mouseenter)="hoverDesktop(2)"
          (mouseleave)="leaveDesktop(2)"
          (click)="
            isActiveMenuMobile(13);
            dataLayerService.categoryNewHome('Capacitaciones', 'Pon a prueba tu negocio');
            isLOgged && onLink(2, ['/diagnostico-negocio', 'landing']);
            !isLOgged && openModalRegister()
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 2 && mainMenu == 2) || hover == 'hover2' }">
            <img
              src="assets/icons/header/training/tap_green.svg"
              alt="card"
              *ngIf="(linkActive == 2 && mainMenu == 2) || hover == 'hover2'"
            />
            <img
              src="assets/icons/header/training/tap.svg"
              alt="card"
              *ngIf="hover !== 'hover2'"
              [ngClass]="{ 'd-none': linkActive == 2 && mainMenu == 2 }"
            />
            Pon a prueba tu negocio <span class="new">Nuevo</span>
          </div>
        </li>
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover2', 'dropdown_link-service-active': linkActive == 3 }"
          (mouseenter)="hoverDesktop(3)"
          (mouseleave)="leaveDesktop(3)"
          (click)="
            isActiveMenuMobile(14);
            dataLayerService.categoryNewHome('Capacitaciones', 'Recursos descargables ahorro');
            isLOgged && goTotrainingDownload(3);
            !isLOgged && openModalRegister()
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 3 && mainMenu == 2) || hover == 'hover3' }">
            <img
              src="assets/icons/header/training/double_green.svg"
              alt="briefcase"
              *ngIf="(linkActive == 3 && mainMenu == 2) || hover == 'hover3'"
            />
            <img
              src="assets/icons/header/training/double.svg"
              alt="briefcase"
              *ngIf="hover !== 'hover3'"
              [ngClass]="{ 'd-none': linkActive == 3 && mainMenu == 2 }"
            />
            Recursos descargables <span class="new">Nuevo</span>
          </div>
        </li>
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover4', 'dropdown_link-service-active': linkActive == 4 }"
          (mouseenter)="hoverDesktop(4)"
          (mouseleave)="leaveDesktop(4)"
          (click)="
            isActiveMenuMobile(15);
            dataLayerService.categoryNewHome('Capacitaciones', 'Unidos contra el Fenómeno El Niño');
            onLink(4, ['/fenomeno-el-nino'])
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 4 && mainMenu == 2) || hover == 'hover4' }">
            <img
              src="assets/icons/header/training/ayuda_green.svg"
              alt="briefcase"
              *ngIf="(linkActive == 4 && mainMenu == 2) || hover == 'hover4'"
            />
            <img
              src="assets/icons/header/training/ayuda.svg"
              alt="briefcase"
              *ngIf="hover !== 'hover4'"
              [ngClass]="{ 'd-none': linkActive == 4 && mainMenu == 2 }"
            />
            Unidos contra el Fenómeno El Niño <span class="new">Nuevo</span>
          </div>
        </li>
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover5', 'dropdown_link-service-active': linkActive == 5 }"
          (mouseenter)="hoverDesktop(5)"
          (mouseleave)="leaveDesktop(5)"
          (click)="
            isActiveMenuMobile(16);
            dataLayerService.categoryNewHome('Capacitaciones', 'Calendario de eventos');
            isLOgged ? onLink(5, ['/nuevo-intranet', 'dashboard']) : openModalRegister()
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 5 && mainMenu == 2) || hover == 'hover5' }">
            <img
              src="assets/icons/header/training/calendar_green.svg"
              alt="briefcase"
              *ngIf="(linkActive == 5 && mainMenu == 2) || hover == 'hover5'"
            />
            <img
              src="assets/icons/header/training/calendar.svg"
              alt="briefcase"
              *ngIf="hover !== 'hover5'"
              [ngClass]="{ 'd-none': linkActive == 5 && mainMenu == 2 }"
            />
            Calendario de eventos
          </div>
        </li>
      </ul>
    </div>

    <div class="col-md-4 col-xl-4 d-none" [ngClass]="links.subMenu.training ? 'd-flex align-items-center' : 'd-none'">
      <div class="dropdown_menu-line-1"></div>
      <div>
        <h1 class="dropdown_title-service">
          ¡Capacítate de forma rápida <br />
          y gratuita de manera 100%<br />
          digital!
        </h1>

        <button
          class="btn btn-primary dropdown_button-service"
          (click)="
            isActiveMenuMobile(12);
            dataLayerService.categoryBannerNewHome('Capacitaciones', 'Quiero capacitarme');
            goToTraining()
          "
        >
          Quiero capacitarme
        </button>
      </div>
    </div>
    <!-- END IS NOT LOGGED TRAINING-->

    <!-- IS NOT LOGGED ALLIED-->
    <div class="col-md-5 col-xl-5 d-none" [ngClass]="links.subMenu.benefits ? 'd-block' : 'd-none'">
      <ul class="dropdown_list-container">
        <li
          class="dropdown_list-title-service"
          [ngClass]="{ active: hover == 'hover1', 'dropdown_link-service-active active': linkActive == 1 }"
          (mouseenter)="hoverDesktop(1)"
          (mouseleave)="leaveDesktop(1)"
          (click)="
            isActiveMenuMobile(18);
            dataLayerService.categoryNewHome('Beneficios y aliados', 'Conoce tus beneficios exclusivos');
            goToBenefits(1)
          "
        >
          <div class="div-option" [ngClass]="{ active: (linkActive == 1 && mainMenu == 3) || hover == 'hover1' }">
            <img
              src="assets/icons/header/gift_green.svg"
              alt="money"
              *ngIf="(linkActive == 1 && mainMenu == 3) || hover == 'hover1'"
            />
            <img
              src="assets/icons/header/gift.svg"
              alt="money"
              *ngIf="hover !== 'hover1'"
              [ngClass]="{ 'd-none': linkActive == 1 && mainMenu == 3 }"
            />

            Conoce tus beneficios exclusivos
          </div>
        </li>
      </ul>
    </div>

    <div
      class="col-md-4 col-xl-4 header-allied-container d-none"
      [ngClass]="links.subMenu.benefits ? 'd-flex align-items-center' : 'd-none'"
    >
      <div class="dropdown_menu-line-1"></div>
      <div>
        <h1 class="dropdown_title-service">
          ¡Nuestros aliados te <br />
          acompañarán en el<br />
          crecimiento de tu negocio!
        </h1>

        <button
          class="btn btn-primary dropdown_button-service"
          (click)="
            isActiveMenuMobile(18);
            dataLayerService.categoryBannerNewHome('Beneficios y aliados', 'Conoce más');
            goToBenefits()
          "
        >
          Conoce más
        </button>
      </div>
    </div>
    <!-- END IS NOT LOGGED ALLIED-->
  </div>
</div>

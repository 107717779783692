<fieldset class="mt-4">
  <legend>Ubicación del negocio</legend>
  <label class="mb-4">Ingresa la información de tus locales o la ubicación donde ofreces tus servicios</label>
  <div
    class="form-row location-container mb-5"
    [formGroup]="location"
    *ngFor="let location of frmLocationsFormGroups; let index = index"
  >
    <div class="form-group col-12 m-0 d-flex justify-content-end">
      <button
        *ngIf="canContinueDeleting"
        type="button"
        class="btn btn-link btn-md py-0"
        (click)="removeLocation(index)"
      >
        X
      </button>
    </div>

    <div class="form-group col-sm-12">
      <yevo-geolocation [parentFormGroup]="location"></yevo-geolocation>
      <div class="text-error">
        <div
          *ngIf="
            frmLocationsFormGroups[index].get('department')?.errors?.required &&
            frmLocationsFormGroups[index].get('department')?.touched
          "
        >
          Departamento es requerido.
        </div>
        <div
          *ngIf="
            frmLocationsFormGroups[index].get('province')?.errors?.required &&
            frmLocationsFormGroups[index].get('province')?.touched
          "
        >
          Provincia es requerido.
        </div>
        <div
          *ngIf="
            frmLocationsFormGroups[index].get('district')?.errors?.required &&
            frmLocationsFormGroups[index].get('district')?.touched
          "
        >
          Distrito es requerido.
        </div>
      </div>
    </div>

    <div class="row col-sm-12">
      <div class="form-group col-12 col-md-6 pr-0">
        <input
          type="text"
          class="form-control"
          placeholder="Ingresa la dirección de tu negocio"
          formControlName="businessName"
          maxlength="100"
          [ngClass]="{
            'border-error':
              !isDirectionChecked(index) &&
              isDirectionText(index)?.length < 1 &&
              frmLocationsFormGroups[index].get('businessName')?.touched
          }"
        />
        <label class="form-inline">
          <div class="text-error">
            <div
              *ngIf="
                !isDirectionChecked(index) &&
                isDirectionText(index)?.length < 1 &&
                frmLocationsFormGroups[index].get('businessName')?.touched
              "
            >
              Dirección de tu negocio es requerido.
            </div>
          </div>
        </label>
      </div>
      <div class="form-group col-12 col-md-6 pr-0">
        <input
          type="text"
          class="form-control"
          placeholder="Ingresa el horario de atención"
          formControlName="businessHours"
          maxlength="100"
        />
        <small class="form-text text-muted"> Ejemplo de horario: 08:00 AM - 06:00 PM </small>
        <div class="text-error m-0">
          <div
            *ngIf="
              frmLocationsFormGroups[index].get('businessHours')?.touched &&
              frmLocationsFormGroups[index].get('businessHours')?.errors?.required
            "
          >
            Horario de atención es requerido.
          </div>
        </div>
      </div>
    </div>
  </div>
  <span class="form-text text-muted text-body text-info"> Ingresa la información de tus locales (opcional): </span>
  <button type="button" class="btn btn-link p-0 btn-add-local" (click)="addLocation()" [disabled]="!canContinueAdding">
    <span class="text-link">
      <svg-icon-sprite [src]="'agregar'" [width]="'16px'"></svg-icon-sprite>
      <span class="px-3">Agregar otro local</span>
    </span>
  </button>
</fieldset>

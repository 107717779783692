<section class="container-frequent-questions">
    <h2 id="faq-detail">{{ selectedFaq.title }}</h2>
    <div class="description">{{ selectedFaq.subTitle}}</div>
    <div class="cards-container mt-4">
      <div>
        <div *ngFor="let detail of faqDetails; let index = index" class="card-accordeon">
          <div class="card-accordeon_header">
            <h5 class="mb-0">
              <button class="btn btn-link" (click)="questionOnClick(index, detail.title)" [attr.aria-expanded]="isVisible[index]">
                <div>{{ detail.title }} <span *ngIf="detail.letter">{{ detail.title_second }}</span></div>
                <img *ngIf="!isVisible[index]" class="ml-2" src="assets/faq/arrow-green-down.svg" alt="open">
                <img *ngIf="isVisible[index]" class="ml-2" src="assets/faq/arrow-green-up.svg" alt="close">
              </button>
            </h5>
          </div>
    
          <div class="collapse" [ngbCollapse]="!isVisible[index]">
            <div class="card-accordeon_body" [innerHTML]="detail.descriptionDesktop"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
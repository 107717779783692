<div class="container downloads-page">
  <div class="mb-3 d-flex align-items-end">
    <h2 class="downloads-page-title">
      Recursos descargables
      <span class="downloads-page-new"> Nuevo </span>
    </h2>
  </div>
  <div class="row d-none d-md-flex justify-content-center mb-4">
    <div class="mr-2 mr-md-3 allied-cards" [ngClass]="{ active: digital }" (click)="getDigital()">
      <img src="assets/icons/communities/tool-cart.svg" alt="credit card" class="mt-3" />
      <p>Digitales</p>
    </div>
    <div class="allied-cards allied-cards-benefits" [ngClass]="{ active: finance }" (click)="getFinance()">
      <img src="assets/icons/communities/tool-money.svg" alt="money card" class="mt-3" />
      <p>Financieras</p>
    </div>
  </div>
  <div class="row d-flex d-block d-md-none justify-content-center mb-4">
    <div class="d-flex justify-content-center d-md-none">
      <div class="mr-2 allied-card-container" [ngClass]="{ active: digital }" (click)="getDigital()">
        <img src="assets/icons/communities/tool-cart.svg" alt="credit card" />
        <p>Digitales</p>
      </div>
    </div>
    <div class="allied-card-container" [ngClass]="{ active: finance }" (click)="getFinance()">
      <img src="assets/icons/communities/tool-money.svg" alt="money card" />
      <p>Financieras</p>
    </div>
  </div>
  <div class="downloads-page-cards">
    <!-- DESKTOP -->
    <div class="col-12 d-none d-md-block">
      <div class="training-dashboard__container_arrow">
        <img
          src="/assets/icons/arrow-next.svg"
          class="training-dashboard_arrow_left"
          (click)="ListPrev()"
          alt="previous"
        />
        <p class="training-dashboard__arrow mb-0">&nbsp;&nbsp;&nbsp;</p>
        <img
          src="/assets/icons/arrow-next.svg"
          class="training-dashboard_arrow_right"
          (click)="ListNext()"
          alt="next"
        />
      </div>
    </div>

    <!-- DESKTOP -->
    <swiper
      #swiperDashboardCourseDesktop
      class="swiperDashboardCourseDesktop training-dashboard-banner d-none d-md-block courseSwiper"
      [config]="configSwiperDesktop"
      modules="{[Pagination,Autoplay, Navigation]}"
      *ngIf="downloadsSelected.length"
    >
      <ng-template swiperSlide *ngFor="let downloads of downloadsSelectedDesktop">
        <div class="d-flex justify-content-around">
          <div *ngFor="let download of downloads">
            <yevo-downloadable-resources [tool]="download"></yevo-downloadable-resources>
          </div>
        </div>
      </ng-template>
    </swiper>
    <!-- END DESKTOP -->
    <!-- pagination fraction swiper-->
    <!-- MOBILE -->
    <swiper
      #swiperDashboard
      class="mySwiper col-12 pl-md-0 pr-md-0 training-dashboard-banner d-block d-md-none courseSwiper"
      [config]="configSwiper"
      modules="{[Pagination,Autoplay, Navigation]}"
      *ngIf="downloadsSelected.length"
    >
      <ng-template swiperSlide *ngFor="let download of downloadsSelected">
        <yevo-downloadable-resources [tool]="download"></yevo-downloadable-resources>
      </ng-template>
    </swiper>
    <!-- MOBILE -->
    <div class="d-block d-md-none arrow-mobile-training">
      <div class="position-absolute arrow-mobile-training__container">
        <div class="training-dashboard__container_arrow mt-3">
          <img
            src="/assets/icons/arrow-next.svg"
            class="training-dashboard_arrow_left"
            (click)="listPrevMobile()"
            alt="arrow left"
          />
          <p class="training-dashboard__arrow mb-0"></p>
          <img
            src="/assets/icons/arrow-next.svg"
            class="training-dashboard_arrow_right"
            (click)="listNextMobile()"
            alt="arrow right"
          />
        </div>
      </div>
    </div>
  </div>
</div>

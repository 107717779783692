import { Component, Input } from '@angular/core';
import { ToolResponse } from '@shared/interfaces/frontend/communities';
import { HttpClient } from '@angular/common/http';
import { DataLayerService } from '@shared/services/data-layer.service';
import { CourseService } from '@shared/services/course.service';


@Component({
  selector: 'yevo-downloadable-resources',
  templateUrl: './downloadable-resources.component.html',
  styleUrls: ['./downloadable-resources.component.scss']
})
export class DownloadableResourcesComponent {
  @Input() tool!: ToolResponse;
  @Input() parentRoute!: string;
  btndissable: boolean = false;

  constructor( private http: HttpClient,  private dataLayerService: DataLayerService, private downloadService: CourseService ) { 
   }

  downloadFile(url: string, fileName: string) {       
    this.btndissable = true;
    this.dataLayerService.selectedDownloads(fileName);
    setTimeout(() => {
      this.btndissable = false;
    }, 2000);

    if (this.tool.fileId) {       
      this.downloadService.postTrainingDownload(this.tool.fileId).subscribe((res) => {
        window.open(url, '_blank');
      });
    } 
  }
}

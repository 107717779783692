import { Injectable } from '@angular/core';
import { SelectedCategory } from '@shared/interfaces/frontend/blog';

@Injectable({
  providedIn: 'root',
})
export class CategoryBlogService {
  categorySelected: SelectedCategory[] = [{
    selectedId: 0,
    selectedName: 'Recientes',
    isSelected: false
  }];

  constructor() {}

  setCategorySelected(selectedId: number, selectedName: string): void {
    const newSelectedCategory: SelectedCategory = {
      selectedId: selectedId,
      selectedName: selectedName,
      isSelected: false,
    };
    this.categorySelected.unshift(newSelectedCategory);

    if (this.categorySelected.length > 2) {
      this.categorySelected.pop();
    }
  }

  getCategorySelected(): SelectedCategory {
    return this.categorySelected[0];
  }

  clearCategorySelected(): void {
    this.categorySelected = [{
      selectedId: 0,
      selectedName: 'Recientes',
      isSelected: false
    }];
  }

  selectCategory(): void {
    if (this.categorySelected.length > 0) {
      this.categorySelected[0].isSelected = true;
    }
  }
}

import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  constructor() {}

  saveData(form: FormGroup, key: string): void {
    localStorage.setItem(key, JSON.stringify(form.value));
  }

  loadData(key: string): any {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  fillForm(form: FormGroup, key: string): void {
    const data = this.loadData(key);
    if (data) {
      form.patchValue(data);
    }
  }

  clearData(key: string): void {
    localStorage.removeItem(key);
  }
}

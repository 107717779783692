<div class="tabs-manager-section_container">
    <div class="tabs-manager-section_container_title">¡Recomienda Yevo y gana!</div>
    <div class="tabs-manager-section_container_description">
        A mayor nivel, más beneficios para ti
    </div>
    <div class="tabs-container overflow container">
        <ul class="nav nav-pills tabs-container_mb-4" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation" *ngFor="let tab of items">
                <a [ngClass]="{ 'btn-bronze': this.progressMedal ==='Bronce' && tab.active,
                                'btn-silver': this.progressMedal ==='Plata' && tab.active,
                                'btn-gold': this.progressMedal ==='Oro' && tab.active }" 
                    class="nav-link d-flex" data-toggle="pill"
                    (click)="selectTab(tab)">
                    {{ tab.label }}
                </a>
            </li>
        </ul>
    </div>
    <div class="tabs-manager-section_container_tabs"></div>
    <div class="tabs-manager-section_container_content"></div>
</div>
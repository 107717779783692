<div class='container'>
  <div class='row align-items-center'>
    <div class='col-12 col-md-12 col-sm-12 col-lg-12'>
      <div appIframeTracker (iframeClick)='handleIFrameClick(training.title)'>
        <div id='player'>
        </div>
      </div>
    </div>
  </div>
  <div class='row p-0 block-links'>
    <div class='col-md-9 p-0 d-flex'>
      <a class='links' (click)='goHome()'>
        <img class='img-arrow' src='./assets/icons/communities/arrow.svg' alt='' />Home
      </a>
      <span>/</span>
      <a class='links' (click)='goTrainings()'>Capacitaciones</a>
    </div>
  </div>
  <div class='row p-0 m-0'>
    <h4 class='title-webinars'>{{ training.title }}</h4>
  </div>
  <div class='row p-0 m-0'>
    <p class='text-webinars'>{{ training.text }}</p>
  </div>
  <div class='row p-0 block-date'>
    <img src='/assets/icons/communities/calendar.svg' alt=''>
    <p class='date'>{{ training.creationDate }}</p>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class AlliedService {
  private readonly directoryBase = `${environment.apiBaseUrl}/api-directory`;
  private readonly contentManagerApi = `${environment.apiBaseUrl}/contentManager`;

  constructor(private httpClient: HttpClient) {}

  getCategory(): Observable<any> {
    return this.httpClient.get(`${this.contentManagerApi}/ally/category/all`);
  }

  getPublicAllied(): Observable<any> {
    return this.httpClient.get(`${this.contentManagerApi}/ally/all`);
  }

  getAllied(): Observable<any> {
    return this.httpClient.get(`${this.directoryBase}/contentManager/ally/all`);
  }
}

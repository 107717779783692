<div class="modal-body hidden  modal_pm__terms">
  <div class="container-fluid">
      <div class="row">
          <div class="col-md-12 text-center">
              <div class="modal_pm__terms_circule">
                  <img class="terms_img" [src]="terms.icon" alt="arrow" >
              </div>
             
          </div>
          <div class="col-md-12 text-center">
              <div class="modal_pm__terms_title">{{terms.title}}</div>
          </div>
      </div>
  </div>
  <div class="container modal_pm__cuerpo">
      <div class="modal_pm__terms_descriptions">{{terms.description}}</div>
      <div class="modal_pm__terms_descriptions">{{terms.AdditionsDescription}}</div>
      <button type="button" class="btn btn-link modal_pm__terms_button" (click)="activeModal.close()">
        {{terms.buttonName}}
      </button>
  </div>
</div>
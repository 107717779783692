import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Article } from '@shared/interfaces/frontend/blog';
import { DataLayerService } from '@shared/services/data-layer.service';
import { goBlog } from '@shared/utils/blog.utils';

@Component({
  selector: 'yc-landing-blog-card',
  templateUrl: './landing-blog-card.component.html',
  styleUrls: ['./landing-blog-card.component.scss'],
})
export class LandingBlogCardComponent implements OnInit {
  @Input() blog: Article = {
    articleId: 0,
    title: '',
    subtitle: '',
    postDate: '',
    multimedia: '',
    caption: '',
    content: '',
    readingTimeMin: 0,
    yevoPlatform: false,
  };

  parentRoute = '';

  constructor(private route: ActivatedRoute, private router: Router, private dataLayerService: DataLayerService) {}

  ngOnInit(): void {
    this.parentRoute = this.route.parent?.routeConfig?.path || '';
  }

  openBlog(blog: Article): void {
    if (this.parentRoute === 'emprende-mujer') {
      this.dataLayerService.virtualEventEM(`Ver artículo: ${blog.title}`, 13);
    } else {
      this.dataLayerService.virtualEventBodegueros(`Ver artículo: ${blog.title}`, 12);
    }
    goBlog(this.router, this.parentRoute, blog);
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@shared/services/auth.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'yevo-modal-terms-conditions-policies',
  templateUrl: './modal-terms-conditions-policies.component.html',
  styleUrls: ['./modal-terms-conditions-policies.component.scss']
})
export class ModalTermsConditionsPoliciesComponent implements OnInit {

  form!: FormGroup;
  constructor(
    private modalService: NgbModal,
    private recaptchaV3Service: ReCaptchaV3Service,
    private fb: FormBuilder,
    private dataLayerService: DataLayerService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  dismiss() {
    this.modalService.dismissAll();
  }

  initForm() {
    this.form = this.fb.group({
      chkTerms: [null, [Validators.required, Validators.requiredTrue]],
      chkPersonalData: [null, [Validators.required, Validators.requiredTrue]],
      shareThirdParties: [false],
    });
  }

  public getRecaptchaToken() {
    this.recaptchaV3Service.execute('register').subscribe((token: string = '') => {
      this.form.get('recaptcha')?.setValue(token);

      //validate all field fill the form
      this.form.markAllAsTouched();

      if (this.form.valid) {
        this.authService.checkTermsAndConditions(this.form.value).subscribe((response) => {
          this.dataLayerService.selectedButtonTermLogin('Continuar');
          setTimeout(() => {
            this.modalService.dismissAll();
          }, 400);
        });
      }
    });
  }
}

import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { take } from 'rxjs/operators';
import { AuthService } from '@shared/services/auth.service';
import { UserProfile } from '@shared/models/UserProfileModel';
import { isPlatformBrowser } from '@angular/common';
import { headerLinks, headerLinksLogin } from '@shared/data/dashboard/menu.json';

@Component({
  selector: 'yevo-new-intranet',
  templateUrl: './new-intranet.component.html',
  styleUrls: ['./new-intranet.component.scss'],
})
export class NewIntranetComponent {
  readonly user$ = this.authService.userProfile$;
  user!: UserProfile;
  currentRoute: string;
  routerLinks: any = {
    default: headerLinks,
    logged: headerLinksLogin,
  };
  headerLinks = headerLinks;

  newDashoard: boolean = true;
  isMenuMobileExpande: boolean = false;
  isUserLogged: boolean = false;

  constructor(public authService: AuthService, @Inject(PLATFORM_ID) private platformId: any) {
    this.currentRoute = 'Demo';
  }

  ngOnInit(): void {
    this.validateUrl();

    if (isPlatformBrowser(this.platformId)) {
      this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
        this.user = user!;
      });
    }
    this.authService.isLogged$.subscribe((logged) => {
      this.headerLinks = this.getHeaderLinks(logged);
    });
  }

  getHeaderLinks(isLogged: boolean) {
    if (isLogged) {
      this.isUserLogged = true;
      return this.routerLinks.logged;
    }
    return this.routerLinks.default;
  }

  validateUrl() {
    const pathname = window.location.pathname.split('/')[2];

    if (
      pathname === 'dashboard' ||
      pathname === 'dashboard-new' ||
      pathname === 'perfil' ||
      pathname === 'financiamiento'
    ) {
      this.newDashoard = true;
    } else {
      this.newDashoard = false;
    }
  }

  mobileMenuIsExpanded(event: any) {
    if (event) {
      this.isMenuMobileExpande = true;
    } else {
      this.isMenuMobileExpande = false;
    }
  }
}

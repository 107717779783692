import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataLayerService } from '../../services/data-layer.service';
import { VIRTUAL_EVENT_VALUES_RE } from '@shared/constants/recommend.constants';
import { URL_RECOMMENDED } from '@shared/constants/dashboard.constants';

export type Icon = {
  name: string;
  icon: string;
  iconDisable?: string;
  url: string;
  section?: string;
  isActived: boolean;
  client?: boolean;
  id:string
};

@Component({
  selector: 'yevo-home-card-benefits',
  templateUrl: './home-card-benefits.component.html',
  styleUrls: ['./home-card-benefits.component.scss']
})
export class HomeCardBenefitsComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() icons: Icon[] = [];
  @Input() client: boolean = false;
 
  constructor(private router: Router, private dataLayerService: DataLayerService) { }

  openBenefit(icon: Icon): void {
    if(icon.url !== ''){
      this.dataLayerCard(icon.url);
      this.router.navigate([icon.url]);
    }
  }

  dataLayerCard(url: string){
    if( url == URL_RECOMMENDED.url ) {
      this.dataLayerService.virtualEventEnlaceRE(VIRTUAL_EVENT_VALUES_RE.selectCloseCardAndBannerRecommendProgramPage,
        VIRTUAL_EVENT_VALUES_RE.selectSectionCard2);
    }
  }
  
}

import { AfterContentChecked, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbDate, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENTTYPELIST } from '@shared/data/login/login.json';
import { ILatLng } from '@shared/interfaces';
import { IDropdownItem, IDropdownItemTraining } from '@shared/interfaces/IDropdownItem';
import { UserProfile, UserProfileRequest } from '@shared/models/UserProfileModel';
import { AuthService } from '@shared/services/auth.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { UserService } from '@shared/services/user.service';
import { generateNgbDate } from '@shared/utils/date.utils';
import * as FormUtil from '@shared/utils/form.utils';
import {
  FACEBOOK_REGEX,
  INSTAGRAM_REGEX,
  NAME_LASTNAME_REGEX,
  NAME_REGEX,
  ONLY_NUMBER_REGEX,
  PHONE_NUMBER_REGEX,
  TIKTOK_REGEX,
  TWITTER_REGEX,
  WEBPAGE_REGEX,
} from '@shared/utils/regex';
import { renameValidatorError } from '@shared/utils/validators.utils';

import {
  DNI_LENGTH,
  DNI_LENGTH_REGEX,
  INMIGRATION_CARD_LENGTH,
  INMIGRATION_CARD_LENGTH_REGEX,
} from '@shared/utils/login';
import {
  myBusinessRequired,
  myHomeRequired,
  myWorkInfoRequired,
  personalInfoDepend,
  personalInfoReadOnly,
  personalInfoRequired,
  socialNetworkRequired,
} from './constants';

const geolocationFormControls: any = {
  geolocation: {
    department: 'department',
    province: 'province',
    district: 'district',
  },
  geolocationBusiness: {
    department: 'businessDepartment',
    province: 'businessProvince',
    district: 'businessDistrict',
  },
};
const INDEPENDENT_EMPLOYMENT_CODE = 25002;
const SUCCESS_REQUEST_STATUS = 204;

export interface IDropdownSelectedOptionsProfile {
  maritalStatusSelected: IDropdownItem | null;
  genderSelected: IDropdownItem | null;
  housingTypesSelected: IDropdownItem | null;
  spouseDocumentTypeSelected: IDropdownItem | null;
  socialNetworkSelected: IDropdownItem | null;
  horaryContactSelected: IDropdownItem | null;
  internetConnectionSelected: IDropdownItem | null;
  whatRecognitionSelected: IDropdownItem | null;
  recognitionHorarySelected: IDropdownItem | null;
  timeContactsSelected: IDropdownItem | null;
  monthlyIncomesSelected: IDropdownItem | null;
  typeContactsSelected: IDropdownItem | null;
  businessSectorsSelected: IDropdownItem | null;
  businessTypesSelected: IDropdownItem | null;
  businessYearsSelected: IDropdownItem | null;
  employmentSelected: IDropdownItem | null;
}

type fieldForm = ValidationErrors | null | undefined;
@Component({
  templateUrl: './profile-personal-info.component.html',
  styleUrls: ['./profile-personal-info.component.scss'],
})
export class ProfilePersonalInfoComponent implements OnInit, OnDestroy {
  @ViewChild('mapSearchField') formSearchField!: ElementRef;
  @ViewChild('mapSearchFieldBusiness') formSearchFieldBusiness!: ElementRef;

  user!: UserProfile;
  userRequest!: UserProfileRequest;
  userExtraInfoRequest: any;
  frmUserInfo!: FormGroup;
  mapModal!: NgbModalRef;
  isIndependent: boolean = false;
  isDependent: boolean = false;
  formDisabled: boolean = true;

  formDisabledPersonalInfo: boolean = true;
  formDisabledHomeInfo: boolean = true;
  formDisabledBusinessInfo: boolean = true;
  formDisabledSocialInfo: boolean = true;
  formDisabledWorkInfo: boolean = true;

  formDisabledPersonalInfoButton: boolean = false;
  formDisabledHomeInfoButton: boolean = false;
  formDisabledBusinessInfoButton: boolean = false;
  formDisabledSocialInfoButton: boolean = false;
  formDisabledWorkInfoButton: boolean = false;

  formTouchedPersonalInfo: boolean = false;
  formTouchedHomeInfo: boolean = false;
  formTouchedBusinessInfo: boolean = false;
  formTouchedSocialInfo: boolean = false;
  formTouchedWorkInfo: boolean = false;

  personalInfo = {};
  myHome = {};
  myBusiness = {};
  socialNetwork = {};
  workingInformation = {};

  currentDate = new NgbDate(new Date().getUTCFullYear(), new Date().getMonth() + 1, new Date().getDate());
  selectedBornDate!: NgbDate | null;
  selectedBusinessStartDate!: NgbDate | null;
  selectedWorkStartDate!: NgbDate | null;

  documentMaxLengthSpouse: number = DNI_LENGTH;
  documentLengthRegexSpouse: string = DNI_LENGTH_REGEX;

  MARITAL_STATUS_INDEX: number = 8002;

  frmUserInfoGroup: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required, Validators.pattern(NAME_LASTNAME_REGEX)]),
    fatherLastName: new FormControl(null, [Validators.required, Validators.pattern(NAME_LASTNAME_REGEX)]),
    motherLastName: new FormControl(null, [Validators.required, Validators.pattern(NAME_LASTNAME_REGEX)]),
    documentNumber: new FormControl(null, []),
    bornDate: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required]),
    phone: new FormControl(null, [
      Validators.required,
      Validators.maxLength(9),
      renameValidatorError(Validators.pattern(PHONE_NUMBER_REGEX), 'phoneNumber'),
    ]),
    typeDocument: new FormControl(null),
    spouseDocumentNumber: new FormControl(null),
    spouseName: new FormControl(null),
    spouseLastName: new FormControl(null),
    gender: new FormControl(null, [Validators.required]),
  });

  formMyHomeGroup: FormGroup = new FormGroup({
    address: new FormControl(null, [this.validatePlaceOptionSelected()]),
    department: new FormControl(null, [Validators.required]),
    province: new FormControl(null, [Validators.required]),
    district: new FormControl(null, [Validators.required]),
  });

  formBusinessGroup: FormGroup = new FormGroup({
    businessName: new FormControl(null, [Validators.required]),
    businessRuc: new FormControl(null, [Validators.required]),
    businessDepartment: new FormControl(null, [Validators.required]),
    businessProvince: new FormControl(null, [Validators.required]),
    businessDistrict: new FormControl(null, [Validators.required]),
    ba: new FormControl(null, [this.validatePlaceOptionSelected(), Validators.required]),
    businessSector: new FormControl(null, []),
    businessType: new FormControl(null, []),
    businessPhoneCel: new FormControl(null, [Validators.required]),
    businessStartDate: new FormControl(null, [Validators.required]),
    businessMaxMonthlyLoanPayment: new FormControl(null, [
      Validators.required,
      renameValidatorError(Validators.pattern(ONLY_NUMBER_REGEX), 'onlyNumber'),
    ]),
  });

  formSocialMediaGroup: FormGroup = new FormGroup({
    whatsapp: new FormControl(null, [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(9),
      renameValidatorError(Validators.pattern(PHONE_NUMBER_REGEX), 'phoneNumber'),
      renameValidatorError(Validators.pattern(ONLY_NUMBER_REGEX), 'onlyNumber'),
    ]),
    facebook: new FormControl(null, [renameValidatorError(Validators.pattern(FACEBOOK_REGEX), 'incorrectUrl')]),
    instagram: new FormControl(null, [renameValidatorError(Validators.pattern(INSTAGRAM_REGEX), 'incorrectUrl')]),
    tiktok: new FormControl(null, [renameValidatorError(Validators.pattern(TIKTOK_REGEX), 'incorrectUrl')]),
    twitter: new FormControl(null, [renameValidatorError(Validators.pattern(TWITTER_REGEX), 'incorrectUrl')]),
    webpage: new FormControl(null, [renameValidatorError(Validators.pattern(WEBPAGE_REGEX), 'incorrectUrl')]),
  });

  formWorkInfoGroup: FormGroup = new FormGroup({
    employmentOccupation: new FormControl(null, [Validators.required]),
    employmentStartDate: new FormControl(null, [Validators.required]),
    monthlyIncome: new FormControl(null, [Validators.required]),
    monthlyExpenses: new FormControl(null, [
      Validators.required,
      renameValidatorError(Validators.pattern(ONLY_NUMBER_REGEX), 'onlyNumber'),
    ]),
    employerBusinessName: new FormControl(null, [Validators.required]),
    employerBusinessRuc: new FormControl(null, [Validators.required]),
  });

  // Google Maps integration variables
  LIMA_LTG_LNG = { lat: -12.0552493, lng: -77.0627323 };
  autocompleteOptions: google.maps.places.AutocompleteOptions = {
    componentRestrictions: { country: 'PE' },
    // !: Be careful to keep a few fields because Google charges us $$$ for each field that we request
    fields: ['address_components', 'geometry', 'formatted_address'],
  };
  mapConfigurations: google.maps.MapOptions = {
    center: this.LIMA_LTG_LNG,
    disableDefaultUI: true,
    fullscreenControl: false,
    zoom: 16,
    zoomControl: true,
  };

  // GEOLOCATION
  map!: google.maps.Map;
  currentPosition!: google.maps.Marker;
  modalSearchField!: HTMLInputElement;
  geocoder = new google.maps.Geocoder();
  googlePlaceSelected = false;

  // GEOLOCATION BUSINESS
  mapBusiness!: google.maps.Map;
  currentPositionBusiness!: google.maps.Marker;
  modalSearchFieldBusiness!: HTMLInputElement;
  geocoderBusiness = new google.maps.Geocoder();
  googlePlaceSelectedBusiness = false;

  geolocationSelected!: string | string[];
  geolocationBusinessSelected!: string | string[];

  // Dropdowns options
  dropdownOptions: any = {
    genderOptions: [],
    employmentOptions: [],
    maritalStatusOptions: [],
    howManyChildrenOptions: [],
    housingTypesOptions: [],
    businessSectorsOptions: [],
    businessTypesOptions: [],
    monthlyIncomesOptions: [],
  };
  // Dropdonws selected options
  selectedOptions: any = {
    maritalStatusSelected: null,
    genderSelected: null,
    spouseDocumentTypeSelected: null,
    housingTypesSelected: null,
    howManyChildrenSelected: null,
    socialNetworkSelected: null,
    horaryContactSelected: null,
    internetConnectionSelected: null,
    typeContactsSelected: null,
    whatRecognitionSelected: null,
    recognitionHorarySelected: null,
    timeContactsSelected: null,
    socialNetworksSelected: null,
    monthlyIncomesSelected: null,
    businessSectorsSelected: null,
    businessTypesSelected: null,
    businessYearsSelected: null,
    employmentSelected: null,
  };

  ubigeoSelected: any = {
    selectedUbigeo: null,
    selectedUbigeoBusiness: null,
  };

  coordinates: any = {
    address: {},
    businessAddress: {},
  };

  documentTypeList: IDropdownItemTraining[] = DOCUMENTTYPELIST;
  documentTypeSelected: IDropdownItemTraining = this.documentTypeList[0];

  activePanels: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private userService: UserService,
    private ngZone: NgZone,
    public authService: AuthService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {

    this.user = this.route.parent?.snapshot.data.user;

    this.setDisableFormPersonalInfo();
    this.setDisableFormHomeInfo();
    this.setDisableFormBusinessInfo();
    this.setDisableFormSocialInfo();
    this.setDisableFormWorkInfo();

    this.userService.getUserExtraInfo().subscribe((resp: any) => {
      this.userExtraInfoRequest = resp;
      this.user = this.route.parent?.snapshot.data.user;

      this.setDisableFormPersonalInfo();
      this.setDisableFormHomeInfo();
      this.setDisableFormBusinessInfo();
      this.setDisableFormSocialInfo();
      this.setDisableFormWorkInfo();
      this.fillAllInputData();
    });
  }

  fillAllInputData(): void {
    this.dropdownOptions.genderOptions = this.convertToIDropdownItem(this.userExtraInfoRequest?.genders);
    this.dropdownOptions.employmentOptions = this.convertToIDropdownItem(
      this.userExtraInfoRequest?.employmentSituations
    );
    this.dropdownOptions.maritalStatusOptions = this.convertToIDropdownItem(this.userExtraInfoRequest?.maritalStatus);
    this.dropdownOptions.howManyChildrenOptions = this.convertToIDropdownItem(
      this.userExtraInfoRequest?.numberChildren
    );
    this.fillUserInfoData();
    this.dropdownOptions.housingTypesOptions = this.convertToIDropdownItem(this.userExtraInfoRequest?.housingTypes);
    this.fillMyHomeData();
    this.dropdownOptions.businessSectorsOptions = this.convertToIDropdownItem(this.userExtraInfoRequest?.businessTypes);
    this.dropdownOptions.businessTypesOptions = this.convertToIDropdownItem(
      this.userExtraInfoRequest?.commercialBusinesses
    );
    this.fillMyBusinessData();
    this.fillSocialMediaData();
    this.dropdownOptions.monthlyIncomesOptions = this.convertToIDropdownItem(this.userExtraInfoRequest?.monthlyIncomes);
    this.fillWorkInformation();
    this.loadSavedData();
  }

  fillUserInfoData(): void {
    this.frmUserInfoGroup.get('name')?.setValue(this.user?.name);
    this.frmUserInfoGroup.get('fatherLastName')?.setValue(this.user?.fatherLastName);
    this.frmUserInfoGroup.get('motherLastName')?.setValue(this.user?.motherLastName);
    this.frmUserInfoGroup.get('documentNumber')?.setValue(this.user?.documentNumber);
    if (this.user?.bornDate) {
      this.selectedBornDate = generateNgbDate(this.user?.bornDate);
      this.frmUserInfoGroup.get('bornDate')?.setValue(this.user?.bornDate);
    }
    this.frmUserInfoGroup.get('email')?.setValue(this.user?.email);
    this.frmUserInfoGroup.get('phone')?.setValue(this.user?.phone);

    if (this.user?.userInfoResponse?.maritalStatus == this.MARITAL_STATUS_INDEX) {
      this.frmUserInfoGroup.get('spouseTypeDocument')?.setValue(this.user?.userInfoResponse?.spouseTypeDocument);
      this.frmUserInfoGroup.get('spouseDocumentNumber')?.setValue(this.user?.userInfoResponse?.spouseDocumentNumber);
      this.frmUserInfoGroup.get('spouseName')?.setValue(this.user?.userInfoResponse?.spouseName);
      this.frmUserInfoGroup.get('spouseLastName')?.setValue(this.user?.userInfoResponse?.spouseLastName);

      this.changeValidatorsSpouseDocumentNumber(this.user?.userInfoResponse?.spouseTypeDocument);
    }
  }

  fillMyHomeData(): void {
    this.formMyHomeGroup.get('address')?.setValue(this.user?.address);
    this.formMyHomeGroup.get('department')?.setValue(this.user?.department);
    this.formMyHomeGroup.get('province')?.setValue(this.user?.province);
    this.formMyHomeGroup.get('district')?.setValue(this.user?.district);
  }

  fillMyBusinessData(): void {
    this.formBusinessGroup.get('ba')?.setValue(this.user?.userInfoResponse?.businessAddress);
    this.formBusinessGroup.get('businessName')?.setValue(this.user?.userInfoResponse?.businessName);
    this.formBusinessGroup.get('businessRuc')?.setValue(this.user?.userInfoResponse?.businessRuc);
    this.formBusinessGroup.get('departmentBusiness')?.setValue(this.user?.userInfoResponse?.businessDepartment);
    this.formBusinessGroup.get('businessDistrict')?.setValue(this.user?.userInfoResponse?.businessDistrict);
    this.formBusinessGroup.get('businessProvince')?.setValue(this.user?.userInfoResponse?.businessProvince);
    this.formBusinessGroup.get('businessPhoneCel')?.setValue(this.user?.userInfoResponse?.businessPhoneCel);

    if (this.user?.userInfoResponse?.businessStartDate) {
      this.selectedBusinessStartDate = generateNgbDate(this.user?.userInfoResponse?.businessStartDate);
      this.formBusinessGroup.get('businessStartDate')?.setValue(this.user?.userInfoResponse?.businessStartDate);
    }

    const businessMaxMonthlyLoanPayment = this.user?.userInfoResponse?.businessMaxMonthlyLoanPayment
      ? `${this.user?.userInfoResponse?.businessMaxMonthlyLoanPayment}`
      : '';
    this.formBusinessGroup.get('businessMaxMonthlyLoanPayment')?.setValue(businessMaxMonthlyLoanPayment);
  }

  fillSocialMediaData(): void {
    this.formSocialMediaGroup.get('whatsapp')?.setValue(this.user?.userInfoResponse?.whatsAppNumber);
    this.formSocialMediaGroup.get('facebook')?.setValue(this.user?.userInfoResponse?.facebookLink);
    this.formSocialMediaGroup.get('instagram')?.setValue(this.user?.userInfoResponse?.instagramLink);
    this.formSocialMediaGroup.get('tiktok')?.setValue(this.user?.userInfoResponse?.tiktokLink);
    this.formSocialMediaGroup.get('twitter')?.setValue(this.user?.userInfoResponse?.twitterLink);
    this.formSocialMediaGroup.get('webpage')?.setValue(this.user?.userInfoResponse?.webPageLink);
  }

  fillWorkInformation(): void {
    this.formWorkInfoGroup.get('employmentOccupation')?.setValue(this.user?.userInfoResponse?.employmentOccupation);

    if (this.user?.userInfoResponse?.employmentStartDate) {
      this.selectedWorkStartDate = generateNgbDate(this.user?.userInfoResponse?.employmentStartDate);
      this.formWorkInfoGroup.get('employmentStartDate')?.setValue(this.user?.userInfoResponse?.employmentStartDate);
    }

    this.formWorkInfoGroup.get('monthlyIncome')?.setValue(this.user?.userInfoResponse?.monthlyIncome);
    const monthlyExpenses = this.user?.userInfoResponse?.monthlyExpenses
      ? `${this.user?.userInfoResponse?.monthlyExpenses}`
      : '';
    this.formWorkInfoGroup.get('monthlyExpenses')?.setValue(monthlyExpenses);
    this.formWorkInfoGroup.get('employerBusinessName')?.setValue(this.user?.userInfoResponse?.employerBusinessName);
    this.formWorkInfoGroup.get('employerBusinessRuc')?.setValue(this.user?.userInfoResponse?.employerBusinessRuc);
  }

  ngAfterViewInit() {
    this.setInitialMarket(this.formSearchField.nativeElement, 'address');
    this.setInitialMarket(this.formSearchFieldBusiness.nativeElement, 'businessAddress');
  }

  setInitialMarket(formControlNativeElement: any, formControlName: string) {
    const formAutocomplete = new google.maps.places.Autocomplete(formControlNativeElement, this.autocompleteOptions);
    formAutocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const location: any = formAutocomplete.getPlace().geometry?.location;
        if (!location) return;
        const latLng: ILatLng = {
          lat: location.lat(),
          lng: location.lng(),
        };
        this.setMarker({ latLng, fillAutocompleteField: true }, formControlName);
      });
    });
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.activeClass('personal-information');
    }, 500);
    this.activePanels = ['personal-information'];
  }

  ngOnDestroy() {
    if (this.mapModal) {
      this.mapModal.close();
    }
  }

  private setDisableFormPersonalInfo(): void {
    personalInfoRequired.forEach((key) => {
      this.frmUserInfoGroup.controls[key].disable();
    });
    personalInfoReadOnly.forEach((key) => {
      this.frmUserInfoGroup.controls[key].disable();
    });
    if (this.user.userInfoResponse?.maritalStatus == this.MARITAL_STATUS_INDEX) {
      personalInfoDepend.forEach((key) => {
        this.frmUserInfoGroup.controls[key].disable();
      });
    }
  }

  private setDisableFormHomeInfo(): void {
    myHomeRequired.forEach((key) => {
      this.formMyHomeGroup.controls[key].disable();
    });
  }

  private setDisableFormBusinessInfo(): void {
    myBusinessRequired.forEach((key) => {
      this.formBusinessGroup.controls[key].disable();
    });
  }

  private setDisableFormSocialInfo(): void {
    socialNetworkRequired.forEach((key) => {
      this.formSocialMediaGroup.controls[key].disable();
    });
  }

  private setDisableFormWorkInfo(): void {
    myWorkInfoRequired.forEach((key) => {
      this.formWorkInfoGroup.controls[key].disable();
    });
  }

  public enableFormPersonalInfo(): void {
    this.formDisabledPersonalInfo = false;
    personalInfoRequired.forEach((key) => {
      this.frmUserInfoGroup.controls[key].enable();
    });
    if (this.user.userInfoResponse?.maritalStatus == this.MARITAL_STATUS_INDEX) {
      personalInfoDepend.forEach((key) => {
        this.frmUserInfoGroup.controls[key].enable();
      });
    }

    this.formDisabledHomeInfo = true;
    this.formDisabledBusinessInfo = true;
    this.formDisabledSocialInfo = true;
    this.formDisabledWorkInfo = true;
    this.setDisableFormBusinessInfo();
    this.setDisableFormHomeInfo();
    this.setDisableFormSocialInfo();
    this.setDisableFormWorkInfo();
  }

  public enabledFormHomeInfo() {
    this.formDisabledHomeInfo = false;
    myHomeRequired.forEach((key) => {
      this.formMyHomeGroup.controls[key].enable();
    });

    this.formDisabledPersonalInfo = true;
    this.formDisabledBusinessInfo = true;
    this.formDisabledSocialInfo = true;
    this.formDisabledWorkInfo = true;
    this.setDisableFormBusinessInfo();
    this.setDisableFormPersonalInfo();
    this.setDisableFormSocialInfo();
    this.setDisableFormWorkInfo();
  }

  public enabledFormBusinessInfo() {
    this.formDisabledBusinessInfo = false;
    myBusinessRequired.forEach((key) => {
      this.formBusinessGroup.controls[key].enable();
    });

    this.formDisabledPersonalInfo = true;
    this.formDisabledHomeInfo = true;
    this.formDisabledSocialInfo = true;
    this.formDisabledWorkInfo = true;
    this.setDisableFormPersonalInfo();
    this.setDisableFormHomeInfo();
    this.setDisableFormSocialInfo();
    this.setDisableFormWorkInfo();
  }

  public enabledFormSocialInfo() {
    this.formDisabledSocialInfo = false;
    socialNetworkRequired.forEach((key) => {
      this.formSocialMediaGroup.controls[key].enable();
    });

    this.formDisabledPersonalInfo = true;
    this.formDisabledHomeInfo = true;
    this.formDisabledBusinessInfo = true;
    this.formDisabledWorkInfo = true;
    this.setDisableFormBusinessInfo();
    this.setDisableFormHomeInfo();
    this.setDisableFormPersonalInfo();
    this.setDisableFormWorkInfo();
  }

  public enabledFormWorkInfo() {
    this.formDisabledWorkInfo = false;
    myWorkInfoRequired.forEach((key) => {
      this.formWorkInfoGroup.controls[key].enable();
    });

    this.formDisabledPersonalInfo = true;
    this.formDisabledHomeInfo = true;
    this.formDisabledBusinessInfo = true;
    this.formDisabledSocialInfo = true;
    this.setDisableFormBusinessInfo();
    this.setDisableFormHomeInfo();
    this.setDisableFormSocialInfo();
    this.setDisableFormPersonalInfo();
  }

  public handleSelectedGender(event: any, formControl: string, selectedComboName: string) {
    this.selectedOptions[selectedComboName] = event?.item;
    this.frmUserInfoGroup.get('gender')?.setValue(event?.item?.label || '');
  }

  public handleSelectedCombo(event: any, formControl: string, selectedComboName: string) {
    this.selectedOptions[selectedComboName] = event?.item;
    this.formMyHomeGroup.get(formControl)?.setValue(this.selectedOptions[selectedComboName]?.label || '');

    if (selectedComboName === 'employmentSelected') {
      this.showHideActivityTypeFields(event?.item?.value);
    }
    if (selectedComboName === 'spouseDocumentTypeSelected') {
      this.frmUserInfoGroup.get('spouseDocumentNumber')?.setValue('');
      this.changeValidatorsSpouseDocumentNumber();
    }
    if (formControl === 'monthlyIncome') {
      this.formWorkInfoGroup.get(formControl)?.setValue(this.selectedOptions.monthlyIncomesSelected?.label);
    }
    if (formControl === 'maritalStatus') {
      this.selectedOptions.maritalStatusSelected?.value === this.MARITAL_STATUS_INDEX
        ? this.changeValidatorsSpouseDocumentNumber()
        : this.removeSpouseValidators();
    }
  }

  private changeValidatorsSpouseDocumentNumber(typeDocument: string = '') {
    if (this.selectedOptions?.spouseDocumentTypeSelected?.value === 'E' || typeDocument === 'E') {
      this.documentMaxLengthSpouse = INMIGRATION_CARD_LENGTH;
      this.documentLengthRegexSpouse = INMIGRATION_CARD_LENGTH_REGEX;
    } else {
      this.documentMaxLengthSpouse = DNI_LENGTH;
      this.documentLengthRegexSpouse = DNI_LENGTH_REGEX;
    }
    this.frmUserInfoGroup
      .get('spouseDocumentNumber')
      ?.setValidators([Validators.required, Validators.pattern(`[0-9]{${this.documentLengthRegexSpouse}}`)]);
    this.frmUserInfoGroup.get('spouseName')?.setValidators([Validators.required, Validators.pattern(NAME_REGEX)]);
    this.frmUserInfoGroup.get('spouseLastName')?.setValidators([Validators.required, Validators.pattern(NAME_REGEX)]);

    this.frmUserInfoGroup.get('spouseDocumentNumber')?.updateValueAndValidity();
    this.frmUserInfoGroup.get('spouseName')?.updateValueAndValidity();
    this.frmUserInfoGroup.get('spouseLastName')?.updateValueAndValidity();
  }

  private removeSpouseValidators() {
    this.frmUserInfoGroup.get('spouseDocumentNumber')?.setValidators(null);
    this.frmUserInfoGroup.get('spouseName')?.setValidators(null);
    this.frmUserInfoGroup.get('spouseLastName')?.setValidators(null);

    this.frmUserInfoGroup.get('spouseDocumentNumber')?.setValue(null);
    this.frmUserInfoGroup.get('spouseName')?.setValue(null);
    this.frmUserInfoGroup.get('spouseLastName')?.setValue(null);

    this.frmUserInfoGroup.get('spouseDocumentNumber')?.updateValueAndValidity();
    this.frmUserInfoGroup.get('spouseName')?.updateValueAndValidity();
    this.frmUserInfoGroup.get('spouseLastName')?.updateValueAndValidity();
  }

  updateSocialMediaProfile(): any {
    this.formDisabledSocialInfoButton = true;
    const { whatsapp, facebook, instagram, tiktok, twitter, webpage } = this.formSocialMediaGroup.value;
    const socialMediaRequest = {
      whatsAppNumber: whatsapp?.toLowerCase(),
      facebookLink: facebook?.toLowerCase(),
      instagramLink: instagram?.toLowerCase(),
      tiktokLink: tiktok?.toLowerCase(),
      twitterLink: twitter?.toLowerCase(),
      webPageLink: webpage?.toLowerCase(),
    };

    if (this.formSocialMediaGroup.invalid) {
      this.formTouchedSocialInfo = true;
      this.formDisabledSocialInfoButton = false;
      this.formMarkAsTouched(this.formSocialMediaGroup);
    } else {
      this.formTouchedSocialInfo = false;
      this.userService
        .updateUserSocialMediaData(this.changeEmptyStringsToNulls(socialMediaRequest))
        .subscribe((response) => {
          if (response.status == SUCCESS_REQUEST_STATUS) {
            this.authService.getUserProfile().subscribe(() => {
              this.formDisabledSocialInfo = true;
              this.formDisabledSocialInfoButton = false;
              this.setDisableFormSocialInfo();
              const user = this.localStorageService.get('userProfile');
              if (user) {
                this.updateUserObject(user);
              }
              this.fillAllInputData();
            });
          }
        });
    }
  }

  updateMyHomeProfile(): any {
    this.formDisabledHomeInfoButton = true;
    const { department, province, district } = this.formMyHomeGroup.value;
    this.user.address = this.formMyHomeGroup.get('address')?.value;
    this.user.latitude = this.coordinates['address'].lat;
    this.user.longitude = this.coordinates['address'].lng;
    const MyHomeData = {
      department,
      province,
      district,
      ubigeo: this.ubigeoSelected['selectedUbigeo'],
      latitude: this.coordinates['address'].lat,
      longitude: this.coordinates['address'].lng,
      address: this.formMyHomeGroup.get('address')?.value,
      housingType: this.selectedOptions.housingTypesSelected?.value,
    };

    if (this.formMyHomeGroup.invalid) {
      this.formTouchedHomeInfo = true;
      this.formDisabledHomeInfoButton = false;
      this.formMarkAsTouched(this.formMyHomeGroup);
    } else {
      this.formTouchedHomeInfo = false;
      this.userService.updateMyHomeInfoData(this.changeEmptyStringsToNulls(MyHomeData)).subscribe((response) => {
        if (response.status == SUCCESS_REQUEST_STATUS) {
          this.authService.getUserProfile().subscribe(() => {
            this.formDisabledHomeInfo = true;
            this.formDisabledHomeInfoButton = false;
            this.setDisableFormHomeInfo();
            const user = this.localStorageService.get('userProfile');
            if (user) {
              this.updateUserObject(user);
            }
            this.fillAllInputData();
          });
        }
      });
    }
  }

  updateMyBusinessInformationProfile(): any {
    this.formDisabledBusinessInfoButton = true;
    const {
      businessName,
      businessRuc,
      businessDepartment,
      businessProvince,
      businessDistrict,

      businessPhoneCel,
      businessStartDate,
      businessMaxMonthlyLoanPayment,
    } = this.formBusinessGroup.value;

    const myBusinessData = {
      businessName,
      businessRuc,
      ubigeo: this.ubigeoSelected['selectedUbigeoBusiness'],
      businessDepartment,
      businessProvince,
      businessDistrict,
      businessLatitude: this.coordinates['businessAddress'].lat,
      businessLongitude: this.coordinates['businessAddress'].lng,
      businessAddress: this.formBusinessGroup.get('ba')?.value,
      businessSector: this.selectedOptions.businessSectorsSelected?.value,
      businessType: this.selectedOptions.businessTypesSelected?.value,
      businessPhoneCel,
      businessStartDate,
      businessMaxMonthlyLoanPayment: businessMaxMonthlyLoanPayment,
    };

    if (this.formBusinessGroup.invalid) {
      this.formTouchedBusinessInfo = true;
      this.formDisabledBusinessInfoButton = false;
      this.formMarkAsTouched(this.formBusinessGroup);
    } else {
      this.formTouchedBusinessInfo = false;
      this.userService
        .updateMyBusinessInfoData(this.changeEmptyStringsToNulls(myBusinessData))
        .subscribe((response) => {
          if (response.status == SUCCESS_REQUEST_STATUS) {
            this.authService.getUserProfile().subscribe(() => {
              this.formDisabledBusinessInfo = true;
              this.formDisabledBusinessInfoButton = false;
              this.setDisableFormBusinessInfo();
              const user = this.localStorageService.get('userProfile');
              if (user) {
                this.updateUserObject(user);
              }
              this.fillAllInputData();
            });
          }
        });
    }
  }

  updateWorkInformationProfile(): any {
    this.formDisabledWorkInfoButton = true;
    const { employmentOccupation, employmentStartDate, monthlyExpenses, employerBusinessName, employerBusinessRuc } =
      this.formWorkInfoGroup.value;

    const monthlyIncome = this.selectedOptions.monthlyIncomesSelected?.value;

    const workInformationData = {
      employmentOccupation,
      employmentStartDate,
      monthlyIncome,
      monthlyExpenses: monthlyExpenses,
      employerBusinessName,
      employerBusinessRuc,
    };

    if (this.formWorkInfoGroup.invalid) {
      this.formTouchedWorkInfo = true;
      this.formDisabledWorkInfoButton = false;
      this.formMarkAsTouched(this.formWorkInfoGroup);
    } else {
      this.formTouchedWorkInfo = false;
      this.userService
        .updateUserWorkInformationData(this.changeEmptyStringsToNulls(workInformationData))
        .subscribe((response) => {
          if (response.status == SUCCESS_REQUEST_STATUS) {
            this.authService.getUserProfile().subscribe(() => {
              this.formDisabledWorkInfo = true;
              this.formDisabledWorkInfoButton = false;
              this.setDisableFormWorkInfo();
              const user = this.localStorageService.get('userProfile');
              if (user) {
                this.updateUserObject(user);
              }
              this.fillAllInputData();
            });
          }
        });
    }
  }

  updatePersonalInformationProfile(): any {
    this.formDisabledPersonalInfoButton = true;
    const {
      bornDate,
      fatherLastName,
      motherLastName,
      name,
      spouseDocumentNumber = null,
      spouseLastName = null,
      spouseName = null,
    } = this.frmUserInfoGroup.value;

    const userInfoRequest = {
      name,
      fatherLastName,
      motherLastName,
      bornDate,
      phone: this.user.phone,
      gender: this.selectedOptions.genderSelected?.value,
      employmentSituation: this.selectedOptions.employmentSelected?.value,
      maritalStatus: this.selectedOptions.maritalStatusSelected?.value,
      numberChildren: this.selectedOptions.howManyChildrenSelected?.value,
      spouseTypeDocument: 'C',
      spouseDocumentNumber: null,
      spouseLastName: null,
      spouseName: null,
    };
    if (this.selectedOptions.maritalStatusSelected?.value == this.MARITAL_STATUS_INDEX) {
      userInfoRequest['spouseTypeDocument'] = this.selectedOptions.spouseDocumentTypeSelected?.value;
      userInfoRequest['spouseDocumentNumber'] = spouseDocumentNumber;
      userInfoRequest['spouseLastName'] = spouseLastName;
      userInfoRequest['spouseName'] = spouseName;
    }

    if (this.frmUserInfoGroup.invalid) {
      this.formTouchedPersonalInfo = true;
      this.formMarkAsTouched(this.frmUserInfoGroup);
      this.formDisabledPersonalInfoButton = false;
    } else {
      this.formTouchedPersonalInfo = false;
      this.userService.updateUserInfoData(this.changeEmptyStringsToNulls(userInfoRequest)).subscribe((response) => {
        if (response.status == SUCCESS_REQUEST_STATUS) {
          this.authService.getUserProfile().subscribe(() => {
            this.formDisabledPersonalInfo = true;
            this.formDisabledPersonalInfoButton = false;
            setTimeout(() => {
              this.setDisableFormPersonalInfo();
            }, 1000);
            const user = this.localStorageService.get('userProfile');
            if (user) {
              this.updateUserObject(user);
            }
            this.fillAllInputData();
          });
        }
      });
    }
  }

  updateUserObject(user: string) {
    const userCompletedPrev = this.user?.levels?.userCompleted;
    this.user = JSON.parse(user) as UserProfile;
    this.userService.userInfoUpdated.next(this.user?.levels?.userCompleted);
    if (this.route.parent?.snapshot.data) {
      this.route.parent.snapshot.data.user = this.user;
    }
    // SHOW MODAL FOR PROFILE COMPLETE ACHIEVEMENT
    if (!userCompletedPrev && this.user?.levels?.userCompleted) {
      this.userService.setUserLevelUp();
    }
  }

  changeEmptyStringsToNulls(object: any) {
    Object.keys(object).forEach((k) => (object[k] = object[k] === '' ? null : object[k]));
    return object;
  }

  public selectBornDate(date: string | null) {
    this.frmUserInfoGroup.get('bornDate')?.setValue(date);
  }
  public selectBusinessStartDate(date: string | null) {
    this.formBusinessGroup.get('businessStartDate')?.setValue(date);
  }
  public selectWorkStartDate(date: string | null) {
    this.formWorkInfoGroup.get('employmentStartDate')?.setValue(date);
  }

  // MODAL MAP METHODS
  openModalMap(modalContent: any, formControlName: string) {
    if (!this.formDisabledBusinessInfo || !this.formDisabledHomeInfo) {
      this.mapModal = this.modalService.open(modalContent, { size: 'xl' });
      this.mapModal.shown.subscribe(() => {
        this.initModalMapAndSearchBar(formControlName);
      });
      this.mapModal.result.then(
        (result) => {
          // When close() method is executed
          this.map = null!;
        },
        (reason) => {
          // When dismiss() method is executed
          this.googlePlaceSelected = false;
          this.currentPosition = null!;
          this.map = null!;
          if (formControlName == 'address') {
            this.frmUserInfo.get(formControlName)?.setValue(null);
          } else if (formControlName == 'businessAddress') {
            this.formBusinessGroup.get('ba')?.setValue(null);
          }
        }
      );
    }
  }

  initModalMapAndSearchBar(formControlName: string) {
    const mapRefElement = document.getElementById('map') as HTMLElement;
    this.map = new google.maps.Map(mapRefElement, {
      ...this.mapConfigurations,
    });
    this.modalSearchField = document.getElementById('modalSearchField') as HTMLInputElement;
    const modalAutocomplete = new google.maps.places.Autocomplete(this.modalSearchField, this.autocompleteOptions);
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.modalSearchField);

    if (
      (formControlName === 'address' && !this.coordinates['address'].lat) ||
      (formControlName === 'businessAddress' && !this.coordinates['businessAddress'].lat)
    ) {
      this.requestAndSetCurrentCoordinates(formControlName);
    } else {
      let latLng = { lat: 0, lng: 0 };
      if (formControlName === 'businessAddress') {
        latLng.lat = this.coordinates['businessAddress'].lat;
        latLng.lng = this.coordinates['businessAddress'].lng;
      }
      if (formControlName === 'address') {
        latLng.lat = this.coordinates['address'].lat;
        latLng.lng = this.coordinates['address'].lng;
      }
      this.setMarker({ latLng, setCenter: true }, formControlName);
    }

    // Events subscriptions
    modalAutocomplete.addListener('place_changed', () => {
      const place = modalAutocomplete.getPlace();
      if (!place) return;
      const bounds = new google.maps.LatLngBounds();

      if (!place.geometry || !place.geometry.location) return;
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }

      this.setMarker(
        {
          latLng: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        },
        formControlName
      );
      this.map.fitBounds(bounds);
    });
    this.map.addListener('bounds_changed', () => {
      modalAutocomplete.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
    });
    this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
      const latLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.setMarker({ latLng }, formControlName);
    });
  }

  requestAndSetCurrentCoordinates(formControlName: string) {
    let latLng = this.LIMA_LTG_LNG;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          latLng.lat = position.coords.latitude;
          latLng.lng = position.coords.longitude;
          this.setMarker({ latLng, setCenter: true }, formControlName);
        },
        (e) => console.error(e),
        { enableHighAccuracy: true, timeout: 5000 }
      );
    }
  }

  private setMarker(
    {
      latLng,
      fillAutocompleteField = true,
      setCenter = false,
    }: {
      latLng: ILatLng;
      fillAutocompleteField?: boolean;
      setCenter?: boolean;
    },
    formControlName: string
  ) {
    if (!this.currentPosition || !this.currentPosition.setPosition) {
      this.currentPosition = new google.maps.Marker({
        position: latLng,
        map: this.map,
        icon: 'https://yevononprodstorage.blob.core.windows.net/yevononprodcontainer/pin.png',
      });
    } else {
      this.currentPosition.setMap(this.map);
      this.currentPosition.setPosition(latLng);
    }

    if (setCenter) {
      this.map.setCenter(latLng);
    }

    if (fillAutocompleteField) {
      this.setAddressFromGeocode(latLng, formControlName);
    }

    this.googlePlaceSelected = true;
  }

  private setAddressFromGeocode(latLng: ILatLng, formControlName: string) {
    this.geocoder.geocode(
      {
        location: latLng,
      },
      (results, status) => {
        const result = results[0];
        if (status === google.maps.GeocoderStatus.OK && result) {
          if (this.modalSearchField) {
            this.modalSearchField.value = result.formatted_address;
          }
          if (formControlName == 'address') {
            this.formMyHomeGroup.get(formControlName)?.setValue(result.formatted_address);
            this.coordinates[formControlName] = latLng;
          } else if (formControlName == 'businessAddress') {
            this.formBusinessGroup.get('ba')?.setValue(result.formatted_address);
            this.coordinates[formControlName] = latLng;
          }
        }
      }
    );
  }

  private validatePlaceOptionSelected(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.googlePlaceSelected ? null : { googlePlaceSelected: { value: control.value } };
    };
  }

  // UTILS METHODS
  public showError(field: string, dirty: boolean = false): boolean | undefined {
    return FormUtil.showError(field, dirty, this.frmUserInfo);
  }

  public getErrorsFromField(field: string): fieldForm {
    return FormUtil.getErrorsFromField(field, this.frmUserInfo);
  }

  public getErrorsFromSocialField(field: string): fieldForm {
    return FormUtil.getErrorsFromField(field, this.formSocialMediaGroup);
  }

  public getErrorsFromFieldBusiness(field: string): fieldForm {
    return FormUtil.getErrorsFromField(field, this.formBusinessGroup);
  }

  public getErrorsFromFieldWork(field: string): fieldForm {
    return FormUtil.getErrorsFromField(field, this.formWorkInfoGroup);
  }

  public trimField(field: string) {
    return FormUtil.trimField(field, this.frmUserInfo);
  }

  private convertToIDropdownItem(items: any): IDropdownItem[] {
    let list: IDropdownItem[] = [];
    items.map((x: any) => list.push({ label: x.name, value: x.code }));
    return list;
  }

  // GEOLOCATION METHODS
  public handleGeolocationClick(geolocationType: string, geolocation?: any): void {
    const formToUpdate = geolocationType == 'geolocation' ? this.formMyHomeGroup : this.formBusinessGroup;

    if (geolocation?.value?.length === 2) {
      formToUpdate.get(geolocationFormControls[geolocationType].department)?.setValue(geolocation?.label);
      return;
    }
    if (geolocation?.value?.length === 4) {
      formToUpdate.get(geolocationFormControls[geolocationType].province)?.setValue(geolocation?.label);
      return;
    }
    if (geolocation?.value?.length === 6) {
      formToUpdate.get(geolocationFormControls[geolocationType].district)?.setValue(geolocation?.label);
      this.ubigeoSelected[geolocationType === 'geolocation' ? 'selectedUbigeo' : 'selectedUbigeoBusiness'] =
        '01' + geolocation?.value;
    }
  }

  // ACTIVTY TYPE METHODS
  private showHideActivityTypeFields(activityTypeCode: number) {
    if (activityTypeCode === INDEPENDENT_EMPLOYMENT_CODE) {
      this.isIndependent = true;
      this.isDependent = false;
      this.setIndependentFieldsObligatory(true);
    } else {
      this.isDependent = true;
      this.isIndependent = false;
      this.setIndependentFieldsObligatory(false);
    }
  }

  private setIndependentFieldsObligatory(isObligatory: boolean) {
    if (isObligatory) {
      this.frmUserInfo.get('businessTime')?.setValidators([Validators.required]);
      this.frmUserInfo.get('businessAddress')?.setValidators([this.validatePlaceOptionSelected()]);
      this.frmUserInfo.get('businessType')?.setValidators([Validators.required]);
      this.frmUserInfo.get('businessSector')?.setValidators([Validators.required]);
    } else {
      this.frmUserInfo.get('businessTime')?.setValidators([Validators.nullValidator]);
      this.frmUserInfo.get('businessAddress')?.setValidators([Validators.nullValidator]);
      this.frmUserInfo.get('businessType')?.setValidators([Validators.nullValidator]);
      this.frmUserInfo.get('businessSector')?.setValidators([Validators.nullValidator]);
    }

    this.frmUserInfo.get('businessTime')?.updateValueAndValidity();
    this.frmUserInfo.get('businessAddress')?.updateValueAndValidity();
    this.frmUserInfo.get('businessType')?.updateValueAndValidity();
    this.frmUserInfo.get('businessSector')?.updateValueAndValidity();
  }

  // LOADING PREVIOUS DATA
  private loadSavedData() {
    this.geolocationSelected = this.user.ubigeo?.substring(2);

    const latitude = this.user.latitude!;
    const longitude = this.user.longitude!;
    if (latitude && longitude) {
      this.coordinates['address'].lat = latitude;
      this.coordinates['address'].lng = longitude;
      this.setAddressFromGeocode(
        { lat: parseFloat(this.coordinates['address'].lat), lng: parseFloat(this.coordinates['address'].lng) },
        'address'
      );
      this.googlePlaceSelected = true;
    }

    const userInfo = this.user.userInfoResponse;

    const options = this.dropdownOptions;
    this.loadDocumentType(this.user?.typeDocument);
    this.loadSavedCombos(userInfo, options);
    this.loadBusinessInfo(userInfo, options);
    this.setLoadedInfoIntoForm();
  }

  private findInAllItems(options: any, valueToFind: any) {
    return options.find((x: any) => x.value == valueToFind);
  }

  private loadDocumentType(typeDocument: string = '') {
    this.documentTypeSelected = typeDocument === 'E' ? this.documentTypeList[1] : this.documentTypeList[0];
  }

  private loadSavedCombos(userInfo: any, options: any) {
    // user info
    this.selectedOptions.genderSelected = this.findInAllItems(options.genderOptions, userInfo?.gender);
    this.selectedOptions.employmentSelected = this.findInAllItems(
      options.employmentOptions,
      userInfo?.employmentSituation
    );
    this.selectedOptions.maritalStatusSelected = this.findInAllItems(
      options.maritalStatusOptions,
      userInfo?.maritalStatus
    );
    options.howManyChildrenOptions.sort((a: any, b: any) => (a.label < b.label ? -1 : 1));

    this.selectedOptions.howManyChildrenSelected = this.findInAllItems(
      options.howManyChildrenOptions,
      userInfo?.numberChildren
    );
    if (this.user?.userInfoResponse?.maritalStatus == this.MARITAL_STATUS_INDEX) {
      this.selectedOptions.spouseDocumentTypeSelected = this.findInAllItems(
        DOCUMENTTYPELIST,
        userInfo?.spouseTypeDocument
      );
    } else {
      this.selectedOptions.spouseDocumentTypeSelected = this.documentTypeList[0];
    }
    //my Home
    this.selectedOptions.housingTypesSelected = this.findInAllItems(options.housingTypesOptions, userInfo?.housingType);
  }

  private loadBusinessInfo(userInfo: any, options: any) {
    this.geolocationBusinessSelected = this.user.userInfoResponse?.ubigeo?.substring(2);
    this.selectedOptions.monthlyIncomesSelected = this.findInAllItems(
      options.monthlyIncomesOptions,
      userInfo?.monthlyIncome
    );
    const latitude = this.user.userInfoResponse?.businessLatitude!;
    const longitude = this.user.userInfoResponse?.businessLongitude!;
    if (latitude && longitude) {
      this.coordinates['businessAddress'].lat = latitude;
      this.coordinates['businessAddress'].lng = longitude;
      this.setAddressFromGeocode(
        {
          lat: parseFloat(this.coordinates['businessAddress'].lat),
          lng: parseFloat(this.coordinates['businessAddress'].lng),
        },
        'businessAddress'
      );
      this.googlePlaceSelected = true;
    }
    this.selectedOptions.businessTypesSelected = this.findInAllItems(
      options.businessTypesOptions,
      userInfo?.businessType
    );
    this.selectedOptions.businessSectorsSelected = this.findInAllItems(
      options.businessSectorsOptions,
      userInfo?.businessSector
    );
    this.formBusinessGroup.get('businessSector')?.setValue(this.selectedOptions.businessSectorsSelected!);
    this.formBusinessGroup.get('businessType')?.setValue(this.selectedOptions.businessTypesSelected!);
  }

  private setLoadedInfoIntoForm() {
    this.frmUserInfoGroup.get('gender')?.setValue(this.selectedOptions?.genderSelected!);
    this.frmUserInfoGroup.get('employment')?.setValue(this.selectedOptions?.employmentSelected!);
    this.frmUserInfoGroup.get('maritalStatus')?.setValue(this.selectedOptions?.maritalStatusSelected!);
    this.frmUserInfoGroup.get('numberOfChildren')?.setValue(this.selectedOptions?.howManyChildrenSelected!);

    this.showHideActivityTypeFields(this.selectedOptions.employmentSelected.value);
  }

  activeClass(elem: string) {
    document.getElementById(elem + '-header')?.classList.add('collapse-header');
  }

  hiddenClass(id: string): void {
    document.getElementById(id + '-header')?.classList.remove('collapse-header');
  }

  onCancelSubmit() {
    this.formDisabled = true;
    this.fillAllInputData();
  }
  onCancelSubmitPersonalInfo(): void {
    this.formTouchedPersonalInfo = false;
    this.formMarkAsTouched(this.frmUserInfoGroup, false);

    this.formDisabledPersonalInfo = true;
    this.setDisableFormPersonalInfo();
    this.fillAllInputData();
  }
  onCancelSubmitHomeInfo(): void {
    this.formTouchedHomeInfo = false;
    this.formMarkAsTouched(this.formMyHomeGroup, false);

    this.formDisabledHomeInfo = true;
    this.setDisableFormHomeInfo();
    this.fillAllInputData();
  }
  onCancelSubmitBusinessInfo(): void {
    this.formTouchedBusinessInfo = false;
    this.formMarkAsTouched(this.formBusinessGroup, false);

    this.formDisabledBusinessInfo = true;
    this.setDisableFormBusinessInfo();
    this.fillAllInputData();
  }
  onCancelSubmitSocialInfo(): void {
    this.formTouchedSocialInfo = false;
    this.formMarkAsTouched(this.formSocialMediaGroup, false);

    this.formDisabledSocialInfo = true;
    this.setDisableFormSocialInfo();
    this.fillAllInputData();
  }
  onCancelSubmitWorkInfo(): void {
    this.formTouchedWorkInfo = false;
    this.formMarkAsTouched(this.formWorkInfoGroup, false);

    this.formDisabledWorkInfo = true;
    this.setDisableFormWorkInfo();
    this.fillAllInputData();
  }

  formMarkAsTouched(form: FormGroup, isTouched: boolean = true) {
    (<any>Object).values(form.controls).forEach((control: any) => {
      isTouched ? control.markAsTouched() : control.markAsUntouched();
    });
  }

  replacedata(text: string) {
    let replace = this.formSocialMediaGroup.get(text)?.value;
    replace = replace.replace(/</g, '');
    replace = replace.replace(/>/g, '');
    this.formSocialMediaGroup.get(text)?.setValue(replace);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-tool-section',
  templateUrl: './tool-section.component.html',
  styleUrls: ['./tool-section.component.scss'],
})
export class ToolSectionComponent implements OnInit {
  parentRoute = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.parentRoute = this.activatedRoute.parent?.routeConfig?.path || '';
  }

  goToToolPage(): void {
    if (this.parentRoute === 'emprende-mujer') {
      this.dataLayerService.virtualEventEM('Seleccionar: Conócelas aquí', 16);
      this.router.navigate(['nuevo-intranet/comunidades/emprende-mujer', 'tools']);
    } else {
      this.dataLayerService.virtualEventBodegueros('Seleccionar: Conócelas aquí', 15);
      this.router.navigate(['nuevo-intranet/comunidades/bodegueros', 'tools']);
    }
  }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@shared/services/local-storage.service';

@Component({
  selector: 'yevo-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() breadcrumbs: { pageName: string; url: string[] | null; active: boolean }[] = [];
  @Input() goback = false;
  @Input() bread = '';

  constructor(private router: Router, private LocalStorageService: LocalStorageService) {}

  goBack() {
    this.router.navigate([this.bread], {
      queryParams: {
        estado: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  removeMenu(i: number) {
    if (i == 0) {
      sessionStorage.setItem('activePageMobile', `-1`);
      this.LocalStorageService.set('activePage', '-1');
      this.LocalStorageService.set('activePageUrlSubmenu', '-1');
    }
  }
}

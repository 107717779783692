<div class="open-map">
  <ng-container>
    <a class="btn btn-link" (click)="openModalMap(modalcontent)">
      Quiero usar mi ubicación actual
      <img src="/assets/icons/financing/pin.svg" alt="ping" />
    </a>
  </ng-container>
</div>

<!-- Map Modal -->
<ng-template #modalcontent let-modal>
  <div class="modal-body">
    <input id="modalSearchField" type="text" placeholder="Ingresa una dirección aquí" />
    <div id="map"></div>
  </div>
  <div class="modal-footer justify-content-center">
    <div class="d-flex">
      <div class="dismiss-button mr-1 mr-sm-3">
        <yevo-button [className]="'light'" (click)="close()">Cancelar</yevo-button>
      </div>
      <div class="accept-button">
        <yevo-button [className]="'primary'" (click)="Accept()">Aceptar</yevo-button>
      </div>
    </div>
  </div>
</ng-template>

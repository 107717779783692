import { Subscription, timer } from 'rxjs';

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OtpService } from '@shared/services/otp.service';
import { IOtpResponse, OtpStatusResponse } from '@shared/interfaces';

@Component({
  selector: 'yevo-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() userEmail!: string;
  @Output() validOtp = new EventEmitter<boolean>();

  @ViewChild('digit1') digit1Input!: ElementRef;
  @ViewChild('digit2') digit2Input!: ElementRef;
  @ViewChild('digit3') digit3Input!: ElementRef;
  @ViewChild('digit4') digit4Input!: ElementRef;
  @ViewChild('digit5') digit5Input!: ElementRef;

  otpForm!: FormGroup;
  digitInputs!: ElementRef[];

  isContactViewVisible = false;
  isSubmitDisabled = false;
  isOtpMatchErrorVisible = false;
  isMaxOtpValidationReached = false;
  isTimeCounterFinished = false;
  submitButtonLabel = 'Confirmar código';

  MAX_RESEND_ATTEMPTS = 3;
  currentResendAttempts = 1;

  countDown$!: Subscription;
  timeCounter = 600; // seconds
  tick = 1000; // one second in milliseconds

  get isMaxResendAttemptsReached(): boolean {
    return this.currentResendAttempts === this.MAX_RESEND_ATTEMPTS;
  }

  get isCloseModalMessageShown(): boolean {
    return this.isMaxResendAttemptsReached && (this.isMaxOtpValidationReached || this.isTimeCounterFinished);
  }

  constructor(private router: Router, private formBuilder: FormBuilder, private otpService: OtpService) {}

  ngOnInit() {
    this.generateOTP();
    this.initForm();
    this.countDown$ = timer(0, this.tick).subscribe(() => {
      if (this.timeCounter > 0) --this.timeCounter;
    });
  }

  ngAfterViewInit() {
    this.digitInputs = [this.digit1Input, this.digit2Input, this.digit3Input, this.digit4Input, this.digit5Input];
  }

  ngOnDestroy() {
    this.countDown$.unsubscribe();
  }

  initForm() {
    this.otpForm = this.formBuilder.group({
      digit1: ['', [Validators.required]],
      digit2: ['', [Validators.required]],
      digit3: ['', [Validators.required]],
      digit4: ['', [Validators.required]],
      digit5: ['', [Validators.required]],
    });
  }

  validateOTP() {
    const code = this.getCodeInserted();
    this.hideErrorMessages();
    this.submitButtonLabel = 'Validando...';
    this.isSubmitDisabled = true;

    this.otpService.verifyOTP(code).subscribe(
      (response: IOtpResponse) => {
        this.resolveServerResponse(response.status);
        this.otpForm.reset();
        this.isSubmitDisabled = false;
        this.submitButtonLabel = 'Confirmar código';
      },
      (err) => {
        this.isSubmitDisabled = false;
        console.error(err);
      }
    );
  }

  handleDigitKeypress($event: KeyboardEvent, digitPosition: number) {
    if (this.isZeroToNineKeyboard($event) && digitPosition < 5) {
      const nextDigitPositionToFocus = digitPosition + 1;
      setTimeout(() => this.focusDigitInput(nextDigitPositionToFocus));
    }
  }

  handleDigitKeydown($event: KeyboardEvent, digitPosition: number) {
    if ($event.key === 'Backspace') {
      const nextDigitPositionToFocus = digitPosition - 1;
      setTimeout(() => this.focusDigitInput(nextDigitPositionToFocus));
    }
  }

  handleResendCode($event: Event) {
    if (this.currentResendAttempts < this.MAX_RESEND_ATTEMPTS) {
      this.currentResendAttempts++;
      this.otpForm.reset();
      this.hideErrorMessages();
      this.timeCounter = 600; // seconds
      this.reGenerateOTP();
    }
  }

  handleSendSolicitude($event: Event) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['contacto']));
    window.open(url, '_blank');
    this.validOtp.emit(false);
  }

  private focusDigitInput(inputDigitNumber: number) {
    this.digitInputs[inputDigitNumber - 1].nativeElement.focus();
  }

  private isZeroToNineKeyboard($event: KeyboardEvent) {
    return $event.key >= '0' && $event.key <= '9';
  }

  private getCodeInserted(): string {
    return (
      this.otpForm.get('digit1')?.value +
      this.otpForm.get('digit2')?.value +
      this.otpForm.get('digit3')?.value +
      this.otpForm.get('digit4')?.value +
      this.otpForm.get('digit5')?.value
    );
  }

  private resolveServerResponse(status: string) {
    if (status === OtpStatusResponse.APPROVED) {
      console.info('OTP verified successfully.');
      this.validOtp.emit(true);
    } else if (status === OtpStatusResponse.PENDING) {
      this.isOtpMatchErrorVisible = true;
      this.otpForm.reset();
    } else if (status === OtpStatusResponse.MAX_ATTEMPTS_REACHED) {
      this.isMaxOtpValidationReached = true;
      this.timeCounter = 0;
    } else if (status === OtpStatusResponse.EXPIRED) {
      this.isTimeCounterFinished = true;
    } else {
      console.error('Resolución de OTP no definida.');
    }
  }

  private generateOTP() {
    this.otpService.generateOTP().subscribe(() => {
      console.info('OTP generated');
    });
  }

  private reGenerateOTP() {
    this.otpService.reGenerateOTP().subscribe(() => {
      console.info('OTP re-generated');
    });
  }

  private hideErrorMessages() {
    this.isOtpMatchErrorVisible = false;
    this.isMaxOtpValidationReached = false;
    this.isTimeCounterFinished = false;
  }
}

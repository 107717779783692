<!-- ********************* MODAL *********************** -->
<ng-template #content let-c="close" let-d="dismiss" class="modal-contents">
  <div class="modal-header position-absolute close-button">
    <button type="button" class="modal-close-button" aria-label="Close" (click)="closeModal()">
      <img src="/assets/icons/close-small.svg" alt="cerrar" />
    </button>
  </div>
  <div class="modal-body text-center py-4 hidden">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <img width="90" height="45" src="/assets/img/info-error.png" class="modal-logo" alt="modal-logo" />
        </div>
        <div class="col-md-12">
          <h1 class="modal-title mt-4 mb-4 text-center">
            ¿Qué significa confiar en <br />
            este dispositivo?
          </h1>
          <p class="modal-description text-center">
            Iniciarás sesión automáticamente <br />
            en este navegador sin escribir tu <br />
            contraseña y tu sesión se <br />
            matendrá activa. Cerrarás <br />
            sesión cuando gustes.
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITabItem } from '@shared/interfaces';

@Component({
  selector: 'yevo-training-tabs',
  templateUrl: './training-tabs.component.html',
  styleUrls: ['./training-tabs.component.scss'],
})
export class TrainingTabsComponent {
  @Input() items: ITabItem[] = [];
  @Input() selectedItem!: ITabItem;
  @Output() selectedItemEmit = new EventEmitter<any>();

  ngOnDestroy(): void {
    this.resetTabs();
    this.selectedItemEmit.emit(this.selectedItem);
  }

  selectTab(tab: ITabItem): void {
    this.selectedItem = tab;
    this.setTabActive(this.selectedItem);
    this.selectedItemEmit.emit(this.selectedItem);
  }

  resetTabs() {
    this.selectedItem = this.items[0];
    this.setTabActive(this.selectedItem);
  }

  setTabActive(activeTab: ITabItem): void {
    this.items.forEach((x) => {
      x.active = x.label == activeTab.label;
    });
  }
}

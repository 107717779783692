import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IMessagePayload } from '@shared/interfaces/frontend';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/functions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly notificationsApi = `${environment.apiBaseUrl}/notification`;

  constructor(private httpClient: HttpClient) {
    if (firebase.messaging.isSupported()) {
      firebase.initializeApp(environment.firebaseConfig);
    }
  }

  requestPermission() {
    return new Promise((resolve, reject) => {
      (async () => {
        const permsis = await Notification.requestPermission();
        if (permsis === 'granted') {
          const tokenFirebase = await firebase.messaging().getToken();
          resolve(tokenFirebase);
        } else {
          reject(new Error('No se otorgaron los permisos'));
        }
     })();
    });

    // Promise returned in function argument where a void return was expected.
    
  }

  private messagingObservable = new Observable<IMessagePayload>((observe) => {
    firebase.messaging().onMessage((payload: any) => {
      observe.next(payload);
    });
  });

  receiveMessage() {
    return this.messagingObservable;
  }

  registerClientWithToken(token: string) {
    return this.httpClient.post(`${this.notificationsApi}/token`, { token });
  }

  getTokenClient(token: string) {
    return this.httpClient.post(`${this.notificationsApi}/repository/token`, { token });
  }

  updateTokenClient(oldToken: string, newToken: string) {
    return this.httpClient.post(`${this.notificationsApi}/repository/replace`, { oldToken, newToken });
  }

  async generateTokenNotification() {
    await firebase
      .messaging()
      .getToken()
      .then(async (oldToken) => {
        this.getTokenClient(oldToken).subscribe(
          async (result) => {
            await firebase.messaging().deleteToken();
            const newToken = await firebase.messaging().getToken();

            this.updateTokenClient(oldToken, newToken).subscribe();
          },
          (error) => {}
        );
      })
      .catch(() => {});
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BANNER_VALUES } from '@shared/constants/dashboard.constants';
import { AuthService } from '@shared/services/auth.service';

@Component({
  selector: 'yevo-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss']
})
export class TopBannerComponent implements OnInit {
  @Output() loadAlert: EventEmitter<boolean> = new EventEmitter(false);
  isLogged: boolean = false;
  constructor(private router: Router,private authService: AuthService) { }

  ngOnInit(): void {
    this.loadCalendar();
    this.authService.isLogged$.subscribe((logged) => {
      this.isLogged = logged;
    });
  }

  loadCalendar(): void {
    this.loadAlert.emit(true);
  }
  goToLink(){
 console.log(this.isLogged ? ['/financiamiento', 'filtro-financiamiento']: ['/financiamiento', 'solicitud']);
 this.router.navigate(this.isLogged ? ['/financiamiento', 'filtro-financiamiento']: ['/financiamiento', 'solicitud'], { queryParamsHandling: 'merge' });
  }
}

<div class="share-link-box mt-2">
    <div class="share-link-box__input-group-content">
      <input type="text" 
      [readonly]="inputIsDisabled"
      class="share-link-box__input" 
      value="{{ truncateTextRecommend(shareLink , maxTitleLengthShareText) }}" 
      aria-describedby="basic-addon2">
      <span class="share-link-box__button--mobile" id="basic-addon2" (click)="copyLink()" (keydown)="handleKeyDown($event)">
          <img src="/assets/icons/refers/copy.svg" alt="copiar enlace" class="share-link-box__icon"> 
          <span class="share-link-box__label">copiar</span> 
      </span>
    </div>
</div>
<div class="share-link-box__message" *ngIf="this.selectedCopyLink">Enlace copiado</div>
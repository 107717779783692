<div class="launches-card">
    <div class="launches-card_">
      <div class="col-12 launches-card__content-img mt-2">
        <img [src]="release.image" alt="badge" class="launches-card__img width_mobile" />
        <div class="gradient"></div>
      </div>
      <div class="pt-05 pl-0 pr-0 launches-card_text">            
        <p *ngIf="release.type === 'default'" 
           class="launches-card_text__title">{{ release.titleInit }} 
           <span class="launches-card_text__title_color">{{
                 release.titleEnd }}
           </span>
          {{ release.titleCenter}}</p>
              
        <p *ngIf="release.type === 'seccond'" class="launches-card_text__title tipo1"><span
            class="launches-card_text__title_color">{{ release.titleInit
            }}</span> {{ release.titleEnd }}</p>
      
        <p class="launches-card_text__description">{{ release.descriptionInit }}<span class="launches-card_text__description_color">{{ release.descriptionCenter }} </span> {{ release.descriptionEnd }}</p>
       <p [outerHTML]="release.spaces"></p>
      </div>
    </div>
    <div class="launches-card__button">
      <button class="launches-card__button_card-btn" 
              (click)="navigateRelease(release);"  
              [disabled]="!release.isActiveButton" 
              [ngClass]="{'color-disabled': !release.isActiveButton}">{{ release.nameButton }}
      </button>
    </div>
  </div>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Forum, ResponseMessage } from '@shared/interfaces/frontend/communities';
import { CommunityService } from '@shared/services/community.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yevo-modal-question-message',
  templateUrl: './modal-question-message.component.html',
  styleUrls: ['./modal-question-message.component.scss'],
})
export class ModalQuestionMessageComponent implements OnInit, OnDestroy {
  modalBoxActive = false;
  modalTransitionActive = false;
  @Input()
  responseMessage: ResponseMessage = {
    message: '',
  };
  forumId = 0;
  dataForum: Forum = {
    question: '',
    forumId: 0,
    answer: [],
    dueDate: '',
    userName: '',
    questionDetail: '',
    creationDate: '',
    view: 0,
    userId: 0,
    communityId: 0,
  };
  subscription = new Subscription();
  codeWomen = 1;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private communityService: CommunityService
  ) {}

  ngOnInit(): void {
    this.deactivateScroll();
    this.modalBoxActive = true;
    this.modalTransitionActive = true;
    if (this.responseMessage.message && this.responseMessage.message.split.length > 0) {
      this.forumId = Number(this.responseMessage.message.split(':')[1].trim());
    }
  }

  activateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'auto';
  }

  closePopup(): void {
    this.modalTransitionActive = false;
    this.modalBoxActive = false;
    const t = setTimeout(() => {
      clearTimeout(t);
      this.activateScroll();
    }, 400);
    this.activeModal.close('Modal Question Message Closed');
  }

  deactivateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'hidden';
  }

  openQuestion(): void {
    this.subscription.add(
      this.communityService.getForumId(this.forumId).subscribe(
        (response) => {
          this.dataForum = response;
          const route =
            this.codeWomen === this.dataForum.communityId
              ? 'nuevo-intranet/comunidades/emprende-mujer/foro/id'
              : 'nuevo-intranet/comunidades/bodegueros/foro/id';
          this.router
            .navigate([route, this.forumId], { state: { data: this.dataForum } })
            .then((result) => {
              console.info(result);
            })
            .catch((error) => {
              console.error(error);
            });
          this.closePopup();
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

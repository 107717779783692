import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { DirectoryService } from '@shared/services/directory.service';
import { LogglyService } from '@shared/services/loggly.service';
import { Observable, Subscription } from 'rxjs';
import { Image } from 'src/app/shared/models/ImageModel';

type ImageValue = string | ArrayBuffer | null;

@Component({
  selector: 'yevo-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent implements OnInit {
  @Input() loadedImages?: Image[];
  @Input() directoryId!: number;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input() changeView!: Observable<void>;

  frmImages!: FormArray;
  errorMsg?: string | null;
  amountErrorMsg?: string | null;
  eventsSubscription!: Subscription;

  MESSAGE_LIMIT_REQUEST = 'Excediste el límite de intentos. Intentalo nuevamente en un minuto';

  // IMAGE CROPPER EVENTS | METHODS
  fileChangeEvent(event: any): void {
    this.errorMsg = null;
    if (event.target.files && event.target.files.length) {
      for (const element of event.target.files) {
        this.uploadImage(element);
      }
    }
    this.fileInput.nativeElement.value = '';
  }

  private uploadImage(file: any) {
    const reader = new FileReader();
    const fileToBeUploaded = file;
    let errorMsg = '';

    if (this.isImageGreaterThanXMB(fileToBeUploaded)) {
      errorMsg = 'El tamaño de la imágen debe ser menor a 5 MB.';
      console.error(errorMsg);
      return;
    }
    if (!/(jpe?g|png)$/i.test(file?.type)) {
      errorMsg = 'Solo se admiten imágenes.';
      console.error(errorMsg);
      return;
    }
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.addImage(file.name, reader.result);
    };
  }

  private isImageGreaterThanXMB(file: File, sizeLimit: number = 1): boolean {
    const fileSizeInMB = +(file.size / 5120 / 1024).toFixed(4);
    return fileSizeInMB > sizeLimit;
  }
  // END IMAGE CROPPER EVENTS | METHODS

  public get images(): Image[] {
    return this.frmImages.value as Image[];
  }

  public get hasImages(): number {
    return this.frmImages.value.length;
  }

  constructor(
    private fb: FormBuilder,
    public directoryService: DirectoryService,
    private logglyService: LogglyService
  ) {}

  ngOnInit(): void {
    this.frmImages = this.fb.array([], Validators.required);
    this.loadData();
    this.eventsSubscription = this.changeView.subscribe(() => {
      this.errorMsg = '';
      this.amountErrorMsg = '';
    });
  }

  private loadData(): void {
    this.loadedImages?.forEach((loadedImage) => {
      const pathSegments = loadedImage.value.split('/');
      this.addImagesLoadedToForm(
        pathSegments[pathSegments.length - 1],
        loadedImage.value,
        loadedImage.directoryImageId
      );
    });
  }

  private addImagesLoadedToForm(fileName: string, value: ImageValue, directoryImageId?: number): void {
    this.frmImages.push(this.fb.group({ fileName, value, directoryImageId }));
  }

  private addImage(filePath: string, value: ImageValue): void {
    const filePathSegments = filePath.split('\\');
    const fileName = filePathSegments[filePathSegments.length - 1];
    this.addImageToForm(fileName, value);
  }

  private addImageToForm(fileName: string, value: ImageValue, directoryImageId?: number): void {
    const imgIntoForm = this.fb.group({ fileName, value, directoryImageId });
    this.frmImages.push(imgIntoForm);

    if (this.frmImages.controls.length > 4) {
      this.amountErrorMsg = 'Sólo se pueden subir 4 imágenes como máximo.';
      this.frmImages.controls.pop();
      this.images.pop();
    } else {
      this.amountErrorMsg = '';
      let indexImageLoading = this.frmImages.length - 1;
      this.directoryService.addImageProgress(indexImageLoading);
      this.saveImage(imgIntoForm.value, indexImageLoading);
    }
  }

  private saveImage(img: Image, index: number) {
    let imgSave: Image[] = [];
    imgSave.push(this.formatImageToUpload(img));

    this.logglyService.log('Affiliate - Upload images Start', {
      directoryId: this.directoryId,
    });

    if (img) {
      this.directoryService.uploadImages(this.directoryId, imgSave).subscribe(
        (event: any) => {
          this.logglyService.log('Affiliate - Upload images End', {
            directoryId: this.directoryId,
          });
          if (event?.status == 200) {
            const imgSaved = event?.body?.images[0]!;
            this.frmImages.value[index] = this.setImageSavedIntoForm(imgSaved, this.frmImages.value[index]);
          }
          this.directoryService.setImageProgress(index, 0, false);
          this.errorMsg = '';
        },
        (error) => {
          this.errorMsg = this.MESSAGE_LIMIT_REQUEST;
          this.directoryService.setImageProgress(index, 0, false);
          this.frmImages.controls.pop();
          this.images.pop();
        }
      );
    }
  }

  private formatImageToUpload(image: Image) {
    const fileSegments = image.value.split(',');
    const base64Image = fileSegments[1];
    return {
      value: base64Image,
    };
  }

  private setImageSavedIntoForm(imgSaved: Image, formImgValue: any) {
    formImgValue.directoryImageId = imgSaved.directoryImageId;
    formImgValue.value = imgSaved.url;
    return formImgValue;
  }

  public deleteSavedImage(image: Image, index: number): void {
    let imgDelete: number[] = [];
    imgDelete.push(image.directoryImageId!);
    this.directoryService.deleteImages(this.directoryId, imgDelete).subscribe(
      (resp) => {
        if (resp) {
          this.frmImages.removeAt(index);
          if (this.images.length) {
            this.images.forEach((img) => img.directoryImageId !== image.directoryImageId!);
          }
          this.errorMsg = '';
        }
      },
      (error) => {
        this.errorMsg = this.MESSAGE_LIMIT_REQUEST;
      }
    );
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'milab-tab-want-to-do-today',
  templateUrl: './tab-want-to-do-today.component.html',
  styleUrls: ['./tab-want-to-do-today.component.scss']
})
export class TabWantToDoTodayComponent implements OnInit {

  @Input() detailToDoToday:any;
  @Input() titleTabFirst:string='';
  @Input() titleTabSecond:string='';
  @Output() showSectionTab: EventEmitter<number> = new EventEmitter<number>();

  activeTab: number = 0;
  constructor( private dataLayerService: DataLayerService){}
  ngOnInit(): void {
    this.dataLayerService.stepNewSeedCashEvent('2',`Card de Seguros: ${this.activeTab==1? this.titleTabFirst : this.titleTabSecond}`);
  }
  setActiveTab(index: number): void {
    this.dataLayerService.stepNewSeedCashEvent('2',`Card de Seguros: ${index==1? this.titleTabFirst: this.titleTabSecond}`);
    this.activeTab = index;
    this.showSectionTab.emit(index);
  }
}

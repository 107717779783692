import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AchievementModalComponent } from '@shared/components/achievement-modal/achievement-modal.component';
import { DEFAULT_MODAL_OPTIONS } from '@shared/constants/ui-modal.constants';
import { Observable, Subject,BehaviorSubject } from 'rxjs';
import { UserProfileRequest } from '../models/UserProfileModel';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly directoryApi = `${environment.apiBaseUrl}/api-directory`;

  userInfoUpdated = new Subject<any>();
  private dataLoginSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public dataLogin$: Observable<string> = this.dataLoginSubject.asObservable();

  constructor(private httpClient: HttpClient, private modalService: NgbModal) {}

  getUserExtraInfo() {
    return this.httpClient.get(`${this.directoryApi}/param/userExtraInfo`);
  }

  updateUser(userInfoResponse: UserProfileRequest): Observable<any> {
    return this.httpClient.put(`${this.directoryApi}/user`, userInfoResponse, { observe: 'response' });
  }

  updateUserInfoData(userInfoRequest: any): Observable<any> {
    return this.httpClient.post(`${this.directoryApi}/user/personalData`, userInfoRequest, { observe: 'response' });
  }

  updateMyHomeInfoData(MyHomeData: any): Observable<any> {
    return this.httpClient.post(`${this.directoryApi}/user/homeData`, MyHomeData, { observe: 'response' });
  }

  updateMyBusinessInfoData(MyBusinessData: any): Observable<any> {
    return this.httpClient.post(`${this.directoryApi}/user/businessData`, MyBusinessData, { observe: 'response' });
  }

  updateUserSocialMediaData(socialMediaRequest: any): Observable<any> {
    return this.httpClient.post(`${this.directoryApi}/user/socialMediaData`, socialMediaRequest, {
      observe: 'response',
    });
  }

  updateUserWorkInformationData(workInformationData: any): Observable<any> {
    return this.httpClient.post(`${this.directoryApi}/user/workingData`, workInformationData, { observe: 'response' });
  }

  setUserImageProfile(image: any): Observable<any> {
    return this.httpClient.post(`${this.directoryApi}/user/image`, { value: image.value }, { observe: 'response' });
  }

  setUserImageHeader(image: any): Observable<any> {
    return this.httpClient.post(
      `${this.directoryApi}/user/imageHeader`,
      { value: image.value },
      { observe: 'response' }
    );
  }

  setUserLevelUp() {
    this.modalService.open(AchievementModalComponent, DEFAULT_MODAL_OPTIONS);
  }

  setDataLogin(value: string): void {
    this.dataLoginSubject.next(value);
  }
  
  cleanDataLogin(): void {
    this.dataLoginSubject.next('');
  }
}

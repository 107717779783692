import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SwiperOptions } from 'swiper/core';

import { ActivatedRoute, Router } from '@angular/router';
import { CONST_COMMUNITIES_CODE } from '@shared/constants/communities.constants';
import Section from '@shared/data/community/community-section.data.json';
import {
  Categories,
  PersonalizerRecomedationsRequest,
  RewardRequest,
  UserLevel,
  VideoBusiness,
  VideoViewedResponse,
} from '@shared/interfaces/frontend/communities';
import { UserProfile } from '@shared/models/UserProfileModel';
import { CommunityService } from '@shared/services/community.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Subscription } from 'rxjs';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'yevo-videos-business',
  templateUrl: './videos-business.component.html',
  styleUrls: ['./videos-business.component.scss'],
})
export class VideosBusinessComponent implements OnInit, OnDestroy, OnChanges {
  videosBusiness: VideoBusiness[] | undefined;
  videosBusinessShow: VideoBusiness[] = [];
  myVideos: VideoBusiness[] = [];
  isUpdateVideos: boolean = false;
  personalizerRecomedationsRequest: PersonalizerRecomedationsRequest = {
    userId: 0,
    rewardActionId: 0,
    probability: 0,
  }
  private subscription = new Subscription();
  config: SwiperOptions = {
    direction: 'horizontal',
    freeMode: true,
    slidesPerView: 1,
    spaceBetween: 16,
    navigation: {
      nextEl: '.swiper-button-videos-next',
      prevEl: '.swiper-button-videos-prev',
    },
    breakpoints: {
      334: {
        slidesPerView: 1.1,
        spaceBetween: -16,
      },
      380: {
        slidesPerView: 1.2,
        spaceBetween: -16,
      },
      450: {
        slidesPerView: 1.45,
        spaceBetween: -16,
      },
      550: {
        slidesPerView: 1.65,
        spaceBetween: -16,
      },
      600: {
        slidesPerView: 1.8,
        spaceBetween: -16,
      },
      690: {
        slidesPerView: 1.95,
        spaceBetween: -16,
      },
      768: {
        slidesPerView: 2.05,
        spaceBetween: -162,
      },
      850: {
        slidesPerView: 2.3,
        spaceBetween: -162,
      },
      950: {
        slidesPerView: 2.6,
        spaceBetween: -162,
      },
      1050: {
        slidesPerView: 2.9,
        spaceBetween: -162,
      },
      1150: {
        slidesPerView: 3.1,
        spaceBetween: -162,
      },
      1250: {
        slidesPerView: 3.4,
        spaceBetween: -162,
      },
      1350: {
        slidesPerView: 3.9,
        spaceBetween: -162,
      },
    },
  };
  slickConfig = {
    arrows: false,
    variableWidth: true,
    slidesToShow: 2,
    infinite: false,
    mobileFirst: true,
    swipe: true,
    swipeToSlide: true,
    slidesToScroll: 2,
  };
  @Input()
  communityId = 0;
  category: Categories[] = [];
  isPressed: boolean[] = [];
  seeAll: Categories = {
    name: 'Ver todo',
    categoryId: 0,
    quantity: 4,
    communityId: 0,
    id: 0,
  };
  resents: Categories = {
    name: 'Recientes',
    categoryId: 0,
    quantity: 4,
    communityId: 0,
    id: 0,
  };
  forMe: Categories = {
    name: 'Para Mí',
    categoryId: 0,
    quantity: 4,
    communityId: 0,
    id: 0,
  };
  @ViewChild('swiperBusiness', { static: false }) sliderRef?: SwiperComponent;
  user!: UserProfile;
  @Input()
  userLevel: UserLevel = {
    idLevel: 0,
    userId: 0,
    flagStatus: 'N',
  };
  @ViewChild('slickCarouselVideo', { static: false }) slickCarouselVideo?: SlickCarouselComponent;
  @Input()
  source = '';
  sourceHome = 'home';
  isMaxLevel: boolean = false;
  @Input() videoViewed: VideoViewedResponse[] = [];
  @Input() showForMe: boolean = true;
  @Input() showTitleCategories: boolean = true;
  pendingAward!: boolean;
  rewardRequest!: RewardRequest;
  @Input() nameSection: string = '';
  videoSecctionIntenal = '';
  initSectionCommunity!: boolean;
  constructor(
    private communityService: CommunityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private dataLayerService: DataLayerService
  ) {}

  goToSeeMoreVideos() {
    this.communityService.sendCommnunityId(this.communityId);
    return this.source === 'emprende-mujer'
      ? this.router.navigate(['nuevo-intranet/comunidades/emprende-mujer', 'videos'], {
          state: { communityId: this.communityId },
        })
      : this.router.navigate(['nuevo-intranet/comunidades/bodegueros', 'videos'], {
          state: { communityId: this.communityId },
        });
  }

  ngOnInit(): void {
    this.isMaxLevel =
      (this.communityId === 1 && CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_MAX_LEVEL === this.userLevel.idLevel) ||
      (this.communityId === 2 && CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_MAX_LEVEL === this.userLevel.idLevel);
    this.isUpdateVideos= false;
    this.getLevelUser();
    this.pendingAward = false;
    this.initSectionCommunity = false;
    this.videoSecctionIntenal = this.nameSection;
  }
  changeCommunityId(changes: SimpleChanges) {
    this.userLevel.idLevel = changes.communityId.currentValue;
    this.toggleButton(0);
  }
  changeUserLevel(changes: SimpleChanges) {
    if (
      changes.userLevel.previousValue.idLevel !== changes.userLevel.currentValue.idLevel &&
      changes.userLevel.currentValue.flagStatus === 'A'
    ) {
      this.getVideoByLevel();
    }
    this.isMaxLevel =
      (this.communityId === 1 && CONST_COMMUNITIES_CODE.COMMUNITY_WOMEN_MAX_LEVEL === this.userLevel.idLevel) ||
      (this.communityId === 2 && CONST_COMMUNITIES_CODE.COMMUNITY_BODEGUEROS_MAX_LEVEL === this.userLevel.idLevel);
    if (this.isMaxLevel) {
      this.getLevelUser();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.userLevel.idLevel > 0 && this.userLevel.flagStatus === 'A') {
      if (this.category[0] && this.category[0].name !== 'Para Mí') {
        const categoriesAux = JSON.parse(JSON.stringify(this.category));
        if (this.showForMe) {
          this.category = [this.forMe].concat(categoriesAux);
        } else {
          this.category = [].concat(categoriesAux);
          this.toggleButton(0);
        }
        this.isPressed = new Array(this.category.length).fill(false);
        this.isPressed[0] = true;
        this.getVideoByLevel();
      }
    }
    if (changes.communityId) {
      this.changeCommunityId(changes);
    }
    if (changes.userLevel) {
      this.changeUserLevel(changes);
    }
    setTimeout(() => {
      this.slickCarouselVideo?.unslick();
      this.slickCarouselVideo?.initSlick();
    }, 4000);
  }

  private loadVideos(): void {
    this.subscription.add(
      this.communityService.getVideosByCommunityId(this.communityId).subscribe(
        (response) => {
          this.videosBusiness = response;
          this.videosBusiness.sort((a, b) => {
            const dateA = new Date(a.creationDate).getTime();
            const dateB = new Date(b.creationDate).getTime();
            return dateA - dateB;
          });
          this.videosBusiness.reverse();
          this.videosBusinessShow = this.videosBusiness;
          this.videosBusinessShow = this.videosBusinessShow
            .map((t) => {
              const idsWebinarViewed = this.videoViewed.map((wv) => wv.videoId);
              t.viewed = idsWebinarViewed.includes(Number(t.id));
              return t;
            })
            .sort((a, b) => {
              if (a.viewed === b.viewed) return 0;
              if (a.viewed) return 1;
              return -1;
            });
          this.videosBusinessShow = this.videosBusinessShow.slice(0, 10);
          this.sliderRef?.swiperRef.slideTo(0);
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  toggleButton(index: number): void {
    for (let i = 0; i < this.isPressed.length; i++) {
      this.isPressed[i] = i === index;
    }
    const communityName = this.source === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
    if (this.initSectionCommunity) this.validDataLayerInternal(communityName, this.category[index].name, 'Filtrar por');
    this.initSectionCommunity = true;
    if (this.category[index].name === 'Ver todo') {
      this.goToSeeMoreVideos().catch((err) => console.error('err: ', err));
    } else if (this.category[index].name === 'Recientes') {
      this.loadVideos();
    } else if (this.category[index].name === 'Para Mí') {
      this.pendingAward = true;
      this.getVideoByLevel();
    } else {
      this.subscription.add(
        this.communityService
          .getVideoByCommunityAndCategory(this.communityId, this.category[index].categoryId)
          .subscribe(
            (response) => {
              this.videosBusinessShow = response;
              this.videosBusinessShow = this.videosBusinessShow
                .map((t) => {
                  const idsWebinarViewed = this.videoViewed.map((wv) => wv.videoId);
                  t.viewed = idsWebinarViewed.includes(Number(t.id));
                  return t;
                })
                .sort((a, b) => {
                  if (a.viewed === b.viewed) return 0;
                  if (a.viewed) return 1;
                  return -1;
                });
              this.sliderRef?.swiperRef.slideTo(0);
            },
            (error) => console.error(error)
          )
      );
    }
  }

  private loadCategory(): void {
    this.category = [];
    this.isPressed = [];
    if (this.userLevel.idLevel > 0 && this.userLevel.flagStatus === 'A' && !this.isMaxLevel) {
      if (this.showForMe) {
        this.category.push(this.forMe);
      }
    }
    this.category.push(this.resents);
    this.subscription.add(
      this.communityService.getVideosCategoryStatistics(this.communityId).subscribe((response) => {
        let qtyAdded = 0;
        response.forEach((item) => {
          if (item.quantity >= 4 && qtyAdded < 5) {
            this.category.push(item);
            qtyAdded += 1;
          }
        });
        this.seeAll.communityId = this.communityId;
        this.category.push(this.seeAll);
        this.isPressed = new Array(this.category.length).fill(false);
        this.isPressed[0] = true;
        let categoriesAux: string[] = [];
        this.category = this.category.filter((c) => {
          if (categoriesAux.includes(c.name)) {
            return false;
          }
          categoriesAux.push(c.name);
          return true;
        });
        this.toggleButton(0);
      })
    );
  }

  private getLevelUser(): void {
    const userString = this.localStorageService.get('userProfile');
    if (userString) {
      this.user = JSON.parse(userString) as UserProfile;
      this.subscription.add(
        this.communityService.getLevelUserByCommunityId(this.user.userId, this.communityId).subscribe(
          (response) => {
            this.userLevel = response;
            if (this.userLevel.idLevel > 0 && this.userLevel.flagStatus === 'A' && !this.isMaxLevel) {
              this.getVideoByLevel();
            } else {
              this.loadVideos();
            }
            this.loadCategory();
          },
          (error) => {
            console.error(error);
          }
        )
      );
    }
  }

  private getVideoByLevel(): void {
    if (this.isUpdateVideos) { return; }
    this.isUpdateVideos = true;
    this.subscription.add(
      this.communityService.getVideoByLevelAndCommunityId(this.userLevel.idLevel, this.communityId).subscribe(
        (response) => {
          this.loadPersonalizerVideos(response);
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  seeMore(): void {
    const community = this.source === 'emprende-mujer' ? 'Emprende Mujer' : 'de Bodegueros';
    this.validDataLayerInternal(community, 'Todos los videos', 'Ver video');
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  validDataLayerInternal(communityName: string, categoriaName: string, label: string) {
    if (this.nameSection === 'comunidad') {
      this.dataLayerService.clickCommunityInternal(Section.CommunityVideo.code, label, categoriaName);
      return;
    }
    const codeItem =
      communityName === 'Emprende Mujer' ? Section.WomenVideoAndWebinar.code : Section.WinemakersVideosAndWebinar.code;
    this.dataLayerService.clickCommunity(communityName, categoriaName, label, codeItem);
  }

  loadPersonalizerVideos(response: any): void {
    this.communityService.getPersonalizerVideoByCommunityIdAndUserId(this.communityId,this.user.userId).subscribe(
      (personalizerResponse) => {
        this.savePersonalizerRecomedations(personalizerResponse);
        this.myVideos =this.addPersonalizerVideo(response,personalizerResponse.videos);
        this.myVideos.sort((a, b) => {
          const dateA = new Date(a.creationDate).getTime();
          const dateB = new Date(b.creationDate).getTime();
          return dateA - dateB;
        });
        this.myVideos.reverse();
        this.videosBusiness = this.myVideos;
        this.videosBusinessShow = this.videosBusiness;
        this.videosBusinessShow = this.videosBusinessShow
          .map((t) => {
            const idsWebinarViewed = this.videoViewed.map((wv) => wv.videoId);
            t.viewed = idsWebinarViewed.includes(Number(t.id));
            return t;
          })
          .sort((a, b) => {
            if (a.viewed === b.viewed) return 0;
            if (a.viewed) return 1;
            return -1;
          });
        this.sliderRef?.swiperRef.slideTo(0);
      });
  }

  savePersonalizerRecomedations(response: any): void {
    this.personalizerRecomedationsRequest.userId = response.userId;
    this.personalizerRecomedationsRequest.rewardActionId = response.rewardActionId;
    this.personalizerRecomedationsRequest.probability = response.probability;
    this.communityService.savePersonalizerRecomedations(this.personalizerRecomedationsRequest).subscribe();
  }

  addPersonalizerVideo(videoBusiness :any,videosPersonalizer:any):any{
    const uniqueIds = new Set<number>();
    return [...videoBusiness, ...videosPersonalizer].filter((video) => {
      if (!uniqueIds.has(video.id)) {
        uniqueIds.add(video.id);
        return true;
      }
      return false;
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OtpModalService } from 'src/app/shared/services/otp-modal.service';

@Component({
  selector: 'yevo-modal-blanketerror',
  templateUrl: './modal-blanketerror.component.html',
  styleUrls: ['./modal-blanketerror.component.scss'],
})
export class ModalBlanketerrorComponent implements OnInit {
  @Input() openModal: boolean = false;
  modalReference!: NgbModalRef;
  reconnect: boolean = false;
  withoutConnection: boolean = false;

  constructor(private modalService: NgbModal, private OtpModalServices: OtpModalService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.reconnect = true;
    }, 30000);
    setTimeout(() => {
      this.withoutConnection = true;
    }, 60000);
  }

  public dismiss(): void {
    this.modalService.dismissAll();
  }

  public close(): void {
    this.modalService.dismissAll();
  }
}

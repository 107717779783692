import { Pipe, PipeTransform } from '@angular/core';
import { PricesMarketsResponse } from '@shared/interfaces/frontend/communities';

@Pipe({
  name: 'searchMarket'
})
export class SearchMarketPipe implements PipeTransform {

  transform(markets: PricesMarketsResponse[], search: string): any[] {

    if (!markets || !search) {
      return markets;
    }
    search = search.toLowerCase();
    markets= markets.filter(m =>
      m.marketName.toLowerCase().includes(search)
    );
    if(markets.length==0){
      return [{marketName: "No hay alternativas.",productName:"null", price: 0, varietyName: "null",dateCurrent: new Date, marketId: 0,productId: 0 }];
    }
    return markets;
  }


}

import { take, tap } from 'rxjs/operators';
import { Lookup } from 'src/app/shared/models';
import { ContactInformation, Directory, ExistingDirectoryResolved } from 'src/app/shared/models/DirectoryModel';
import { Image } from 'src/app/shared/models/ImageModel';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { DirectoryService } from 'src/app/shared/services/directory.service';
import { convertLookupToMap } from 'src/app/shared/utils/LookupToMapConverter';

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExistingDirectoryResolverKey } from '@shared/resolvers/existing-directory.resolver';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { LogglyService } from '@shared/services/loggly.service';

import { IntranetAffiliateResolved } from '@shared/models';
import { AffiliateResolverKey } from './affiliate.resolver';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { LocationsComponent } from './locations/locations.component';
import { ProductInfoComponent } from './product-info/product-info.component';
import { OtpModalService } from '@shared/services/otp-modal.service';
import { UserProfile } from '@shared/models/UserProfileModel';
import { Subject } from 'rxjs';

export enum StepTypes {
  tabGeneral = 'tab-general',
  tabProduct = 'tab-product',
  tabLocation = 'tab-location',
}

export enum SectionNames {
  general = 'general-section',
  contact = 'contact-section',
  product = 'product-section',
  locations = 'locations-section',
}

@Component({
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.scss'],
})
export class AffiliateComponent implements OnInit, OnDestroy {
  paymentMethods!: Map<string, number>;
  categories!: Map<string, number>;
  contactTypes!: Lookup[];
  directoryData!: Directory;
  images?: Image[];
  generalError?: string | null;
  errorCode?: string;
  errorMsgType: string = '';
  stepStatus: StepTypes = StepTypes.tabGeneral;
  sectionNames = SectionNames;

  user!: UserProfile;

  private ready: boolean = false;
  isSaving = false;

  isNewDirectory = false;
  isNewDirectoryUnpublished = false;
  isUpdateDirectory = false;
  directoryId!: number;
  btnEnabled: boolean = true;

  imgsUploaded: Image[] = [];
  imgsToUpload: Image[] = [];

  @ViewChild(GeneralInfoComponent) generalInfoComponent!: GeneralInfoComponent;
  @ViewChild(ContactInfoComponent) contactInfoComponent!: ContactInfoComponent;
  @ViewChild(ProductInfoComponent) productInfoComponent!: ProductInfoComponent;
  @ViewChild(LocationsComponent) locationsComponent!: LocationsComponent;
  @ViewChild(ImageUploaderComponent)
  imageUploaderComponent!: ImageUploaderComponent;

  eventsSubject: Subject<void> = new Subject<void>();

  public get isFormValidFirst(): boolean {
    return (
      this.ready && this.generalInfoComponent?.frmGeneralInfo?.valid && this.contactInfoComponent?.frmContactInfo?.valid
    );
  }

  public get isFormValidSecond(): boolean {
    return (
      this.ready &&
      this.generalInfoComponent?.frmGeneralInfo?.valid &&
      this.productInfoComponent?.frmProductInfo?.valid &&
      this.productInfoComponent?.isDeliveryValid() &&
      this.imageUploaderComponent?.frmImages?.valid
    );
  }

  public get isFormValid(): boolean {
    return (
      this.ready &&
      this.generalInfoComponent?.frmGeneralInfo?.valid &&
      this.productInfoComponent?.frmProductInfo?.valid &&
      this.productInfoComponent?.isDeliveryValid() &&
      this.locationsComponent?.frmLocations?.valid &&
      this.locationsComponent?.isDirectionTextValid() &&
      this.contactInfoComponent?.frmContactInfo?.valid &&
      this.imageUploaderComponent?.frmImages?.valid &&
      !this.isSaving
    );
  }

  constructor(
    private authService: AuthService,
    private directoryService: DirectoryService,
    private route: ActivatedRoute,
    private router: Router,
    private dataLayerService: DataLayerService,
    private logglyService: LogglyService,
    private localStorageService: LocalStorageService,
    private otpModalService: OtpModalService
  ) {}

  ngOnInit(): void {
    this.initLookups();
    this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
      this.user = user!;
      this.fillAffiliateData();
    });
    setTimeout(() => {
      this.ready = true;
    }, 10);
    this.showFooter(true);
    this.loadAutoSavedFormData();
  }

  ngOnDestroy() {
    this.showFooter(false);
  }

  loadAutoSavedFormData() {
    if (this.directoryData?.directoryId && this.directoryData?.directoryId != 0) {
      if (this.directoryData?.published) {
        this.isUpdateDirectory = true;
        this.isNewDirectoryUnpublished = false;
        this.isNewDirectory = false;
      } else {
        this.isNewDirectoryUnpublished = true;
        this.isNewDirectory = false;
        this.isUpdateDirectory = false;
      }
    } else {
      this.isNewDirectory = true;
      this.isNewDirectoryUnpublished = false;
      this.isUpdateDirectory = false;
    }

    const generalInfoSaved = this.localStorageService.parseAndGet(SectionNames.general);
    if (generalInfoSaved) {
      this.directoryData = { ...generalInfoSaved };
    }

    const contactInfoSaved = this.localStorageService.parseAndGet(SectionNames.contact);
    if (contactInfoSaved) {
      this.directoryData.contactInformation = contactInfoSaved;
    }

    const productInfoSaved = this.localStorageService.parseAndGet(SectionNames.product);
    if (productInfoSaved) {
      this.directoryData = { ...productInfoSaved, ...this.directoryData };
    }

    const locationsSaved = this.localStorageService.parseAndGet(SectionNames.locations);
    if (locationsSaved) {
      for (let location of locationsSaved) {
        const department = location.department.substr(0, 2);
        const province = location.province.substr(0, 4);
        const district = location.district.substr(0, 6);
        location.ubigeo = '51' + department + province + district;
      }
      this.directoryData.locationInformation = locationsSaved;
    }
  }

  showFooter(status: boolean) {
    const element = document?.querySelector('#footer-main') as HTMLElement;
    if (element) element.hidden = status;
  }

  isProductStep(): boolean {
    return this.stepStatus === StepTypes.tabProduct || this.stepStatus === StepTypes.tabLocation;
  }

  isLocationStep(): boolean {
    return this.stepStatus === StepTypes.tabLocation;
  }

  changeStepStatus(isNext: boolean) {
    if (isNext) {
      if (this.stepStatus == StepTypes.tabGeneral && this.isFormValidFirst) {
        this.stepStatus = StepTypes.tabProduct;
        this.firstStep();
        this.eventsSubject.next();
      } else if (this.stepStatus == StepTypes.tabProduct && this.isFormValidSecond) {
        this.stepStatus = StepTypes.tabLocation;
        this.eventsSubject.next();
        this.secondStep();
      } else {
        this.thirdStep();
        return;
      }
    } else {
      if (this.stepStatus == StepTypes.tabLocation) {
        this.stepStatus = StepTypes.tabProduct;
      } else if (this.stepStatus == StepTypes.tabProduct) {
        this.stepStatus = StepTypes.tabGeneral;
      }
    }
    document.getElementById('#steps-page')?.scrollIntoView({ behavior: 'smooth' });
  }

  onContinue(isNext: boolean, event: MouseEvent): void {
    this.changeStepStatus(isNext);
    this.pushPublishBusinessButtonEvent(event);
  }

  setUserDirectoryId(directoryId: number) {
    const user = this.localStorageService.get('userProfile');
    if (user) {
      const userObj = JSON.parse(user) as UserProfile;
      userObj.directoryId = directoryId;
      this.localStorageService.set('userProfile', JSON.stringify(userObj));
    }
  }

  firstStep() {
    if (this.isNewDirectoryUnpublished) {
      this.updateDirectoryStep1();
    }

    if (this.isNewDirectory) {
      this.btnEnabled = false;
      this.initializeDirectory();
      this.isNewDirectoryUnpublished = true;
      this.isNewDirectory = false;
      this.isUpdateDirectory = false;
    }
  }

  secondStep() {
    if (this.isNewDirectoryUnpublished) {
      this.updateDirectoryStep2();
    }
  }

  thirdStep() {
    if (this.isNewDirectoryUnpublished) {
      this.updateDirectoryStep3();
    }
  }

  initializeDirectory() {
    this.directoryData = {
      ...this.generalInfoComponent?.frmGeneralInfo?.value,
      productDescription: '',
      addres: '',
      homeDelivery: false,
      categories: [],
      paymentMethods: [],
      locationInformation: [],
      contactInformation: this.contactInfoComponent?.frmContactInfo?.value?.filter((x: ContactInformation) => x.link),
    };
    this.directoryService.savePresaved(this.directoryData).subscribe((resp) => {
      this.directoryData.directoryId = resp;
      this.directoryId = this.directoryData.directoryId;
      this.setUserDirectoryId(this.directoryId);
      this.btnEnabled = true;
    });
  }

  updateDirectoryStep1() {
    this.directoryData.directoryId = this.directoryId;
    this.directoryData.businessName = this.generalInfoComponent?.frmGeneralInfo?.get('businessName')?.value;
    this.directoryData.ruc = this.generalInfoComponent?.frmGeneralInfo?.get('ruc')?.value;
    this.directoryData.description = this.generalInfoComponent?.frmGeneralInfo?.get('description')?.value;
    this.directoryData.locationInformation = this.directoryData.locationInformation || [];

    if (this.contactInfoComponent?.frmContactInfo?.value) {
      this.directoryData.contactInformation = this.contactInfoComponent?.frmContactInfo?.value?.filter(
        (x: ContactInformation) => x.link
      );
      this.directoryService.savePresaved(this.directoryData).subscribe();
    }
  }

  updateDirectoryStep2() {
    this.directoryData = {
      directoryId: this.directoryId,
      ...this.directoryData,
      ...this.productInfoComponent?.frmProductInfo?.value,
      contactInformation: this.contactInfoComponent?.frmContactInfo?.value?.filter((x: ContactInformation) => x.link),
      locationInformation: this.directoryData.locationInformation || [],
    };
    this.generalError = null;

    this.imageUploaderComponent.errorMsg = null;
    this.imageUploaderComponent.amountErrorMsg = null;

    this.directoryService.savePresaved(this.directoryData).subscribe();
  }

  updateDirectoryStep3() {
    const formValues = this.formatGeolocation(this.getFormsValues());
    this.directoryData.locationInformation = formValues.locationInformation;
    this.directoryService.savePresaved(this.directoryData).subscribe();
  }

  hasImages(): boolean {
    return this.imageUploaderComponent?.hasImages > 0;
  }

  affiliateAttempt($event: Event): void {
    if (!this.isFormValid) return;

    if (this.isNewDirectoryUnpublished) {
      this.updateDirectoryStep3();
    }

    this.otpModalService.open().subscribe((otpValid: boolean) => {
      this.otpModalService.close();
      if (otpValid) {
        this.pushPublishBusinessButtonEvent($event);
        this.isNewDirectoryUnpublished ? this.affiliate() : this.affiliateForPublished();
      }
    });
  }

  affiliate() {
    this.generalError = null;
    this.imageUploaderComponent.errorMsg = null;
    this.imageUploaderComponent.amountErrorMsg = null;
    this.dataLayerService.pushBusisnesPublishIntent();

    const formValues = this.formatGeolocation(this.getFormsValues());
    this.directoryData.locationInformation = formValues.locationInformation;

    this.logglyService.log('Affiliate - Register Start', { form: formValues });

    this.isSaving = true;
    let savedDirectoryId: number = 0;
    this.directoryService.publishPreSaved(this.directoryId).subscribe((result) => {
      savedDirectoryId = this.directoryId;
      this.logglyService.log('Affiliate - Register End', {
        form: formValues,
      });

      if (!this.directoryData) {
        this.directoryData = new Directory();
      }
      this.directoryData.directoryId = this.directoryId;

      this.pushOptionsEvents(formValues);
      this.dataLayerService.pushBusisnesPublishSuccess(formValues.businessName);

      this.afterRegisterPublish(result, savedDirectoryId);
    });
  }

  affiliateForPublished() {
    this.generalError = null;
    this.imageUploaderComponent.errorMsg = null;
    this.imageUploaderComponent.amountErrorMsg = null;
    this.dataLayerService.pushBusisnesPublishIntent();

    if (!this.hasImages()) {
      this.generalError = 'La carga de una imagen es requerida.';
      return;
    }

    const formValues = this.formatGeolocation(this.getFormsValues());
    this.logglyService.log('Affiliate - Register Start', { form: formValues });

    this.isSaving = true;
    let savedDirectoryId: number = 0;
    this.directoryService
      .save(formValues)
      .pipe(
        tap((directoryId) => {
          savedDirectoryId = directoryId;
          this.logglyService.log('Affiliate - Register End', {
            form: formValues,
          });

          if (!this.directoryData) {
            this.directoryData = new Directory();
          }
          this.directoryData.directoryId = directoryId;

          this.pushOptionsEvents(formValues);
          this.dataLayerService.pushBusisnesPublishSuccess(formValues.businessName);
        })
      )
      .subscribe((result) => {
        if (result) {
          this.afterRegisterPublish(result, savedDirectoryId);
        }
      });
  }

  private afterRegisterPublish(result: any, savedDirectoryId: number) {
    this.localStorageService.bulkRemove(Object.values(SectionNames));

    this.logglyService.log('Affiliate - Upload images End', {
      directoryId: savedDirectoryId,
      result,
    });

    this.router.navigate(['confirmacion'], {
      relativeTo: this.route,
      queryParams: {
        directoryId: savedDirectoryId,
      },
      skipLocationChange: true,
      queryParamsHandling: 'merge',
    });
  }

  private formatGeolocation(formValues: any): any {
    formValues.locationInformation.forEach((location: any) => {
      location.ubigeo =
        '01' + // Peru code
        location.department +
        location.province.substr(2, 2) +
        location.district.substr(4, 2);
      if (location.isPhysicalAddressChecked) {
        delete location.businessName;
      }
    });
    return formValues;
  }

  pushOptionsEvents(formInformation: Directory) {
    // Format categories into key -> value structure
    const categoriesIdNameMapped: any = {};
    this.categories.forEach((value, key) => {
      categoriesIdNameMapped[value] = key;
    });

    // Format paymentMenthods into key -> value structure
    const paymentMethodsIdNameMapped: any = {};
    this.paymentMethods.forEach((value, key) => {
      paymentMethodsIdNameMapped[value] = key;
    });

    // Push Interaction Events
    formInformation.categories?.map((category) => {
      this.dataLayerService.pushBusinessOption(
        formInformation.businessName,
        'Categoría',
        categoriesIdNameMapped[category.value!]
      );
    });

    formInformation.paymentMethods?.map((paymentMethod) => {
      this.dataLayerService.pushBusinessOption(
        formInformation.businessName,
        'Método de pago',
        paymentMethodsIdNameMapped[paymentMethod.value!]
      );
    });

    if (formInformation.pickUpSalePoint) {
      this.dataLayerService.pushBusinessOption(
        formInformation.businessName,
        'Método de entrega',
        'Recojo en punto de venta'
      );
    }

    if (formInformation.homeDelivery) {
      this.dataLayerService.pushBusinessOption(
        formInformation.businessName,
        'Método de entrega',
        'Entrega a domicilio'
      );
    }
  }

  pushPublishBusinessButtonEvent($event: any) {
    const buttonLabel = $event?.target?.innerText.trim();
    this.dataLayerService.pushPublishBusinessButtonEvent(buttonLabel);
  }

  private getErrorType(msg: string) {
    if (msg.includes('dirección')) {
      this.errorMsgType = 'address';
    }
    if (msg.includes('descripción')) {
      this.errorMsgType = 'description';
    }
    if (msg.includes('lista de productos')) {
      this.errorMsgType = 'products';
    }
    if (msg.includes(' nombre de negocio')) {
      this.errorMsgType = 'business';
    }
  }

  private getFormsValues(): Directory {
    return {
      directoryId: this.directoryData?.directoryId,
      ...this.generalInfoComponent?.frmGeneralInfo?.value,
      ...this.productInfoComponent?.frmProductInfo?.value,
      locationInformation: this.locationsComponent?.frmLocations?.value,
      contactInformation: this.contactInfoComponent?.frmContactInfo?.value.reduce(
        (contactInformation: any, contactInfo: any) => {
          if (contactInfo?.link) {
            contactInfo.virtual = contactInfo?.type == '2001' ? this.evaluateLinkEnd(contactInfo?.link) : false;
            contactInformation.push(contactInfo);
          }
          return contactInformation;
        },
        []
      ),
    };
  }

  private evaluateLinkEnd(str: string): boolean {
    if (str) {
      const terms = ['yevo.pe', 'yevo.pe/'];
      const condition1 = str.includes(terms[0], str.length - terms[0].length);
      const condition2 = str.includes(terms[1], str.length - terms[1].length);
      return condition1 || condition2;
    }
    return false;
  }

  private initLookups(): void {
    this.route.data.subscribe((data) => {
      const affiliateResolved: IntranetAffiliateResolved = data[AffiliateResolverKey];

      if (affiliateResolved?.paymentMethods) {
        this.paymentMethods = convertLookupToMap(affiliateResolved.paymentMethods);
      }
      if (affiliateResolved?.categories) {
        this.categories = convertLookupToMap(affiliateResolved.categories);
      }
      if (affiliateResolved?.contactTypes) {
        this.contactTypes = affiliateResolved.contactTypes;
      }
    });
  }

  private fillAffiliateData(): void {
    this.route.data.subscribe((data) => {
      const existingDirectoryResolved: ExistingDirectoryResolved = data[ExistingDirectoryResolverKey];
      this.directoryData = existingDirectoryResolved?.directoryData;
      this.images = existingDirectoryResolved?.images;
      this.directoryId = this.directoryData.directoryId!;
    });
  }

  saveFormStateInLocalStorage(formState: any, sectionName: SectionNames) {
    this.localStorageService.stringifyAndSet(sectionName, formState);
  }

  goBack() {
    if (this.isLocationStep()) {
      this.stepStatus = StepTypes.tabProduct;
    } else if (this.isProductStep()) {
      this.stepStatus = StepTypes.tabGeneral;
    } else {
      this.router.navigate(['/nuevo-intranet', 'dashboard'], { queryParamsHandling: 'merge' });
    }
  }
}

import { Component, OnInit, Input, HostListener} from '@angular/core';
import { REFERRED_PROGRAM_VALUES } from '@shared/constants/communities.constants';
import { ReferredProgram } from '@shared/interfaces/frontend/communities';
import { LocalStorageService } from '@shared/services/local-storage.service';

@Component({
  selector: 'yevo-progress-level',
  templateUrl: './progress-level.component.html',
  styleUrls: ['./progress-level.component.scss']
})
export class ProgressLevelComponent implements OnInit {
  user: any = '';
  @Input() referredPrograma!:ReferredProgram;
  @Input() progressMedal!:string;
  nextLevelPoints!:string;
  sowNextLevelInfo:boolean = false;
  isMobile!:boolean;
  isBronzeActive:boolean=false;
  isSilverActive:boolean=false;
  isGoldActive:boolean=false;
  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    const userProfile = this.localStorageService.get('userProfile');
    if (userProfile) {
      this.user = JSON.parse(userProfile);
    }
    this.sowNextLevelInfo=true;
  
  }

  getNextNevelName(points:number):string {
    if (points >= REFERRED_PROGRAM_VALUES.pointsStartBronze && points < REFERRED_PROGRAM_VALUES.pointsStartSilver) {
      this.nextLevelPoints = REFERRED_PROGRAM_VALUES.nextLevelBronzeInfo;
      this.isBronzeActive=true;
    } else if (points >= REFERRED_PROGRAM_VALUES.pointsStartSilver && points < REFERRED_PROGRAM_VALUES.pointsStartGold) {
      this.nextLevelPoints = REFERRED_PROGRAM_VALUES.nextLevelSilverInfo;
      this.isSilverActive=true;
    } else if (points >= REFERRED_PROGRAM_VALUES.pointsStartGold) {
      this.sowNextLevelInfo=false;
      this.isGoldActive=true;
    }
    return  this.nextLevelPoints;
  }

  calculateAdjustedPercentage(points: number): number {
    if (points < REFERRED_PROGRAM_VALUES.pointsStartSilver) {
      return this.calculatePercentage(points, REFERRED_PROGRAM_VALUES.totalPointsBronze);
     } else if (points < REFERRED_PROGRAM_VALUES.pointsStartGold) {
      return this.calculatePercentage(points-REFERRED_PROGRAM_VALUES.totalPointsBronze, REFERRED_PROGRAM_VALUES.totalPointsSilver);
     } else {
     return this.calculatePercentage(points<REFERRED_PROGRAM_VALUES.limitPoints?points-REFERRED_PROGRAM_VALUES.pointsStartGold:REFERRED_PROGRAM_VALUES.totalPointsGold, REFERRED_PROGRAM_VALUES.totalPointsGold);
     }
  }
  
  calculatePercentage(points: number, adjustedTotal: number): number {
    return Math.max((points / adjustedTotal) * 100, REFERRED_PROGRAM_VALUES.percentageDefault);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkIfMobile();
  }
  checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  changeDynamicMargin(points: number): string {
    let leftMargin = '-26px 0px 0px';
    let additionalMargin: string;
    this.checkIfMobile();
    if (points >= REFERRED_PROGRAM_VALUES.pointsStartBronze && points < REFERRED_PROGRAM_VALUES.pointsStartSilver) {
      additionalMargin = '-23px';
    } else if (points >= REFERRED_PROGRAM_VALUES.pointsStartSilver && points < REFERRED_PROGRAM_VALUES.pointsStartGold) {
      additionalMargin = this.isMobile ? '116px' : '150px';
    } else if (points >= REFERRED_PROGRAM_VALUES.pointsStartGold) {
      additionalMargin = this.isMobile ? '227px' : '310px';
    } else {
      additionalMargin = '0px';
    }
    return `${leftMargin} ${additionalMargin}`;
  }

}
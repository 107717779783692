import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@shared/services/auth.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { LoaderService } from '@shared/services/loader.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { RecommendService } from '@shared/services/recommend.service';
import { UserService } from '@shared/services/user.service';

import { progressSpiralCardType } from '@shared/constants/progress-spiral.constants';
import { UserLevel } from '@shared/interfaces/frontend/communities';
import { UserProfile } from '@shared/models/UserProfileModel';
import { BANNER_VALUES, URL_RECOMMENDED } from '@shared/constants/dashboard.constants';
import { REFERRED_PROGRAM_VALUES } from '@shared/constants/communities.constants';
import mblBenefit from '@shared/data/dashboard/mbl-benefit.json';
import { images as IMAGES_DASHBOARD, mainbannerimages as imgbanners } from '@shared/data/dashboard/benefit.json';
import { ModalWelcomeRecommendComponent } from '../../components/communities/modal-welcome-recommend/modal-welcome-recommend.component';
import recomendedJson  from '@shared/data/home/recomended.json';
import { CardLaunchesItem } from '@shared/interfaces/IDropdownItem';

@Component({
  selector: 'yevo-dashboardv3',
  templateUrl: './dashboardv3.component.html',
  styleUrls: ['./dashboardv3.component.scss'],
})
export class Dashboardv3Component implements OnInit {
  isLoading = true;
  isLoadCalendar = false;
  recomendedJson!: CardLaunchesItem[];
  worker: boolean = false;

  bannerCampaignSize = 'lg';
  subscription: Subscription = new Subscription();
  userLevel: UserLevel = {
    idLevel: 0,
    userId: 0,
    flagStatus: 'N',
  };
  userclient!: UserProfile;
  client: boolean = true;
  isModalOpen: boolean = false;
  userId!: number;
  typeCreditHealth: progressSpiralCardType = 'neutral';
  labelCreditHealth: string = '';
  showWebinarBanner: boolean = false;
  webinarBannerData: any;
  allCourses: any = [];
  banners: any = IMAGES_DASHBOARD;
  imagesnew: any = imgbanners;
  titleBenefits = 'Descubre y prueba nuestras nuevas funcionalidades ';
  descriptionBenefits = '';
  icons!: any;
  constructor(
    public authService: AuthService,
    private loaderService: LoaderService,
    private modalService: NgbModal,
    private dataLayerService: DataLayerService,
    private recommendServices: RecommendService,
    private userService: UserService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.loaderService.isLoading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    sessionStorage.removeItem('capacitacionesBreadcrumbs');
    sessionStorage.removeItem('isBusinessDiagnosisBreadcrumbs');
  }

  ngOnInit(): void {
    this.icons = mblBenefit.benefit;
    this.recomendedJson = recomendedJson;
    this.modalService.dismissAll();
    localStorage.setItem('activePageUrl', '/nuevo-intranet/dashboard');
    this.getScreenSize();
    const userProfile = this.localStorageService.get('userProfile');
    if (userProfile) {
      this.userclient = JSON.parse(userProfile) as UserProfile;
      this.userId = this.userclient.userId;
      this.worker = this.userclient.worker;
    }
  }

  loadCalendar(event: boolean): void {
    if (event && !this.isLoadCalendar) {
      this.isLoadCalendar = event;
    }
  }

  selectedBanner(item: any) {
    this.dataLayerService.selectedCardBannerDashboard3(item.title);
  }

  selectButtonBanner(code: string, title: string) {
    this.dataLayerService.selectedButtonAllDashboard3(code, title);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    this.bannerCampaignSize = window.innerWidth <= 768 ? 'xs' : 'lg';
  }
  verifyReferredUser(client:number, worker:boolean): void {
    this.userService.dataLogin$.pipe(take(1)).subscribe((response) => {
      if (response === REFERRED_PROGRAM_VALUES.loginCorrectly && !worker && client) {
        this.userService.cleanDataLogin();
        this.recommendServices.getRecommendProgramByUser(this.userId).subscribe((response) => {
          if (
            response.stateReferred != null &&
            response.modalView == 0 &&
            response.stateReferred === REFERRED_PROGRAM_VALUES.stateReferredDefault &&
            !this.isModalOpen
          ) {
            setTimeout(() => {
              this.openModalRecommend(response.userReferred, response.modalView);
              this.isModalOpen = true;
            }, 1200);
          }
          this.dataEventInit(response.userReferred);
        });
      }
    });
  }

  openModalRecommend(userReferred: number, modalView: number) {
    const modalRef = this.modalService.open(ModalWelcomeRecommendComponent, {
      windowClass: 'modal-welcome-recommend',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.userId = this.userId;
    modalRef.componentInstance.userReferred = userReferred;
    modalRef.componentInstance.modalView = modalView;
  }

  dataEventInit(userId: number): void {
    if (userId > 0) {
      this.recommendServices.getRecommendProgramByUserId(userId).subscribe((response) => {
        this.dataLayerService.pushEnlacedReferred(response.referredCode);
      });
    }
  }

  scrollTo(elementId: string) {
    document.querySelector(`#${elementId}`)?.scrollIntoView({ behavior: 'smooth' });
  }

  goToLink() {
    this.router.navigate([BANNER_VALUES.urlCalculateSeed]);
  }

  validRecommended(client: boolean, worker: boolean) {
    const isActive = client && !worker;
    const url = isActive ? URL_RECOMMENDED.url : '';
    
    this.client = isActive;
    
    const iconProperties = {
      url: url,
      isActived: isActive,
      client: isActive
    };
  
    const recommendedProperties = {
      url: url,
      isActiveButton: isActive
    };
  
    Object.assign(this.icons[2], iconProperties);
    Object.assign(this.recomendedJson[0], recommendedProperties);
  }
}

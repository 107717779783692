<div class="d-flex justify-content-start align-items-center">
  <img class="mr-2" src="assets/icons/breadcrumb-arrow.svg" alt="breadcrumb" />
  <div
    *ngFor="let item of breadcrumbs; index as i"
    class="d-flex align-items-center justify-content-center breadcrumb-gap-2"
  >
    <a
      *ngIf="item.url !== null && goback === false"
      class="breadcrumb"
      [routerLink]="item.url"
      queryParamsHandling="merge"
      [ngClass]="{ 'breadcrumb-active': item.active }"
      (click)="removeMenu(i)"
    >
      {{ item.pageName }}
    </a>

    <a
      *ngIf="goback === true && i == 0"
      class="breadcrumb cursor"
      [ngClass]="{ 'breadcrumb-active': item.active }"
      (click)="goBack(); removeMenu(i)"
    >
      {{ item.pageName }}
    </a>

    <a
      *ngIf="!item.url && goback === true && i !== 0"
      class="breadcrumb"
      href="javascript:void(0)"
      [ngClass]="{ 'breadcrumb-active': item.active }"
      (click)="removeMenu(i)"
    >
      {{ item.pageName }}
    </a>

    <a
      *ngIf="!item.url && goback === false"
      class="breadcrumb"
      href="javascript:void(0)"
      [ngClass]="{ 'breadcrumb-active': item.active }"
      (click)="removeMenu(i)"
    >
      {{ item.pageName }}
    </a>
    <span *ngIf="i !== breadcrumbs.length - 1" class="mr-2">/</span>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'yevo-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  @Input() title = 'Regresar';
  @Input() routerLink!: string[];
  @Output() backClick = new EventEmitter();
}

import { Component, Input } from '@angular/core';
import { IProfileSocialMediaLink } from '@shared/interfaces';

@Component({
  selector: 'yevo-intranet-social-icons',
  templateUrl: './intranet-social-icons.component.html',
  styleUrls: ['./intranet-social-icons.component.scss'],
})
export class IntranetSocialIconsComponent {
  @Input() profileSocialMediaLinks!: IProfileSocialMediaLink[];

  constructor() {
    //DO NOTHING
  }
}

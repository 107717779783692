<div class="card border-0">
  <div [ngClass]="{ 'dark-overlay': this.training.viewed }">
    <img [src]="training.img | image : imgDefault" class="img-fluid card-img-top" alt="card" />
    <div class="viewed_indicator" *ngIf="this.training.viewed">
      <div class="check_icon_container">
        <img src="assets/icons/communities/multimedia_viewed.svg" alt="check_icon" />
      </div>
      <div class="green_loader"></div>
    </div>
  </div>
  <div class="card-body w-100 p-0">
    <h3>{{ training.title }}</h3>
    <a class="button-webinar" (click)="openDetailWebinar(training)">Ver webinar</a>
  </div>
</div>

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { allies } from '@shared/data/financing/allies-data.json';
import { IAllyInfo } from '@shared/interfaces';
import { AuthService } from '@shared/services/auth.service';

@Component({
  selector: 'yevo-financing-ally-landing',
  templateUrl: './financing-ally-landing.component.html',
  styleUrls: ['./financing-ally-landing.component.scss'],
})
export class FinancingAllyLandingComponent implements OnInit, OnChanges {
  @Input() identifier!: string;
  @Input() isAmountFormVisible!: boolean;
  @Input() ally: string = '';
  @Input() formYapeShow: boolean = false;

  allies: IAllyInfo[] = allies;
  selectedAlly!: IAllyInfo;
  breadcrumbs = [
    { pageName: 'Inicio', url: ['/'], active: true },
    { pageName: 'Financiamiento', url: null, active: false },
  ];

  isLogged: boolean = false;

  constructor(private authService: AuthService) {
    /* TODO document why this constructor is empty */
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLogged = true;
        this.breadcrumbs = [
          { pageName: 'Inicio', url: ['/nuevo-intranet', 'dashboard'], active: true },
          { pageName: 'Financiamiento', url: null, active: false },
        ];
      }
    });
  }

  ngOnInit(): void {
    this.showAllyBanner(this.identifier);
  }

  ngOnChanges(changes: any): void {
    if (changes.identifier && !changes.identifier.firstChange) {
      this.showAllyBanner(this.identifier);
    }
    if (this.ally === 'yape' && this.formYapeShow) {
      const url = this.isLogged ? ['/financiamiento', 'filtro-financiamiento'] : ['/financiamiento', 'solicitud'];
      this.breadcrumbs = [
        { pageName: 'Financiamiento', url: url, active: true },
        { pageName: 'Financiamiento Mibanco', url: null, active: false },
      ];
    }
  }

  showAllyBanner(ally: string) {
    this.selectedAlly = this.allies.find((a) => a.id === ally)!;
  }
}

<div
  [ngClass]="{
    'div__training-container__color': showTitleCategories,
    'div__training-container__color__educational': !showTitleCategories
  }"
>
  <div class="training-container">
    <div class="header" *ngIf="showTitleCategories">
      <h4>¡No te pierdas nuestras capacitaciones!</h4>
      <p>Despeja tus dudas con nuestros expertos y miembros de la comunidad</p>
    </div>
    <div class="row row-title-category" *ngIf="showTitleCategories">
      <p class="title-category">Categorías</p>
    </div>
    <div class="mobile">
      <ngx-slick-carousel #slickCarouselTraining [config]="slickConfigTraining" style="margin-bottom: 20px">
        <div ngxSlickItem *ngFor="let category of categories; let i = index" class="slide">
          <button
            type="button"
            class="btn"
            [ngClass]="{
              'button-category-all': category.name === 'Ver todo' && showTitleCategories,
              active: isPressed[i],
              'button-category': showTitleCategories,
              'button-category_educational': !showTitleCategories,
              'button-category-all_educational': category.name === 'Ver todo' && !showTitleCategories
            }"
            (click)="toggleButton(i)"
          >
            {{ category.name }}
          </button>
        </div>
      </ngx-slick-carousel>
    </div>
    <div class="desktop">
      <div class="m-md-1 m-lg-1" *ngFor="let category of categories; let i = index">
        <button
          type="button"
          class="btn"
          [ngClass]="{
            'button-category-all': category.name === 'Ver todo' && showTitleCategories,
            active: isPressed[i],
            'button-category': showTitleCategories,
            'button-category_educational': !showTitleCategories,
            'button-category-all_educational': category.name === 'Ver todo' && !showTitleCategories
          }"
          (click)="toggleButton(i)"
        >
          {{ category.name }}
        </button>
      </div>
    </div>
    <div class="swiper-section">
      <swiper #swiperTraining [config]="config" style="margin: 0 10px">
        <ng-container *ngFor="let item of trainingsShow; let i = index">
          <ng-template swiperSlide>
            <yc-training-card
              class="card-webinars-swiper"
              [nameSectionInternal]="this.nameSectionInternal"
              [training]="item"
              [source]="source"
              [sourceClick]="sourceHome"
            ></yc-training-card>
          </ng-template>
        </ng-container>
      </swiper>
      <div class="swiper-button-training-prev">
        <img src="assets/icons/communities/arrow-left.svg" alt="arrow" />
      </div>
      <div class="swiper-button-training-next">
        <img src="assets/icons/communities/arrow-right.svg" alt="arrow" />
      </div>
    </div>
    <div class="row m-auto justify-content-center">
      <a class="btn btn-see-more-webinar" (click)="goToSeeMoreWebinars(); seeMore()">Ver más webinars</a>
    </div>
  </div>
</div>

import { DirectoryService } from 'src/app/shared/services/directory.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin } from 'rxjs';
import { IntranetAffiliateResolved } from 'src/app/intranet/models/affiliate';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';

export const AffiliateResolverKey = 'AffiliateResolved';

@Injectable({
  providedIn: 'root',
})
export class AffiliateResolver implements Resolve<IntranetAffiliateResolved | null> {
  constructor(private directoryService: DirectoryService, private authService: AuthService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return forkJoin([
      this.directoryService.getCategories(),
      this.directoryService.getContactTypes(),
      this.directoryService.getPaymentMethods(),
    ]).pipe(map(([categories, contactTypes, paymentMethods]) => ({ paymentMethods, categories, contactTypes })));
  }
}

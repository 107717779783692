import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  private readonly directoryBase = `${environment.apiBaseUrl}/api-directory`;
  //api-home
  private readonly contentManagerApi = `${environment.apiBaseUrl}`;

  private isActivePWA = new BehaviorSubject<boolean>(false);
  activePWA = this.isActivePWA.asObservable();

  constructor(private httpClient: HttpClient) {}

  registerPWA(user: any): Observable<any> {
    return this.httpClient.post(`${this.contentManagerApi}/traceEvent`, user);
  }

  changePWA(value: boolean) {
    this.isActivePWA.next(value);
  }
}

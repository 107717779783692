import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownItem, ISelectItem } from '@shared/interfaces/IDropdownItem';

@Component({
  selector: 'yevo-filter-pills-v2',
  templateUrl: './filter-pills-v2.component.html',
  styleUrls: ['./filter-pills-v2.component.scss'],
})
export class FilterPillsV2Component implements OnInit {
  @Input() itemList!: any[];
  @Input() itemListKeyName = 'id';
  // itemIdSelected uses itemListKeyName to make
  @Input() itemIdSelected = '';
  @Input() itemListValueName = 'value';
  @Input() moreButtonText: any = undefined;
  @Input() showSeeMore: boolean = true;
  @Input() items!: IDropdownItem[];
  @Input() itemSelected!: IDropdownItem;
  @Input() withHandleOption: boolean = true;
  @Input() showSecondTheme: boolean = false;
  // Possible values for dropDownPlacement are "top", "top-left", "top-right",
  // "bottom", "bottom-left", "bottom-right", "left", "left-top", "left-bottom",
  // "right", "right-top", "right-bottom"
  @Input() dropdownPlacement: any[] = ['bottom-left', 'bottom-right', 'top-left', 'top-right'];
  @Input() dropdownTitle?: string;
  @Input() numberPillsShowed = 4;
  @Input() showJustifyContentBetween = true;
  @Input() showJustifyWrap = false;
  @Input() showCustomTitle = false;
  @Input() showSeedCashTitle = false;
  @Input() showInactiveTheme = false;
  @Input() showSliderPills = false;
  @Output() selectItem = new EventEmitter<ISelectItem>();

  @ViewChild(NgbDropdown) private dropdown!: NgbDropdown;

  @ViewChild('container') containerRef: ElementRef = new ElementRef(null);

  isLeftArrowHidden: boolean = true;
  isRightArrowHidden: boolean = false;

  // Components Variables
  showedItems: IDropdownItem[] = [];
  hiddenItems: IDropdownItem[] = [];

  ngOnInit(): void {
    this.setShowedHiddenItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const items: any[] = changes?.items?.currentValue;
    if (items?.length) {
      this.setShowedHiddenItems();
    }
    if (changes?.itemSelected?.currentValue) {
      this.itemSelected = changes?.itemSelected?.currentValue;
    }
    if (changes?.numberPillsShowed?.currentValue) {
      this.setShowedHiddenItems();
    }
  }

  setShowedHiddenItems(): void {
    const items = this.items;
    if (items) {
      this.showedItems = items.slice(0, this.numberPillsShowed);
      this.hiddenItems = items.slice(this.numberPillsShowed, items.length);
    }
  }

  handleSelectItem(event: any, item: IDropdownItem): void {
    if (this.showInactiveTheme && !item?.enabled) return;

    if (this.itemSelected?.value == item?.value) {
      // Remove active item if is the same pill clicked
      if (this.withHandleOption) {
        this.itemSelected = null!;
        event = null;
        item = null!;
      }
    } else {
      this.itemSelected = item;
    }
    this.selectItem.emit({
      event,
      item,
    });
    this.closeDropdownMenu();
  }

  closeDropdownMenu(): void {
    this.dropdown?.close();
  }

  getDropdownTitle(title: string): string {
    if (title == 'Independiente') return 'Tengo un negocio';
    if (title == 'Dependiente') return this.showSeedCashTitle ? 'Trabajo para una empresa' : 'Soy emplead@';
    return title;
  }

  ngAfterContentInit(): void {
    this.checkArrowsVisibility();
  }

  ngAfterViewInit(): void {
    if (this.containerRef) {
      this.containerRef.nativeElement.addEventListener('scroll', this.handleScroll.bind(this));
    }
  }

  handleScroll(): void {
    this.checkArrowsVisibility();
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.checkArrowsVisibility();
  }

  scrollLeft(): void {
    this.containerRef.nativeElement.scrollBy({ left: -120, behavior: 'smooth' });
    this.checkArrowsVisibility();
  }

  scrollRight(): void {
    this.containerRef.nativeElement.scrollBy({ left: 120, behavior: 'smooth' });
    this.checkArrowsVisibility();
  }

  private checkArrowsVisibility(): void {
    const container = this.containerRef.nativeElement;
    this.isLeftArrowHidden = container.scrollLeft <= 0;
    this.isRightArrowHidden = container.scrollLeft + container.clientWidth >= container.scrollWidth;
  }
}

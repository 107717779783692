export enum COMMUNITIES_CODE {
  COMMUNITY_WOMEN = 'COMMUNITY_WOMEN',
  COMMUNITY_BODEGUEROS = 'COMMUNITY_BODEGUEROS',
}

export const CONST_COMMUNITIES_CODE = {
  COMMUNITY_WOMEN_ID: 1,
  COMMUNITY_BODEGUEROS_ID: 2,
  COMMUNITY_WOMEN_MAX_LEVEL: 5,
  COMMUNITY_BODEGUEROS_MAX_LEVEL: 5,
};

export const USER_PROFILE = {
  ADMIN_CONTENT_COMMUNITY: 3,
};

export const REGULAR_PHRASE = {
  description: /\?descripcion=.*?(&|$)/,
  firstValue: '$1',
  numericTwoDigit:/^\d+(\.\d{1,2})?$/,
}

export const creditOption = {
  titleWithRedirection: '¿Quieres un crédito?',
  descriptionWithRedirection: 'Adquiérelo rápidamente',
  iconWithRedirection: '/assets/icons/communities/credit.svg',
  urlWithRedirection: 'financiamiento/filtro-financiamiento',
  titleInformative: 'Asesorías personalizadas',
  descriptionInformative: 'Solicítalo directamente por el Grupo',
  iconInformative: '/assets/icons/communities/consulting.svg',
};
export const savingsAccountOption = {
  titleWithRedirection: '¿Quieres una Cta de ahorro?',
  descriptionWithRedirection: 'Descubre todas las cuentas para ti',
  iconWithRedirection: '/assets/icons/communities/cta-ahorro.svg',
  urlWithRedirection: 'cuenta-ahorros/landing',
  titleInformative: 'Transmisiones en vivo',
  descriptionInformative: 'Conéctate mediante los enlaces directos',
  iconInformative: '/assets/icons/communities/transmissions.svg',
};
export const trainYouOption = {
  titleWithRedirection: '¿Quieres capacitarte?',
  descriptionWithRedirection: 'Aprende nuevos temas gratis!',
  iconWithRedirection: '/assets/icons/communities/train-you.svg',
  urlWithRedirection: 'nuevo-intranet/comunidades/emprende-mujer/webinar',
  titleInformative: 'Próximos eventos',
  descriptionInformative: 'Entérate primero de los eventos',
  iconInformative: '/assets/icons/communities/events.svg',
};

export const WHATSAPP_COMMUNITY = {
  otherOptionsTitle: 'Tenemos otras opciones',
  whyJoinTitle: '¿Por qué unirme al Grupo de WhatsApp?',
  womanCode: 18,
  winemakersCode: 14,
  firstVersionName: 'whatsappA',
  secondVersionName: 'whatsappB',
  titleButton: 'Chatea por WhatsApp',
  utmSourceWoman: 'emprendemujer',
  utmSourceWinemakers: 'bodegueros',
  utmMedium: 'botonflotante',
  utmCampaign: 'whatsapp',
  whatsappWomanTitle: 'Únete al grupo de WhatsApp de la Comunidad Emprende Mujer',
  whatsappWinemakersTitle: 'Únete al grupo de WhatsApp de la Comunidad Bodegueros',
};

export const BLOG_CROSS_SELLING = {
  urlFinance: 'financiamiento/filtro-financiamiento',
  utmSourceWoman: 'emprendemujer',
  utmSourceWinemakers: 'bodegueros',
  utmMedium: 'articulo',
  utmCampaign: 'banner',
};

export const VIDEOS_VALUES = {
  sourceDefaul: 'Elección por campaña',
  sourceUserSelected: 'Elección por usuario',
};

export const PERSONALIZER_VALUES = {
  rewardPositiveCode: 1,
  rewardNegativeCode: -1,
  recommendedByAzure: 'Azure personalizer',
};

export const MAILCHIMP_COMMUNITY_VALUES = {
  action: 'REGISTER',
};

export const REFERRED_PROGRAM_VALUES = {
  pathReferredProgram: 'programa-referidos',
  routeWoman: 'nuevo-intranet/comunidades/emprende-mujer',
  routeWinemakers: 'nuevo-intranet/comunidades/bodegueros',
  woman: 'emprende-mujer',
  womanImageRoute: '/assets/img/communities/referred_women.png',
  womanImageDesktopRouteSection: '/assets/img/communities/mask_group.svg',
  womanImageMobileRouteSection: '/assets/img/communities/woman-money.svg',
  newRegisterRoute: '/registro/paso-1?recommend_code=',
  userReferredCodeTemp: 'aB3cD2eF1gH7IjK4LmN8O9P2Q5R6S3T1U0V',
  urlShareMessenger: 'fb-messenger://share/?link=',
  urlShareWhatsApp: 'https://wa.me/?text=',
  urlShareFacebook: 'https://www.facebook.com/sharer.php?u=',
  urlShareSms: 'sms:?body=',
  nextLevelBronzeInfo: 'Para subir de nivel: 30 puntos',
  nextLevelSilverInfo: 'Para subir de nivel: 100 puntos',
  totalPointsBronze: 30,
  totalPointsSilver: 70,
  totalPointsGold: 100,
  pointsStartBronze: 0,
  pointsStartSilver: 30,
  pointsStartGold: 100,
  percentageDefault: 5,
  limitPoints: 200,
  currentLevel: 'Tu nivel actual',
  unlockedthisLevel: 'Desbloqueaste este nivel',
  cardDesktopLimit: 3,
  loginKey: 'login',
  loginCorrectly: 'correctly',
  completedApplication: 'completed_application',
  newReferredKey: 'new-referred',
  newReferredCorrectly: 'referred-correctly',
  stateReferredActive: 1,
  stateReferredDefault: 0,
  sizeBarLoadingDefault: 4,
  pointScrollYInit: 60,
  pointScrollYEnd: 1400,
};
export const SOCIAL_NETWORKS_VALUES = {
  messenger: 'Messenger ',
  whatsApp: 'Whatsapp',
  facebook: 'Facebook',
  sms: 'Mensajes',
};
export const MEDALS_VALUES = {
  bronze: 'Bronce',
  silver: 'Plata',
  gold: 'Oro',
};
export const MEDAL_INDEX_MAP: { [key: string]: number } = {
  Bronce: 0,
  Plata: 1,
  Oro: 2,
};
export const PARAMETERS_URL_VALUES = {
  utmSourceWoman: 'emprendemujer',
  utmSourceWinemakers: 'bodegueros',
  utmMediumBanner: 'banner',
  utmCampaignReferred: 'referidos',
  urlWhatsAppWoman: 'https://chat.whatsapp.com/JfnJHAEZE9cFfQ8NqSfA2J',
  urlWhatsAppWinemakers: 'https://chat.whatsapp.com/DZdxOVwC39A6oKjQe3FYWG',
};
export const REFERRED_EVENT_VALUES = {
  activate: 'ACTIVATE',
  registerCommunities: 'REGISTER_COMMUNITIES',
  savingsAccountApplication: 'SAVINGS_ACCOUNT_APPLICATION',
  creditApplication: 'CREDIT_APPLICATION',
};
export const TYPE_SCORE_VALUES = {
  newRegister: 1,
  applyFinancing: 2,
  approvedFinancing: 3,
};
export const MEDAL_MAP: { [key: number]: string } = {
  1: 'Bronce',
  2: 'Plata',
  3: 'Oro',
};
export const VIRTUAL_EVENT_VALUES = {
  selectSectionReferredProgram: 'Seleccionar: Conoce más',
  selectSectionWomanCode: 19,
  selectSectionWinemakersCode: 20,
  selectShareLinkReferredProgramPage: 'Seleccionar: Compartir enlace',
  selectShareLinkWomanCode: 20,
  selectShareLinkWinemakersCode: 21,
  shareLink: 'Compartir: ',
  copyLink: 'Copiar enlace',
  askReferredProgram: 'Pregunta: ',
  tabReferred: 'Filtrar: ',
}
export const TOOLS_VALUES  = {
  actionGetInto: 'Ingresar',
}

export const UTILITY_CALCULATION_VALUES = {
  redirectToInit:'utility-calculation',
  redirectToStepOne:'tools-utility-step1',
  redirectToSteptwo:'tools-utility-step2',
  redirectToStepthree:'tools-utility-step3',
  redirectToStepFour:'tools-utility-step4',
  redirectToStepFive:'tools-utility-results',
  stepOneCode:0,
  stepTwoCode:1,
  stepThreeCode:2,
  stepFourCode:3,
  stepFiveCode:4,
  sizeBarLoadingDefault:4,
  placeholderTextOptional:'Ingresa un monto (opcional)',
  selectMonth:'Selecciona un mes',
  labelMonth: 'Compras del mes*',
  labelDay:'Compras del día*',
  labelDayCode:1,
}

export const MONTH_MAP: { [key: number]: string } = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre',
};
export const DONUT_CHART_VALUES = {
  conicGradientInit:'conic-gradient(',
  conicGradientEnd:')',
  eat:',',
  color009FF9:'#009FF9',
  color4B50E6:'#4B50E6',
  color009530:'#009530',
  color1CB4A7:'#1CB4A7',
  colorFFFFFF:'#FFFFFF',
  revenue:'Ganancia',
  shopping:'Compras',
  businessExpenses:'Gastos del negocio',
  personalExpenses:'Gastos personales',
  percentageValueOne:50,
  percentageValueTwo:78,
  percentageValueThree:85,
}
export const NOTIFICATION_VALUES = {
  topicUser: 'members',
}
<div class="progress-level-content ">
    <div class="progress-level-content-first-section">
        <div class="progress-bar-content">
            <div>
                <p class="name-user">{{this.user.name}}, este es tu progreso</p>
                <p class="name-level">Nivel <span class="medal" [ngClass]="{
                                    'medal-color-bronze': this.progressMedal === 'Bronce',
                                    'medal-color-silver': this.progressMedal === 'Plata',
                                    'medal-color-gold': this.progressMedal === 'Oro'
                                  }">{{this.progressMedal}}</span></p>
                </div>
            <div class="progress-bar">
                <div class="progress" [style.width.%]="calculateAdjustedPercentage(this.referredPrograma.totalPoints)" [ngClass]="{
                    'bg-bronze': this.progressMedal === 'Bronce',
                    'bg-silver': this.progressMedal === 'Plata',
                    'bg-gold': this.progressMedal === 'Oro'
                  }"></div>
            </div>

            <div class="custom-list">
                <div class="point" [ngClass]="{
                    'c-bronze': this.progressMedal === 'Bronce',
                    'c-silver': this.progressMedal === 'Plata',
                    'c-gold': this.progressMedal === 'Oro'
                  }">
                    <span class="info">Actual: {{this.referredPrograma.totalPoints}} puntos</span>
                </div>
                <ng-container *ngIf="this.sowNextLevelInfo">
                    <div class="point">
                        <span class="info">{{getNextNevelName(this.referredPrograma.totalPoints)}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="progress-level-content-second-section">
        <div class="progress-line-content">
            <div class="container_text">
                <div class="container_text_title">Mi trayectoria</div>
                <div class="container_text_description">Sigue desbloqueando niveles para acceder a mejores beneficios.
                </div>
            </div>
            <div class="container_body">
                <p class="container_body_title" [style.margin]="changeDynamicMargin(this.referredPrograma.totalPoints)">Tu nivel</p>
                <div class="timeline">
                    <div class="timeline_event">
                        <div class="level-badge  level-bronze" [ngClass]="{'level-large': isBronzeActive}"></div>
                        <span class="text" [ngClass]="{'active-text': isBronzeActive}">Bronce</span>
                    </div>
                    <div class="timeline_event">
                        <div class="level-badge level-silver" [ngClass]="{'level-large': isSilverActive}"></div>
                        <span class="text" [ngClass]="{'active-text': isSilverActive}">Plata</span>
                    </div>
                    <div class="timeline_event">
                        <div class="level-badge level-gold" [ngClass]="{'level-large': isGoldActive}"></div>
                        <span class="text" [ngClass]="{'active-text': isGoldActive}">Oro</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card card-allied-card mb-3">
  <img [src]="allied.imgSrc" class="card-img-top card-allied-img" alt="image" (click)="registerOnClick()" />
  <span [class]="style">{{ allied.textLabel }}</span>
  <div class="card-body d-flex card-allied-container-description">
    <img [src]="allied.iconSrc" alt="image" class="card-allied-icon mr-2" />
    <div class="position-relative">
      <h5 class="card-title card-allied-title" (click)="registerOnClick()">{{ allied.title }}</h5>
      <p class="card-text card-allied-description mt-2" (click)="registerOnClick()">
        {{ allied.description }}
      </p>
      <a (click)="link()" class="btn btn-secundary card-allied-button mt-2">{{ allied.buttonLabel }}</a>
    </div>
  </div>
</div>

<div class="new-banner">
    <div class="info">
        

        <h3 class='communities-title'>{{this.origin === 'bodeguero' ? 'Bienvenido a la Comunidad' : 'Bienvenida a la Comunidad'}}</h3>
        <div class='communities-box'>
          <img class='img-fluid spark-desktop' [src]='sparkLeftDesktop' alt='spark-left' />
          <img class='img-fluid spark-mobile' [src]='sparkLeftMobile' alt='spark-left' />
          <div class='communities-paragraph'><p class='text-center title-center'>{{title}}</p></div>
          <img class='img-fluid spark-desktop' [src]='sparkRightDesktop' alt='spark-right' />
          <img class='img-fluid spark-mobile' [src]='sparkRightMobile' alt='spark-right' />
        </div>
        <p class='communities-paragraph-2'>{{description}}</p>
        <div class="container-button">
            <button type='button' class='btn btn-link button-wwp button-whatsapp-desktop'
                    (click)='openLink(this.urlWhatsapp)'>
                <img src='/assets/icons/communities/whatsapp.svg' alt='' />
                <span>Únete a la comunidad de WhatsApp</span>
            </button>
        </div>


    </div>
    <div class="image-desktop">
        <img class='chica-image-desktop' [src]='imageDesktop' alt='women' [style]="origin === 'bodeguero' ? 'width: 495px; height: auto;' : '' "/>
    </div>
    <div class="image-mobile">
        <div class="image">
            <img class='chica-image-mobile' [src]='imageMobile' alt='women' [style]="origin === 'bodeguero' ? 'width: 245px; height: auto;' : '' "/>
        </div>
        <div class="button">
        <button type='button' class='btn btn-link button-wwp button-whatsapp-mobile'
            (click)='openLink(this.urlWhatsapp)'>
            <img src='/assets/icons/communities/whatsapp.svg' alt='' />
            <span>Únete a la comunidad de WhatsApp</span>
        </button>
        </div>
    </div>
</div>
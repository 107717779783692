export const socialNetworkRequired = ["whatsapp","facebook","instagram","tiktok","twitter","webpage"]

export const personalInfoRequired = ["name", "fatherLastName", "motherLastName",  "bornDate"];
export const personalInfoReadOnly = ["documentNumber", "email", "phone"]
export const personalInfoDepend = ["spouseDocumentNumber", "spouseName", "spouseLastName"]

export const myHomeRequired = ['address'];

export const myBusinessRequired = ["businessName", "businessRuc", "businessPhoneCel", "businessStartDate", "businessMaxMonthlyLoanPayment", "ba"]

export const myWorkInfoRequired = ["employmentOccupation", "employmentStartDate", "monthlyIncome", "monthlyExpenses", "employerBusinessName", "employerBusinessRuc"]
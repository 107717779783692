<div class="d-flex flex-column vh-100">
  <header id="#yevoHeader">
    <yevo-header class="fixed-header" [links]="links" [fluid]="true" (mobileMenuIsExpanded)="mobileMenuIsExpanded($event)"></yevo-header>
  </header>
  <div class="content-temp"></div>
  <main class="flex-grow-1" [hidden]="isMenuMobileExpande">
    <oigo-loading></oigo-loading>
    <router-outlet></router-outlet>
  </main>

  <footer [ngClass]="{ 'd-none': isMenuMobileExpande, 'd-block': !isMenuMobileExpande }">
    <yevo-footer></yevo-footer>
  </footer>
</div>

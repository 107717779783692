<div class="background-container">
  <div class="background" (click)="goToWebinar()">
    <div class="webinar-info" [ngClass]="{ 'if-description': webinar.description.length > 0}">
      <div>
        <p class="webinar-title">{{ truncateStringWithEllipsis(webinar.title) }}</p>
        <p class="webinar-subtitle">{{ truncateStringWithEllipsis(webinar.description, 'description') }}</p>
      </div>
      <button (click)="goToWebinar()">Quiero verlo</button>
    </div>
  </div>
</div>
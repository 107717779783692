<form [formGroup]="formaDate" action="">
  <label *ngIf="showLabel" class="control-label">{{ label }}<span *ngIf="obligatory">*</span></label>
  <div class="input-group">
    <input
      (click)="d.toggle()"
      class="form-control custom-datepicker"
      [placeholder]="placeholder"
      name="date"
      formControlName="date"
      ngbDatepicker
      #d="ngbDatepicker"
      [readOnly]="true"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [startDate]="startDate"
      [disabled]="isDisabled"
      [ngClass]="{ error: showManualError }"
    />
    <span role="button" class="custom-icon" (click)="!isDisabled && d.toggle()">
      <img class="images__clouds" [src]="'/assets/icons/seed-cash/' + icon + '.svg'" alt="calendar-icon" />
    </span>
  </div>
  <label class="form-inline">
    <div *ngIf="showError('date') || showManualError" class="text-error">
      <div *ngIf="getErrorsFromField('date')?.required">{{ label }} es requerido.</div>
    </div>
  </label>
</form>

<div
  class="step__card mb-2"
  [ngClass]="{ 'no-image': numberOfActiveStep > 1 && !hasSameStyle, 'py-4': !showSteps, seedcash: isSeedCash }"
>
  <span *ngIf="showSteps" class="step__card-title mb-2 mt-0"
    >Paso {{ numberOfActiveStep }} de {{ totalSteps }} <img [src]="steps[numberOfActiveStep - 1].icon" alt="icon-image"
  /></span>
  <span
    class="step__card-description"
    [innerHTML]="currentTitle ? currentTitle : steps[numberOfActiveStep - 1].title"
  ></span>
</div>

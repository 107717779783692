import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CommentModel } from '@shared/models/Video.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  private readonly contentManager = `${environment.apiBaseUrl}/api-directory/contentManager`;

  constructor(private httpClient: HttpClient) {}

  getAllVideoComment(id: string): Observable<any> {
    return this.httpClient.get(`${this.contentManager}/comment/${id}`);
  }

  setVideoComment(commentRequest: CommentModel): Observable<any> {
    return this.httpClient.post(`${this.contentManager}/comment`, commentRequest, { observe: 'response' });
  }
}

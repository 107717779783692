<div class="certified_section_container">
  <div class="certified_image_mobile">
    <img src="assets/img/communities/CERTIFICADO_DEMO_1.svg" alt="certified" />
  </div>
  <div class="certified_info">
    <div class="certified_info_title">¡Agrega un certificado a tu perfil profesional!</div>
    <div class="certified_info_description">
      Al completar un webinar o un Programa de Especialización obtienes un certificado respaldado por Mibanco.
    </div>
    <div class="certified_info_button">
      <button class="certified_button" (click)="goToCertified()">Ver mis certificados</button>
    </div>
  </div>
  <div class="certified_image_desktop">
    <img src="assets/img/communities/CERTIFICADO_DEMO_1.svg" alt="certified" />
  </div>
</div>

<div class="benefit-card" [ngClass]="{'bg-bronze': this.progressMedal === 'Bronce',
                                      'bg-silver': this.progressMedal === 'Plata',
                                      'bg-gold': this.progressMedal === 'Oro'}">
    <div class="benefit-card-image-section">
        <img class="image-card" [src]="this.medalBenefit.image" alt="benefit" />
    </div>
    <div class="benefit-card-content-section">
        <p class="title-card">{{this.medalBenefit.imageDescripton}}</p>
        <p class="benefit-title">{{this.medalBenefit.Benefit}}</p>
        <p class="description">{{this.medalBenefit.description}}</p>
    </div>
    <div class="benefit-card-button-section">
        <button class="" (click)="goToRedirection(this.medalBenefit.urlPage,this.medalBenefit.url,this.medalBenefit.Benefit)">{{this.medalBenefit.titleButton}} </button>
    </div>
</div>
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbDateStruct, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import {
  CDEIncompleteStepsLabel,
  EMPLOYMENT_SITUATION_CODES,
  EMPLOYMENT_SITUATION_LABES,
  FORM_TYPES,
  FinancingIncompleteStepsIndex,
  MARRIED_IDENTIFIER_PREAPPROVED,
} from '@shared/constants/financing.constants';
import {
  EXTERNAL_FINANCING_LABEL,
  GEOLOCATION_LABELS,
  INTERNAL_FINANCING_LABEL,
  STEPS_PREAPPROVE_V2,
  STEPS_TITLES,
} from '@shared/data/financing/financing-preapproved-data.json';
import { DOCUMENTTYPELIST } from '@shared/data/login/login.json';
import {
  DNI_LENGTH,
  DNI_LENGTH_REGEX,
  INMIGRATION_CARD_LENGTH,
  INMIGRATION_CARD_LENGTH_REGEX,
} from '@shared/utils/login';
import { ONLY_NUMBER_REGEX, ONLY_NUMBER_WITH_TWO_DECIMALS_REGEX } from '@shared/utils/regex';
import {
  EMAIL_VALIDATORS,
  NAME_VALIDATORS,
  PHONE_CONFIRMATION_VALIDATOR,
  PHONE_VALIDATORS,
  renameValidatorError,
} from '@shared/utils/validators.utils';

import {
  AssessEligibilityExternalRequest,
  IBusinessFinanceForm,
  IDependentForm,
  IExternalUserFinancingForm,
  IFamilyFinanceForm,
  IFormTip,
  IIndependentForm,
  ILatLng,
  IPersonalInfoForm,
  IPreapprovedForm,
  ISpouseForm,
  IUrlQueryParamsFinancing,
  StepFormItem,
} from '@shared/interfaces';
import { IDropdownItem, ISelectItem } from '@shared/interfaces/IDropdownItem';
import { UserProfile } from '@shared/models/UserProfileModel';
import { preapproveDependentModel, preapproveIndependentModel } from '@shared/models/financing.model';

import * as seedCashData from '@shared/data/financing/seed-cash-data.json';
import { Lookup } from '@shared/models';
import { AuthService } from '@shared/services/auth.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { FinancingService } from '@shared/services/financing.service';
import { GeolocationService } from '@shared/services/geolocation.service';
import { LoaderService } from '@shared/services/loader.service';
import { generateDropDownItemsOrder } from '@shared/utils/dropdown.utils';
import { isStateUpdateValid } from '@shared/utils/financing.utils';
import { formMarkAsTouched, isFormInvalid } from '@shared/utils/form.utils';
import { FormDataService } from '@shared/services/form-data.service';

declare let $: any;

@Component({
  selector: 'yevo-financing-cde-form',
  templateUrl: './financing-cde-form.component.html',
  styleUrls: ['./financing-cde-form.component.scss'],
})
export class FinancingCdeFormComponent implements OnInit {
  @ViewChild('mapSearchField') formSearchField!: ElementRef;

  formDisabled = true;
  userForm!: FormGroup;
  mapModal!: NgbModalRef;

  documentTypeList: IDropdownItem[] = DOCUMENTTYPELIST;
  documentTypeSelected: IDropdownItem = this.documentTypeList[0];
  documentTypeSpouseSelected: IDropdownItem = this.documentTypeList[0];
  documentMaxLength: number = DNI_LENGTH;
  documentLengthRegex: string = DNI_LENGTH_REGEX;
  documentMaxLengthSpouse: number = DNI_LENGTH;
  documentLengthRegexSpouse: string = DNI_LENGTH_REGEX;

  isLogged: boolean = false;
  isExternalFinancing: boolean = false;
  isIndependent: boolean = false;
  isDependent: boolean = false;
  externalUserFinancingForm!: IExternalUserFinancingForm;
  urlQueryParams: IUrlQueryParamsFinancing = {};

  steps: StepFormItem[] = STEPS_PREAPPROVE_V2;
  activeStepNumber: number = 1;
  stepsTitles: any = STEPS_TITLES;
  currentStepTitle: string = this.stepsTitles.firstStepTitle;

  // DROPDOWNS
  timeContactList: IDropdownItem[] = [];
  timeContactSelected!: IDropdownItem;
  employmentSituationList: IDropdownItem[] = [];
  employmentSituationSelected!: IDropdownItem;
  maritalStatusList: IDropdownItem[] = [];
  maritalStatusSelected!: IDropdownItem;
  houseTypes: IDropdownItem[] = [];
  houseTypeSelected!: IDropdownItem;
  economicActivityList: IDropdownItem[] = [];
  economicActivitySelected!: IDropdownItem;
  businessFrequencyList: IDropdownItem[] = [];
  businessFrequencySelected!: IDropdownItem;
  businessPurchasesFrequencyList: IDropdownItem[] = [];
  businessPurchasesFrequencySelected!: IDropdownItem;
  helpWithForm: IDropdownItem[] = [];
  helpWithFormSelected!: IDropdownItem;

  selectedDate: string = '';
  showSpouseInputs: boolean = false;

  googlePlaceSelected = false;
  geolocationLabels = GEOLOCATION_LABELS;

  // FORMS MANAGE
  showInitialForm: boolean = true;
  showFirstStepOfFinalForm: boolean = true;
  showSecondStepOfFinalForm: boolean = false;
  showThirdStepOfFinalForm = false;
  showFourStepOfFinalForm: boolean = false;
  showFiveStepOfFinalForm: boolean = false;
  showSixStepOfFinalForm: boolean = false;

  dataDependent!: preapproveDependentModel;
  dataIndependent!: preapproveIndependentModel;

  aliado: string = '';
  utm_campaign: string = '';
  utm_content: string = '';
  utm_medium: string = '';
  utm_source: string = '';
  utm_id: string = '';

  latitude: string = '';
  longitude: string = '';

  assessEligibilityExternalRequest: AssessEligibilityExternalRequest =
    this.financingService.assessEligibilityExternalRequest;

  selfie!: string;
  documents!: any;
  ubigeo: string = '';
  sendFiles: any = [];

  isInitialFormValid: boolean = false;
  isFinalFormValid: boolean = false;

  showBusinessAdditionalExpenseMessage: boolean = false;
  showFamilyExpenseMessage: boolean = false;
  showAditionalLoansMessage: boolean = false;
  showWorkExpenseMessage: boolean = false;
  showNameMessage: boolean = false;
  showLastNameMessage: boolean = false;

  userProfile!: UserProfile;
  count: boolean = false;
  startDate: NgbDate = new NgbDate(2010, 1, 1);
  model!: NgbDateStruct;

  uploadSelfie: boolean = false;
  uploadDocuments: boolean = false;

  titleSteps: string = 'paso1';
  locations: any;

  mobileBreakShow: boolean = true;
  showGeolocationErrors: boolean = false;

  showUploadDocumentsErrorMessage: boolean = false;

  showUploadErrorMessage: boolean = false;
  uploadErrorMessage: string = '';

  tipList: IFormTip[] = seedCashData.tips;
  showBusinessAddressInfo: boolean = false;

  private readonly NAME_LENGTH: number = 100;
  private readonly SPOUSE_NAME_LENGTH: number = 50;
  private readonly MARITAL_STATUS_CODE: number = 29002;

  infoModal: boolean = false;

  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  selectedIndependentActivity: IDropdownItem[] = [];
  civilStatusSelected!: IDropdownItem;
  numberCategoriesShowed = 2;

  employmentStatusList: IDropdownItem[] = [];
  employmentStatusSelected!: IDropdownItem;

  autocompleteOptions: google.maps.places.AutocompleteOptions = {
    componentRestrictions: { country: 'PE' },
    // !: Be careful to keep a few fields because Google charges us $$$ for each field that we request
    fields: ['address_components', 'geometry', 'formatted_address'],
  };
  currentPosition!: google.maps.Marker;
  positionSelected!: google.maps.Marker;
  newGeolocation: any = {};
  geocoder = new google.maps.Geocoder();
  currentGeolocation: any = {};

  showAmount: boolean = true;
  currentPrecreditStep: number = 4;

  showLoanSimulatorResult: boolean = false;
  simulatorData: any = {};

  isEmailReadOnly: boolean = true;

  breadcrumbs = [
    { pageName: 'Financiamiento', url: ['/financiamiento/solicitud'], active: true },
    { pageName: 'Compra deuda express', url: null, active: false },
  ];
  amountPreApproved: number = 0;
  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    public financingService: FinancingService,
    public dataLayerService: DataLayerService,
    public geolocationService: GeolocationService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    public breakpointObserver: BreakpointObserver,
    private modalService: NgbModal,
    private ngZone: NgZone,
    private formDataService: FormDataService,
    @Inject(DOCUMENT) private _document: any
  ) {
    const state = window.history.state;
    if (state.source === 'simulator') {
      this.showLoanSimulatorResult = true;
      this.isEmailReadOnly = false;
      this.simulatorData = { ...state?.data };
    }
    this.financingService.solicitudeId = '';
    if (this.financingService.assessEligibilityExternalRequest.email === '') {
      this.isEmailReadOnly = false;
    }
  }

  googleMapsAutocomplete(): void {
    setTimeout(() => {
      const formAutocomplete = new google.maps.places.Autocomplete(
        this.formSearchField.nativeElement,
        this.autocompleteOptions
      );

      formAutocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const location = formAutocomplete.getPlace().geometry?.location;
          if (!location) return;
          const latLng: ILatLng = {
            lat: location!.lat(),
            lng: location!.lng(),
          };
          this.currentPosition = new google.maps.Marker({
            position: latLng,
            icon: 'https://yevononprodstorage.blob.core.windows.net/yevononprodcontainer/pin.png',
          });
          this.newGeolocation = latLng;
          this.setLocation();
        });
      });
    });
  }

  ngOnInit(): void {
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.breadcrumbs = [
          { pageName: 'Financiamiento', url: ['/financiamiento/filtro-financiamiento'], active: true },
          { pageName: 'Compra deuda express', url: null, active: false },
        ];
        this.isLogged = true;
        this.loadLoggedUser();
      }
    });
    this.validateInitialAmountExists();
    this.buildForm();
    this.getEventShared();
    this.getDocumentType();
    this.viewPort();

    this.financingService.getPreapprovedParams().subscribe((resp: any) => {
      this.employmentSituationList = this.getDropdownItems(resp?.employmentSituations!).filter(
        (item: any) => item.label !== 'Dependiente'
      );

      this.houseTypes = this.getDropdownItems(resp?.housingTypes!);
      this.maritalStatusList = this.getDropdownItems(resp?.maritalStatusPreApproved!);
      this.timeContactList = this.getDropdownItems(resp?.schedules!);
      this.economicActivityList = generateDropDownItemsOrder(resp?.economicActivities!);
      this.businessFrequencyList = this.getDropdownItems(resp?.frequences!);
      this.businessPurchasesFrequencyList = this.businessFrequencyList;

      this.helpWithForm = [
        { label: 'No, lo hice sol@', value: '1' },
        { label: 'Si, un asesor me ayudó', value: '2' },
      ];

      this.employmentSituationSelected = this.employmentSituationList[0];
      this.selectedIndependentActivity = this.employmentSituationList;
      this.userForm.get('employmentSituation')?.setValue(this.employmentSituationSelected?.label || '');
      this.fillAndSaveForm();
    });

    this.updateIncompleteState(FinancingIncompleteStepsIndex.STEP_4, CDEIncompleteStepsLabel.STEP_4);
  }

  private loadLoggedUser() {
    this.authService.userProfile$.subscribe((userProfile) => (this.userProfile = userProfile!));
  }

  private validateInitialAmountExists() {
    if (!this.financingService?.initialFormAmount) {
      this.router.navigate(['financiamiento'], { queryParamsHandling: 'merge' });
    }
  }

  private getDropdownItems(list: any) {
    let finalList: IDropdownItem[] = [];
    list.map((item: any) => finalList.push({ label: item.name, value: item.code }));
    return finalList;
  }

  private buildForm() {
    let name: string = '';
    let fatherLastName: string = '';
    let motherLastName: string = '';
    this.getAmountPreApproved();
    if (this.isLogged) {
      name = this.userProfile?.name;
      fatherLastName = this.userProfile?.fatherLastName;
      motherLastName = this.userProfile?.motherLastName;
    }

    this.userForm = this.fb.group(
      {
        amount: [`S/ ${this.financingService.amountRequest}`, [Validators.required]],
        name: [name, NAME_VALIDATORS(this.NAME_LENGTH, true)],
        fatherLastName: [fatherLastName, NAME_VALIDATORS(this.NAME_LENGTH, true)],
        motherLastName: [motherLastName, NAME_VALIDATORS(this.NAME_LENGTH, true)],
        documentNumber: [
          this.userProfile?.documentNumber || this.assessEligibilityExternalRequest?.documentNumber,
          [Validators.required],
        ],
        phone: [this.userProfile?.phone || this.assessEligibilityExternalRequest?.phone, PHONE_VALIDATORS()],
        phoneConfirm: [null, PHONE_VALIDATORS()],
        timeContact: [null, [Validators.required]],
        employmentSituation: [null, [Validators.required]],
        maritalStatus: [null, [Validators.required]],
        housingType: [null, [Validators.required]],
        email: [this.userProfile?.email || this.assessEligibilityExternalRequest?.email || '', EMAIL_VALIDATORS(100)],
        economicActivity: [null, [Validators.required]],
        department: [null, Validators.required],
        province: [null, Validators.required],
        district: [null, Validators.required],
        businessPhone: [null, PHONE_VALIDATORS(false)],
        address: [null, [this.validatePlaceOptionSelected(), Validators.required]],
        typeDocumentSpouse: ['C', [Validators.required]],
        documentNumberSpouse: [null, [Validators.required]],
        nameSpouse: [null, NAME_VALIDATORS(this.SPOUSE_NAME_LENGTH, true)],
        lastNameSpouse: [null, NAME_VALIDATORS(this.SPOUSE_NAME_LENGTH, true)],
        occupation: [null, NAME_VALIDATORS(this.NAME_LENGTH, true)],
        grossSalary: [
          null,
          [Validators.required, renameValidatorError(Validators.pattern(ONLY_NUMBER_REGEX), 'onlyNumber')],
        ],
        workExpense: [
          null,
          [Validators.required, renameValidatorError(Validators.pattern(ONLY_NUMBER_REGEX), 'onlyNumber')],
        ],
        employerName: [null, NAME_VALIDATORS(this.NAME_LENGTH, true)],
        employerRuc: [
          null,
          [
            Validators.required,
            Validators.minLength(11),
            Validators.maxLength(11),
            renameValidatorError(Validators.pattern(ONLY_NUMBER_REGEX), 'onlyNumber'),
          ],
        ],
        businessSalesFrequency: [null, [Validators.required]],
        businessSalesAmount: [
          null,
          [
            Validators.required,
            Validators.maxLength(18),
            renameValidatorError(Validators.pattern(ONLY_NUMBER_WITH_TWO_DECIMALS_REGEX), 'onlyNumber'),
          ],
        ],
        businessPurchasesFrequency: [null, [Validators.required]],
        businessPurchasesAmount: [
          null,
          [
            Validators.required,
            Validators.maxLength(18),
            renameValidatorError(Validators.pattern(ONLY_NUMBER_WITH_TWO_DECIMALS_REGEX), 'onlyNumber'),
          ],
        ],
        businessAdditionalExpense: [
          null,
          [
            Validators.required,
            Validators.maxLength(18),
            renameValidatorError(Validators.pattern(ONLY_NUMBER_WITH_TWO_DECIMALS_REGEX), 'onlyNumber'),
          ],
        ],
        familyExpense: [
          null,
          [
            Validators.required,
            Validators.maxLength(18),
            renameValidatorError(Validators.pattern(ONLY_NUMBER_WITH_TWO_DECIMALS_REGEX), 'onlyNumber'),
          ],
        ],
        additionalPayment: [
          null,
          [
            Validators.maxLength(18),
            renameValidatorError(Validators.pattern(ONLY_NUMBER_WITH_TWO_DECIMALS_REGEX), 'onlyNumber'),
          ],
        ],
        additionalLoansAmount: [
          null,
          [
            Validators.maxLength(18),
            renameValidatorError(Validators.pattern(ONLY_NUMBER_WITH_TWO_DECIMALS_REGEX), 'onlyNumber'),
          ],
        ],
        emailSpouse: [null],
        workStartDate: [null, [Validators.required]],
        helpWithFormSelected: [null, [Validators.required]],
      },
      {
        updateOn: 'change',
        validators: [
          (group: FormGroup): { [key: string]: any } | null => {
            return PHONE_CONFIRMATION_VALIDATOR(group, 'phoneConfirm', 'phone');
          },
          (group: FormGroup): { [key: string]: any } | null => {
            if (this.documentTypeSelected.value === 'C' && group.get('documentNumber')?.value.match('[0-9]{8}')) {
              return null;
            }
            if (this.documentTypeSelected.value === 'E' && group.get('documentNumber')?.value.match('[0-9]{9}')) {
              return null;
            }
            group.get('documentNumber')?.setErrors({ invalidDocumentNumber: true });
            group.get('documentNumber')?.markAsTouched();
            return { invalidDocumentNumber: true };
          },
          (group: FormGroup): { [key: string]: any } | null => {
            if (
              group.get('typeDocumentSpouse')?.value !== '' ||
              (group.get('typeDocumentSpouse')?.value === 'C' &&
                group.get('documentNumberSpouse')?.value.match('[0-9]{8}'))
            ) {
              return null;
            }
            if (
              group.get('typeDocumentSpouse')?.value !== '' ||
              (group.get('typeDocumentSpouse')?.value === 'E' &&
                group.get('documentNumberSpouse')?.value.match('[0-9]{9}'))
            ) {
              return null;
            }
            group.get('documentNumberSpouse')?.setErrors({ invalidDocumentNumberSpouse: true });
            group.get('documentNumberSpouse')?.markAsTouched();
            return { invalidDocumentNumberSpouse: true };
          },
        ],
      }
    );

    this.onInitialFormChanges();
  }

  phoneMatchValidator() {
    return this.userForm.get('phone')?.value === this.userForm.get('phoneConfirm')?.value ? null : { mismatch: true };
  }
  // FORM VALIDATION
  private onInitialFormChanges() {
    this.userForm.valueChanges.subscribe((value: IPreapprovedForm) => {
      this.showInitialForm ? this.validateInitialForm(value) : this.validateFinalForm(value);
    });
  }

  private validateInitialForm(formValue: any): void {
    let keysPersonalInfoForm: IPersonalInfoForm = this.initPersonalInfoForm();
    let keySpouseForm: ISpouseForm = this.initSpouseForm();
    let keysAditionalForm: any = this.isUserDependent() ? this.initDependentForm() : this.initIndependentForm();
    let isFormInvalid: boolean = false;

    // PERSONAL INFO
    for (let key of Object.keys(keysPersonalInfoForm)) {
      if (this.isInvalidControl(formValue, key)) {
        isFormInvalid = true;
        break;
      }
    }
    // DEPENDS ON MARITAL STATUS -> SPOUSE INFO
    if (this.isUserMarried()) {
      Object.keys(keySpouseForm).forEach((key: string) => {
        if (this.isInvalidControl(formValue, key)) {
          isFormInvalid = true;
          return;
        }
      });
    }
    // DEPENDS ON EMPLOYMENT SITUATION -> ADITIONAL FIELDS
    Object.keys(keysAditionalForm).forEach((key: string) => {
      if (key === 'businessPhone') {
        if (formValue.businessPhone) {
          if (this.isInvalidControl(formValue, key)) {
            isFormInvalid = true;
            return;
          }
        }
        return;
      }

      if (this.isInvalidControl(formValue, key)) {
        isFormInvalid = true;
        return;
      }
    });
    this.isInitialFormValid = !isFormInvalid;
  }

  private validateFinalForm(formValue: any): void {
    let keysBusinessFinanceForm: IBusinessFinanceForm = this.initBusinessFinanceForm();
    let keysFamilyFinanceForm: IFamilyFinanceForm = this.initFamilyFinanceForm();
    let isBusinessFormInvalid: boolean = false;
    let isFamilyFormInvalid: boolean = false;

    // BUSINESS FINANCE INFO
    if (this.showFirstStepOfFinalForm) {
      Object.keys(keysBusinessFinanceForm).forEach((key: string) => {
        if (this.isInvalidControl(formValue, key)) {
          isBusinessFormInvalid = true;
          return;
        }
      });
      this.isFinalFormValid = !isBusinessFormInvalid;
    }
    // FAMILY FINANCE INFO
    if (this.showFirstStepOfFinalForm) {
      if (!isBusinessFormInvalid) {
        Object.keys(keysFamilyFinanceForm).forEach((key: string) => {
          if (this.isInvalidControl(formValue, key)) {
            isFamilyFormInvalid = true;
            return;
          }
        });
      }
      this.isFinalFormValid = !isFamilyFormInvalid;
    }
  }

  private isInvalidControl(formValue: any, key: any) {
    return !!(!formValue[key] || !this.userForm.get(key)?.valid);
  }

  private initPersonalInfoForm(): IPersonalInfoForm {
    return {
      name: '',
      fatherLastName: '',
      motherLastName: '',
      documentNumber: '',
      email: '',
      phone: '',
      timeContact: '',
      employmentSituation: '',
      maritalStatus: '',
      housingType: '',
      phoneConfirm: '',
    };
  }
  private initSpouseForm(): ISpouseForm {
    return {
      typeDocumentSpouse: '',
      documentNumberSpouse: '',
      nameSpouse: '',
      lastNameSpouse: '',
    };
  }
  private initDependentForm(): IDependentForm {
    return {
      occupation: '',
      workStartDate: '',
      grossSalary: '',
      workExpense: '',
      employerName: '',
      employerRuc: '',
    };
  }
  private initIndependentForm(): IIndependentForm {
    return {
      department: '',
      province: '',
      district: '',
      address: '',
      economicActivity: '',
      businessPhone: '',
      helpWithFormSelected: '',
    };
  }
  private initBusinessFinanceForm(): IBusinessFinanceForm {
    return {
      businessSalesFrequency: '',
      businessSalesAmount: '',
      businessPurchasesFrequency: '',
      businessPurchasesAmount: '',
      businessAdditionalExpense: '',
      familyExpense: '',
      additionalLoansAmount: '',
      additionalPayment: '',
    };
  }
  private initFamilyFinanceForm(): IFamilyFinanceForm {
    return {
      familyExpense: '',
      additionalLoansAmount: '',
      additionalPayment: '',
    };
  }

  // ERROR METHODS
  public getErrorsFromField(field: string): any {
    return this.userForm.get(field)?.errors;
  }
  public showError(field: string, dirty: boolean = false): boolean | undefined {
    const control = this.userForm.get(field)!;
    return dirty ? control.invalid && (control.dirty || control.touched) : control.invalid && control.touched;
  }

  // DROPDOWN EVENTS
  public getTimeContactEvent(event: any): void {
    this.timeContactSelected = event.item;
    this.userForm.get('timeContact')?.setValue(event.item?.label || '');
  }
  public getEmploymentSituationEvent(event: any): void {
    this.employmentSituationSelected = event.item;
    this.userForm.get('employmentSituation')?.setValue(event.item?.label || '');
    //this.showHideEmploymentSituationFields(this.employmentSituationSelected.value);
  }
  private showHideEmploymentSituationFields(employmentSituationCode: string) {
    if (employmentSituationCode == EMPLOYMENT_SITUATION_CODES.INDEPENDENT_CODE) {
      this.isIndependent = true;
      this.isDependent = false;
    }
    if (employmentSituationCode == EMPLOYMENT_SITUATION_CODES.DEPENDENT_CODE) {
      this.isIndependent = false;
      this.isDependent = true;
    }
  }
  public getMaritalStatusEvent(event: any): void {
    this.maritalStatusSelected = event.item;
    this.userForm.get('maritalStatus')?.setValue(event.item?.label || '');
    this.getSpouseInfo();
  }
  public getHouseTypeEvent(event: any): void {
    this.houseTypeSelected = event.item;
    this.userForm.get('housingType')?.setValue(event.item.label);
  }
  public getHelpWithForm(event: any): void {
    this.helpWithFormSelected = event.item;
    this.userForm.get('helpWithFormSelected')?.setValue(event.item.label);
  }
  public getEconomicActivityEvent(event: any): void {
    this.economicActivitySelected = event.item;
    this.userForm.get('economicActivity')?.setValue(event.item.label);
  }
  public getBusinessFrequencyEvent(event: any): void {
    this.businessFrequencySelected = event.item;
    this.userForm.get('businessSalesFrequency')?.setValue(event.item?.label || '');
  }
  public getBusinessPurchasesFrequencyEvent(event: any): void {
    this.businessPurchasesFrequencySelected = event.item;
    this.userForm.get('businessPurchasesFrequency')?.setValue(event.item?.label || '');
  }
  private getSpouseInfo(): void {
    if (this.isUserMarried()) {
      this.showSpouseInputs = true;
    } else {
      this.showSpouseInputs = false;
    }
    this.changeValidatorsSpouseDocumentNumber();
  }
  public getDocumentTypeEvent(event: ISelectItem): void {
    this.documentTypeSelected = event.item;
    this.userForm.get('documentNumber')?.setValue(event.item?.label || '');
  }
  public getDocumentTypeSpouseEvent(event: ISelectItem): void {
    this.documentTypeSpouseSelected = event.item!;
    this.userForm.get('typeDocumentSpouse')?.setValue(this.documentTypeSpouseSelected?.label || '');
    this.userForm.get('documentNumberSpouse')?.setValue('');
    this.changeValidatorsSpouseDocumentNumber();
  }
  private changeValidatorsSpouseDocumentNumber() {
    if (this.documentTypeSpouseSelected.value === 'E') {
      this.documentMaxLengthSpouse = INMIGRATION_CARD_LENGTH;
      this.documentLengthRegexSpouse = INMIGRATION_CARD_LENGTH_REGEX;
    } else {
      this.documentMaxLengthSpouse = DNI_LENGTH;
      this.documentLengthRegexSpouse = DNI_LENGTH_REGEX;
    }

    this.userForm
      .get('documentNumberSpouse')
      ?.setValidators([Validators.required, Validators.pattern(`[0-9]{${this.documentLengthRegexSpouse}}`)]);
    this.userForm.get('documentNumberSpouse')?.updateValueAndValidity();
  }

  // DROPDOWN EVENTS END
  public selectWorkStartDate(date: string | null) {
    this.userForm.get('workStartDate')?.setValue(date);
  }

  // STEPS EVENTS
  public changeStep(stepNumber: number) {
    this.activeStepNumber = stepNumber;

    window.scrollTo(0, 0);
  }
  public changeStepTitle(stepTitleReference: string) {
    this.currentStepTitle = this.stepsTitles[stepTitleReference];
  }

  public showNextFormGroupStepTwo() {
    const isMarried = this.maritalStatusSelected?.value == this.MARITAL_STATUS_CODE.toString();
    let keysPersonalInfoForm: IPersonalInfoForm = this.initPersonalInfoForm();
    let keysSpouseForm: ISpouseForm = this.initSpouseForm();
    if (
      isFormInvalid(this.userForm, keysPersonalInfoForm, true) ||
      (isFormInvalid(this.userForm, keysSpouseForm, true) && isMarried)
    ) {
      formMarkAsTouched(this.userForm, true, keysPersonalInfoForm, true);
      if (isMarried) formMarkAsTouched(this.userForm, true, keysSpouseForm, true);
      return;
    }

    this.isIndependent = true;
    this.showAmount = false;
    this.changeStep(2);
    this.changeStepTitle('secondStepTitleBusiness');
    this.changeUrl('paso2');
    setTimeout(() => {
      $('#collapseBusinessInfo').collapse('toggle');
      this.updateIncompleteState(FinancingIncompleteStepsIndex.STEP_5, CDEIncompleteStepsLabel.STEP_5);
    }, 300);
  }

  public showNextFormGroup() {
    this.showGeolocationErrors = true;

    let keysPersonalInfoForm: IPersonalInfoForm = this.initPersonalInfoForm();
    let keysIndependentInfoForm: IIndependentForm = this.initIndependentForm();
    let keysSpouseForm: ISpouseForm = this.initSpouseForm();

    const isMarried = this.maritalStatusSelected?.value == this.MARITAL_STATUS_CODE.toString();

    if (
      isFormInvalid(this.userForm, keysPersonalInfoForm, true) ||
      isFormInvalid(this.userForm, keysIndependentInfoForm, true) ||
      (isFormInvalid(this.userForm, keysSpouseForm, true) && isMarried)
    ) {
      formMarkAsTouched(this.userForm, true, keysPersonalInfoForm, true);
      formMarkAsTouched(this.userForm, true, keysIndependentInfoForm, true);
      if (isMarried) formMarkAsTouched(this.userForm, true, keysSpouseForm, true);
      return;
    }

    this.showInitialForm = false;
    window.scrollTo(0, 0);
    this.isFinalFormValid = false;

    this.changeStep(3);
    this.changeStepTitle('thirdStepTitleStep1');
    this.changeUrl('paso3');
    this.updateIncompleteState(FinancingIncompleteStepsIndex.STEP_6, CDEIncompleteStepsLabel.STEP_6);
  }

  public nextStepOfFinalForm() {
    if (this.showFirstStepOfFinalForm) {
      let keysBusinessFinanceForm: IBusinessFinanceForm = this.initBusinessFinanceForm();
      if (isFormInvalid(this.userForm, keysBusinessFinanceForm, true)) {
        formMarkAsTouched(this.userForm, true, keysBusinessFinanceForm, true);
        return;
      }
      let keysFamilyFinanceForm: IFamilyFinanceForm = this.initFamilyFinanceForm();
      if (isFormInvalid(this.userForm, keysFamilyFinanceForm, true)) {
        formMarkAsTouched(this.userForm, true, keysFamilyFinanceForm, true);
        return;
      }
      this.sendSolicitude();
    }
  }

  public sendSolicitude() {
    this.loaderService.setIsLoadingLazyPage(true);
    this.setSucessInformationForNextView();

    const solicitudId = this.financingService.solicitudeId;

    if (this.isUserDependent()) {
      this.sendFormDependent();
      if (solicitudId === '') {
        if (this.isLogged) {
          this.financingService.postCDEInternalDependent(this.dataDependent).subscribe(
            (data) => {
              this.loaderService.setIsLoadingLazyPage(false);
              this.router.navigate(['financiamiento', 'compradeuda-solicitud-enviada', 'solicitudenviada'], {
                state: { isFinancingCde: true },
                queryParamsHandling: 'merge',
              });
            },
            (error) => this.loaderService.setIsLoadingLazyPage(false)
          );
        } else {
          this.financingService.postCDEExternalDependent(this.dataDependent).subscribe(
            (data) => {
              this.loaderService.setIsLoadingLazyPage(false);
              this.router.navigate(['financiamiento', 'compradeuda-solicitud-enviada', 'solicitudenviada'], {
                state: { isFinancingCde: true },
                queryParamsHandling: 'merge',
              });
            },
            (error) => this.loaderService.setIsLoadingLazyPage(false)
          );
        }
      } else {
        this.financingService.putCDE(this.dataDependent, solicitudId).subscribe(
          (data) => {
            this.loaderService.setIsLoadingLazyPage(false);
            this.router.navigate(['financiamiento', 'compradeuda-solicitud-enviada', 'solicitudenviada'], {
              state: { isFinancingCde: true },
              queryParamsHandling: 'merge',
            });
          },
          (error) => this.loaderService.setIsLoadingLazyPage(false)
        );
      }
    } else {
      this.sendFormIndependent();
      if (solicitudId === '') {
        if (this.isLogged) {
          this.financingService.postCDEInternalIndependent(this.dataIndependent).subscribe(
            (data) => {
              this.showUploadDocuments(data.id);
            },
            (error) => this.loaderService.setIsLoadingLazyPage(false)
          );
        } else {
          this.financingService.postCDEExternalIndependent(this.dataIndependent).subscribe(
            (data) => {
              this.showUploadDocuments(data.id);
            },
            (error) => this.loaderService.setIsLoadingLazyPage(false)
          );
        }
      } else {
        this.financingService.putCDE(this.dataIndependent, solicitudId).subscribe(
          (data) => {
            this.showUploadDocuments(solicitudId);
          },
          (error) => this.loaderService.setIsLoadingLazyPage(false)
        );
      }
    }
  }

  public showUploadDocuments(idSolicitude: string) {
    window.scrollTo(0, 0);
    this.loaderService.setIsLoadingLazyPage(false);
    this.financingService.solicitudeId = idSolicitude;
    this.showFirstStepOfFinalForm = false;
    this.changeUrl('paso4');
    this.showFirstStepOfFinalForm = false;
    this.showSecondStepOfFinalForm = true;
    this.showThirdStepOfFinalForm = true;
    this.isFinalFormValid = false;
    this.changeStep(4);
    this.changeStepTitle('thirdStepTitleStep3');
    this.updateIncompleteState(FinancingIncompleteStepsIndex.STEP_7, CDEIncompleteStepsLabel.STEP_7);
  }

  // MARKS
  public pushFinancingEventSentSolicitude() {
    this.dataLayerService.pushFinancingSolicitudeIntention(
      this.isExternalFinancing,
      this.financingService.derivationSelected
    );
  }

  public sendFormDependent() {
    const referredCode  = this.financingService.defaultRefersData.referredCode;
    this.dataDependent = {
      name: this.userForm.get('name')?.value,
      fatherLastName: this.userForm.get('fatherLastName')?.value,
      motherLastName: this.userForm.get('motherLastName')?.value,
      email: this.userForm.get('email')?.value,
      phone: this.userForm.get('phone')?.value,
      typeDocument: this.documentTypeSelected?.value,
      documentNumber: this.userForm.get('documentNumber')?.value,
      amount: this.financingService.amountRequest,
      maritalStatus: this.userForm.get('maritalStatus')?.value,
      typeDocumentSpouse: this.documentTypeSpouseSelected?.value,
      documentNumberSpouse: this.userForm.get('documentNumberSpouse')?.value,
      nameSpouse: this.userForm.get('nameSpouse')?.value,
      lastNameSpouse: this.userForm.get('lastNameSpouse')?.value,
      emailSpouse: this.userForm.get('emailSpouse')?.value,
      typeCampaign: this.financingService.typeCampaign,
      aliado: this.aliado,
      utmSource: this.utm_source,
      utmMedium: this.utm_medium,
      utmCampaign: this.utm_campaign,
      utmId: this.utm_id,
      idPreCredit: this.financingService.idPreCredit,
      timeContact: this.userForm.get('timeContact')?.value,
      employmentSituation: this.userForm.get('employmentSituation')?.value,
      housingType: this.houseTypeSelected?.label,
      occupation: this.userForm.get('occupation')?.value,
      workStartDate: this.userForm.get('workStartDate')?.value,
      grossSalary: this.userForm.get('grossSalary')?.value,
      workExpense: this.userForm.get('workExpense')?.value,
      employerName: this.userForm.get('employerName')?.value,
      employerRuc: this.userForm.get('employerRuc')?.value,
      completedWithHelp: this.helpWithFormSelected?.value == '1' ? false : true,
      completed: false,
      referredCode:referredCode,
      formType: FORM_TYPES.CDE,
      ...this.getSolicitudeSimulatorData(),
    };
  }

  public sendFormIndependent() {
    const referredCode  = this.financingService.defaultRefersData.referredCode;
    this.dataIndependent = {
      name: this.userForm.get('name')?.value,
      fatherLastName: this.userForm.get('fatherLastName')?.value,
      motherLastName: this.userForm.get('motherLastName')?.value,
      email: this.userForm.get('email')?.value,
      phone: this.userForm.get('phone')?.value,
      typeDocument: this.documentTypeSelected?.value,
      documentNumber: this.userForm.get('documentNumber')?.value,
      amount: this.financingService.amountRequest,
      maritalStatus: this.userForm.get('maritalStatus')?.value,
      typeDocumentSpouse: this.documentTypeSpouseSelected?.value,
      documentNumberSpouse: this.userForm.get('documentNumberSpouse')?.value,
      nameSpouse: this.userForm.get('nameSpouse')?.value,
      lastNameSpouse: this.userForm.get('lastNameSpouse')?.value,
      emailSpouse: this.userForm.get('emailSpouse')?.value,
      typeCampaign: this.financingService.typeCampaign,
      aliado: this.aliado,
      utmSource: this.utm_source,
      utmMedium: this.utm_medium,
      utmCampaign: this.utm_campaign,
      utmId: this.utm_id,
      idPreCredit: this.financingService.idPreCredit,
      timeContact: this.userForm.get('timeContact')?.value,
      employmentSituation: this.userForm.get('employmentSituation')?.value,
      housingType: this.houseTypeSelected?.label,
      businessEconomicActivity: this.economicActivitySelected?.label,
      businessPhone: this.userForm.get('businessPhone')?.value,
      businessSalesFrequency: this.businessFrequencySelected?.label,
      businessSalesAmount: this.userForm.get('businessSalesAmount')?.value,
      businessPurchasesFrequency: this.businessPurchasesFrequencySelected?.label,
      businessPurchasesAmount: this.userForm.get('businessPurchasesAmount')?.value,
      businessAdditionalExpense: this.userForm.get('businessAdditionalExpense')?.value,
      familyExpense: this.userForm.get('familyExpense')?.value,
      additionalPayment: this.userForm.get('additionalPayment')?.value,
      additionalLoansAmount: this.userForm.get('additionalLoansAmount')?.value,
      ubigeo: this.ubigeo,
      department: this.userForm.get('department')?.value,
      province: this.userForm.get('province')?.value,
      district: this.userForm.get('district')?.value,
      address: this.userForm.get('address')?.value,
      latitude: String(this.latitude),
      longitude: String(this.longitude),
      completedWithHelp: this.helpWithFormSelected?.value == '1' ? false : true,
      completed: false,
      referredCode:referredCode,
      formType: FORM_TYPES.CDE,
      ...this.getSolicitudeSimulatorData(),
    };
  }

  getEventShared() {
    this.route.queryParams.subscribe((params) => {
      this.aliado = params.aliado || this.financingService.aliado;
      this.utm_campaign = params.utm_campaign || '';
      this.utm_content = params.utm_content || '';
      this.utm_medium = params.utm_medium || '';
      this.utm_source = params.utm_source || '';
      this.utm_id = params.utm_id || '';
    });
  }

  handleGeolocationClick(geolocation: any): void {
    if (geolocation?.length === 8) {
      this.ubigeo = geolocation;
      this.userForm.get('ubigeo')?.setValue(geolocation);

      this.locations = {
        department: {
          label: this.userForm.get('department')?.value,
          value: geolocation?.substr(2, 2),
        },
        province: {
          label: this.userForm.get('province')?.value,
          value: geolocation?.substr(4, 2),
        },
        district: {
          label: this.userForm.get('district')?.value,
          value: geolocation?.substr(6, 2),
        },
      };
    }
  }

  handleGeolocationSeparatelyClick(geolocation?: any): void {
    if (geolocation.value.length === 2) {
      this.userForm.get('department')?.setValue(geolocation?.label);
    } else if (geolocation.value.length === 4) {
      this.userForm.get('province')?.setValue(geolocation?.label);
    } else if (geolocation.value?.length === 6) {
      this.userForm.get('district')?.setValue(geolocation?.label);
    }
  }

  getDocumentType() {
    const type = this.userProfile?.typeDocument || this.assessEligibilityExternalRequest?.typeDocument;
    if (type === 'C') {
      this.documentTypeSelected = this.documentTypeList[0];
    } else if (type === 'E') {
      this.documentTypeSelected = this.documentTypeList[1];
    }
  }

  getSolicitudeSimulatorData() {
    this.financingService.assessEligibilityExternalRequest.email = this.userForm.get('email')?.value;
    if (!this.financingService.simulatorCompleted) return {};
    if (this.simulatorData.fromCard) return { simulatorId: this.simulatorData.simulatorId };
    return {
      simulatorId: this.simulatorData.simulatorId,
      simulatorTermAmount: this.simulatorData.maxPaymentRate,
      simulatorTerms: this.simulatorData.paymentDeadline,
    };
  }

  addGeolocation(geolocation: any) {
    this.latitude = geolocation.lat;
    this.longitude = geolocation.lng;
    this.googlePlaceSelected = true;
    this.userForm.get('address')?.setValue(geolocation.address);
    this.dataLayerService.pushCDEModalLocationEvent();
  }

  addSelfie(image: any) {
    this.selfie = image;
    this.showUploadErrorMessage = false;
    this.financingService.showSelfieError = false;
  }

  addDocuments(doc: any) {
    this.documents = doc;
    this.showUploadErrorMessage = false;
    this.financingService.showDocumentError = false;
  }

  completeSolicitude() {
    if (!this.selfie || this.documents?.length !== 2) {
      this.getUploadErrorMessage();
      this.showUploadErrorMessage = true;
      this.showUploadDocumentsErrorMessage = true;
      this.financingService.showDocumentError = !this.documents || this.documents?.length < 2;
      this.financingService.showSelfieError = !this.selfie;
    } else {
      this.showUploadErrorMessage = false;
      this.showUploadDocumentsErrorMessage = false;
      this.sendSuccessFullStepMark('4');

      this.financingService.completeSolicitude(this.financingService.solicitudeId).subscribe((resp) => {
        this.cleanUploadedDocuments();
        this.loaderService.setIsLoadingLazyPage(false);
        this.deleteIdPrecredit();

        this.router.navigate(['financiamiento', 'solicitud-enviada'], {
          state: { isFinancingCde: true },
          queryParamsHandling: 'merge',
        });
      });
    }
  }

  private cleanUploadedDocuments() {
    this.selfie = '';
    this.documents = [];
    this.financingService.uploadedSelfie = '';
    this.financingService.uploadedSelfieId = 0;
    this.financingService.uploadedDocuments = [];
  }

  private getUploadErrorMessage() {
    if (!this.documents || this.documents?.length == 0)
      this.uploadErrorMessage = '* Para continuar debes subir 2 boletas o facturas de tu negocio.';
    if (this.documents?.length == 1)
      this.uploadErrorMessage = '* Para continuar debes subir 1 boleta o factura más de tu negocio.';
    if (!this.selfie)
      this.uploadErrorMessage = '* Para continuar debes tomarte una foto selfie con tu negocio o mercadería:';
    if (!this.selfie && this.documents?.length !== 2)
      this.uploadErrorMessage = '* Para continuar debes subir los siguientes documentos:';
  }

  private setSucessInformationForNextView() {
    this.financingService.setSuccessInformation(
      this.userForm.get('name')?.value,
      this.userForm.get('fatherLastName')?.value,
      this.userForm.get('motherLastName')?.value
    );
  }

  private isUserDependent() {
    return this.userForm.get('employmentSituation')?.value === EMPLOYMENT_SITUATION_LABES.DEPENDENT_LABEL;
  }
  private isUserMarried() {
    return this.maritalStatusSelected?.label == MARRIED_IDENTIFIER_PREAPPROVED;
  }

  public showHideBusinessAdditionalExpenseMessage() {
    this.showBusinessAdditionalExpenseMessage = !this.showBusinessAdditionalExpenseMessage;
  }
  public showHideFamilyExpenseMessage() {
    this.showFamilyExpenseMessage = !this.showFamilyExpenseMessage;
  }
  public showHideAditionalLoansMessage() {
    this.showAditionalLoansMessage = !this.showAditionalLoansMessage;
  }
  public showHideWorkExpenseMessage() {
    this.showWorkExpenseMessage = !this.showWorkExpenseMessage;
  }

  changeUrl(title: string) {
    const queryParams = window.location.href.split('?')[1] || '';

    if (title === 'paso2') {
      this.sendSuccessFullStepMark('1');
    } else if (title === 'paso3') {
      this.sendSuccessFullStepMark('2');
      this.count = false;
      this.validateFormStepThree();
    } else if (title === 'paso4' && !this.count) {
      this.sendSuccessFullStepMark('3');
      title = 'paso4';
      this.count = true;
      this.validateFromStepFour();
    } else if (title === 'paso4' && this.count) {
      this.sendSuccessFullStepMark('4');
      title = 'paso5';
      this.count = false;
    }
    this.titleSteps = title;

    window.history.pushState(
      'financing',
      'Financing',
      queryParams
        ? `financiamiento/compradeuda-solicitud/${title}?${queryParams}`
        : `financiamiento/compradeuda-solicitud/${title}`
    );
  }

  private sendSuccessFullStepMark(step: string): void {
    this.dataLayerService.pushCDEStepSuccessfull('compra de deuda express', step);
  }

  private sendModalLocationMark(button: string): void {
    this.dataLayerService.pushCDEModalLocationButtonEvent(button);
  }

  buttonAcceptedLocation(event: boolean) {
    if (event) {
      this.sendModalLocationMark('Aceptar');
    }
  }

  buttonCloseLocation(event: boolean) {
    if (event) {
      this.sendModalLocationMark('Cancelar');
    }
  }

  buttonAcceptedSelfie(event: boolean) {
    if (event) {
      this.sendFilesMark('Intención de subida', 'Foto');
    }
  }

  buttonUploadSelfie(event: boolean) {
    if (event) {
      this.uploadSelfie = true;
      this.sendFilesMark('Subida exitosa', 'Foto');
    }
  }

  buttonAcceptedDocument(event: boolean) {
    if (event) {
      this.sendFilesMark('Intención de subida', 'Boleta');
    }
  }

  buttonUploadDocument(event: boolean) {
    if (event) {
      this.uploadDocuments = true;
      this.sendFilesMark('Subida exitosa', 'Boleta');
    }
  }

  private getFinancingTypeMarkLabel(): string {
    return this.isLogged ? INTERNAL_FINANCING_LABEL : EXTERNAL_FINANCING_LABEL;
  }
  private sendFilesMark(action: string, fileType: string): void {
    const typeFinancing = this.getFinancingTypeMarkLabel();
    this.dataLayerService.pushCDEUploaddocument(typeFinancing, action, fileType);
  }

  navigationSteps() {
    if (this.titleSteps === 'paso1') {
      this.router.navigate(['/nuevo-intranet/dashboard']);
    } else if (this.titleSteps === 'paso2') {
      this.isIndependent = false;
      this.showAmount = true;
      this.activeStepNumber = 1;
      this.titleSteps = 'paso1';

      this.changeStep(1);
      this.changeStepTitle('firstStepTitle');
      this.changeUrl('paso1');

      $('#collapsePersonalInfo').collapse('toggle');
    } else if (this.titleSteps === 'paso3') {
      this.showInitialForm = true;
      this.showFirstStepOfFinalForm = true;
      this.showSecondStepOfFinalForm = false;

      this.googleMapsAutocomplete();
      this.changeStep(2);
      this.changeStepTitle('secondStepTitleBusiness');
      this.changeUrl('paso2');

      let keysIndependentInfoForm: IIndependentForm = this.initIndependentForm();
      formMarkAsTouched(this.userForm, false, keysIndependentInfoForm, true);

      setTimeout(() => {
        const isPersonalInfo = this._document.getElementById('collapsePersonalInfo');
        if (isPersonalInfo) {
          this._document.getElementById('collapsePersonalInfo').classList.add('d-none');
        }
      });

      if (this.isDependent) {
        $('#collapsePersonalInfo').collapse('toggle');
        $('#collapseBusinessInfo').collapse('hide');
        $('#collapseWorkInfo').collapse('hide');
      } else if (!this.isDependent) {
        setTimeout(() => {
          $('#collapseBusinessInfo').collapse('toggle');
        });
      }
    } else if (this.titleSteps === 'paso4') {
      this.isFinalFormValid = true;
      this.changeStep(3);
      this.changeStepTitle('thirdStepTitleStep2');
      this.changeUrl('paso3');
      this.showFirstStepOfFinalForm = true;
      this.showSecondStepOfFinalForm = false;
      this.showThirdStepOfFinalForm = false;
    }
  }

  changeToStep1() {
    this.changeStep(1);
    this.changeStepTitle('firstStepTitle');
    this.changeUrl('paso1');

    let keysPersonalInfoForm: IPersonalInfoForm = this.initPersonalInfoForm();
    formMarkAsTouched(this.userForm, false, keysPersonalInfoForm, true);
  }

  changeToStep2() {
    this.changeStep(2);
    this.changeStepTitle('secondStepTitleBusiness');
    this.changeUrl('paso2');

    let keysIndependentInfoForm: IIndependentForm = this.initIndependentForm();
    formMarkAsTouched(this.userForm, false, keysIndependentInfoForm, true);
    this.showGeolocationErrors = false;
    this.showAmount = false;
  }

  validateFormStepThree() {
    const businessForm = {
      businessSalesFrequency: '',
      businessSalesAmount: '',
      businessPurchasesFrequency: '',
      businessPurchasesAmount: '',
      businessAdditionalExpense: '',
    };

    Object.keys(businessForm).forEach((key: any) => {
      if (this.userForm.get(key)?.valid) {
        this.isFinalFormValid = true;
      } else {
        this.isFinalFormValid = false;
      }
    });
  }

  validateFromStepFour() {
    const expenseForm = {
      familyExpense: '',
      additionalLoansAmount: '',
      additionalPayment: '',
    };

    Object.keys(expenseForm).forEach((key: any) => {
      if (this.userForm.get(key)?.valid) {
        this.isFinalFormValid = true;
      } else {
        this.isFinalFormValid = false;
      }
    });
  }

  viewPort() {
    this.breakpointObserver.observe(['(min-width: 768px)']).subscribe((state: BreakpointState) => {
      if (state.breakpoints['(min-width: 768px)']) {
        this.mobileBreakShow = true;
      }
    });

    this.breakpointObserver
      .observe(['(min-width: 300px)', '(max-width: 750px)'])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints['(min-width: 300px)'] && state.breakpoints['(max-width: 750px)']) {
          this.mobileBreakShow = false;
        }
      });
  }

  validateIncome(value: string, date: string): number {
    let income = parseFloat(value);
    if (date === 'Semanales') {
      return income / 7;
    } else if (date === 'Quincenales') {
      return income / 15;
    } else if (date === 'Mensuales') {
      return income / 30;
    }
    return income;
  }

  showHideName() {
    this.showNameMessage = !this.showNameMessage;
  }

  showHideLastName() {
    this.showLastNameMessage = !this.showLastNameMessage;
  }

  toggleBusinessAddressInfo() {
    this.showBusinessAddressInfo ? (this.showBusinessAddressInfo = false) : (this.showBusinessAddressInfo = true);
  }

  closeInfoModal() {
    this.modalService.dismissAll();
  }

  generateDropDownItems(data: Lookup[]) {
    return data.map(
      (data) =>
        ({
          label: data.name,
          value: data.code.toString(),
        } as IDropdownItem)
    );
  }

  private setLocation() {
    this.positionSelected = this.currentPosition;
    this.setAddressFromGeocode(this.positionSelected.getPosition());
    this.googlePlaceSelected = true;
  }

  private setAddressFromGeocode(latLng: any) {
    this.geocoder.geocode(
      {
        location: latLng,
      },
      (results, status) => {
        const result = results[0];
        if (status === google.maps.GeocoderStatus.OK && result) {
          this.latitude = String(result.geometry.location.lat());
          this.longitude = String(result.geometry.location.lng());
          this.userForm.get('address')?.setValue(result.formatted_address);
          this.currentGeolocation = { ...this.newGeolocation };
        }
      }
    );
  }

  private validatePlaceOptionSelected(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.googlePlaceSelected ? null : { googlePlaceSelected: { value: control.value } };
    };
  }

  public updateApprovedPrecreditState(stepNumber: string, stepDescription: string) {
    const data = {
      campaignType: '6',
      formType: FORM_TYPES.CDE,
      stepNumber,
      stepDescription,
      referredCode:this.financingService.defaultRefersData.referredCode,
    };
    this.financingService.updatePrecreditState(this.financingService.idPreCredit?.toString(), data).subscribe();
  }

  private updateIncompleteState(stepNumber: number, label: string) {
    if (isStateUpdateValid(stepNumber, this.currentPrecreditStep)) {
      this.currentPrecreditStep = stepNumber;
      this.updateApprovedPrecreditState(stepNumber.toString(), label);
    }
  }

  private deleteIdPrecredit(): void {
    this.financingService.deletePrecredit(this.financingService.idPreCredit?.toString()).subscribe();
  }
  private getAmountPreApproved(): void {
    if (this.financingService.amountPreApproved) {
      this.amountPreApproved = this.financingService.amountPreApproved;
      this.financingService.amountRequest =
        this.financingService.amountRequest > this.amountPreApproved
          ? this.amountPreApproved
          : this.financingService.amountRequest;
    }
  }
  isAmountInvalid(): boolean {
    const amountControl = this.userForm.get('amount');
    return !!amountControl && amountControl.invalid && amountControl.touched;
  }

  

  private fillAndSaveForm() {
    const DNI = this.userProfile?.documentNumber || this.assessEligibilityExternalRequest?.documentNumber;
    let savedData: any = null;

    if (
      localStorage.getItem('formKey') !== null &&
      localStorage.getItem('formKey') !== undefined &&
      localStorage.getItem('formKey') !== 'undefined'
    ) {
      savedData = this.formDataService.loadData('formKey');
    }
    if (savedData && DNI === savedData?.documentNumber) {
      const email = this.userProfile?.email || this.assessEligibilityExternalRequest?.email;
      const phone = this.userProfile?.phone || this.assessEligibilityExternalRequest?.phone;

      savedData.email = email;
      savedData.phone = phone;

      if (savedData.address) {
        this.googlePlaceSelected = true;
      }

      this.userForm.patchValue(savedData);
      this.fillSelectModal(savedData);
      setTimeout(() => {
        this.userForm.valueChanges.subscribe((values) => {
          this.formDataService.saveData(this.userForm, 'formKey');
        });
      }, 1000);
    } else {
      this.formDataService.clearData('formKey');
      setTimeout(() => {
        this.userForm.valueChanges.subscribe((values) => {
          this.formDataService.saveData(this.userForm, 'formKey');
        });
      }, 1000);
    }
  }

  fillSelectModal(data: any) {
    try {
      if (data.timeContact && this.timeContactList.length > 0) {
        this.timeContactSelected =
          this.timeContactList.find((item: any) => item.label === data.timeContact) ?? this.timeContactList[0];
      }

      if (data.maritalStatus && this.maritalStatusList.length > 0) {
        this.maritalStatusSelected =
          this.maritalStatusList.find((item: any) => item.label === data.maritalStatus) ?? this.maritalStatusList[0];
        this.getSpouseInfo();
        if(data.typeDocumentSpouse) {
          this.documentTypeSpouseSelected = this.documentTypeList.find(e => e.value === data.typeDocumentSpouse) ?? this.documentTypeList[0]; 
          this.userForm.get('typeDocumentSpouse')?.setValue(this.documentTypeSpouseSelected?.value || '');
          this.changeValidatorsSpouseDocumentNumber();
        }
      }

      if (data.economicActivity && this.economicActivityList.length > 0) {
        this.economicActivitySelected =
          this.economicActivityList.find((item: any) => item.label === data.economicActivity) ??
          this.economicActivityList[0];
      }

      if (data.helpWithFormSelected && this.helpWithForm.length > 0) {
        this.helpWithFormSelected =
          this.helpWithForm.find((item: any) => item.label === data.helpWithFormSelected) ?? this.helpWithForm[0];
      }

      if (data.department || data.province || data.district) {
        this.locations = {
          department: {
            label: data.department,
            value: (data.ubigeo ?? this.ubigeo ?? '').substr(2, 2),
          },
          province: {
            label: data.province,
            value: (data.ubigeo ?? this.ubigeo ?? '').substr(4, 2),
          },
          district: {
            label: data.district,
            value: (data.ubigeo ?? this.ubigeo ?? '').substr(6, 2),
          },
        };
      }

      if (data.businessSalesFrequency && this.businessFrequencyList.length > 0) {
        this.businessFrequencySelected =
          this.businessFrequencyList.find((item: any) => item.label === data.businessSalesFrequency) ??
          this.businessFrequencyList[0];
      }
      if (data.housingType && this.houseTypes.length > 0) {
        this.houseTypeSelected =
          this.houseTypes.find((item: any) => item.label === data.housingType) ?? this.houseTypes[0];
      }
      if(data.businessPurchasesFrequency && this.businessPurchasesFrequencyList.length > 0) {
        this.businessPurchasesFrequencySelected = 
          this.businessPurchasesFrequencyList.find((item: any) => item.label === data.businessPurchasesFrequency) ?? this.businessPurchasesFrequencyList[0];
      }
    } catch (error) {
     console.log(error); 
    }
  }
}

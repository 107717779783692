import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { AuthService } from '@shared/services/auth.service';
import { LocalStorageService } from '@shared/services/local-storage.service';

import { environment } from '@environments/environment';
import { catchError, delay, map, retryWhen, switchMap, take } from 'rxjs/operators';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalBlanketerrorComponent } from '@shared/components/modal-blanketerror/modal-blanketerror.component';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  newRefreshToken: boolean = false;
  verifyRefresh: boolean = false;
  retryCount: boolean = false;

  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {

        if (
          (error.status === 401 || error.error.statusCode === 401) &&
          (error.error.code == 'expiredTokenApplication' || error.error?.message == 'Token inválido')
        ) {
          const refreshToken = this.localStorageService.get('refresh_token');

          if (refreshToken) {
            this.newRefreshToken = true;
            return this.authService.refreshToken(refreshToken).pipe(
              switchMap((resp: any) => {
                const accessToken = resp.access_token ?? resp.accessToken;
                this.localStorageService.set('access_token', accessToken);

                const applicationToken = resp.application_token ?? resp.applicationToken;
                this.localStorageService.set('application_token', applicationToken);

                if (resp.refreshToken) {
                  this.localStorageService.set('refresh_token', resp.refreshToken);
                }
                this.authService.refreshTokenSessionWithoutRedirect(resp);
                this.headersRefreshToken(request);

                return next.handle(request);
              })
            );
          }

        } else if (
          error.status === 401 &&
          (error.error.code == 'expiredRefreshTokenApplication' ||
            error.error.code == 'invalidTokenApplication' ||
            error.error.message == 'token de aplicación expirado.')
        ) {
          this.removeSession();
          return throwError(error);
        } else if (error.status === 500) {
          this.modalService.dismissAll();

          this.modalService.open(ModalBlanketerrorComponent, this._modalOptionsNormal);
          if (!this.retryCount) {
            return next.handle(request).pipe(
              retryWhen((errors) =>
                errors.pipe(
                  delay(30000),
                  take(1),
                  map(() => (this.retryCount = true))
                )
              )
            );
          } else {
            return throwError(error);
          }
        }

        if (this.localStorageService.get('refresh_token')) {
          return next.handle(request);
        } else {
          return throwError(error);
        }
      })
    );
  }

  headersRefreshToken(req: any) {
    let headers = req.headers
      .set('Ocp-Apim-Subscription-Key', environment.subscriptionKey)
      .set('Ocp-Apim-Trace', environment.trace)
      .set('X-Frame-Options', 'DENY')
      .set('Content-Security-Policy', "default-src 'self';");

    const accessToken = this.localStorageService.get('access_token');
    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    const applicationToken = this.localStorageService.get('application_token');
    if (applicationToken) {
      headers = headers.set('application_token', applicationToken);
    }

    const newReq = req.clone({
      headers,
    });

    return newReq;
  }

  removeSession() {
    this.localStorageService.remove('refresh_token');
    this.authService.logout();
  }
}

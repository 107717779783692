import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DirectoryService } from 'src/app/shared/services/directory.service';
import { ExistingDirectoryResolved } from '../models/DirectoryModel';
import { forkJoin, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, mergeMap, take } from 'rxjs/operators';

export const ExistingDirectoryResolverKey = 'ExistingDirectoryResolved';

@Injectable({
  providedIn: 'root',
})
export class ExistingDirectoryResolver implements Resolve<ExistingDirectoryResolved | null> {
  constructor(private directoryService: DirectoryService, private authService: AuthService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.userProfile$.pipe(
      take(1),
      mergeMap((user) =>
        user?.hasDirectory
          ? forkJoin([
              this.directoryService.getDirectoryById(user?.directoryId),
              this.directoryService.getUserDirectoryImages(user?.directoryId),
            ]).pipe(map(([directoryData, images]) => ({ directoryData, images })))
          : of(null)
      )
    );
  }
}

import { Component } from '@angular/core';
import { EVENT_VALUES } from '@shared/constants/points-programs';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'milab-page-points-program',
  templateUrl: './page-points-program.component.html',
  styleUrls: ['./page-points-program.component.scss']
})
export class PagePointsProgramComponent {
  constructor(private dataLayerService: DataLayerService) { }

  eventclicked() {
    this.dataLayerService.programPointsEvent(EVENT_VALUES.codeActionOne, EVENT_VALUES.labelSelected + EVENT_VALUES.labelSeePoints);
  }
}

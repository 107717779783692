import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LocationInformation } from 'src/app/shared/models/DirectoryModel';

interface ILocationFormFields {
  businessName?: string;
  businessHours?: string;
  department?: string;
  province?: string;
  district?: string;
  isPhysicalAddressChecked?: boolean;
}

@Component({
  selector: 'yevo-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
  @Input() locations?: LocationInformation[];
  @Output() stateAfterFieldBlur = new EventEmitter();

  frmLocations!: FormArray;

  public get frmLocationsFormGroups(): FormGroup[] {
    return this.frmLocations.controls as FormGroup[];
  }

  public get canContinueAdding(): boolean {
    return this.frmLocations.length <= 5;
  }

  public get canContinueDeleting(): boolean {
    return this.frmLocations.length > 1;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.frmLocations = this.fb.array([]);
    this.subscribeFormChanges();
    this.loadData();
  }

  private subscribeFormChanges() {
    this.frmLocations.valueChanges.subscribe((formValue) => {
      this.stateAfterFieldBlur.emit(formValue);
    });
  }

  private subscribeFormChangesFormGroup(form: FormGroup) {
    form.valueChanges.subscribe((formValue) => {
      if (formValue.department) {
        form.get('department')?.markAsTouched();
      }
      if (formValue.province) {
        form.get('province')?.markAsTouched();
      }
      if (formValue.district) {
        form.get('district')?.markAsTouched();
      }
    });
  }

  isDirectionTextValid(): boolean {
    for (let i = 0; i < this.frmLocationsFormGroups.length; i++) {
      const isValid =
        this.isDirectionChecked(i) || (!this.isDirectionChecked(i) && this.isDirectionText(i)?.length > 0);
      if (!isValid) return false;
    }
    return true;
  }

  addLocation(locationFields?: ILocationFormFields): void {
    this.frmLocations.push(this.initLocation(locationFields!));
    this.subscribeFormChangesFormGroup(this.frmLocationsFormGroups[this.frmLocations.length - 1]);
  }

  removeLocation(index: number): void {
    this.frmLocations.removeAt(index);
  }

  private initLocation(locationFields: ILocationFormFields): FormGroup {
    return this.fb.group(
      {
        department: [locationFields?.department, [Validators.required]],
        province: [locationFields?.province, [Validators.required]],
        district: [locationFields?.district, [Validators.required]],
        businessName: [locationFields?.businessName, [Validators.maxLength(100)]],
        businessHours: [locationFields?.businessHours, [Validators.maxLength(100), Validators.required]],
        isPhysicalAddressChecked: [locationFields?.isPhysicalAddressChecked],
      },
      { updateOn: 'blur' }
    );
  }

  private loadData(): void {
    if (this.locations && this.locations?.length > 0) {
      this.locations.forEach((location) => {
        this.addLocation({
          department: location.ubigeo?.substr(2, 2) || '',
          province: location.ubigeo?.substr(4, 2) || '',
          district: location.ubigeo?.substr(6, 2) || '',
          businessName: location?.businessName,
          businessHours: location?.businessHours,
          isPhysicalAddressChecked: !location?.businessName,
        });
      });
    } else {
      this.addLocation();
    }
  }

  isDirectionChecked(index: number) {
    return this.frmLocationsFormGroups[index].get('isPhysicalAddressChecked')?.value;
  }

  isDirectionText(index: number) {
    return this.frmLocationsFormGroups[index].get('businessName')?.value;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarouselConfig, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { isSafariBrowser } from '@shared/utils/validators.utils';

@Component({
  selector: 'yevo-rotative-banner',
  templateUrl: './rotative-banner.component.html',
  styleUrls: ['./rotative-banner.component.scss']
})
export class RotativeBannerComponent implements OnInit {
  @Input() loader: boolean = false;
  @Input() banners: any = [];
  @Output() bannerClicked = new EventEmitter();
  mediaHeight: string = '325px';

  constructor(private config: NgbCarouselConfig, private router: Router) { }

  ngOnInit(): void {
    this.setMediaHeight();
  }

  goToBanner(banner: any) {
    if (banner.external) {
      if (isSafariBrowser()) {
        window.location.href = banner.routerLink;
      } else {
        window.open(banner.routerLink, '_blank');
      }
    } else {
      this.router.navigate([banner.routerLink]);
    }

    this.bannerClicked.emit(banner);
  }

  setMediaHeight() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 1439 && screenWidth > 575) {
      this.mediaHeight = '244px';
    } else if (screenWidth <= 575) {
      this.mediaHeight = '325px';
    }
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT) {
      // Si la diapositiva cambió debido a la navegación con flechas
      console.log('La diapositiva se cambió con flechas. Índice de la diapositiva actual:', slideEvent.current);
      console.log('Número de orden del banner:', this.banners[slideEvent.current].order);
    } else if (slideEvent.source === NgbSlideEventSource.INDICATOR) {
      // Si la diapositiva cambió debido a los indicadores de navegación
      console.log('La diapositiva se cambió con indicadores. Índice de la diapositiva actual:', slideEvent.current);
      console.log('Número de orden del banner:', this.banners[slideEvent.current].order);
    }
  }
}

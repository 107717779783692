import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Comment, VideoBusiness } from '@shared/interfaces/frontend/communities';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataLayerService } from '@shared/services/data-layer.service';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { UserProfile } from '@shared/models/UserProfileModel';
import { CommunityService } from '@shared/services/community.service';
import { VIDEOS_VALUES } from '@shared/constants/communities.constants';

declare let YT: any;

@Component({
  selector: 'yevo-videos-communities-detail',
  templateUrl: './videos-communities-detail.component.html',
  styleUrls: ['./videos-communities-detail.component.scss'],
})
export class VideosCommunitiesDetailComponent implements OnInit, OnDestroy {
  @Input()
  video!: VideoBusiness;
  safeResourceUrl!: SafeResourceUrl;
  getListCommentVideo: any[] = [];

  commentSent = false;
  comment = '';
  commentRequest: Comment = {
    comment: '',
    userName: '',
    commentId: 0,
    creationDate: '',
    id: 0,
  };
  subscription = new Subscription();
  user!: UserProfile;
  sourceVideo = VIDEOS_VALUES.sourceDefaul;
  private player: any;
  constructor(
    private domSanitizer: DomSanitizer,
    private router: Router,
    public dataLayerService: DataLayerService,
    private localStorageService: LocalStorageService,
    private communityService: CommunityService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.communityService.videoSubjectSearch$.subscribe(
      (response) => {
        this.video = response;
        this.sourceVideo = this.validSourceVideo(this.video.source, VIDEOS_VALUES.sourceUserSelected);
      }
    );
    if (!this.video) {
      this.subscription.add(
        this.activatedRoute.params.subscribe((params) => {
          this.communityService.getVideoBusinessId(params.id).subscribe((response) => {
            this.video = response;
            this.getListCommentVideo = response.comment;
            this.safeResourceUrl = this.getTrustedVideoURL(this.video.videoUrl);
            this.covertDate();
          });
        })
      );
    } else {
      this.getListCommentVideo = this.video.comment;
      this.safeResourceUrl = this.getTrustedVideoURL(this.video.videoUrl);
      this.covertDate();
    }
  }

  private covertDate(): void {
    this.video.creationDate = format(new Date(this.video.creationDate), 'd MMM');
    this.onYouTubeIframeAPIReady();
  }

  getTrustedVideoURL(videoURL: string): SafeResourceUrl {
    if (videoURL.startsWith('https://www.youtube.com/watch?v=')) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(videoURL.replace('watch?v=', 'embed/'));
    } else if (videoURL.startsWith('https://youtu.be')) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(
        videoURL.replace('https://youtu.be', 'https://www.youtube.com/embed/')
      );
    } else {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(videoURL);
    }
  }

  handleIFrameClick(videoTitle: string): void {
    this.dataLayerService.pushTrainingVideoClick(videoTitle);
  }

  createComment(): void {
    const userString = this.localStorageService.get('userProfile');
    if (userString && !this.commentSent) {
      this.user = JSON.parse(userString) as UserProfile;
      this.commentRequest.userName = `${this.user.name} ${this.user.fatherLastName} ${this.user.motherLastName}`;
      this.commentRequest.comment = this.comment;
      this.commentRequest.id = this.video.id;
      this.subscription.add(
        this.communityService.createComment(this.commentRequest).subscribe(
          (response) => {
            this.loadVideo();
            this.commentSent = true;
            this.comment = '';
          },
          (error) => {
            console.error(error);
          }
        )
      );
    }
  }

  newComment(): void {
    this.commentSent = false;
  }

  private loadVideo(): void {
    this.subscription.add(
      this.communityService.getVideoBusinessId(this.video.id).subscribe(
        (response) => {
          this.video = response;
          this.getListCommentVideo = response.comment;
          this.covertDate();
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  goHome(): Promise<boolean> {
    return this.router.navigate(['../../..'], { relativeTo: this.activatedRoute });
  }

  goVideos(): Promise<boolean> {
    const parentRoute = this.activatedRoute.parent?.routeConfig?.path;
    return parentRoute === 'emprende-mujer'
      ? this.router.navigate(['nuevo-intranet/comunidades/emprende-mujer', 'videos'], {
          state: { communityId: this.video.communityId },
        })
      : this.router.navigate(['nuevo-intranet/comunidades/bodegueros', 'videos'], {
          state: { communityId: this.video.communityId },
        });
  }

  onYouTubeIframeAPIReady(): void {
    this.player = new YT.Player('player', {
      videoId: this.youTubeGetID(this.video.videoUrl),
      height: '360',
      width: '100%',
      events: {
        onStateChange: this.onPlayerStateChange.bind(this),
      },
    });
  }

  private onPlayerStateChange(event: any): void {
    if (event.data === 0) {
      const userString = this.localStorageService.get('userProfile');
      if (userString) {
        this.user = JSON.parse(userString) as UserProfile;
        this.subscription.add(
          this.communityService.saveUserVideoByView(this.user.userId, this.video.id,this.sourceVideo).subscribe(
            (response) => {},
            (error) => {
              console.error(error);
            }
          )
        );
      }
    }
  }

  private youTubeGetID(url: string): string {
    const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  validSourceVideo(value: string | null | undefined, defaultValue: string): string {
    return value ?? defaultValue;
  }
}

<div class="container-fluid">
  <div class="row">
    <div class="col-12 pl-0 pr-0">
      <yevo-banner-allied></yevo-banner-allied>
    </div>
  </div>
</div>

<div class="container mt-3 mb-5">
  <div class="row">
    <div class="col-12 pl-md-0 pr-md-0 mt-3">
      <img [src]="details.image" alt="image" class="modal-allied-image mb-3 mt-3" />
    </div>

    <div class="col-md-12 modal-allied-line d-flex pl-md-0 pr-md-0">
      <span class="modal-allied-tags mt-3 mb-3" *ngFor="let item of details.tags">
        <img [src]="item.icon" alt="image" class="modal-allied-icon" /> {{ item.title }}</span
      >
    </div>

    <div class="col-12 pl-md-0 pr-md-0">
      <p class="modal-allied-title">
        {{ details.title }}
      </p>
    </div>

    <div class="col-md-12 modal-allied-line pl-md-0 pr-md-0">
      <p class="modal-allied-description mt-4 mb-4">
        {{ details.description }}
      </p>
    </div>

    <div class="col-12 mb-3 pl-md-0 pr-md-0 mb-5 modal-allied-line container-button">
      <button class="btn btn-primary modal-allied-button-catalogue mt-4">
        <span>{{ details.catalogue }}</span>
      </button>
      <button class="btn btn-primary modal-allied-button ml-lg-4 mt-4">
        <span>{{ details.button }}</span>
      </button>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'yevo-bodegueros',
  templateUrl: './bodegueros.component.html',
  styleUrls: ['./bodegueros.component.scss']
})
export class BodeguerosComponent {
  constructor( private activatedRoute: ActivatedRoute) {
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'yevo-dashboard-content-manager',
  templateUrl: './dashboard-content-manager.component.html',
  styleUrls: ['./dashboard-content-manager.component.scss']
})
export class DashboardContentManagerComponent {
  constructor(private activatedRoute: ActivatedRoute) {}
}

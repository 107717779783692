import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataLayerService } from '@shared/services/data-layer.service';
import { ModalTermsPriceSearchComponent } from '../modal-terms-price-search/modal-terms-price-search.component';
import { TermsConditions } from '@shared/interfaces/frontend/communities';
import termsAndConditionsJson from '@shared/data/communities/terms-and-conditions.json';

@Component({
  selector: 'yevo-price-finder-section',
  templateUrl: './price-finder-section.component.html',
  styleUrls: ['./price-finder-section.component.scss'],
})
export class PriceFinderSectionComponent implements OnInit {
  @Input() title = '';
  @Input() description = '';
  @Input() origin: string = '';
  image_mobile = '';
  alt_image_mobile = '';
  imageDesktop = '';
  termsConditionsReferred!: TermsConditions;

  constructor(
    public modal: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private dataLayerService: DataLayerService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.image_mobile = '/assets/img/communities/bodeguero/price-finder-mobile.png';
    this.alt_image_mobile = 'Buscador de precios';
    this.imageDesktop = '/assets/img/communities/bodeguero/banner-finder-desktop.png';
  }

  goToPriceFinder(): void {
    const parentRoute = this.route.parent?.routeConfig?.path;
    if (parentRoute) {
      this.router.navigate([`/nuevo-intranet/comunidades/${parentRoute}/comparador-precio`]);
      this.dataLayerService.virtualEventBodegueros('Seleccionar: Buscar al mejor precio', 17);
    }
  }

  open() {
    const modalRef = this.modalService.open(ModalTermsPriceSearchComponent, { windowClass: 'modal-basic-title' });
    this.termsConditionsReferred =termsAndConditionsJson.priceFinder;
    modalRef.componentInstance.terms = this.termsConditionsReferred;
  }
}

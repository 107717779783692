import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TopicModel } from '@shared/models/Topic.model';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core';

SwiperCore.use([Pagination, Navigation, Autoplay]);

@Component({
  selector: 'yevo-training-section-list-v1',
  templateUrl: './training-section-list-v1.component.html',
  styleUrls: ['./training-section-list-v1.component.scss'],
})
export class TrainingSectionListV1Component {
  @Input() title: string = '';
  @Input() list: TopicModel[] = [];
  @Input() showAllLink: string[] = null!;

  @Output() clickTopic: EventEmitter<TopicModel> = new EventEmitter();
  page: number = 1;
  endPage: number = 0;

  pageMobile: number = 1;
  endPageMobile: number = 0;

  loader: boolean = false;

  @ViewChild('swiperVideoDashboard', { static: false }) swiperVideoDashboard?: SwiperComponent;
  @ViewChild('swiperVideoDashboardDesktop', { static: false }) swiperVideoDashboardDesktop?: SwiperComponent;

  configSwiperVideo: any = {
    pagination: {
      type: 'fraction',
    },
    slidesPerGroup: 1,
    slidesPerView: 'auto',
    spaceBetween: 0,
    centeredSlides: false,
    initialSlide: 0,
    roundLengths: true,
    loop: true,
    speed: 400,
    autoplay: {
      enabled: true,
      delay: 20000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      360: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      375: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      425: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        initialSlide: 1,
        slidesPerGroup: 1,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
    },
  };

  configSwiperDesktopVideo: any = {
    pagination: {
      type: 'fraction',
    },
    slidesPerGroup: 4,
    spaceBetween: 5,
    loop: true,
    loopFillGroupWithBlank: true,
    speed: 400,
    autoplay: {
      enabled: true,
      delay: 20000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      360: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      375: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      425: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        initialSlide: 0,
        slidesPerGroup: 1,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 0,
        slidesPerGroup: 4,
      },
    },
  };

  constructor() {
    this.loader = true;
  }

  handleClick(topic: TopicModel) {
    this.clickTopic.emit(topic);
  }

  ListPrev() {
    this.swiperVideoDashboardDesktop?.swiperRef.slidePrev(400);
  }

  ListNext() {
    this.swiperVideoDashboardDesktop?.swiperRef.slideNext(400);
  }

  listNextMobile() {
    this.swiperVideoDashboard?.swiperRef.slideNext(400);
  }

  listPrevMobile() {
    this.swiperVideoDashboard?.swiperRef.slidePrev(400);
  }
}

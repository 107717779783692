import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import mailchimpClient from '@mailchimp/mailchimp_transactional';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MailchimpTransactionalService {
  template_names: { [key: string]: string } = {
    'emprende-mujer': '[Comunidades] Bienvenida Emprende Mujer',
    bodegueros: '[Comunidades] Bienvenida bodegueros',
  };

  private readonly directoryBase = `${environment.apiBaseUrl}/api-directory`;

  constructor(private httpClient: HttpClient) {}

  sendEmailTemplate(source: string, email: string, name: string) {
    const client = mailchimpClient(environment.mailchimpTransactional.apiKey);
    const template_name: string = this.template_names[source];
    if (!template_name) {
      return new Promise((_, reject) => {
        reject({
          succces: false,
          message: "template doesn't exist",
        });
      });
    }
    return client.messages.sendTemplate({
      template_name: this.template_names[source],
      template_content: [],
      message: {
        subject: 'Bienvenido a Yevo Comunidades',
        from_email: 'soporte@yevo.pe',
        from_name: 'Yevo Comunidades',
        to: [
          {
            email,
            type: 'to',
          },
        ],
        headers: {},
        important: false,
        track_opens: false,
        track_clicks: false,
        auto_text: false,
        auto_html: false,
        inline_css: false,
        url_strip_qs: false,
        preserve_recipients: false,
        view_content_link: false,
        bcc_address: '',
        tracking_domain: '',
        signing_domain: '',
        return_path_domain: '',
        merge: false,
        merge_language: 'mailchimp',
        global_merge_vars: [],
        merge_vars: [
          {
            rcpt: email,
            vars: [
              {
                name: 'fname',
                content: name,
              },
            ],
          },
        ],
        tags: [],
        subaccount: 'basesubaccount',
        google_analytics_domains: [],
        google_analytics_campaign: '',
        metadata: {
          website: '',
        },
        recipient_metadata: [],
        attachments: [],
        images: [],
      },
      async: false,
      ip_pool: '',
      send_at: '',
    });
  }

  postSavingsEmail(): Observable<any> {
    return this.httpClient.post(`${this.directoryBase}/mailchimp/products/tags/yevo-uso-ahorros`, null);
  }

  postSimulatorEmail(): Observable<any> {
    return this.httpClient.post(`${this.directoryBase}/mailchimp/products/tags/yevo-uso-simulador`, null);
  }
}

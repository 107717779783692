import { Component, HostListener, OnInit,Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialNetwork } from '@shared/interfaces/frontend/communities';
import socialNetworksJson from '@shared/data/communities/social-networks.json';
import { REFERRED_PROGRAM_VALUES, SOCIAL_NETWORKS_VALUES, VIRTUAL_EVENT_VALUES } from '@shared/constants/communities.constants';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-modal-share-link',
  templateUrl: './modal-share-link.component.html',
  styleUrls: ['./modal-share-link.component.scss']
})
export class ModalShareLinkComponent implements OnInit {
  @Input() referredCode: string | undefined;
  @Input() source!: string | undefined;

  baseUrl = ''
  isMobile: boolean = false;
  constructor(public activeModal: NgbActiveModal,private dataLayerService: DataLayerService) { }
  shareLink = '';
  socialNetworks: SocialNetwork[] = [];
  selectedCopyLink: boolean = false;
  messageWhatsapp: string = ' ¡Hola! Si no tienes cuenta en Mibanco Lab, te invito a registrarte y a solicitar un préstamo de manera digital hoy mismo. Utiliza este enlace único de la campaña ';

  ngOnInit(): void {
    this.socialNetworks = socialNetworksJson;
    this.baseUrl = window.location.origin;
    this.shareLink = `${this.baseUrl}${REFERRED_PROGRAM_VALUES.newRegisterRoute}${this.referredCode}`;
    this.checkIfMobile();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkIfMobile();
  }
  checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  validResize(item: any): boolean {
    return (this.isMobile && item.mobile) || (!this.isMobile && item.desktop);
  }

  copyLink() {
    this.dataLayerPush(VIRTUAL_EVENT_VALUES.copyLink);
    navigator.clipboard
      .writeText(this.shareLink)
      .then(() => {
        this.selectedCopyLink = true;
        setTimeout(() => {
          this.selectedCopyLink = false;
        }, 2000);
      })
      .catch((err) => { });
  }

  shareSocialNetworks(name: string) {
    navigator.clipboard.writeText(this.shareLink).then();
    this.dataLayerPush(name);
    switch (name) {
      case SOCIAL_NETWORKS_VALUES.whatsApp: {
        const urlWhatsapp = `${REFERRED_PROGRAM_VALUES.urlShareWhatsApp}${this.messageWhatsapp}${encodeURIComponent(this.shareLink)}`;
        this.redirectAndShare(urlWhatsapp);
        break;
      }
      case SOCIAL_NETWORKS_VALUES.facebook: {
        const urlFacebook = `${REFERRED_PROGRAM_VALUES.urlShareFacebook}${encodeURIComponent(this.shareLink)}`;
        this.redirectAndShare(urlFacebook);
        break;
      }
      case SOCIAL_NETWORKS_VALUES.sms: {
        const urlSms = `${REFERRED_PROGRAM_VALUES.urlShareSms}${encodeURIComponent(this.shareLink)}`;
        this.redirectAndShare(urlSms);
        break;
      }
    }
  }

  redirectAndShare(url: string): void {
    window.open(url, '_blank');
  }

  dataLayerPush(option: string): void {
    if (this.source === REFERRED_PROGRAM_VALUES.woman) {
      this.dataLayerService.virtualEventEM(VIRTUAL_EVENT_VALUES.shareLink+option,VIRTUAL_EVENT_VALUES.selectShareLinkWomanCode);
    } else {
      this.dataLayerService.virtualEventBodegueros(VIRTUAL_EVENT_VALUES.shareLink+option,VIRTUAL_EVENT_VALUES.selectShareLinkWinemakersCode);
    }
  }
}
<div class="swiper-benefit  animate__animated animate__fadeInLeft">
    <div class="swiper-benefit_title">Disfruta tus beneficios y potencia tu negocio</div>
  
    <!-- DESKTOP -->
  <swiper
  #swiperDashboardCourseDesktop
  class="swiperDashboardCourseDesktop col-12 pl-md-0 pr-md-0 training-dashboard-banner d-none d-md-block " style="max-width: 1024px;"
  [config]="configSwiperDesktop"
  [ngClass]="loader ? 'courseSwiper' : 'hiddenSwiper'"
  modules="{[Pagination,Autoplay, Navigation]}"
  *ngIf="banners.length"
  >
  <ng-template swiperSlide *ngFor="let item of banners; let i = index">
      <ngx-skeleton-loader
      *ngIf="!loader"
      [appearance]="'line'"
      count="1"
      animation="pulse"
      [theme]="{
        'background-color': '#F5F5F5',
        'max-width': '262.5px',
        width: '100%',
        height: '211px',
        'border-radius': '16px',
        'margin-bottom': 0,
        'padding-bottom': 0
      }"
    ></ngx-skeleton-loader>
      <yevo-card-swiper-benefit [banner]="item"></yevo-card-swiper-benefit>
  </ng-template>
  </swiper>
  
  
  <div class="d-none d-md-block arrow-mobile-training">
    <div class=" arrow-mobile-training__container">
      <div class="training-dashboard-desktop__container_arrow" [ngClass]="loader ? 'mt-3' : 'mt-2 bg-white'" [ngStyle]="{ 'top.px': '210' }">
        <img
          [src]="loader ? '/assets/icons/arrow-next.svg' : '/assets/icons/next-grey.png'"
          class="training-dashboard-desktop_arrow_left"
          (click)="listPrevDesktop()"
          alt="arrow left"
        />
        <p class="training-dashboard-desktop_arrow mb-0"></p>
          <img
          [src]="loader ? '/assets/icons/arrow-next.svg' : '/assets/icons/next-grey.png'"
          class="training-dashboard-desktop_arrow_right"
          (click)="listNextDesktop()"
          alt="arrow right"
        />
      </div>
    </div>
  </div>
  
  <!-- END DESKTOP -->
  
    <!-- MOBILE -->
    <swiper #swiperDashboard class="swiper-container col-12 training-dashboard-banner d-block d-md-none"
    [config]="configSwiper" modules="{[Pagination,Autoplay, Navigation]}" *ngIf="banners.length" (slideChange)="onSlideChange()">
    <ng-template swiperSlide *ngFor="let item of banners; let i = index">
      <yevo-card-swiper-benefit [banner]="item"></yevo-card-swiper-benefit>
    </ng-template>
  </swiper>

  <div class="d-block d-md-none arrow-mobile-training ">
    <div class=" arrow-mobile-training__container">
      <div class="training-dashboard__container_arrow" [ngClass]="loader ? 'mt-3' : 'mt-2 bg-white'">
        <img
          [src]="loader ? '/assets/icons/arrow-next.svg' : '/assets/icons/next-grey.png'"
          class="training-dashboard_arrow_left"
          (click)="listPrevMobile()"
          alt="arrow left"
        />
        <p class="training-dashboard__arrow mb-0">{{this.page}} / <span class="training-dashboard__arrow_span">{{banners.length}}</span></p>
        <img
          [src]="loader ? '/assets/icons/arrow-next.svg' : '/assets/icons/next-grey.png'"
          class="training-dashboard_arrow_right"
          (click)="listNextMobile()"
          alt="arrow right"
        />
      </div>
    </div>
  </div>
 <!-- MOBILE END -->

  </div>
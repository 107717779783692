import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'yevo-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent implements OnInit {
  @Input() openInfoModal: boolean = false;
  @Input() title: string = '';
  @Input() titleModal: string = '';
  @Input() descriptionModal: string = '';
  @Input() isLabelInfo: boolean = false;

  @ViewChild('modalInfoContent', { static: true }) modalInfoContent!: TemplateRef<any>;

  modalReference!: NgbModalRef;

  _modalOptionsNormal: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms',
    backdrop: 'static',
    keyboard: false,
  };

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    //DO NOTHING
  }

  open() {
    this.modalService.open(this.modalInfoContent, this._modalOptionsNormal);
  }

  public dismiss(): void {
    this.modalService.dismissAll();
  }

  public close(): void {
    this.modalService.dismissAll();
  }
}

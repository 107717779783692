import { Component, EventEmitter, Input, Output } from '@angular/core';
import { tabItemData } from '@shared/data/training/course.json';
@Component({
  selector: 'yevo-training-virtual-courses',
  templateUrl: './training-virtual-courses.component.html',
  styleUrls: ['./training-virtual-courses.component.scss'],
})
export class TrainingVirtualCoursesComponent {
  @Input() seletecTab: any;
  @Input() allCourses: any;
  @Output() goAllCourses = new EventEmitter<any>();

  tab: any = tabItemData[2];

  showAllCourses() {
    this.tab.active = true;
    this.goAllCourses.emit(this.tab);
  }
}

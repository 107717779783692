<div class="checkbox">
  <input
    type="checkbox"
    class="custom-control-input"
    [id]="keyValue['key']"
    [value]="keyValue['value']"
    [checked]="checked"
    [disabled]="isDisabled"
    (change)="onCheckedChange($event)"
  />
  <div class="box">
    <svg-icon-sprite
      *ngIf="aditionalInfo?.isSvg"
      [src]="aditionalInfo?.icon"
      [width]="aditionalInfo?.iconWidth || '3rem'"
      class="mx-2 mt-1"
    ></svg-icon-sprite>
    <img
      *ngIf="!aditionalInfo?.isSvg"
      [src]="'/assets/icons/intranet/logos/' + aditionalInfo?.icon"
      alt="icono"
      class="mx-2"
    />
    <p [attr.data-text]="label || keyValue['key']" class="mr-1">{{ aditionalInfo?.key || label || keyValue['key'] }}</p>
  </div>
</div>

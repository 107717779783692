import { Component,Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsConditions } from '@shared/interfaces/frontend/communities';

@Component({
  selector: 'yevo-modal-terms-price-search',
  templateUrl: './modal-terms-price-search.component.html',
  styleUrls: ['./modal-terms-price-search.component.scss']
})
export class ModalTermsPriceSearchComponent {

  @Input() terms!:TermsConditions;


  constructor(public activeModal: NgbActiveModal){}
}

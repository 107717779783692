<section class="questions-section">
    <div class="cards-container">
      <div>
        <div *ngFor="let detail of listQuestions; let index = index" class="card-accordeon">
          <div class="card-accordeon_header">
            <h5 class="mb-0">
              <button class="btn btn-link" (click)="questionOnClick(index)" [attr.aria-expanded]="isVisible[index]">
                {{ detail.question }}
                <img [ngClass]="{'rotate-icon': isVisible[index]}" class="ml-2" src="assets/icons/communities/down.svg" alt="open_and_close">
              </button>
            </h5>
          </div>
          <div class="collapse" [ngbCollapse]="!isVisible[index]">
            <div class="card-accordeon_body" [innerHTML]="detail.answer"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
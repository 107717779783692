import { Component, EventEmitter, Input, Output } from '@angular/core';
import { tabItemData } from '@shared/data/training/course.json';
import { TopicModel } from '@shared/models/Topic.model';

@Component({
  selector: 'yevo-training-webinars',
  templateUrl: './training-webinars.component.html',
  styleUrls: ['./training-webinars.component.scss'],
})
export class TrainingWebinarsComponent {
  @Input() topics: TopicModel[] = [];

  @Output() clickTopic = new EventEmitter<any>();
  @Output() clickAllTopics = new EventEmitter<any>();

  tab = tabItemData[4];

  showTopic(topic: TopicModel) {
    this.clickTopic.emit(topic);
  }

  showAllTopics() {
    this.tab.active = true;
    this.clickAllTopics.emit(this.tab);
  }
}

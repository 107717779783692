import { LocalStorageService } from './../services/local-storage.service';
import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isRefreshing: boolean = false;

  constructor(private localStorageService: LocalStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers
      .set('Ocp-Apim-Subscription-Key', environment.subscriptionKey)
      .set('Ocp-Apim-Trace', environment.trace)
      .set('X-Frame-Options', 'DENY')
      .set('Content-Security-Policy', "default-src 'self';");

    const accessToken = this.localStorageService.get('access_token');
    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    const applicationToken = this.localStorageService.get('application_token');
    if (applicationToken) {
      headers = headers.set('application_token', applicationToken);
    }

    const newReq = req.clone({
      headers,
    });

    return next.handle(newReq);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VIRTUAL_EVENT_VALUES_REMITTANCES } from '@shared/constants/remittances.constant';
import { RemittancesDetailModel, RemittancesModel } from '@shared/models/remittances.model';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'frequent-questions',
  templateUrl: './landing-remittances-frequent-questions.component.html',
  styleUrls: ['./landing-remittances-frequent-questions.component.scss']
})
export class LandingRemittancesFrequentQuestionsComponent implements OnInit {

    @Input() selectedFaq!: RemittancesModel;
    selectedFaqChild: number | null = null;
    @Input() isVisible: boolean[] = [];
    @Output() questionClick = new EventEmitter<string>();

  
    constructor(private dataLayerService: DataLayerService){}
    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }
  
    public get faqDetails(): RemittancesDetailModel[] {
      return this.selectedFaq?.details.slice().sort(
        (prev, curr) => prev.order - curr.order
      );
    }
  
    questionOnClick(index: number , title:string ) {
      this.dataLayerPush(title);
      this.isVisible[index] = !this.isVisible[index];
      for (let i = 0; i < this.isVisible.length; i++) {
        if (index != i) {
          this.isVisible[i] = false;
        }
      }
      if (this.isVisible[index]) {
        this.questionClick.emit(this.faqDetails[index].title);
      }
    }

    dataLayerPush(option: string): void {
      this.dataLayerService.virtualEventEnlaceREM(VIRTUAL_EVENT_VALUES_REMITTANCES.selectQuestionsRemittancesProgramPage + option, VIRTUAL_EVENT_VALUES_REMITTANCES.selectSectionAgenceBtnQuestions);
    }
  
}
<fieldset [formGroup]="frmProductInfo">
  <div class="form-row">
    <div class="form-group col-12">
      <label>Escribe los productos que ofreces</label>
      <textarea
        class="form-control"
        placeholder="Ingresa la lista de productos que ofreces en tu negocio. Ejemplo: Pantalones, Shorts, Camisas"
        formControlName="productDescription"
        maxlength="350"
        [ngClass]="{
          'border-error':
            (errorCode === 'textContentModerator' && errorMsgType === 'products') ||
            frmProductInfo.get('productDescription')?.errors?.pattern ||
            (frmProductInfo.get('productDescription')?.errors?.required && showError('productDescription'))
        }"
        (change)="avoidEmpty($event, 'productDescription')"
      ></textarea>
      <label class="form-inline">
        <div *ngIf="showError('productDescription')" class="text-error">
          <div *ngIf="frmProductInfo.get('productDescription')?.errors?.required">Lista de productos es requerida.</div>
          <div *ngIf="frmProductInfo.get('productDescription')?.errors?.pattern">Ingrese una descripción valida</div>
        </div>
      </label>
    </div>

    <div class="form-group col-12">
      <legend>¿A qué categorías pertenece tu negocio?</legend>
      <div class="form-row">
        <div *ngFor="let category of categories | keyvalue" class="col-6">
          <yevo-checkbox
            [keyValue]="category"
            (onCheckedChanged)="onCategoryCheckChanged($event)"
            [checked]="isChecked(category.value, 'categories')"
            [isDisabled]="maxSelected(category.value)"
          ></yevo-checkbox>
        </div>
      </div>
      <label class="form-inline">
        <div *ngIf="frmProductInfo.get('categories')?.errors?.required" class="text-error">
          Seleccione al menos una categoría.
        </div>
      </label>
    </div>

    <div class="form-group col-12">
      <legend>¿Qué métodos de pago aceptas?*</legend>

      <div class="checkboxes-container">
        <yevo-checkbox-v2
          *ngFor="let paymentMethod of paymentMethods | keyvalue: originalOrder"
          [keyValue]="paymentMethod"
          (onCheckedChanged)="onPaymentMethodCheckChanged($event)"
          [checked]="isChecked(paymentMethod.value, 'paymentMethods')"
          [aditionalInfo]="getAditionalInfo(paymentMethod)"
        ></yevo-checkbox-v2>
      </div>

      <label class="form-inline">
        <div *ngIf="frmProductInfo.get('paymentMethods')?.errors?.required" class="text-error">
          Seleccione al menos un método de pago.
        </div>
      </label>

      <div *ngIf="showTextOption()" class="form-group col-12 pl-0">
        <input
          type="text"
          class="form-control"
          placeholder="Ingresa aquí otro método de pago"
          formControlName="otherPaymentMethod"
          maxlength="50"
        />
        <label class="form-inline">
          <div
            *ngIf="showError('otherPaymentMethod') || !frmProductInfo.get('otherPaymentMethod')?.value"
            class="text-error"
          >
            <div *ngIf="frmProductInfo.get('otherPaymentMethod')?.errors?.required">
              Nombre de otro tipo de pago es requerido.
            </div>
          </div>
        </label>
      </div>
    </div>

    <div class="form-group col-12">
      <legend>¿Qué tipo de entrega realizas?*</legend>

      <div class="custom-control-inline">
        <div class="checkbox">
          <input type="checkbox" class="custom-control-input" id="homeDelivery" formControlName="homeDelivery" />
          <div class="box">
            <svg-icon-sprite [src]="'car-icon'" [width]="'21px'" class="mr-3"></svg-icon-sprite>
            <p [attr.data-text]="'Entrega a Domicilio'" class="mr-2">
              Entrega a <br class="d-none d-md-block" />
              domicilio
            </p>
          </div>
        </div>
      </div>
      <div class="custom-control-inline">
        <div class="checkbox">
          <input type="checkbox" class="custom-control-input" id="pickUpSalePoint" formControlName="pickUpSalePoint" />
          <div class="box">
            <svg-icon-sprite [src]="'house-icon'" [width]="'21px'" class="mr-3"></svg-icon-sprite>
            <p [attr.data-text]="'Recojo en Punto de Venta'" class="mr-2">
              Recojo en punto <br class="d-none d-md-block" />
              de venta
            </p>
          </div>
        </div>
      </div>
      <label class="form-inline">
        <div *ngIf="!isDeliveryValid()" class="text-error pt-2">
          <div>Seleccione al menos un modo de entrega.</div>
        </div>
      </label>
    </div>
  </div>
</fieldset>

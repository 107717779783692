import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PricesMarketsResponse } from '@shared/interfaces/frontend/communities';
import { CommunityService } from '@shared/services/community.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { LocalStorageService } from '@shared/services/local-storage.service';

@Component({
  selector: 'yevo-price-finder-result',
  templateUrl: './price-finder-result.component.html',
  styleUrls: ['./price-finder-result.component.scss'],
})
export class PriceFinderResultComponent implements OnInit {
  disableButton: boolean = true;
  dataResult: PricesMarketsResponse[] = [];
  dateConsult: string = '';
  notData!: boolean;
  error!: string;
  loading = false;
  formattedDate!: string;
  elementos: string = '';
  elementOfPage = 10;
  elementVisible: PricesMarketsResponse[] = [];
  countProduct: number = 0;
  moreProduct: boolean = true;
  time!: string;
  minutes!: string;
  day!: string;
  month!: string;
  user: any = '';
  idSection: string = 'price-finder-section';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private dataLayerService: DataLayerService,
    private communityServer: CommunityService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.notData = true;
    this.getProduct();
    this.getDay();
    const userProfile = this.localStorageService.get('userProfile');
    if (userProfile) {
      this.user = JSON.parse(userProfile);
    }
  }

  getProduct() {
    this.communityServer.obsFormPriceSearch$.subscribe(
      (response) => {
        if (response.length > 0 || this.dataResult.length !== 0) {
          this.dataResult = this.sortList(response);
          this.getMoreElement();
          this.loading = true;
          this.notData = false;
        } else {
          this.loading = false;
          this.notData = true;
        }
      },
      (error) => {
        this.error = error;
        this.loading = false;
        this.notData = true;
      }
    );
  }
  sortList(list: PricesMarketsResponse[]) {
    const copyList = [...list];
    let exchange: boolean;
    do {
      exchange = false;
      for (let i = 0; i < copyList.length - 1; i++) {
        if (copyList[i].productVarietyName > copyList[i + 1].productVarietyName) {
          const temp = copyList[i];
          copyList[i] = copyList[i + 1];
          copyList[i + 1] = temp;
          exchange = true;
        }
      }
    } while (exchange);

    return copyList;
  }

  getDay() {
    const currentDate = new Date();
    this.formattedDate = this.formatDate(currentDate);
  }

  formatDate(date: Date): string {
    const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const hour = hours % 12 || 12;
    if (hour <= 9) {
      this.time = '0' + hour;
    } else {
      this.time = '' + hour;
    }
    if (dayOfMonth <= 9) {
      this.day = '0' + dayOfMonth;
    } else {
      this.day = '' + dayOfMonth;
    }
    if (month <= 9) {
      this.month = '0' + month;
    } else {
      this.month = '' + month;
    }
    if (minutes <= 9) {
      this.minutes = '0' + minutes;
    } else {
      this.minutes = '' + minutes;
    }
    return `${dayOfWeek}, ${this.day}/${this.month}/${year} , ${this.time}:${this.minutes} ${ampm}`;
  }
  returnConsult(): void {
    const parentRoute = this.route.parent?.routeConfig?.path;
    if (parentRoute) {
      this.dataLayerService.virtualEventBodegueros('Página de gracias: Realizar nueva consulta', 18);
      this.router.navigate([`/nuevo-intranet/comunidades/${parentRoute}/comparador-precio`]);
    }
  }

  getMoreElement() {
    this.countProduct += 10;
    if (this.countProduct > this.dataResult.length) {
      this.countProduct = this.dataResult.length;
      this.moreProduct = false;
    }
    const startIndex = this.elementVisible.length;
    const endIndex = startIndex + this.elementOfPage;
    if (endIndex <= this.dataResult.length) {
      this.elementVisible = this.dataResult.slice(0, endIndex);
    } else {
      this.elementVisible = this.dataResult;
    }
  }
  goHomeSearchPrice(): void {
    this.dataLayerService.virtualEventBodegueros('Página de gracias: Ir al inicio', 18);
    this.router.navigate(['..'], { relativeTo: this.route }).then(() => {
      const element = document.getElementById(this.idSection);
      if (element) {
        element.scrollIntoView();
      }
    });
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'yevo-certified-section',
  templateUrl: './certified-section.component.html',
  styleUrls: ['./certified-section.component.scss'],
})
export class CertifiedSectionComponent {
  constructor(private route: ActivatedRoute, private router: Router, public dataLayerService: DataLayerService) {}
  goToCertified(): void {
    const parentRoute = this.route.parent?.routeConfig?.path;
    if (parentRoute) {
      this.router.navigate([`/nuevo-intranet/comunidades/${parentRoute}/certified`]);
      if (parentRoute === 'emprende-mujer') {
        this.dataLayerService.virtualEventEM('Seleccionar: Ver mis certificados', 16);
      } else {
        this.dataLayerService.virtualEventBodegueros('Seleccionar: Ver mis certificados', 15);
      }
    }
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CATEGORY as CATEGORY_BODEGUEROS } from '@shared/data/communities/category-bodegueros.json';
import { CATEGORY } from '@shared/data/communities/category.json';
import {
  CANAL as CANAL_BODEGUEROS,
  COMPETITION as COMPETITION_BODEGUEROS,
  PEOPLE as PEOPLE_BODEGUEROS,
  QUESTION as QUESTION_BODEGUEROS,
} from '@shared/data/communities/question-bodegueros.json';
import { CANAL, COMPETITION, PEOPLE, QUESTION } from '@shared/data/communities/question-communities.json';
import {
  Answer,
  Categories,
  CategoryTemp,
  CertifiedResponse,
  Comment,
  Communities,
  Community,
  Course,
  Forum,
  ForumRequest,
  LevelRequest,
  LevelResponse,
  LevelResult,
  NotificationMessage,
  PersonalizerBusinessVideoResponse,
  PersonalizerRecomedationsRequest,
  PricesMarketsResponse,
  Question,
  Questions,
  ReferredCodeResponse,
  ReferredProgram,
  ReferredResponse,
  RegisterMemberRequest,
  RewardRequest,
  Testimonial,
  ToolResponse,
  Training,
  UserCommunity,
  UserCreditEM,
  UserLevel,
  VideoBusiness,
  VideoViewLevelResponse,
  VideoViewedResponse,
  WebinarResponse,
  WebinarViewLevelResponse,
  WebinarViewedResponse,
} from '@shared/interfaces/frontend/communities';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommunityService {
  private localStorageKey = 'datosMidagri';

  //private readonly contentManagerApi = `http://localhost:9995/`;
  private readonly contentManagerApi = `${environment.apiBaseUrl}/api-community/`;
  private params: HttpParams = new HttpParams();

  private formDataSubject = new BehaviorSubject<any>(null);
  obsFormPriceSearch$ = this.formDataSubject.asObservable();

  private videoSubject = new BehaviorSubject<any>(null);
  videoSubjectSearch$ = this.videoSubject.asObservable();

  private formDataGenericSubject = new BehaviorSubject<any>(null);
  obsFormDataGeneric$ = this.formDataGenericSubject.asObservable();

  private formUtilitySubject = new BehaviorSubject<any>(null);
  obsFormUtilitySearch$ = this.formUtilitySubject.asObservable();

  private communityIdSubject = new BehaviorSubject<any>(null);
  communityIdSearch$ = this.communityIdSubject.asObservable();
  private isActiveCommunitySeccion = new BehaviorSubject<boolean>(false);
  activeCommunity = this.isActiveCommunitySeccion.asObservable();

  constructor(private httpClient: HttpClient) {}

  changeCommunitySection(value: boolean) {
    this.isActiveCommunitySeccion.next(value);
  }

  getVideoBusiness(communityId: number): Observable<VideoBusiness[]> {
    this.removeParams();
    this.params = this.params.set('communityId', communityId.toString());
    return this.httpClient.get<VideoBusiness[]>(`${this.contentManagerApi}business`, { params: this.params });
  }

  getForum(communityId: number): Observable<Forum[]> {
    this.removeParams();
    this.params = this.params.set('communityId', communityId.toString());
    return this.httpClient.get<Forum[]>(`${this.contentManagerApi}forum`, { params: this.params });
  }

  createQuestion(forumRequest: ForumRequest): Observable<string> {
    return this.httpClient.post<string>(`${this.contentManagerApi}forum`, forumRequest);
  }

  createAnswer(answerRequest: Answer): Observable<string> {
    return this.httpClient.post<string>(`${this.contentManagerApi}answer`, answerRequest);
  }

  getWebinar(communityId: number): Observable<Training[]> {
    this.removeParams();
    this.params = this.params.set('communityId', communityId.toString());
    return this.httpClient.get<Training[]>(`${this.contentManagerApi}webinar`, { params: this.params });
  }

  getForumId(id: number): Observable<Forum> {
    return this.httpClient.get<Forum>(`${this.contentManagerApi}forum/${id}`);
  }

  createComment(comment: Comment): Observable<Comment> {
    return this.httpClient.post<Comment>(`${this.contentManagerApi}comment`, comment);
  }

  getVideoBusinessId(id: number): Observable<VideoBusiness> {
    return this.httpClient.get<VideoBusiness>(`${this.contentManagerApi}business/${id}`);
  }

  getTestimonial(communityId: number): Observable<Testimonial[]> {
    this.removeParams();
    this.params = this.params.set('communityId', communityId.toString());
    return this.httpClient.get<Testimonial[]>(`${this.contentManagerApi}testimonial`, { params: this.params });
  }

  getUserCommunity(id: number, communityId: number): Observable<UserCommunity> {
    this.removeParams();
    this.params = this.params.set('communityId', communityId.toString());
    return this.httpClient.get<UserCommunity>(`${this.contentManagerApi}user/${id}`, { params: this.params });
  }

  createUserCommunity(user: UserCommunity): Observable<UserCommunity> {
    return this.httpClient.post<UserCommunity>(`${this.contentManagerApi}user`, user);
  }

  getCourse(): Observable<Course[]> {
    return this.httpClient.get<Course[]>(`${this.contentManagerApi}course`);
  }

  getCommunityByCode(code: string): Observable<Community> {
    this.removeParams();
    const params = { code };
    return this.httpClient.get<Community>(`${this.contentManagerApi}`, { params });
  }

  getVideosCategoryStatistics(communityId: number): Observable<Categories[]> {
    return this.httpClient.get<Categories[]>(`${this.contentManagerApi}category/videos/${communityId}/statistic`);
  }

  getWebinarsCategoryStatistics(communityId: number): Observable<Categories[]> {
    return this.httpClient.get<Categories[]>(`${this.contentManagerApi}category/webinars/${communityId}/statistic`);
  }

  getVideoByCommunityAndCategory(communityId: number, categoryId: number): Observable<VideoBusiness[]> {
    this.removeParams();
    this.params = this.params.set('communityId', communityId.toString());
    this.params = this.params.set('categoryId', categoryId.toString());
    return this.httpClient.get<VideoBusiness[]>(`${this.contentManagerApi}business/category`, { params: this.params });
  }

  getWebinarByCommunityAndCategory(communityId: number, categoryId: number): Observable<Training[]> {
    this.removeParams();
    this.params = this.params.set('communityId', communityId.toString());
    this.params = this.params.set('categoryId', categoryId.toString());
    return this.httpClient.get<Training[]>(`${this.contentManagerApi}webinar/category`, { params: this.params });
  }

  getWebinarId(id: number): Observable<Training> {
    return this.httpClient.get<Training>(`${this.contentManagerApi}webinar/${id}`);
  }

  getCategories(): Observable<Categories[]> {
    return this.httpClient.get<Categories[]>(`${this.contentManagerApi}category`);
  }

  getQuestion(): Question[] {
    return QUESTION;
  }

  getQuestionPeople(): Question[] {
    return PEOPLE;
  }
  getQuestionCanal(): Question[] {
    return CANAL;
  }

  getQuestionCompetition(): Question[] {
    return COMPETITION;
  }
  getCategoryTemp(): CategoryTemp[] {
    return CATEGORY;
  }

  getQuestionBodegueros(): Question[] {
    return QUESTION_BODEGUEROS;
  }
  getQuestionPeopleBodegueros(): Question[] {
    return PEOPLE_BODEGUEROS;
  }
  getQuestionCanalBodegueros(): Question[] {
    return CANAL_BODEGUEROS;
  }

  getQuestionCompetitionBodegueros(): Question[] {
    return COMPETITION_BODEGUEROS;
  }
  getCategoryTempBodeguero(): CategoryTemp[] {
    return CATEGORY_BODEGUEROS;
  }

  getLevelQuestion(levelRequest: LevelRequest): Observable<LevelResponse> {
    return this.httpClient.post<LevelResponse>(`${this.contentManagerApi}level`, levelRequest);
  }
  saveLevelUser(userLevel: UserLevel): Observable<UserLevel> {
    return this.httpClient.post<UserLevel>(`${this.contentManagerApi}level/user`, userLevel);
  }
  getLevelUser(userId: number): Observable<UserLevel> {
    return this.httpClient.get<UserLevel>(`${this.contentManagerApi}level/user/${userId}`);
  }
  getVideoByLevel(idLevel: number): Observable<VideoBusiness[]> {
    return this.httpClient.get<VideoBusiness[]>(`${this.contentManagerApi}business/level/${idLevel}`);
  }

  getWebinarByLevel(idLevel: number): Observable<Training[]> {
    return this.httpClient.get<Training[]>(`${this.contentManagerApi}webinar/level/${idLevel}`);
  }
  updateLevelUser(userId: number, communityId: number): Observable<UserLevel> {
    return this.httpClient.get<UserLevel>(`${this.contentManagerApi}level/analytics/${userId}/${communityId}`);
  }

  private removeParams(): void {
    this.params.delete('communityId');
    this.params.delete('categoryId');
  }

  getCommunityByCommunityId(communityId: number): Observable<Communities> {
    return this.httpClient.get<Communities>(`${this.contentManagerApi}community/${communityId}`);
  }
  getLevelUserByCommunityId(userId: number, communityId: number): Observable<UserLevel> {
    return this.httpClient.get<UserLevel>(`${this.contentManagerApi}level/user/${userId}/community/${communityId}`);
  }
  getQuestionsByCommunityId(communityId: number): Observable<Questions[]> {
    this.removeParams();
    this.params = this.params.set('communityId', communityId.toString());
    return this.httpClient.get<Questions[]>(`${this.contentManagerApi}questions`, { params: this.params });
  }
  getLevelQuestionByCommunityId(optionQuestion1: number, optionQuestion2: number): Observable<LevelResult> {
    return this.httpClient.post<LevelResult>(`${this.contentManagerApi}level/find-result`, {
      optionQuestion1,
      optionQuestion2,
    });
  }
  getWebinarByLevelAndCommunityId(idLevel: number, communityId: number): Observable<Training[]> {
    return this.httpClient.get<Training[]>(
      `${this.contentManagerApi}webinar/level/${idLevel}/community/${communityId}`
    );
  }
  getWebinarsByCommunityId(communityId: number): Observable<Training[]> {
    return this.httpClient.get<Training[]>(`${this.contentManagerApi}webinar/community/${communityId}`);
  }
  getVideosByCommunityId(communityId: number): Observable<VideoBusiness[]> {
    return this.httpClient.get<VideoBusiness[]>(`${this.contentManagerApi}business/community/${communityId}`);
  }
  getVideoByLevelAndCommunityId(idLevel: number, communityId: number): Observable<VideoBusiness[]> {
    return this.httpClient.get<VideoBusiness[]>(
      `${this.contentManagerApi}business/level/${idLevel}/community/${communityId}`
    );
  }
  saveUserVideoByView(userId: number, videoId: number, source: string): Observable<VideoViewLevelResponse> {
    return this.httpClient.post<VideoViewLevelResponse>(`${this.contentManagerApi}view/video-level`, {
      userId,
      videoId,
      source,
    });
  }

  saveUserWebinarByView(userId: number, webinarId: number): Observable<WebinarViewLevelResponse> {
    return this.httpClient.post<WebinarViewLevelResponse>(`${this.contentManagerApi}view/webinar-level`, {
      userId,
      webinarId,
    });
  }

  getVideosViewedByUserid(userId: number): Observable<VideoViewedResponse[]> {
    return this.httpClient.get<VideoViewedResponse[]>(`${this.contentManagerApi}video-view-level/user/${userId}`);
  }

  getWebinarsViewedByUserid(userId: number): Observable<WebinarViewedResponse[]> {
    return this.httpClient.get<WebinarViewedResponse[]>(`${this.contentManagerApi}webinar-view-level/user/${userId}`);
  }
  checkIfCreditoMujer(userId: string): Observable<UserCreditEM[]> {
    return this.httpClient.get<UserCreditEM[]>(`${this.contentManagerApi}user-credit/${userId}`);
  }
  getCertifiedByUserAndCommunity(documentNumber: string, communityId: number): Observable<CertifiedResponse[]> {
    return this.httpClient.get<CertifiedResponse[]>(
      `${this.contentManagerApi}certificate/${documentNumber}/communityId/${communityId}`
    );
  }
  getLiveIfExists(communityId: number): Observable<WebinarResponse> {
    return this.httpClient.get<WebinarResponse>(`${this.contentManagerApi}webinar/live/community/${communityId}`);
  }
  getWebinarsToActive(): Observable<WebinarResponse[]> {
    return this.httpClient.get<WebinarResponse[]>(`${this.contentManagerApi}webinar/live/to-active`);
  }
  activeWebinar(webinarId: number, url: string) {
    return this.httpClient.put(`${this.contentManagerApi}webinar/activate/${webinarId}`, { url });
  }

  getAllPriceMarkets(): Observable<PricesMarketsResponse[]> {
    const datosEnLocalStorage = localStorage.getItem(this.localStorageKey);
    const fechaAlmacenada = localStorage.getItem('dataMidagriLocal');

    if (datosEnLocalStorage && fechaAlmacenada) {
      const datos = JSON.parse(datosEnLocalStorage);
      const fechaActual = new Date().toISOString().split('T')[0];
      if (fechaAlmacenada === fechaActual) {
        return of(datos);
      }
    }
    return this.httpClient.get<PricesMarketsResponse[]>(`${this.contentManagerApi}midagri/price-markets`).pipe(
      tap((data) => {
        localStorage.setItem(this.localStorageKey, JSON.stringify(data));
        localStorage.setItem('dataMidagriLocal', new Date().toISOString().split('T')[0]);
      })
    );
  }

  getToolsByCommunityId(communityId: number): Observable<ToolResponse[]> {
    this.removeParams();
    this.params = this.params.set('communityId', communityId.toString());
    return this.httpClient.get<ToolResponse[]>(`${this.contentManagerApi}tool`, { params: this.params });
  }

  sendFormPriceMarkets(data: any) {
    this.formDataSubject.next(data);
  }

  sendRewardToPersonalizer(reward: RewardRequest): Observable<string> {
    return this.httpClient.post<string>(`${this.contentManagerApi}personalizer/reward`, reward);
  }

  registerClientWithToken(token: string, topic: string, userId: number): Observable<string> {
    return this.httpClient.post<string>(`${this.contentManagerApi}notification/token`, { token, topic, userId });
  }

  sendNotification(notificationMessage: NotificationMessage): Observable<string> {
    return this.httpClient.post<string>(`${this.contentManagerApi}notification/send`, notificationMessage);
  }

  sendVideoCommnunity(data: any) {
    this.videoSubject.next(data);
  }

  sendCommnunityId(id: number) {
    this.communityIdSubject.next(id);
  }

  getPersonalizerVideoByCommunityIdAndUserId(
    communityId: number,
    userId: number
  ): Observable<PersonalizerBusinessVideoResponse> {
    return this.httpClient.get<PersonalizerBusinessVideoResponse>(
      `${this.contentManagerApi}personalizer/videos/community/${communityId}/user/${userId}`
    );
  }

  savePersonalizerRecomedations(
    personalizerRecomedationsRequest: PersonalizerRecomedationsRequest
  ): Observable<string> {
    return this.httpClient.post<string>(
      `${this.contentManagerApi}personalizer/recomedations/save`,
      personalizerRecomedationsRequest
    );
  }

  registerJourneyMember(registerMemberRequest: RegisterMemberRequest): Observable<string> {
    return this.httpClient.post<string>(`${this.contentManagerApi}mailchimp/register`, registerMemberRequest);
  }

  registerReferredProgram(userId: number): Observable<string> {
    return this.httpClient.post<string>(`${this.contentManagerApi}referred-program/register`, { userId });
  }

  getReferredProgramByUserId(id: number): Observable<ReferredProgram> {
    return this.httpClient.get<ReferredProgram>(`${this.contentManagerApi}referred-program/user/${id}`);
  }

  createReferred(userId: number, codeReferred: string): Observable<string> {
    return this.httpClient.post<string>(`${this.contentManagerApi}referred-program/referred`, { userId, codeReferred });
  }

  getUserByReferredCode(code: string): Observable<ReferredCodeResponse> {
    return this.httpClient.get<ReferredCodeResponse>(`${this.contentManagerApi}referred-program/referred-code/${code}`);
  }

  getReferredByUser(id: number): Observable<ReferredResponse> {
    return this.httpClient.get<ReferredResponse>(`${this.contentManagerApi}referred-program/referred/${id}`);
  }

  updateStateReferred(userId: number, event: string): Observable<string> {
    return this.httpClient.post<string>(`${this.contentManagerApi}referred-program/referred/update`, { event, userId });
  }
  updateReferredProgram(userId: number, typeScore: number): Observable<string> {
    return this.httpClient.post<string>(`${this.contentManagerApi}referred-program/update`, { typeScore, userId });
  }

  updateFormData(data: any) {
    this.formDataGenericSubject.next(data);
  }
  
  clearFormData() {
    this.formDataGenericSubject.next(null);
  }
}

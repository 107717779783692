import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmptyError, of, throwError } from 'rxjs';
import { catchError, concatMap, take } from 'rxjs/operators';

import {
  BUTTON_MARKS_LABELS,
  DASHBOARD_ROUTE,
  FINANCING_TYPES,
  FORM_TYPES,
  LOGIN_ROUTE,
  MARKS_PAGE_TYPES,
  MESSAGE_TYPES,
  NEGATIVE_ERROR_CODE,
  STATE_MARKS_LABELS,
  messageTypes,
} from '@shared/constants/financing.constants';
import { REGISTER_SOURCE_CODE } from '@shared/constants/register.constants';
import messageData from '@shared/data/financing/message-data-general.json';
import { DOCUMENTTYPELIST, DOCUMENTTYPELIST_DNI_ID } from '@shared/data/login/login.json';
import { formMarkAsTouched, getErrorsFromField, showError } from '@shared/utils/form.utils';
import { EMAIL_VALIDATORS, PASSWORD_VALIDATORS } from '@shared/utils/validators.utils';

import { AuthService } from '@shared/services/auth.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { FinancingService } from '@shared/services/financing.service';
import { RegisterService } from '@shared/services/register.service';

import { IDropdownItem, ISelectItem } from '@shared/interfaces/IDropdownItem';
import { IFinancingMessage } from '@shared/interfaces/frontend';
import { Login } from '@shared/models/login.model';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { SeedCashService } from '@shared/services/seed-cash.service';
import { UserInteractionLogService } from '@shared/services/user-interaction-log.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RegisterErrorModalComponent } from 'src/app/register/register-error-modal/register-error-modal.component';

@Component({
  selector: 'yevo-financing-request-message',
  templateUrl: './financing-request-message.component.html',
  styleUrls: ['./financing-request-message.component.scss'],
})
export class FinancingRequestMessageComponent implements OnInit {
  @ViewChild('email') emailTextInput!: ElementRef<HTMLInputElement>;

  @Input() messageType: messageTypes = 'not-sent';
  @Input() isLogged: boolean = false;
  @Input() kushka: boolean = false;
  @Input() unsuccess: boolean = false;
  @Input() username: string = '';
  @Input() showRegister: boolean = false;
  @Input() typeForm: string = '';

  currentLabel!: string;
  currentRoute!: string[];
  pageType!: string;

  userMessage: string = this.username
    ? `¡${this.username}, ${FINANCING_TYPES.MESSAGE_USER}!`
    : `¡${FINANCING_TYPES.MESSAGE_USER}!`;

  messageInformation!: IFinancingMessage;
  registryForm!: FormGroup;
  loginForm!: FormGroup;
  name: string = this.financingService.sucessFinancingSolicitudeRegister?.name;
  email: string = '';
  documentNumber!: string;
  typeDocument!: string;
  phone!: string;
  estado: string = '';
  isAffiliate: boolean = false;
  loginError?: string | null;

  closeResult = '';
  ip: string = '';

  documentTypeList: IDropdownItem[] = DOCUMENTTYPELIST;
  documentTypeSelected: IDropdownItem = this.documentTypeList[0];

  showErrorMessage = false;
  showPassword: boolean = false;
  isLoaded: boolean = false;

  formGeneral: boolean = false;
  isFinanceRejectRequest: boolean = false;
  user: any;

  constructor(
    private dataLayerService: DataLayerService,
    private router: Router,
    private registerService: RegisterService,
    private financingService: FinancingService,
    private authService: AuthService,
    private userInteractionLogService: UserInteractionLogService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private seedCashService: SeedCashService,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    private localStorageService: LocalStorageService
  ) {
    const state = window.history?.state;
    if (state?.isFinancingGeneral) {
      this.formGeneral = true;
    } else {
      this.formGeneral = false;
    }
    const userProfile = this.localStorageService.get('userProfile');
    if (userProfile) {
      this.user = JSON.parse(userProfile);
    }
  }

  ngOnInit(): void {
    const state = window.history?.state;
    this.getTypeFormFinance(state);
    this.getUsername();
    const message = this.getMessageInformation(this.messageType);
    this.messageInformation = { ...message };

    this.messageInformation.title = this.replaceUsername(this.messageInformation.title, this.username || this.name);
    this.messageInformation.name = this.replaceUsername(this.messageInformation.name, this.username || this.name);

    if (this.kushka || this.unsuccess) this.getRedirectionLabels();
    this.validateStep();
    this.pageType = this.getPageTypeForMarks();
    this.addStatusQueryParams();
    this.checkAffiliate();
  }
  getTypeFormFinance(state: any) {
    if (state?.isFinancingGeneral) {
      this.typeForm = 'general';
    }
    if (state?.isFinancingCde) {
      this.typeForm = 'cde';
    }
    if (state?.isFinancingAprobado) {
      this.typeForm = 'aprobadodigital';
    }
    if (state?.isFinancingPreaprobado) {
      this.typeForm = 'preaprobadodigital';
    }
    if (state?.isFinancingYape) {
      this.typeForm = 'yape';
    }
  }

  private getUsername(): void {
    this.name = this.financingService.sucessFinancingSolicitudeRegister?.name;
    if (this.isLogged) {
      this.username = this.name || this.user.name || 'Hola';
    } else if (this.username === null || this.username === undefined || this.username === '') {
      this.financingService.isExternalFinancing = true;
      this.username = this.financingService.sucessFinancingSolicitudeRegister?.name || 'Hola';
    }
  }
  private getMessageInformation(messageType: string): IFinancingMessage {
    if (messageType == MESSAGE_TYPES.SENT_MESSAGE && this.typeForm === FORM_TYPES.YAPE && !this.unsuccess) {
      return messageData.SENT_MESSAGE_YAPE;
    }

    if (messageType == MESSAGE_TYPES.SENT_MESSAGE && this.typeForm === FORM_TYPES.ALICORP && !this.unsuccess)
      return messageData.ALICORP_SENT_MESSAGE;

    if (messageType == MESSAGE_TYPES.SENT_MESSAGE && !this.unsuccess) return messageData.SENT_MESSAGE;

    if (messageType == MESSAGE_TYPES.NOT_SENT_MESSAGE && !this.unsuccess) return messageData.NOT_SENT_MESSAGE;
    if (messageType == MESSAGE_TYPES.ALREADY_SENT_MESSAGE && !this.unsuccess) return messageData.ALREADY_SENT_MESSAGE;

    if (messageType == MESSAGE_TYPES.YAPE_NOT_OFFER && !this.unsuccess) {
      this.typeForm = FORM_TYPES.YAPE.toLowerCase();
      return messageData.YAPE_NOT_OFFER;
    }
    if (messageType == MESSAGE_TYPES.YAPE_REJECTED && !this.unsuccess) {
      this.typeForm = FORM_TYPES.YAPE.toLowerCase();
      return messageData.YAPE_REJECTED;
    }
    if (messageType == MESSAGE_TYPES.YAPE_NOT_OFFER_CLIENT && !this.unsuccess) {
      this.typeForm = FORM_TYPES.YAPE.toLowerCase();
      return messageData.YAPE_NOT_OFFER_CLIENT;
    }

    if (messageType == MESSAGE_TYPES.YAPE_NOT_CAMPAIGN && !this.unsuccess) {
      this.typeForm = FORM_TYPES.YAPE.toLowerCase();
      return messageData.YAPE_NOT_CAMPAIGN;
    }
    if (this.unsuccess) {
      this.isFinanceRejectRequest = true;
      return messageData.FINANCING_REQUEST_REJECTED;
    }

    return messageData.SENT_MESSAGE;
  }
  private getPageTypeForMarks(): string {
    if (this.isLogged) {
      return this.messageType == MESSAGE_TYPES.SENT_MESSAGE
        ? MARKS_PAGE_TYPES.SUCCESS_LOGGED
        : MARKS_PAGE_TYPES.UNSUCCESS_LOGGED;
    } else {
      return this.messageType == MESSAGE_TYPES.SENT_MESSAGE
        ? MARKS_PAGE_TYPES.SUCCESS_NOT_LOGGED
        : MARKS_PAGE_TYPES.UNSUCCESS_NOT_LOGGED;
    }
  }

  /** VALIDATION */
  private validateStep(): void {
    if (this.financingService.assessEligibilityExternalRequest) {
      this.email = this.financingService.assessEligibilityExternalRequest?.email;
      this.documentNumber = this.financingService.assessEligibilityExternalRequest?.documentNumber;
      this.typeDocument = this.financingService.assessEligibilityExternalRequest?.typeDocument;
      this.phone = this.financingService.assessEligibilityExternalRequest?.phone;
    } else if (this.seedCashService.leadData) {
      this.email = this.seedCashService.leadDataInvalidEligibility?.email ?? this.seedCashService.leadData?.txtCorreo;
      this.documentNumber =
        this.seedCashService.leadDataInvalidEligibility?.documentNumber ?? this.seedCashService.leadData?.txtDocumento;
      this.typeDocument = DOCUMENTTYPELIST_DNI_ID;
      this.phone = this.seedCashService.leadDataInvalidEligibility?.phone ?? this.seedCashService.leadData?.txtCelular;
    }

    if (
      this.email &&
      this.documentNumber &&
      this.typeDocument &&
      this.phone &&
      this.financingService.isExternalFinancing
    ) {
      this.isLoaded = true;
      this.initForm();
      this.financingService.isExternalFinancing = false;
    }
  }

  /** REGISTRY METHODS */
  private initForm(): void {
    this.registryForm = this.formBuilder.group({
      email: [this.email, EMAIL_VALIDATORS()],
      password: ['', PASSWORD_VALIDATORS()],
      acceptTC: [false, [Validators.required, Validators.requiredTrue]],
      acceptPersonalData: [false, [Validators.required, Validators.requiredTrue]],
      shareThirdParties: [false],
      recaptcha: [null],
    });
  }
  public register(): void {
    if (this.registryForm.invalid) {
      let keys = this.initRegistryFieldsReference();
      formMarkAsTouched(this.registryForm, true, keys, true);
      this.pushMarks(BUTTON_MARKS_LABELS.CREATE_ACCOUNT, STATE_MARKS_LABELS.REGISTRY_INTENTION);
      return;
    }
    this.showErrorMessage = false;
    this.registerService.clientData = this.getRegistryPayload();
    this.registerUserWithActivation();
  }
  private initRegistryFieldsReference(): any {
    return {
      email: '',
      password: '',
      acceptTC: '',
      acceptPersonalData: '',
      shareThirdParties: '',
      recaptcha: '',
    };
  }
  private getRegistryPayload(): any {
    return {
      documentNumber: this.documentNumber,
      typeDocument: this.typeDocument,
      phone: this.phone,
      name: this.financingService.sucessFinancingSolicitudeRegister?.name,
      fatherLastName: this.financingService.sucessFinancingSolicitudeRegister?.fatherLastName,
      motherLastName: this.financingService.sucessFinancingSolicitudeRegister?.motherLastName,
      ...this.registryForm.value,
      source: REGISTER_SOURCE_CODE.FINANCING,
    };
  }
  private registerUserWithActivation(): void {
    this.authService
      .registerWithActivation(this.registerService.clientData)
      .pipe(
        take(1),
        catchError((err) => {
          if (err?.error?.code === NEGATIVE_ERROR_CODE) {
            this.modalService.open(RegisterErrorModalComponent);
          } else {
            this.showErrorMessage = true;
          }

          window.scrollTo(0, 0);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.pushMarks(BUTTON_MARKS_LABELS.CREATE_ACCOUNT, STATE_MARKS_LABELS.SUCCESS_REGISTRY);
        this.registerService.clientData = this.registerService.defaultClientData;
        this.financingService.isExternalFinancing = false;
        this.router.navigate(['/ingresar'], { queryParamsHandling: 'merge' });
      });
  }

  public getRegisterCaptcha() {
    this.recaptchaV3Service.execute('register').subscribe((token: string = '') => {
      this.registryForm.get('recaptcha')?.setValue(token);
      this.register();
    });
  }

  public getLoginCaptcha() {
    this.recaptchaV3Service.execute('register').subscribe((token: string = '') => {
      this.loginForm.get('recaptcha')?.setValue(token);
      this.login();
    });
  }

  /** LOGIN METHODS */
  checkAffiliate() {
    if (this.documentNumber) {
      this.authService
        .checkDocument(this.documentNumber)
        .pipe(take(1))
        .subscribe(
          (resp) => {
            this.isAffiliate = true;
            this.documentTypeSelected = this.documentTypeList.filter((item) => item.value === this.typeDocument)[0];
            this.buildLoginForm();
            this.isLoaded = true;
          },
          (error) => {
            this.financingService.assessEligibilityExternalRequest.typeDocument = this.documentNumber;
            this.isAffiliate = false;
            this.documentTypeSelected = this.documentTypeList.filter((item) => item.value === this.typeDocument)[0];
            this.showRegister = true;
            this.unsuccess = true;
          }
        );
    } else {
      this.isLoaded = true;
    }
  }

  buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      documentNumber: [this.documentNumber, [Validators.required, Validators.pattern('[0-9]{8}')]],
      password: [null, [Validators.required, Validators.maxLength(30)]],
      rememberMe: [true],
      recaptcha: [null],
    });
  }

  private get loginInfo(): Login {
    return this.loginForm.value as Login;
  }

  getIP() {
    fetch('https://api.ipify.org/?format=json', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        this.ip = `${data.ip}`;
      });
  }

  login(): void {
    this.dataLayerService.pushLoginAttempt();
    this.loginError = null;

    this.authService
      .login(this.loginInfo, this.ip)
      .pipe(concatMap((response) => (response ? this.authService.getUserProfile() : of(EmptyError))))
      .subscribe(
        (response) => {
          if (response) {
            this.dataLayerService.pushLoginSuccess();
            this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
              this.dataLayerService.pushUserSession(user?.userId!, user?.businessName!);
              this.userInteractionLogService.logUserAction(user?.documentNumber!, 'click', 'botón de logeo', 'Login');
            });
            this.router.navigate(['/'], { queryParamsHandling: 'merge' });
          }
        },
        (err) => {
          if (err?.error?.code === 'userNotActivated') {
            const validateActivate = err.error.message;
            const validateNavigation = validateActivate.split('-');
            if (validateNavigation[2] === 'sms') {
              this.router.navigate(['verificacion-de-activacion/sms'], {
                queryParams: { email: err.error.message },
                queryParamsHandling: 'merge',
              });
            } else {
              this.router.navigate(['verificacion-de-activacion'], {
                queryParams: { email: err.error.message },
                queryParamsHandling: 'merge',
              });
            }
          } else {
            this.loginError = err.error.message;
          }
        }
      );
  }

  private addStatusQueryParams(): void {
    if (this.isLogged) {
      return;
    }

    if (this.messageType === 'sent' || this.messageType === 'already-sent') {
      this.estado = 'solicitudexitosa';
    } else {
      this.estado = 'solicitudsinoferta';
    }

    if (!this.hasStatusParam() && !this.documentNumber) {
      if (!this.unsuccess) {
        this.router.navigate(['/'], { queryParamsHandling: 'merge' });
      }
    } else if (this.hasStatusParam()) {
      this.showRegister = true;
    } else {
      this.showRegister = true;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { estado: this.estado },
        queryParamsHandling: 'merge',
      });
    }
  }

  /** BUTTONS ACTIONS */
  public goToHome(): void {
    this.pushMarks(BUTTON_MARKS_LABELS.GO_HOME, STATE_MARKS_LABELS.NOT_AVAILABLE);
    this.router.navigate(['/'], { queryParamsHandling: 'merge' });
  }
  public findAgencies(): void {
    this.pushMarks(BUTTON_MARKS_LABELS.FIND_AGENCIES, STATE_MARKS_LABELS.NOT_AVAILABLE);
    window.open('https://www.mibanco.com.pe/ubicanos', '_blank');
  }
  public open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-term', size: 'md', centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  public closeModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /** MARKS */
  public pushMarks(option: string, state: string): void {
    this.dataLayerService.pushThanksPageEvent(this.pageType, option, state);
  }

  /** KUSHKA */
  private getRedirectionLabels(): void {
    this.currentLabel = this.isLogged ? FINANCING_TYPES.DASHBOARD_LABEL : FINANCING_TYPES.LOGIN_LABEL;
    this.currentRoute = this.isLogged ? DASHBOARD_ROUTE : LOGIN_ROUTE;
  }
  public buttonClickPushEvent(): void {
    this.dataLayerService.pushLoginSolicitudeMessage();
    this.router.navigate(this.currentRoute, { queryParamsHandling: 'merge' });
  }

  /** UTILS */
  public getDocumentTypeEvent(event: ISelectItem): void {
    this.documentTypeSelected = event.item!;
  }
  public showError(field: string, dirty: boolean = false): boolean | undefined {
    return showError(field, dirty, this.registryForm);
  }
  public getErrorsFromField(field: string): ValidationErrors | null | undefined {
    return getErrorsFromField(field, this.registryForm);
  }
  public showHidePassword(event: any): void {
    this.showPassword = event;
  }
  private replaceUsername(title: string, userName: string): string {
    if (this.messageType === MESSAGE_TYPES.NOT_SENT_MESSAGE && !userName) {
      return title.replace('@name', userName.trim());
    } else if (userName !== null && userName !== undefined && userName !== '') {
      return title.replace('@name', userName.trim());
    } else if (
      this.messageType === MESSAGE_TYPES.ALREADY_SENT_MESSAGE &&
      this.isLogged &&
      (userName === null || userName === undefined || userName === '')
    ) {
      this.username = this.user.name;
      return title.replace('@name', `${this.username}`);
    }

    return title.replace('@name', 'Hola');
  }
  private hasStatusParam() {
    const params = this.route.snapshot.queryParams;
    if (params['estado']) {
      return true;
    }
    return false;
  }
}

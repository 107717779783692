import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDate',
})
export class ShortDatePipe implements PipeTransform {
  transform(value: string): string {
    const dateString = value.split('/');
    const date = new Date(`${dateString[1]}/${dateString[0]}/${dateString[2]}`);
    const day = date.getDate().toString().padStart(2, '0');
    const month = this.getMonthName(date.getMonth());
    return `${day} ${month}`;
  }

  private getMonthName(month: number): string {
    const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    return months[month];
  }
}

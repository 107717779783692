import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DownloadResponse } from '@shared/interfaces/frontend/';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  private readonly contentManager = `${environment.apiBaseUrl}/contentManager`;
  private readonly directoryBase = `${environment.apiBaseUrl}/api-directory`;
  private readonly urlRequest = `${environment.apiBaseUrl}/api-directory/courses`;
  private readonly contentTrainingApi = `${environment.apiBaseUrl}/api-directory`;
  private readonly host = `${environment.apiBaseUrl}`;

  defaultCourseData: any = [];
  examData: any;
  certificateUsername: string = '';
  courseViewId: any;

  public lessonActive = new BehaviorSubject<boolean>(false);
  lessonActiveUpdate = this.lessonActive.asObservable();

  public lastLesson = new BehaviorSubject<any>(null);
  lastLessonUpdate = this.lastLesson.asObservable();

  private isActiveDownloadSeccion = new BehaviorSubject<boolean>(false);
  activeDownload = this.isActiveDownloadSeccion.asObservable();

  constructor(private httpClient: HttpClient) {}

  changeDownloadSection(value: boolean) {
    this.isActiveDownloadSeccion.next(value);
  }

  changeLesson(value: boolean) {
    this.lessonActive.next(value);
  }

  changeLastLesson(value: any) {
    this.lastLesson.next(value);
  }

  getAllVideo(): Observable<any> {
    return this.httpClient.get(`${this.contentManager}/topic/video/all`);
  }

  getAllVideoPhenomenonChild(): Observable<any> {
    return this.httpClient.get(`${this.contentManager}/topic/code/3018`);
  }

  getAllCategoryPhenomenonChild(): Observable<any> {
    return this.httpClient.get(`${this.contentManager}/advice`);
  }

  getAllCourses(): Observable<any> {
    return this.httpClient.get(`${this.directoryBase}/courses/all`);
  }

  getCourseBySlug(slug: string): Observable<any> {
    return this.httpClient.get(`${this.host}/courses/slug/${slug}`);
  }

  getAllCoursesPublic(): Observable<any> {
    return this.httpClient.get(`${this.host}/courses/all`);
  }

  postCourseById(courseId: number): Observable<any> {
    return this.httpClient.post(`${this.directoryBase}/courses/${courseId}/enroll`, {});
  }

  getAllComments(id: number): Observable<any> {
    return this.httpClient.get(`${this.urlRequest}/${id}/comments`);
  }

  addCommentById(comentario: {}, id: number): Observable<any> {
    return this.httpClient.post(`${this.urlRequest}/${id}/comments`, comentario);
  }

  putLessonCompleted(id: number, lessonId: number): Observable<any> {
    return this.httpClient.put(`${this.directoryBase}/courses/enrollments/${id}/lessons/${lessonId}/complete`, {});
  }

  putCourseCompleted(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.directoryBase}/courses/enrollments/${id}/complete`, data);
  }

  postLessonCompleteView(id: number, lessonId: number): Observable<any> {
    return this.httpClient.post(`${this.urlRequest}/${id}/lessons/${lessonId}/view`, {});
  }

  getToolsTraining(): Observable<DownloadResponse[]> {
    return this.httpClient.get<DownloadResponse[]>(`${this.contentTrainingApi}/files/all`);
  }

  postTrainingDownload(fileId: number): Observable<any> {
    return this.httpClient.post(`${this.contentTrainingApi}/files/${fileId}/view`, {});
  }

  getExamQuestionsCourse(examId: number): Observable<any> {
    return this.httpClient.get(`${this.directoryBase}/exams/${examId}`);
  }
  getExamQuestionsCourseExternal(examId: number): Observable<any> {
    return this.httpClient.get(`${this.host}/exams/${examId}`);
  }

  takeExamCourse(courseId: number, examId: number): Observable<any> {
    return this.httpClient.post(`${this.directoryBase}/courses/${courseId}/exams/${examId}`, {});
  }

  getLastCompletedExam(courseId: number, examId: number): Observable<any> {
    return this.httpClient.get(`${this.directoryBase}/courses/${courseId}/exams/${examId}/latest`);
  }

  validateAnswer(userExamId: number, questionId: number, answerId: number): Observable<any> {
    const body = {
      userExamId,
      questionId,
      answerId
    }
    return this.httpClient.post(`${this.directoryBase}/exam/answer`, body);
  }

  validateAnswerExternal(questionId: number, answerId: number): Observable<any> {
    const body = {
      questionId,
      answerId
    }
    return this.httpClient.post(`${this.host}/exam/answer`, body);
  }

  putCertificateClaimed(courseId: number, viewId: number, body: { name: string }): Observable<any> {
    return this.httpClient.put(`${this.host}/courses/${courseId}/views/${viewId}`, body);
  }

  postCourseView(courseId: number, body: any): Observable<any> {
    return this.httpClient.post(`${this.host}/courses/${courseId}/view`, body);
  }

  updateCourseViewLesson(courseId: number, lessonId: number, courseViewId?: number): Observable<any> {
    if(courseViewId && courseViewId === this.courseViewId){
      return this.httpClient.put(`${this.host}/courses/${courseId}/views/${courseViewId ?? this.courseViewId}`, { courseLessonId: lessonId });
    }
    return of(null);
  }

  postExamView(body: any): Observable<any> {
    return this.httpClient.post(`${this.host}/exam-view`, body);
  }

  updateExamViewLesson(examViewId: number, body: any): Observable<any> {
    return this.httpClient.put(`${this.host}/exam-view/${examViewId}`, body);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'milab-header-refers',
  templateUrl: './header-refers.component.html',
  styleUrls: ['./header-refers.component.scss']
})
export class HeaderRefersComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goHome(){
    this.router.navigate(['/']);
  }
}

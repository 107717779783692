<fieldset class="mt-2">
  <legend>Canales de contacto</legend>
  <div class="col-12 m-1 mb-4 title-info d-flex align-items-center">
    <p class="my-2 mx-2">
      <span class="title-highlight">Ingresa</span> la información de cada uno de los canales de contacto que tienes en
      los espacios señalados abajo.
    </p>
  </div>

  <div class="row">
    <div [formGroup]="contactInfo" *ngFor="let contactInfo of showFieldIcon; let i = index" class="col-md-6">
      <div class="mb-4">
        <div class="url-title d-flex align-items-center justify-content-start mb-3">
          <img
            class="bg-image mr-3"
            [src]="'/assets/icons/intranet/logos/' + getInfoFromType(contactInfo.get('type')?.value, 'icon')"
            alt="icono"
          />
          <span class="contact-label">{{ getInfoFromType(contactInfo.get('type')?.value, 'label') }}</span>
        </div>
        <div class="contact-input">
          <input
            type="text"
            class="form-control"
            formControlName="link"
            [placeholder]="getInfoFromType(contactInfo.get('type')?.value, 'placeholder')"
            [maxlength]="getMaxLengthFromType(contactInfo.get('type')?.value)"
          />
          <label class="w-100">
            <div *ngIf="!contactInfo.get('link')?.value" class="info-required">
              {{ isContactRequired(contactInfo) }}
            </div>
            <div *ngIf="showError(contactInfo)" class="text-error pl-2">
              <div *ngIf="contactInfo.get('link')?.errors?.pattern">
                {{ getPatternErrorFromType(contactInfo.get('type')?.value) }}
              </div>
              <div *ngIf="contactInfo.get('link')?.errors?.maxlength || contactInfo.get('link')?.errors?.minlength">
                {{ getMaxMinLengthErrorFromType(contactInfo.get('type')?.value) }}
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</fieldset>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { RotativeBannerComponent } from './rotative-banner/rotative-banner.component';

@NgModule({
  declarations: [RotativeBannerComponent],
  imports: [CommonModule, NgbCarouselModule],
  exports: [RotativeBannerComponent],
})
export class GlobalComponentModule {}

<div class="div__training-container__color">
  <div class="training-container">
    <div class="header" *ngIf="showTitleCategories">
      <h4>¡Consejos útiles para tu negocio!</h4>
      <p>Aprende en pocos minutos para impulsar el crecimiento de tu negocio</p>
    </div>
    <div class="row row-title-category" *ngIf="showTitleCategories">
      <p class="title-category">Categorías</p>
    </div>
    <div class="mobile">
      <ngx-slick-carousel #slickCarouselBlog [config]="slickConfigBlogs" style="margin-bottom: 20px">
        <div ngxSlickItem *ngFor=" let category of categories; let i = index" class="slide">
          <button
            type="button"
            class="btn button-category"
            [ngClass]="{ 'button-category-all' : category.name === 'Ver todo', 'active': isPressed[i]}"
            (click)="toggleButton(i)"
          >
            {{ category.name }}
          </button>
        </div>
      </ngx-slick-carousel>
    </div>
    <div class="desktop">
      <div class="m-md-1 m-lg-1" *ngFor="let category of categories; let i = index">
        <button
          type="button"
          class="btn button-category"
          [ngClass]="{ 'button-category-all' : category.name === 'Ver todo', 'active': isPressed[i]}"
          (click)="toggleButton(i)"
        >
          {{ category.name }}
        </button>
      </div>
    </div>
    <div class="swiper-section">
      <swiper #swiperBlog [config]="config" style="margin: 0 10px">
        <ng-container *ngFor="let item of blogsShow; let i = index">
          <ng-template swiperSlide>
            <yc-landing-blog-card class="card-webinars-swiper" [blog]="item"></yc-landing-blog-card>
          </ng-template>
        </ng-container>
      </swiper>
      <div class="swiper-button-blog-prev">
        <img src="assets/icons/communities/arrow-left.svg" alt="arrow" />
      </div>
      <div class="swiper-button-blog-next">
        <img src="assets/icons/communities/arrow-right.svg" alt="arrow" />
      </div>
    </div>
    <div class="row m-auto justify-content-center">
      <a class="btn btn-see-more-webinar" (click)="goToSeeMoreArticles();">Ver más artículos</a>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import SwiperCore, { Navigation } from 'swiper/core';
import { ALLIED_BUSINESS_DATA, ALLIED_BENEFITS_DATA, ALLIED_BUSINESS } from '@shared/data/allied/allied.data.json';
import { isSafariBrowser } from '@shared/utils/validators.utils';
import { DashboardService } from '@shared/services/dashboard.service';

SwiperCore.use([Navigation]);

@Component({
  selector: 'yevo-allied-details',
  templateUrl: './allied-details.component.html',
  styleUrls: ['./allied-details.component.scss'],
})
export class AlliedDetailsComponent implements OnInit {
  isLogged = false;
  detailParam = '';
  businessData: any = ALLIED_BUSINESS_DATA;
  benefitsData: any = ALLIED_BENEFITS_DATA;
  allied: any = {};
  anothersAllied: any = [{}];
  alliedAll = [];
  alliedData: any;
  banner: any = [];
  details: any = {};

  constructor(
    private router: Router,
    private authService: AuthService,
    public dataLayerService: DataLayerService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute
  ) {
    this.loadLoggedUser();
    this.route.params.subscribe((params) => {
      const param = params['slug'];
      this.detailParam = param;
    });
  }

  ngOnInit(): void {
    this.getLoadData();
    this.getAllied();
    this.getDetailsAllied();
  }

  getDetailsAllied() {
    this.details = ALLIED_BUSINESS.filter((item: any) => {
      if (item.id == this.detailParam) {
        return item;
      }
    })[0]?.modal;
  }

  getLoadData() {
    this.dashboardService.getCardList().subscribe((data) => {
      this.alliedAll = data.filter((item: any) => item.type === 'aliado');
      this.alliedData = this.alliedAll.filter((item: any) => {
        if (this.detailParam == item.iconName) {
          return item;
        }
      })[0];

      this.banner = this.alliedAll.filter((item: any) => {
        if (this.detailParam !== item.iconName) {
          return item;
        }
      });
    });
  }

  getAllied() {
    this.businessData.filter((allied: any) => {
      if (this.detailParam == allied.title) {
        this.allied = allied;
      }
    });
  }

  private loadLoggedUser() {
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLogged = true;
      }
    });
  }

  selectedBannerEvent(option: string, redirect: string) {
    this.dataLayerService.selectedBannerAllied(option, this.isLogged);
    this.goToLink(redirect);
  }

  selectedCuponEvent(option: string, redirect: string) {
    this.dataLayerService.selectedCuponAllied(this.detailParam, option, this.isLogged);
    if (!this.isLogged) {
      this.router.navigate(['/registro'], { queryParamsHandling: 'merge' });
    } else {
      this.goToLink(redirect);
    }
  }

  goToChange() {
    this.alliedData = this.alliedAll.filter((item: any) => {
      if (this.detailParam == item.iconName) {
        return item;
      }
    })[0];

    this.banner = this.alliedAll.filter((item: any) => {
      if (this.detailParam !== item.iconName) {
        return item;
      }
    });
  }

  gotToAlliedCupon(allied: string): void {
    this.detailParam = allied;
    this.dataLayerService.selectedMoreAllied(allied, this.isLogged);
    this.router.navigate(['/aliados/detalle/', allied], {
      queryParamsHandling: 'merge',
    });
    this.goToChange();
  }
  back() {
    this.router.navigate(['aliados'], { queryParamsHandling: 'merge' });
  }

  goToLink(link: string) {
    if (isSafariBrowser()) {
      window.location.href = link;
    } else {
      window.open(link, '_blank');
    }
  }

  getImageBanner(banner: any) {
    if (banner.iconName === 'Insuma') {
      return banner.iconSrc;
    }
    return banner.logoSrc;
  }

  getImageIcono(iconoImage: any) {
    if (iconoImage.iconName === 'Yape') {
      return '/assets/img/allies/yape/yape.png';
    }
    return iconoImage.iconSrc;
  }
}

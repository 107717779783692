<div class="row pl-12">
  <div class="col birthdate birthdate__day">
    <yevo-dropdown 
      [items]="days"
      [canRemoveActiveItem]="false" 
      buttonLabel="Día"
      containerTitle="Día"
      (selectItem)="handleDayEvent($event)"
      [showManualError]="invalid">
    </yevo-dropdown>
  </div>
  <div class="col birthdate birthdate__month">
    <yevo-dropdown 
      [items]="months" 
      [canRemoveActiveItem]="false" 
      buttonLabel="Mes"
      containerTitle="Mes"
      (selectItem)="handleMonthEvent($event)"
      [showManualError]="invalid">
    </yevo-dropdown>
  </div>
  <div class="col birthdate birthdate__year">
    <yevo-dropdown 
      [items]="years" 
      [canRemoveActiveItem]="false" 
      buttonLabel="Año"
      containerTitle="Año"
      (selectItem)="handleYearEvent($event)"
      [showManualError]="invalid">
    </yevo-dropdown>
  </div>
</div>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserProfile } from '@shared/models/UserProfileModel';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { Answer } from '@shared/interfaces/frontend/communities';
import { Subscription } from 'rxjs';
import { CommunityService } from '@shared/services/community.service';
import { ModalAnswerMessageComponent } from '../modal-answer-message/modal-answer-message.component';

@Component({
  selector: 'yevo-modal-answer',
  templateUrl: './modal-answer.component.html',
  styleUrls: ['./modal-answer.component.scss'],
})
export class ModalAnswerComponent implements OnInit, OnDestroy {
  modalBoxActive = false;
  modalTransitionActive = false;
  @Input()
  question = '';
  @Input()
  userName = '';
  @Input()
  forumId = 0;
  answerPopup = '';
  user!: UserProfile;
  answer: Answer = {
    forumId: 0,
    answer: '',
    userId: 0,
    userName: '',
    creationDate: '',
    dueDate: '',
  };

  private subscription = new Subscription();

  constructor(
    private activeModal: NgbActiveModal,
    private localStorageService: LocalStorageService,
    private communityService: CommunityService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.deactivateScroll();
    this.modalBoxActive = true;
    this.modalTransitionActive = true;
  }

  closePopup(): void {
    this.modalTransitionActive = false;
    this.modalBoxActive = false;
    this.answerPopup = '';
    const t = setTimeout(() => {
      clearTimeout(t);
      this.activateScroll();
    }, 400);
    this.activeModal.close('Modal Answer Closed');
  }

  activateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'auto';
  }

  createAnswer(): void {
    const userString = this.localStorageService.get('userProfile');
    if (userString && this.answerPopup !== '') {
      this.user = JSON.parse(userString) as UserProfile;
      this.answer.userId = this.user.userId;
      this.answer.forumId = this.forumId;
      this.answer.answer = this.answerPopup;
      this.answer.userName = `${this.user.name} ${this.user.fatherLastName} ${this.user.motherLastName}`;
      this.subscription.add(
        this.communityService.createAnswer(this.answer).subscribe(
          (response) => {
            this.answerPopup = '';
            this.closePopup();
            const modalRef = this.modalService.open(ModalAnswerMessageComponent);
            modalRef.componentInstance.forumId = this.forumId;
            modalRef.result
              .then((result) => {
              })
              .catch((error) => {
                console.error(error);
              });
          }, error => {
            console.error(error);
          }
        )
      );
    }
  }

  deactivateScroll(): void {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'hidden';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

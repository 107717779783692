<oigo-loading></oigo-loading>
<div *ngIf="webinar" class="mt-3">
  <div class="background-container">
    <div class="background" (click)="goToWebinar()">
      <div class="webinar-info" [ngClass]="{ 'if-description': webinar.text.length > 0 }" *ngIf="webinar">
        <div>
          <p class="webinar-title">{{ truncateStringWithEllipsis(webinar.title) }}</p>
          <p class="webinar-subtitle">{{ truncateStringWithEllipsis(webinar.text, 'description') }}</p>
        </div>
        <button (click)="goToWebinar()">Quiero verlo</button>
      </div>
    </div>
  </div>
</div>
<yevo-new-banner
  origin="emprende-mujer"
  imageDesktop="/assets/img/communities/emprende-mujer-chica-1.png"
  imageMobile="/assets/img/communities/emprende-mujer-chica-1-mobile.png"
  urlWhatsapp="https://chat.whatsapp.com/JfnJHAEZE9cFfQ8NqSfA2J"
  title="EMPRENDE MUJER"
  description="¡Encuentra aquí consejos para convertirte en una emprendedora de éxito!"
  sparkLeftDesktop="/assets/img/communities/spark-mujer-left-desktop.svg"
  sparkRightDesktop="/assets/img/communities/spark-mujer-right-desktop.svg"
  sparkLeftMobile="/assets/img/communities/spark-mujer-left-mobile.svg"
  sparkRightMobile="/assets/img/communities/spark-mujer-right-mobile.svg"
></yevo-new-banner>
<yevo-community-benefits
  [title]="this.titleBenefits"
  [description]="this.descriptionBenefits"
  [icons]="this.icons"
></yevo-community-benefits>
<div id="services" class="second_container">
  <div class="credit-block">
    <div class="row_container p-0">
      <div class="row row-business">
        <p class="communities-title-h2">¡Crece con tu negocio!</p>
      </div>
      <div class="row m-0">
        <p class="communities-paragraph-3">Accede a beneficios exclusivos para potenciar las finanzas de tu empresa</p>
      </div>
      <div class="card-container row m-0">
        <div class="card credit-card">
          <div class="card-body p-0">
            <div class="div-container__text">
              <img
                src="/assets/icons/communities/credit-1-gray.svg"
                class="img-fluid rounded-start img-credit"
                alt="rounded"
              />
              <div class="credit-text">
                <p class="card-title communities-title-h3">Crédito Mujer</p>
                <p class="card-text credit-body-text">
                  Destinado a emprendedoras como tú que necesiten invertir en su negocio
                </p>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-link credit-button"
              (click)="goToCreditoMujer(); optionSelectedBlockFinancing('Crédito Mujer')"
            >
              Solicita aquí tu Crédito Mujer
            </button>
          </div>
        </div>
        <div class="card credit-card credit-card__margin_inverse">
          <div class="card-body p-0">
            <div class="div-container__text">
              <img
                src="/assets/icons/communities/credit-2-gray.svg"
                class="img-fluid rounded-start"
                alt="rounded"
                style="margin: 9px 16px 16px 25px"
              />
              <div class="credit-text">
                <p class="card-title communities-title-h3">Cuenta de Ahorros</p>
                <p class="card-text credit-body-text">
                  Coloca tu dinero en un lugar seguro con tasas altamente competitivas
                </p>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-link credit-button"
              (click)="goToCuentasAhorro(); optionSelectedBlockFinancing('Cuenta de Ahorros')"
              routerLink="/cuenta-ahorros/landing"
            >
              Abre aquí tu Cuenta de Ahorros
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #isLevel>
  <div class="block-level">
    <div class="row m-auto card-level">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <p class="card-level-title">¡Crecemos juntos!</p>
        <p class="card-level-text">Has alcanzado el nivel {{ levelUser }} de 5.</p>
        <div
          class="progress"
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            [ngClass]="{
              'w-20': levelUser === 1,
              'w-40': levelUser === 2,
              'w-60': levelUser === 3,
              'w-80': levelUser === 4,
              'w-100': levelUser === 5
            }"
          ></div>
        </div>
      </div>
      <div class="col">
        <p class="card-level-message" *ngIf="levelUser != 5">
          Para subir los niveles, mira los videos y webinars sugeridos en la categoría “Para Mi”.
        </p>
        <p class="card-level-message" *ngIf="levelUser == 5">
          ¡Felicitaciones! Completaste todos los niveles. Te recomendamos ver los videos que acabamos de publicar esta
          semana.
        </p>
        <button type="button" class="btn card-level-button" (click)="goToSection('training')" *ngIf="levelUser != 5">
          Quiero subir mi nivel
        </button>
        <button type="button" class="btn card-level-button" (click)="goToSection('training')" *ngIf="levelUser == 5">
          Ver videos recientes
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="userLevel.flagStatus === 'I' || userLevel.flagStatus == null; else isLevel">
  <div style="background: #3fb4e5">
    <div class="section-test">
      <div class="container mt-0">
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-2 m-auto banner-section-icon">
            <img class="img-fluid img-mobile" src="/assets/icons/communities/escudo-check.png" alt="escudo" />
            <img class="img-fluid img-desktop" src="/assets/icons/communities/escudo-check-desktop.png" alt="escudo" />
          </div>
          <div class="col-8 col-sm-8 col-md-8 col-lg-10 m-auto banner-section-title-text">
            <p class="title">Descubre el nivel de tu negocio en Yevo</p>
            <p class="text">Responde brevemente el formulario y elige tus preferencias.</p>
            <button type="button" (click)="checkModal(); initModalDataLayer()">Iniciar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<yevo-certified-section *ngIf="this.certificates.length > 0"></yevo-certified-section>
<yevo-tool-section></yevo-tool-section>
<yevo-specialization-section
  title="¡Conviértete en experta!"
  subtitle="Elige el Programa de Especialización y lleva tu negocio al siguiente nivel."
  [items]="specializationItems"
></yevo-specialization-section>
<div class="educational-content_container">
  <yevo-educational-content-section
    [items]="tabItems"
    [selectedItem]="selectedTab"
    (selectedItemEmit)="handleSelectedTab($event)"
    id="educational-content"
  ></yevo-educational-content-section>
  <yc-landing-blogs *ngIf="selectedTab.code === 'articulos'" [showTitleCategories]="false"></yc-landing-blogs>
  <yc-trainings
    id="training"
    class="educational_content_class"
    [communityId]="COMMUNITY_ID"
    [userLevel]="this.userLevel"
    [source]="source"
    [webinarViewed]="this.webinarViewed"
    [showTitleCategories]="false"
    *ngIf="this.webinarViewed && selectedTab.code === 'webinars'"
  ></yc-trainings>
  <yevo-videos-business
    id="videos"
    class="educational_content_class"
    [communityId]="COMMUNITY_ID"
    [userLevel]="this.userLevel"
    [source]="source"
    [videoViewed]="this.videosViewed"
    [showTitleCategories]="false"
    *ngIf="this.videosViewed && selectedTab.code === 'videos'"
  ></yevo-videos-business>
</div>
<yevo-whatsapp-icon-floating
  urlWhatsApp="https://chat.whatsapp.com/JfnJHAEZE9cFfQ8NqSfA2J"
  [source]="source"
></yevo-whatsapp-icon-floating>
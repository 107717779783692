import { Component, Input } from '@angular/core';
import { SpecializationItem } from '@shared/interfaces/frontend/communities';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'yevo-specialization-section',
  templateUrl: './specialization-section.component.html',
  styleUrls: ['./specialization-section.component.scss'],
})
export class SpecializationSectionComponent {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() items!:SpecializationItem[];
  configSwiper: SwiperOptions = {
    direction: 'horizontal',
    freeMode: true,
    slidesPerView: 1,
    spaceBetween: 40,
    breakpoints: {
      334: {
        slidesPerView: 1.1,
        spaceBetween: 5,
      },
      380: {
        slidesPerView: 1.1,
        spaceBetween: 5,
      },
      450: {
        slidesPerView: 1.15,
        spaceBetween: 5,
      },
      550: {
        slidesPerView: 1.17,
        spaceBetween: 5,
      },
      600: {
        slidesPerView: 1.19,
        spaceBetween: 5,
      },
      690: {
        slidesPerView: 1.21,
        spaceBetween: 5,
      },
      768: {
        slidesPerView: 2.25,
        spaceBetween: 16,
      },
      850: {
        slidesPerView: 2.3,
        spaceBetween: 5,
      },
      950: {
        slidesPerView: 3,
        spaceBetween: 5,
      },
      1050: {
        slidesPerView: 3.1,
        spaceBetween: 5,
      },
      1150: {
        slidesPerView: 3.1,
        spaceBetween: 5,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1250: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1350: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };
}

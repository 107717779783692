<div (click)="openModal(isMobile ? modalMobileContent : modalContent)" class="mobile-div mb-1">
  <input
    type="text"
    class="form-control custom-selector custom-disabled"
    maxlength="8"
    placeholder="Selecciona tu opción aquí"
    [value]="itemSelected?.label"
    [ngClass]="{ error: showManualError }"
  />
</div>

<!-- DESKTOP MODAL -->
<ng-template #modalContent let-modal class="modal-content">
  <div class="w-100 d-flex justify-content-end">
    <button type="button" class="close p-2" (click)="closeModal()">
      <img src="/assets/img/close-small.svg" alt="close" />
    </button>
  </div>
  <div class="modal-container">
    <div class="modal-header pt-0 pb-2 w-100">
      <h3>Giro o actividad comercial</h3>
    </div>
    <div class="modal-body pt-0 pb-4 mb-5 w-100">
      <div>
        <div (click)="showFullScreenModal = true">
          <div class="input-group">
            <input
              type="text"
              class="form-control modal-input mb-3 fw_500 searched"
              maxlength="50"
              placeholder="Escribe aquí tu actividad comercial"
              (input)="onSearchChange($event.target)"
              [(ngModel)]="txtSearch"
              id="searched"
            />
            <span class="custom-icon" *ngIf="!deleteSearch"
              ><img alt="search-icon" src="/assets/img/search.svg"
            /></span>
            <span class="custom-icon close-icon" *ngIf="deleteSearch" (click)="deleteSearchText()"
              ><img width="32" alt="search-icon" src="/assets/icons/close-small.svg"
            /></span>
          </div>
        </div>
        <ng-container *ngIf="!showFullScreenModal; else fullscreenList">
          <div
            *ngFor="let item of mostPopularItems"
            class="showed-pills second-theme"
            [ngClass]="{
              active: item?.value == itemSelected?.value
            }"
            (click)="selectItem($event, item)"
          >
            <p>{{ item.label }}</p>
          </div>
          <div class="showed-pills second-theme" (click)="allActivities()">
            <p class="fw_600">Ver otras actividades comerciales</p>
          </div>
        </ng-container>
        <ng-template #fullscreenList>
          <div class="main-fullscreen-container">
            <div class="fullscreen-items-container">
              <div *ngIf="allItems?.length! <= 0" class="no-items p-2">
                <img src="/assets/img/alert-icon.svg" alt="icon" class="mr-2" />
                <p class="mb-0">No encontramos un resultado que coincida con tu búsqueda</p>
              </div>
              <div
                *ngFor="let item of allItems"
                class="fullscreen-item"
                (click)="selectItem($event, item)"
                [ngClass]="{
                  active: item?.value == itemSelected?.value
                }"
              >
                <p>{{ item.label }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<!-- MOBILE MODAL -->
<ng-template #modalMobileContent let-modal>
  <div class="modal-content" [ngClass]="{ fullscreen: showFullScreenModal }">
    <div class="modal-header pt-4 pb-3">
      <h3>Giro o actividad comercial</h3>
      <button type="button" class="close" (click)="closeModal()">
        <img class="images__clouds" src="/assets/img/close-small.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body pt-0 pb-4">
      <div>
        <div (click)="toggleFullScreenModal()">
          <div class="input-group">
            <input
              type="text"
              class="form-control modal-input mb-3 fw_500 searched"
              maxlength="50"
              placeholder="Escribe aquí tu actividad comercial"
              (input)="onSearchChange($event.target)"
              [(ngModel)]="txtSearch"
              id="searched"
            />
            <span class="custom-icon" *ngIf="!deleteSearch"
              ><img alt="search-icon" src="/assets/img/search.svg"
            /></span>
            <span class="custom-icon close-icon" *ngIf="deleteSearch" (click)="deleteSearchText()"
              ><img width="32" alt="search-icon" src="/assets/icons/close-small.svg"
            /></span>
          </div>
        </div>
        <ng-container *ngIf="!showFullScreenModal; else fullscreenList">
          <div
            *ngFor="let item of mostPopularItems"
            class="showed-pills second-theme content d-block"
            [ngClass]="{
              active: item?.value == itemSelected?.value
            }"
            (click)="selectItem($event, item)"
          >
            <p>{{ item.label }}</p>
          </div>
          <div class="showed-pills second-theme mb-5" (click)="allActivities()">
            <p class="fw_600">Ver otras actividades comerciales</p>
          </div>
        </ng-container>

        <ng-template #fullscreenList>
          <div class="main-fullscreen-container">
            <div class="fullscreen-items-container">
              <div *ngIf="allItems?.length! <= 0" class="no-items p-2">
                <img src="/assets/img/alert-icon.svg" alt="icon" class="mr-2" />
                <p class="mb-0">No encontramos un resultado que coincida con tu búsqueda</p>
              </div>
              <div
                *ngFor="let item of allItems"
                class="fullscreen-item"
                (click)="selectItem($event, item)"
                [ngClass]="{
                  active: item?.value == itemSelected?.value
                }"
              >
                <p>{{ item.label }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<div class="tool_section_container">
  <div class="tool_section_info">
    <div class="tool_section_info_title">Herramientas</div>
    <div class="tool_section_info_description">Hemos creado distintas herramientas para potenciar tu negocio.</div>
    <div class="tool_section_info_button">
      <div class="tool_button" (click)="goToToolPage()">Conócelas aquí</div>
    </div>
  </div>
  <div class="tool_section_image">
    <img src="assets/img/communities/work-group.svg" alt="teamwork_image" class="image_tool" />
  </div>
</div>

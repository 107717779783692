<div class="overflow-hidden">
  <yevo-rotative-banner [loader]="true" (bannerClicked)="selectedBanner($event)" [banners]="banners">
  </yevo-rotative-banner>
</div>

<div class="container mt-3">
  <div class="row breadcrumb mb-3">
    <yevo-breadcrumb [breadcrumbs]="breadcrumbs" class="col-12 pl-3 pl-md-1"></yevo-breadcrumb>
  </div>
  <div class="row d-none d-md-flex justify-content-center mb-4">
    <div class="mr-2 mr-md-3 allied-cards" [ngClass]="{ active: business }" (click)="getbusiness('Para tu negocio')">
      <img src="/assets/img/allies/credit-card.svg" alt="credit card" class="mt-3" />
      <p>Negocio</p>
    </div>

    <div
      class="allied-cards allied-cards-benefits"
      [ngClass]="{ active: benefits }"
      (click)="getbenefits('Beneficios de MiBanco')"
    >
      <img src="/assets/img/allies/Money.svg" alt="money card" class="mt-3" />
      <p>Mibanco</p>
    </div>
  </div>

  <div class="row d-flex d-block d-md-none justify-content-center mb-4">
    <div class="col-12 d-flex justify-content-center d-md-none">
      <div class="mr-2 allied-card-container" [ngClass]="{ active: business }" (click)="getbusiness('Para tu negocio')">
        <img src="/assets/img/allies/credit-card.svg" alt="credit card" />
        <p>Negocio</p>
      </div>

      <div
        class="allied-card-container"
        [ngClass]="{ active: benefits }"
        (click)="getbenefits('Beneficios de MiBanco')"
      >
        <img src="/assets/img/allies/Money.svg" alt="money card" />
        <p>MiBanco</p>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="business">
    <div class="col-12">
      <p class="allied-filter d-block d-md-none">Filtra por categorías</p>
    </div>
    <div class="col-12 mb-4 scroll d-lg-flex justify-content-lg-center overflow">
      <yevo-filter-pills
        [items]="topicOptionsDropdown"
        [showSliderPills]="true"
        [showSeeMore]="false"
        [dropdownPlacement]="['bottom-right']"
        [scrollActivated]="true"
        dropdownTitle="Ver temas"
        [showJustifyContentBetween]="false"
        [numberPillsShowed]="topicOptionsDropdown.length"
        [itemSelected]="topicSelected"
        (selectItem)="selectTopic($event)"
        [withHandleOption]="false"
        class="allied-tags"
      ></yevo-filter-pills>
    </div>
  </div>

  <div class="row" *ngIf="business">
    <yevo-card-allied
      class="col-12 col-sm-6 col-md-6 col-lg-4"
      *ngFor="let item of alliedData"
      [allied]="item"
      (registerClick)="openModal(item)"
    ></yevo-card-allied>
  </div>

  <div *ngIf="benefits" class="row justify-content-center mb-5">
    <div class="col-6 col-md-2 mb-3" *ngFor="let item of service">
      <div class="card card-container card-benefits-list" (click)="goToDetailsBenefit(item.iconName, item.buttonLink)">
        <img class="optiontwo" src="/assets/img/allies/mibanco.png" [alt]="item.iconName" />
        <span class="titlecard">{{ item.iconName }}</span>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 text-center">
      <yevo-pagination-v2
        [page]="currentPage"
        [dataSize]="allieds.length"
        [pageSize]="pageSize"
        (pageChange)="pageChangeEvent($event)"
      ></yevo-pagination-v2>
    </div>
  </div>

  <div class="row mb-3 mb-md-0">
    <div class="col-12">
      <div class="inf mt-2 mt-md-3 mb-0">
        <span>
          {{ isLogged ? 'El usuario reconoce que ' : '' }}YEVO y Mibanco no son responsables por la calidad, veracidad o
          idoneidad de los productos y/o servicios ofrecidos por nuestros aliados.
        </span>
      </div>
    </div>
  </div>

  <div class="row jcc mb_42 d-none d-md-block text-center mt-5 d-none" *ngIf="!isLogged">
    <div class="col-12">
      <button class="btn" (click)="registerEvent()">Registrarme</button>
    </div>
  </div>
</div>

<div class="container-fluid d-none d-md-block">
  <div class="row">
    <div class="col-12 pl-0 pr-0 mt-4">
      <img class="allied-cloud" src="/assets/img/courses/nubes.png" alt="cloud" />
    </div>
  </div>
</div>

<div *ngIf="isScrolled && !isLogged" class="fixed__button d-none">
  <button class="register__button" (click)="registerEvent()">Registrarme</button>
</div>

<!-- ********************* MODAL *********************** -->
<ng-template #content let-c="close" let-d="dismiss" class="modal-contents">
  <div class="modal-body text-center py-4 hidden">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <img src="/assets/icons/close-green.svg" alt="cerrar" />
    </button>
    <img [src]="modalData.image" alt="image" class="modal-allied-image mb-3 mt-3" />
    <div class="container">
      <div class="row">
        <div class="col-md-12 modal-allied-line d-flex pl-0 pr-0">
          <div *ngIf="modalData.tags.length" class="d-flex">
            <span class="modal-allied-tags mt-3 mb-3" *ngFor="let item of modalData.tags; let i = index">
              <img [src]="tagsIcon[i]" alt="image" class="modal-allied-icon" /> {{ item.label }}</span
            >
            <span class="modal-allied-tags mt-3 mb-3">
              <img src="/assets/img/allies/store.svg" alt="image" class="modal-allied-icon" />Negocio</span
            >
          </div>
        </div>

        <div class="col-12 pl-0 pr-0">
          <p class="modal-allied-title">{{ modalData.title }}</p>
        </div>

        <div class="col-md-12 modal-allied-line pl-0 pr-0" *ngIf="modalData.description">
          <p class="modal-allied-description mt-3">{{ modalData.description }}</p>
        </div>

        <div class="col-12 mb-3 text-center pl-0 pr-0 mt-4" *ngIf="!isLogged">
          <button class="btn btn-primary modal-allied-button" (click)="registerEvent()">Registrarme</button>
        </div>

        <div class="col-12 mb-3 text-center pl-0 pr-0 mt-4" *ngIf="modalData.link && isLogged">
          <button class="btn btn-primary modal-allied-button" (click)="goToLink(modalData.link)">
            {{ modalData.button }}
          </button>
        </div>

        <div class="col-12 text-center pl-0 pr-0" *ngIf="modalData.catalogue && isLogged">
          <a
            [href]="modalData.download"
            class="btn btn-primary modal-allied-button-catalogue"
            target="_blank"
            [download]="modalData.title"
          >
            {{ modalData.catalogue }}
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SocialNetwork } from '@shared/interfaces/frontend/communities';
import socialNetworksJson from '@shared/data/communities/social-networks.json';
@Component({
  selector: 'milab-modal-expandable-refers',
  templateUrl: './modal-expandable-refers.component.html',
  styleUrls: ['./modal-expandable-refers.component.scss']
})
export class ModalExpandableRefersComponent implements OnInit {
 @Input() bgColor!: string;
 @Input() codeRecomended: string = '34567';
 @Output() showEmitter = new EventEmitter<boolean>();
  showContent: boolean = false;
  COLOR_DOWN:string = '#F5F5F5';
  COLOR_UP:string = '#FFFFFF';

  socialNetworks: SocialNetwork[] = socialNetworksJson;

  constructor() { }


  ngOnInit(): void {
    setTimeout(() => {
      this.showContent = true;
      this.showEmitter.emit(this.showContent);
      this.bgColor = this.COLOR_UP;
    }, 1800);
  }

  checkexpandable(){
    this.showContent = !this.showContent;
    this.showEmitter.emit(this.showContent);
    this.bgColor= this.showContent? this.COLOR_UP:this.COLOR_DOWN;
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataLayerService } from '@shared/services/data-layer.service';
import { Training } from '@shared/interfaces/frontend/communities';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunityService } from '@shared/services/community.service';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { UserProfile } from '@shared/models/UserProfileModel';
import { LocalStorageService } from '@shared/services/local-storage.service';

declare let YT: any;

@Component({
  selector: 'yevo-training-detail',
  templateUrl: './training-detail.component.html',
  styleUrls: ['./training-detail.component.scss'],
})
export class TrainingDetailComponent implements OnInit, OnDestroy {
  safeResourceUrl!: SafeResourceUrl;
  @Input()
  training!: Training;
  subscription: Subscription = new Subscription();
  private player: any;
  user!: UserProfile;

  constructor(
    private domSanitizer: DomSanitizer,
    private dataLayerService: DataLayerService,
    private activatedRoute: ActivatedRoute,
    private communityService: CommunityService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.communityService.videoSubjectSearch$.subscribe(
      (response) => {
        this.training = response;
      }
    );
    if (!this.training) {
      this.subscription.add(
        this.activatedRoute.params.subscribe((params) => {
          this.communityService.getWebinarId(params.id).subscribe((response) => {
            this.training = response;
            this.convertDate();
            this.safeResourceUrl = this.getTrustedVideoURL(this.training.url);
          });
        })
      );
    } else {
      this.safeResourceUrl = this.getTrustedVideoURL(this.training.url);
      this.convertDate();
    }
  }

  getTrustedVideoURL(videoURL: string): SafeResourceUrl {
    if (videoURL.startsWith('https://www.youtube.com/watch?v=')) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(videoURL.replace('watch?v=', 'embed/'));
    } else if (videoURL.startsWith('https://youtu.be')) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(
        videoURL.replace('https://youtu.be', 'https://www.youtube.com/embed/')
      );
    } else {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(videoURL);
    }
  }

  handleIFrameClick(videoTitle: string): void {
    this.dataLayerService.pushTrainingVideoClick(videoTitle);
  }

  private convertDate(): void {
    this.training.creationDate = format(new Date(this.training.creationDate), 'd MMM');
    this.onYouTubeIframeAPIReady();
  }

  goHome(): Promise<boolean> {
    return this.router.navigate(['../../..'], { relativeTo: this.activatedRoute });
  }

  goTrainings(): Promise<boolean> {
    const parentRoute = this.activatedRoute.parent?.routeConfig?.path;
    return parentRoute === 'emprende-mujer'
      ? this.router.navigate(['nuevo-intranet/comunidades/emprende-mujer', 'webinar'], {
          state: { communityId: this.training.communityId },
        })
      : this.router.navigate(['nuevo-intranet/comunidades/bodegueros', 'webinar'], {
          state: { communityId: this.training.communityId },
        });
  }

  onYouTubeIframeAPIReady(): void {
    this.player = new YT.Player('player', {
      videoId: this.youTubeGetID(this.training.url),
      height: '360',
      width: '100%',
      events: {
        onStateChange: this.onPlayerStateChange.bind(this),
      },
    });
  }

  private onPlayerStateChange(event: any): void {
    if (event.data === 0) {
      const userString = this.localStorageService.get('userProfile');
      if (userString) {
        this.user = JSON.parse(userString) as UserProfile;
        this.subscription.add(
          this.communityService.saveUserWebinarByView(this.user.userId, Number(this.training.id)).subscribe(
            (response) => {},
            (error) => {
              console.error(error);
            }
          )
        );
      }
    }
  }

  private youTubeGetID(url: string): string {
    const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
